import Link from 'next/link';
import { useRouter } from 'next/router';
import React from 'react';
import { slugify, hyphenate } from '../functions/helper';
import { useTranslation } from 'next-i18next';
import { sanitize } from '@curated-property/utils';

export interface BreadcrumbProps {
  prefix?: string;
  delimiter?: React.ReactNode;
  hierarchy: Array<{
    title?: string;
    slug?: string;
    isFrontPage?: boolean;
  }>;
  hierarchyArrayLength?: number;
  hotelName?: string;
  countryName?: string;
  countryName_noTx?: string;
  stateName?: string;
  stateName_noTx?: string;
  city?: string;
  city_noTx?: string;
  styleOptions?: {
    padding?: string;
    background?: string;
    anchorClasses?: string;
    activeAnchorClasses?: string;
  };
  pageSlug?: string;
  activeTextColour?: string;
  backgroundColour?: string;
  textColour?: string;
  delimiterColour?: string;
  delimiterPadding?: string;
}

export function BreadCrumbs({
  prefix,
  delimiter,
  hierarchy: heirarchyProp,
  hierarchyArrayLength,
  hotelName,
  countryName,
  countryName_noTx,
  stateName,
  stateName_noTx,
  city,
  city_noTx,
  styleOptions,
  activeTextColour,
  backgroundColour,
  textColour,
  delimiterColour,
  delimiterPadding,
}: BreadcrumbProps) {
  const { locale = 'en' } = useRouter();
  const { t } = useTranslation();
  const hierarchy = [...heirarchyProp];

  if (!hierarchy.length) {
    return null;
  }

  const paddingClasses = styleOptions?.padding ?? 'px-2 py-2 sm:px-16 sm:py-4';
  const backgroundClass = styleOptions?.background ?? 'bg-primary';

  const activePageObj = hierarchy.pop();

  if (!activePageObj?.slug) {
    return null;
  }

  const locationsUrl = `https://www.hilton.com/${locale}/locations/`;

  const startIndex = !activePageObj?.isFrontPage ? 5 : 4;

  const isSubPage =
    hierarchyArrayLength === 2 && !activePageObj?.isFrontPage ? true : false;

  const activePage = (
    <BreadCrumbActive
      testid="active"
      text={activePageObj?.title ?? ''}
      stylingOptions={{ classes: styleOptions?.activeAnchorClasses }}
      activeTextColour={activeTextColour}
      hotelName={hotelName}
      isFrontPage={activePageObj?.isFrontPage}
    />
  );

  const parentPagesEls: React.ReactNode[] = [];
  let fullSlug = '';
  hierarchy.forEach((page, index) => {
    fullSlug += page.slug + '/';
    parentPagesEls.push(
      <BreadCrumbAnchor
        testid={`crumb-${index + startIndex}`}
        url={`/${locale}${prefix}/${fullSlug}`}
        text={page.title}
        delimiter={delimiter}
        stylingOptions={{ classes: styleOptions?.anchorClasses }}
        textColour={textColour}
        position={isSubPage ? 6 : index + startIndex}
      />
    );
  });

  let metaPosition = 1;

  return (
    <ol
      data-element-id="breadcrumb-component"
      data-testid="crumbs"
      className={`w-full ${paddingClasses} ${backgroundClass}`}
      itemType="https://schema.org/BreadcrumbList"
      itemScope
      style={{ background: backgroundColour }}
    >
      {/* Locations */}
      <BreadCrumbAnchor
        testid="location"
        url={`${locationsUrl}`}
        text={t('locations')}
        delimiter={delimiter}
        stylingOptions={{ classes: styleOptions?.anchorClasses }}
        textColour={textColour}
        delimiterColour={delimiterColour}
        delimiterPadding={delimiterPadding}
        position={metaPosition}
      />
      {/* Country */}
      <BreadCrumbAnchor
        testid="country"
        url={`${locationsUrl}${slugify(countryName_noTx ?? '')}/`}
        text={countryName}
        delimiter={delimiter}
        stylingOptions={{ classes: styleOptions?.anchorClasses }}
        textColour={textColour}
        delimiterColour={delimiterColour}
        delimiterPadding={delimiterPadding}
        position={++metaPosition}
      />
      {/* State */}
      {stateName && (
        <BreadCrumbAnchor
          testid="stateName"
          url={`${locationsUrl}${slugify(countryName_noTx ?? '')}/${slugify(
            stateName_noTx ?? ''
          )}/`}
          text={stateName}
          delimiter={delimiter}
          stylingOptions={{ classes: styleOptions?.anchorClasses }}
          textColour={textColour}
          delimiterColour={delimiterColour}
          delimiterPadding={delimiterPadding}
          position={++metaPosition}
        />
      )}
      {/* City */}
      <BreadCrumbAnchor
        testid="city"
        url={`${locationsUrl}${slugify(countryName_noTx ?? '')}/${
          stateName ? `${slugify(stateName_noTx ?? '')}/` : ''
        }${hyphenate(city_noTx ?? '')}/`}
        text={t('cityHotels', { city })}
        delimiter={delimiter}
        stylingOptions={{ classes: styleOptions?.anchorClasses }}
        textColour={textColour}
        delimiterColour={delimiterColour}
        delimiterPadding={delimiterPadding}
        position={++metaPosition}
      />
      {/* Home */}
      {!activePageObj?.isFrontPage && (
        <BreadCrumbAnchor
          testid="home"
          url={`/${locale}${prefix}/`}
          text={hotelName}
          delimiter={delimiter}
          stylingOptions={{ classes: styleOptions?.anchorClasses }}
          textColour={textColour}
          delimiterColour={delimiterColour}
          delimiterPadding={delimiterPadding}
          position={++metaPosition}
        />
      )}
      {parentPagesEls.map((component, index) => (
        <React.Fragment key={index}>{component}</React.Fragment>
      ))}
      {activePage}
    </ol>
  );
}

/* Individual anchor link */
interface AnchorProps {
  url?: string;
  text?: string | null;
  testid?: string;
  delimiter?: React.ReactNode;
  stylingOptions?: {
    classes?: string;
  };
  textColour?: string;
  delimiterColour?: string;
  delimiterPadding?: string;
  position?: number;
}

function BreadCrumbAnchor({
  url,
  text,
  testid,
  delimiter,
  stylingOptions,
  textColour,
  delimiterColour,
  delimiterPadding,
  position = 1,
}: AnchorProps) {
  // Properly structure URL for the Schema ID
  const schemaUrl =
    url.search('https://www.hilton.com') === -1
      ? `https://www.hilton.com${url}`
      : url;

  if (!text) {
    return null;
  }
  const stylingClasses = stylingOptions?.classes ?? 'text-bg hover:underline';
  delimiter = delimiter ?? (
    <span style={{ color: textColour || undefined }} className="px-2 text-bg">
      /
    </span>
  );
  return (
    <li
      className="inline-block"
      itemProp="itemListElement"
      itemType="https://schema.org/ListItem"
      itemScope
    >
      <Link
        href={url || ''}
        data-testid={testid}
        className={stylingClasses}
        style={{ color: textColour }}
        itemProp="item"
        itemType="https://schema.org/WebPage"
        itemID={schemaUrl}
        itemScope
      >
        <span
          itemProp="name"
          dangerouslySetInnerHTML={{ __html: sanitize(text) }}
        ></span>
      </Link>
      <span
        className={`px-1 md:${delimiterPadding}`}
        style={{ color: delimiterColour || undefined }}
      >
        {delimiter}
      </span>
      <meta itemProp="position" content={`${position}`} />
    </li>
  );
}

/* Active non-anchor link */
interface ActiveProps {
  text?: string;
  testid?: string;
  stylingOptions?: {
    classes?: string;
  };
  activeTextColour?: string;
  hotelName?: string;
  isFrontPage?: boolean;
}

function BreadCrumbActive({
  testid,
  text,
  stylingOptions,
  activeTextColour,
  hotelName,
  isFrontPage,
}: ActiveProps) {
  if (!text) {
    return null;
  }
  if (isFrontPage) {
    text = hotelName;
  }
  const stylingClasses =
    stylingOptions?.classes ?? 'text-bg font-black inline-block';

  return (
    <li
      data-testid={testid}
      className={stylingClasses}
      style={{ color: activeTextColour }}
    >
      <span dangerouslySetInnerHTML={{ __html: sanitize(text ?? '') }}></span>
    </li>
  );
}
