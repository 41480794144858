import React from 'react';
export function Users({
  className,
  fillColor,
}: {
  className?: string;
  fillColor?: string;
}) {
  return (
    <svg className={className} viewBox="0 0 24 24" style={{ fill: fillColor }}>
      <g data-name="Bounding Box">
        <path style={{ fill: 'none' }} d="M0,0V24H24V0Z" />
      </g>
      <g data-name="Icons Outlined">
        <g>
          <path d="M17.618,10.9435a3.3751,3.3751,0,1,0-3.2359,0A4.3758,4.3758,0,0,0,11.625,15a.375.375,0,0,0,.75,0,3.625,3.625,0,0,1,7.25,0,.375.375,0,0,0,.75,0A4.3758,4.3758,0,0,0,17.618,10.9435ZM13.375,8A2.625,2.625,0,1,1,16,10.625,2.6281,2.6281,0,0,1,13.375,8Z" />
          <path d="M9.618,14.9435a3.3751,3.3751,0,1,0-3.2359,0A4.3758,4.3758,0,0,0,3.625,19a.375.375,0,0,0,.75,0,3.625,3.625,0,0,1,7.25,0,.375.375,0,0,0,.75,0A4.3758,4.3758,0,0,0,9.618,14.9435ZM5.375,12A2.625,2.625,0,1,1,8,14.625,2.6281,2.6281,0,0,1,5.375,12Z" />
        </g>
      </g>
    </svg>
  );
}
