import React from 'react';
export function LocationBlank({
  className,
  fillColor,
}: {
  className?: string;
  fillColor?: string;
}) {
  return (
    <svg className={className} viewBox="6 4 12 16" style={{ fill: fillColor }}>
      <g>
        <g>
          <path d="M12,3.81l4.5,9.55-4.499,5.999L7.5146,13.3594A5.5622,5.5622,0,0,1,6.375,10,5.625,5.625,0,1,1,16.5,13.375Z" />
        </g>
      </g>
    </svg>
  );
}
