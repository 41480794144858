import dynamic from 'next/dynamic';

const HotelLogo = dynamic<{ className: string }>(() =>
  import('./auahhhh/hotel-logo').then((i) => i.HotelLogo)
);

export const hotelLogoHeader = (
  <HotelLogo className="fill-current text-bg antialiased w-30 md:w-36 h-20" />
);

export const hotelLogoFooter = (
  <HotelLogo className="fill-current text-bg antialiased w-32 h-20" />
);
