import dynamic from 'next/dynamic';

const HotelLogo = dynamic<{ className?: string; fillColor?: string }>(() =>
  import('./hubwhhf/hotel-logo').then((i) => i.HotelLogo)
);

export const hotelLogoHeader = (
  <HotelLogo
    className="fill-current antialiased w-48 xl:w-64"
    fillColor="#796646"
  />
);

export const hotelLogoFooter = (
  <HotelLogo
    className="fill-current antialiased w-28 lg:w-40"
    fillColor="#FFF"
  />
);
