import React from 'react';
export function AV({
  className,
  fillColor,
}: {
  className?: string;
  fillColor?: string;
}) {
  return (
    <svg className={className} viewBox="0 0 24 24" style={{ fill: fillColor }}>
      <g data-name="Bounding Box">
        <path style={{ fill: 'none' }} d="M0,0V24H24V0Z" />
      </g>
      <g data-name="Icons Outlined">
        <g>
          <path d="M10.375,14A.3751.3751,0,0,0,10,13.625H6A.6256.6256,0,0,1,5.375,13V5.375h9.25V8a.375.375,0,0,0,.75,0V5.375H16a.375.375,0,0,0,0-.75H4a.375.375,0,0,0,0,.75h.625V13A1.377,1.377,0,0,0,6,14.375h4A.3751.3751,0,0,0,10.375,14Z" />
          <path d="M10.375,16.7988V15a.375.375,0,0,0-.75,0v1.7988L6.792,18.6875a.3754.3754,0,0,0,.416.625l2.417-1.6113V19a.375.375,0,0,0,.75,0V17.7012l2.417,1.6113a.3754.3754,0,0,0,.416-.625Z" />
          <path d="M16.3279,16.3094a.36.36,0,0,0,.06-.0443.3553.3553,0,0,0,.0433-.0547.2842.2842,0,0,0,.0573-.1307.2815.2815,0,0,0,.0022-.1459.3764.3764,0,0,0-.005-.0471L15.256,11.9893a2.3915,2.3915,0,0,0-.0319-1.5811.3512.3512,0,0,0-.0161-.0664l-.008-.011a2.62,2.62,0,0,0-5.075.9192,2.592,2.592,0,0,0,.34,1.2657c.0032.008.0022.0165.0059.0243.0043.0094.0142.013.0192.0218a2.626,2.626,0,0,0,1.3016,1.1259l1.8784,3.49a.3527.3527,0,0,0,.089.0924c.0172.0153.028.0375.0474.0493A.3748.3748,0,0,0,14,17.375a.38.38,0,0,0,.1592-.0352l2.1279-1A.3485.3485,0,0,0,16.3279,16.3094Zm-5.335-4.4254a1.8546,1.8546,0,0,1-.1179-.634,1.8689,1.8689,0,0,1,3.4928-.9312Zm.3929.6443,3.2176-1.4923a1.8418,1.8418,0,0,1,.0216.214,1.87,1.87,0,0,1-3.2392,1.2783Zm2.7759,3.9811-1.4183-2.6351.0066.0007a2.6088,2.6088,0,0,0,2.0118-.96l.91,2.8843Z" />
          <path d="M20,14.625A1.8767,1.8767,0,0,0,18.125,16.5v1a1.125,1.125,0,0,1-2.25,0,.375.375,0,0,0-.75,0,1.875,1.875,0,0,0,3.75,0v-1A1.1266,1.1266,0,0,1,20,15.375a.375.375,0,0,0,0-.75Z" />
        </g>
      </g>
    </svg>
  );
}
