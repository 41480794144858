import dynamic from 'next/dynamic';

const HotelLogo = dynamic<{ className: string; fillColor?: string }>(() =>
  import('./sjnhihh/hotel-logo').then((i) => i.HotelLogo)
);

export const hotelLogoHeader = (
  <HotelLogo className="fill-bg antialiased w-44 md:w-48 md:mr-5 h-24" />
);

export const hotelLogoFooter = (
  <HotelLogo className="fill-bg antialiased w-40 mr-2 md:w-48 md:mr-0 h-24" />
);
