import React, { useState, useContext } from 'react';
import { EventCalendarModalContentEvent } from './event-calendar-modal-content-event';
import { GenericModal } from '../global/generic-modal';
import { useTranslation } from 'next-i18next';
import { EventCalendarInfoContext } from './event-calendar-info-context';

interface DailyEventsLineItemProps {
  dateStringNom?: string;
  eventDateStr?: string;
  eventTitle?: string;
  eventTimes?: string;
  eventContent?: any;
  dateNumber?: number;
  disabledState?: boolean;
}

export function EventCalendarDailyEventsLineItem({
  dateStringNom,
  eventDateStr,
  eventTitle,
  eventTimes,
  eventContent,
  disabledState,
}: DailyEventsLineItemProps) {
  const [modalIsActive, setModalIsActive] = useState(false);
  const [entryContentForModal, setEntryContentForModal] = useState<any | null>(
    null
  );
  const { t } = useTranslation();
  const hotelInfoContext = useContext(EventCalendarInfoContext);
  const modalSettings = hotelInfoContext?.modalSettings;
  const eventTimesAdj = eventTimes
    .replace(/a.m./g, 'am')
    .replace(/p.m./g, 'pm');

  return (
    <>
      <button
        data-testid="dailyEventListItemBtn"
        aria-label={`${t(
          'calendar.viewEventInfo'
        )}  ${eventTitle}, ${eventTimes}`}
        aria-hidden={false}
        disabled={disabledState}
        className="bg-primary border border-primary text-white hover:text-primary hover:bg-white rounded px-2.5 py-1 mr-0 md:mr-2 text-xs flex flex-col-reverse md:flex-row-reverse my-2 md:my-0.5 calendar-additional-button"
        tabIndex={!disabledState ? 0 : -1}
        onClick={() => {
          setEntryContentForModal(eventContent);
          setModalIsActive(true);
        }}
      >
        <span className="block text-left md:text-center md:inline">
          {eventTitle}
        </span>
        <span className="font-bold mr-1 block md:inline text-left md:text-center">
          {eventTimesAdj}
        </span>
      </button>
      {modalIsActive && (
        <GenericModal
          modalChildWrapperStyles="w-5/6 sm:w-3/4 lg:w-1/2 flex justify-center items-center"
          modalChildStyles="w-full h-auto"
          modalSettings={modalSettings}
          setModalActive={setModalIsActive}
          modalActive={modalIsActive}
          modalTitle={eventTitle}
          modalChild={
            <EventCalendarModalContentEvent
              title={eventTitle}
              eventDateNumber={1}
              dateStringNom={dateStringNom}
              content={entryContentForModal}
              eventType="regular"
              hideICS={true}
            />
          }
        />
      )}
    </>
  );
}
