import React from 'react';
export function Home({
  className,
  fillColor,
}: {
  className?: string;
  fillColor?: string;
}) {
  return (
    <svg className={className} viewBox="0 0 24 24" style={{ fill: fillColor }}>
      <g data-name="Bounding Box">
        <path style={{ fill: 'none' }} d="M0,0V24H24V0Z" />
      </g>
      <g data-name="Icons Outlined">
        <g>
          <path d="M18,11.625a.3751.3751,0,0,0-.375.375v6.625h-3.25V15A.3751.3751,0,0,0,14,14.625H10A.3751.3751,0,0,0,9.625,15v3.625H6.375V12a.375.375,0,0,0-.75,0v7A.3751.3751,0,0,0,6,19.375h4A.3751.3751,0,0,0,10.375,19V15.375h3.25V19a.3751.3751,0,0,0,.375.375h4A.3751.3751,0,0,0,18.375,19V12A.3751.3751,0,0,0,18,11.625Z" />
          <path d="M20.2471,11.7178l-8-7a.3768.3768,0,0,0-.4942,0l-8,7a.3751.3751,0,1,0,.4942.5644L12,5.498l7.7529,6.7842a.3751.3751,0,0,0,.4942-.5644Z" />
        </g>
      </g>
    </svg>
  );
}
