import {
  RadioChecked,
  RadioDisabled,
  RadioUnchecked,
} from '@curated-property/icons';
import React, { forwardRef, ChangeEventHandler, ReactNode } from 'react';
import { slugify } from '../functions/helper';
import { WACEmailData, WACRadioStyles } from './interfaces';

interface WACStyledSelectProps {
  onChange?: ChangeEventHandler<HTMLSelectElement>;
  children: ReactNode;
  borderColor: string;
  testId?: string;
  id?: string;
}

// Use forwardRef to handle refs properly and type the component
export const WACStyledSelect = forwardRef<
  HTMLSelectElement,
  WACStyledSelectProps
>(({ id, testId, onChange, borderColor, children }, ref) => (
  <select
    id={id}
    data-testid={testId}
    onChange={onChange}
    ref={ref}
    className="p-2 rounded-lg border mb-4 form-select appearance-none"
    style={{
      borderColor,
    }}
  >
    {children}
  </select>
));

export const WACContainer: React.FC<{
  children?: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
  bgColor: string;
  dataElementId?: string;
}> = ({ className = '', style, children, bgColor, dataElementId }) => (
  <div
    data-element-id={dataElementId}
    className={`${className} py-6 px-4`}
    style={{
      ...style,
      background: bgColor,
    }}
  >
    {children}
  </div>
);

export const WACStyledRadio = function ({
  value,
  handler,
  checked,
  disabled,
  styles,
  label,
  name,
  autoFocus,
}: {
  value: string;
  label?: string;
  handler(val: string): void;
  checked?: boolean;
  disabled?: boolean;
  styles?: WACRadioStyles;
  name?: string;
  ref?: React.MutableRefObject<HTMLInputElement>;
  autoFocus?: boolean;
}) {
  let icon;
  let textColor = checked ? styles?.checked : styles?.unchecked;
  let scoreText = false;
  if (disabled) {
    scoreText = true;
    textColor = styles?.disabled;
    icon = (
      <RadioDisabled
        className="mx-1 relative top-1"
        fillColor={styles?.disabled}
      />
    );
  } else {
    icon = checked ? (
      <RadioChecked className="relative top-1" fillColor={styles?.checked} />
    ) : (
      <RadioUnchecked
        className="mx-1 relative top-1"
        fillColor={styles?.unchecked}
      />
    );
  }
  return (
    <div
      className="relative h-6 my-1"
      style={{
        textDecoration: scoreText ? 'line-through' : undefined,
        color: textColor,
      }}
    >
      <label
        className="flex justify-left"
        htmlFor={`wac-option-${slugify(value)}`}
      >
        {icon}
        <span className="ml-2">{label ?? value}</span>
      </label>
      <input
        className="appearance-none absolute top-1 left-1 w-4 h-4"
        disabled={disabled}
        checked={!disabled && checked}
        type="radio"
        value={value}
        id={`wac-option-${slugify(value)}`}
        name={`wac-option-${slugify(name)}`}
        onClick={(_e) => handler(value)}
        required
        autoFocus={autoFocus}
      />
    </div>
  );
};

export function generateEmailLink(
  emailData: WACEmailData,
  selectedDate: Date | null,
  selectedVenue: string,
  selectedTimeSlot: string
) {
  const subject = generateEmailLinkPlaceholderReplace(
    emailData?.title || '',
    selectedVenue,
    selectedTimeSlot,
    selectedDate
  );
  const body = generateEmailLinkPlaceholderReplace(
    emailData?.copy || '',
    selectedVenue,
    selectedTimeSlot,
    selectedDate
  );

  return `mailto:${emailData.address}?subject=${subject}&body=${body}`;
}

function generateEmailLinkPlaceholderReplace(
  s: string,
  selectedVenue: string,
  selectedTimeSlot: string,
  selectedDate?: Date | null
) {
  const dateString =
    selectedDate?.toLocaleDateString('en-US', {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    }) ?? '';
  return encodeURIComponent(
    s
      ?.replace('{selectedDate}', dateString)
      ?.replace('{selectedVenue}', selectedVenue)
      ?.replace('{selectedTimeSlot}', selectedTimeSlot)
  );
}
