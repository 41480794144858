import dynamic from 'next/dynamic';

const HotelLogo = dynamic<{ className: string; fillColor?: string }>(() =>
  import('./phxrsqq/hotel-logo').then((i) => i.HotelLogo)
);

export const hotelLogoHeader = (
  <HotelLogo
    className="fill-bg antialiased w-30 md:w-32 md:mr-5 h-24"
    fillColor="#000"
  />
);

export const hotelLogoFooter = (
  <HotelLogo className="fill-bg antialiased w-32 h-24" />
);
