import dynamic from 'next/dynamic';

const HotelLogo = dynamic<{ className: string }>(() =>
  import('./auhetci/hotel-logo').then((i) => i.HotelLogo)
);
const BrandLogo = dynamic<{ className: string }>(() =>
  import('./auhetci/brand-logo').then((i) => i.BrandLogo)
);

export const hotelLogoHeader = (
  <HotelLogo className="fill-current text-bg antialiased w-28 xl:w-42" />
);

export const hotelLogoFooter = (
  <BrandLogo className="fill-current text-bg antialiased w-[180px] md:w-48 h-12" />
);
