import React, { useState } from 'react';
import {
  CroppedImage,
  customLoader,
  appliedCloudinaryParams,
} from '@curated-property/utils';
import { ArrowHead, Arrow } from '@curated-property/icon-list';
import {
  GIS_merge,
  GIS_Padder,
  StyleObject,
} from './functions/global-instance-styles';
import { useTranslation } from 'next-i18next';
import { HandleAnimations } from './functions/helper';
import cx from 'classnames';

export interface GallerySliderProps {
  tileHeight?: string;
  tilePadding?: number;
  columnCount?: number;
  repeater?: {
    image?: {
      altText?: string;
      sourceUrl?: string;
    };
  }[];
  instanceStyles?: StyleObject;
  globalStyles?: StyleObject;
}

export function GallerySlider({
  columnCount,
  tileHeight,
  tilePadding,
  repeater,
  instanceStyles,
  globalStyles,
}: GallerySliderProps) {
  const [curInd, setCurInd] = useState(0);
  const { t } = useTranslation();
  const count = repeater?.length;
  const inlineStyles = GIS_merge(globalStyles, instanceStyles);
  const paddingStyles = GIS_Padder(
    inlineStyles?.paddingTop,
    inlineStyles?.paddingBottom
  );
  const imageHeight = tileHeight == 'portrait' ? '660' : '440';

  let columnStyles;
  switch (columnCount) {
    case 4:
      columnStyles = 'grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4';
      break;
    case 5:
      columnStyles = 'grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5';
      break;
    case 6:
      columnStyles = 'grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-6';
      break;
    default:
      columnStyles = 'grid-cols-1 sm:grid-cols-2 md:grid-cols-3';
      break;
  }

  const nextInd = curInd + 1 > count - 1 ? 0 : curInd + 1;
  const prevInd = curInd - 1 < 0 ? count - 1 : curInd - 1;

  const handleNav = (dir: boolean) => {
    if (!repeater || count <= 1) return null;
    setCurInd(dir ? nextInd : prevInd);
    if (dir) {
      repeater.push(repeater.shift());
    } else repeater.unshift(repeater.pop());
  };

  const animations = HandleAnimations({
    hideAnimation: inlineStyles?.hideAnimations !== 'show',
    start: inlineStyles?.animationDirection
      ? `${inlineStyles?.animationDirection}-8`
      : '-translate-y-8',
    delayOne: 'delay-100',
    delayTwo: 'delay-200',
    delayThree: 'delay-300',
  });

  return (
    <section
      data-element-id="gallery-slider-component"
      className={paddingStyles}
      style={{
        backgroundImage: inlineStyles?.componentBackgroundImage?.sourceUrl
          ? `url('${appliedCloudinaryParams(
              inlineStyles?.componentBackgroundImage?.sourceUrl,
              inlineStyles?.componentBackgroundRepeat
            )}')`
          : null,
        backgroundSize: inlineStyles?.componentBackgroundSize || 'cover',
        backgroundRepeat: inlineStyles?.componentBackgroundRepeat || '',
        backgroundPosition: inlineStyles?.componentBackgroundPosition || '',
        backgroundColor: inlineStyles?.componentBackgroundColor || '',
      }}
    >
      <div
        ref={animations?.ref}
        className="container px-0 flex flex-col"
        style={{
          backgroundImage: inlineStyles?.contentBackgroundImage?.sourceUrl
            ? `url('${appliedCloudinaryParams(
                inlineStyles?.contentBackgroundImage?.sourceUrl,
                inlineStyles?.contentBackgroundRepeat
              )}')`
            : '',
          backgroundColor: inlineStyles?.contentBackgroundColor || null,
          backgroundSize: inlineStyles?.contentBackgroundSize || 'cover',
          backgroundRepeat:
            inlineStyles?.contentBackgroundRepeat || 'no-repeat',
          backgroundPosition:
            inlineStyles?.contentBackgroundPosition || 'left center',
        }}
      >
        <div
          data-testid="sliderContainer"
          className={cx(
            columnStyles,
            'grid grid-rows-1 overflow-hidden relative'
          )}
          style={{ gridAutoRows: '0px', columnGap: `${tilePadding}px` }}
        >
          {/* Aria Live SR announcement */}
          <div className="sr-only" aria-live="polite">
            {t('slideAnnounce', {
              currentSlideNum: curInd + 1,
              totalSlides: repeater?.length || 0,
              slideTitle: repeater?.[curInd]?.image?.altText,
            })}
          </div>
          <div
            className={cx(
              'absolute z-1 top-0 w-full h-full px-5 flex justify-between items-center',
              animations?.one
            )}
          >
            <button
              className="h-12 w-12 flex justify-center items-center bg-bg"
              onClick={() => handleNav(false)}
              style={{
                backgroundColor: inlineStyles?.carouselControlsBackgroundColour,
              }}
              aria-label={t('movePreviousSlide')}
            >
              <ArrowHead
                color={inlineStyles?.carouselControlsArrowColour}
                className="h-8 fill-current transform rotate-90"
              />
            </button>
            <button
              className="h-12 w-12 flex justify-center items-center bg-bg"
              onClick={() => handleNav(true)}
              style={{
                backgroundColor: inlineStyles?.carouselControlsBackgroundColour,
              }}
              aria-label={t('moveNextSlide')}
            >
              <ArrowHead
                color={inlineStyles?.carouselControlsArrowColour}
                className="h-8 text-primary fill-current transform -rotate-90"
              />
            </button>
          </div>
          {repeater
            ?.filter((item) => item?.image)
            ?.map((img, key) => (
              <CroppedImage
                key={key}
                loader={() => {
                  return customLoader({
                    src: img?.image?.sourceUrl,
                    width: 660,
                    height: imageHeight,
                  });
                }}
                src={img?.image?.sourceUrl}
                className={cx('w-full', animations?.three)}
                style={{ transitionDelay: `${key + 1}00ms` }}
                layout="responsive"
                width={660}
                height={imageHeight}
                alt={img?.image?.altText}
              />
            ))}
        </div>
        <div className="flex items-center justify-end pt-5">
          <div
            className={cx('flex items-center', animations?.two)}
            style={{
              backgroundColor: inlineStyles?.imageCounterBackgroundColour,
            }}
          >
            <button
              data-testid="lowerPreviousButton"
              className="w-8 mr-7"
              onClick={() => handleNav(false)}
              aria-hidden={true}
              tabIndex={-1}
            >
              <Arrow
                className="fill-current text-primary w-8 rtl:transform rtl:rotate-180"
                fillColor={inlineStyles?.imageCounterTextColour}
              />
            </button>
            <span
              data-testid="carousel-current"
              className="text-2xl text-primary font-bold"
              style={{ color: inlineStyles?.imageCounterTextColour }}
              aria-hidden={true}
            >
              {curInd + 1}
            </span>
            <span
              className="text-2xl m-1 text-primary"
              style={{ color: inlineStyles?.imageCounterTextColour }}
              aria-hidden={true}
            >
              /
            </span>
            <span
              className="text-2xl text-primary"
              style={{ color: inlineStyles?.imageCounterTextColour }}
              aria-hidden={true}
            >
              {count}
            </span>
            <button
              data-testid="lowerNextButton"
              className="w-8 ml-7"
              onClick={() => handleNav(true)}
              aria-hidden={true}
              tabIndex={-1}
            >
              <Arrow
                className="fill-current text-primary w-8 transform rotate-180 rtl:rotate-0"
                fillColor={inlineStyles?.imageCounterTextColour}
              />
            </button>
          </div>
        </div>
      </div>
    </section>
  );
}
