export function Temperature({
  className,
  fillColor,
}: {
  className?: string;
  fillColor?: string;
}) {
  return (
    <svg
      className={className}
      x="0px"
      y="0px"
      viewBox="0 0 24 24"
      style={{ fill: fillColor }}
    >
      <path d="m0 0v24h24v-24z" fill="none" />
      <path d="m14.5 13.254v-8.254c0-1.379-1.122-2.5-2.5-2.5s-2.5 1.121-2.5 2.5v8.254c-1.241.837-2 2.246-2 3.746 0 2.481 2.019 4.5 4.5 4.5s4.5-2.019 4.5-4.5c0-1.5-.759-2.91-2-3.746zm-2.5 7.246c-1.93 0-3.5-1.57-3.5-3.5 0-1.262.655-2.397 1.752-3.038.154-.09.248-.254.248-.432v-8.53c0-.827.673-1.5 1.5-1.5s1.5.673 1.5 1.5v8.53c0 .178.094.342.248.432 1.097.641 1.752 1.776 1.752 3.038 0 1.93-1.57 3.5-3.5 3.5z" />
      <path d="m12.5 14.551v-9.551c0-.276-.224-.5-.5-.5s-.5.224-.5.5v9.551c-1.14.232-2 1.242-2 2.449 0 1.379 1.122 2.5 2.5 2.5s2.5-1.121 2.5-2.5c0-1.208-.86-2.217-2-2.449zm-.5 3.949c-.827 0-1.5-.673-1.5-1.5s.673-1.5 1.5-1.5 1.5.673 1.5 1.5-.673 1.5-1.5 1.5z" />
      <path d="m17 11.5h-1c-.276 0-.5.224-.5.5s.224.5.5.5h1c.276 0 .5-.224.5-.5s-.224-.5-.5-.5z" />
      <path d="m17 9.5h-1c-.276 0-.5.224-.5.5s.224.5.5.5h1c.276 0 .5-.224.5-.5s-.224-.5-.5-.5z" />
      <path d="m17 7.5h-1c-.276 0-.5.224-.5.5s.224.5.5.5h1c.276 0 .5-.224.5-.5s-.224-.5-.5-.5z" />
      <path d="m17 5.5h-1c-.276 0-.5.224-.5.5s.224.5.5.5h1c.276 0 .5-.224.5-.5s-.224-.5-.5-.5z" />
    </svg>
  );
}
