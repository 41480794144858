import React from 'react';
export function WeddingBird({
  className,
  fillColor,
}: {
  className?: string;
  fillColor?: string;
}) {
  return (
    <svg className={className} viewBox="0 0 24 24" style={{ fill: fillColor }}>
      <g data-name="Bounding Box">
        <path style={{ fill: 'none' }} d="M0,0V24H24V0Z" />
      </g>
      <g data-name="Icons Outlined">
        <g>
          <path d="M22.3633,10.9629a2.1169,2.1169,0,0,0-2.3369-1.8418l-.21.0225-.0215-.2061A2.1119,2.1119,0,0,0,17.4873,7.06a2.0657,2.0657,0,0,0-1.4043.7744,2.11,2.11,0,0,0-.45,1.5469l.0936.8627-.976-.3a2.6134,2.6134,0,0,0-2.5541-2.1407H12.19c-1.0713,0-2.0732.7881-2.8222,2.2188a.3747.3747,0,1,0,.664.3476C10.3877,9.69,11.14,8.5527,12.19,8.5527h.0039a2.0347,2.0347,0,0,1,1.9219,1.9688l.0059.01c.0008.0051-.0011.01,0,.015a9.6309,9.6309,0,0,1,.1318,1.6445,4.4,4.4,0,0,1-1.3232,3.14A4.4762,4.4762,0,0,1,9.7676,16.625H9.752a5.2176,5.2176,0,0,1-3.6563-1.6113.3634.3634,0,0,0-.3145-.1035l-1.8623.2587-1.0781-.9628,3.3037-.5186a.3737.3737,0,0,0,.2256-.6152c-2.1855-2.5244-1.9814-4.5547-1.7324-5.43l5.9424,5.35a.3748.3748,0,1,0,.5019-.5567L4.7344,6.7217A.3869.3869,0,0,0,4.4209,6.63a.3773.3773,0,0,0-.2666.1894c-.0625.1153-1.4492,2.7442,1.2285,6.2286l-3.44.54a.3746.3746,0,0,0-.1924.6494l1.7988,1.6065a.37.37,0,0,0,.3018.0917l1.8545-.2578A5.906,5.906,0,0,0,9.75,17.375h.0176a5.2235,5.2235,0,0,0,3.6894-1.51,5.1421,5.1421,0,0,0,1.5469-3.6718c.0005-.1268-.003-.42-.0248-.7686l.8333-.3887.25,2.3057a.376.376,0,0,0,.373.335c.0127,0,.0264-.001.0391-.002l3.9951-.4209a2.1307,2.1307,0,0,0,1.4453-.7851A2.0328,2.0328,0,0,0,22.3633,10.9629ZM21.3311,11.998a1.3816,1.3816,0,0,1-.9395.51l-3.624.3818-.3887-3.5888a1.3566,1.3566,0,0,1,.291-.999,1.3176,1.3176,0,0,1,.8965-.4961,1.3434,1.3434,0,0,1,1.4824,1.2129l.0616.5781a.3775.3775,0,0,0,.1376.2519.3939.3939,0,0,0,.2754.0811l.5821-.0625a1.3515,1.3515,0,0,1,1.5117,1.1758A1.29,1.29,0,0,1,21.3311,11.998Z" />
          <path d="M13.1084,10.1416a.5611.5611,0,1,0-.56.56A.5605.5605,0,0,0,13.1084,10.1416Z" />
        </g>
      </g>
    </svg>
  );
}
