import React from 'react';
export function ArrowHeadCircle({
  className,
  fillColor,
  bgColor,
  arrowColor,
  fillBg,
}: {
  className?: string;
  fillColor?: string;
  bgColor?: string;
  arrowColor?: string;
  fillBg?: boolean;
}) {
  return (
    <svg className={className} viewBox="0 0 24 24" style={{ fill: fillColor }}>
      <g data-name="Bounding Box">
        <path style={{ fill: 'none' }} d="M0,0V24H24V0Z" />
      </g>
      <g data-name="Icons Outlined">
        <g>
          <path
            fillOpacity={bgColor ? 0.9 : 0.7}
            fill={bgColor}
            d={`M12,3.625A8.375,8.375,0,1,0,20.375,12,8.3842,8.3842,0,0,0,12,3.625Z${
              fillBg
                ? ''
                : 'm0,16A7.625,7.625,0,1,1,19.625,12,7.6341,7.6341,0,0,1,12,19.625Z'
            }`}
          />
          <path
            fill={arrowColor ? arrowColor : '#fff'}
            d="M15.7344,9.7344,12,13.47,8.2656,9.7344a.3756.3756,0,0,0-.5312.5312l4,4a.3771.3771,0,0,0,.5312,0l4-4a.3756.3756,0,0,0-.5312-.5312Z"
          />
        </g>
      </g>
    </svg>
  );
}
