import React from 'react';
export function Hotel({
  className,
  fillColor,
}: {
  className?: string;
  fillColor?: string;
}) {
  return (
    <svg className={className} viewBox="0 0 24 24" style={{ fill: fillColor }}>
      <g data-name="Bounding Box">
        <path style={{ fill: 'none' }} d="M0,0V24H24V0Z" />
      </g>
      <g data-name="Icons Outlined">
        <g>
          <path d="M14,6.375A.3751.3751,0,0,0,14.375,6V5.375h1.25V6a.375.375,0,0,0,.75,0V4a.375.375,0,0,0-.75,0v.625h-1.25V4a.375.375,0,0,0-.75,0V6A.3751.3751,0,0,0,14,6.375Z" />
          <path d="M17,17.625H13a.375.375,0,0,0,0,.75h4a.375.375,0,0,0,0-.75Z" />
          <path d="M9,13.625H6A1.377,1.377,0,0,0,4.625,15v5a.375.375,0,0,0,.75,0V15A.6256.6256,0,0,1,6,14.375H9a.375.375,0,0,0,0-.75Z" />
          <path d="M18,7.625H12A1.377,1.377,0,0,0,10.625,9V20a.375.375,0,0,0,.75,0V9A.6256.6256,0,0,1,12,8.375h6A.6256.6256,0,0,1,18.625,9V20a.375.375,0,0,0,.75,0V9A1.377,1.377,0,0,0,18,7.625Z" />
          <path d="M17,15.625H13a.375.375,0,0,0,0,.75h4a.375.375,0,0,0,0-.75Z" />
          <path d="M17,13.625H13a.375.375,0,0,0,0,.75h4a.375.375,0,0,0,0-.75Z" />
          <path d="M17,11.625H13a.375.375,0,0,0,0,.75h4a.375.375,0,0,0,0-.75Z" />
          <path d="M17,9.625H13a.375.375,0,0,0,0,.75h4a.375.375,0,0,0,0-.75Z" />
          <path d="M9,17.625H7a.375.375,0,0,0,0,.75H9a.375.375,0,0,0,0-.75Z" />
          <path d="M9,15.625H7a.375.375,0,0,0,0,.75H9a.375.375,0,0,0,0-.75Z" />
        </g>
      </g>
    </svg>
  );
}
