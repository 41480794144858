import * as hotelOverrides from '../../../component-overrides';
import {
  getStaticPaths,
  getStaticProps,
} from '../../../utils/static-paths-and-props-functions';
import {
  defaultComponentMapping,
  PageProps,
} from '../../../mapping/default-props-to-components';
import { WordpressPageInfoQuery } from '../../../generated/wp';

export default function Slug(props) {
  const thisHotelComponentOverrides =
    hotelOverrides[props.ctyhocn.toLowerCase()] || {};
  const Comp = makeSlugComponent(thisHotelComponentOverrides);
  return (
    <Comp {...props} googleMapsKey={process.env.NEXT_PUBLIC_GOOGLE_MAPS_KEY} />
  );
}

/** Moved from Shared-Pages */
export type ArrayElement<A> = A extends readonly (infer T)[] ? T : never;
export type ExtractUnion<U, T> = Extract<ArrayElement<U>, T>;
type FlexComponent = { __typename: string | undefined } | undefined;
export type SlugConfig<
  CT extends FlexComponent[] | undefined,
  PageProps,
  T extends string = CT[0]['__typename']
> = {
  [P in T]: (
    props: ExtractUnion<CT, { __typename: P }>,
    pageProps: PageProps,
    key: number
  ) => JSX.Element;
};

export type SlugProps = PageProps & {
  googleMapsKey: string;
  isPreview: boolean;
};

export function makeSlugComponent<WP extends WordpressPageInfoQuery>(
  overrides?: Partial<
    SlugConfig<
      NonNullable<NonNullable<WP['page']>['acfFlexible']>['flexibleComponents'],
      PageProps
    >
  >
) {
  const config = { ...defaultComponentMapping, ...overrides };

  return function Slug(props: SlugProps) {
    // todo this path must be known to work
    const flexFields =
      props?.wpPageInfo?.page?.acfFlexible?.flexibleComponents || [];
    const contents = (
      <div id="main">
        {flexFields?.map((item: { __typename: string }, index) => {
          return config?.[item?.__typename]?.(
            item,
            props,
            index
          ) as JSX.Element;
        })}
      </div>
    );
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return (
      <>
        {contents}
        {props.isPreview && (
          <div className="fixed bottom-0 py-2 px-4">
            <a
              href="/api/exit-preview"
              className="bg-primary inline-block p-4 text-bg font-bold rounded-sm shadow-lg border-bg border"
            >
              Exit Preview Mode
            </a>
          </div>
        )}
      </>
    );
  };
}

export { getStaticPaths, getStaticProps };
