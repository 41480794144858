import React, { useState } from 'react';
import { HeadingStyle } from './includes/heading-style';
import { customLoader, imageUrl } from '@curated-property/utils';
import styles from './css/tabbed-table-data.module.css';
import cx from 'classnames';
import Img from 'next/legacy/image';
import { MagnifyingGlass } from '@curated-property/icons';
import { GenericModal } from './global/generic-modal';
import {
  GIS_Array,
  GIS_Padder,
  StyleObject,
} from './functions/global-instance-styles';
import {
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  TabsKeyboardActivation,
} from '@reach/tabs';
import { HandleWYSIWYGContent } from './functions/helper';
import { useTranslation } from 'next-i18next';

export interface TabbedSectionProps {
  header?: string;
  menuRepeater?: {
    menuName?: string;
    gallery?: {
      altText?: string;
      sourceUrl?: string;
      mediaDetails?: {
        height?: number;
        width?: number;
      };
    }[];
    contentSelection?: string;
    menuColumn?: {
      menuHeader?: string;
      menuItems?: string;
    }[];
    sectionTableColumns?: {
      column1?: string;
      column2?: string;
      column3?: string;
      column4?: string;
      column5?: string;
      column6?: string;
      column7?: string;
      column8?: string;
    }[];
    tableHeadingSpan?: {
      columnSpan?: number;
      text?: string;
    }[];
  }[];
  globalStyles?: StyleObject;
  instanceStyles?: StyleObject;
  modalSettings?: any;
}

export function TabbedSection({
  header,
  menuRepeater,
  globalStyles,
  instanceStyles,
  modalSettings,
}: TabbedSectionProps) {
  const [clicked, setClicked] = useState(0);
  const [active, setActive] = useState(true);
  const [lastFocussed, setLastFocussed] = useState<any | null>(null);
  const [activeImage, setActiveImage] = useState({
    url: null,
    alt: null,
  });
  const [modalActive, setModalActive] = useState(false);
  const tabbedSectionID = header?.replace(/ /g, '-').toLowerCase();
  const handleTabsChange = (index: number) => {
    setClicked(index);
  };

  const inlineStyles = GIS_Array(globalStyles, instanceStyles);
  const paddingStyles = GIS_Padder(
    inlineStyles?.paddingTop,
    inlineStyles?.paddingBottom
  );

  return (
    <div
      data-testid="tabTableSection"
      className={cx(
        'bg-bg-alt cp-tabbedTableData',
        styles.cpTabbedStyles,
        inlineStyles?.showHide && 'hidden'
      )}
      style={{
        backgroundImage: inlineStyles?.componentBackgroundImage
          ? `url('${inlineStyles?.componentBackgroundImage}')`
          : undefined,
        backgroundSize: inlineStyles?.componentBackgroundSize || undefined,
        backgroundRepeat:
          inlineStyles?.componentBackgroundRepeat || 'no-repeat',
        backgroundPosition:
          inlineStyles?.componentBackgroundPosition || 'left center',
        backgroundColor: inlineStyles?.componentBackgroundColor,
      }}
    >
      <div
        className={cx('container', paddingStyles)}
        style={{
          backgroundImage: inlineStyles?.contentBackgroundImage
            ? `url('${inlineStyles?.contentBackgroundImage}')`
            : undefined,
          backgroundSize: inlineStyles?.contentBackgroundSize || undefined,
          backgroundRepeat:
            inlineStyles?.contentBackgroundRepeat || 'no-repeat',
          backgroundPosition:
            inlineStyles?.contentBackgroundPosition || 'left center',
          backgroundColor: inlineStyles?.contentBackgroundColor,
        }}
      >
        {header && (
          <HeadingStyle
            id={tabbedSectionID}
            text={header}
            type="h2"
            className="pb-12 text-center text-inverse"
            textColorInline={inlineStyles?.titleColor}
          />
        )}
        <div>
          <Tabs
            index={clicked}
            onChange={handleTabsChange}
            keyboardActivation={TabsKeyboardActivation.Auto}
          >
            {(menuRepeater?.length || 0) > 1 ? (
              <TabList className="w-full border-b-2 border-text-disabled flex flex-wrap md:flex-nowrap justify-center">
                {menuRepeater?.map((item, key) => {
                  return (
                    <MenuTab
                      key={key}
                      title={item?.menuName}
                      textColor={inlineStyles?.subtitleColor}
                      isClicked={clicked === key ? true : false}
                      activeTabAccentColour={
                        clicked === key
                          ? inlineStyles?.activeTabAccentColour
                          : null
                      }
                      activeTabTextColour={
                        clicked === key
                          ? inlineStyles?.activeTabTextColour
                          : null
                      }
                    />
                  );
                })}
              </TabList>
            ) : null}
            <TabPanels className="w-full">
              {(menuRepeater?.length || 0) > 0
                ? menuRepeater?.map((item, key) => {
                    return (
                      <TabPanel
                        key={key}
                        className="overflow-scroll md:overflow-auto w-full"
                      >
                        <div className="flex flex-wrap justify-between py-4 gap-6">
                          <div
                            className={cx(
                              'flex flex-wrap lg:flex-nowrap justify-center w-full',
                              {
                                'opacity-100 transition-all duration-300 ease-in':
                                  active,
                                'opacity-0': !active,
                              }
                            )}
                          >
                            {menuRepeater?.[clicked]?.gallery?.map(
                              (item, key) => {
                                return (
                                  <TabbedGalleryImages
                                    key={key}
                                    image={item?.sourceUrl}
                                    altText={item?.altText}
                                    setModalActive={setModalActive}
                                    setActiveImage={setActiveImage}
                                    setLastFocussed={setLastFocussed}
                                  />
                                );
                              }
                            )}
                          </div>

                          {menuRepeater?.[clicked]?.contentSelection ===
                          'WISYWIG' ? (
                            menuRepeater?.[clicked]?.menuColumn?.map(
                              (col, key) => {
                                return (
                                  <MenuPanel
                                    key={key}
                                    colTitle={col?.menuHeader}
                                    colItems={col?.menuItems}
                                  />
                                );
                              }
                            )
                          ) : (
                            <TableSection
                              repeater={
                                menuRepeater?.[clicked]?.sectionTableColumns
                              }
                              headingSpan={
                                menuRepeater?.[clicked]?.tableHeadingSpan
                              }
                              textColor={inlineStyles?.textColor}
                              textAlignment={inlineStyles?.textAlignment}
                              headerBackground={
                                inlineStyles?.tableHeaderBackgroundColour
                              }
                              headerTextColor={
                                inlineStyles?.tableHeaderTextColour
                              }
                              rowTextColor={inlineStyles?.tableRowTextColour}
                              rowBackgroundColor={
                                inlineStyles?.tableRowBackgroundColour
                              }
                              altRowTextColor={
                                inlineStyles?.tableAltRowTextColour
                              }
                              altRowBackgroundColor={
                                inlineStyles?.tableAltRowBackgroundColour
                              }
                            />
                          )}
                        </div>
                      </TabPanel>
                    );
                  })
                : false}
              {modalActive ? (
                <GenericModal
                  modalTitle={menuRepeater?.[clicked]?.menuName || ''}
                  modalStyles="justify-center lg:justify-start align-center py-12 overflow-auto"
                  modalChild={
                    <img
                      src={activeImage?.url || ''}
                      className=""
                      alt={activeImage?.alt || ''}
                    />
                  }
                  modalChildStyles="max-w-screen-lg"
                  setModalActive={setModalActive}
                  modalActive={modalActive}
                  modalSettings={modalSettings}
                />
              ) : (
                false
              )}
            </TabPanels>
          </Tabs>
        </div>
      </div>
    </div>
  );
}

interface TabProps {
  title?: string;
  textColor?: string;
  isClicked?: boolean;
  activeTabAccentColour?: string;
  activeTabTextColour?: string;
}

function MenuTab({
  title,
  textColor,
  isClicked,
  activeTabAccentColour,
  activeTabTextColour,
}: TabProps) {
  return (
    <Tab
      style={{
        color: activeTabTextColour || textColor || undefined,
        borderBottom: isClicked
          ? `4px solid ${activeTabAccentColour}`
          : undefined,
      }}
    >
      {title}
    </Tab>
  );
}

interface PanelProps {
  colTitle?: string;
  colItems?: string;
}

function MenuPanel({ colTitle, colItems }: PanelProps) {
  return (
    <div className="w-full sm:w-5/12 lg:max-w-xs lg:w-full">
      {colTitle ? (
        <HeadingStyle
          text={colTitle}
          type="h3"
          className="pb-12 text-center text-inverse text"
        />
      ) : (
        ''
      )}
      <div
        className={cx(styles.menuList, {
          'pt-0 lg:pt-12': !colTitle,
          'pt-4': colTitle,
        })}
        dangerouslySetInnerHTML={{
          __html: HandleWYSIWYGContent(colItems),
        }}
      ></div>
    </div>
  );
}

interface TableProps {
  repeater?: any;
  headingSpan?: {
    columnSpan?: number;
    text?: string;
  }[];
  textColor?: string;
  textAlignment?: any;
  headerBackground?: string;
  headerTextColor?: string;
  rowTextColor?: string;
  rowBackgroundColor?: string;
  altRowTextColor?: string;
  altRowBackgroundColor?: string;
}

function TableSection({
  repeater,
  textColor,
  rowTextColor,
  rowBackgroundColor,
  altRowTextColor,
  altRowBackgroundColor,
  textAlignment,
  headerBackground,
  headerTextColor,
}: TableProps) {
  let bgAlt = '';

  const tableRows = repeater?.map(
    (
      item: NonNullable<
        NonNullable<
          TabbedSectionProps['menuRepeater']
        >[number]['sectionTableColumns']
      >[number],
      key: number
    ) => {
      if (key % 2 === 0) {
        bgAlt = 'bg-border-alt';
      } else {
        bgAlt = '';
      }
      const rowtextColourDecider =
        key % 2 === 0 ? altRowTextColor : rowTextColor;
      if (key === 0) {
        return (
          <thead key={key}>
            <tr
              className="text-center bg-primary-alt text-bg font-bold"
              style={{ textAlign: textAlignment, background: headerBackground }}
            >
              {item.column1 ? (
                <th
                  scope="col"
                  className="p-2"
                  style={{ color: headerTextColor }}
                >
                  {item.column1}
                </th>
              ) : null}
              {item.column2 ? (
                <th scope="col" style={{ color: headerTextColor }}>
                  {item.column2}
                </th>
              ) : null}
              {item.column3 ? (
                <th scope="col" style={{ color: headerTextColor }}>
                  {item.column3}
                </th>
              ) : null}
              {item.column4 ? (
                <th scope="col" style={{ color: headerTextColor }}>
                  {item.column4}
                </th>
              ) : null}
              {item.column5 ? (
                <th scope="col" style={{ color: headerTextColor }}>
                  {item.column5}
                </th>
              ) : null}
              {item.column6 ? (
                <th scope="col" style={{ color: headerTextColor }}>
                  {item.column6}
                </th>
              ) : null}
              {item.column7 ? (
                <th scope="col" style={{ color: headerTextColor }}>
                  {item.column7}
                </th>
              ) : null}
              {item.column8 ? (
                <th scope="col" style={{ color: headerTextColor }}>
                  {item.column8}
                </th>
              ) : null}
            </tr>
          </thead>
        );
      } else {
        return (
          <tbody key={key} className="text-center">
            <tr
              className={bgAlt}
              style={{
                textAlign: textAlignment,
                background:
                  key % 2 === 0 ? altRowBackgroundColor : rowBackgroundColor,
                color: key % 2 === 0 ? altRowTextColor : rowTextColor,
              }}
            >
              {item.column1 ? (
                <th
                  scope="row"
                  className="p-2"
                  style={{ color: rowtextColourDecider || textColor }}
                >
                  {item.column1}
                </th>
              ) : null}
              {item.column2 ? (
                <td style={{ color: rowtextColourDecider || textColor }}>
                  {item.column2}
                </td>
              ) : null}
              {item.column3 ? (
                <td style={{ color: rowtextColourDecider || textColor }}>
                  {item.column3}
                </td>
              ) : null}
              {item.column4 ? (
                <td style={{ color: rowtextColourDecider || textColor }}>
                  {item.column4}
                </td>
              ) : null}
              {item.column5 ? (
                <td style={{ color: rowtextColourDecider || textColor }}>
                  {item.column5}
                </td>
              ) : null}
              {item.column6 ? (
                <td style={{ color: rowtextColourDecider || textColor }}>
                  {item.column6}
                </td>
              ) : null}
              {item.column7 ? (
                <td style={{ color: rowtextColourDecider || textColor }}>
                  {item.column7}
                </td>
              ) : null}
              {item.column8 ? (
                <td style={{ color: rowtextColourDecider || textColor }}>
                  {item.column8}
                </td>
              ) : null}
            </tr>
          </tbody>
        );
      }
    }
  );

  return (
    <table className={cx('w-full mt-16', styles.mobileWidth1024)}>
      {tableRows}
    </table>
  );
}

interface GalleryProps {
  image?: string;
  altText?: string;
  setModalActive?: any;
  setActiveImage?: any;
  setLastFocussed?: any;
}

function TabbedGalleryImages({
  image,
  setActiveImage,
  setModalActive,
  altText,
  setLastFocussed,
}: GalleryProps) {
  const [hovered, setHovered] = useState(false);
  const { t } = useTranslation();

  return (
    <button
      data-testid="gallery-img-btn"
      className="relative overflow-hidden w-full md:w-1/2 lg:w-1/3"
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onFocus={() => setHovered(true)}
      onBlur={() => setHovered(false)}
      onClick={(e) => {
        setModalActive(true);
        setActiveImage({
          url: image,
          alt: altText,
        });
        setLastFocussed(e.target);
      }}
      type="button"
      aria-label={`${t('openModal')} ${altText || t('image')}`}
    >
      <Img
        loader={customLoader}
        src={imageUrl(image || undefined)}
        alt={altText}
        width={700}
        height={560}
        layout="responsive"
        className="w-full"
      />
      <span
        className={cx(
          'absolute top-0 left-0 w-full h-full flex items-center justify-center bg-text opacity-80 transform transition-all duration-200 ease-in-out',
          {
            '-translate-y-full': !hovered,
            '-translate-y-0': hovered,
          }
        )}
      >
        <MagnifyingGlass
          className={cx(
            'text-bg fill-current w-12 transform transition-all delay-100 duration-300 ease-in-out',
            {
              'opacity-0 -rotate-180 scale-0': !hovered,
              'opacity-100 rotate-0 scale-100': hovered,
            }
          )}
        />
      </span>
    </button>
  );
}
