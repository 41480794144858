import { FooterProps } from '@curated-property/shared-components';
import dynamic from 'next/dynamic';

// this override footer
const SharedFooter = dynamic<FooterProps>(() =>
  import('@curated-property/shared-components').then((f) => f.Footer)
);

export const Footer = (props: FooterProps) => {
  return (
    <SharedFooter
      {...props}
      styleOptions={{
        ...props?.styleOptions,
        linkStyles: {
          underline: true,
          fontSize: 'base-text',
        },
        columnStyles: {
          columnAlign: 'top',
          columnOne: {
            fontSize: 'text-base leading-8',
            logoPadding: 'px-4 py-8 sm:py-4 sm:px-4',
            logoClasses: 'border-C4C4C4',
            copyClasses: 'leading-8',
          },
          columnTwo: {
            padding: 'pt-8 sm:p-8 xl:p-0 leading-loose',
          },
          columnThree: {
            width: 'max-w-2xl',
          },
        },
      }}
    />
  );
};
