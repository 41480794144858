import dynamic from 'next/dynamic';

const HotelLogo = dynamic<{ className: string; fillColor?: string }>(() =>
  import('./atlwcsa/hotel-logo').then((i) => i.HotelLogo)
);

export const hotelLogoHeader = (
  <HotelLogo
    className="fill-current text-current antialiased w-36 sm:w-48 lg:mr-5"
    fillColor="#0F224A"
  />
);

export const hotelLogoFooter = (
  <HotelLogo
    className="fill-current text-current antialiased w-36 mr-2 sm:w-48 sm:mr-0"
    fillColor="#FFFFFF"
  />
);
