export function Wellness({
  className,
  fillColor,
}: {
  className?: string;
  fillColor?: string;
}) {
  return (
    <svg
      className={className}
      x="0px"
      y="0px"
      viewBox="0 0 24 24"
      style={{ fill: fillColor }}
    >
      <path d="m12.25 7c1.241 0 2.25-1.01 2.25-2.25s-1.009-2.25-2.25-2.25-2.25 1.01-2.25 2.25 1.009 2.25 2.25 2.25zm0-3.5c.689 0 1.25.561 1.25 1.25s-.561 1.25-1.25 1.25-1.25-.561-1.25-1.25.561-1.25 1.25-1.25z" />
      <path d="m19 7.5h-14c-.827 0-1.5.673-1.5 1.5s.673 1.5 1.5 1.5h4.199l-3.89 7.945c-.245.431-.242.942.007 1.371.249.428.693.684 1.188.684.48 0 .932-.256 1.179-.668l2.745-4.575c.142-.236.065-.544-.171-.686-.237-.144-.543-.065-.686.172l-2.745 4.575c-.067.112-.191.182-.322.182-.194 0-.292-.131-.324-.187-.033-.057-.098-.205.013-.401l4.256-8.692c.076-.155.066-.338-.025-.484s-.252-.235-.424-.235h-5c-.276 0-.5-.225-.5-.5s.224-.5.5-.5h14c.276 0 .5.225.5.5s-.224.5-.5.5h-5c-.215 0-.406.138-.474.342l-1 3c-.051.152-.025.32.068.45.094.131.245.208.406.208h2.933c.369.112.567.409.567 1.5v4c0 .275-.224.5-.5.5s-.5-.225-.5-.5v-4c0-.276-.224-.5-.5-.5h-3c-.276 0-.5.224-.5.5s.224.5.5.5h2.5v3.5c0 .827.673 1.5 1.5 1.5s1.5-.673 1.5-1.5v-4c0-.694 0-2.141-1.379-2.485-.04-.01-.08-.015-.121-.015h-2.306l.667-2h4.64c.827 0 1.5-.673 1.5-1.5s-.673-1.5-1.5-1.5z" />
    </svg>
  );
}
