import dynamic from 'next/dynamic';

const HotelLogo = dynamic<{ className?: string; fillColor?: string }>(() =>
  import('./sezlbhi/hotel-logo').then((i) => i.HotelLogo)
);

export const hotelLogoHeader = (
  <HotelLogo className="antialiased w-32 p-4" fillColor="#ffffff" />
);

export const hotelLogoFooter = (
  <HotelLogo className="antialiased w-32 px-4" fillColor="#ffffff" />
);
