import React, { ReactChild } from 'react';
import cx from 'classnames';
import {
  GIS_Array,
  GIS_TextAlignment,
  GIS_Padder,
  StyleObject,
} from './functions/global-instance-styles';
import { iconmapper, HandleAnimations } from './functions/helper';
import { appliedCloudinaryParams } from '@curated-property/utils';
import { sanitize } from '@curated-property/utils';
import { Close } from '@curated-property/icon-list';
import { HandleWYSIWYGContent } from './functions/helper';
export type IconBlockIconKeyType = keyof typeof iconmapper;

export interface IconBlockProps {
  header?: string;
  headerCopy?: string;
  columnCount?: string;
  iconSize?: string;
  repeater?: {
    iconList?: IconBlockIconKeyType | string;
    iconLabel?: string;
    iconColor?: string;
    labelColor?: string;
  }[];
  globalStyles?: StyleObject;
  instanceStyles?: StyleObject;
}

export function IconBlock({
  header,
  headerCopy,
  columnCount,
  iconSize,
  repeater,
  globalStyles,
  instanceStyles,
}: IconBlockProps) {
  const inlineStyles = GIS_Array(globalStyles, instanceStyles);
  const paddingStyles = GIS_Padder(
    inlineStyles?.paddingTop,
    inlineStyles?.paddingBottom
  );
  const iconMap = iconmapper();
  let flexColumns: string;
  switch (columnCount) {
    case '3':
      flexColumns = 'w-1/2 sm:w-1/3';
      break;
    case '4':
      flexColumns = 'w-1/2 sm:w-1/3 md:w-1/4';
      break;
    case '5':
      flexColumns = 'w-1/2 sm:w-1/3 md:w-1/4 lg:w-1/5';
      break;
    case '6':
      flexColumns = 'w-1/2 sm:w-1/3 md:w-1/4 lg:w-1/6';
      break;
    default:
      flexColumns = 'w-1/2 sm:w-1/3 md:w-1/4 lg:w-1/6';
      break;
  }

  let iconSizes: string;
  switch (iconSize) {
    case 'medium':
      iconSizes = 'h-8 w-8 md:h-12 md:w-12 lg:h-20 lg:w-20';
      break;
    case 'large':
      iconSizes = 'h-8 w-8 md:h-16 md:w-16 lg:h-24 lg:w-24';
      break;
    default:
      iconSizes = 'h-8 w-8 lg:h-16 lg:w-16';
      break;
  }
  const hideAnimations = inlineStyles?.hideAnimations !== 'show';
  const animations = HandleAnimations({
    hideAnimation: hideAnimations,
    start: `${inlineStyles?.animationDirection ?? '-translate-x'}-8`,
    delayOne: 'delay-200',
    delayTwo: 'delay-300',
  });

  return (
    <div
      style={{
        backgroundImage: inlineStyles?.componentBackgroundImage
          ? `url('${appliedCloudinaryParams(
              inlineStyles?.componentBackgroundImage,
              inlineStyles?.componentBackgroundRepeat
            )}')`
          : undefined,
        backgroundSize: inlineStyles?.componentBackgroundSize || 'cover',
        backgroundRepeat: inlineStyles?.componentBackgroundRepeat || undefined,
        backgroundPosition:
          inlineStyles?.componentBackgroundPosition || undefined,
        backgroundColor: inlineStyles?.componentBackgroundColor || undefined,
      }}
      data-element-id="icon-grid-wrapper"
    >
      <div
        className={cx('container', paddingStyles)}
        style={{
          backgroundImage: inlineStyles?.contentBackgroundImage
            ? `url('${appliedCloudinaryParams(
                inlineStyles?.contentBackgroundImage,
                inlineStyles?.contentBackgroundRepeat
              )}')`
            : undefined,
          backgroundSize: inlineStyles?.contentBackgroundSize || 'cover',
          backgroundRepeat: inlineStyles?.contentBackgroundRepeat || undefined,
          backgroundPosition:
            inlineStyles?.contentBackgroundPosition || undefined,
          backgroundColor: inlineStyles?.contentBackgroundColor || undefined,
        }}
      >
        <div ref={animations?.ref} className="text-center">
          {header ? (
            <h2
              data-testid="iconHeader"
              className={cx(
                'font-headline text-2xl md:text-4xl font-black leading-none mb-6',
                animations?.one
              )}
              dangerouslySetInnerHTML={{
                __html: sanitize(header || ''),
              }}
              style={{
                color: inlineStyles?.titleColor,
              }}
            ></h2>
          ) : null}
          {headerCopy ? (
            <div
              data-testid="iconCopy"
              className={cx('mb-8 text-lg', animations?.two)}
              dangerouslySetInnerHTML={{
                __html: HandleWYSIWYGContent(
                  headerCopy,
                  inlineStyles?.textColor
                ),
              }}
              style={{
                color: inlineStyles?.textColor,
              }}
            ></div>
          ) : null}
        </div>
        <div className={`py-4 md:py-8 flex flex-wrap justify-center`}>
          {repeater?.map((icon, key) => {
            const Icon = iconMap[icon?.iconList as keyof typeof iconMap];
            return (
              <IconBlockItem
                key={key}
                icon={
                  Icon ? (
                    <Icon
                      className={iconSizes}
                      fillColor={
                        icon?.iconColor || inlineStyles?.iconBlockColour
                      }
                    />
                  ) : (
                    <Close
                      className={iconSizes}
                      fillColor={
                        icon?.iconColor || inlineStyles?.iconBlockColour
                      }
                    />
                  )
                }
                iconLabel={icon?.iconLabel}
                itemAlignment={inlineStyles?.textAlignment}
                iconColor={icon?.iconColor || inlineStyles?.iconBlockColour}
                labelColor={icon?.labelColor || inlineStyles?.labelBlockColour}
                className={flexColumns}
                hideAnimations={hideAnimations}
                animationDirection={inlineStyles?.animationDirection}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}

interface IconProps {
  icon?: ReactChild;
  iconLabel?: string;
  itemAlignment?: string;
  iconColor?: string;
  labelColor?: string;
  className?: string;
  hideAnimations?: boolean;
  animationDirection?: string;
}

function IconBlockItem({
  icon,
  iconLabel,
  itemAlignment,
  iconColor,
  labelColor,
  className,
  hideAnimations,
  animationDirection,
}: IconProps) {
  const textAlignment = GIS_TextAlignment(itemAlignment);

  const animations = HandleAnimations({
    hideAnimation: hideAnimations,
    start: `${animationDirection ?? '-translate-y'}-8`,
    end: 'translate-y-0',
    delayOne: 'delay-100',
  });
  return (
    <div
      ref={animations?.ref}
      data-testid="iconBlockItem"
      className={cx(
        'flex flex-col items-center text-center my-2 grow',
        className,
        animations?.one
      )}
      style={{
        textAlign: textAlignment || 'center',
        color: iconColor,
        justifyItems: itemAlignment,
      }}
    >
      <div className="max-w-32 margin-auto">
        <div aria-hidden="true" className="w-full flex justify-center">
          {icon}
        </div>
        <p
          style={{ color: labelColor }}
          className="w-full text-lg px-2"
          dangerouslySetInnerHTML={{
            __html: sanitize(iconLabel || ''),
          }}
        />
      </div>
    </div>
  );
}
