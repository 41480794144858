import React, { RefObject } from 'react';
import {
  useWindowSize,
  useWindowScroll,
  scaleValue,
} from '@curated-property/shared-components';
import { useEffect, useRef, useState } from 'react';

interface ParallaxLayerProps {
  children?: JSX.Element;
  hideAnimations?: boolean;
  inView?: boolean;
  parallaxSpeed?: number;
}

export function ParallaxLayer({
  children,
  hideAnimations,
  inView,
  parallaxSpeed,
}: ParallaxLayerProps) {
  const elementRef = useRef<HTMLDivElement | null>(null);
  const scrollY = useWindowScroll(true);
  const [elementOffset, setElementOffset] = useState<number>(0);
  const screenHeight: number = useWindowSize().height || 0;

  useEffect(() => {
    if (!hideAnimations && inView) {
      const elementOffset: number = parallaxCalculator(
        elementRef,
        screenHeight,
        parallaxSpeed || 65
      );
      setElementOffset(elementOffset);
    }
  }, [hideAnimations, inView, parallaxSpeed, screenHeight, scrollY]);

  return (
    <div
      ref={elementRef}
      className="relative"
      style={{
        top: `${useWindowSize()?.width ?? 0 >= 1024 ? elementOffset : 0}px`,
      }}
    >
      {children}
    </div>
  );
}

/**
 * Parallax calculator
 * @param elementRef - The element to be parallax'd. Needs to use useRef()
 * @param parallaxForce - Optional - A number that determines how much parallax scrolling is applied. Positive or Negative number - higher means more animation
 * @param screenHeight - Pass the height of the current browser window
 * @returns
 */

export function parallaxCalculator(
  elementRef: RefObject<HTMLElement>,
  screenHeight: number,
  parallaxForce: number
): number {
  const elementTop: number =
    elementRef?.current?.getBoundingClientRect()?.top ?? 0;
  const elementOffsetVal: number =
    scaleValue(
      elementTop,
      -(elementRef?.current?.getBoundingClientRect()?.height ?? 0),
      screenHeight ?? 0,
      1,
      -1
    ) * parallaxForce;
  return elementOffsetVal;
}
