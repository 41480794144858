import React from 'react';
export function Translation({
  className,
  fillColor,
}: {
  className?: string;
  fillColor?: string;
}) {
  return (
    <svg className={className} viewBox="0 0 24 24" style={{ fill: fillColor }}>
      <g data-name="Bounding Box">
        <path style={{ fill: 'none' }} d="M0,0V24H24V0Z" />
      </g>
      <g data-name="Icons Outlined">
        <g>
          <path d="M11,6.458H8.875V6a.375.375,0,0,0-.75,0v.458H6a.375.375,0,0,0,0,.75H9.769a3.5943,3.5943,0,0,1-1.2551,2.47A3.624,3.624,0,0,1,7.5078,8.3516a.3753.3753,0,0,0-.6894.2968A4.3219,4.3219,0,0,0,7.89,10.13a4.7445,4.7445,0,0,1-1.1488.5064.3749.3749,0,0,0,.0918.7383.4131.4131,0,0,0,.0908-.0107,5.75,5.75,0,0,0,1.5739-.7505,5.7085,5.7085,0,0,0,1.5775.75.3747.3747,0,0,0,.1836-.7266A4.8673,4.8673,0,0,1,9.12,10.1245,4.2928,4.2928,0,0,0,10.5185,7.208H11a.375.375,0,0,0,0-.75Z" />
          <path d="M14.375,12V5A2.3777,2.3777,0,0,0,12,2.625H5A2.3777,2.3777,0,0,0,2.625,5v7A2.3777,2.3777,0,0,0,5,14.375h7A2.3777,2.3777,0,0,0,14.375,12Zm-11,0V5A1.6262,1.6262,0,0,1,5,3.375h7A1.6262,1.6262,0,0,1,13.625,5v7A1.6262,1.6262,0,0,1,12,13.625H5A1.6262,1.6262,0,0,1,3.375,12Z" />
          <path d="M14.1787,15.6436a.374.374,0,0,0-.5029.1669l-.176.3512-.0027.0033-.833,1.667a.376.376,0,0,0,.1679.503.37.37,0,0,0,.168.04.3743.3743,0,0,0,.335-.207l.7295-1.46h2.871l.7295,1.46a.3743.3743,0,0,0,.335.207.37.37,0,0,0,.168-.04.376.376,0,0,0,.1679-.503l-.833-1.667L17.5,16.1617l-1.1818-2.3629a.3748.3748,0,1,0-.67.336l.912,1.8232H14.379A.3688.3688,0,0,0,14.1787,15.6436Z" />
          <path d="M19,9.625H16a.375.375,0,0,0,0,.75h3A1.6262,1.6262,0,0,1,20.625,12v7A1.6262,1.6262,0,0,1,19,20.625H12A1.6262,1.6262,0,0,1,10.375,19V16a.375.375,0,0,0-.75,0v3A2.3777,2.3777,0,0,0,12,21.375h7A2.3777,2.3777,0,0,0,21.375,19V12A2.3777,2.3777,0,0,0,19,9.625Z" />
        </g>
      </g>
    </svg>
  );
}
