import { Tab, useTabsContext } from '@reach/tabs';

export interface TabProps {
  index?: number;
  title?: string;
  textColor?: string;
  handleTabsChange?: any;
  activeTabAccentColour?: string;
  activeTabTextColour?: string;
  tabTypeButtonStyle?: string;
  indexShift?: number;
}

export function MenuTab({
  index,
  title,
  textColor,
  activeTabAccentColour,
  activeTabTextColour,
  tabTypeButtonStyle,
  indexShift,
}: TabProps) {
  const { selectedIndex } = useTabsContext();
  const isClicked = index - indexShift === selectedIndex ? true : false;
  const activeTabText = isClicked ? activeTabTextColour : null;
  const activeTabAccent = isClicked ? activeTabAccentColour : null;

  return tabTypeButtonStyle ? (
    <Tab
      aria-selected={isClicked ? 'true' : 'false'}
      style={{ border: 'initial', padding: 'initial', margin: 'initial' }}
      className="!m-1.5"
    >
      <div
        className={`btn btn-${tabTypeButtonStyle} btn-html-menu-${
          isClicked ? tabTypeButtonStyle : ''
        }-selected overflow-none flex items-center`}
      >
        {title}
      </div>
    </Tab>
  ) : (
    <Tab
      className="w-full sm:w-auto py-7 px-4 text-xl relative"
      data-testid="tab-button"
      aria-selected={isClicked ? 'true' : 'false'}
      style={{
        color: activeTabText || textColor,
        zIndex: isClicked ? 1 : null,
      }}
    >
      {title}
      {isClicked && (
        <div
          className="absolute -right-0 top-[53px] sm:top-[76px] w-full h-1 bg-primary"
          style={{ background: activeTabAccent }}
        ></div>
      )}
    </Tab>
  );
}
