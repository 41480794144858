import dynamic from 'next/dynamic';

const HotelLogo = dynamic<{ className: string; fillColor?: string }>(() =>
  import('./bnaleci/hotel-logo').then((i) => i.HotelLogo)
);

export const hotelLogoHeader = (
  <HotelLogo
    fillColor="#000000"
    className="fill-current text-current antialiased w-44 md:w-48 h-24"
  />
);

export const hotelLogoFooter = (
  <HotelLogo
    fillColor="#ffffff"
    className="fill-current text-current antialiased w-40 md:w-48 h-12 "
  />
);
