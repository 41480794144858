import React from 'react';
export function Shower({
  className,
  fillColor,
}: {
  className?: string;
  fillColor?: string;
}) {
  return (
    <svg className={className} viewBox="0 0 24 24" style={{ fill: fillColor }}>
      <g data-name="Bounding Box">
        <path style={{ fill: 'none' }} d="M0,0V24H24V0Z" />
      </g>
      <g data-name="Icons Outlined">
        <g>
          <path d="M15,17.125a.625.625,0,1,0,.625.625A.6256.6256,0,0,0,15,17.125Z" />
          <path d="M13,17.125a.625.625,0,1,0,.625.625A.6256.6256,0,0,0,13,17.125Z" />
          <path d="M11,17.125a.625.625,0,1,0,.625.625A.6256.6256,0,0,0,11,17.125Z" />
          <path d="M18,11.625H6a.375.375,0,0,0,0,.75H18a.375.375,0,0,0,0-.75Z" />
          <path d="M9,17.125a.625.625,0,1,0,.625.625A.6256.6256,0,0,0,9,17.125Z" />
          <path d="M15,15.125a.625.625,0,1,0,.625.625A.6256.6256,0,0,0,15,15.125Z" />
          <path d="M13,15.125a.625.625,0,1,0,.625.625A.6256.6256,0,0,0,13,15.125Z" />
          <path d="M11,15.125a.625.625,0,1,0,.625.625A.6256.6256,0,0,0,11,15.125Z" />
          <path d="M9,15.125a.625.625,0,1,0,.625.625A.6256.6256,0,0,0,9,15.125Z" />
          <path d="M15,13.125a.625.625,0,1,0,.625.625A.6256.6256,0,0,0,15,13.125Z" />
          <path d="M13,13.125a.625.625,0,1,0,.625.625A.6256.6256,0,0,0,13,13.125Z" />
          <path d="M11,13.125a.625.625,0,1,0,.625.625A.6256.6256,0,0,0,11,13.125Z" />
          <path d="M9,13.125a.625.625,0,1,0,.625.625A.6256.6256,0,0,0,9,13.125Z" />
          <path d="M7,11.375A.3751.3751,0,0,0,7.375,11,3.6289,3.6289,0,0,1,11,7.375h2A3.6289,3.6289,0,0,1,16.625,11a.375.375,0,0,0,.75,0A4.38,4.38,0,0,0,13,6.625h-.625V6A1.6262,1.6262,0,0,1,14,4.375h4a.375.375,0,0,0,0-.75H14A2.3777,2.3777,0,0,0,11.625,6v.625H11A4.38,4.38,0,0,0,6.625,11,.3751.3751,0,0,0,7,11.375Z" />
        </g>
      </g>
    </svg>
  );
}
