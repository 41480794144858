export function Sauna({
  className,
  fillColor,
}: {
  className?: string;
  fillColor?: string;
}) {
  return (
    <svg
      className={className}
      x="0px"
      y="0px"
      viewBox="0 0 24 24"
      style={{ fill: fillColor }}
    >
      <path d="m0 0v24h24v-24z" fill="none" />
      <path d="m13 19.75h-.5v-6.75c0-.276-.224-.5-.5-.5s-.5.224-.5.5v6.75h-2v-4.75c0-.017-.008-.032-.01-.048v-4.452h2.01v.5c0 .276.224.5.5.5s.5-.224.5-.5v-.5h2v1.5c0 .276.224.5.5.5s.5-.224.5-.5v-1.5h.922l-.678 4.679c-.039.272.15.526.424.566.256.03.526-.15.566-.424l.761-5.25c.021-.144-.022-.289-.117-.398s-.232-.173-.378-.173h-14c-.146 0-.283.063-.379.174-.095.109-.137.256-.115.399l1.52 10.25c.036.245.246.427.494.427h8.48c.276 0 .5-.224.5-.5s-.224-.5-.5-.5zm-9.421-9.25h1.921v5.5c0 .276.224.5.5.5s.5-.224.5-.5v-5.5h1.99v4.938c0 .017.008.032.01.048v4.264h-1.941l-.059-1.767c-.01-.275-.192-.49-.517-.483-.276.01-.492.24-.483.517l.058 1.733h-.607z" />
      <path d="m20 6.75c-.774 0-1.416.581-1.495 1.4l-.005 8.6h-4.5c-.276 0-.5.224-.5.5v1c0 1.379 1.121 2.5 2.5 2.5h1c1.379 0 2.5-1.121 2.5-2.5v-1l.002-9.05c.026-.257.24-.45.593-.45.275 0 .5.225.5.5v1c0 .276.224.5.5.5s.5-.224.5-.5v-1c0-.827-.673-1.5-1.595-1.5zm-1.5 11.5c0 .827-.673 1.5-1.5 1.5h-1c-.827 0-1.5-.673-1.5-1.5v-.5h4z" />
      <path d="m6.576 6.145c.354.274.454.38.435.545-.033.271-.377.575-.97.859-.249.12-.354.418-.235.667.086.18.265.284.451.284.073 0 .146-.016.216-.049.951-.456 1.452-.993 1.53-1.642.091-.755-.455-1.177-.815-1.456-.349-.27-.454-.379-.435-.545.032-.269.377-.573.97-.857.249-.12.354-.418.235-.667-.119-.25-.421-.354-.667-.235-.953.457-1.454.994-1.53 1.642-.091.754.454 1.176.815 1.454z" />
      <path d="m10.313 6.145c.354.274.454.38.435.547-.032.269-.377.573-.97.857-.249.12-.354.418-.235.667.086.18.265.284.451.284.073 0 .146-.016.216-.049.953-.457 1.454-.994 1.53-1.642.091-.755-.455-1.177-.815-1.456-.349-.27-.454-.379-.435-.543.033-.271.377-.575.97-.859.249-.12.354-.418.235-.667-.12-.25-.421-.354-.667-.235-.951.456-1.452.993-1.53 1.642-.091.754.454 1.176.815 1.454z" />
      <path d="m14.063 6.145c.354.274.454.38.435.547-.032.269-.377.573-.97.857-.249.12-.354.418-.235.667.086.18.265.284.451.284.073 0 .146-.016.216-.049.953-.457 1.454-.994 1.53-1.642.091-.755-.455-1.177-.815-1.456-.349-.27-.454-.379-.435-.543.033-.271.377-.575.97-.859.249-.12.354-.418.235-.667-.119-.25-.42-.354-.667-.235-.951.456-1.452.993-1.53 1.642-.091.754.454 1.176.815 1.454z" />
    </svg>
  );
}
