import React from 'react';

export function Phone({
  className,
  fillColor,
}: {
  className?: string;
  fillColor?: string;
}) {
  return (
    <svg
      className={className}
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill={fillColor}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.4727 12.7891L13.5352 11.1016C13.4297 11.0664 13.3242 11.0312 13.1836 11.0312C12.9375 11.0312 12.6914 11.1719 12.5508 11.3477L10.793 13.4922C8.05078 12.1914 5.87109 10.0117 4.57031 7.26953L6.71484 5.51172C6.89062 5.37109 7.03125 5.125 7.03125 4.84375C7.03125 4.73828 6.99609 4.63281 6.96094 4.52734L5.27344 0.589844C5.13281 0.273438 4.81641 0.0625 4.46484 0.0625C4.42969 0.0625 4.35938 0.0976562 4.28906 0.0976562L0.632812 0.941406C0.246094 1.04688 0 1.36328 0 1.75C0 10.7852 7.27734 18.0625 16.3125 18.0625C16.6992 18.0625 17.0156 17.8164 17.1211 17.4297L17.9648 13.7734C17.9648 13.7031 17.9648 13.6328 17.9648 13.5977C17.9648 13.2461 17.7539 12.9297 17.4727 12.7891Z"
        fill={fillColor}
      />
    </svg>
  );
}
