interface Props {
  buttons: Array<{
    backgroundColour?: string;
    backgroundColourHover?: string;
    borderColour?: string;
    borderColourHover?: string;
    borderRadius?: string;
    buttonType?: string;
    fieldGroupName?: string;
    fontFamily?: string;
    hoverStyle?: string;
    textColour?: string;
    textColourHover?: string;
    textTransform?: string;
    buttonHeight?: string;
    buttonPadding?: string;
    fontSize?: string;
    fontWeight?: string;
    pageRestrictions?: string;
    lineHeight?: string;
  }>;
  waldorfButtons?: Array<{
    buttonType?: string;
    textColour?: string;
    textColourHover?: string;
    underlineColour?: string;
    underlineColourHover?: string;
    pageRestrictions?: string;
  }>;
  pageSlug?: string;
}

export function ButtonStyles({ buttons, waldorfButtons, pageSlug }: Props) {
  return (
    <>
      {waldorfButtons
        ? waldorfButtons?.map((button, key) => {
            if (
              !button?.pageRestrictions ||
              pageSlug === button?.pageRestrictions
            ) {
              return (
                <style key={key} id="buttonStyles" suppressHydrationWarning>{`
                .btn.btn-${button.buttonType} {
                  ${
                    button?.textColour
                      ? 'color:' + button?.textColour + ';'
                      : ''
                  }                                     
                }               
                .btn.btn-${button.buttonType}:hover, .btn.btn-${
                  button.buttonType
                }:focus {
                  ${
                    button?.textColourHover
                      ? 'color:' + button?.textColourHover + ';'
                      : ''
                  }
                }
                .btn.btn-${button.buttonType}:after {
                  ${
                    button?.underlineColour &&
                    'background-color:' + button?.underlineColour + ';'
                  }                  
                }          
                .btn.btn-${button.buttonType}:hover:after, .btn.btn-${
                  button.buttonType
                }:focus:after {
                  ${
                    button?.underlineColourHover &&
                    'background-color:' + button?.underlineColourHover + ';'
                  }                  
                }              
              `}</style>
              );
            }
          })
        : buttons
        ? buttons?.map((button, key) => {
            let buttonDirection;
            let buttonDirectionHover;

            switch (button?.hoverStyle) {
              case 'slide-bottom':
                buttonDirection = `top:100%;left:0;`;
                buttonDirectionHover = `top:0;`;
                break;
              case 'slide-top':
                buttonDirection = 'bottom:100%;left:0;';
                buttonDirectionHover = 'bottom:0;';
                break;
              case 'slide-left':
                buttonDirection = `top:0;right:100%;`;
                buttonDirectionHover = `right:0;`;
                break;
              case 'slide-right':
                buttonDirection = `top:0;left:100%;`;
                buttonDirectionHover = `left:0;`;
                break;
              case 'fade':
                buttonDirection = `top:0;left:0;opacity:0;`;
                buttonDirectionHover = `top:0;left:0;opacity:1;`;
                break;
              default:
                buttonDirection = '';
                buttonDirectionHover = '';
            }

            if (
              !button?.pageRestrictions ||
              pageSlug === button?.pageRestrictions
            ) {
              return (
                <style key={key} id="buttonStyles2" suppressHydrationWarning>{`
                    .btn.btn-${button.buttonType} {
                      ${
                        button?.buttonHeight
                          ? `height: ${button?.buttonHeight};`
                          : ''
                      } 
                      ${
                        button?.lineHeight
                          ? `line-height: ${button?.lineHeight};`
                          : ''
                      }                          
                      ${
                        button?.fontFamily
                          ? 'font-family:' + button?.fontFamily + ';'
                          : ''
                      }
                      ${
                        button?.fontSize
                          ? 'font-size:' + button?.fontSize + ';'
                          : ''
                      }                      
                      ${
                        button?.backgroundColour
                          ? 'background-color:' + button?.backgroundColour + ';'
                          : ''
                      }
                      ${
                        button?.borderColour
                          ? 'border-color:' + button?.borderColour + ';'
                          : ''
                      }
                      ${
                        button?.textColour
                          ? 'color:' + button?.textColour + ';'
                          : ''
                      }
                      ${
                        button?.borderRadius
                          ? 'border-radius:' + button?.borderRadius + ';'
                          : ''
                      }
                      ${
                        button?.textTransform
                          ? 'text-transform:' + button?.textTransform + ';'
                          : ''
                      }
                      ${
                        button?.buttonPadding
                          ? 'padding-left:' +
                            button?.buttonPadding +
                            ';padding-right:' +
                            button?.buttonPadding +
                            ';'
                          : ''
                      }  
                      ${
                        button?.fontWeight
                          ? 'font-weight:' + button?.fontWeight + ';'
                          : ''
                      }                                           
                    }
                    ${
                      button?.hoverStyle !== 'default'
                        ? `
                        .btn.btn-${button.buttonType} {
                          position:relative;
                          overflow: hidden;
                          z-index: 0;
                          ${
                            button?.backgroundColour &&
                            'background-color:' +
                              button?.backgroundColour +
                              '!important' +
                              ';'
                          }     
                        }
                        .btn.btn-${button.buttonType}:after {
                          content: '';
                          position: absolute;
                          width: 100%;
                          height: 100%;
                          transition: all .2s ease-in-out;
                          z-index: -1;
                          ${buttonDirection}
                          ${
                            button?.backgroundColourHover &&
                            'background-color:' +
                              button?.backgroundColourHover +
                              ';'
                          }                           
                        }
                        .btn.btn-${button.buttonType}:hover:after, .btn.btn-${
                            button.buttonType
                          }:focus:after {
                          ${buttonDirectionHover}
                        }                        
                        `
                        : ``
                    }                     
                    .btn.btn-${button.buttonType}:hover, .btn.btn-${
                  button.buttonType
                }:focus {
                      ${
                        button?.backgroundColourHover
                          ? 'background-color:' +
                            button?.backgroundColourHover +
                            ';'
                          : ''
                      }
                      ${
                        button?.borderColourHover &&
                        'border-color:' + button?.borderColourHover + ';'
                      }
                      ${
                        button?.textColourHover
                          ? 'color:' + button?.textColourHover + ';'
                          : ''
                      }
                    }
                    .btn.btn-html-menu-${button.buttonType}-selected {
                      ${
                        button?.backgroundColourHover &&
                        'background-color:' +
                          button?.backgroundColourHover +
                          '!important' +
                          ';'
                      }
                      ${
                        button?.textColourHover
                          ? 'color:' +
                            button?.textColourHover +
                            '!important' +
                            ';'
                          : ''
                      }
                    }
                  `}</style>
              );
            } else {
              return null;
            }
          })
        : null}
    </>
  );
}
