import dynamic from 'next/dynamic';

const HotelLogo = dynamic<{ className?: string; fillColor?: string }>(() =>
  import('./nycnhhh/hotel-logo').then((i) => i.HotelLogo)
);

export const hotelLogoHeader = (
  <HotelLogo className="antialiased h-20 sm:h-24 w-32" fillColor="#ffffff" />
);

export const hotelLogoFooter = (
  <HotelLogo className="antialiased w-32" fillColor="#ffffff" />
);
