import React from 'react';
export const CleanStayLogo = ({
  className,
  mainColor,
  lineGradientStart,
  lineGradientStop,
}: {
  className?: string;
  mainColor?: string;
  lineGradientStart?: string;
  lineGradientStop?: string;
}) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 299.82 191.61"
    >
      <defs>
        <linearGradient
          id="linear-gradient"
          y1="189.1"
          x2="299.82"
          y2="189.1"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.2" stopColor={lineGradientStart || '#FFF'} />
          <stop offset="1" stopColor={lineGradientStop || '#000'} />
        </linearGradient>
      </defs>
      <g data-name="Layer 2" fill={mainColor ?? '#000'}>
        <g data-name="—ÎÓÈ_2">
          <rect
            fill="url(#linear-gradient)"
            y="186.58"
            width="299.82"
            height="5.03"
          />
          <path d="M5.51,5.09H294.32V105.94H5.51ZM.41,111h299V0H.41Z" />
          <polygon points="80.45 29.03 80.45 81.92 84.59 82.47 84.59 85.56 64.06 85.56 64.06 82.47 68.48 81.92 68.48 57.84 42.24 57.84 42.24 81.92 46.29 82.47 46.29 85.56 26.13 85.56 26.13 82.47 30.18 81.92 30.18 29.03 26.13 28.48 26.13 25.47 46.29 25.47 46.29 28.48 42.24 29.03 42.24 51.64 68.48 51.64 68.48 29.03 64.06 28.48 64.06 25.47 84.59 25.47 84.59 28.48 80.45 29.03" />
          <polygon points="108.44 82.28 112.31 82.83 112.31 85.56 93.43 85.56 93.43 82.83 97.3 82.28 97.3 46.08 93.43 45.53 93.43 42.8 108.44 42.8 108.44 82.28" />
          <polygon points="120.5 82.91 124.28 82.38 124.28 28.65 120.5 28.12 120.5 25.47 135.51 25.47 135.51 82.38 139.38 82.91 139.38 85.56 120.5 85.56 120.5 82.91" />
          <path d="M162.31,42.8h10.12v4H162.31V72.25c0,6.57,1.37,8.67,7.36,8.67a24.63,24.63,0,0,0,6-.46v2.91a42.16,42.16,0,0,1-13.9,2.74c-8.65,0-10.68-3.92-10.68-11.4V46.81h-7.92v-4h8.2V33.86l11-3.29V42.8" />
          <path d="M223.17,64.59c0,13.32-9,22.25-23.2,22.25-13.73,0-22.47-5.84-22.47-22.25,0-13.77,9-23,23.2-23,14,0,22.47,8.3,22.47,23m-12.34-.27c0-13.5-4.05-19.43-10.59-19.43-6.35,0-10.41,5.66-10.41,19.43,0,13.59,4.06,19.24,10.41,19.24,6.82,0,10.59-5.38,10.59-19.24" />
          <path d="M274.36,82.83v2.74H255.48V82.83l3.78-.54V57.93c0-6.74-2.48-9.75-7.64-9.75-2.4,0-6.17,1.64-8.93,3.55V82.29l3.86.54v2.74h-19V82.83l3.86-.54V46.35l-3.86-.54v-3h15.1v5.93C247.38,45,251.9,42,258.25,42c8.47,0,12.24,4.93,12.24,14.59V82.29l3.87.54" />
          <path d="M109,31.49a6.08,6.08,0,1,1-6.07-6,6,6,0,0,1,6.07,6" />
          <path d="M294.71,118.65h.91c.6,0,.81-.17.81-.6s-.21-.59-.81-.59h-.91v1.19m2.46-.65a1,1,0,0,1-.76,1.1l.91,1.36h-.83l-.83-1.25h-1v1.24H294V116.8h1.62c1.11,0,1.57.37,1.57,1.2m-4.68.66a3,3,0,1,0,3-3,3.05,3.05,0,0,0-3,3m6.92,0a3.88,3.88,0,1,1-3.88-3.88,3.87,3.87,0,0,1,3.88,3.88" />
          <path d="M63.38,134.48l-6.12,3.41a12.3,12.3,0,0,0-9.71-4.61,12.9,12.9,0,0,0,0,25.8,12.33,12.33,0,0,0,9.71-4.6l6.12,3.41A20,20,0,0,1,47.5,165.7a19.52,19.52,0,1,1,0-39A20,20,0,0,1,63.38,134.48Z" />
          <path d="M72.81,165.15H66.25V127.21h6.56Z" />
          <path d="M103.16,151.6v1.79H81.48c.21,4.45,4,7.48,8.51,7.48a11,11,0,0,0,7.58-2.93L101,161a16.6,16.6,0,0,1-11.6,4.67,14.22,14.22,0,0,1-14.31-14.1,14.06,14.06,0,0,1,14-14.09C97.9,137.51,103.16,143.8,103.16,151.6Zm-21.63-1.41H97c0-4.44-2.87-8-7.7-8A7.86,7.86,0,0,0,81.53,150.19Z" />
          <path d="M130.47,147.65v17.5h-6.61v-3.3a15.66,15.66,0,0,1-10.62,3.85c-5.26,0-9.11-3.42-9.11-8.62s4-7.92,10.24-8h9.49v-.87c0-3.9-2.71-5.63-6.56-5.63a16.48,16.48,0,0,0-8.89,2.87l-2-4.23A24.72,24.72,0,0,1,119,137.51C125.43,137.46,130.47,140.38,130.47,147.65Zm-19.78,8.83c0,3.14,2.27,5.2,5.47,5.2a12.44,12.44,0,0,0,7.7-2.33v-7.58h-7C113,151.77,110.69,153.34,110.69,156.48Z" />
          <path d="M161.52,147.1v18.05h-6.61V149.06c0-4-2.28-6.18-5.74-6.18a13.09,13.09,0,0,0-8.73,3.25v19h-6.56v-27.1h6.56v3.58a18.07,18.07,0,0,1,11.33-4.12C157.3,137.51,161.52,140.66,161.52,147.1Z" />
          <path d="M195.61,130.79l-3,5.42a30,30,0,0,0-13.28-3.58c-3.52.06-6.5,1.41-6.5,4.66,0,2.12,1.25,3.09,8.24,4.88,9.26,2.5,15.5,4.18,15.5,12,0,7.54-6.4,11.5-15.34,11.5a32.29,32.29,0,0,1-17.67-5l3.2-5.26a30.37,30.37,0,0,0,15.28,4.17c3.74,0,7.1-1.4,7.1-4.55,0-3.9-2.55-4.28-10.13-6.23-8.78-2.22-13.82-4.28-13.82-10.68,0-8,6.66-11.49,14.85-11.49A30.22,30.22,0,0,1,195.61,130.79Z" />
          <path d="M207.48,138.05h7.75v5h-7.75v12.09a4.84,4.84,0,0,0,5.09,5.15,7.77,7.77,0,0,0,3.74-1l.92,4.5a15.82,15.82,0,0,1-7.15,1.9c-5,.05-9.22-3.2-9.22-9.44V143.09h-4.28v-5h4.28v-7.53h6.62Z" />
          <path d="M243.52,147.65v17.5h-6.61v-3.3a15.66,15.66,0,0,1-10.63,3.85c-5.25,0-9.1-3.42-9.1-8.62s3.95-7.92,10.24-8h9.49v-.87c0-3.9-2.71-5.63-6.56-5.63a16.45,16.45,0,0,0-8.89,2.87l-2-4.23a24.72,24.72,0,0,1,12.57-3.74C238.48,137.46,243.52,140.38,243.52,147.65Zm-19.78,8.83c0,3.14,2.27,5.2,5.47,5.2a12.44,12.44,0,0,0,7.7-2.33v-7.58h-7C226,151.77,223.74,153.34,223.74,156.48Z" />
          <path d="M261.29,167.75c-2.65,6.62-6.45,8.51-10.73,8.51a19.31,19.31,0,0,1-7.15-1.51l1.84-4.34a11,11,0,0,0,4.72.92c2.11-.11,3.9-1.14,5.52-4.44l.44-.92-12-27.92h6.93l8,19.73,7.59-19.73h6.89Z" />
        </g>
      </g>
    </svg>
  );
};
