import React from 'react';
export function ChevronRight(props: React.SVGAttributes<SVGElement>) {
  return (
    <svg viewBox="0 0 8 16" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fill="none"
        fillRule="evenodd"
        stroke={!props?.stroke ? 'currentColor' : props?.stroke}
        strokeLinecap="round"
        strokeWidth="2"
        d="M1,15l3-3.5L7,8,1,1"
      />
    </svg>
  );
}
