import dynamic from 'next/dynamic';

const HotelLogo = dynamic<{ className: string }>(() =>
  import('./mbjrhhf/hotel-logo').then((i) => i.HotelLogo)
);

export const hotelLogoHeader = (
  <HotelLogo className="fill-current text-bg antialiased w-28 sm:w-36 md:w-40 md:mr-5 h-24" />
);

export const hotelLogoFooter = (
  <HotelLogo className="fill-current text-bg antialiased mr-3 md:mr-0 w-32 h-24" />
);
