import React, { useCallback } from 'react';
import cx from 'classnames';
import { sanitize } from '@curated-property/utils';
export interface HeadingStyleProps {
  id?: string;
  text?: string;
  type?: string;
  className?: string;
  styledAs?: string;
  textColorInline?: any;
  anchorFragment?: string;
  style?: React.CSSProperties;
}

export function HeadingStyle({
  id,
  text,
  styledAs,
  type,
  className,
  textColorInline,
  anchorFragment,
  style = {},
}: HeadingStyleProps) {
  let heading: React.ReactElement;
  let styledAsClasses = '';

  if (textColorInline) {
    style.color = textColorInline;
  }

  const onClick = useCallback(
    (e: React.MouseEvent) => {
      if (anchorFragment) window.location.hash = anchorFragment;
    },
    [anchorFragment]
  );

  const h1Styles =
    'text-3xl md:text-5xl font-black leading-none font-headline h1';
  const h2Styles =
    'text-2xl md:text-4xl font-black leading-none font-headline h2';
  const h3Styles =
    'text-2xl md:text-3xl font-black leading-none font-headline h3';
  const h4Styles =
    'text-lg md:text-2xl font-black leading-none font-headline h4';
  const h5Styles = 'text-xl text-bg-inverse tracking-widest -my-1.5 h5';

  switch (styledAs) {
    case 'h1':
    case 'H1':
      styledAsClasses = h1Styles;
      break;
    case 'h2':
    case 'H2':
      styledAsClasses = h2Styles;
      break;
    case 'h3':
    case 'H3':
      styledAsClasses = h3Styles;
      break;
    case 'h4':
    case 'H4':
      styledAsClasses = h4Styles;
      break;
    case 'h5':
    case 'H5':
      styledAsClasses = h5Styles;
      break;
  }

  /**
   * Switch HTML Element Types
   */
  switch (type) {
    case 'h1':
    case 'H1':
      heading = (
        <h1
          onClick={onClick}
          id={id}
          className={cx(styledAs ? styledAsClasses : h1Styles, className)}
          style={style}
          dangerouslySetInnerHTML={{ __html: sanitize(text || '') }}
        ></h1>
      );
      break;
    case 'h2':
    case 'H2':
      heading = (
        <h2
          onClick={onClick}
          id={id}
          className={cx(styledAs ? styledAsClasses : h2Styles, className)}
          style={style}
          dangerouslySetInnerHTML={{ __html: sanitize(text || '') }}
        ></h2>
      );
      break;
    case 'h3':
    case 'H3':
      heading = (
        <h3
          onClick={onClick}
          id={id}
          className={cx(styledAs ? styledAsClasses : h3Styles, className)}
          style={style}
          dangerouslySetInnerHTML={{ __html: sanitize(text || '') }}
        ></h3>
      );
      break;
    case 'h4':
    case 'H4':
      heading = (
        <h4
          onClick={onClick}
          id={id}
          className={cx(styledAs ? styledAsClasses : h4Styles, className)}
          style={style}
          dangerouslySetInnerHTML={{ __html: sanitize(text || '') }}
        ></h4>
      );
      break;
    case 'h5':
    case 'H5':
      heading = (
        <h5
          onClick={onClick}
          id={id}
          className={cx(styledAs ? styledAsClasses : h5Styles, className)}
          style={style}
          dangerouslySetInnerHTML={{ __html: sanitize(text || '') }}
        ></h5>
      );
      break;
    case 'p':
    case 'P':
      heading = (
        <p
          onClick={onClick}
          id={id}
          className={cx(styledAs ? styledAsClasses : h4Styles, className)}
          style={style}
          dangerouslySetInnerHTML={{ __html: sanitize(text || '') }}
        ></p>
      );
      break;
    default:
      heading = (
        <h2
          onClick={onClick}
          id={id}
          className={cx(styledAs ? styledAsClasses : h2Styles, className)}
          style={style}
          dangerouslySetInnerHTML={{ __html: sanitize(text || '') }}
        ></h2>
      );
      break;
  }

  return heading;
}
