import { AdditionalHeadProps } from '../pages/_app';

export const AdditionalHead = ({ pageSlug }: AdditionalHeadProps) => {
  switch (pageSlug) {
    case 'podium-restaurant-and-bar':
      return (
        <>
          <style>{`
                .font-headline {
                    font-family: "Times New Roman" !important;
                }
                
                 p {
                    font-family: "Times New Roman" !important;
                }
                
                .btn-quarternary {
                    color: #ffffff!important;
                    background-color: #7F944F!important;
                    border-color: #7F944F!important;
                    text-transform: uppercase;
                }
                
                .btn-quarternary:hover {
                    color: #ffffff!important;
                    background-color: #826A3A!important;
                    border-color: #826A3A!important;
                }
              `}</style>
        </>
      );
    case 'galvin-at-windows':
      return (
        <>
          <link
            rel="stylesheet"
            href="//fast.fonts.net/cssapi/de1a7c7e-5dbf-4f9d-849d-0506ae8e13a5.css"
          ></link>
          <style>{`
                .font-headline {
                    font-family: 'Century Gothic W01' !important;
                    text-transform: uppercase!important;
                }
                
                div#main {
                    font-family: 'Century Gothic W01' !important;
                }
                
                .btn-tertiary-outline {
                    color: #ffffff!important;
                    background-color: #AC8B6E!important;
                    border-color: #AC8B6E!important;
                    text-transform: uppercase;
                }
                
                .btn-tertiary-outline:hover {
                    color: #ffffff!important;
                    background-color: #202E74!important;
                    border-color: #202E74!important;
                }
              `}</style>
        </>
      );
    case '10-degrees-sky-bar':
      return (
        <>
          <link
            rel="stylesheet"
            href="https://use.typekit.net/cuq8ngc.css"
          ></link>
          <style>{`
                    div#main {
                        font-family: baskerville-urw!important;
                    }
                    
                    .font-headline {
                        font-family: baskerville-urw!important;
                    }
                    
                    .btn-tertiary {
                        color: #ffffff!important;
                        background-color: #2D5A28!important;
                        border-color: #2D5A28!important;
                        text-transform: uppercase;
                    }
                    
                    .btn-tertiary:hover {
                        color: #2B2C2D!important;
                        background-color: #ECE3B3!important;
                        border-color: #ECE3B3!important;
                    }
              `}</style>
        </>
      );
    case 'park-corner-brasserie':
      return (
        <>
          <link
            rel="stylesheet"
            href="https://use.typekit.net/kel7xwz.css"
          ></link>
          <style>{`
                  h1, h1 > p, h1 > div { 
                    font-size: 4.5rem!important;
                  }
                  h2, h2 > p {
                    font-size: 3.75rem!important;
                  }
                  h3 {
                    font-size: 3rem!important;
                  }
                  strong {
                    font-weight: 700!important;
                  }
                  div#main, .htmlMenuLine1, .htmlMenuLine2, .htmlMenuPrice, .htmlMenuDisclaimer {
                      font-family: sofia-pro!important;
                      font-style: normal!important;
                      font-weight: 200!important;
                   }
                   h4.htmlMenuLine1 {
                    font-weight: 200!important;
                   }
                  .font-headline {
                    font-family: fave-script-pro!important;
                   }
                  span[data-element-id="subtitle-wrapper"],
                  .htmlMenuSubtitle {
                      font-family: sofia-pro!important;
                      font-style: normal!important;
                      font-weight: 700!important;
                   }    
              `}</style>
        </>
      );
    case 'revery-bar':
      return (
        <>
          <link
            rel="stylesheet"
            href="https://use.typekit.net/sbq3nji.css"
          ></link>
          <style>{`
                  div#main {
                    font-family: area-normal!important;
                    font-style: normal!important;
                    font-weight: 100!important;
                  }
                .font-headline {
                    font-family: Beth Ellen!important;
                    text-transform: lowercase!important;
                  }
                span[data-element-id="subtitle-wrapper"],
                .htmlMenuSubtitle {
                    font-family: area-normal!important;
                    font-style: normal!important;
                    font-weight: 700!important;
                  }    
                `}</style>
        </>
      );
  }
};
