import React from 'react';
export function Fireplace({
  className,
  fillColor,
}: {
  className?: string;
  fillColor?: string;
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      className={className}
      style={{ fill: fillColor }}
    >
      <path d="m0 0v24h24v-24z" fill="none" />
      <path d="m4.5 5.5h15v1h-15z" fill="none" />
      <path d="m20 4.5h-16c-.28 0-.5.22-.5.5v2c0 .28.22.5.5.5h.5v10.5c0 .28.22.5.5.5s.5-.22.5-.5v-10.5h13v10.5c0 .28.22.5.5.5s.5-.22.5-.5v-10.5h.5c.28 0 .5-.22.5-.5v-2c0-.28-.22-.5-.5-.5zm-.5 2h-15v-1h15z" />
      <path d="m20 19.5h-16c-.28 0-.5.22-.5.5s.22.5.5.5h16c.28 0 .5-.22.5-.5s-.22-.5-.5-.5z" />
      <path d="m16 18.5c.28 0 .5-.22.5-.5v-4c0-2.48-2.02-4.5-4.5-4.5s-4.5 2.02-4.5 4.5v4c0 .28.22.5.5.5s.5-.22.5-.5v-4c0-1.93 1.57-3.5 3.5-3.5s3.5 1.57 3.5 3.5v4c0 .28.22.5.5.5z" />
      <path d="m11.78 17.45c.25.13.55.02.67-.22.97-1.94-.38-3.25-1.22-3.67-.15-.08-.34-.07-.49.02s-.24.25-.24.43c0 .71-.3 1.09-.63 1.51-.46.58-1.02 1.31-.32 2.72.09.17.26.28.45.28.08 0 .15-.02.22-.05.25-.12.35-.42.22-.67-.42-.84-.21-1.11.21-1.65.25-.32.54-.69.7-1.21.34.38.62 1 .19 1.87-.12.25-.02.55.22.67z" />
      <path d="m13.25 18.43c.08.04.16.07.25.07.17 0 .34-.09.43-.25 1.16-2.03.63-3.62-.02-4.59-.68-1.02-1.63-1.57-1.67-1.59-.24-.13-.54-.05-.68.19s-.06.54.18.68c.12.07 3.04 1.8 1.31 4.82-.14.24-.05.54.19.68z" />
    </svg>
  );
}
