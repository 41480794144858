import { TabPanel } from '@reach/tabs';

export interface FilterPanelProps {
  children: React.ReactNode;
  className?: string;
  label: string;
}

export function FilterPanel(props: FilterPanelProps) {
  return (
    <TabPanel
      tabIndex={-1}
      className={`exclude-focus ${props?.className}`}
      data-testid={props?.label}
    >
      {props?.children}
    </TabPanel>
  );
}
