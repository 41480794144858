import {
  BrandTextConradLogo,
  BrandTextHiltonLogo,
  BrandTextLXRLogo,
  LXRLogo,
} from '@curated-property/icons';
import React, { ReactNode } from 'react';

export interface IBrandDetails {
  brandLogo?: ReactNode;
  brandLogoAlt?: ReactNode;
  brandColour?: string;
  brandCode?: string;
}

export function getBrandDetails(brand?: string) {
  switch (brand) {
    case 'conrad':
      return {
        brandLogo: (
          <BrandTextConradLogo className="w-20 lg:w-24 h-auto text-current" />
        ),
        brandLogoAlt: (
          <BrandTextConradLogo className="w-20 lg:w-24 h-auto text-current" />
        ),
        brandColour: '#A98B5F',
        brandCode: 'CH',
      };
    case 'lxr':
      return {
        brandLogo: <LXRLogo className="w-12 lg:w-16 h-auto text-current" />,
        brandLogoAlt: (
          <BrandTextLXRLogo className="w-12 lg:w-16 h-auto text-current" />
        ),
        brandColour: '#0B4228',
        brandCode: 'OL',
      };
    case 'hilton':
      return {
        brandLogo: (
          <BrandTextHiltonLogo className="w-12 lg:w-16 h-auto text-current" />
        ),
        brandLogoAlt: (
          <BrandTextHiltonLogo className="w-12 lg:w-16 h-auto text-current" />
        ),
        brandColour: '#000000',
        brandCode: 'HI',
      };
    case 'hiallinclusive':
    case 'AI':
      return {
        brandColour: '#83235e',
      };
    default:
      return null;
  }
}
