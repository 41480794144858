/**
 *
 * @param url:string
 * @param backgroudRepeat:string
 * @returns Image source URL with and updated width & quality parameters for Cloudinary. Intended for use on background images.
 */
export function appliedCloudinaryParams(
  url: string | undefined,
  backgroudRepeat?: string
) {
  let str = url;
  // check the background is not a repeating pattern
  if (
    backgroudRepeat === 'repeat' ||
    backgroudRepeat === 'repeat-x' ||
    backgroudRepeat === 'repeat-y'
  ) {
    // remove the width and height (images come down as 300x300 from WP)
    str = str?.replace('w_300,h_300,', '');
  } else {
    // Change width to 1600 & remove height
    str = str?.replace(/(w_?[0-9]{0,5},h_?[0-9]{0,5})/g, 'w_1600');
  }
  // Reduce quality
  str = str?.replace(/q_[0-9]{0,3}/g, 'q_90');
  return str;
}

export function capitalizeFirstLetter(string: string) {
  return string?.charAt(0)?.toUpperCase() + string?.slice(1);
}
