type dxGqlRestaurants = any;
type wpRestaurants = any;

export function mergeRestaurants({
  dxGqlRestaurants,
  wpRestaurants,
}: {
  dxGqlRestaurants?: dxGqlRestaurants;
  wpRestaurants?: wpRestaurants;
}) {
  const wpRestaurantsByRoomTypeCode = wpRestaurants?.reduce((prev, curr) => {
    if (!curr.restaurantId) {
      return prev;
    }
    prev[curr.restaurantId] = curr;
    return prev;
  }, {} as Record<string, ArrayElement<typeof dxGqlRestaurants>>);

  // changing the logic here so that we pull in all IDs from core+ and supplement that data with IDs from WorDress
  // mergeRooms works the other way around
  return dxGqlRestaurants?.map((wpRoom) => {
    return {
      ...wpRestaurantsByRoomTypeCode?.[wpRoom?.id || ''],
      ...wpRoom,
    };
  });
}

type ArrayElement<A> = A extends readonly (infer T)[] ? T : never;
