import { useState, useContext } from 'react';
import cx from 'classnames';
import { EventCalendarModalContentEvent } from './event-calendar-modal-content-event';
import { GenericModal } from '../global/generic-modal';
import { useTranslation } from 'next-i18next';
import { EventCalendarInfoContext } from './event-calendar-info-context';
import { LineItemProps } from './lib/event-calendar-props';
import { format } from 'date-fns';

export function EventCalendarLineItem({
  regularOrSpecialEvent,
  dateStringNom,
  dateStringNoDashes,
  eventDateStr,
  eventTitle,
  eventTimes,
  eventContent,
  eventLineItemLocale,
  eventLineItemCategory,
  dateNumber,
  showHide,
  disabledState,
  dayOfWeek,
  multiDateDaysRemaning,
}: LineItemProps) {
  const [modalIsActive, setModalIsActive] = useState(false);
  const [entryContentForModal, setEntryContentForModal] = useState<any | null>(
    null
  );

  // Set individual event button ID tags
  const eventId = !eventContent?.databaseId
    ? eventContent?.calendarId
    : eventContent?.databaseId;
  const buttonId = `${eventId}${dateNumber}`;

  const { t } = useTranslation();
  const hotelInfoContext = useContext(EventCalendarInfoContext);
  const modalSettings = hotelInfoContext?.modalSettings;

  const today = format(new Date(), 'yyyyMMdd');

  // Determine button tailwind classes based on event type (regular, special, multi)
  let buttonClassesFromEventType = '';
  switch (regularOrSpecialEvent) {
    case 'multidate':
      buttonClassesFromEventType = cx(
        'font-bold calendar-special-date  border-none block px-2.5',
        multiDateDaysRemaning === 1
          ? 'md:mb-2 w-content lg:w-full'
          : 'absolute w-content lg:w-full lg:pr-0',
        disabledState
          ? `bg-[#f5f5f5] text-text-disabled cursor-not-allowed`
          : `bg-primary  text-white z-10`
      );

      break;
    case 'special':
      buttonClassesFromEventType = 'calendar-special-date border px-2.5  ';
      break;
    case 'regular':
      buttonClassesFromEventType =
        'calendar-regular-event  bg-[#f5f5f5] border px-2.5  ';
      break;
  }

  const startDate = eventContent?.calendarOfEvents?.startDate;

  const isMultidate: boolean = regularOrSpecialEvent === 'multidate';
  const multiDateDisplayClasses =
    isMultidate &&
    startDate !== dateStringNoDashes &&
    dayOfWeek > 0 &&
    dateNumber > 1
      ? 'lg:hidden'
      : '';

  // If this item's category is not (or no longer) in the category list, set it to "Attractions"
  const selectedCategory =
    hotelInfoContext?.eventCategoriesWithCustom?.indexOf(
      eventLineItemCategory
    ) !== -1
      ? eventLineItemCategory
      : 'Attractions';

  const eventTimesAdj = eventTimes
    .replace(/a.m./g, 'am')
    .replace(/p.m./g, 'pm');
  return (
    <>
      <button
        id={buttonId}
        data-testid={`${eventDateStr}-button`}
        aria-label={`${t(
          'calendar.viewEventInfo'
        )}  ${eventTitle} ${eventDateStr}, ${eventTimes}`}
        aria-hidden={showHide === true ? false : true}
        data-locale={eventLineItemLocale}
        data-category={selectedCategory}
        disabled={disabledState}
        data-event-type={regularOrSpecialEvent}
        data-days-to-end={multiDateDaysRemaning}
        className={cx(
          'text-xs leading-4  py-1 my-1.5  text-left rounded-md',
          !disabledState
            ? 'border-secondary hover:bg-primary hover:text-white hover:border-primary cursor-pointer'
            : 'border-text-disabled text-text-disabled cursor-not-allowed',
          buttonClassesFromEventType,
          {
            invisible: showHide === true ? false : true,
          },
          isMultidate && multiDateDisplayClasses
        )}
        tabIndex={!disabledState ? 0 : -1}
        onClick={() => {
          setEntryContentForModal(eventContent);
          setModalIsActive(true);
        }}
      >
        {!isMultidate && (
          <span className={cx('font-bold inline-block')} aria-hidden="true">
            {eventTimesAdj}
          </span>
        )}{' '}
        {isMultidate && `${eventTitle}`}
        {(regularOrSpecialEvent === 'special' ||
          regularOrSpecialEvent === 'regular') && (
          <span className={!eventTimes ? 'font-bold' : ''}>{eventTitle}</span>
        )}
      </button>
      {modalIsActive && (
        <GenericModal
          modalChildWrapperStyles="w-5/6 sm:w-3/4 lg:w-1/2 flex justify-center items-center"
          modalChildStyles="w-full h-auto"
          modalSettings={modalSettings}
          setModalActive={setModalIsActive}
          modalActive={modalIsActive}
          modalTitle={eventTitle}
          modalChild={
            <EventCalendarModalContentEvent
              title={eventTitle}
              eventDateNumber={dateNumber}
              dateStringNom={dateStringNom}
              content={entryContentForModal}
              eventType={regularOrSpecialEvent}
              hideICS={false}
            />
          }
        />
      )}
    </>
  );
}
