import { useRef, useEffect, useState, useContext, createContext } from 'react';
import { CloseIcon } from '@curated-property/icons';
import { useTranslation } from 'next-i18next';
import cx from 'classnames';
import { DialogOverlay, DialogContent } from '@reach/dialog';
import { elementExceedsWindowHeight, hexToRgb } from '../functions/helper';
import { SharedContext, isReducedMotion } from '@curated-property/utils';

export const ModalContext = createContext<
  { closeDialog: () => void } | undefined
>(undefined);

export interface GenericModalProps {
  isBookingWidget?: boolean;
  modalTitle?: string;
  setModalActive: undefined | ((setActive: boolean) => void);
  modalActive: boolean;
  modalChild?: React.ReactNode;
  modalChildWrapperStyles?: string;
  modalChildStyles?: string;
  modalStyles?: string;
  modalSettings?: {
    modalBackgroundOpacity?: number;
    modalBackgroundColour?: string;
    modalCloseButtonBackgroundColour?: string;
    modalCloseButtonIconColour?: string;
    contentBackgroundColour?: string;
    hideBoxShadow?: boolean;
  };
  animationSpeed?: number;
  ignoreReducedMotion?: boolean;
}

export function GenericModal({
  isBookingWidget,
  modalTitle,
  setModalActive,
  modalActive,
  modalChild,
  modalChildWrapperStyles,
  modalChildStyles,
  modalStyles,
  modalSettings,
  animationSpeed,
  ignoreReducedMotion = false,
}: GenericModalProps) {
  const [elementBiggerThanWindow, setElementBiggerThanWindow] = useState(false);
  const modalInner = useRef<HTMLDivElement>(null);
  const [mounted, setMounted] = useState(false);
  const { t } = useTranslation();
  const reducedMotion = !ignoreReducedMotion ? isReducedMotion : false;
  const transitionSpeed = !reducedMotion ? animationSpeed || 400 : 0;
  const sContext = useContext(SharedContext);
  modalSettings = modalSettings ? modalSettings : sContext.modalStyles;
  // convert hex result to rgb(a)
  const rgbArray = modalSettings?.modalBackgroundColour
    ? hexToRgb(modalSettings?.modalBackgroundColour)
    : '';
  const modalColorRgba =
    rgbArray !== ''
      ? `rgba(${rgbArray?.r},${rgbArray?.g},${rgbArray?.b}, ${
          modalSettings?.modalBackgroundOpacity || '.98'
        })`
      : '';

  useEffect(() => {
    // <Dialog> uses a focus-lock package that stops animations when the component mounts
    // Setting a timeout of 0 when the mount variable is set seems to bypass that quirk
    if (modalActive)
      setTimeout(() => {
        setMounted(true);
      }, 0);
  }, [modalActive]);

  // split these out into a custom function when we have CMS animation options
  const baseTransitionClasses =
    'transform-gpu transition-all ease-in-out duration-300';
  const transitionType = mounted
    ? 'opacity-1 translate-y-0'
    : 'opacity-20 -translate-y-full';

  function closeDialog() {
    setTimeout(() => {
      setModalActive?.(false);
    }, transitionSpeed);
    setMounted(false);
  }

  useEffect(() => {
    function handleResize() {
      setElementBiggerThanWindow(
        elementExceedsWindowHeight(modalInner?.current?.clientHeight || 0, 24)
      );
    }
    const resizeObserver = new ResizeObserver((_entries) => {
      handleResize();
    });

    // check to see if modal is bigger than window size
    setTimeout(() => {
      if (modalInner.current instanceof Object) {
        resizeObserver.observe(modalInner.current);
      }
      handleResize();
    }, 100);

    window.addEventListener('resize', handleResize);
    return () => {
      resizeObserver.disconnect();
      window.removeEventListener('resize', handleResize);
    };
  }, [
    modalActive,
    modalInner,
    elementExceedsWindowHeight,
    setElementBiggerThanWindow,
  ]);

  return modalActive ? (
    <DialogOverlay
      data-testid="modal-overlay"
      allowPinchZoom={true}
      onDismiss={() => closeDialog()}
      className={cx(
        'cp-generic-modal dialog-overlay bg-transparent overflow-hidden',
        !reducedMotion ? baseTransitionClasses : '',
        !reducedMotion ? transitionType : ''
      )}
      style={{
        transitionDuration: `${transitionSpeed}ms`,
      }}
    >
      <div
        className={cx('h-full w-full absolute z-40')}
        style={{
          backgroundColor:
            modalColorRgba ||
            `rgba(var(--color-primary-alt), ${
              modalSettings?.modalBackgroundOpacity || '.95'
            })`,
        }}
      />
      <div
        data-testid="generic-modal-wrapper"
        className={cx(
          'h-full w-full absolute flex flex-col overflow-auto px-4 lg:px-16 z-50',
          modalStyles,
          elementBiggerThanWindow ? 'justify-start' : 'justify-center'
        )}
      >
        <button
          data-testid="closeButton"
          className={cx(
            'absolute top-2 right-5 md:top-5 md:right-5 w-8 h-8 sm:w-12 sm:h-12 bg-bg-alt rounded-full z-60 flex items-center justify-center transform-gpu transition-all !duration-500 !ease-bounce',
            mounted ? 'scale-100 opacity-1' : 'scale-0 opacity-0'
          )}
          onClick={closeDialog}
          style={{
            backgroundColor: modalSettings?.modalCloseButtonBackgroundColour,
          }}
        >
          <span className="sr-only">{t('closeModal')}</span>
          <CloseIcon
            className="fill-current text-bg-inverse w-2 h-2 sm:w-4 sm:h-4"
            fillColor={modalSettings?.modalCloseButtonIconColour}
          />
        </button>
        <DialogContent
          aria-label={modalTitle}
          data-testid="generic-modal"
          data-modal-id={isBookingWidget ? 'booking-widget' : 'generic-modal'}
          className={cx(
            'dialog-content p-0 bg-bg rounded-none max-w-screen-2xl',
            modalChildWrapperStyles ? modalChildWrapperStyles : 'w-fit',
            modalSettings?.hideBoxShadow ? 'shadow-none' : ''
          )}
        >
          <div
            role="alertdialog"
            ref={modalInner}
            data-testid="child"
            className={modalChildStyles}
            style={{ backgroundColor: modalSettings?.contentBackgroundColour }}
          >
            <ModalContext.Provider value={{ closeDialog }}>
              {modalChild}
            </ModalContext.Provider>
          </div>
        </DialogContent>
      </div>
    </DialogOverlay>
  ) : null;
}
