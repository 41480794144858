export const CURATED_4082_HOTEL_LIST = [
  'deshihf',
  'hnlwahf',
  'dxbwawa',
  'szxcici',
  'lonmetw',
  'bhxmetw',
  'pbiwphh',
  'dohpaol',
  'auscvhh',
  'pqcpqqq',
  'dubhcci',
  'selcici',
  'ctuwawa',
  'hghfrci',
  'xmnwawa',
  'mfmcsci',
  'lirguwa',
  'isthitw',
  'syxhihi',
  'sancchh',
  'dxbjbhi',
  'msynhhh',
  'kwihihi',
  'dabdhhf',
  'dxbpdwa',
  'dxbpdwa',
  'rktwawa',
  'snaahhh',
  'sjdwawa',
  'cunhihh',
  'cunwawa',
  'laxavci',
  'fllcici',
  'fllcici',
  'orldwhh',
  'sezitol',
  'slcdmwa',
  'sluhcqq',
  'sjnhihh',
  'dlmhrhi',
  'auhetci',
  'auhetci',
  'auhetci',
  'pvrpahh',
  'mlahitw',
  'cunqrhh',
  'phxrsqq',
  'laxwawa',
  'sfecghh',
  'orlochh',
  'flldhqq',
  'laswdwa',
  'rrohihi',
  'chiphhh',
  'auhwbqq',
  'dohqtwa',
  'dohqtwa',
  'bgihihh',
  'dxbpjhi',
  'mleaihi',
  'sancnqq',
  'auhyihi',
  'itmolol',
  'axazbol',
  'sealxol',
  'bjvtrdi',
  'lonhitw',
  'berwawa',
  'sanqqqq',
  'sanqqqq',
  'sanqqqq',
  'rmfhihi',
  'rktmidi',
  'sbafphh',
  'orlcici',
  'sanrhqq',
  'jrswawa',
  'cunmchh',
  'chiwawa',
];
