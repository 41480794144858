import React from 'react';
export function TV({
  className,
  fillColor,
}: {
  className?: string;
  fillColor?: string;
}) {
  return (
    <svg className={className} viewBox="0 0 24 24" style={{ fill: fillColor }}>
      <g data-name="Bounding Box">
        <path style={{ fill: 'none' }} d="M0,0V24H24V0Z" />
      </g>
      <g data-name="Icons Outlined">
        <g>
          <path d="M15,17.625H9a.375.375,0,0,0,0,.75h6a.375.375,0,0,0,0-.75Z" />
          <path d="M15.1621,9.1621a.3742.3742,0,0,0-.5.1758l-1.1133,2.3242L12.348,9.3665A.3731.3731,0,0,0,12,9.125H8a.375.375,0,0,0,0,.75H9.625V12.5a.375.375,0,0,0,.75,0V9.875h1.3931l1.4633,2.7988a.3736.3736,0,0,0,.3321.2012H13.57a.3747.3747,0,0,0,.3311-.2129l1.4365-3A.3745.3745,0,0,0,15.1621,9.1621Z" />
          <path d="M19,5.625H5A1.377,1.377,0,0,0,3.625,7v8A1.377,1.377,0,0,0,5,16.375H19A1.377,1.377,0,0,0,20.375,15V7A1.377,1.377,0,0,0,19,5.625ZM19.625,15a.6256.6256,0,0,1-.625.625H5A.6256.6256,0,0,1,4.375,15V7A.6256.6256,0,0,1,5,6.375H19A.6256.6256,0,0,1,19.625,7Z" />
        </g>
      </g>
    </svg>
  );
}
