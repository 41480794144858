import cx from 'classnames';
import Route from 'next/router';
import {
  PlayControl,
  PauseControl,
  AudioOn,
  AudioMuted,
} from '@curated-property/icons';
import { adobeEventTracking } from '../global/adobe-analytics';
import { HeroImageOverlayProps } from './hero-image.types';
import { useTranslation } from 'next-i18next';

export function HeroVideo({
  videoUrl,
  videoQualityOverride,
  videoQualitySlider,
  vidRef,
  videoAutoplay,
  hideAnimations,
}: Pick<
  HeroImageOverlayProps,
  | 'videoUrl'
  | 'videoQualityOverride'
  | 'videoQualitySlider'
  | 'vidRef'
  | 'videoAutoplay'
  | 'hideAnimations'
>) {
  const currentRoute: string = Route?.router?.asPath;
  if (videoUrl) {
    [
      'https://assets.hiltonstatic.com/video/upload/',
      'https://assets.hiltonstatic.com/hilton-asset-cache/video/upload/',
    ].every((cloudinaryBaseUrl) => {
      if (videoUrl.startsWith(cloudinaryBaseUrl)) {
        let videoQuality: string;
        if (videoQualityOverride && videoQualitySlider !== null) {
          videoQuality = 'q_' + videoQualitySlider;
        } else {
          videoQuality = 'q_auto:low';
        }
        videoUrl =
          cloudinaryBaseUrl +
          videoQuality +
          ',f_auto/' +
          videoUrl.replace(cloudinaryBaseUrl, '');
        return false;
      }
      return true;
    });
  }

  return (
    <video
      data-testid="mastheadVideo"
      id="mastheadVideo"
      key={currentRoute}
      ref={vidRef}
      className={cx(
        'z-[16] absolute inset-0 w-full h-full object-cover pointer-events-none',
        {
          'animate-fade-in': !hideAnimations,
        }
      )}
      width="100%"
      height="100%"
      autoPlay={videoAutoplay}
      muted
      loop
      playsInline
    >
      <source src={videoUrl} type="video/mp4"></source>
    </video>
  );
}

export function HeroVideoControls({
  inlineStyles,
  videoMuteChoice,
  videoMute,
  setVideoMute,
  vidRef,
  videoPause,
  videoCtaPause,
  videoCtaPlay,
  videoActive,
  setVideoActive,
  setVideoPause,
}: Pick<
  HeroImageOverlayProps,
  | 'inlineStyles'
  | 'videoMuteChoice'
  | 'vidRef'
  | 'videoMute'
  | 'setVideoMute'
  | 'videoPause'
  | 'videoCtaPause'
  | 'videoCtaPlay'
  | 'videoActive'
  | 'setVideoActive'
  | 'setVideoPause'
>) {
  const { t } = useTranslation();

  return (
    <div
      className={cx(
        'cp-heroImage-videoCta text-text text-base flex justify-center items-center h-12 ml-2',
        inlineStyles?.videoCtaShadow || 'shadow-xl',
        inlineStyles?.videoCtaRoundedCorners || 'rounded-sm'
      )}
      style={{
        backgroundColor: inlineStyles?.videoCtaBackgroundColor ?? '#F2F2F2',
      }}
    >
      <button
        data-testid={videoPause ? 'pauseVideo' : 'playVideo'}
        aria-live="polite"
        aria-label={videoPause ? videoCtaPause : videoCtaPlay}
        className={cx(
          'flex items-center justify-center w-full h-full px-[18px]',
          {
            'border-r-2': !videoMuteChoice,
          }
        )}
        style={{ borderColor: inlineStyles?.videoCtaBorderColor }}
        onClick={(e) => {
          setVideoActive(true);
          setVideoPause(!videoPause);
          !videoActive && videoMute && setVideoMute(false);
          adobeEventTracking({
            ctaText: !videoPause ? videoCtaPlay : videoCtaPause,
            sectionHeading: '',
            actionDetail: 'Video',
            interactionName: 'propertyVideo',
          });
        }}
      >
        {videoPause ? (
          <PauseControl
            fill={inlineStyles?.videoCtaPlaypauseColor}
            className="mr-px pr-px mx-0.5"
          ></PauseControl>
        ) : (
          <PlayControl
            fill={inlineStyles?.videoCtaPlaypauseColor}
            className="pl-px"
          ></PlayControl>
        )}
      </button>
      {!videoMuteChoice && (
        <button
          data-testid={!videoMute ? 'unmute' : 'mute'}
          aria-live="polite"
          aria-label={!videoMute ? t('unmute') : t('mute')}
          className="flex items-center justify-center w-full h-full px-3.5"
          onClick={(e) => {
            setVideoMute(!videoMute);
            const videoMasthead = document.querySelector('#mastheadVideo');
            if (videoMasthead) {
              vidRef.current.muted = videoMute;
            }
          }}
        >
          {videoMute ? (
            <AudioOn fill={inlineStyles?.videoCtaUnmuteColor}></AudioOn>
          ) : (
            <AudioMuted fill={inlineStyles?.videoCtaMuteColor}></AudioMuted>
          )}
        </button>
      )}
    </div>
  );
}
