import React from 'react';
export function Cosmetics({
  className,
  fillColor,
}: {
  className?: string;
  fillColor?: string;
}) {
  return (
    <svg
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="m0 0v24h24v-24z" fill="none" />
      <path
        fill={fillColor}
        d="m14 4.5c.28 0 .5-.22.5-.5s-.22-.5-.5-.5h-5c-1.38 0-2.5 1.12-2.5 2.5 0 .28.22.5.5.5s.5-.22.5-.5c0-.83.67-1.5 1.5-1.5h2.5v2h-1c-.28 0-.5.22-.5.5v1.5c-1.38 0-2.5 1.12-2.5 2.5v7c0 1.38 1.12 2.5 2.5 2.5h4c1.38 0 2.5-1.12 2.5-2.5v-7c0-1.38-1.12-2.5-2.5-2.5v-1.5c0-.28-.22-.5-.5-.5h-1v-2zm0 15h-4c-.83 0-1.5-.67-1.5-1.5v-5.5h1.5c.28 0 .5-.22.5-.5s-.22-.5-.5-.5h-1.5v-.5c0-.83.67-1.5 1.5-1.5h1.5v7.5c0 .28.22.5.5.5s.5-.22.5-.5v-4.5h3v5.5c0 .83-.67 1.5-1.5 1.5zm1.5-8.5v.5h-3v-2h1.5c.83 0 1.5.67 1.5 1.5zm-2.5-2.5h-2v-1h2z"
      />
    </svg>
  );
}
