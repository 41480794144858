import React from 'react';
export function Family({
  className,
  fillColor,
}: {
  className?: string;
  fillColor?: string;
}) {
  return (
    <svg className={className} viewBox="0 0 24 24" style={{ fill: fillColor }}>
      <g data-name="Bounding Box">
        <path style={{ fill: 'none' }} d="M0,0V24H24V0Z" />
      </g>
      <g data-name="Icons Outlined">
        <g>
          <path d="M8.5,7.375A1.875,1.875,0,1,0,6.625,5.5,1.8767,1.8767,0,0,0,8.5,7.375Zm0-3A1.125,1.125,0,1,1,7.375,5.5,1.1266,1.1266,0,0,1,8.5,4.375Z" />
          <path d="M15,7.375A1.875,1.875,0,1,0,13.125,5.5,1.8767,1.8767,0,0,0,15,7.375Zm0-3A1.125,1.125,0,1,1,13.875,5.5,1.1266,1.1266,0,0,1,15,4.375Z" />
          <path d="M10.125,12.5A1.875,1.875,0,1,0,12,10.625,1.8767,1.8767,0,0,0,10.125,12.5ZM12,11.375A1.125,1.125,0,1,1,10.875,12.5,1.1266,1.1266,0,0,1,12,11.375Z" />
          <path d="M12,14.625A2.3777,2.3777,0,0,0,9.625,17v1a.375.375,0,0,0,.75,0V17a1.625,1.625,0,0,1,3.25,0v1a.375.375,0,0,0,.75,0V17A2.3777,2.3777,0,0,0,12,14.625Z" />
          <path d="M13,16.625a.3751.3751,0,0,0-.375.375v3a.375.375,0,0,0,.75,0V17A.3751.3751,0,0,0,13,16.625Z" />
          <path d="M11,16.625a.3751.3751,0,0,0-.375.375v3a.375.375,0,0,0,.75,0V17A.3751.3751,0,0,0,11,16.625Z" />
          <path d="M10.625,10.1709a.375.375,0,0,0,.75,0A2.3777,2.3777,0,0,0,9,7.7959H8a2.3777,2.3777,0,0,0-2.375,2.375v4a.375.375,0,0,0,.75,0v-4A1.6262,1.6262,0,0,1,8,8.5459H9A1.6262,1.6262,0,0,1,10.625,10.1709Z" />
          <path d="M17,7.625H13A1.377,1.377,0,0,0,11.625,9a.375.375,0,0,0,.75,0A.6256.6256,0,0,1,13,8.375h4A.6256.6256,0,0,1,17.625,9v6a.375.375,0,0,0,.75,0V9A1.377,1.377,0,0,0,17,7.625Z" />
          <path d="M16.5,12.625a.3751.3751,0,0,0-.375.375v7a.375.375,0,0,0,.75,0V13A.3751.3751,0,0,0,16.5,12.625Z" />
          <path d="M7.5,12.5674a.3751.3751,0,0,0-.375.375V20a.375.375,0,0,0,.75,0V12.9424A.3751.3751,0,0,0,7.5,12.5674Z" />
        </g>
      </g>
    </svg>
  );
}
