/* eslint-disable complexity */
import cx from 'classnames';
import { AnchorLink, ACFAnchorProps } from './global/anchor-link';
import {
  CroppedImage,
  CropProps,
  customLoader,
  WrappedSubtitle,
  ParallaxLayer,
  appliedCloudinaryParams,
} from '@curated-property/utils';
import { sanitize } from '@curated-property/utils';
import { HandleAnimations, hexToRgb } from './functions/helper';
import { HandleWYSIWYGContent, useMediaQuery } from './functions/helper';
import {
  GIS_merge,
  GIS_Gradient,
  GIS_TextAlignment,
  GIS_Padder,
  StyleObject,
} from './functions/global-instance-styles';
import { useState } from 'react';
import { ArrowRight, ArrowLeft } from '@curated-property/icons';
import styles from './css/contentStyle.module.css';
import { useAdobeCarouselEvent } from './global/adobe-analytics';
import {
  MediaBlocksMediaLayoutsInterface,
  mediaBlocksFactory,
} from './media-blocks';

interface IfullWidthImageContent {
  image?: {
    sourceUrl?: string;
    altText?: string;
  };
  title?: string;
  title_noTx?: string;
  subtitle?: string;
  subtitle_noTx?: string;
  copy?: string;
  style?: string;
  buttons?: Array<ACFAnchorProps>;
  showHide?: boolean;
}

export interface IfullWidthImage {
  image?: string;
  imageAlt?: string;
  mediaBlock?: MediaBlocksMediaLayoutsInterface;
  title?: string;
  title_noTx?: string;
  subtitle?: string;
  subtitle_noTx?: string;
  copy?: string;
  style?: string;
  buttons?: Array<ACFAnchorProps>;
  enableCarousel?: boolean;
  carouselItems?: Array<IfullWidthImageContent>;
  globalStyles?: StyleObject;
  instanceStyles?: StyleObject;
  customImageSize?: {
    w?: number;
    h?: number;
  };
  flipImageHorizontally?: boolean;
}

export interface FullWidthImageProps extends CropProps, IfullWidthImage {}

export function FullWidthImage({
  image,
  imageAlt,
  mediaBlock,
  title,
  title_noTx,
  subtitle,
  subtitle_noTx,
  copy,
  style,
  buttons,
  enableCarousel,
  carouselItems,
  enableCropping,
  cropType,
  xPosition,
  xPositionAdvanced,
  yPosition,
  yPositionAdvanced,
  cropWidth,
  cropHeight,
  autoPosition,
  globalStyles,
  instanceStyles,
  customImageSize,
  flipImageHorizontally,
}: FullWidthImageProps) {
  /**
    bg-gradient-to-r
    bg-gradient-to-l
    bg-gradient-to-t
    bg-gradient-to-b
   */
  const [current, setCurrent] = useState(0);
  carouselItems = carouselItems?.filter((item) => !item?.showHide);
  const count = carouselItems?.length || 0;
  const inlineStyles = GIS_merge(globalStyles, instanceStyles);
  const gradientClasses = GIS_Gradient(inlineStyles?.gradientPosition);
  const textAlignment = GIS_TextAlignment(inlineStyles?.textAlignment);
  const paddingStyles = GIS_Padder(
    inlineStyles?.paddingTop,
    inlineStyles?.paddingBottom
  );
  const bgRGB = hexToRgb(inlineStyles?.contentBackgroundColor);
  const background = inlineStyles?.contentBackgroundColor
    ? `rgba(${bgRGB?.r},${bgRGB?.g},${bgRGB?.b},${inlineStyles?.contentBoxBackgroundOpacity})`
    : 'initial';

  let gradientColor = '';
  if (inlineStyles?.contentBackgroundColor) {
    gradientColor = '';
  } else {
    gradientColor = style === 'black' ? 'from-bg' : 'from-text';
  }

  const styleOptions = {
    gradientToggle: !!instanceStyles?.gradientToggle,
    gradientColor: gradientColor,
    gradientClasses: gradientClasses,
    textAlignment: textAlignment,
    background: background,
    backgroundColorOpaque: inlineStyles?.contentBackgroundColor,
    inlineStyles: inlineStyles,
  };

  function next() {
    setCurrent((s) => (s + 1 > count - 1 ? 0 : s + 1));
  }

  function prev() {
    setCurrent((s) => (s - 1 < 0 ? count - 1 : s - 1));
  }

  const animations = HandleAnimations({
    hideAnimation: inlineStyles?.hideAnimations !== 'show',
    start: inlineStyles?.animationDirection
      ? `${inlineStyles?.animationDirection}-8`
      : inlineStyles?.contentBoxHorizontal === 'center'
      ? '-translate-y-8'
      : inlineStyles?.contentBoxHorizontal === 'end'
      ? 'translate-x-8'
      : '-translate-x-8',
    delayOne: 'delay-100',
    delayTwo: 'delay-200',
    delayThree: 'delay-300',
    delayFour: 'delay-500',
    delayFive: 'delay-700',
  });

  let parallaxSpeed = useMediaQuery(1024) ? 10 : 55;
  if (inlineStyles?.contentBoxVertical === 'start') {
    parallaxSpeed *= -1;
  }

  const animationOptions = {
    hideAnimations:
      animations?.hideAnimations || inlineStyles?.hideParallax === 'hide',
    title: animations?.one,
    subtitle: animations?.two,
    copy: animations?.three,
    buttons: animations?.four,
    controls: animations?.five,
    inView: animations?.inView,
    parallaxSpeed: parallaxSpeed,
  };

  return (
    <section
      ref={animations?.ref}
      className={cx(inlineStyles?.showHide && 'hidden', paddingStyles)}
    >
      <div className="cp-fullWidthMediaAndCopyOverlay relative flex flex-col items-center justify-center w-full">
        {enableCarousel ? (
          <FullWidthImageContent
            image={carouselItems?.[current]?.image?.sourceUrl}
            imageAlt={carouselItems?.[current]?.image?.altText}
            title={
              inlineStyles?.headingsNoTranslate
                ? carouselItems?.[current]?.title_noTx
                : carouselItems?.[current]?.title
            }
            subtitle={
              inlineStyles?.headingsNoTranslate
                ? carouselItems?.[current]?.subtitle_noTx
                : carouselItems?.[current]?.subtitle
            }
            copy={carouselItems?.[current]?.copy}
            style={carouselItems?.[current]?.style}
            buttons={carouselItems?.[current]?.buttons}
            styleOptions={styleOptions}
            carousel={true}
            current={current}
            count={count}
            prev={prev}
            next={next}
            animationOptions={animationOptions}
          />
        ) : (
          <FullWidthImageContent
            image={image}
            imageAlt={imageAlt}
            mediaBlock={mediaBlock}
            title={inlineStyles?.headingsNoTranslate ? title_noTx : title}
            subtitle={
              inlineStyles?.headingsNoTranslate ? subtitle_noTx : subtitle
            }
            copy={copy}
            cropping={{
              enableCropping: enableCropping,
              cropType: cropType,
              xPosition: xPosition,
              xPositionAdvanced: xPositionAdvanced,
              yPosition: yPosition,
              yPositionAdvanced: yPositionAdvanced,
              cropWidth: cropWidth,
              cropHeight: cropHeight,
              autoPosition: autoPosition,
            }}
            style={style}
            buttons={buttons}
            styleOptions={styleOptions}
            animationOptions={animationOptions}
            customImageSize={customImageSize}
            flipImageHorizontally={flipImageHorizontally}
          />
        )}
      </div>
    </section>
  );
}

interface FullWidthContentProps {
  image?: string;
  imageAlt?: string;
  mediaBlock?: MediaBlocksMediaLayoutsInterface;
  title?: string;
  subtitle?: string;
  copy?: string;
  style?: string;
  cropping?: CropProps;
  buttons?: Array<ACFAnchorProps>;
  styleOptions?: {
    gradientToggle?: boolean;
    gradientColor?: string;
    gradientClasses?: string;
    textAlignment?: string;
    background?: string;
    inlineStyles?: any;
    backgroundColorOpaque?: string;
  };
  carousel?: boolean;
  current?: number;
  count?: number;
  prev?: () => void;
  next?: () => void;
  animationOptions?: {
    hideAnimations?: boolean;
    title?: string;
    subtitle?: string;
    copy?: string;
    buttons?: string;
    controls?: string;
    inView?: boolean;
    parallaxSpeed?: number;
  };
  customImageSize?: IfullWidthImage['customImageSize'];
  flipImageHorizontally?: boolean;
}

function FullWidthImageContent({
  image,
  imageAlt,
  mediaBlock,
  title,
  subtitle,
  copy,
  style,
  buttons,
  cropping,
  styleOptions,
  carousel,
  current,
  count = 0,
  prev,
  next,
  animationOptions,
  customImageSize,
  flipImageHorizontally,
}: FullWidthContentProps) {
  let padding: string, maxWidth: string;
  switch (styleOptions?.inlineStyles?.contentBoxPadding) {
    case 'small':
      padding = 'lg:p-6';
      maxWidth = 'lg:max-w-lg';
      break;
    case 'medium':
      padding = 'lg:p-10';
      maxWidth = 'lg:max-w-xl';
      break;
    case 'large':
      padding = 'lg:p-20 xl:p-32';
      maxWidth = 'lg:max-w-screen-sm';
      break;
    default:
      padding = 'lg:p-6';
      maxWidth = 'lg:max-w-lg';
  }

  useAdobeCarouselEvent({
    sectionHeading: title,
    currentIndex: (current || 0) + 1,
    totalCount: count,
  });

  const mq1024 = useMediaQuery(1024);

  // since we have the option to change the graident & have no bg colour selected
  // if no background colour, we need to give the text a background for mobile.
  const bg = style === 'white' ? '#000' : '#fff';
  const bgColourWhenNoneSelected =
    styleOptions?.background === 'initial'
      ? bg
      : styleOptions?.backgroundColorOpaque;

  const contentBgAllWithOpacity = !styleOptions?.inlineStyles
    ?.contentBackgroundImage?.sourceUrl
    ? styleOptions?.background || undefined
    : `url('${appliedCloudinaryParams(
        styleOptions?.inlineStyles?.contentBackgroundImage?.sourceUrl,
        styleOptions?.inlineStyles?.contentBackgroundRepeat
      )}')
    ${styleOptions?.inlineStyles?.contentBackgroundPosition || 'center'} /  ${
        styleOptions?.inlineStyles?.contentBackgroundSize || 'cover'
      }  
     ${styleOptions?.inlineStyles?.contentBackgroundRepeat || 'no-repeat'}   ${
        styleOptions?.background || bgColourWhenNoneSelected
      }`;

  const contentBgAllOpaque = !styleOptions?.inlineStyles?.contentBackgroundImage
    ?.sourceUrl
    ? styleOptions?.backgroundColorOpaque || undefined
    : `url('${appliedCloudinaryParams(
        styleOptions?.inlineStyles?.contentBackgroundImage?.sourceUrl,
        styleOptions?.inlineStyles?.contentBackgroundRepeat
      )}')
    ${styleOptions?.inlineStyles?.contentBackgroundPosition || 'center'} /  ${
        styleOptions?.inlineStyles?.contentBackgroundSize || 'cover'
      }  
     ${styleOptions?.inlineStyles?.contentBackgroundRepeat || 'no-repeat'}   ${
        styleOptions?.backgroundColorOpaque || bgColourWhenNoneSelected
      }`;

  let gradientClasses = styleOptions?.gradientClasses || '';

  gradientClasses =
    gradientClasses?.indexOf('bg-gradient-to-') > -1 &&
    !styleOptions?.gradientColor
      ? `${gradientClasses} from-text`
      : gradientClasses;

  return (
    <>
      <div
        data-testid="full-width-media-and-copy-overlay"
        className={cx(
          styleOptions?.gradientToggle ? styleOptions?.gradientColor : '',
          styleOptions?.gradientToggle ? gradientClasses : '',
          'to-transparent lg:absolute z-2'
        )}
      ></div>
      {title && copy && (
        <div
          data-testid="fullWidthMediaAndCopyContainer"
          className={cx(
            'lg:bg-transparent lg:absolute z-2 flex h-full container py-4 order-2 lg:overflow-hidden pointer-events-none',
            styleOptions?.inlineStyles?.contentBoxHorizontal
              ? `justify-${styleOptions?.inlineStyles?.contentBoxHorizontal}`
              : 'justify-start',
            styleOptions?.inlineStyles?.contentBoxVertical
              ? `items-${styleOptions?.inlineStyles?.contentBoxVertical}`
              : 'items-center',
            styleOptions?.textAlignment && `text-${styleOptions?.textAlignment}`
          )}
          style={{
            background: !mq1024 ? undefined : contentBgAllOpaque,
          }}
        >
          <ParallaxLayer
            hideAnimations={animationOptions?.hideAnimations}
            inView={animationOptions?.inView}
            parallaxSpeed={animationOptions?.parallaxSpeed}
          >
            <div
              className="pointer-events-auto"
              style={{
                background: !mq1024 ? contentBgAllWithOpacity : undefined,
              }}
            >
              <div
                aria-live="polite"
                className={cx(
                  'in flex flex-col relative z-1 py-2',
                  maxWidth,
                  padding
                )}
              >
                <h2
                  className={cx(
                    'text-2xl font-black leading-none font-headline md:text-4xl text-balance break-normal',
                    animationOptions?.title
                  )}
                  dangerouslySetInnerHTML={{
                    __html: sanitize(title || ''),
                  }}
                  style={{
                    color: styleOptions?.inlineStyles?.titleColor,
                  }}
                ></h2>
                <WrappedSubtitle>
                  <p
                    className={cx(
                      'text-lg font-bold leading-none',
                      animationOptions?.subtitle,
                      {
                        'mt-12': subtitle,
                      }
                    )}
                    dangerouslySetInnerHTML={{
                      __html: sanitize(subtitle || ''),
                    }}
                    style={{
                      color: styleOptions?.inlineStyles?.subtitleColor,
                    }}
                  ></p>
                </WrappedSubtitle>
                <div
                  className={cx(
                    'my-6 text-base lg:text-lg',
                    styles.listStyle,
                    styles.paragraphStyle,
                    animationOptions?.copy
                  )}
                  style={{
                    color: styleOptions?.inlineStyles?.textColor,
                  }}
                  dangerouslySetInnerHTML={{
                    __html: HandleWYSIWYGContent(
                      copy,
                      styleOptions?.inlineStyles?.textColor
                    ),
                  }}
                ></div>
                {buttons ? (
                  <div className={cx('mt-0', animationOptions?.buttons)}>
                    {buttons?.map((link, key) => {
                      return (
                        <AnchorLink
                          key={key}
                          url={link?.link?.url}
                          title={link?.link?.title}
                          target={link?.link?.target}
                          buttonStyle={link?.buttonStyle ?? 'primary'}
                          sectionHeading={title}
                        />
                      );
                    })}
                  </div>
                ) : null}
                {carousel && (
                  <div className={animationOptions?.controls}>
                    <ControlsCompact
                      current={current}
                      count={count}
                      prev={prev}
                      next={next}
                      styleOptions={{
                        uiPrimaryColour:
                          styleOptions?.inlineStyles?.uiPrimaryColour,
                        uiSecondaryColour:
                          styleOptions?.inlineStyles?.uiSecondaryColour,
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
          </ParallaxLayer>
        </div>
      )}
      <div className="w-full text-white order-1">
        {mediaBlock ? (
          <div className="w-full aspect-[1280/573]">
            {mediaBlocksFactory(mediaBlock, 1280)}
          </div>
        ) : (
          <div
            className={cx({ 'transform scale-x-[-1]': flipImageHorizontally })}
          >
            <CroppedImage
              loader={() => {
                return customLoader({
                  src: image,
                  width: customImageSize?.w || 2560,
                  height: customImageSize?.h || 1146,
                  crop: cropping?.enableCropping,
                  cropType: cropping?.cropType,
                  cropHeight: cropping?.cropHeight,
                  cropWidth: cropping?.cropWidth,
                  xPosition: cropping?.xPosition,
                  xPositionAdvanced: cropping?.xPositionAdvanced,
                  yPosition: cropping?.yPosition,
                  yPositionAdvanced: cropping?.yPositionAdvanced,
                  autoPosition: cropping?.autoPosition,
                });
              }}
              src={image || ''}
              className="w-full"
              layout="responsive"
              width={customImageSize?.w || 2560}
              height={customImageSize?.h || 1146}
              alt={imageAlt}
            />
          </div>
        )}
      </div>
    </>
  );
}

interface ControlsProps {
  current?: number;
  count: number;
  prev?: () => void;
  next?: () => void;
  styleOptions?: {
    uiPrimaryColour?: string;
    uiSecondaryColour?: string;
  };
}

function ControlsCompact({
  current,
  count,
  prev,
  next,
  styleOptions,
}: ControlsProps) {
  const pagerButtons: JSX.Element[] = [];
  for (let i = 0; i < count; i++) {
    const pagerBorderColour =
      i === current
        ? styleOptions?.uiPrimaryColour
        : styleOptions?.uiSecondaryColour;
    pagerButtons.push(
      <div
        key={i}
        data-testid="pagerButtons"
        className={cx(
          'w-14 border-t-3',
          i === current ? 'border-t-6 border-primary' : 'border-bg-disabled'
        )}
        style={{
          borderColor: pagerBorderColour ? pagerBorderColour : '',
        }}
      ></div>
    );
  }
  return (
    <div
      className="flex justify-between w-full mt-4"
      data-testid="carouselControls"
    >
      <div className="items-center flex w-44 sm:w-auto xl:w-64">
        {pagerButtons}
      </div>
      <div className="flex text-primary">
        <button aria-label="Left" onClick={prev} data-testid="arrowLeft">
          <ArrowLeft
            className="h-5 w-5 fill-current"
            fillColor={styleOptions?.uiPrimaryColour}
          />
        </button>
        <div
          className="text-lg p-2"
          style={{ color: styleOptions?.uiPrimaryColour }}
        >
          <span className="font-black" data-testid="currentIndex">
            {(current || 0) + 1}
          </span>
          <span style={{ color: styleOptions?.uiSecondaryColour }}> / </span>
          <span data-testid="totalCount">{count}</span>
        </div>
        <button aria-label="Right" onClick={next} data-testid="arrowRight">
          <ArrowRight
            className="h-5 w-5 fill-current"
            fillColor={styleOptions?.uiPrimaryColour}
          />
        </button>
      </div>
    </div>
  );
}
