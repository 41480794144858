import React from 'react';
export function Beach({
  className,
  fillColor,
}: {
  className?: string;
  fillColor?: string;
}) {
  return (
    <svg className={className} viewBox="0 0 24 24" style={{ fill: fillColor }}>
      <g data-name="Bounding Box">
        <path style={{ fill: 'none' }} d="M0,0V24H24V0Z" />
      </g>
      <g data-name="Icons Outlined">
        <g>
          <path d="M17.1653,6.8347l0-.0007a7.3169,7.3169,0,0,0-10.33,0l0,0a.3736.3736,0,0,0-.11.2644l0,.0007,0,0a.3737.3737,0,0,0,.1091.264l0,.0007,0,0a.3739.3739,0,0,0,.2654.11A2.3655,2.3655,0,0,1,9.5254,9.9014a95.6091,95.6091,0,0,1,.0232.1121.356.356,0,0,0,.0688.1243c.0065.0077.01.018.0171.0251l.0012.0031.004.0017c.0064.0061.0151.0089.0218.0146a.3529.3529,0,0,0,.0662.0444.3664.3664,0,0,0,.0587.0248.3723.3723,0,0,0,.0407.0171,5.1589,5.1589,0,0,1,3.9053,3.9052c.0015.0073.0089.0107.0108.0178a.3708.3708,0,0,0,.0905.172l0,.0007,0,0c.0067.0067.0163.01.0235.016s.0168.0109.0258.0174a.3659.3659,0,0,0,.2161.0768,2.3655,2.3655,0,0,1,2.4258,2.4268.3729.3729,0,0,0,.1091.2615l.0012.0031.004.0017a.3732.3732,0,0,0,.2561.1058l.0046.0019.0037-.0015a.3724.3724,0,0,0,.2619-.1089,7.315,7.315,0,0,0,0-10.33Zm-9.181-.009a6.5629,6.5629,0,0,1,7.2944-.4939,10.0748,10.0748,0,0,0-5.1071,2.79A2.9237,2.9237,0,0,0,7.9843,6.8257Zm2.6742,2.888c2.5747-2.3654,5.1591-3.165,5.9766-2.3488.8177.82.011,3.4176-2.3705,6A5.9063,5.9063,0,0,0,10.6585,9.7137Zm6.4959,6.3288a2.91,2.91,0,0,0-2.2591-2.2331,10.0538,10.0538,0,0,0,2.7732-5.0869A6.5653,6.5653,0,0,1,17.1544,16.0425Z" />
          <path d="M11.0342,12.4346l-6.3,6.3a.3756.3756,0,0,0,.5312.5312l6.3-6.3a.3756.3756,0,1,0-.5312-.5312Z" />
          <path d="M18.3,6.0752a.3745.3745,0,0,0,.2656-.1094l.7-.7a.3756.3756,0,0,0-.5312-.5312l-.7.7a.375.375,0,0,0,.2656.6406Z" />
        </g>
      </g>
    </svg>
  );
}
