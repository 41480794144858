import React from 'react';
export function Award({
  className,
  fillColor,
}: {
  className?: string;
  fillColor?: string;
}) {
  return (
    <svg className={className} viewBox="0 0 24 24" style={{ fill: fillColor }}>
      <g data-name="Bounding Box">
        <path style={{ fill: 'none' }} d="M0,0V24H24V0Z" />
      </g>
      <g data-name="Icons Outlined">
        <g>
          <path d="M12,3.625A5.375,5.375,0,1,0,17.375,9,5.3809,5.3809,0,0,0,12,3.625Zm0,10A4.625,4.625,0,1,1,16.625,9,4.6308,4.6308,0,0,1,12,13.625Z" />
          <path d="M15.3564,8.0635a.3743.3743,0,0,0-.3486-.2588l-1.998-.04L12.3545,5.877a.3752.3752,0,0,0-.709.246l.7412,2.1338a.3753.3753,0,0,0,.3467.252l1.18.0234-.94.7129a.3756.3756,0,0,0-.1328.4072l.3428,1.13-.97-.6738a.3733.3733,0,0,0-.4278,0l-.97.6738.3428-1.13a.3756.3756,0,0,0-.1328-.4072l-.94-.7129,1.18-.0234a.375.375,0,0,0-.0078-.75H11.251l-2.2588.0459a.3751.3751,0,0,0-.2188.6738l1.5918,1.207-.5791,1.9122a.3748.3748,0,0,0,.5733.416L12,10.873l1.6406,1.1407a.3748.3748,0,0,0,.5733-.416l-.5791-1.9122,1.5918-1.207A.375.375,0,0,0,15.3564,8.0635Z" />
          <path d="M15,14.625a.3751.3751,0,0,0-.375.375v4.2988l-2.417-1.6113a.3749.3749,0,0,0-.416,0L9.375,19.2988V15a.375.375,0,0,0-.75,0v5a.3751.3751,0,0,0,.583.3125L12,18.4512l2.792,1.8613a.3776.3776,0,0,0,.3848.0186A.3756.3756,0,0,0,15.375,20V15A.3751.3751,0,0,0,15,14.625Z" />
        </g>
      </g>
    </svg>
  );
}
