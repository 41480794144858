import dynamic from 'next/dynamic';

const HotelLogo = dynamic<{ className?: string; fillColor?: string }>(() =>
  import('./jhmgwwa/hotel-logo').then((i) => i.HotelLogo)
);

export const hotelLogoHeader = (
  <HotelLogo
    className="fill-current antialiased w-40 sm:w-60 sm:pl-2 lg:w-64 lg:pr-2"
    fillColor="#20212E"
  />
);

export const hotelLogoFooter = (
  <HotelLogo
    className="fill-current antialiased w-40 sm:w-60 lg:w-64 lg:w-40"
    fillColor="#FFFFFF"
  />
);
