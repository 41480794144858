import dynamic from 'next/dynamic';

const HotelLogo = dynamic<{ className?: string; fillColor?: string }>(() =>
  import('./bjvtrdi/hotel-logo').then((i) => i.HotelLogo)
);

export const hotelLogoHeader = (
  <HotelLogo className="text-bg antialiased h-24" fillColor="#09194E" />
);

export const hotelLogoFooter = (
  <HotelLogo className="text-bg antialiased h-24" fillColor="#FAFAFA" />
);
