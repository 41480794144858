import React, { useState, useEffect } from 'react';
import Link from 'next/link';
import cx from 'classnames';
import { NavDesktop } from './global/nav-desktop';
import { NavMobile } from './global/nav-mobile';
import { useLanguage, useLinkContext } from '@curated-property/utils';
import {
  NavUtility,
  INavUtilityStylingOptionProps,
} from './global/nav-utility';
import { useWindowScroll, useWindowSize } from './functions/helper';
import { useTranslation } from 'next-i18next';
import { BookingWidgetButton } from './global/booking-widget-button';
import {
  OSCLanguageSelector,
  TranslationOverrideProps,
} from './global/osc-language-switcher';
import { IalertsData, PropertyAlert } from './property-alerts/alert';
import { HonorsSignIn } from './includes/honors-sign-in';
import OscComposableSearchForm, {
  GroupBooking,
} from './osc-composable/osc-composable-search-form';

export interface InavMenuItem {
  label?: string;
  label_noTx?: string;
  url?: string;
  noTx?: boolean;
  childItems?: Array<InavMenuItem> | null;
  icon?: string;
  location?: string[];
  pageId?: number;
}

export interface langSwitchButtonStyles {
  backgroundColour?: string;
  backgroundColourHover?: string;
  textCase?: string;
  textColour?: string;
  textColourHover?: string;
}

export interface HeaderStyles {
  headerClassName?: string;
  navStyles?: string;
  textColorOnLoad?: string;
  bgColorOnLoad?: string;
  textColorOnActive?: string;
  bgColorOnActive?: string;
  bgSubmenuColor?: string;
  separatorColor?: string;
  separatorClasses?: string;
  fontWeight?: string;
  menuItems?: {
    colorOnHover?: string;
    paddingClasses?: string;
    fontSize?: string;
    case?: string;
    underline?: {
      active?: boolean;
      color?: string;
      size?: string;
    };
    itemActive?: {
      classes?: string;
      bgColor?: string;
      bgText?: string;
    };
  };
  iconMenu?: INavUtilityStylingOptionProps;
  honorsSignIn?: {
    backgroundColor?: string;
    backgroundHighlightColor?: string;
    textColor?: string;
    textHighlightColor?: string;
  };
  bookingButton?: {
    mainClasses?: string;
    extraClasses?: string;
    buttonStyle?: string;
    backgroundColor?: string;
    backgroundColorHover?: string;
    textColor?: string;
    textColorHover?: string;
    borderColor?: string;
    borderColorHover?: string;
    textCase?: string;
    hideBookingWidget?: boolean;
  };
  languageSwitchButtonStyles?: langSwitchButtonStyles;
  languageSwitchButtonStylesActive?: langSwitchButtonStyles;
}

export interface LogoSettingsProps {
  brandLogoClasses?: string;
  brandLogoClassesFooter?: string;
  brandLogoColourActive?: string;
  brandLogoColourFooter?: string;
  brandLogoColourInactive?: string;
  hotelLogo: {
    altText?: string;
    sourceUrl?: string;
  };
  brandLogo: {
    altText?: string;
    sourceUrl?: string;
  };
  hotelLogoClasses?: string;
  hotelLogoClassesFooter?: string;
  hotelLogoColourActive?: string;
  hotelLogoColourFooter?: string;
  hotelLogoColourInactive?: string;
}

export interface ModalSettings {
  modalBackgroundColour?: string;
  modalBackgroundOpacity?: number;
  modalCloseButtonBackgroundColour?: string;
  modalCloseButtonIconColour?: string;
}

export interface CMSOptions {
  backgroundColour?: string;
  backgroundColourHover?: string;
  borderColour?: string;
  borderColourHover?: string;
  dividerColour?: string;
  dividerColourHover?: string;
  backgroundBlur?: string;
  buttonStyle?: string;
  fieldGroupName?: string;
  hideBookingWidget?: string;
  textCase?: string;
  textColour?: string;
  textColourHover: string;
}

export interface LangSelectorProps {
  colors?: {
    languageNavAccentColour?: string;
    languageNavTextColour?: string;
    buttonBackgroundColour?: string;
    buttonTextColour?: string;
    buttonTextHoverColour?: string;
  };
}

interface ShopFormInlineStyles extends React.CSSProperties {
  '--border-color'?: string;
}

export interface MenuProps {
  activePageId?: number;
  pageSlug?: string | undefined;
  menu?: Array<InavMenuItem>;
  iconMenu?: Array<InavMenuItem>;
  languageMenu?: LangSelectorProps;
  locale?: string;
  isFrontPage?: boolean;
  navKey?: string;
  CTYHOCN: string;
  brandCode?: string;
  currency?: string;
  defaultArrivalDate?: string;
  gmtHours?: number;
  associatedHotels?: {
    hotel?: {
      name?: string;
      ctyhocn?: string;
    };
  }[];
  showWidgetOnHomepageLoad?: boolean;
  hotelLogo?: React.ReactNode;
  brandLogo?: React.ReactNode;
  bookingButtonText?: any;
  hotelDates: {
    hotelOpen: boolean;
    preOpenMsg?: string;
    resEnabled: boolean;
    openDate: string;
    resEnabledDate: string;
  };
  hotelAlerts?: Array<IalertsData>;
  alertBarSettings?: {
    backgroundColor?: string;
    linkColor?: string;
    textColor?: string;
  };
  title?: string;
  headerSettings?: any;
  modalSettings?: any;
  styleOptions?: HeaderStyles;
  cmsLogoSettings?: LogoSettingsProps;
  cmsTranslationOverrides?: Array<TranslationOverrideProps>;
  bookingWidgetConfig?: {
    ctyhocn?: string;
    defaultArrivalDate?: string;
    gmtHours?: number;
    ageRange?: {
      min: number;
      max: number;
    };
    resEnabled?: boolean | null;
    brandCode?: string;
    currency?: string;
    associatedHotels?: {
      hotel?: {
        name?: string;
        ctyhocn?: string;
      };
    }[];
    shopFormInHeader?: boolean;
    oscBookButtonStyle?: string;
    backgroundColor?: string;
  };
  maxRooms?: number;
  groupBooking?: GroupBooking;
  hideGroupLink?: boolean;
}

export function Header({
  activePageId,
  pageSlug,
  menu,
  iconMenu,
  languageMenu,
  locale,
  isFrontPage,
  navKey,
  defaultArrivalDate,
  gmtHours,
  associatedHotels,
  hotelDates,
  hotelLogo,
  brandLogo,
  CTYHOCN,
  brandCode,
  currency,
  bookingButtonText,
  hotelAlerts,
  alertBarSettings,
  title,
  headerSettings,
  modalSettings,
  styleOptions,
  cmsLogoSettings,
  cmsTranslationOverrides,
  bookingWidgetConfig,
  maxRooms,
  groupBooking,
  hideGroupLink,
}: MenuProps) {
  const { itemPrefix: urlPrefix } = useLinkContext();
  const [headerActive, setHeaderActive] = useState(false);
  const [headerScroll, setHeaderScroll] = useState(false);
  const wWidth = useWindowSize().width || 0;
  const bgBlur = headerSettings?.SettingsHeaderSettings?.backgroundBlur;

  const lang = useLanguage();
  const { t } = useTranslation();
  const scrollDistance = useWindowScroll().scrollY || 0;

  // Set Nav appearance colors based on either CMS controls or hard-coded app props
  const headerCmsSettings = headerSettings?.SettingsHeaderSettings;

  // Background
  const headerBgOnLoad =
    headerCmsSettings?.backgroundColour || styleOptions?.bgColorOnLoad;
  let headerBgOnActive =
    headerCmsSettings?.backgroundColourActive || styleOptions?.bgColorOnActive;
  // If there is no active background color but there is a color set for load, use the load color for the active state as well.
  if (
    headerBgOnLoad &&
    !headerCmsSettings?.backgroundColourActive &&
    !styleOptions?.bgColorOnActive
  ) {
    headerBgOnActive = headerBgOnLoad;
  }

  // Text
  const textColourOnLoad =
    headerCmsSettings?.textColour || styleOptions?.textColorOnLoad;
  const textColourOnActive =
    headerCmsSettings?.textColourActive || styleOptions?.textColorOnActive;

  // Additional items - navigation
  const mainNavStyles = headerCmsSettings?.mainNavStyles;
  const mainNavUnderlineColor =
    mainNavStyles?.menuItemUnderlineColour ||
    styleOptions?.menuItems?.underline?.color;
  const mainNavUnderlineActive =
    mainNavStyles?.menuItemUnderlineToggle ||
    styleOptions?.menuItems?.underline?.active;
  const mainNavSubNavBgColor =
    mainNavStyles?.submenuBackgroundColour || styleOptions?.bgSubmenuColor;
  const fontWeight = mainNavStyles?.fontWeight || styleOptions?.fontWeight;
  const fontCase = mainNavStyles?.fontCase || styleOptions?.menuItems?.case;
  const fontSize = mainNavStyles?.fontSize || styleOptions?.menuItems?.fontSize;
  let itemPadding =
    mainNavStyles?.menuItemPadding || styleOptions?.menuItems?.paddingClasses;
  if (!itemPadding) itemPadding = `px-2 xl:px-3 py-2`;
  // Underline, Background Colour, nothing
  const activeMenuItemDisplayType = mainNavStyles?.menuItemActiveState || '';
  // Icon Nav Underline
  const cmsIconNavUnderline = headerCmsSettings?.iconNavStyles?.underline;
  let iconNavItemUnderline =
    !cmsIconNavUnderline || cmsIconNavUnderline === undefined ? false : true;
  // CMS control for underline, which should take precedence
  const styleOptIconNavUnderline = styleOptions?.iconMenu?.underline;
  iconNavItemUnderline =
    !styleOptIconNavUnderline || styleOptIconNavUnderline === undefined
      ? false
      : true;

  useEffect(() => {
    if (scrollDistance > 50) {
      setHeaderScroll(true);
    } else {
      setHeaderScroll(false);
    }
  }, [scrollDistance]);

  useEffect(() => {
    const hotelLogo = document?.getElementById('hotel-logo');
    const brandLogo = document?.getElementById('brand-logo');
    if (cmsLogoSettings?.hotelLogo?.sourceUrl && hotelLogo) {
      headerScroll || headerActive
        ? (hotelLogo.style.backgroundColor =
            cmsLogoSettings?.hotelLogoColourActive || '')
        : (hotelLogo.style.backgroundColor =
            cmsLogoSettings?.hotelLogoColourInactive || '');
    }

    if (cmsLogoSettings?.brandLogo?.sourceUrl && brandLogo) {
      headerScroll || headerActive
        ? (brandLogo.style.backgroundColor =
            cmsLogoSettings?.brandLogoColourActive || '')
        : (brandLogo.style.backgroundColor =
            cmsLogoSettings?.brandLogoColourInactive || '');
    }

    // Removes focus from any page elements upon a new page load.
    if (document.activeElement instanceof HTMLElement) {
      if (document.activeElement.id === 'logoLink')
        document.activeElement.blur();
    }
  });

  const headerBgColors =
    headerActive || headerScroll
      ? headerBgOnActive || 'rgba(10, 57, 113, 0.98)'
      : headerBgOnLoad || 'rgba(42, 58, 78, 0.7)';
  const headerTextColors =
    headerActive || headerScroll
      ? textColourOnActive || '#fff'
      : textColourOnLoad || '#fff';
  const headerDividerColors =
    (headerActive || headerScroll
      ? headerCmsSettings?.dividerColourActive
      : headerCmsSettings?.dividerColour) || headerTextColors;
  const headerIconMenuColors =
    headerActive || headerScroll
      ? textColourOnActive || '#fff'
      : textColourOnLoad || '#fff';

  const languageSelectorLabelColor =
    headerCmsSettings?.languageSwitchTitleStyles?.textColour ||
    headerTextColors;

  const headerIconMenuColorsMobile = styleOptions?.textColorOnActive || '#fff';
  const hideSignInBorder = iconMenu?.length || 0 > 0 ? false : true;

  const iconNavProps = {
    iconMenu: iconMenu,
    activeHeader: setHeaderActive,
    urlPrefix: urlPrefix,
    stylingOptions: {
      extraClasses: styleOptions?.iconMenu?.extraClasses,
      fontSize: styleOptions?.iconMenu?.fontSize,
      color:
        headerActive || headerScroll
          ? styleOptions?.iconMenu?.activeColor || headerIconMenuColors
          : styleOptions?.iconMenu?.color || headerIconMenuColors,
      iconColor:
        headerActive || headerScroll
          ? styleOptions?.iconMenu?.activeIconColor || headerIconMenuColors
          : styleOptions?.iconMenu?.iconColor || headerIconMenuColors,
      separatorColor: styleOptions?.separatorColor,
      underline: iconNavItemUnderline,
      underlineHover: styleOptions?.iconMenu?.underlineHover,
    },
  };

  const LanguageSelectorStyleProps = {
    textColour:
      headerActive || headerScroll
        ? headerSettings?.SettingsHeaderSettings
            ?.languageSwitchButtonStylesActive?.textColour ||
          headerCmsSettings?.languageSwitchButtonStyles?.textColour
        : headerCmsSettings?.languageSwitchButtonStyles?.textColour,
    textCase: headerCmsSettings?.languageSwitchButtonStyles?.textCase,
    backgroundColour:
      headerActive || headerScroll
        ? headerSettings?.SettingsHeaderSettings
            ?.languageSwitchButtonStylesActive?.backgroundColour ||
          headerCmsSettings?.languageSwitchButtonStyles?.backgroundColour
        : headerCmsSettings?.languageSwitchButtonStyles?.backgroundColour,
    textColourHover:
      headerActive || headerScroll
        ? headerSettings?.SettingsHeaderSettings
            ?.languageSwitchButtonStylesActive?.textColourHover ||
          headerCmsSettings?.languageSwitchButtonStyles?.textColourHover
        : headerCmsSettings?.languageSwitchButtonStyles?.textColourHover,
    backgroundColourHover:
      headerActive || headerScroll
        ? headerSettings?.SettingsHeaderSettings
            ?.languageSwitchButtonStylesActive?.backgroundColourHover ||
          headerCmsSettings?.languageSwitchButtonStyles?.backgroundColourHover
        : headerCmsSettings?.languageSwitchButtonStyles?.backgroundColourHover,
    labelTextColour:
      headerActive || headerScroll
        ? headerCmsSettings?.languageSwitchTitleStyles?.textColourActive ||
          languageSelectorLabelColor
        : languageSelectorLabelColor,
    labelTextCase: headerCmsSettings?.languageSwitchTitleStyles?.textCase,
    defaultTextColour: headerTextColors,
    accentColour: languageMenu?.colors?.languageNavAccentColour,
  };

  const shopFormStyle: ShopFormInlineStyles = {
    borderColor: headerDividerColors,
    '--border-color': headerDividerColors,
    backgroundColor:
      (!bgBlur || wWidth < 1024) && bookingWidgetConfig?.backgroundColor,
  };

  return (
    <div>
      {/* skip to content */}
      <a
        id="skip-to-content"
        href="#main"
        className="skip-to-content w-0 h-0 block overflow-hidden"
      >
        {t('skipToContent')}
      </a>
      <header
        onMouseEnter={() => setHeaderActive(true)}
        onMouseLeave={() => setHeaderActive(false)}
        data-testid="header-wrapper"
        className={cx(
          'cp-header relative xl:fixed w-full transition duration-200 ease-in-out z-[50]',
          headerScroll && 'cp-header--scroll',
          headerActive && 'cp-header--active',
          styleOptions?.headerClassName
        )}
        style={{
          background: headerBgColors,
        }}
      >
        <div
          className="absolute inset-0 bg-transparent -z-10"
          style={{
            backdropFilter: !headerScroll && `blur(${bgBlur || 0}px)`,
          }}
        />
        {pageSlug ? (
          <PropertyAlert
            {...hotelDates}
            alerts={hotelAlerts}
            alertBarSettings={alertBarSettings}
            modalSettings={modalSettings}
          />
        ) : null}
        <div className="flex justify-end mb-4 relative">
          <div
            role="application"
            className="container absolute z-10 top-0 right-0 left-0 border-b pb-1 sm:border-b-0"
            style={{ borderColor: headerDividerColors }}
          >
            <div
              className={`flex justify-end ${
                styleOptions?.iconMenu?.fontSize || 'text-sm'
              }`}
            >
              <NavUtility {...iconNavProps} />
              <HonorsSignIn
                hideBorder={hideSignInBorder}
                fontSize={styleOptions?.iconMenu?.fontSize}
                underline={iconNavItemUnderline}
                textColor={
                  headerActive || headerScroll
                    ? styleOptions?.iconMenu?.activeColor ||
                      headerIconMenuColors
                    : styleOptions?.iconMenu?.color || headerIconMenuColors
                }
                iconColor={
                  headerActive || headerScroll
                    ? styleOptions?.iconMenu?.activeIconColor ||
                      headerIconMenuColors
                    : styleOptions?.iconMenu?.iconColor || headerIconMenuColors
                }
                ddTextColor={styleOptions?.honorsSignIn?.textColor}
                ddTextHighlightColor={
                  styleOptions?.honorsSignIn?.textHighlightColor
                }
                ddBackgroundColor={styleOptions?.honorsSignIn?.backgroundColor}
                ddBackgroundHighlightColor={
                  styleOptions?.honorsSignIn?.backgroundHighlightColor
                }
              />
              <OSCLanguageSelector
                locale={locale || ''}
                CTYHOCN={CTYHOCN}
                languageSelectorStyles={LanguageSelectorStyleProps}
                cmsTranslationOverrides={cmsTranslationOverrides}
              />
            </div>
          </div>
        </div>
        <div
          className="container flex items-center justify-between  border-current h-32 pt-8 sm:pt-0 sm:h-28"
          style={{ color: headerTextColors }}
        >
          {/* LOGO */}
          <section
            className={cx('cursor-pointer outline-none flex', {
              'pointer-events-none': isFrontPage,
            })}
          >
            <Link
              href={`/${lang}${urlPrefix}`}
              data-testid="logo-link"
              id="logoLink"
            >
              <div className="flex items-center justify-center">
                {hotelLogo ? (
                  <div id="hotel-logo-button" data-testid="hotel-logo">
                    {hotelLogo}
                    <span className="sr-only">{title}</span>
                  </div>
                ) : (
                  false
                )}
                {brandLogo ? (
                  <div
                    data-testid="brand-logo"
                    className="border-l"
                    style={{
                      borderColor: headerTextColors,
                    }}
                  >
                    {brandLogo}
                  </div>
                ) : (
                  false
                )}
              </div>
            </Link>
          </section>

          {/* MAIN NAV / ICON NAV INLINE / BOOKING BUTTON */}
          <section
            className={cx(
              'w-full h-full rtl:lg:w-auto flex  mt-0 pt-4 sm:pt-0 sm:mt-4 items-end pb-4 sm:items-center sm:pb-0'
            )}
          >
            <div className="w-full h-auto sm:h-full rtl:lg:w-auto">
              <div
                className={`flex w-full h-full xl:hidden ${styleOptions?.navStyles}`}
              >
                <NavMobile
                  key={navKey}
                  menuItems={menu}
                  iconMenu={iconMenu}
                  bgColor={headerBgOnActive}
                  textColorOnActive={textColourOnActive}
                  urlPrefix={urlPrefix}
                  shopFormInHeader={bookingWidgetConfig?.shopFormInHeader}
                  stylingOptions={{
                    fontWeight: fontWeight,
                    case: fontCase || '',
                    iconMenu: {
                      color: headerIconMenuColorsMobile,
                      separatorColor:
                        styleOptions?.iconMenu?.color ??
                        styleOptions?.separatorColor,
                      underline: styleOptions?.iconMenu?.underline,
                    },
                  }}
                />
              </div>
              <div
                className={cx(
                  'hidden h-full xl:w-full xl:flex rtl:w-auto rtl:lg:inline-flex',
                  styleOptions?.navStyles
                    ? styleOptions?.navStyles
                    : '2xl:text-lg'
                )}
              >
                <NavDesktop
                  key={navKey}
                  activePageId={activePageId}
                  menuItems={menu}
                  urlPrefix={urlPrefix}
                  activeHeader={setHeaderActive}
                  stylingOptions={{
                    bgSubmenuColor: mainNavSubNavBgColor,
                    colorOnHover: styleOptions?.menuItems?.colorOnHover,
                    underlineActive: mainNavUnderlineActive,
                    underlineColor: mainNavUnderlineColor,
                    underlineSize: styleOptions?.menuItems?.underline?.size,
                    itemActiveClasses:
                      styleOptions?.menuItems?.itemActive?.classes,
                    itemActiveBgColor:
                      styleOptions?.menuItems?.itemActive?.bgColor,
                    itemActiveTextColor:
                      styleOptions?.menuItems?.itemActive?.bgText,
                    fontWeight: fontWeight,
                    paddingClasses: itemPadding || 'px-2',
                    case: fontCase || '',
                    fontSize: fontSize || '',
                    activeMenuItemDisplayType: activeMenuItemDisplayType,
                  }}
                  currentPage={pageSlug}
                />
              </div>
            </div>
            <div
              className={cx(
                'cp-header-navUtilities flex items-center rtl:lg:w-full rtl:lg:justify-between',
                !hotelDates?.resEnabled ? 'pt-4' : ''
              )}
            >
              {(hotelDates?.resEnabled || associatedHotels?.length || 0 > 0) &&
                !styleOptions?.bookingButton?.hideBookingWidget &&
                !bookingWidgetConfig?.shopFormInHeader && (
                  <div
                    data-testid="booking-button-wrapper"
                    className={cx('text-text flex-col justify-center')}
                  >
                    <BookingWidgetButton
                      CTYHOCN={CTYHOCN}
                      brandCode={brandCode}
                      currency={currency}
                      associatedHotels={associatedHotels}
                      text={
                        bookingButtonText
                          ? bookingButtonText
                          : t('makeAReservation')
                      }
                      mobileText={t('book')}
                      defaultArrivalDate={defaultArrivalDate}
                      gmtHours={gmtHours}
                      mainClasses={styleOptions?.bookingButton?.mainClasses}
                      className={styleOptions?.bookingButton?.extraClasses}
                      activeHeader={setHeaderActive}
                      modalSettings={modalSettings}
                      styleOptions={{
                        buttonStyle:
                          styleOptions?.bookingButton?.buttonStyle || null,
                        backgroundColor:
                          styleOptions?.bookingButton?.backgroundColor || null,
                        backgroundColorHover:
                          styleOptions?.bookingButton?.backgroundColorHover ||
                          null,
                        textColor:
                          styleOptions?.bookingButton?.textColor || null,
                        textColorHover:
                          styleOptions?.bookingButton?.textColorHover || null,
                        borderColor:
                          styleOptions?.bookingButton?.borderColor || null,
                        borderColorHover:
                          styleOptions?.bookingButton?.borderColorHover || null,
                        textCase:
                          styleOptions?.bookingButton?.textCase || undefined,
                      }}
                      cmsBookStyle={
                        headerSettings?.SettingsHeaderSettings
                          ?.bookingWidgetButtonStyles
                      }
                      oscBookBtnGlobalSetting={
                        bookingWidgetConfig?.oscBookButtonStyle
                      }
                      maxRooms={maxRooms}
                      groupBooking={groupBooking}
                      hideGroupLink={hideGroupLink}
                    />
                  </div>
                )}
            </div>
          </section>
        </div>
        {bookingWidgetConfig?.shopFormInHeader && (
          <div
            className="cp-header-shop-form border-t py-2"
            style={shopFormStyle}
          >
            <OscComposableSearchForm
              ctyhocn={bookingWidgetConfig?.ctyhocn}
              defaultArrivalDate={bookingWidgetConfig?.defaultArrivalDate}
              gmtHours={bookingWidgetConfig?.gmtHours}
              brandCode={bookingWidgetConfig?.brandCode}
              currency={bookingWidgetConfig?.currency}
              associatedHotels={associatedHotels}
              oscBookButtonStyle={
                bookingWidgetConfig?.brandCode === 'WA'
                  ? ''
                  : bookingWidgetConfig?.oscBookButtonStyle
              }
              maxRooms={maxRooms}
              groupBooking={groupBooking}
              hideGroupLink={hideGroupLink}
            />
          </div>
        )}
      </header>
    </div>
  );
}
