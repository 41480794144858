import React, { useState } from 'react';
import { HeadingStyle } from './includes/heading-style';
import {
  GIS_merge,
  GIS_Padder,
  StyleObject,
} from './functions/global-instance-styles';
import { AnchorLink, ACFAnchorProps } from './global/anchor-link';
import { useTranslation } from 'next-i18next';
import { Caret } from '@curated-property/icon-list';
import { iconmapper } from './functions/helper';
import { sanitize } from '@curated-property/utils';
import {
  CroppedImage,
  customLoader,
  appliedCloudinaryParams,
} from '@curated-property/utils';
import cx from 'classnames';
import { Restaurant } from '@curated-property/icon-list';

type IconKeyType = keyof typeof iconmapper;
export interface RestaurantsProps {
  readMoreLess?: boolean;
  hideList?: string;
  restaurants?: {
    restaurantId?: string;
    id?: string;
    name?: string;
    description?: string;
    remarks?: string;
    cuisineType?: string;
    cuisineTypeSelect?: string;
    iconList?: IconKeyType | string;
    image?: {
      altText?: string;
      sourceUrl?: string;
    };
    overviewImage?: {
      url?: string;
      variants?: {
        size?: string;
        url?: string;
      };
    };
    buttons?: Array<ACFAnchorProps>;
    openingHours?: {
      title?: string;
      hours?: string;
    }[];
    operatingHours?: IOperatingHours[];
  }[];
  instanceStyles?: StyleObject;
  globalStyles?: StyleObject;
}

interface IOperatingHours {
  _id?: string | string[];
  headline?: string | string[];
  monday?: string | string[];
  tuesday?: string | string[];
  wednesday?: string | string[];
  thursday?: string | string[];
  friday?: string | string[];
  saturday?: string | string[];
  sunday?: string | string[];
}

export function Restaurants({
  readMoreLess,
  hideList,
  restaurants,
  globalStyles,
  instanceStyles,
}: RestaurantsProps) {
  const inlineStyles = GIS_merge(globalStyles, instanceStyles);
  const paddingStyles = GIS_Padder(
    inlineStyles?.paddingTop,
    inlineStyles?.paddingBottom
  );

  let hideListArray;
  if (hideList) {
    hideListArray = hideList?.includes(',') ? hideList?.split(',') : [hideList];
  }

  if (hideListArray?.length > 0) {
    restaurants = restaurants?.filter((item) => {
      return !hideListArray.includes(item?.id);
    });
  }

  return (
    <div
      data-element-id="restaurants-wrapper"
      className={paddingStyles}
      style={{
        backgroundImage: inlineStyles?.componentBackgroundImage?.sourceUrl
          ? `url('${appliedCloudinaryParams(
              inlineStyles?.componentBackgroundImage?.sourceUrl,
              inlineStyles?.componentBackgroundRepeat
            )}')`
          : '',
        backgroundColor: inlineStyles?.componentBackgroundColor || null,
        backgroundSize: inlineStyles?.componentBackgroundSize || 'cover',
        backgroundRepeat:
          inlineStyles?.componentBackgroundRepeat || 'no-repeat',
        backgroundPosition:
          inlineStyles?.componentBackgroundPosition || 'left center',
      }}
    >
      <div className="container">
        <div className="flex flex-col w-full justify-center">
          <div className="grid auto-rows-auto md:grid-flow-row-dense grid-cols-1 gap-x-4 gap-y-2 md:gap-y-6 md:grid-cols-2 lg:grid-cols-3 pt-2 md:pt-4 py-4 overflow-hidden">
            {restaurants?.map((restaurant, key) => {
              return (
                <RestaurantItems
                  readMoreLess={readMoreLess}
                  item={restaurant}
                  key={key}
                  inlineStyles={inlineStyles}
                />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

interface restaurantProps {
  readMoreLess?: boolean;
  item?: any;
  inlineStyles?: any;
}

function RestaurantItems({
  readMoreLess,
  item,
  inlineStyles,
}: restaurantProps) {
  const [t] = useTranslation();
  const [readMore, setReadMore] = useState(false);
  const [openingHours, setOpeningHours] = useState(false);

  const cuisineType = item?.cuisineTypeSelect ?? item?.cuisineType ?? null;
  const iconMap = iconmapper();
  const Icon = iconMap[item?.iconList || ''];

  return (
    <div data-id={item?.id} data-testid="restaurantItem">
      <div
        className={cx(
          'relative h-full flex flex-col border border-solid border-bg-alt',
          inlineStyles?.hideTileBorder && 'border-0'
        )}
        style={{
          backgroundImage: inlineStyles?.contentBackgroundImage?.sourceUrl
            ? `url('${appliedCloudinaryParams(
                inlineStyles?.contentBackgroundImage?.sourceUrl,
                inlineStyles?.contentBackgroundRepeat
              )}')`
            : '',
          backgroundColor: inlineStyles?.contentBackgroundColor || null,
          backgroundSize: inlineStyles?.contentBackgroundSize || null,
          backgroundRepeat:
            inlineStyles?.contentBackgroundRepeat || 'no-repeat',
          backgroundPosition:
            inlineStyles?.contentBackgroundPosition || 'left center',
          textAlign: inlineStyles?.textAlignment,
          borderColor: inlineStyles?.tileBorderColour || null,
        }}
      >
        <div
          className="p-4 bg-bg-alt"
          style={{
            backgroundColor: inlineStyles?.topSectionBackgroundColour || null,
          }}
        >
          {item?.name_noTx && (
            <HeadingStyle
              text={item?.name_noTx}
              type={'h2'}
              styledAs={'h4'}
              className={cx(
                'OneLinkNoTx font-extrabold',
                cuisineType && 'mb-2'
              )}
              textColorInline={inlineStyles?.titleColor}
            />
          )}
          {cuisineType && (
            <div className="flex items-center fill-primary">
              {Icon ? (
                <Icon
                  className="w-8 mr-2"
                  fillColor={
                    inlineStyles?.cuisineTypeIconColour ||
                    inlineStyles?.subtitleColor
                  }
                />
              ) : (
                <Restaurant
                  className="w-8 mr-2"
                  fillColor={
                    inlineStyles?.cuisineTypeIconColour ||
                    inlineStyles?.subtitleColor
                  }
                />
              )}
              <span
                className="text-primary font-normal leading-6"
                style={{
                  color:
                    inlineStyles?.cuisineTypeTextColour ||
                    inlineStyles?.subtitleColor,
                }}
              >
                {cuisineType}
              </span>
            </div>
          )}
        </div>
        <div>
          {item?.image?.sourceUrl ? (
            <div className="pb-[75%] h-0 overflow-hidden">
              <CroppedImage
                loader={() => {
                  return customLoader({
                    src: item?.image?.sourceUrl,
                    crop: item?.enableCropping,
                    cropType: item?.cropType,
                    cropHeight: item?.cropHeight,
                    cropWidth: item?.cropWidth,
                    xPosition: item?.xPosition,
                    xPositionAdvanced: item?.xPositionAdvanced,
                    yPosition: item?.yPosition,
                    yPositionAdvanced: item?.yPositionAdvanced,
                    autoPosition: item?.autoPosition,
                  });
                }}
                width="432"
                height="329"
                src={item?.image?.sourceUrl || ''}
                alt={item?.image?.altText || ''}
                objectFit="cover"
              />
            </div>
          ) : (
            // core+ image
            <div className="relative" data-testid="background-img">
              {item?.overviewImage?.variants[0]?.url &&
              item?.overviewImage?.variants[0]?.url !==
                'https://assets.hiltonstatic.com/images/v1583957952/no-image/no-image.png' ? (
                <div
                  className="pb-[75%] h-0"
                  style={{
                    backgroundImage: `url(${item?.overviewImage?.variants[0]?.url})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center center',
                  }}
                ></div>
              ) : (
                <div className="relative">
                  <div className="absolute w-full h-full flex justify-center items-center text-lg">
                    <p>No image available</p>
                  </div>
                  <CroppedImage
                    src=""
                    alt=""
                    width="431"
                    height="287"
                    className="w-full"
                    layout="responsive"
                  />
                </div>
              )}
            </div>
          )}
        </div>
        {(item?.openingHours || item?.operatingHours.length > 0) && (
          <div
            className="pt-6 pb-4 mx-4 border-b border-b-solid border-bg-alt"
            style={{ borderColor: inlineStyles?.hoursSeperatorColour || null }}
          >
            <button
              data-testid="hoursToggle"
              className="relative w-full text-left"
              style={{ color: inlineStyles?.textColor }}
              onClick={(e) => {
                !openingHours ? setOpeningHours(true) : setOpeningHours(false);
              }}
              aria-expanded={openingHours ? 'true' : 'false'}
            >
              <HeadingStyle
                text={t('openingHours')}
                type={'h3'}
                styledAs={'h6'}
                className="font-bold leading-6"
              />
              <Caret
                className={cx(
                  'absolute h-1.5 transform transition-all top-[calc(50%-4px)] right-[5px]',
                  {
                    'rotate-180': openingHours,
                  }
                )}
                fillColor={inlineStyles?.textColor || '#000'}
              />
            </button>
          </div>
        )}
        <div
          data-testid="openingHours"
          className={cx(
            'flex flex-col border-b border-b-solid mx-4 transform transition-all',
            {
              'opacity-0 pointer-events-none max-h-0 transition-none':
                !openingHours,
              'max-h-auto duration-500': openingHours,
            }
          )}
          style={{ borderColor: inlineStyles?.hoursSeperatorColour || null }}
        >
          {item?.openingHours
            ? item?.openingHours?.map((item, key) => {
                return (
                  <React.Fragment key={key}>
                    {key === 0 && <div className="py-1"></div>}
                    {item?.title ? (
                      <OperatingHours
                        dayOfTheWeek={item?.title}
                        time={item?.hours}
                        inlineStyles={inlineStyles}
                      />
                    ) : (
                      <span className="pt-4"></span>
                    )}
                  </React.Fragment>
                );
              })
            : item?.operatingHours?.map((item, key) => {
                const operatingHoursArray = convertOperatingHoursToArray(item);
                const combinedOperatingHours =
                  combineDaysWithSameHours(operatingHoursArray);
                const combinedOperatingHoursStripped =
                  stripMealNamesAndCommasFromhours(combinedOperatingHours);

                return (
                  <React.Fragment key={key}>
                    {item?.headline && item?.headline !== 'Hours' ? (
                      <HeadingStyle
                        text={item?.headline}
                        type={'h3'}
                        styledAs={'h6'}
                        className="font-bold py-4 leading-6"
                        textColorInline={inlineStyles?.textColor}
                      />
                    ) : (
                      <div className="py-1"></div>
                    )}
                    {combinedOperatingHoursStripped?.map((item, key) => {
                      const days = item?.day.replace(
                        /monday – sunday/gi,
                        'daily'
                      );
                      return (
                        <OperatingHours
                          key={key}
                          dayOfTheWeek={days}
                          time={item?.hours}
                          inlineStyles={inlineStyles}
                        />
                      );
                    })}
                  </React.Fragment>
                );
              })}
        </div>

        <div
          className={cx(
            'py-4 mx-4 mb-4 text-base',
            item?.buttons?.length > 0 && 'border-b border-b-solid border-bg-alt'
          )}
          style={{ color: inlineStyles?.textColor }}
        >
          {readMoreLess && item?.description.length > 300 ? (
            <React.Fragment>
              <p
                dangerouslySetInnerHTML={{
                  __html: sanitize(
                    readMore
                      ? item?.description
                      : item?.description.substring(0, 300) + '...'
                  ),
                }}
              ></p>

              <button
                data-testid="mobile-button-trigger"
                className="mt-3 mb-2"
                onClick={(e) => {
                  !readMore ? setReadMore(true) : setReadMore(false);
                }}
              >
                <span className="font-bold underline">
                  {!readMore ? '...More' : '...Less'}
                </span>
              </button>
            </React.Fragment>
          ) : (
            <p
              dangerouslySetInnerHTML={{
                __html: sanitize(item?.description || ''),
              }}
            ></p>
          )}
        </div>

        {item?.buttons?.length > 0 && (
          <div className="px-2 pt-2 pb-4 flex h-full w-full flex-wrap items-end content-end">
            {item?.buttons?.map((link, key) => {
              return (
                <AnchorLink
                  key={key}
                  url={link?.link?.url}
                  title={link?.link?.title}
                  target={link?.link?.target}
                  buttonStyle={link?.buttonStyle ?? 'primary'}
                />
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
}

interface OperatingHoursProps {
  dayOfTheWeek?: string;
  time?: string;
  inlineStyles?: any;
}

function OperatingHours({
  dayOfTheWeek,
  time,
  inlineStyles,
}: OperatingHoursProps) {
  const [t] = useTranslation();
  const a11yHours =
    time.toString().includes('-') && time.toString().replace('-', 'through');

  // add larger dashes to time
  const timeWithDashes = time.toString().replace('-', '–');

  return (
    <div
      className={cx('flex justify-between text-base leading-6 pb-2')}
      style={{ color: inlineStyles?.textColor }}
    >
      <span
        aria-hidden={true}
        dangerouslySetInnerHTML={{ __html: sanitize(dayOfTheWeek) }}
        className="whitespace-nowrap capitalize font-bold"
      ></span>
      <span
        aria-hidden={true}
        dangerouslySetInnerHTML={{
          __html:
            time.length && time[0] !== ''
              ? timeWithDashes
              : t('occupancy.closed'),
        }}
        className="w-full text-balance text-right"
      ></span>
      <span
        className="sr-only"
        dangerouslySetInnerHTML={{
          __html: sanitize(dayOfTheWeek || ''),
        }}
      ></span>
      <span
        className="sr-only"
        dangerouslySetInnerHTML={{
          __html: sanitize(a11yHours ? a11yHours : t('occupancy.closed') || ''),
        }}
      ></span>
    </div>
  );
}

/**
 * Convert object to an array of objects, this way we can loop through them
 * @return Array of objects for each day listed in Core+: [{ day: key, hours: value }]
 */
export function convertOperatingHoursToArray(obj: IOperatingHours) {
  const combinedArray = [];
  const whitelistValues = [
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
    'sunday',
  ];
  for (const [key, value] of Object.entries(obj)) {
    // we only ever want the days of the week, so only add to the array if the key is whitelisted
    if (whitelistValues.includes(key)) {
      combinedArray.push({
        day: key,
        hours: value.length === 0 ? [''] : value,
      });
    }
  }
  return combinedArray;
}

/**
 * Combine days with similar opening hours together
 * @return Array of objects for each day listed in Core+: [{ day: key, hours: value }] - shortened to reduce openingHours duplication
 */
export function combineDaysWithSameHours(arr) {
  const modifiedArray = [];
  let indexesWithDuplicate = [arr[0].day];
  let currentHours = arr[0].hours;

  for (let i = 0; i < arr.length; i++) {
    const curr = arr[i];
    const prev = arr[i - 1];
    const next = arr[i + 1];

    if (JSON.stringify(curr.hours) === JSON.stringify(currentHours)) {
      indexesWithDuplicate.push(curr?.day);
    } else {
      if (indexesWithDuplicate.length > 1) {
        modifiedArray.push({
          day: `${indexesWithDuplicate[0]} – ${
            indexesWithDuplicate[indexesWithDuplicate.length - 1]
          }`,
          hours: currentHours,
        });
      } else {
        modifiedArray.push({
          day: indexesWithDuplicate[0],
          hours: currentHours,
        });
      }
      indexesWithDuplicate = [curr.day];
      currentHours = curr.hours;
    }
  }

  // Process the last accumulated days
  if (indexesWithDuplicate.length > 1) {
    modifiedArray.push({
      day: `${indexesWithDuplicate[0]} – ${
        indexesWithDuplicate[indexesWithDuplicate.length - 1]
      }`,
      hours: currentHours,
    });
  } else {
    modifiedArray.push({
      day: indexesWithDuplicate[0],
      hours: currentHours,
    });
  }

  return modifiedArray;
}

export function stripMealNamesAndCommasFromhours(arr) {
  const arrStripped = arr?.map((item) => {
    return {
      ...item,
      hours: item?.hours
        ?.toString()
        .replace('Breakfast: ', '')
        .replace('Lunch: ', '')
        .replace('Dinner: ', '')
        .replace(/,/g, '<br>'),
    };
  });

  return arrStripped;
}
