import dynamic from 'next/dynamic';

const HotelLogo = dynamic<{ className: string; fillColor: string }>(() =>
  import('./dohqtwa/hotel-logo').then((i) => i.HotelLogo)
);

export const hotelLogoHeader = (
  <HotelLogo className="text-bg antialiased h-20" fillColor="#000" />
);

export const hotelLogoFooter = (
  <HotelLogo
    className="fill-current text-bg antialiased h-24"
    fillColor="#fff"
  />
);
