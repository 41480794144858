import React, { useState } from 'react';
import {
  CroppedImage,
  isReducedMotion,
  appliedCloudinaryParams,
} from '@curated-property/utils';
import cx from 'classnames';
import { ImageModalImage, ImageModal } from './includes/image-modal';
import { HandleAnimations } from './functions/helper';
import {
  GIS_merge,
  GIS_Padder,
  StyleObject,
} from './functions/global-instance-styles';
import { sanitize } from '@curated-property/utils';
import { useTranslation } from 'next-i18next';
import { adobeEventTracking } from './global/adobe-analytics';
import { GenericModal } from './global/generic-modal';

export interface ImageGalleryProps {
  galleries:
    | {
        galleryTitle?: string;
        galleryThumbnail?: {
          sourceUrl?: string;
          altText?: string;
        };
        galleryImages?: ImageModalImage[];
      }[]
    | undefined;
  instanceStyles?: StyleObject;
  globalStyles?: StyleObject;
  modalSettings?: {
    modalBackgroundOpacity?: number;
    modalBackgroundColour?: string;
    modalCloseButtonBackgroundColour?: string;
    modalCloseButtonIconColour?: string;
  };
}

interface ActiveGalleryState {
  id: number;
  lastFocussedElement: HTMLButtonElement | null;
}

export function ImageGallery({
  galleries,
  instanceStyles,
  globalStyles,
  modalSettings,
}: ImageGalleryProps) {
  const [modalActive, setModalActive] = useState(false);
  const [activeGallery, setActiveGallery] = useState<ActiveGalleryState>({
    id: 0,
    lastFocussedElement: null,
  });
  const inlineStyles = GIS_merge(globalStyles, instanceStyles);
  const paddingStyles = GIS_Padder(
    inlineStyles?.paddingTop,
    inlineStyles?.paddingBottom
  );
  const slideAnimation: string = inlineStyles?.sliderAnimations || 'fade';
  const zoomOnHover = inlineStyles?.zoomOnHover === 'show' && !isReducedMotion;
  modalSettings = {
    ...modalSettings,
    modalBackgroundColour:
      inlineStyles?.galleryTilesBackgroundColour ||
      modalSettings?.modalBackgroundColour,
    modalCloseButtonBackgroundColour:
      inlineStyles?.galleryTilesControlsBackgroundColour ||
      modalSettings?.modalCloseButtonBackgroundColour,
    modalCloseButtonIconColour:
      inlineStyles?.galleryTilesControlsIconColour ||
      modalSettings?.modalCloseButtonIconColour,
  };

  if (galleries) {
    galleries[-1] = {
      galleryImages: Array(6).fill({ sourceUrl: '' }),
    };
  }

  function open() {
    if (!galleries) {
      return;
    }
    setModalActive(true);
  }

  function close() {
    if (!galleries) {
      return;
    }

    setActiveGallery({
      id: -1,
      lastFocussedElement: activeGallery?.lastFocussedElement,
    });

    setModalActive(false);
    activeGallery?.lastFocussedElement?.focus();
  }

  return (
    <div
      className={cx(paddingStyles, inlineStyles?.showHide && 'hidden')}
      style={{
        backgroundImage: inlineStyles?.componentBackgroundImage
          ? `url('${appliedCloudinaryParams(
              inlineStyles?.componentBackgroundImage?.sourceUrl,
              inlineStyles?.componentBackgroundRepeat
            )}')`
          : undefined,
        backgroundColor: inlineStyles?.componentBackgroundColor || undefined,
        backgroundSize: inlineStyles?.componentBackgroundSize || 'cover',
        backgroundRepeat:
          inlineStyles?.componentBackgroundRepeat || 'no-repeat',
        backgroundPosition:
          inlineStyles?.componentBackgroundPosition || 'left center',
      }}
      data-element-id="gallery-tiles-and-fullscreen-carousel-wrapper"
    >
      <div className="container mx-auto grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
        {galleries?.map((gallery, key) => {
          return (
            <GalleryItem
              key={key}
              id={key}
              thumbImg={gallery?.galleryThumbnail?.sourceUrl || ''}
              thumbAlt={gallery?.galleryThumbnail?.altText}
              galleryTitle={gallery?.galleryTitle}
              galleryImages={gallery?.galleryImages}
              open={open}
              active={setActiveGallery}
              modalActive={modalActive}
              inlineStyles={inlineStyles}
              zoomOnHover={zoomOnHover}
            />
          );
        })}
      </div>
      <GenericModal
        modalTitle={galleries?.[activeGallery.id]?.galleryTitle}
        setModalActive={close}
        modalActive={modalActive}
        modalChildWrapperStyles="w-full lg:px-32 pt-8 bg-transparent shadow-none"
        modalStyles="w-full"
        modalSettings={modalSettings}
        modalChild={
          <ImageModal
            images={galleries?.[activeGallery.id]?.galleryImages}
            title={galleries?.[activeGallery.id]?.galleryTitle}
            active={modalActive}
            slideAnimation={slideAnimation}
            captionColour={inlineStyles?.galleryTilesControlsCaptionColour}
            controlsBackgroundColour={
              modalSettings?.modalCloseButtonBackgroundColour
            }
            controlsIconColour={modalSettings?.modalCloseButtonIconColour}
          />
        }
      />
    </div>
  );
}

interface GalleryItemProps {
  id: number;
  thumbImg: string;
  thumbAlt?: string;
  galleryTitle?: string;
  galleryImages?: any;
  open?: any;
  active?: any;
  modalActive?: boolean;
  inlineStyles?: StyleObject;
  zoomOnHover?: boolean;
}

function GalleryItem({
  id,
  thumbImg,
  thumbAlt,
  galleryTitle,
  galleryImages,
  open,
  active,
  modalActive,
  inlineStyles,
  zoomOnHover,
}: GalleryItemProps) {
  const { t } = useTranslation();

  const animations = HandleAnimations({
    hideAnimation: inlineStyles?.hideAnimations !== 'show',
    start: `${inlineStyles?.animationDirection ?? '-translate-y'}-8`,
    delayOne: 'delay-200',
  });

  return (
    <div
      ref={animations?.ref}
      className={cx('relative text-center shadow rounded', animations?.one)}
      style={{
        backgroundImage: inlineStyles?.contentBackgroundImage
          ? `url('${appliedCloudinaryParams(
              inlineStyles?.contentBackgroundImage?.sourceUrl
            )}')`
          : undefined,
        backgroundColor: inlineStyles?.contentBackgroundColor || undefined,
        backgroundSize: inlineStyles?.contentBackgroundSize || 'cover',
        backgroundRepeat: inlineStyles?.contentBackgroundRepeat || 'no-repeat',
        backgroundPosition:
          inlineStyles?.contentBackgroundPosition || 'left center',
        transitionDelay: `${id + 1}00ms`,
      }}
    >
      <button
        className="h-full w-full"
        data-testid="gallery-modal-trigger"
        aria-label={t('openGallery') + (galleryTitle || '')}
        onClick={(e) => {
          open();
          active({
            id: id,
            lastFocussedElement: e?.target,
          });
          adobeEventTracking({
            carouselNumber: `${1}:${galleryImages?.length}`,
            sectionHeading: galleryTitle,
            actionDetail: 'Carousel',
            interactionName: 'propertyGalleryCarousel',
          });
        }}
        aria-expanded={modalActive ? true : false}
      >
        <div className="relative overflow-hidden">
          <CroppedImage
            src={thumbImg}
            alt={thumbAlt}
            width={540}
            height={390}
            title={galleryTitle}
            className={cx('transform transition-all duration-500', {
              'hover:scale-125': zoomOnHover,
            })}
          />
        </div>
        <h2
          data-element-id="gallery-item-heading"
          className="text-xl font-black leading-none font-headline md:text-3xl pt-4"
          style={{ color: inlineStyles?.titleColor }}
          dangerouslySetInnerHTML={{
            __html: sanitize(galleryTitle || ''),
          }}
        ></h2>

        <div
          className="text-md uppercase pt-2 pb-4"
          style={{ color: inlineStyles?.titleColor }}
        >
          {!inlineStyles?.hideImageCount && (
            <span>
              <b>{!galleryImages ? '0' : galleryImages.length}</b> {t('photos')}
            </span>
          )}
        </div>
      </button>
    </div>
  );
}
