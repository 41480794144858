import { useEffect, useState } from 'react';
import cx from 'classnames';
import {
  GIS_Array,
  GIS_TextAlignment,
  GIS_Padder,
  StyleObject,
} from './functions/global-instance-styles';
import { WrappedHeading } from '@curated-property/utils';
import Route from 'next/router';
import styles from './css/contentStyle.module.css';
import {
  HandleWYSIWYGContent,
  useWindowSize,
  HandleAnimations,
} from './functions/helper';
import { inlineBackgroundStyles } from '@curated-property/utils';
import { sanitize } from '@curated-property/utils';

let componentInstance = 0;

interface Props {
  componentIndex?: number;
  isHomepage: boolean;
  propertyHasAlert: boolean;
  corePlusHeadline?: string;
  corePlusShortDesc?: string;
  globalStyles?: StyleObject;
  instanceStyles?: StyleObject;
}

export function HotelTitle({
  componentIndex,
  propertyHasAlert,
  isHomepage,
  corePlusHeadline,
  corePlusShortDesc,
  globalStyles,
  instanceStyles,
}: Props) {
  const [headerHeight, setHeaderHeight] = useState('0px');
  // check for route change and reset component instance
  const currentRoute = Route?.router?.asPath;
  useEffect(() => {
    componentInstance = null;
  }, [currentRoute]);
  // set this variable to the component key once so that we know what instance of the component we're on - on the current page
  if (componentInstance === null) {
    componentInstance = componentIndex;
  }
  //set h1 if this is the first componentinstance
  const hasH1 =
    componentInstance === componentIndex && isHomepage === false ? 'h1' : 'h2';
  const windowSize = useWindowSize();
  const defaultPaddingSize = propertyHasAlert ? '164px' : '128px';

  useEffect(() => {
    if (typeof document !== 'undefined' && componentIndex === 0) {
      setHeaderHeight(
        `${document?.getElementsByTagName('header')?.[0]?.offsetHeight}px`
      );
    }
  }, [headerHeight, windowSize]);
  const inlineStyles = GIS_Array(globalStyles, instanceStyles);
  const textAlignment = GIS_TextAlignment(inlineStyles?.textAlignment);
  const paddingStyles = GIS_Padder(
    inlineStyles?.paddingTop,
    inlineStyles?.paddingBottom
  );
  const flexDirection =
    inlineStyles?.layoutDirection === 'col'
      ? 'lg:flex-col'
      : 'lg:flex-row md:px-16 md:py-8 xl:px-16 xl:py-16';
  const titleWidth =
    inlineStyles?.layoutDirection === 'col' ? 'w-full' : 'lg:w-5/12';
  const copyWidth =
    inlineStyles?.layoutDirection === 'col' ? 'w-full max-w-4xl' : 'lg:w-7/12';
  const headerStyles = inlineStyles?.layoutDirection === 'col' ? 'mb-6' : '';
  const copyStyles = inlineStyles?.layoutDirection === 'col' ? '' : 'lg:pl-2';
  const copyContainer = 'container';
  const image = instanceStyles?.image;

  let alignItems: string, imageAlignStyles: string;

  switch (inlineStyles?.textAlignment) {
    case 'flex-start':
      alignItems = 'items-start';
      imageAlignStyles = 'ml-0';
      break;
    case 'center':
      alignItems = 'items-center';
      imageAlignStyles = 'm-auto';
      break;
    case 'flex-end':
      alignItems = 'items-end';
      imageAlignStyles = 'ml-auto';
      break;
    default:
      alignItems = 'items-start';
      imageAlignStyles = 'ml-0';
  }

  const animations = HandleAnimations({
    hideAnimation: inlineStyles?.hideAnimations !== 'show',
    start: inlineStyles?.animationDirection
      ? `${inlineStyles?.animationDirection}-8`
      : alignItems === 'items-center'
      ? '-translate-y-12'
      : alignItems === 'items-end'
      ? 'translate-x-8'
      : '-translate-x-8',
    end: 'translate-x-0 translate-y-0',
    delayOne: 'delay-200',
    delayTwo: 'delay-300',
    delayThree: 'delay-500',
  });

  return (
    <div
      data-testid="hotel-title"
      className={cx(inlineStyles?.showHide && 'hidden')}
      style={{
        paddingTop:
          !headerHeight && componentIndex === 0
            ? defaultPaddingSize
            : headerHeight,
        transition: 'padding-top .5s ease',
      }}
    >
      <div
        className={cx('copy-block', paddingStyles)}
        style={{
          ...inlineBackgroundStyles(inlineStyles, 'component'),
          backgroundRepeat:
            inlineStyles?.componentBackgroundRepeat || 'no-repeat',
          backgroundPosition:
            inlineStyles?.componentBackgroundPosition || 'left center',
        }}
      >
        <div
          ref={animations?.ref}
          data-testid="offsetAndDirectionContainer"
          className={cx(
            'flex flex-col px-4 bg-bottom bg-right bg-no-repeat transform relative',
            alignItems,
            copyContainer,
            flexDirection
          )}
          style={{
            ...inlineBackgroundStyles(inlineStyles, 'content'),
            backgroundRepeat:
              inlineStyles?.contentBackgroundRepeat || 'no-repeat',
            backgroundPosition:
              inlineStyles?.contentBackgroundPosition || 'left center',
            textAlign: textAlignment,
            justifyContent: inlineStyles?.textAlignment,
          }}
        >
          {
            <div className={cx('pr-2', titleWidth, animations?.one)}>
              <WrappedHeading header={hasH1}>
                <p
                  className={cx(
                    'text-4xl font-black leading-none font-headline md:text-5xl',
                    headerStyles
                  )}
                >
                  <span
                    className={'block'}
                    style={{ color: inlineStyles?.titleColor }}
                    dangerouslySetInnerHTML={{
                      __html: sanitize(corePlusHeadline),
                    }}
                  />
                </p>
              </WrappedHeading>
            </div>
          }
          <div
            className={cx(
              'mt-4 text-lg lg:mt-0',
              copyWidth,
              copyStyles,
              styles.listStyle,
              styles.paragraphStyle
            )}
          >
            <div
              className={animations?.two}
              data-testid="hotel-title-paragraph"
              style={{ color: inlineStyles?.textColor }}
              dangerouslySetInnerHTML={{
                __html: HandleWYSIWYGContent(
                  corePlusShortDesc,
                  inlineStyles?.textColor
                ),
              }}
            ></div>
            {image?.sourceUrl ? (
              // eslint-disable-next-line jsx-a11y/img-redundant-alt
              <img
                className={cx(
                  'h-16 w-auto my-2 block mt-8 mb-8',
                  imageAlignStyles,
                  animations?.three
                )}
                src={image?.sourceUrl}
                alt={image?.altText}
              />
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}
