import React from 'react';
export function CameraIcon({
  className,
  fillColor,
}: {
  className?: string;
  fillColor?: string;
}) {
  return (
    <svg
      className={className}
      fill={fillColor}
      viewBox="0 0 1320 1024"
      aria-hidden="true"
    >
      <path d="M1146.092 1024H114.215C51.2 1024 0 972.8 0 913.723v-665.6c0-63.015 51.2-110.277 110.277-110.277h224.492c19.692 0 35.446-15.754 35.446-35.446C374.153 43.323 417.477 0 472.615 0h315.077c55.138 0 98.462 43.323 98.462 98.462 0 19.692 15.754 35.446 35.446 35.446h224.492c63.015 0 110.277 51.2 110.277 110.277v665.6C1260.307 972.8 1209.107 1024 1146.092 1024zM114.215 177.231c-39.385 0-74.831 31.508-74.831 70.892v665.6c0 39.385 31.508 70.892 70.892 70.892h1035.815c39.385 0 70.892-31.508 70.892-70.892v-665.6c0-39.385-31.508-70.892-70.892-70.892H921.599c-43.323 0-74.831-35.446-74.831-74.831 0-35.446-27.569-63.015-59.077-63.015H472.614c-31.508 0-59.077 27.569-59.077 59.077 0 43.323-35.446 74.831-74.831 74.831H114.214z" />
      <path d="M630.154 838.892c-149.662 0-275.692-122.092-275.692-275.692s122.092-275.692 275.692-275.692S905.846 409.6 905.846 563.2 779.815 838.892 630.154 838.892zm0-508.061c-129.969 0-236.308 106.338-236.308 236.308s106.338 236.308 236.308 236.308c129.969 0 236.308-106.338 236.308-236.308S760.124 330.831 630.154 330.831zM949.169 248.123h145.723v39.385H949.169v-39.385z" />
    </svg>
  );
}
