import dynamic from 'next/dynamic';

const HotelLogo = dynamic<{ className?: string; fillColor?: string }>(() =>
  import('./dxbahhi/hotel-logo').then((i) => i.HotelLogo)
);

export const hotelLogoHeader = (
  <HotelLogo fillColor="#ffffff" className="antialiased w-24 md:w-28" />
);

export const hotelLogoFooter = (
  <HotelLogo fillColor="#ffffff" className="antialiased w-24 md:w-28 mr-2" />
);
