import { WACContext, WACStages } from '../';
import { getOrdinal } from '@curated-property/utils';
import { isAfter, isBefore, isValid } from 'date-fns';
import { FormEvent, RefObject, useContext, useRef, useState } from 'react';
import cx from 'classnames';

import { WACCalendar } from '../calendar';
import { WACContainer, WACStyledSelect } from '../utils';
import { GIS_TextAlignment } from '../../functions/global-instance-styles';
import { WACStyles } from '../interfaces';

interface WACSelectDates {
  styles: WACStyles;
  startFocusElementRef: RefObject<HTMLSelectElement>;
}

export const WACSelectDatesSection = function ({
  styles,
  startFocusElementRef,
}: WACSelectDates) {
  const { stage, minDate, maxDate, selectedDate, setSelectedYearMonth } =
    useContext(WACContext);
  const yearSelectRef = useRef<HTMLSelectElement>(null);
  const monthSelectRef = startFocusElementRef;
  const [dropDownYearMonth, setDropDownYearMonth] = useState(minDate);
  /* date selection */
  const activeYears = [];

  function onDropDownDateChange() {
    let newDate = new Date(
      parseInt(yearSelectRef?.current?.value || ''),
      parseInt(monthSelectRef?.current?.value || ''),
      1
    );
    if (isBefore(newDate, minDate)) {
      newDate = new Date(minDate);
    }
    if (isAfter(newDate, maxDate)) {
      newDate = new Date(maxDate);
    }
    if (dropDownYearMonth !== newDate) setDropDownYearMonth(newDate);
  }

  function onDropDownSelectDate(e: FormEvent) {
    onDropDownDateChange();
    e.preventDefault();
    setSelectedYearMonth?.(dropDownYearMonth);
  }

  for (let i = 0; i < maxDate.getFullYear() - minDate.getFullYear(); i++) {
    const year = minDate.getFullYear() + i;
    const option = (
      <option
        value={year}
        key={year}
        selected={dropDownYearMonth?.getFullYear() === year}
      >
        {year}
      </option>
    );
    activeYears.push(option);
  }

  const maxMonth =
    dropDownYearMonth.getFullYear() === maxDate.getFullYear()
      ? maxDate.getMonth()
      : 11;
  const minMonth =
    dropDownYearMonth.getFullYear() === minDate.getFullYear()
      ? minDate.getMonth()
      : 0;
  const activeYearMonths = Array.from(
    { length: maxMonth - minMonth + 1 },
    (_, i) => {
      const monthIndex = minMonth + i;
      const tmpDate = new Date(dropDownYearMonth.getFullYear(), monthIndex, 1);
      const monthTitle = tmpDate.toLocaleDateString('en-US', { month: 'long' });
      return (
        <option
          value={monthIndex}
          key={monthIndex}
          selected={dropDownYearMonth?.getMonth() === monthIndex}
        >
          {monthTitle}
        </option>
      );
    }
  );
  return (
    <div
      className={`${
        stage !== WACStages.DATE && 'hidden'
      } grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-8`}
    >
      <WACContainer bgColor={styles?.background?.primary}>
        <h3
          className="text-3xl mb-6"
          style={{
            textAlign: GIS_TextAlignment(styles?.subheadingAlign),
            color: styles?.subheadingColor,
          }}
        >
          Choose a Date
        </h3>
        <p>
          When will the wedding take place? We’ll show you what dates are
          available.
        </p>
        <form className="my-6 flex flex-col">
          <label htmlFor="wac-month-select" className="ml-2 mb-2 font-bold">
            Month
          </label>
          <WACStyledSelect
            aria-label="Month"
            id="wac-month-select"
            testId="wac-month-select"
            ref={monthSelectRef}
            onChange={onDropDownDateChange}
            borderColor={styles?.background?.secondary}
          >
            {activeYearMonths}
          </WACStyledSelect>
          <label htmlFor="wac-year-select" className="ml-2 mb-2 font-bold">
            Year
          </label>
          <WACStyledSelect
            aria-label="Year"
            id="wac-year-select"
            testId="wac-year-select"
            ref={yearSelectRef}
            onChange={onDropDownDateChange}
            borderColor={styles?.background?.secondary}
          >
            {activeYears}
          </WACStyledSelect>
          <div>
            <input
              data-testid="submitDate"
              type="submit"
              value="Submit a Date"
              className="btn btn-primary text text-lg sm:text-xl text-center sm:text-left py-2 px-6 mr-1 ml-1 mt-2 sm:w-auto hover:text-hover cursor-pointer"
              onClick={onDropDownSelectDate}
            ></input>
          </div>
        </form>
      </WACContainer>
      <WACContainer bgColor={styles?.background?.primary}>
        <WACCalendar styles={styles}></WACCalendar>
      </WACContainer>
      <WACContainer
        dataElementId="wac-wedding-details"
        bgColor={
          isValid(selectedDate)
            ? styles?.thirdPanel?.active.backgroundColor
            : styles?.thirdPanel?.inactive.backgroundColor
        }
        style={{
          color: isValid(selectedDate)
            ? styles?.thirdPanel?.active.textColor
            : styles?.thirdPanel?.inactive.textColor,
        }}
        className={cx('col-span-1 md:col-span-2 xl:col-span-1', {
          'wac-wedding-details--active': isValid(selectedDate),
        })}
      >
        <div className="flex flex-col h-full justify-between text-center">
          <h3
            className="text-3xl mb-6"
            style={{
              textAlign: GIS_TextAlignment(styles?.subheadingAlign),
              color: isValid(selectedDate)
                ? styles?.thirdPanel?.active.textColor
                : styles?.thirdPanel?.inactive.textColor,
            }}
          >
            Wedding Details
          </h3>
          {isValid(selectedDate) ? (
            <div className="flex flex-col align-center text-3xl">
              <div className="mb-4" data-testid="selectedWeekday">
                <strong>
                  {selectedDate?.toLocaleDateString('en-US', {
                    weekday: 'long',
                  })}
                </strong>
              </div>
              <div className="mb-4" data-testid="selectedDayofMonth">
                <strong>
                  {selectedDate?.getDate()}
                  {getOrdinal(selectedDate?.getDate() || 0)}
                </strong>
              </div>
              <div className="text-2xl" data-testid="selectedMonthAndYear">
                {selectedDate?.toLocaleDateString('en-US', {
                  month: 'long',
                })}{' '}
                {selectedDate?.getFullYear()}
              </div>
            </div>
          ) : (
            <p className="text-sm">
              It looks like you haven't picked that special day yet.
            </p>
          )}
          <div className="w-48 mx-auto">
            {isValid(selectedDate)
              ? "Now let's see what venues are available on this date."
              : null}
          </div>
        </div>
      </WACContainer>
    </div>
  );
};
