/**
 *
 *
 * DO NOT UPDATE THIS FILE, UPDATE SJCSMSA.TSX - THIS FILE IS THE OLD CTYHON FOR SIGNIA SAN JOSE
 *
 * TODO: DELETE THIS FILE
 *
 */

import dynamic from 'next/dynamic';

const HotelLogo = dynamic<{ className: string; fillColor: string }>(() =>
  import('./sjcsmhh/hotel-logo').then((i) => i.HotelLogo)
);

export const hotelLogoHeader = (
  <HotelLogo
    className="fill-current text-bg antialiased w-40 h-20"
    fillColor="#ffffff"
  />
);

export const hotelLogoFooter = (
  <HotelLogo
    className="fill-current text-bg antialiased w-32 h-20"
    fillColor="#fff"
  />
);
