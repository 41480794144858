import React from 'react';

export function Instagram({
  className,
  fillColor,
}: {
  className?: string;
  fillColor?: string;
}) {
  return (
    <svg
      className={className}
      fill={fillColor}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="m0 0v24h24v-24z" fill="none" />
      <path d="m15.75 4h-7.5c-2.343 0-4.25 1.906-4.25 4.25v7.5c0 2.344 1.907 4.25 4.25 4.25h7.5c2.344 0 4.25-1.906 4.25-4.25v-7.5c0-2.344-1.906-4.25-4.25-4.25zm3.25 11.75c0 1.792-1.458 3.25-3.25 3.25h-7.5c-1.792 0-3.25-1.458-3.25-3.25v-7.5c0-1.792 1.458-3.25 3.25-3.25h7.5c1.792 0 3.25 1.458 3.25 3.25z" />
      <path d="m12 7.75c-2.343 0-4.25 1.906-4.25 4.25s1.907 4.25 4.25 4.25 4.25-1.906 4.25-4.25-1.906-4.25-4.25-4.25zm0 7.5c-1.792 0-3.25-1.458-3.25-3.25s1.458-3.25 3.25-3.25 3.25 1.458 3.25 3.25-1.458 3.25-3.25 3.25z" />
      <path d="m16.5 6.5c-.552 0-1 .448-1 1s.448 1 1 1 1-.448 1-1-.448-1-1-1z" />
    </svg>
  );
}
