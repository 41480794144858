import dynamic from 'next/dynamic';

const HotelLogo = dynamic<{ className: string; fillColor: string }>(() =>
  import('./sezhihi/hotel-logo').then((i) => i.HotelLogo)
);

export const hotelLogoHeader = (
  <HotelLogo className="antialiased w-24 md:w-28" fillColor="#ffffff" />
);

export const hotelLogoFooter = (
  <HotelLogo
    className="antialiased w-20 mr-2 md:mr-0 md:w-28"
    fillColor="#ffffff"
  />
);
