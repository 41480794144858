import React from 'react';
export function Currency({
  className,
  fillColor,
}: {
  className?: string;
  fillColor?: string;
}) {
  return (
    <svg className={className} viewBox="0 0 24 24" style={{ fill: fillColor }}>
      <g data-name="Bounding Box">
        <path style={{ fill: 'none' }} d="M0,0V24H24V0Z" />
      </g>
      <g data-name="Icons Outlined">
        <g>
          <path d="M9.16,12.375a1.6023,1.6023,0,0,1,1.0859.4229.3753.3753,0,0,0,.5078-.5528,2.3533,2.3533,0,0,0-3.7406.7735H6.5a.375.375,0,0,0,0,.75h.3192A2.3212,2.3212,0,0,0,6.7959,14a2.3212,2.3212,0,0,0,.0233.2314H6.5a.375.375,0,0,0,0,.75h.5133a2.3533,2.3533,0,0,0,3.7406.7735.3753.3753,0,0,0-.5078-.5528,1.5972,1.5972,0,0,1-2.3639-.2207H9.084a.375.375,0,0,0,0-.75H7.5692a1.1607,1.1607,0,0,1,0-.4628H9.084a.375.375,0,0,0,0-.75H7.8822A1.6035,1.6035,0,0,1,9.16,12.375Z" />
          <path d="M15,4.625c-2.6055,0-5.375.832-5.375,2.375S12.3945,9.375,15,9.375a8.52,8.52,0,0,0,4.625-1.1216V9c0,.7666-1.9775,1.625-4.625,1.625a.375.375,0,0,0,0,.75,8.52,8.52,0,0,0,4.625-1.1216V11c0,.7666-1.9775,1.625-4.625,1.625a.375.375,0,0,0,0,.75,8.52,8.52,0,0,0,4.625-1.1216V13c0,.7666-1.9775,1.625-4.625,1.625a.375.375,0,0,0,0,.75,8.52,8.52,0,0,0,4.625-1.1216V15c0,.7666-1.9775,1.625-4.625,1.625a.375.375,0,0,0,0,.75c2.6055,0,5.375-.832,5.375-2.375V7C20.375,5.457,17.6055,4.625,15,4.625Zm0,4c-2.6475,0-4.625-.8584-4.625-1.625S12.3525,5.375,15,5.375,19.625,6.2334,19.625,7,17.6475,8.625,15,8.625Z" />
          <path d="M9,8.625A5.375,5.375,0,1,0,14.375,14,5.3809,5.3809,0,0,0,9,8.625Zm0,10A4.625,4.625,0,1,1,13.625,14,4.6308,4.6308,0,0,1,9,18.625Z" />
        </g>
      </g>
    </svg>
  );
}
