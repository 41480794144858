import dynamic from 'next/dynamic';

const HotelLogo = dynamic<{ className?: string }>(() =>
  import('./rrohihi/hotel-logo').then((i) => i.HotelLogo)
);

export const hotelLogoHeader = (
  <HotelLogo className="fill-current text-current antialiased w-28" />
);

export const hotelLogoFooter = (
  <HotelLogo className="fill-current text-current antialiased w-28" />
);
