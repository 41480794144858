import { InavMenuItem } from '@curated-property/shared-components';

export function checkIfNested(
  item: InavMenuItem | undefined | null,
  activePageId: number | undefined
) {
  if (activePageId === item?.pageId) {
    return true;
  }
  if (item?.childItems && item?.childItems?.length > 0) {
    for (let i = 0; i < item.childItems.length; i++)
      if (checkIfNested(item.childItems[i], activePageId)) {
        return true;
      }
  }
  return false;
}
