import { Tab, useTabsContext } from '@reach/tabs';
import { FilterPanelProps } from './filter-panel';
import cx from 'classnames';

interface FilterTabProps extends FilterPanelProps {
  id: number;
}

export function FilterTab(props: FilterTabProps) {
  const { selectedIndex } = useTabsContext();
  return (
    <Tab
      className={cx(
        'text-sm sm:text-xl text-center w-full max-w-lg font-headline font-extralight py-2 px-2 sm:py-4 sm:px-4 tracking-tight hover:bg-[#00000007]',
        props?.className,
        props?.id === selectedIndex
          ? 'border-0 border-b border-b-[5px]'
          : 'border-none'
      )}
      data-testid={props?.label}
    >
      {props?.children}
    </Tab>
  );
}
