export const AdditionalHead = () => {
  return (
    <>
      <link rel="stylesheet" href="https://use.typekit.net/fxv0jjv.css"></link>
      <style>
        {`
            :root {
              --font-headline: "baskerville-display-pt", serif !important;
              --font-serif:  "baskerville-display-pt", serif !important;
            }

            h1,h2,h3,h4,
            .font-headline {
              font-family: "baskerville-display-pt", serif !important;
              font-weight:300 !important;
              font-style:italic;

            }
            
            body {
                font-family: var(--font-serif) !important;
            }
            
          `}
      </style>
    </>
  );
};
