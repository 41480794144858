import React from 'react';
export function Diamond({
  className,
  fillColor,
}: {
  className?: string;
  fillColor?: string;
}) {
  return (
    <svg className={className} viewBox="0 0 24 24" style={{ fill: fillColor }}>
      <g data-name="Bounding Box">
        <path style={{ fill: 'none' }} d="M0,0V24H24V0Z" />
      </g>
      <g data-name="Icons Outlined">
        <path d="M20.3723,9.9863c0-.008-.0038-.0143-.0046-.0223a.3724.3724,0,0,0-.1005-.2261l-.0016-.0035-4-4A.3754.3754,0,0,0,16,5.625H8a.3754.3754,0,0,0-.2656.1094l-4,4-.0016.0035a.3724.3724,0,0,0-.1005.2261c-.0008.008-.0043.0143-.0046.0223L3.625,10a.3654.3654,0,0,0,.0756.2144c.0043.0061.0018.0141.0064.02l8,9.997c.0073.0091.0186.0129.0265.0211a.3721.3721,0,0,0,.0367.0386.337.337,0,0,0,.0387.0213.3506.3506,0,0,0,.055.0287.3157.3157,0,0,0,.0287.0153.3619.3619,0,0,0,.0913.0134c.0058,0,.01.0052.0161.0052a.3722.3722,0,0,0,.1074-.0156,46.0225,46.0225,0,0,1,.075-.04c.0111-.0064.0236-.0107.0338-.018l.0069-.0037a.3659.3659,0,0,0,.05-.0507c.0057-.0062.0142-.0088.0195-.0155l8-9.997c.0046-.0059.0021-.0139.0064-.02A.3654.3654,0,0,0,20.375,10ZM15.8447,6.375l3.25,3.25h-3.907l-2.4393-3.25Zm-7.6894,0h3.0963L8.8123,9.625H4.9053Zm-3.3742,4h3.94l2.363,7.8766ZM12,18.6933l-2.5855-8.62L12,6.6279,14.25,9.625H11a.375.375,0,0,0,0,.75h3.4958Zm.9154-.44,2.3641-7.8781h3.94Z" />
      </g>
    </svg>
  );
}
