import { useState, useEffect } from 'react';
import { ArrayElement, getIcon, GuestsState, Icon, mergeRooms } from '../utils';
import { RoomsCTA, RoomsCTAProps } from './rooms-cta';
import { HandleAnimations } from '../../functions/helper';
import {
  appliedCloudinaryParams,
  CroppedImage,
  getBrandDetails,
  IBrandDetails,
} from '@curated-property/utils';
import { carouselImageURL } from '../utils';
import cx from 'classnames';
import { useTranslation } from 'next-i18next';
import { ThreeSixtyTour } from '@curated-property/icon-list';
import { sendReward } from '@dx-ui/framework-conductrics';

export function RoomCard({
  ind,
  room,
  ctyhocn,
  loading,
  status,
  queryData,
  isError,
  pricingEnabled,
  hideLeadRate,
  rate,
  icons,
  inlineStyles,
  textAlignment,
  currency,
  resEnabled,
  associatedHotels,
  openDetailsModal,
  openVirtualTourModal,
  ctaData,
  textType,
}: {
  ind: number;
  room: ArrayElement<ReturnType<typeof mergeRooms>>;
  ctyhocn: string;
  loading: boolean;
  status?: string;
  queryData?: any;
  isError: boolean;
  pricingEnabled: boolean;
  hideLeadRate: boolean;
  rate: string;
  icons?: Icon[];
  inlineStyles?: any;
  textAlignment?: any;
  currency?: any;
  resEnabled?: boolean;
  associatedHotels?: string[];
  guests?: GuestsState;
  openDetailsModal?: () => void;
  closeDetailsModal?: () => void;
  openVirtualTourModal?: () => void;
  closeVirtualTourModal?: () => void;
  ctaData?: RoomsCTAProps;
  textType?: string;
}) {
  const { t } = useTranslation();
  const noPriceRoom = !loading && !isError && !rate;
  const dxRoomImage = carouselImageURL(room?.carousel[0]?.variants, 'md');
  const brandDetails: IBrandDetails = getBrandDetails(
    room?.brandName?.toLowerCase()
  );

  const [isClient, setIsClient] = useState(false);
  useEffect(() => {
    setIsClient(true);
  }, []);

  const feeStringColor = inlineStyles?.feeTransparencyDefaultColor;

  const animations = HandleAnimations({
    hideAnimation: inlineStyles?.hideAnimations !== 'show',
    start: `${inlineStyles?.animationDirection ?? '-translate-y'}-8`,
    delayOne: 'delay-100',
    delayTwo: 'delay-500',
    delayThree: 'delay-700',
    delayFour: 'delay-1000',
  });
  return (
    <div
      ref={animations?.ref}
      data-testid="roomTypeCard"
      data-element-id="tile-wrapper"
      data-ctyhocn={room?.hotelCtyhocn || ctyhocn}
      data-id={room.roomTypeCode}
      className={cx(
        `relative ${
          !inlineStyles?.disableDropShadow ? 'shadow-lg' : ''
        } flex flex-col`,
        animations?.one
      )}
      style={{
        backgroundImage: inlineStyles?.contentBackgroundImage
          ? `url('${appliedCloudinaryParams(
              inlineStyles?.contentBackgroundImage?.sourceUrl
            )}')`
          : null,
        backgroundColor: inlineStyles?.contentBackgroundColor || '#e9e9e9',
        backgroundSize: inlineStyles?.contentBackgroundSize || 'cover',
        backgroundRepeat: inlineStyles?.contentBackgroundRepeat || 'no-repeat',
        backgroundPosition:
          inlineStyles?.contentBackgroundPosition || 'left center',
        textAlign: textAlignment,
        justifyContent: inlineStyles?.textAlignment,
        transitionDelay: `${ind + 2}00ms`,
      }}
    >
      <div>
        {room.image?.sourceUrl ? (
          // wordpress image
          <CroppedImage
            src={room.image?.sourceUrl}
            alt={room.image?.altText}
            width="528"
            height="316"
            key={!noPriceRoom ? '1' : '2'}
            className="w-full"
            layout="responsive"
          />
        ) : (
          // core+ image
          <div className="relative">
            {dxRoomImage !==
            'https://assets.hiltonstatic.com/images/v1583957952/no-image/no-image.png' ? (
              <img className="w-full" src={dxRoomImage} alt="" />
            ) : (
              <div className="relative">
                <div className="absolute w-full h-full flex justify-center items-center text-lg">
                  <p>No image available</p>
                </div>
                <CroppedImage
                  src=""
                  alt=""
                  width="528"
                  height="316"
                  key={!noPriceRoom ? '1' : '2'}
                  className="w-full"
                  layout="responsive"
                />
              </div>
            )}
          </div>
        )}
      </div>
      <div
        className={cx(
          'text-left w-full flex flex-1 flex-col text-text transition-all p-4'
        )}
        style={{
          backgroundColor: inlineStyles?.activeTileColor,
        }}
      >
        <div className="flex justify-between">
          <h2
            className={cx(
              'font-bold leading-tight w-auto basis-4/5 text-balance',
              {
                'text-base sm:text-lg':
                  room?.icon?.sourceUrl || room?.brandName,
                'text-2xl': !room?.icon?.sourceUrl && !room?.brandName,
              },
              animations?.two
            )}
            style={{
              color: inlineStyles?.titleColor,
            }}
          >
            {room.roomTypeName}
          </h2>
          {room?.icon?.sourceUrl ||
          (room?.brandName && brandDetails?.brandLogoAlt != null) ? (
            <div
              data-testid="roomIcon"
              className={cx(
                'flex items-center justify-center py-2 basis-1/5',
                animations?.three
              )}
              style={{ fill: brandDetails?.brandColour || null }}
            >
              {room?.icon?.sourceUrl ? (
                <img
                  data-testid="roomIconImage"
                  src={room?.icon?.sourceUrl}
                  alt={room?.icon?.altText}
                  style={{
                    width: room?.iconWidth ? room?.iconWidth + 'px' : '70px',
                    maxWidth: '120px',
                  }}
                />
              ) : (
                <>
                  <div aria-hidden="true" data-testid="associatedHotelIcon">
                    {brandDetails?.brandLogoAlt}
                  </div>
                  <p className="sr-only">{room?.brandName}</p>
                </>
              )}
            </div>
          ) : null}
        </div>
        <div className="border-t border-overlay mt-3"></div>
        <div className="py-3 flex flex-1 flex-col justify-between content-between">
          <div>
            {room?.virtualMedia?.length > 0 && openVirtualTourModal ? (
              <button
                data-testid="btn-virtual-tours"
                className="btn btn-primary btn-virtual-tours fill-current py-0 px-2 !text-xs"
                onClick={openVirtualTourModal}
              >
                <div className="flex gap-2 items-center">
                  <ThreeSixtyTour
                    fillColor="fill-current"
                    className="w-8 mt-1"
                  ></ThreeSixtyTour>
                  <span>{t('tourThisRoom')}</span>
                </div>
              </button>
            ) : null}
          </div>
          <div
            className={cx('block text-left pb-2', animations?.three)}
            data-element-id="room-feature-list"
          >
            {icons?.map((icon) => {
              const { name, Icon } = getIcon(icon);
              if (!(name || Icon) || icon === undefined) {
                return null;
              }
              return (
                <div
                  key={name}
                  className="flex flex-row items-center leading-normal my-2"
                  style={{
                    color: inlineStyles?.textColor,
                  }}
                >
                  <Icon
                    className="w-7 h-7"
                    style={{
                      stroke: inlineStyles?.textColor,
                      color: inlineStyles?.textColor,
                    }}
                    fillColor={inlineStyles?.textColor}
                    fill={inlineStyles?.textColor}
                  />
                  <div data-testid="roomItemDesc" className="text-center ml-2">
                    {name}
                  </div>
                </div>
              );
            })}
          </div>
          <div
            className={cx(
              `flex flex-wrap items-center cp-roomTypes-ctas gap-2`,
              animations?.four
            )}
          >
            {resEnabled || associatedHotels?.length > 0 ? (
              <RoomsCTA
                {...ctaData}
                className="w-auto mt-1"
                inlineStyles={inlineStyles}
                currency={currency}
                textType={textType}
                onClick={() => sendReward('EngageCheckRatesCTA')}
              />
            ) : null}

            <button
              data-testid="detailsBtn"
              className="text-primary btn btn-primary-outline py-2 mt-1 rtl:mr-0 rtl:ml-3  w-auto"
              onClick={() => {
                openDetailsModal();
                sendReward('click-room-details');
              }}
            >
              <div className="flex items-center">
                {t('moreDetails')}{' '}
                <span className="sr-only">{t('forRoomTitle')}</span>
              </div>
            </button>
          </div>
          {room?.moreRatesFromRate?.feeTransparencyIndicator && (
            <div
              data-testid="feeTransparency"
              className={cx(
                'text-xs lg:text-sm pt-4 my-0 text-primary',
                animations?.four
              )}
              style={{ color: feeStringColor }}
              dangerouslySetInnerHTML={{
                __html: room?.moreRatesFromRate?.serviceChargeDetails || '',
              }}
            />
          )}
        </div>
      </div>
      <div className="absolute top-8 right-0">
        {isClient && pricingEnabled && !isError && !hideLeadRate && (
          <FromPricingOverlay
            loading={loading}
            status={status}
            queryData={queryData}
            fromRate={rate}
            noPriceRoom={noPriceRoom}
            inlineStyles={inlineStyles}
            currency={currency}
          />
        )}
      </div>
    </div>
  );
}

interface FromPricingOverlayProps {
  loading?: boolean;
  status?: string;
  queryData?: any;
  fromRate?: string;
  noPriceRoom?: boolean;
  inlineStyles?: any;
  currency?: any;
}

export function FromPricingOverlay({
  loading,
  status,
  queryData,
  fromRate,
  noPriceRoom,
  inlineStyles,
  currency,
}: FromPricingOverlayProps) {
  const { t } = useTranslation();
  return (
    <div
      data-testid="pricingOverlay"
      className="leading-tight bg-primary text-bg px-5 py-3"
      style={{ backgroundColor: inlineStyles?.rateBackgroundColor || null }}
    >
      <span className="sr-only">{`${t('from')} ${
        loading
          ? t('loadingPrice')
          : fromRate?.replace(currency?.currencyCode, currency?.description)
      }`}</span>
      {(loading || fromRate) && (
        <span
          key={`from`}
          className="block text-right text-base"
          style={{ color: inlineStyles?.rateFromColor || null }}
          aria-hidden={true}
        >
          {t('from')}
        </span>
      )}
      <p
        key={`price`}
        className={cx('font-bold block text-right', {
          'text-2xl': !loading,
          'text-sm animate-pulse': loading,
        })}
        style={{ color: inlineStyles?.rateFigureColor || null }}
        aria-hidden={true}
      >
        {loading ? t('loadingPrice') : fromRate}
      </p>
      {noPriceRoom && (
        <div style={{ color: inlineStyles?.rateErrorColor || null }}>
          {status === 'success' && !queryData
            ? t('unableToRetrievePricing')
            : t('roomNotAvailable')}
        </div>
      )}
    </div>
  );
}
