import React, { useContext } from 'react';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import cx from 'classnames';
// Usable for both event types
import { EventCalendarICS } from './event-calendar-ics';
import { appliedCloudinaryParams } from '@curated-property/utils';
import { EventCalendarInfoContext } from './event-calendar-info-context';
import { sanitize } from '@curated-property/utils';
import { RegularlyScheduledEventContentProps } from './lib/event-calendar-props';
interface BlockProps {
  title?: string;
  eventContent?: RegularlyScheduledEventContentProps;
  dateStringFmt?: string;
  operationTimeString?: string;
  eventType?: string;
  allDay?: boolean;
  hideICS?: boolean;
}

export function EventCalendarModalContentBlock({
  title,
  eventContent,
  dateStringFmt,
  operationTimeString,
  eventType,
  allDay,
  hideICS,
}: BlockProps) {
  const { t } = useTranslation();

  let startEndTimeDisplay = '';
  if (eventType === 'special') {
    startEndTimeDisplay = eventContent?.startTime;
    eventContent?.startTime &&
      eventContent?.endTime &&
      (startEndTimeDisplay += ` ${t('calendar.to')} ${eventContent?.endTime}`);
  } else {
    if (eventType === 'regular') {
      const timeObj = eventContent?.timeObj;
      startEndTimeDisplay = timeObj?.startTime;
      timeObj?.startTime &&
        timeObj?.endTime &&
        (startEndTimeDisplay += ` ${t('calendar.to')} ${timeObj?.endTime}`);
    }
  }

  const hotelInfoContext = useContext(EventCalendarInfoContext);

  return (
    <div className="p-4 w-full" tabIndex={0}>
      {eventContent?.image && (
        <div>
          <img
            src={appliedCloudinaryParams(eventContent?.image?.sourceUrl)}
            alt={eventContent?.image?.altText || ''}
          />
        </div>
      )}
      <div>
        {title && (
          <h2
            className="text-2xl my-2 font-bold"
            dangerouslySetInnerHTML={{
              __html: sanitize(title || ''),
            }}
            style={{
              color: hotelInfoContext?.inlineStyles?.eventsCalBaseTextColor,
            }}
          />
        )}
        {dateStringFmt && (
          <p
            className="my-2 text-primary font-bold"
            dangerouslySetInnerHTML={{
              __html: sanitize(dateStringFmt || ''),
            }}
          />
        )}
        {operationTimeString && (
          <p
            className="my-2 text-primary font-bold"
            dangerouslySetInnerHTML={{
              __html: sanitize(operationTimeString || ''),
            }}
          />
        )}

        {eventContent?.attractionEventLocation && (
          <p
            style={{
              color: hotelInfoContext?.inlineStyles?.eventsCalBaseTextColor,
            }}
            className="my-2 font-bold"
          >
            {eventContent?.attractionEventLocation}
          </p>
        )}
        {eventContent?.distanceFromResort && (
          <div
            className="my-2 font-bold"
            style={{
              color: hotelInfoContext?.inlineStyles?.eventsCalBaseTextColor,
            }}
          >
            {eventContent?.distanceFromResort}
          </div>
        )}
        {eventContent?.onSiteOrOffSite === 'On-site' &&
          eventContent?.locationAtResort && (
            <p
              style={{
                color: hotelInfoContext?.inlineStyles?.eventsCalBaseTextColor,
              }}
              className="my-2 font-bold"
              dangerouslySetInnerHTML={{
                __html: `${t('calendar.location')}: ${
                  eventContent?.locationAtResort
                }`,
              }}
            />
          )}
      </div>
      {eventContent?.description && (
        <div
          className="my-2"
          style={{
            color: hotelInfoContext?.inlineStyles?.eventsCalBaseTextColor,
          }}
          dangerouslySetInnerHTML={{
            __html: sanitize(eventContent?.description),
          }}
        />
      )}
      <div className="flex justify-items-start mt-4">
        {eventContent?.buttons?.map((i, e) => {
          return (
            i?.link?.url && (
              <Link
                key={`eventBtn${e}`}
                href={i?.link?.url}
                className={cx(
                  'mr-4 py-2 btn  w-auto font-bold text-center',
                  i?.buttonStyle ? `btn-${i?.buttonStyle}` : 'btn-primary'
                )}
                target={i?.link?.target}
              >
                {i?.link?.title}
              </Link>
            )
          );
        })}
      </div>
      {!allDay && !hideICS && (
        <div>
          <EventCalendarICS title={title} content={eventContent} />
        </div>
      )}
    </div>
  );
}
