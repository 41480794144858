import { Hero, heroPropMapper } from '@curated-property/shared-components';
import {
  getGlobalStyles,
  getPageDetails,
  PageProps,
  PartialComponentMapping,
} from '../';
import { HeroImageFlexProps } from 'libs/shared-components/src/lib/hero-image/hero-image.types';
import { useTranslation } from 'react-i18next';

const overrides: PartialComponentMapping = {
  Page_Acfflexible_FlexibleComponents_Masthead: (
    flex: HeroImageFlexProps,
    {
      dxGqlInfo,
      wpPageInfo,
      wpThemeSettings,
      ctyhocn,
      associatedHotelInfo,
    }: NonNullable<PageProps>,
    key: number
  ) => {
    const [t] = useTranslation();
    const globalStyles = getGlobalStyles(wpThemeSettings);
    const oscBookButtonStyle =
      wpThemeSettings?.shopFormSettings?.shopFormSettings
        ?.bookButtonStyleFromGlobal?.shopFormBookGlobalButtonStyleButtonStyle ||
      '';

    const heroProps = heroPropMapper(flex, globalStyles, t, oscBookButtonStyle);

    const pageDetails = getPageDetails(wpPageInfo);
    if (flex?.mastheadComponentSettings?.showHide) {
      return null;
    }

    return (
      <Hero
        {...heroProps}
        isShopFormInHeader={
          wpThemeSettings?.shopFormSettings?.shopFormSettings?.attachToHeader
        }
        propertyHasAlert={
          dxGqlInfo?.hotel?.alerts?.length ?? 0 > 0 ? true : false
        }
        key={key + (pageDetails?.slug ?? '')}
        componentInstance={key}
        pageDetails={pageDetails}
        bookingWidgetConfig={{
          ctyhocn: ctyhocn,
          defaultArrivalDate: dxGqlInfo?.hotel?.resEnabledDateFmt,
          gmtHours: dxGqlInfo?.hotel?.localization?.gmtHours,
          brandCode: dxGqlInfo?.hotel?.brandCode || 'HI',
          resEnabled: dxGqlInfo?.hotel?.display?.resEnabled,
          currency: dxGqlInfo?.hotel?.localization?.currencyCode,
          associatedHotels: associatedHotelInfo,
          maxRooms: 7,
          groupBooking: {
            url: 'https://thenomadhotellondon.tripleseat.com/booking_request/25292',
          },
        }}
      />
    );
  },
};
export default overrides;
