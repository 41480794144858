import React from 'react';
export function Terrace({
  className,
  fillColor,
}: {
  className?: string;
  fillColor?: string;
}) {
  return (
    <svg className={className} viewBox="0 0 24 24" style={{ fill: fillColor }}>
      <g data-name="Bounding Box">
        <path style={{ fill: 'none' }} d="M0,0V24H24V0Z" />
      </g>
      <g data-name="Icons Outlined">
        <g>
          <path d="M16.375,13A.3751.3751,0,0,0,16,12.625H12.375V10.3262a.375.375,0,0,0-.75,0V12.625H8a.375.375,0,0,0,0,.75h3.625V19a.375.375,0,0,0,.75,0V13.375H16A.3751.3751,0,0,0,16.375,13Z" />
          <path d="M9.375,16A.3751.3751,0,0,0,9,15.625H8.0331c-.0044,0-.0086,0-.013,0H5.304l-.9407-3.7168a.3747.3747,0,1,0-.7266.1836l.98,3.8729-.9724,2.9171a.3739.3739,0,0,0,.2373.4737A.359.359,0,0,0,4,19.375a.3744.3744,0,0,0,.3555-.2568L5.27,16.375H7.73l.9143,2.7432A.3744.3744,0,0,0,9,19.375a.359.359,0,0,0,.1182-.02.3739.3739,0,0,0,.2373-.4737L8.52,16.375H9A.3751.3751,0,0,0,9.375,16Z" />
          <path d="M19.3831,15.9647l.98-3.8729a.3747.3747,0,1,0-.7266-.1836L18.696,15.625H15a.375.375,0,0,0,0,.75h.48l-.8357,2.5068a.3739.3739,0,0,0,.2373.4737.359.359,0,0,0,.1182.02.3744.3744,0,0,0,.3555-.2568L16.27,16.375h2.46l.9143,2.7432A.3744.3744,0,0,0,20,19.375a.359.359,0,0,0,.1182-.02.3739.3739,0,0,0,.2373-.4737Z" />
          <path d="M3.65,9.1254A.3639.3639,0,0,0,3.6911,9.2a.3527.3527,0,0,0,.0218.04l.0113.0091a.3717.3717,0,0,0,.0662.0533.38.38,0,0,0,.0508.0343.38.38,0,0,0,.0687.02.3633.3633,0,0,0,.0734.0148L4,9.375H20a.3717.3717,0,0,0,.151-.0329c.0114-.0051.02-.0147.031-.0209a.3708.3708,0,0,0,.0943-.0727l.0108-.0083a.3527.3527,0,0,0,.0218-.04.3639.3639,0,0,0,.0408-.0748.3583.3583,0,0,0,.0144-.0715A.3478.3478,0,0,0,20.375,9c0-.006-.0031-.0109-.0034-.0168a.3724.3724,0,0,0-.0152-.075.3781.3781,0,0,0-.0188-.0659A.3823.3823,0,0,0,20.3,8.787a.3755.3755,0,0,0-.0491-.0615l-.01-.0126A14.4174,14.4174,0,0,0,12.375,5.64V5a.375.375,0,0,0-.75,0v.64A14.4174,14.4174,0,0,0,3.7588,8.7129l-.01.0126A.3755.3755,0,0,0,3.7,8.787a.3823.3823,0,0,0-.0372.0553.3781.3781,0,0,0-.0188.0659.3724.3724,0,0,0-.0152.075c0,.0059-.0034.0108-.0034.0168a.3478.3478,0,0,0,.0109.0539A.3583.3583,0,0,0,3.65,9.1254ZM12.375,6.3905A13.3231,13.3231,0,0,1,18.8334,8.625H12.375Zm-.75,0V8.625H5.1666A13.3231,13.3231,0,0,1,11.625,6.3905Z" />
        </g>
      </g>
    </svg>
  );
}
