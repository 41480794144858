import React from 'react';
export function KidsClub({
  className,
  fillColor,
}: {
  className?: string;
  fillColor?: string;
}) {
  return (
    <svg className={className} viewBox="0 0 24 24" style={{ fill: fillColor }}>
      <g data-name="Bounding Box">
        <path style={{ fill: 'none' }} d="M0,0V24H24V0Z" />
      </g>
      <g data-name="Icons Outlined">
        <path d="M19.292,3.9961a.3732.3732,0,0,0-.11-.2613l0,0,0,0a.3738.3738,0,0,0-.2645-.1093H11.0012l-.0066.0018a.3629.3629,0,0,0-.2339.0967c-.0077.0065-.0191.0036-.0263.0109-.0156.0156-.0111.0392-.0233.0567a.3532.3532,0,0,0-.0666.0907L6.7209,15.6525A2.4433,2.4433,0,0,0,5.32,16.32a2.3751,2.3751,0,0,0,3.3565,3.3613l1.5918-1.5625a1.58,1.58,0,0,1,2.4628,0,.375.375,0,0,0,.5372-.5234A2.3321,2.3321,0,0,0,9.7373,17.59L8.1484,19.1484a1.6245,1.6245,0,1,1-2.292-2.3027,1.85,1.85,0,0,1,1.1514-.4707c.0075,0,.0137-.0042.0211-.0048a.3337.3337,0,0,0,.0893-.0147l12-4c.0374-.0123.06-.0442.09-.0664.0177-.0123.0414-.0077.0571-.0235.0072-.0072.0044-.0187.0109-.0263a.3612.3612,0,0,0,.0942-.2293l.0035-.0113.0008-.0026Zm-1.2759.3789L14.9718,7.4406,11.9062,4.375Zm-6.8523.32,3.2786,3.2785L7.8619,14.6Zm4.5706,4.57,2.57,2.5706-9.9283,3.31,6.8563-6.904.0064-.0026.0026-.0065L18.5513,4.9l.0641,6.1838-2.35-2.35a.3756.3756,0,0,0-.5312.5312Z" />
      </g>
    </svg>
  );
}
