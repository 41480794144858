import dynamic from 'next/dynamic';

const HotelLogo = dynamic<{ className: string; fillColor: string }>(() =>
  import('./laxavci/hotel-logo').then((i) => i.HotelLogo)
);

export const hotelLogoHeader = (
  <HotelLogo
    fillColor="#a98b5f"
    className="text-bg antialiased w-44 md:w-48 h-24"
  />
);

export const hotelLogoFooter = (
  <HotelLogo fillColor="#a98b5f" className="antialiased w-40 md:w-48 h-12" />
);
