import originalSanitize from 'sanitize-html';

export function sanitize(
  dirty?: string,
  options: originalSanitize.IOptions = {
    allowedTags: [
      'h3',
      'h4',
      'h5',
      'h6',
      'blockquote',
      'p',
      'a',
      'ul',
      'ol',
      'nl',
      'li',
      'b',
      'i',
      'strong',
      'em',
      'strike',
      'code',
      'hr',
      'br',
      'div',
      'span',
      'table',
      'thead',
      'caption',
      'tbody',
      'tr',
      'th',
      'td',
      'pre',
      'img',
      'iframe',
      'svg',
      'g',
      'path',
    ],
    allowedAttributes: {
      '*': ['class', 'style', 'data-*', 'width', 'height', 'aria-*', 'role'],
      a: ['href', 'name', 'target', 'rel'],
      img: ['src', 'alt'],
      iframe: ['src', 'width', 'height', 'frameborder'],
      svg: ['viewbox'],
      path: ['d', 'fill'],
      g: ['d', 'fill'],
    },
    selfClosing: [
      'img',
      'br',
      'hr',
      'area',
      'base',
      'basefont',
      'input',
      'link',
      'meta',
    ],
    // URL schemes we permit
    allowedSchemes: ['http', 'https', 'ftp', 'mailto', 'tel'],
    allowedSchemesByTag: {},
  }
) {
  return originalSanitize(dirty || '', options);
}
