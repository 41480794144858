import React from 'react';

export function Balcony(props: React.SVGAttributes<SVGElement>) {
  return (
    <svg viewBox="0 0 33 34" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M21.968 29.336h4.76V18.66h-4.76v10.677zm-19.96 2.656h29.53v-1.648H2.007v1.648zm4.81-2.656h4.229V18.66h-4.23v10.677zm0-11.685h9.45V2.008h-9.45v15.643zm5.237 11.685h4.213V18.66h-4.213v10.677zm5.221 0h3.683V18.66h-3.683v10.677zm0-11.685h9.452V2.008h-9.452v15.643zm15.27 2.062v-1.558a.504.504 0 00-.505-.504h-4.305V1H5.809v16.651H1.504a.504.504 0 00-.504.504v1.558a.504.504 0 101.008 0v-1.054h3.801v10.677H1V33h31.545v-3.664h-4.81V18.66h3.802v1.054a.504.504 0 101.008 0z"
        strokeWidth={0.5}
        fillRule="evenodd"
        fill={props?.style?.stroke}
      />
    </svg>
  );
}
