import React from 'react';
export function UserSquare({
  className,
  fillColor,
}: {
  className?: string;
  fillColor?: string;
}) {
  return (
    <svg className={className} viewBox="0 0 24 24" style={{ fill: fillColor }}>
      <g data-name="Bounding Box">
        <path style={{ fill: 'none' }} d="M0,0V24H24V0Z" />
      </g>
      <g data-name="Icons Outlined">
        <g>
          <path d="M17.4443,4.625H6.5557A1.933,1.933,0,0,0,4.625,6.5557V17.4443A1.933,1.933,0,0,0,6.5557,19.375H17.4443a1.933,1.933,0,0,0,1.9307-1.9307V6.5557A1.933,1.933,0,0,0,17.4443,4.625ZM18.625,17.4443a1.1816,1.1816,0,0,1-1.1807,1.1807H6.5557A1.1816,1.1816,0,0,1,5.375,17.4443V6.5557A1.1816,1.1816,0,0,1,6.5557,5.375H17.4443A1.1816,1.1816,0,0,1,18.625,6.5557Z" />
          <path d="M13.54,12.4192a2.875,2.875,0,1,0-3.08,0A4.3716,4.3716,0,0,0,7.625,16.5a.375.375,0,0,0,.75,0,3.625,3.625,0,0,1,7.25,0,.375.375,0,0,0,.75,0A4.3715,4.3715,0,0,0,13.54,12.4192ZM12,12.125A2.125,2.125,0,1,1,14.125,10,2.1272,2.1272,0,0,1,12,12.125Z" />
        </g>
      </g>
    </svg>
  );
}
