import React from 'react';

export function PlayControl({
  className,
  fill,
}: {
  className?: string;
  fill?: string;
}) {
  return (
    <svg
      className={className}
      width="14"
      height="17"
      viewBox="0 0 14 17"
      fill={fill ?? '#121212'}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M13.25 6.70439L2.25 0.204393C1.34375 -0.326858 0 0.204393 0 1.48564V14.4856C0 15.6731 1.25 16.3919 2.25 15.7981L13.25 9.29814C14.2188 8.70439 14.2188 7.29814 13.25 6.70439Z" />
    </svg>
  );
}
