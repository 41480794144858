import React from 'react';
export function Iron({
  className,
  fillColor,
}: {
  className?: string;
  fillColor?: string;
}) {
  return (
    <svg className={className} viewBox="0 0 24 24" style={{ fill: fillColor }}>
      <g data-name="Bounding Box">
        <path style={{ fill: 'none' }} d="M0,0V24H24V0Z" />
      </g>
      <g data-name="Icons Outlined">
        <g>
          <path d="M21.0547,6.35a3.53,3.53,0,0,0-2.4224.6072l-.269-1.05a.4.4,0,0,0-.3779-.2822c-.2481.01-6.1084.2568-9.1543,2.0381C4.835,10,2.7354,15.6328,2.6475,15.8711A.3751.3751,0,0,0,3,16.375H16a.3754.3754,0,0,0,.2656-.1094,6.9913,6.9913,0,0,1,3.7725-2.0908.3756.3756,0,0,0,.3252-.4658L18.8392,7.7635c.0049-.0048.0116-.0064.0163-.0115a2.6313,2.6313,0,0,1,2.0908-.66.375.375,0,0,0,.1084-.7422Zm-1.5176,7.1563a8.0464,8.0464,0,0,0-3.69,2.1191H3.5566C4.166,14.1973,6.1191,10.1182,9.21,8.3105c2.5058-1.4658,7.2627-1.8437,8.5029-1.92Z" />
          <path d="M16,17.625H3a.375.375,0,0,0,0,.75H16a.375.375,0,0,0,0-.75Z" />
          <path d="M15.0205,7.627a6.3554,6.3554,0,0,0-3.207,1.0566A8.8428,8.8428,0,0,0,8.67,11.8213.375.375,0,0,0,9,12.375h6a1.889,1.889,0,0,0,1.2412-.5439A2.4838,2.4838,0,0,0,16.875,9.999C16.8691,7.6729,15.0488,7.627,15.0205,7.627ZM15,11.625H9.69a8.1161,8.1161,0,0,1,2.4951-2.2891,5.842,5.842,0,0,1,2.81-.96c.1153.0019,1.127.0683,1.1309,1.625S15.1123,11.623,15,11.625Z" />
        </g>
      </g>
    </svg>
  );
}
