import React from 'react';

export function PinIconSolid({
  className,
  fillColor,
}: {
  className?: string;
  fillColor?: string;
}) {
  return (
    <svg
      className={className}
      width="14"
      height="19"
      viewBox="0 0 14 19"
      fill={fillColor}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.04098 17.6949C6.35707 18.1866 7.09463 18.1866 7.41073 17.6949C12.5385 10.3193 13.4868 9.54665 13.4868 6.80714C13.4868 3.08421 10.4663 0.0637207 6.74341 0.0637207C2.98537 0.0637207 0 3.08421 0 6.80714C0 9.54665 0.913171 10.3193 6.04098 17.6949ZM6.74341 9.61689C5.16293 9.61689 3.93366 8.38762 3.93366 6.80714C3.93366 5.26177 5.16293 3.99738 6.74341 3.99738C8.28878 3.99738 9.55317 5.26177 9.55317 6.80714C9.55317 8.38762 8.28878 9.61689 6.74341 9.61689Z"
        fill={fillColor}
      />
    </svg>
  );
}
