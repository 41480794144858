import React, { ReactNode } from 'react';
import cx from 'classnames';
import {
  AnimationOutput,
  HandleAnimations,
  HandleWYSIWYGContent,
  iconmapper,
} from './functions/helper';
import {
  GIS_Padder,
  GIS_merge,
  StyleObject,
} from './functions/global-instance-styles';
import {
  appliedCloudinaryParams,
  isReducedMotion,
} from '@curated-property/utils';
import { ACFAnchorProps, AnchorLink } from './global/anchor-link';
import contentStyles from './css/contentStyle.module.css';

interface ContentProps {
  copy?: string;
  buttons?: Array<ACFAnchorProps>;
}

export interface InformationBarProps {
  location?: ContentProps;
  hours?: ContentProps;
  details?: ContentProps;
  phoneNumber?: string;
  emailAddress?: string;
  bookCta?: ACFAnchorProps;
  hideIcons?: boolean;
  globalStyles?: StyleObject;
  instanceStyles?: StyleObject;
}

export function InformationBar({
  location,
  hours,
  details,
  phoneNumber,
  emailAddress,
  bookCta,
  hideIcons,
  globalStyles,
  instanceStyles,
}: InformationBarProps) {
  const inlineStyles = GIS_merge(globalStyles, instanceStyles);
  const paddingStyles = GIS_Padder(
    inlineStyles?.paddingTop,
    inlineStyles?.paddingBottom
  );
  const iconMap = iconmapper();
  const hideAnimations: boolean =
    inlineStyles?.hideAnimations !== 'show' || isReducedMotion;
  const isAltContact: boolean =
    inlineStyles?.contactBackgroundColor || inlineStyles?.contactBorderColor
      ? true
      : false;
  const hasContact: boolean =
    phoneNumber || emailAddress || bookCta ? true : false;
  const textAlignment = inlineStyles?.textAlignment?.includes('-')
    ? inlineStyles?.textAlignment?.split('-')?.[1]
    : inlineStyles?.textAlignment;
  const colClasses = `flex flex ${
    textAlignment === 'center' ? 'flex-col' : ''
  } px-6 w-full sm:w-1/2 xl:w-auto grow border-transparent sm:even:border-l xl:border-l first:!border-l-0 rtl:xl:border-r rtl:xl:border-l-0 rtl:first:border-r-0`;
  const alignmentClasses = `items-${textAlignment} text-${textAlignment}`;
  const ContactIcon = iconMap['contact'];
  const PhoneIcon = iconMap['telephone'];
  const EmailIcon = iconMap['mail'];
  const colCount: number = [
    location?.copy,
    hours?.copy,
    details?.copy,
    hasContact,
  ].filter((c) => c).length;
  let itemContentMaxWidth = '';
  switch (colCount) {
    case 3:
      itemContentMaxWidth = 'xl:max-w-[450px]';
      break;
    case 4:
      itemContentMaxWidth = 'xl:max-w-[250px]';
      break;
  }
  const numHash = {
    1: 'one',
    2: 'two',
    3: 'three',
    4: 'four',
  };

  const animations: AnimationOutput = HandleAnimations({
    hideAnimation: hideAnimations,
    start: `${inlineStyles?.animationDirection ?? '-translate-y'}-8`,
    delayOne: 'delay-100',
    delayTwo: 'delay-200',
    delayThree: 'delay-300',
    delayFour: 'delay-500',
  });

  let itemInst = 0;
  const InfoItem = (content: ContentProps, title: string): ReactNode => {
    itemInst += 1;
    const iconName =
      title === 'Location'
        ? 'location'
        : title === 'Details'
        ? 'information'
        : 'clock';
    const Icon = iconMap[iconName];

    return (
      <div
        data-testid={`information-bar-item-${title}`}
        className={cx(
          colClasses,
          alignmentClasses,
          animations[numHash[itemInst]]
        )}
        style={{ borderColor: inlineStyles?.dividerColor }}
      >
        {!hideIcons && (
          <div className={textAlignment !== 'center' ? 'h-full mr-2' : ''}>
            <Icon
              className="h-10 fill-primary"
              fillColor={inlineStyles?.iconColor}
            />
          </div>
        )}
        <div
          data-testid="information-bar-item"
          className={cx('flex flex-col mt-px', alignmentClasses)}
        >
          <h2
            data-testid={`heading-${title}`}
            className="text-2xl font-semibold mb-3"
            style={{ color: inlineStyles?.titleColor }}
          >
            {title}
          </h2>
          {content && (
            <div
              className={cx(
                '[&>*]:!m-0',
                itemContentMaxWidth,
                contentStyles.paragraphStyle,
                contentStyles.listStyle
              )}
              dangerouslySetInnerHTML={{
                __html: HandleWYSIWYGContent(
                  content?.copy,
                  inlineStyles?.textColor
                ),
              }}
              style={{
                color: inlineStyles?.textColor,
              }}
            ></div>
          )}
          {content?.buttons && (
            <div
              className={cx(
                'w-auto flex flex-col',
                textAlignment ? alignmentClasses : 'items-start'
              )}
            >
              {content?.buttons?.map((link, key) => {
                if (!link) {
                  return null;
                }
                return (
                  <AnchorLink
                    key={key}
                    url={link.link?.url || ''}
                    title={link.link?.title || ''}
                    target={link.link?.target || ''}
                    buttonStyle={link?.buttonStyle || 'primary'}
                    className="mt-2 !mx-0 !text-center"
                  />
                );
              })}
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <section
      className={cx(
        inlineStyles?.showHide && 'hidden',
        'overflow-hidden text-lg',
        paddingStyles
      )}
      style={{
        backgroundColor: inlineStyles?.componentBackgroundColor,
        backgroundImage: inlineStyles?.componentBackgroundImage
          ? `url('${appliedCloudinaryParams(
              inlineStyles?.componentBackgroundImage?.sourceUrl,
              inlineStyles?.componentBackgroundRepeat
            )}')`
          : null,
        backgroundSize: inlineStyles?.componentBackgroundSize || null,
        backgroundRepeat:
          inlineStyles?.componentBackgroundRepeat || 'no-repeat',
        backgroundPosition:
          inlineStyles?.componentBackgroundPosition || 'left center',
      }}
    >
      <div
        data-testid="information-bar"
        ref={animations?.ref}
        className={cx(
          'container flex flex-wrap justify-between items-start gap-y-8 items-stretch border-y-2 border-transparent py-6',
          inlineStyles?.borderBottomColor && 'border-b-2',
          inlineStyles?.borderTopColor && 'border-t-2'
        )}
        style={{
          backgroundColor: inlineStyles?.contentBackgroundColor,
          backgroundImage: inlineStyles?.contentBackgroundImage
            ? `url('${appliedCloudinaryParams(
                inlineStyles?.contentBackgroundImage?.sourceUrl,
                inlineStyles?.contentBackgroundRepeat
              )}')`
            : null,
          backgroundSize: inlineStyles?.contentBackgroundSize || null,
          backgroundPosition: inlineStyles?.contentBackgroundPosition || null,
          backgroundRepeat:
            inlineStyles?.contentBackgroundRepeat || 'no-repeat',
          borderTopColor: inlineStyles?.borderTopColor,
          borderBottomColor: inlineStyles?.borderBottomColor,
        }}
      >
        {location?.copy && InfoItem(location, 'Location')}
        {hours?.copy && InfoItem(hours, 'Hours')}
        {details?.copy && InfoItem(details, 'Details')}

        {hasContact && (
          <div
            data-testid="information-bar-item-contact"
            className={cx(
              isAltContact && 'items-start',
              colClasses,
              alignmentClasses,
              animations[numHash[colCount]]
            )}
            style={{
              borderColor: !isAltContact ? inlineStyles?.dividerColor : null,
            }}
          >
            <div
              data-testid="information-bar-item"
              className={isAltContact ? `w-full py-4 px-5 h-auto` : ''}
              style={{
                backgroundColor: isAltContact
                  ? inlineStyles?.contactBackgroundColor || '#D9D9D9'
                  : null,
              }}
            >
              <div
                className={
                  isAltContact
                    ? 'pt-2 pb-4 border-y-2 flex justify-center'
                    : textAlignment !== 'center'
                    ? 'flex'
                    : ''
                }
                style={{
                  borderColor: inlineStyles?.contactBorderColor || '#000',
                }}
              >
                <div
                  className={cx('flex items-center', {
                    'flex-col': textAlignment === 'center',
                  })}
                >
                  {!hideIcons && !isAltContact && (
                    <div
                      className={
                        textAlignment !== 'center' ? 'h-full mr-2' : ''
                      }
                    >
                      <ContactIcon
                        className="h-10 fill-primary"
                        fillColor={inlineStyles?.iconColor}
                      />
                    </div>
                  )}
                </div>
                <div
                  className={cx(
                    'flex flex-col mt-px',
                    !isAltContact ? alignmentClasses : 'items-center',
                    itemContentMaxWidth
                  )}
                >
                  {!isAltContact && (
                    <h2
                      data-testid="heading-contact"
                      className="text-2xl font-semibold mb-3"
                      style={{ color: inlineStyles?.titleColor }}
                    >
                      Contact
                    </h2>
                  )}
                  {phoneNumber && (
                    <div
                      className="flex mb-3 sm:mb-0"
                      style={{ color: inlineStyles?.textColor }}
                    >
                      <PhoneIcon className="h-7 mr-2 fill-current" />
                      <a
                        className="hover:underline"
                        href={`tel:${phoneNumber}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {phoneNumber}
                      </a>
                    </div>
                  )}
                  {emailAddress && (
                    <div
                      className="flex mb-3 sm:mb-0 break-all"
                      style={{ color: inlineStyles?.textColor }}
                    >
                      <EmailIcon className="h-7 mr-2 fill-current" />
                      <a
                        className="hover:underline"
                        href={`mailto:${emailAddress}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {emailAddress}
                      </a>
                    </div>
                  )}
                  <AnchorLink
                    url={bookCta.link?.url || '#'}
                    title={bookCta.link?.title || 'Book Online'}
                    target={bookCta.link?.target || ''}
                    buttonStyle={bookCta?.buttonStyle || 'primary'}
                    className="mt-2 !mx-0 !text-center"
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </section>
  );
}
