import React from 'react';
export function Snacks({
  className,
  fillColor,
}: {
  className?: string;
  fillColor?: string;
}) {
  return (
    <svg className={className} viewBox="0 0 24 24" style={{ fill: fillColor }}>
      <g data-name="Bounding Box">
        <path style={{ fill: 'none' }} d="M0,0V24H24V0Z" />
      </g>
      <g data-name="Icons Outlined">
        <g>
          <path d="M11,7.625A.3751.3751,0,0,0,10.625,8v5a.375.375,0,0,0,.75,0V8A.3751.3751,0,0,0,11,7.625Z" />
          <path d="M13,7.625A.3751.3751,0,0,0,12.625,8v5a.375.375,0,0,0,.75,0V8A.3751.3751,0,0,0,13,7.625Z" />
          <path d="M12,3.625A4.38,4.38,0,0,0,7.625,8v7A1.377,1.377,0,0,0,9,16.375h6A1.377,1.377,0,0,0,16.375,15V8A4.38,4.38,0,0,0,12,3.625ZM15.625,15a.6256.6256,0,0,1-.625.625H9A.6256.6256,0,0,1,8.375,15V8a3.625,3.625,0,0,1,7.25,0Z" />
          <path d="M13,17.625a.3751.3751,0,0,0-.375.375v1a.625.625,0,0,1-1.25,0V18a.375.375,0,0,0-.75,0v1a1.375,1.375,0,0,0,2.75,0V18A.3751.3751,0,0,0,13,17.625Z" />
        </g>
      </g>
    </svg>
  );
}
