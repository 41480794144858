import React from 'react';
export function CleaningEquipment({
  className,
  fillColor,
}: {
  className?: string;
  fillColor?: string;
}) {
  return (
    <svg
      className={className}
      style={{ fill: fillColor }}
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 24 24"
    >
      <g>
        <g>
          <path style={{ fill: 'none' }} d="M0,0v24h24V0H0z" />
        </g>
        <g>
          <g>
            <path
              d="M10.875,6.5c0-0.2071-0.1679-0.375-0.375-0.375S10.125,6.2929,10.125,6.5C10.1259,7.5351,10.9649,8.3741,12,8.375
                    c0.2071,0,0.375-0.1679,0.375-0.375S12.2071,7.625,12,7.625C11.379,7.6241,10.8759,7.121,10.875,6.5z"
            />
            <path
              d="M19,12.625h-0.625V10.5c-0.0039-1.0476-0.5791-2.0096-1.5-2.5089V6c-0.0001-0.2071-0.1679-0.3749-0.375-0.375h-2
                    C14.2929,5.6251,14.1251,5.7929,14.125,6v1.9911c-0.9209,0.4993-1.4961,1.4613-1.5,2.5089v0.3621
                    c-0.3503-0.3436-0.759-0.622-1.207-0.8221l-0.459-0.2246C9.6368,9.1809,9.0557,8.8945,9.005,6.374
                    C9.2099,6.3715,9.3747,6.2049,9.375,6C9.3753,5.655,9.655,5.3753,10,5.375h3C13.2071,5.3749,13.3749,5.2071,13.375,5V3
                    C13.3749,2.7929,13.2071,2.6251,13,2.625H6.5c-0.1181-0.0003-0.2294,0.0554-0.3,0.15l-1.5,2
                    c-0.1121,0.1491-0.0976,0.3579,0.0342,0.49l1,1C5.8047,6.3355,5.9003,6.3751,6,6.375h0.4955
                    c0.0816,1.3028-0.0808,2.6093-0.4789,3.8525c-0.2753,0.7686-0.4061,1.5814-0.3859,2.3975H5c-0.7594,0-1.375,0.6156-1.375,1.375
                    S4.2406,15.375,5,15.375h0.1546L5.46,19.0381C5.5912,20.3487,6.6831,21.3532,8,21.375h8c1.3169-0.0218,2.4088-1.0263,2.54-2.3369
                    l0.3054-3.6631H19c0.7594,0,1.375-0.6156,1.375-1.375S19.7594,12.625,19,12.625z M14.875,7.625v-1.25h1.25v1.25H14.875z
                     M13.375,10.5c0-1.1736,0.9514-2.125,2.125-2.125s2.125,0.9514,2.125,2.125v2.125h-4.25V10.5z M12.625,4.625h-1.25v-1.25h1.25
                    V4.625z M5.495,4.965l1.1924-1.59h3.9376v1.25H10c-0.6147,0.0011-1.1544,0.409-1.3232,1H6.1553L5.495,4.965z M6.7255,10.4729
                    c0.4278-1.3214,0.6041-2.7113,0.52-4.0977H8.256C8.3171,9.24,9.09,9.75,10.6338,10.4922l0.45,0.2187
                    c0.8455,0.2735,1.4435,1.0285,1.5162,1.9141H6.38c-0.0197-0.7327,0.0975-1.4626,0.3456-2.1523L6.7255,10.4729z M17.792,18.9756
                    c-0.1017,0.9201-0.8666,1.6242-1.792,1.6494H8c-0.9254-0.0252-1.6904-0.7292-1.792-1.6494l-0.3-3.6006h12.1841L17.792,18.9756z
                     M19,14.625H5c-0.3452,0-0.625-0.2798-0.625-0.625S4.6548,13.375,5,13.375h14c0.3452,0,0.625,0.2798,0.625,0.625
                    S19.3452,14.625,19,14.625z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
