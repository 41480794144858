import React from 'react';

const OutdoorPoolIcon = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg viewBox="0 0 44 44" focusable="false" {...props}>
      <g
        strokeWidth="1.5"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        stroke="currentColor"
      >
        <path d="M36.72 20.723a3.341 3.341 0 1 1-6.683 0 3.342 3.342 0 1 1 6.684 0zM40.434 31.406s-4.111-3.589-9.365-3.589c-5.256 0-9.792 2.62-15.59 2.62-6.94 0-10.693-3.189-10.693-3.189" />
        <path d="M9.77 29.476l12.662-7.545-1.385-3.071s-.302-1.255-1.34-.908c-1.039.345-6.058 2.922-6.9 3.244-2.134.821-3.118-1.404-1.053-2.608 2.675-1.561 7.699-4.042 8.462-4.45.965-.52 1.874-.614 2.691.784 1.187 2.034 4.88 7.615 5.666 8.997.786 1.383 1.213 2.55-.291 3.995" />
      </g>
    </svg>
  );
};

export default OutdoorPoolIcon;
