import gql from 'graphql-tag-transform.macro';

const PropertyAlertsFragment = gql`
  fragment PropertyAlertsFragment on Hotel {
    alerts {
      description(format: md)
      headline
      type
      link {
        _id
        adaDescription
        isNewWindow
        label
        url
      }
    }
  }
`;

const MeetingsFragment = gql`
  fragment MeetingsFragment on Hotel {
    showMeetingsSimplified
    meetings {
      desc
      eEventsUrl
      headline
      hiltonLinkUrl
      largestExhibitSpaceArea
      largestExhibitSpaceAreaFmt
      largestMeetingRoom
      largestMeetingRoomArea
      largestMeetingRoomAreaFmt
      largestMeetingRoomFmt
      numberOfMeetingRooms
      numberOfMeetingRoomsFmt
      rfpUrl
      totalArea
      totalAreaFmt
      totalExhibitSpaceArea
      totalExhibitSpaceAreaFmt
      totalMeetingRoomsArea
      totalMeetingRoomsAreaFmt
      meetingRooms {
        _id
        area
        areaFmt
        banquet
        boothEightByTen
        boothTenByTen
        configurationCapacities {
          _id
          banquet
          booth10x10
          booth8x10
          classroom
          conference
          hollowSquare
          reception
          tabletopBooths
          theater
          uShaped
        }
        capacityBanquet
        capacityConference
        capacityHollowSquare
        capacityReception
        capacitySchoolRoom
        capacityTheatre
        capacityUShaped
        ceilingHeight
        ceilingHeightFmt
        classroom
        conference
        desc
        floorId
        floorIdDesc
        floorName
        hollowSquare
        includeInTtlArea
        isForExhibitUse
        isOffSite
        length
        lengthFmt
        locationDesc
        name
        name_noTx: name
        reception
        roomId
        roomIdDesc
        roomRank
        sqrFootage
        sqrFootageFmt
        tabletopBooths
        theater
        typeDesc
        uShaped
        width
        widthFmt
      }
    }
  }
`;

const RestaurantsFragment = gql`
  fragment RestaurantsFragment on Hotel {
    restaurantOverview {
      restaurants(enhance: true, filter: { onSite: true }) {
        id
        awards {
          name
        }
        contactDetails {
          information
          type
        }
        images {
          altText
          url
        }
        expandedImage: image(variant: diningExpanded) {
          altText
          url
        }
        halfAndHalfImage: image(variant: halfAndHalf) {
          altText
          url
        }
        overviewImage: image(variant: diningOverview) {
          altText
          variants(filter: { size: sm }) {
            size
            url
          }
        }
        attire
        averagePrice
        breakfast
        coverCharge
        description
        dinner
        headline
        link {
          _id
          adaDescription
          isNewWindow
          label
          url
        }
        lunch
        name_noTx: name
        onSite
        operatingHours {
          _id
          headline
          monday
          tuesday
          wednesday
          thursday
          friday
          saturday
          sunday
        }
        remarks
      }
      desc
      headline
    }
  }
`;

const AmenitiesFeaturesFragment = gql`
  fragment AmenitiesFeaturesFragment on Hotel {
    hotelAmenities: amenities(filter: { groups_includes: [hotel] }) {
      id
      name
    }
    accessibleFeatures: features(
      filter: {
        type_in: [
          adaaccessible
          allamenities
          allhotelsafety
          allhotelservices
          allhotelattributes
        ]
      }
    ) {
      name
    }
    nonAccessibleFeatures: features(filter: { type: non_accessible }) {
      name
    }
  }
`;

const HotelPoliciesFragment = gql`
  fragment HotelPoliciesFragment on Hotel {
    creditCardTypes {
      name
    }

    pets {
      description
      kennelServiceDesc
      kennelServiceOffered
      otherServiceDesc
      otherServiceOffered
      petCharge
      petChargeFmt
      petChargeRefundable
      petMaxSize
      petMaxWeightFmt
      petsAllowed
      servicePetsAllowed
      servicePetsDesc
    }

    overview {
      nor1Enabled
      resortFeeDisclosureDesc
    }

    policy {
      wifi {
        freeWlanMsg
        wlanInRoom
        wlanInRoomDesc
        wlanInRoomDisclaimer
        wlanInRoomFee
        wlanInRoomFeeFmt
        wlanPublic
        wlanPublicFee
        wlanPublicFeeFmt
      }

      smoking {
        nonSmokingDesc
        smokingDesc
      }
      fees {
        desc
      }

      additionalPolicies(filter: { headline: "Face Coverings" }) {
        headline
        type
        link {
          adaDescription
          isNewWindow
          label
          url
        }
        terms {
          heading {
            value
          }
          description {
            value_noTx: value
            value
          }
        }
      }
    }

    policyOptions(filter: { name: cancellationPolicy }) {
      name
      value
      label
      options {
        name
        value
        label
      }
    }

    safetyAndSecurity {
      smokingPolicyDesc
    }

    foodAndBeverage {
      hasFreeBreakfast
      freeBreakfastMsg
    }
    internetAccess {
      freeWlanMsg
      hasWlanInRoom
      wlanInRoomDesc
      wlanInRoomFee
      wlanInRoomFeeFmt
    }

    parking {
      hasFreeParking
      hasSelfParking
      hasValetParking
      hasOnSiteParking
      hasInOutParkingPrivilege
      inOutPrivileges
      hasSecuredParking
      hasParkingFacility
      hasCoveredParking
      chargedSelf
      chargedSelfAccess
      valetDescs
      selfDesc
      selfParkingComments

      valetDesc
      valetOnlyParking
      valetParkingComments
      selfParkingCharge {
        chargeAmount
        chargeAmountFmt
        parkingComments
      }

      valetParkingCharge {
        chargeAmount
        chargeAmountFmt
        chargeAmount
      }
      chargedValet

      hasAccessibleParking
      accessibleParkingInSelfParkingFacility
      otherParkingInfo
      otherParkingInformation
      selfDesc
      selfDescs
    }
  }
`;

export const getDxGqlInfoQuery = gql`
  query brand_hotel_shopAvailOptions($ctyhocn: String!, $language: String!) {
    hotel(ctyhocn: $ctyhocn, language: $language, enhance: false) {
      ...MeetingsFragment
      ...PropertyAlertsFragment
      ...RestaurantsFragment
      ...HotelPoliciesFragment
      ...AmenitiesFeaturesFragment
      shopAvailOptions {
        maxNumOccupants
        ageBasedPricing
        adultAge
        maxNumChildren
      }
      associatedHotels
      name
      brandCode
      totalRooms
      brand {
        name
        formalName
      }
      facilityOverview {
        headline
        shortDesc
        creditCardOnly
        isWorldwideResort
        allowAdultsOnly
      }
      images {
        master {
          url
        }
      }
      address {
        addressStacked: addressFmt(format: "stacked")
        addressLine1
        city
        city_noTx: city
        country
        countryName
        countryName_noTx: countryName
        state
        stateName
        stateName_noTx: stateName
      }
      registration {
        checkinTime
        checkinTimeFmt
        checkoutTime
        checkoutTimeFmt
        earlyCheckinText
        earlyDepartureFee
        earlyDepartureFeeFmt
        earlyDepartureFeeType
        earlyDepartureText
        eCheckin
        lateCheckoutFee
        lateCheckoutFeeFmt
        lateCheckoutText
        minAgeToRegister
      }
      contactInfo {
        emailAddress1
        faxNumber
        phoneNumber
        networkDisclaimer
      }
      localization {
        coordinate {
          latitude
          longitude
        }
        currency {
          description
        }
        gmtHours
        gmtHoursFmt
        timeZone
        currencyCode
      }
      display {
        open
        preOpenMsg
        resEnabled
        openDateFmt(format: "long")
        resEnabledDateFmt(format: "long")
      }
      tripAdvisorLocationSummary {
        rating
        ratingImageUrl
        numReviews
        reviews {
          id
          lang
          locationId
          publishedDate
          rating
          helpfulVotes
          ratingImageUrl
          url
          text
          travelDate
          user {
            username
          }
          title
        }
      }
      resEnabledDateFmt(format: "YYYY-MM-DD")
    }
    brand(brandCode: "HI", language: "en") {
      footer {
        sections {
          links {
            url
            label
          }
        }
      }
    }
  }
  ${HotelPoliciesFragment}
  ${AmenitiesFeaturesFragment}
  ${PropertyAlertsFragment}
  ${MeetingsFragment}
  ${RestaurantsFragment}
`;

export const getPropertyAlerts = gql`
  query propertyAlerts($ctyhocn: String!, $language: String!) {
    hotel(ctyhocn: $ctyhocn, language: $language, enhance: false) {
      ...PropertyAlertsFragment
    }
  }
  ${PropertyAlertsFragment}
`;

export const getFrenchTravelAndTourismMediatorLegaleseQuery = gql`
  query frenchLegalese($ctyhocn: String!, $language: String!) {
    hotel(ctyhocn: $ctyhocn, language: $language, enhance: false) {
      policy {
        additionalPolicies(filter: { subType: "french-tourism-mediator-fr" }) {
          headline
          type
          link {
            adaDescription
            isNewWindow
            label
            url
          }
          terms {
            heading {
              value
            }
            description {
              value_noTx: value
              value
            }
          }
        }
      }
    }
  }
`;

export const getAssociatedHotelInfo = gql`
  query associatedHotelInfo($ctyhocn: String!, $language: String!) {
    hotel(ctyhocn: $ctyhocn, language: $language, enhance: false) {
      name
      ctyhocn
      facilityOverview {
        headline
        shortDesc
        creditCardOnly
      }
      display {
        open
        preOpenMsg
        resEnabled
        openDateFmt(format: "long")
        resEnabledDateFmt(format: "long")
      }
      ...PropertyAlertsFragment
      ...MeetingsFragment
      ...RestaurantsFragment
      ...HotelPoliciesFragment
      ...AmenitiesFeaturesFragment
    }
  }
  ${HotelPoliciesFragment}
  ${AmenitiesFeaturesFragment}
  ${PropertyAlertsFragment}
  ${MeetingsFragment}
  ${RestaurantsFragment}
`;

const staticOffersListingFragment = gql`
  fragment OfferListing on StaticOfferOptions {
    offers {
      id
      headline
      shortDescription
      longDescription
      name
      type
      attributes {
        name
        id
      }
      externalLink {
        adaDescription
        isNewWindow
        label
        url
      }
      images(imageVariant: offerListing) {
        ogImage: url(width: 400, height: 400)
        altText
        variants {
          url
          size
        }
      }
      bookStart
      bookEnd
      stayStart
      stayEnd
      policy {
        _id
        name
        terms {
          _id
          description {
            value
          }
        }
      }
    }
  }
`;

export const getStaticOffersByCtyhocn = gql`
  ${staticOffersListingFragment}
  query hotelOfferListing($language: String!, $ctyhocn: String!) {
    hotelStaticOfferOptions(language: $language, ctyhocn: $ctyhocn) {
      ...OfferListing
    }
  }
`;
