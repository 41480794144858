import React from 'react';
export function Safe({
  className,
  fillColor,
}: {
  className?: string;
  fillColor?: string;
}) {
  return (
    <svg className={className} viewBox="0 0 24 24" style={{ fill: fillColor }}>
      <g data-name="Bounding Box">
        <path style={{ fill: 'none' }} d="M0,0V24H24V0Z" />
      </g>
      <g data-name="Icons Outlined">
        <g>
          <path d="M14.5,9.125a2.8383,2.8383,0,0,0-1.3759.3666.142.142,0,0,0-.0982.0534,2.8516,2.8516,0,0,0,0,4.91.3509.3509,0,0,0,.0376.0333.3435.3435,0,0,0,.0461.0122,2.8372,2.8372,0,0,0,1.39.3745,2.875,2.875,0,0,0,0-5.75ZM12.375,12a2.1117,2.1117,0,0,1,.7593-1.6144L14.0665,12l-.9322,1.6144A2.1114,2.1114,0,0,1,12.375,12ZM14.5,14.125a2.102,2.102,0,0,1-.7167-.1331l.9336-1.6169h1.87A2.1247,2.1247,0,0,1,14.5,14.125Zm.2169-2.5-.9336-1.6169A2.1016,2.1016,0,0,1,14.5,9.875a2.1247,2.1247,0,0,1,2.0871,1.75Z" />
          <path d="M19,5.625H6A1.377,1.377,0,0,0,4.625,7V8.625H4A.3751.3751,0,0,0,3.625,9v2A.3751.3751,0,0,0,4,11.375h.625v1.25H4A.3751.3751,0,0,0,3.625,13v2A.3751.3751,0,0,0,4,15.375h.625V17A1.377,1.377,0,0,0,6,18.375H19A1.377,1.377,0,0,0,20.375,17V7A1.377,1.377,0,0,0,19,5.625Zm-14.625,5V9.375h2.25v1.25H4.375Zm.625,4H4.375v-1.25h2.25v1.25ZM19.625,17a.6256.6256,0,0,1-.625.625H6A.6256.6256,0,0,1,5.375,17V15.375H7A.3751.3751,0,0,0,7.375,15V13A.3751.3751,0,0,0,7,12.625H5.375v-1.25H7A.3751.3751,0,0,0,7.375,11V9A.3751.3751,0,0,0,7,8.625H5.375V7A.6256.6256,0,0,1,6,6.375H19A.6256.6256,0,0,1,19.625,7Z" />
        </g>
      </g>
    </svg>
  );
}
