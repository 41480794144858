import dynamic from 'next/dynamic';

const HotelLogo = dynamic<{ className: string }>(() =>
  import('./lonhitw/hotel-logo').then((i) => i.HotelLogo)
);

export const hotelLogoHeader = (
  <HotelLogo className="fill-white text-current antialiased w-36" />
);

export const hotelLogoFooter = (
  <HotelLogo className="fill-black text-current antialiased w-32 text-[#C07759]" />
);
