import { WACStyles } from './interfaces';

export const defaultStyles: WACStyles = {
  headerCopyAlign: 'center',
  headerCopyColor: '#000',
  headerTitleAlign: 'center',
  headerTitleColor: '#000',
  subheadingAlign: 'center',
  subheadingColor: '#000',
  mainTextColor: '#000',
  thirdPanel: {
    active: {
      backgroundColor: '#000',
      textColor: '#f1f1f1',
    },
    inactive: {
      backgroundColor: '#000',
      textColor: '#f1f1f1',
    },
  },
  progress: {
    activeColor: '#212121',
    inactiveColor: '#828282',
  },
  background: {
    primary: '#F2F2F2',
    secondary: '#E0E0E0',
  },
  radio: {
    checked: '#000',
    unchecked: '#828282',
    disabled: '#BDBDBD',
  },
  calendar: {
    cells: {
      disabled: {
        cornerFlagColor: 'transparent',
        backgroundColor: '#E0E0E0',
        textColor: '#BDBDBD',
      },
      full: {
        cornerFlagColor: 'transparent',
        backgroundColor: '#E0E0E0',
        textColor: '#BDBDBD',
      },
      partial: {
        cornerFlagColor: '#828282',
        backgroundColor: '#CFCFCE',
        textColor: '#333333',
      },
      free: {
        cornerFlagColor: 'transparent',
        backgroundColor: '#CFCFCE',
        textColor: '#333333',
      },
      selected: {
        cornerFlagColor: 'transparent',
        backgroundColor: '#333333',
        textColor: '#FFFFFF',
      },
    },
  },
};
