import dynamic from 'next/dynamic';

const HotelLogo = dynamic<{ className?: string; fillColor?: string }>(() =>
  import('./dpsbahi/hotel-logo').then((i) => i.HotelLogo)
);

export const hotelLogoHeader = (
  <HotelLogo fillColor="#104C97" className="antialiased w-30 md:w-32 h-20" />
);

export const hotelLogoFooter = (
  <HotelLogo fillColor="#104C97" className="antialiased w-32 h-20" />
);
