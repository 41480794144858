const HonorsLogo = ({
  className,
  fillColor,
}: {
  className?: string;
  fillColor?: string;
}) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 160 50"
    >
      <path
        fill={fillColor || '#231F20'}
        d="M0 0v39.2h17.7v-1.6h-16v-36h95.6v36H81.2v1.6H99V0H0z"
      />
      <path
        fill={fillColor || '#231F20'}
        d="M26.7 42.2V39h-4.1v3.2H21v-7.6h1.5v3.1h4v-3.1H28v7.6zM39 38.4a4 4 0 1 1-4-4 4 4 0 0 1 4 4zm-6.6 0a2.6 2.6 0 1 0 2.6-2.6 2.6 2.6 0 0 0-2.6 2.6zm14.9 3.8-4-5.3v5.3h-1.4v-7.6h1.5l4 5.3v-5.3h1.4v7.6zm12.4-3.8a4 4 0 1 1-4-4 4 4 0 0 1 4 4zm-6.7 0a2.6 2.6 0 1 0 2.6-2.6 2.6 2.6 0 0 0-2.6 2.6zm12.9-3.8c2.3 0 3.3.7 3.3 2.5a2 2 0 0 1-1.7 2.3l2 2.8h-1.7L66 39.6h-2v2.6h-1.5v-7.6zM64 38.5h2c1.3 0 1.8-.4 1.8-1.3s-.5-1.4-1.8-1.4h-2zm13.8-3.2-.6 1a6 6 0 0 0-2.6-.6c-.7 0-1.3.2-1.3.9 0 .4.2.6 1.6 1 1.9.5 3.1.8 3.1 2.4s-1.2 2.3-3 2.3a6.5 6.5 0 0 1-3.6-1l.6-1a6.2 6.2 0 0 0 3 .8c.8 0 1.5-.3 1.5-1s-.5-.8-2-1.2c-1.8-.4-2.8-.8-2.8-2.1 0-1.6 1.4-2.3 3-2.3a6.1 6.1 0 0 1 3.1.8zM26.4 9.6v17.5l1.4.2v1H21v-1l1.4-.2v-8h-8.6v8l1.3.2v1H8.4v-1l1.4-.2V9.6l-1.4-.1v-1h6.7v1l-1.3.1v7.5h8.6V9.6L21 9.5v-1h6.8v1l-1.4.1zm9.3 17.7 1.3.1v1h-6.3v-1l1.3-.1v-12l-1.3-.2v-.9h5v13zm4 .2 1.2-.2V9.5l-1.2-.2v-.8h5v18.8l1.2.2v.9h-6.2v-1zm13.8-13.3h3.3v1.3h-3.3V24c0 2.2.5 3 2.4 3a8.2 8.2 0 0 0 2-.2v1a14 14 0 0 1-4.6.9c-2.8 0-3.5-1.3-3.5-3.8v-9.3h-2.6v-1.3h2.7v-3l3.6-1zm20.1 7.2c0 4.4-3 7.4-7.6 7.4s-7.5-2-7.5-7.4c0-4.5 3-7.6 7.7-7.6s7.4 2.8 7.4 7.6m-4 0c0-4.5-1.4-6.5-3.5-6.5s-3.5 1.9-3.5 6.4 1.3 6.4 3.4 6.4c2.3 0 3.5-1.8 3.5-6.4m21.1 6.1v1h-6.3v-1l1.3-.1v-8c0-2.3-.8-3.3-2.6-3.3a6.3 6.3 0 0 0-3 1.2v10l1.3.3v.8H75v-.9l1.3-.1v-12l-1.3-.1v-1h5v2c1.5-1.2 3-2.2 5.1-2.2 2.8 0 4 1.6 4 4.8v8.5zM35.9 10.5a2 2 0 1 1-2-2 2 2 0 0 1 2 2"
      />
    </svg>
  );
};

export default HonorsLogo;
