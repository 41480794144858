import { CroppedImage, customLoader } from '@curated-property/utils';
import { MediaBlocksVideoInterface } from './interfaces';
import { getVideoSrc } from '@dx-ui/utilities-get-video-src';
import cx from 'classnames';

export function MediaBlocksVideo(props: MediaBlocksVideoInterface) {
  const videoSrc = getVideoSrc(props?.videoUrl || '', 'youtube', false, false);
  // if getVideoSrc includes /shorts/
  const isVertical = props?.videoUrl?.includes('/shorts/') ? true : false;

  return (
    <div
      className="relative flex h-full w-full items-center justify-center"
      style={{ aspectRatio: '16/9' }}
      data-testid="media-blocks-video"
    >
      <div className="flex size-full justify-center">
        <iframe
          className={cx({
            'aspect-[9/16]': isVertical,
            'aspect-[16/9]': !isVertical,
          })}
          src={videoSrc}
          allow="encrypted-media; autoplay"
          allowFullScreen
          title={'hello'}
          sandbox="allow-same-origin allow-scripts allow-popups allow-forms allow-popups-to-escape-sandbox"
        />
      </div>
    </div>
  );
}
