import dynamic from 'next/dynamic';

const HotelLogo = dynamic<{ className: string; fillColor?: string }>(() =>
  import('./psplqqq/hotel-logo').then((i) => i.HotelLogo)
);
const HotelLogoWithCurio = dynamic<{ className: string; fillColor?: string }>(
  () =>
    import('./psplqqq/hotel-logo-with-curio').then((i) => i.HotelLogoWithCurio)
);

export const hotelLogoHeader = (
  <HotelLogo fillColor="#000000" className="antialiased w-40 md:w-48 h-24" />
);

export const hotelLogoFooter = (
  <HotelLogoWithCurio
    fillColor="#ffffff"
    className="antialiased w-32 md:w-40 h-auto"
  />
);
