import React from 'react';

export function VolumeControl({ className }: { className?: string }) {
  return (
    <svg viewBox="0 0 28 21" version="1.1" className={className}>
      <g
        id="Home-Page"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g transform="translate(-1615.000000, -398.000000)" fill="currentColor">
          <g transform="translate(1554.000000, 387.000000)">
            <g transform="translate(61.000000, 11.000000)">
              <path d="M1.47368421,13.6068197 L7.14,13.6068197 C7.30136842,13.6068197 7.45831579,13.6600697 7.58652632,13.7598197 L13.4002105,18.2643197 L13.4002105,2.26306968 L7.58652632,6.76831968 C7.45831579,6.86806968 7.30136842,6.92206968 7.14,6.92206968 L1.47368421,6.92206968 L1.47368421,13.6068197 Z M14.1370526,20.5278197 C13.9786316,20.5278197 13.8216842,20.4760697 13.6912632,20.3748197 L6.89168421,15.1068197 L0.736842105,15.1068197 C0.330105263,15.1068197 0,14.7708197 0,14.3568197 L0,6.17206968 C0,5.75731968 0.330105263,5.42206968 0.736842105,5.42206968 L6.89168421,5.42206968 L13.6905263,0.152569681 C13.9145263,-0.019180319 14.2144211,-0.048430319 14.4642105,0.077569681 C14.7154737,0.204319681 14.8738947,0.464569681 14.8738947,0.749569681 L14.8738947,19.7778197 C14.8738947,20.0628197 14.7154737,20.3230697 14.4642105,20.4498197 C14.3603158,20.5023197 14.2490526,20.5278197 14.1370526,20.5278197 L14.1370526,20.5278197 Z"></path>
              <g transform="translate(16.532526, 4.871570)">
                <path d="M10.2273684,5.7675 L0.368421053,5.7675 C0.164315789,5.7675 6.21724894e-14,5.5995 6.21724894e-14,5.3925 C6.21724894e-14,5.18475 0.164315789,5.0175 0.368421053,5.0175 L10.2273684,5.0175 C10.4307368,5.0175 10.5957895,5.18475 10.5957895,5.3925 C10.5957895,5.5995 10.4307368,5.7675 10.2273684,5.7675"></path>
                <path d="M5.29715789,10.78425 C5.09378947,10.78425 4.92873684,10.61625 4.92873684,10.40925 L4.92873684,0.375 C4.92873684,0.168 5.09378947,-4.3298698e-14 5.29715789,-4.3298698e-14 C5.50126316,-4.3298698e-14 5.66557895,0.168 5.66557895,0.375 L5.66557895,10.40925 C5.66557895,10.61625 5.50126316,10.78425 5.29715789,10.78425"></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
