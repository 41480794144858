import React from 'react';
import cx from 'classnames';
import { CroppedImage, customLoader } from '@curated-property/utils';
import { ImagesInput, ReturnedImages } from './media-and-copy-carousel.types';

export function CroppedImages({
  repeater,
  currentIndex,
  slideAnimation,
  navAnimationDir,
  nextImage,
  sizes,
  nextTimeout,
  getNextIndex,
  getPrevIndex,
  contentBackgroundPosition,
}: ImagesInput): ReturnedImages {
  const imagePosition: string | undefined =
    repeater?.[currentIndex]?.imagePositioning;
  const getPrevSlideInd = () => {
    const ind: number =
      slideAnimation === 'slide-ltr' && navAnimationDir
        ? getNextIndex()
        : getPrevIndex();
    return ind;
  };

  const slideStyles = {
    '-translate-x-full':
      nextTimeout &&
      slideAnimation !== 'fade' &&
      slideAnimation !== 'slide-ltr' &&
      (navAnimationDir || slideAnimation === 'slide-rtl'),
    'translate-x-full':
      nextTimeout &&
      slideAnimation !== 'fade' &&
      slideAnimation !== 'slide-rtl' &&
      (!navAnimationDir || slideAnimation === 'slide-ltr'),
  };

  const croppedImageDesktop =
    slideAnimation !== 'fade' ? (
      <div className="flex flex-row h-full w-full justify-center relative overflow-hidden">
        <div
          className={cx(
            'absolute h-full w-full transform transition-all duration-500 ease-in-out',
            {
              'translate-x-0': nextTimeout && slideAnimation !== 'slide-rtl',
              '-translate-x-full':
                !nextTimeout || slideAnimation === 'slide-rtl',
            }
          )}
        >
          <CroppedImage
            src={repeater?.[getPrevSlideInd()]?.image?.sourceUrl || ''}
            loader={() => {
              return customLoader({
                src: repeater?.[getPrevSlideInd()]?.image?.sourceUrl || '',
                crop: repeater?.[getPrevSlideInd()]?.enableCropping || false,
                cropType: repeater?.[getPrevSlideInd()]?.cropType || '',
                cropHeight: repeater?.[getPrevSlideInd()]?.cropHeight || '',
                cropWidth: repeater?.[getPrevSlideInd()]?.cropWidth || '',
                xPosition: repeater?.[getPrevSlideInd()]?.xPosition || '',
                xPositionAdvanced:
                  repeater?.[getPrevSlideInd()]?.xPositionAdvanced || '',
                yPosition: repeater?.[getPrevSlideInd()]?.yPosition || '',
                yPositionAdvanced:
                  repeater?.[getPrevSlideInd()]?.yPositionAdvanced || '',
              });
            }}
            alt={`${
              repeater?.[getPrevSlideInd()]?.image?.altText || ''
            }-previous-transition`}
            layout="fill"
            objectFit="cover"
            objectPosition={
              imagePosition || contentBackgroundPosition || undefined
            }
          />
        </div>
        <div
          className={cx(
            'absolute h-full w-full order-3 transform transition-all duration-500 ease-in-out',
            {
              'translate-x-0':
                (slideAnimation === 'slide-rtl' && nextTimeout) ||
                (nextTimeout &&
                  slideAnimation !== 'slide-ltr' &&
                  navAnimationDir),
              'translate-x-full':
                !nextTimeout ||
                (slideAnimation !== 'slide-rtl' && !navAnimationDir) ||
                slideAnimation === 'slide-ltr',
            }
          )}
        >
          <CroppedImage
            src={repeater?.[nextImage.ind]?.image?.sourceUrl || ''}
            loader={() => {
              return customLoader({
                src: repeater?.[nextImage.ind]?.image?.sourceUrl || '',
                crop: repeater?.[nextImage.ind]?.enableCropping || false,
                cropType: repeater?.[nextImage.ind]?.cropType || '',
                cropHeight: repeater?.[nextImage.ind]?.cropHeight || '',
                cropWidth: repeater?.[nextImage.ind]?.cropWidth || '',
                xPosition: repeater?.[nextImage.ind]?.xPosition || '',
                xPositionAdvanced:
                  repeater?.[nextImage.ind]?.xPositionAdvanced || '',
                yPosition: repeater?.[nextImage.ind]?.yPosition || '',
                yPositionAdvanced:
                  repeater?.[nextImage.ind]?.yPositionAdvanced || '',
              });
            }}
            alt={`${
              repeater?.[nextImage.ind]?.image?.altText || ''
            }-next-transition`}
            layout="fill"
            objectFit="cover"
            objectPosition={
              imagePosition || contentBackgroundPosition || undefined
            }
          />
        </div>
        <div
          data-testid="image-container"
          className={cx(
            'w-full order-2 transform transition-all duration-500 ease-in-out',
            {
              'transition-none': !nextTimeout,
            },
            slideStyles
          )}
        >
          <CroppedImage
            data-testid="currentImg"
            src={repeater?.[currentIndex]?.image?.sourceUrl || ''}
            loader={() => {
              return customLoader({
                src: repeater?.[currentIndex]?.image?.sourceUrl || '',
                crop: repeater?.[currentIndex]?.enableCropping || false,
                cropType: repeater?.[currentIndex]?.cropType || '',
                cropHeight: repeater?.[currentIndex]?.cropHeight || '',
                cropWidth: repeater?.[currentIndex]?.cropWidth || '',
                xPosition: repeater?.[currentIndex]?.xPosition || '',
                xPositionAdvanced:
                  repeater?.[currentIndex]?.xPositionAdvanced || '',
                yPosition: repeater?.[currentIndex]?.yPosition || '',
                yPositionAdvanced:
                  repeater?.[currentIndex]?.yPositionAdvanced || '',
              });
            }}
            alt={repeater?.[currentIndex]?.image?.altText || ''}
            layout="fill"
            objectFit="cover"
            objectPosition={
              imagePosition || contentBackgroundPosition || undefined
            }
          />
        </div>
      </div>
    ) : (
      <div>
        <CroppedImage
          src={repeater?.[nextImage?.ind]?.image?.sourceUrl || ''}
          loader={() => {
            return customLoader({
              src: repeater?.[nextImage?.ind]?.image?.sourceUrl || '',
              crop: repeater?.[nextImage?.ind]?.enableCropping || false,
              cropType: repeater?.[nextImage?.ind]?.cropType || '',
              cropHeight: repeater?.[nextImage?.ind]?.cropHeight || '',
              cropWidth: repeater?.[nextImage?.ind]?.cropWidth || '',
              xPosition: repeater?.[nextImage?.ind]?.xPosition || '',
              xPositionAdvanced:
                repeater?.[nextImage?.ind]?.xPositionAdvanced || '',
              yPosition: repeater?.[nextImage?.ind]?.yPosition || '',
              yPositionAdvanced:
                repeater?.[nextImage?.ind]?.yPositionAdvanced || '',
            });
          }}
          alt={`${repeater?.[nextImage?.ind]?.image?.altText || ''}-transition`}
          layout="fill"
          objectFit="cover"
          objectPosition={
            imagePosition || contentBackgroundPosition || undefined
          }
          className="transform transition-all duration-500 ease-in-out"
        />
        <CroppedImage
          data-testid="currentImg"
          src={repeater?.[currentIndex]?.image?.sourceUrl || ''}
          loader={() => {
            return customLoader({
              src: repeater?.[currentIndex]?.image?.sourceUrl || '',
              crop: repeater?.[currentIndex]?.enableCropping || false,
              cropType: repeater?.[currentIndex]?.cropType || '',
              cropHeight: repeater?.[currentIndex]?.cropHeight || '',
              cropWidth: repeater?.[currentIndex]?.cropWidth || '',
              xPosition: repeater?.[currentIndex]?.xPosition || '',
              xPositionAdvanced:
                repeater?.[currentIndex]?.xPositionAdvanced || '',
              yPosition: repeater?.[currentIndex]?.yPosition || '',
              yPositionAdvanced:
                repeater?.[currentIndex]?.yPositionAdvanced || '',
            });
          }}
          alt={repeater?.[currentIndex]?.image?.altText || ''}
          layout="fill"
          objectFit="cover"
          objectPosition={
            imagePosition || contentBackgroundPosition || undefined
          }
          className={cx(
            'absolute transform transition-all duration-500 ease-in-out',
            {
              'opacity-0': nextTimeout ? true : false,
              'opacity-100': nextTimeout ? false : true,
            }
          )}
        />
      </div>
    );

  const croppedImageMobile =
    slideAnimation !== 'fade' ? (
      <div className="flex flex-row h-full w-full justify-center relative overflow-hidden">
        <div
          className={cx(
            'absolute h-full w-full transform transition-all duration-500 ease-in-out',
            {
              'translate-x-0': nextTimeout && slideAnimation !== 'slide-rtl',
              '-translate-x-full':
                !nextTimeout || slideAnimation === 'slide-rtl',
              'transition-none': !nextTimeout,
            }
          )}
        >
          <CroppedImage
            src={repeater?.[getPrevSlideInd()]?.image?.sourceUrl || ''}
            alt={`${
              repeater?.[getPrevSlideInd()]?.image?.altText || ''
            }-previous-transition`}
            width={sizes?.width}
            height={sizes?.height}
            layout="responsive"
            objectFit="cover"
            objectPosition={undefined}
          />
        </div>
        <div
          className={cx(
            'absolute h-full w-full order-3 transform transition-all duration-500 ease-in-out',
            {
              'translate-x-0':
                (slideAnimation === 'slide-rtl' && nextTimeout) ||
                (nextTimeout && slideAnimation !== 'slide-ltr'),
              'translate-x-full':
                !nextTimeout ||
                (slideAnimation !== 'slide-rtl' && !navAnimationDir) ||
                slideAnimation === 'slide-ltr',
              'transition-none': !nextTimeout,
            }
          )}
        >
          <CroppedImage
            src={repeater?.[nextImage?.ind]?.image?.sourceUrl || ''}
            alt={`${
              repeater?.[nextImage?.ind]?.image?.altText || ''
            }-next-transition`}
            width={sizes?.width}
            height={sizes?.height}
            layout="responsive"
            objectFit="cover"
            objectPosition={undefined}
          />
        </div>
        <div
          className={cx(
            'w-full order-2 transform transition-all duration-500 ease-in-out',
            {
              'transition-none': !nextTimeout,
            },
            slideStyles
          )}
        >
          <CroppedImage
            data-testid="currentImg"
            src={repeater?.[currentIndex]?.image?.sourceUrl || ''}
            alt={repeater?.[currentIndex]?.image?.altText || ''}
            width={sizes?.width}
            height={sizes?.height}
            layout="responsive"
            objectFit="cover"
            objectPosition={undefined}
          />
        </div>
      </div>
    ) : (
      <div>
        <CroppedImage
          src={repeater?.[nextImage?.ind]?.image?.sourceUrl || ''}
          alt={`${repeater?.[nextImage?.ind]?.image?.altText || ''}-transition`}
          width={sizes?.width}
          height={sizes?.height}
          layout="fill"
          objectFit="cover"
          objectPosition={undefined}
          className="transform transition-all duration-500 ease-in-out"
        />
        <CroppedImage
          data-testid="currentImg"
          src={repeater?.[currentIndex]?.image?.sourceUrl || ''}
          alt={repeater?.[currentIndex]?.image?.altText || ''}
          width={sizes?.width}
          height={sizes?.height}
          layout="responsive"
          objectFit="cover"
          objectPosition={undefined}
          className={cx(
            'absolute transform transition-all duration-500 ease-in-out',
            {
              'opacity-0': nextTimeout ? true : false,
              'opacity-100': nextTimeout ? false : true,
            }
          )}
        />
      </div>
    );

  return { desktop: croppedImageDesktop, mobile: croppedImageMobile };
}
