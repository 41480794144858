import dynamic from 'next/dynamic';

const HotelLogo = dynamic<{ className: string; fillColor?: string }>(() =>
  import('./sbazdqq/hotel-logo').then((i) => i.HotelLogo)
);

export const hotelLogoHeader = (
  <HotelLogo className="antialiased w-36 sm:w-40 md:w-48 pr-4" />
);

export const hotelLogoFooter = (
  <HotelLogo className="antialiased w-32" fillColor="#010202" />
);
