import { CroppedImage, customLoader } from '@curated-property/utils';
import { MediaBlocksThreeTwoLeftOneRightInterface } from './interfaces';

export function MediaBlocksThreeTwoLeftOneRight(
  props: MediaBlocksThreeTwoLeftOneRightInterface
) {
  return (
    <div
      className="h-full w-full grid grid-cols-2"
      data-testid="media-blocks-three-two-left-one-right"
    >
      <div className="grid grid-row-2 bg-green-500">
        <div className="bg-red-500 flex justify-center items-center overflow-hidden relative">
          <CroppedImage
            layout="fill"
            src={props?.imageTopLeft?.sourceUrl}
            alt={props?.imageTopLeft?.altText}
            loader={() => {
              return customLoader({
                width: props?.maxImageWidth || undefined,
                src: props?.imageTopLeft?.sourceUrl,
                crop: props?.imageTopLeftCrop?.imageCrop11AddCrop && true,
                cropType:
                  props?.imageTopLeftCrop?.imageCrop11AddCrop?.[0]?.cropType,
                cropHeight:
                  props?.imageTopLeftCrop?.imageCrop11AddCrop?.[0]?.cropHeight,
                cropWidth:
                  props?.imageTopLeftCrop?.imageCrop11AddCrop?.[0]?.cropWidth,
                xPosition:
                  props?.imageTopLeftCrop?.imageCrop11AddCrop?.[0]?.xPosition,
                xPositionAdvanced:
                  props?.imageTopLeftCrop?.imageCrop11AddCrop?.[0]
                    ?.xPositionAdvanced,
                yPosition:
                  props?.imageTopLeftCrop?.imageCrop11AddCrop?.[0]?.yPosition,
                yPositionAdvanced:
                  props?.imageTopLeftCrop?.imageCrop11AddCrop?.[0]
                    ?.yPositionAdvanced,
                autoPosition:
                  props?.imageTopLeftCrop?.imageCrop11AddCrop?.[0]
                    ?.autoPosition,
              });
            }}
            objectFit="cover"
          />
        </div>
        <div className="bg-yellow-500 flex justify-center items-center overflow-hidden relative">
          <CroppedImage
            layout="fill"
            src={props?.imageBottomLeft?.sourceUrl}
            alt={props?.imageBottomLeft?.altText}
            loader={() => {
              return customLoader({
                width: props?.maxImageWidth || undefined,
                src: props?.imageBottomLeft?.sourceUrl,
                crop: props?.imageBottomLeftCrop?.imageCrop11AddCrop && true,
                cropType:
                  props?.imageBottomLeftCrop?.imageCrop11AddCrop?.[0]?.cropType,
                cropHeight:
                  props?.imageBottomLeftCrop?.imageCrop11AddCrop?.[0]
                    ?.cropHeight,
                cropWidth:
                  props?.imageBottomLeftCrop?.imageCrop11AddCrop?.[0]
                    ?.cropWidth,
                xPosition:
                  props?.imageBottomLeftCrop?.imageCrop11AddCrop?.[0]
                    ?.xPosition,
                xPositionAdvanced:
                  props?.imageBottomLeftCrop?.imageCrop11AddCrop?.[0]
                    ?.xPositionAdvanced,
                yPosition:
                  props?.imageBottomLeftCrop?.imageCrop11AddCrop?.[0]
                    ?.yPosition,
                yPositionAdvanced:
                  props?.imageBottomLeftCrop?.imageCrop11AddCrop?.[0]
                    ?.yPositionAdvanced,
                autoPosition:
                  props?.imageBottomLeftCrop?.imageCrop11AddCrop?.[0]
                    ?.autoPosition,
              });
            }}
            objectFit="cover"
          />
        </div>
      </div>
      <div className="bg-blue-500 flex justify-center items-center overflow-hidden relative">
        <CroppedImage
          layout="fill"
          src={props?.imageRight?.sourceUrl}
          alt={props?.imageRight?.altText}
          loader={() => {
            return customLoader({
              width: props?.maxImageWidth || undefined,
              src: props?.imageRight?.sourceUrl,
              crop: props?.imageRightCrop?.imageCrop12AddCrop && true,
              cropType:
                props?.imageRightCrop?.imageCrop12AddCrop?.[0]?.cropType,
              cropHeight:
                props?.imageRightCrop?.imageCrop12AddCrop?.[0]?.cropHeight,
              cropWidth:
                props?.imageRightCrop?.imageCrop12AddCrop?.[0]?.cropWidth,
              xPosition:
                props?.imageRightCrop?.imageCrop12AddCrop?.[0]?.xPosition,
              xPositionAdvanced:
                props?.imageRightCrop?.imageCrop12AddCrop?.[0]
                  ?.xPositionAdvanced,
              yPosition:
                props?.imageRightCrop?.imageCrop12AddCrop?.[0]?.yPosition,
              yPositionAdvanced:
                props?.imageRightCrop?.imageCrop12AddCrop?.[0]
                  ?.yPositionAdvanced,
              autoPosition:
                props?.imageRightCrop?.imageCrop12AddCrop?.[0]?.autoPosition,
            });
          }}
          objectFit="cover"
        />
      </div>
    </div>
  );
}
