import React from 'react';

export function SettingSliders({
  className,
  fill,
}: {
  className?: string;
  fill?: string;
}) {
  return (
    <svg
      viewBox="0 0 16 14"
      className={className}
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill={fill}>
        <path d="M15 6.625H7C6.90054 6.625 6.80516 6.66451 6.73483 6.73483C6.66451 6.80516 6.625 6.90054 6.625 7C6.625 7.09946 6.66451 7.19484 6.73483 7.26517C6.80516 7.33549 6.90054 7.375 7 7.375H15C15.0995 7.375 15.1948 7.33549 15.2652 7.26517C15.3355 7.19484 15.375 7.09946 15.375 7C15.375 6.90054 15.3355 6.80516 15.2652 6.73483C15.1948 6.66451 15.0995 6.625 15 6.625Z" />
        <path d="M1 3.375H9.625V5C9.625 5.09946 9.66451 5.19484 9.73483 5.26517C9.80516 5.33549 9.90054 5.375 10 5.375C10.0995 5.375 10.1948 5.33549 10.2652 5.26517C10.3355 5.19484 10.375 5.09946 10.375 5V1C10.375 0.900544 10.3355 0.805161 10.2652 0.734835C10.1948 0.664509 10.0995 0.625 10 0.625C9.90054 0.625 9.80516 0.664509 9.73483 0.734835C9.66451 0.805161 9.625 0.900544 9.625 1V2.625H1C0.900544 2.625 0.805161 2.66451 0.734835 2.73484C0.664509 2.80516 0.625 2.90054 0.625 3C0.625 3.09946 0.664509 3.19484 0.734835 3.26517C0.805161 3.33549 0.900544 3.375 1 3.375Z" />
        <path d="M7 8.625C6.90055 8.62503 6.80518 8.66454 6.73486 8.73486C6.66454 8.80518 6.62503 8.90055 6.625 9V10.625H1C0.900544 10.625 0.805161 10.6645 0.734835 10.7348C0.664509 10.8052 0.625 10.9005 0.625 11C0.625 11.0995 0.664509 11.1948 0.734835 11.2652C0.805161 11.3355 0.900544 11.375 1 11.375H6.625V13C6.625 13.0995 6.66451 13.1948 6.73483 13.2652C6.80516 13.3355 6.90054 13.375 7 13.375C7.09946 13.375 7.19484 13.3355 7.26517 13.2652C7.33549 13.1948 7.375 13.0995 7.375 13V9C7.37497 8.90055 7.33546 8.80518 7.26514 8.73486C7.19482 8.66454 7.09945 8.62503 7 8.625Z" />
        <path d="M1 7.375H3.625V9C3.625 9.09946 3.66451 9.19484 3.73484 9.26517C3.80516 9.33549 3.90054 9.375 4 9.375C4.09946 9.375 4.19484 9.33549 4.26517 9.26517C4.33549 9.19484 4.375 9.09946 4.375 9V5C4.375 4.90054 4.33549 4.80516 4.26517 4.73483C4.19484 4.66451 4.09946 4.625 4 4.625C3.90054 4.625 3.80516 4.66451 3.73484 4.73483C3.66451 4.80516 3.625 4.90054 3.625 5V6.625H1C0.900544 6.625 0.805161 6.66451 0.734835 6.73483C0.664509 6.80516 0.625 6.90054 0.625 7C0.625 7.09946 0.664509 7.19484 0.734835 7.26517C0.805161 7.33549 0.900544 7.375 1 7.375Z" />
        <path d="M13 3.375H15C15.0995 3.375 15.1948 3.33549 15.2652 3.26517C15.3355 3.19484 15.375 3.09946 15.375 3C15.375 2.90054 15.3355 2.80516 15.2652 2.73484C15.1948 2.66451 15.0995 2.625 15 2.625H13C12.9005 2.625 12.8052 2.66451 12.7348 2.73484C12.6645 2.80516 12.625 2.90054 12.625 3C12.625 3.09946 12.6645 3.19484 12.7348 3.26517C12.8052 3.33549 12.9005 3.375 13 3.375Z" />
        <path d="M15 10.625H10C9.90054 10.625 9.80516 10.6645 9.73483 10.7348C9.66451 10.8052 9.625 10.9005 9.625 11C9.625 11.0995 9.66451 11.1948 9.73483 11.2652C9.80516 11.3355 9.90054 11.375 10 11.375H15C15.0995 11.375 15.1948 11.3355 15.2652 11.2652C15.3355 11.1948 15.375 11.0995 15.375 11C15.375 10.9005 15.3355 10.8052 15.2652 10.7348C15.1948 10.6645 15.0995 10.625 15 10.625Z" />
      </g>
    </svg>
  );
}
