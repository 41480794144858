import React from 'react';
const DoubleTreeCookieIcon = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg viewBox="0 0 44 44" focusable="false" {...props}>
      <g fill="none" fillRule="evenodd" stroke="currentColor">
        <path
          d="M23.937 9.205a5.336 5.336 0 0 1-.425-2.018A16.234 16.234 0 0 0 21.052 7C12.185 7 5 14.164 5 23s7.186 16 16.051 16c7.983 0 14.681-5.835 15.866-13.557a5.366 5.366 0 0 1-2.058-1.081 6.743 6.743 0 0 1-7.199-5.505 6.156 6.156 0 0 1-4.91-6.023c0-1.326.423-2.589 1.187-3.629z"
          stroke={'#666'}
          strokeWidth="2"
        />
        <g transform="translate(10 8)" fill={'#666'}>
          <ellipse cx="2.652" cy="10.816" rx="2.652" ry="2.644" />
          <ellipse cx="16.293" cy="19.883" rx="2.652" ry="2.644" />
          <ellipse cx="7.578" cy="1.133" rx="1.516" ry="1.133" />
          <ellipse cx="10.61" cy="12.327" rx="1.516" ry="1.133" />
          <ellipse cx="13.641" cy="28.194" rx="1.516" ry="1.133" />
          <ellipse cx="4.926" cy="23.661" rx="2.652" ry="2.644" />
          <ellipse cx="24.86" cy="9.965" rx="1.068" ry="1" />
          <ellipse cx="27.552" cy="4.596" rx="1.068" ry="1" />
          <ellipse cx="20.651" cy="1.561" rx="1.346" ry="1.342" />
        </g>
      </g>
    </svg>
  );
};

export default DoubleTreeCookieIcon;
