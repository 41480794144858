import dynamic from 'next/dynamic';

const HotelLogo = dynamic<{ className?: string; fillColor?: string }>(() =>
  import('./miambhf/hotel-logo').then((i) => i.HotelLogo)
);

export const hotelLogoHeader = (
  <HotelLogo className="antialiased w-24 md:w-28" fillColor="#104c97" />
);

export const hotelLogoFooter = (
  <HotelLogo className="antialiased w-32 mr-2.5 sm:mr-0" fillColor="#104c97" />
);
