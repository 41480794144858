import React, { useEffect, useState } from 'react';
import { useTranslation } from 'next-i18next';
import { AnchorLink, ACFAnchorProps } from './global/anchor-link';
import { GIS_Array, StyleObject } from './functions/global-instance-styles';
import cx from 'classnames';
import { Cancellation } from '@curated-property/icons';
import { HandleWYSIWYGContent } from './functions/helper';
import { appliedCloudinaryParams } from '@curated-property/utils';

export interface RetailRailProps {
  copy?: string;
  title?: string;
  buttons?: Array<ACFAnchorProps>;
  globalStyles?: StyleObject;
  instanceStyles?: StyleObject;
}

export function RetailRail({
  copy,
  title,
  buttons,
  instanceStyles,
  globalStyles,
}: RetailRailProps) {
  const inlineStyles = GIS_Array(globalStyles, instanceStyles);
  const [isToggled, setIsToggled] = useState<boolean>(true);
  const [lastFocused, setLastFocused] = useState<any | null>(null);

  const { t } = useTranslation();

  const handleClose = () => {
    setIsToggled(false);
    lastFocused?.focus();
  };

  useEffect(() => {
    function modalKeypress(e: KeyboardEvent) {
      const tar = e.target as HTMLElement;
      if (
        tar.tagName !== 'BODY' &&
        tar.innerText !== buttons?.[0]?.link?.title &&
        tar.innerText !== t('close')
      ) {
        setLastFocused(e.target);
      }
    }

    if (isToggled) window.addEventListener('keydown', modalKeypress);
    return () => {
      window.removeEventListener('keydown', modalKeypress);
    };
  }, [buttons, isToggled, t]);

  return (
    <div
      data-testid="retail-rail"
      data-element-id="retail-rail-component"
      className={cx(
        inlineStyles?.showHide && 'hidden',
        `${
          isToggled ? '' : 'opacity-0 translate-y-full'
        } z-[99] fixed flex justify-center w-full bottom-0 bg-primary transform transition duration-500 ease-in-out`
      )}
      style={{
        background: inlineStyles?.componentBackgroundImage
          ? `url('${appliedCloudinaryParams(
              inlineStyles?.componentBackgroundImage,
              inlineStyles?.componentBackgroundRepeat
            )}')`
          : inlineStyles.componentBackgroundColor,
        backgroundSize: inlineStyles?.componentBackgroundSize || 'cover',
        backgroundRepeat:
          inlineStyles?.componentBackgroundRepeat || 'no-repeat',
        backgroundPosition:
          inlineStyles?.componentBackgroundPosition || 'top left',
      }}
    >
      <div
        className={cx(
          `flex flex-col sm:flex-row justify-between sm:justif-between w-full text-bg h-auto`
        )}
        style={{
          background: inlineStyles?.contentBackgroundImage
            ? `url('${appliedCloudinaryParams(
                inlineStyles?.contentBackgroundImage,
                inlineStyles?.contentBackgroundRepeat
              )}')`
            : inlineStyles.contentBackgroundColor,
          backgroundSize: inlineStyles?.contentBackgroundSize || 'cover',
          backgroundRepeat:
            inlineStyles?.contentBackgroundRepeat || 'no-repeat',
          backgroundPosition:
            inlineStyles?.contentBackgroundPosition || 'top left',
        }}
      >
        <div
          className={cx(
            `${
              inlineStyles?.textAlignment === 'flex-end'
                ? 'text-right rtl:text-left'
                : inlineStyles?.textAlignment !== 'flex-start'
                ? `text-${inlineStyles?.textAlignment}`
                : 'text-left rtl:text-right'
            } overflow-hidden flex flex-col justify-center border-opacity-20 px-5 lg:px-20 w-full
          `,
            buttons?.length && 'sm:w-4/6'
          )}
        >
          <h3
            className="text-bg font-bold font-headline md:text-lg lg:text-xl w-full mb-3"
            style={{
              color: inlineStyles?.titleColor,
            }}
          >
            {title}
          </h3>
          <div
            className={cx(
              `mb-2 text-sm md:text-base leading-5 md:leading-normal`
            )}
            dangerouslySetInnerHTML={{
              __html: HandleWYSIWYGContent(copy, inlineStyles?.textColor),
            }}
            style={{
              color: inlineStyles?.textColor,
            }}
          ></div>
        </div>
        {buttons?.length && (
          <div className="flex items-center justify-center">
            <div
              className={cx(
                `${isToggled ? '' : 'hidden'} z-30 mt-0 mb-5 md:mb-0`
              )}
            >
              <AnchorLink
                url={buttons?.[0]?.link?.url}
                title={buttons?.[0]?.link?.title}
                target={buttons?.[0]?.link?.target}
                buttonStyle={buttons?.[0]?.buttonStyle || 'white-outline'}
              />
            </div>
          </div>
        )}

        <div
          className={cx(
            `${
              isToggled ? '' : 'hidden'
            } flex flex-grow-0 mt-2 mr-5 justify-end sm:justify-start order-first sm:order-last`
          )}
          style={{
            color: inlineStyles?.closeTextColour,
          }}
        >
          <button
            id="closeButton"
            aria-hidden={isToggled ? false : true}
            className={cx(
              `flex items-center justify-around text-sm font-extrabold w-20 h-5 md:w-16 hover:cursor-pointer z-30 fill-current`
            )}
            onClick={handleClose}
          >
            {t('close')}
            <Cancellation
              className="h-5"
              color={inlineStyles?.closeIconColour}
            />
          </button>
        </div>
      </div>
    </div>
  );
}
