import React, { useContext, useCallback } from 'react';
import { chunkArray, CroppedImage } from '@curated-property/utils';
import { useState, useEffect, useRef } from 'react';
import { Arrow } from '@curated-property/icon-list';
import { useTranslation } from 'next-i18next';
import cx from 'classnames';
import { adobeEventTracking } from '../global/adobe-analytics';
import { ModalContext } from '../global/generic-modal';
import { sanitize } from '@curated-property/utils';

export type ImageModalImage = {
  sourceUrl?: string;
  altText?: string;
  caption?: string;
  mediaDetails?: {
    width?: number;
    height?: number;
  };
};

export interface IimageModal {
  title?: string;
  images?: ImageModalImage[];
  active?: boolean;
  captionColour?: string;
  slideAnimation?: string;
  controlsIconColour?: string;
  controlsBackgroundColour?: string;
}

export function ImageModal({
  title,
  images,
  active,
  captionColour,
  slideAnimation,
  controlsIconColour,
  controlsBackgroundColour,
}: IimageModal) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [nextImage, setNextImage] = useState({
    image: images ? images[currentIndex] : null,
    ind: currentIndex,
  });
  const [nextTimeout, setNextTimeout] = useState<ReturnType<
    typeof setTimeout
  > | null>(null);
  const [navAnimationDir, setNavAnimationDir] = useState(true);
  const count = images ? images.length : 0;
  const modalInner = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const modalContext = useContext(ModalContext);

  const getNextIndex = useCallback(
    () => (currentIndex + 1 > count - 1 ? 0 : currentIndex + 1),
    [count, currentIndex]
  );
  const getPrevIndex = useCallback(
    () => (currentIndex - 1 < 0 ? count - 1 : currentIndex - 1),
    [count, currentIndex]
  );

  const adobe = useCallback(
    (currentIndex: number) => {
      adobeEventTracking({
        carouselNumber: `${currentIndex + 1}:${count}`,
        sectionHeading: title,
        actionDetail: 'Carousel',
        interactionName: 'propertyGalleryCarousel',
      });
    },
    [count, title]
  );

  const next = useCallback(
    function () {
      if (!images || images.length <= 1) {
        return;
      }
      if (!navAnimationDir) setNavAnimationDir(true);
      if (!nextTimeout) {
        const nextInd = getNextIndex();
        setNextImage({
          image: images[nextInd],
          ind: nextInd,
        });
        setNextTimeout(
          setTimeout(() => {
            setCurrentIndex(getNextIndex);
            setNextTimeout(null);
            adobe(getNextIndex());
          }, 500)
        );
      } else {
        clearTimeout(nextTimeout);
        setNextTimeout(null);
        const skipTransInd =
          currentIndex === count - 1
            ? 1
            : currentIndex + 2 > count - 1
            ? 0
            : currentIndex + 2;
        setCurrentIndex(skipTransInd);
        setNextImage({
          image: images[skipTransInd],
          ind: skipTransInd,
        });
        adobe(skipTransInd);
      }
    },
    [
      adobe,
      count,
      currentIndex,
      getNextIndex,
      images,
      nextTimeout,
      navAnimationDir,
    ]
  );

  const prev = useCallback(
    function () {
      if (!images || images.length <= 1) {
        return;
      }
      if (navAnimationDir) setNavAnimationDir(false);
      if (!nextTimeout) {
        const nextInd = getPrevIndex();
        setNextImage({
          image: images[nextInd],
          ind: nextInd,
        });
        setNextTimeout(
          setTimeout(() => {
            setCurrentIndex(getPrevIndex);
            setNextTimeout(null);
            adobe(getPrevIndex());
          }, 500)
        );
      } else {
        clearTimeout(nextTimeout);
        setNextTimeout(null);
        const skipTransInd =
          currentIndex === 0
            ? count - 1
            : currentIndex - 2 < 0
            ? count - 1
            : currentIndex - 2;
        setCurrentIndex(skipTransInd);
        setNextImage({
          image: images[skipTransInd],
          ind: skipTransInd,
        });
        adobe(skipTransInd);
      }
    },
    [
      adobe,
      count,
      currentIndex,
      getPrevIndex,
      images,
      nextTimeout,
      navAnimationDir,
    ]
  );

  function closeDialog() {
    modalContext?.closeDialog();
    setCurrentIndex(0);
    setNextImage({
      image: null,
      ind: 0,
    });
  }

  const getPrevSlideInd = () => {
    const ind =
      slideAnimation === 'slide-ltr' && navAnimationDir
        ? getNextIndex()
        : getPrevIndex();
    return ind;
  };

  const slideStyles = {
    '-translate-x-full':
      nextTimeout &&
      slideAnimation !== 'fade' &&
      slideAnimation !== 'slide-ltr' &&
      (navAnimationDir || slideAnimation === 'slide-rtl'),
    'translate-x-full':
      nextTimeout &&
      slideAnimation !== 'fade' &&
      slideAnimation !== 'slide-rtl' &&
      (!navAnimationDir || slideAnimation === 'slide-ltr'),
  };

  return active ? (
    <div
      className="max-w-[1920px] relative w-11/12 sm:w-10/12 mx-auto"
      ref={modalInner}
    >
      <h3
        className="py-2 text-bg text-lg rtl:md:pr-10 rtl:pr-2"
        style={{ color: captionColour }}
      >
        {title}
      </h3>
      <div className="relative">
        {slideAnimation !== 'fade' ? (
          <div className="flex flex-row h-full w-full justify-center relative overflow-hidden">
            <div
              className={cx(
                'absolute h-full w-full transform transition-all duration-500 ease-in-out',
                {
                  'translate-x-0':
                    nextTimeout && slideAnimation !== 'slide-rtl',
                  '-translate-x-full':
                    !nextTimeout || slideAnimation === 'slide-rtl',
                  'transition-none': !nextTimeout,
                }
              )}
            >
              <CroppedImage
                src={images?.[getPrevSlideInd()]?.sourceUrl || ''}
                objectPosition={
                  !images?.[getPrevSlideInd()]?.sourceUrl ? 'center center' : ''
                }
                alt={`${images?.[getPrevSlideInd()]?.altText}-next-transition`}
                width={1920}
                height={1080}
                quality="100"
                priority={true}
                objectFit="cover"
                layout="fill"
                draggable={false}
              />
            </div>
            <div
              className={cx(
                'absolute h-full w-full order-3 transform transition-all duration-500 ease-in-out',
                {
                  'translate-x-0':
                    (slideAnimation === 'slide-rtl' && nextTimeout) ||
                    (nextTimeout &&
                      slideAnimation !== 'slide-ltr' &&
                      navAnimationDir),
                  'translate-x-full':
                    !nextTimeout ||
                    (slideAnimation !== 'slide-rtl' && !navAnimationDir) ||
                    slideAnimation === 'slide-ltr',
                  'transition-none': !nextTimeout,
                }
              )}
            >
              <CroppedImage
                src={nextImage?.image?.sourceUrl || ''}
                alt={`${nextImage?.image?.altText}-next-transition`}
                objectPosition={
                  !nextImage?.image?.sourceUrl ? 'center center' : ''
                }
                width={1920}
                height={1080}
                quality="100"
                priority={true}
                objectFit="cover"
                layout="fill"
                draggable={false}
              />
            </div>
            <div
              className={cx(
                'w-full h-full order-2 transform transition-all duration-500 ease-in-out',
                {
                  'transition-none': !nextTimeout,
                },
                slideStyles
              )}
            >
              <CroppedImage
                src={images?.[currentIndex]?.sourceUrl || ''}
                alt={images?.[currentIndex]?.altText}
                objectPosition={
                  !images?.[currentIndex]?.sourceUrl ? 'center center' : ''
                }
                width={1920}
                height={1080}
                quality="100"
                priority={true}
                layout="responsive"
              />
            </div>
          </div>
        ) : (
          <div>
            <CroppedImage
              src={nextImage?.image?.sourceUrl || ''}
              alt={`${nextImage?.image?.altText}-transition`}
              objectPosition={
                !nextImage?.image?.sourceUrl ? 'center center' : ''
              }
              width={1920}
              height={1080}
              quality="100"
              priority={true}
              objectFit="cover"
              layout="fill"
              draggable={false}
              className="transform transition-all duration-500 ease-in-out animate-fade-in-slow"
            />
            <CroppedImage
              data-testid="currentModalImg"
              src={images?.[currentIndex]?.sourceUrl || ''}
              alt={images?.[currentIndex]?.altText}
              objectPosition={
                !images?.[currentIndex]?.sourceUrl ? 'center center' : ''
              }
              width={1920}
              height={1080}
              quality="100"
              priority={true}
              layout="responsive"
              className={cx(
                'absolute w-full inset-0 transform transition-all duration-500 ease-in-out',
                {
                  'opacity-0': nextTimeout ? true : false,
                  'opacity-100': nextTimeout ? false : true,
                }
              )}
            />
          </div>
        )}
        <div
          style={{
            visibility: 'hidden',
            width: 0,
            height: 0,
            overflow: 'hidden',
          }}
        >
          {images?.map((preloadImage, key) => {
            return <img key={key} src={preloadImage.sourceUrl} alt="" />;
          })}
        </div>
        <div
          data-testid="buttonContainer"
          className={cx(
            'absolute flex justify-between inset-0 m-auto z-10 h-8 sm:h-16',
            {
              hidden: images?.length === 1,
            }
          )}
        >
          <button
            data-testid="image-modal-left"
            className="flex justify-center items-center bg-bg-alt rounded-full w-8 h-8 sm:w-12 sm:h-12 transform -translate-x-1/2 rtl:translate-x-1/2 rtl:rotate-180"
            onClick={prev}
            style={{ backgroundColor: controlsBackgroundColour }}
          >
            <span className="sr-only">
              {t('modalPrevImage')
                .replace('{x}', '' + (getPrevIndex() + 1))
                .replace('{y}', '' + count)}
            </span>
            <Arrow
              className="fill-current text-bg-inverse w-6 h-6 sm:w-8 sm:h-8 lg:mr-0.5 rtl:lg:mr-0"
              fillColor={controlsIconColour}
            />
          </button>
          <button
            data-testid="image-modal-right"
            className="flex justify-center items-center bg-bg-alt rounded-full w-8 h-8 sm:w-12 sm:h-12 transform translate-x-1/2 rtl:-translate-x-1/2 rtl:rotate-180"
            onClick={next}
            style={{ backgroundColor: controlsBackgroundColour }}
          >
            <span className="sr-only">
              {t('modalNextImage')
                .replace('{x}', '' + (getNextIndex() + 1))
                .replace('{y}', '' + count)}
            </span>
            <Arrow
              className="fill-current text-bg-inverse transform rotate-180 w-6 h-6 sm:w-8 sm:h-8 lg:ml-0.5 rtl:lg:ml-0"
              fillColor={controlsIconColour}
            />
          </button>
        </div>
      </div>
      <div
        className="block lg:flex lg:justify-between lg:rtl:flex-row-reverse text-bg"
        aria-live="assertive"
      >
        <span className="sr-only">
          {!images
            ? `0 ${t('of')} 0`
            : `${nextImage?.ind + 1} ${t('of')} ${count}`}
        </span>
        <p
          data-testid="currentIndex"
          aria-hidden={true}
          className="p-0 lg:p-4 text-sm lg:text-md"
          style={{ color: captionColour }}
        >
          {!images ? `0 / 0` : `${nextImage?.ind + 1} / ${count}`}
        </p>
        <p
          className="px-0 pt-2 pb-4 lg:p-4 text-sm  lg:text-md"
          style={{ color: captionColour }}
          dangerouslySetInnerHTML={{
            __html: sanitize(images?.[nextImage?.ind]?.caption || ''),
          }}
        ></p>
      </div>
      <ImageModalThumbnailReel
        adobe={adobe}
        active={active}
        images={images}
        activeImageIdx={nextImage?.ind}
        onImageSelected={setCurrentIndex}
        nextTimeout={nextTimeout}
        setNextTimeout={setNextTimeout}
        setNextImage={setNextImage}
      ></ImageModalThumbnailReel>
    </div>
  ) : null;
}

export interface setNextIndProps {
  ind: number;
  image: ImageModalImage;
}

export interface iImageModalThumbnailReel {
  active: boolean;
  images?: ImageModalImage[];
  onImageSelected: (imageIndex: number) => void;
  adobe: (imageIndex: number) => void;
  nextTimeout?: ReturnType<typeof setTimeout> | null;
  setNextTimeout: (args: ReturnType<typeof setTimeout> | null) => void;
  setNextImage: (args: setNextIndProps) => void;
  activeImageIdx: number;
  imageRes?: {
    width?: number;
    height?: number;
  };
}

export function ImageModalThumbnailReel({
  active,
  images,
  onImageSelected,
  activeImageIdx,
  imageRes,
  adobe,
  nextTimeout,
  setNextTimeout,
  setNextImage,
}: iImageModalThumbnailReel) {
  const thumbnailReelRef = useRef<HTMLDivElement | null>(null);
  const activeThumbRef = useRef<HTMLButtonElement | null>(null);
  const [width, setWidth] = useState<number>(0);
  const { t } = useTranslation();

  const handleResize = () => {
    setWidth(thumbnailReelRef?.current?.offsetWidth || 0);
  };

  const chunkSize = 6;
  useEffect(() => {
    window.addEventListener('resize', handleResize);
  }, []);
  useEffect(() => {
    handleResize();
    if (thumbnailReelRef?.current) {
      if (
        activeThumbRef?.current?.offsetLeft &&
        (activeThumbRef?.current?.offsetLeft || 0) >
          thumbnailReelRef.current.scrollLeft + width
      ) {
        thumbnailReelRef.current.scrollLeft =
          activeThumbRef?.current?.offsetLeft || 0;
      } else if (
        activeThumbRef?.current?.offsetLeft &&
        activeThumbRef?.current?.offsetLeft <
          thumbnailReelRef.current.scrollLeft
      ) {
        thumbnailReelRef.current.scrollLeft =
          activeThumbRef?.current?.offsetLeft - width;
      }
    }
  }, [thumbnailReelRef, activeThumbRef, active, activeImageIdx, width]);

  if (typeof images === 'undefined') return null;
  const galleryThumbChunks = chunkArray(images, chunkSize);
  return (
    <div
      className="w-full relative overflow-x-scroll py-4 -mt-4"
      ref={thumbnailReelRef}
    >
      <div
        className="flex"
        style={{
          width: (width + 4) * galleryThumbChunks?.length,
        }}
      >
        {galleryThumbChunks?.map((chunk, chunkKey) => (
          <div
            className={`grid grid-cols-6 gap-2 ${chunkKey !== 0 ? 'ml-2' : ''}`}
            key={chunkKey}
            style={{
              width: width,
            }}
          >
            {chunk?.map((im: ImageModalImage, key: number) => {
              const imageIdx = chunkKey * chunkSize + key;
              return (
                <button
                  data-testid={`thumbnailReelBtn${imageIdx}`}
                  data-active={activeImageIdx === imageIdx ? true : false}
                  aria-label={`${imageIdx + 1} ${t('of')} ${chunk.length}`}
                  ref={activeImageIdx === imageIdx ? activeThumbRef : null}
                  className={` border-2 ${
                    activeImageIdx === imageIdx
                      ? 'border-white'
                      : 'border-transparent'
                  }`}
                  style={{
                    outline: '!none',
                    lineHeight: 0,
                  }}
                  onClick={(e) => {
                    if (
                      !images ||
                      images.length === 1 ||
                      activeImageIdx === imageIdx
                    ) {
                      return;
                    }
                    setNextImage({
                      image: images[imageIdx],
                      ind: imageIdx,
                    });
                    if (!nextTimeout) {
                      setNextTimeout(
                        setTimeout(() => {
                          onImageSelected(imageIdx);
                          setNextTimeout(null);
                          adobe(imageIdx);
                        }, 500)
                      );
                    } else {
                      clearTimeout(nextTimeout);
                      setNextTimeout(null);
                      onImageSelected(imageIdx);
                      adobe(imageIdx);
                    }
                  }}
                  key={chunkSize + key + chunkKey}
                >
                  <CroppedImage
                    src={im?.sourceUrl || ''}
                    alt={im?.altText}
                    width={imageRes?.width ?? 1920}
                    height={imageRes?.height ?? 1080}
                    quality="100"
                    objectPosition={
                      !im?.sourceUrl ? 'center center' : undefined
                    }
                  />
                </button>
              );
            })}
          </div>
        ))}
      </div>
    </div>
  );
}
