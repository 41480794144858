import dynamic from 'next/dynamic';

const HotelLogo = dynamic<{
  className: string;
  fillColorOne: string;
  fillColorTwo: string;
}>(() => import('./sanqqqq/hotel-logo').then((i) => i.HotelLogo));

export const hotelLogoHeader = (
  <HotelLogo
    className="fill-current text-bg antialiased w-36 sm:w-40 md:w-52 md:mr-4"
    fillColorOne="#ca3604"
    fillColorTwo="#25282a"
  />
);

export const hotelLogoFooter = (
  <HotelLogo
    className="fill-current text-bg antialiased w-32 sm:w-36 md:w-44"
    fillColorOne="#ca3604"
    fillColorTwo="#25282a"
  />
);
