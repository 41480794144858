import { ArrowHead } from '@curated-property/icon-list';
import { useTranslation } from 'next-i18next';
import { Dispatch, SetStateAction, useContext } from 'react';
import { HeadingStyle } from '../../includes/heading-style';
import { OfferOffersCurated, OffersCuratedContext } from '../offers-curated';
import { TagButtonOffersCurated } from './tag';
import { sanitize } from '@curated-property/utils';

export interface TileOffersCuratedStyleProps {
  backgroundColor?: string;
  textColor?: string;
  bookButtonStyle?: string;
  termsButtonStyle?: string;
  termsBookButtonStyle?: string;
  additionalDataAccentColor?: string;
  arrowAccentColor?: string;
}

export const defaultStyles: TileOffersCuratedStyleProps = {
  backgroundColor: '#f7f6f4',
  bookButtonStyle: 'primary',
  termsButtonStyle: 'primary-outline',
  termsBookButtonStyle: 'primary',
  textColor: '#000',
  additionalDataAccentColor: '#9ab9ad',
  arrowAccentColor: '#9ab9ad',
};

export interface TileOffersCuratedProps {
  id: number;
  offer: OfferOffersCurated;
  active: boolean;
  setActive: Dispatch<SetStateAction<number>>;
}

export function TileOffersCurated({
  id,
  offer,
  active,
  setActive,
}: TileOffersCuratedProps) {
  const { t } = useTranslation();
  const contextData = useContext(OffersCuratedContext);
  const styles = contextData?.styles?.tiles;
  const additionalData = offer?.additionalData?.map((data) => (
    <div
      className="flex justify-between py-2"
      style={{ borderBottom: `1px solid ${styles?.additionalDataAccentColor}` }}
    >
      <strong>{data?.label}</strong>
      <span>{data?.copy}</span>
    </div>
  ));

  return (
    <>
      <div
        data-offerid={offer?.id?.toString()}
        data-offer-type={offer?.type}
        key={`offers-curated-tile-id-${id}`}
        data-testid={`offers-curated-tile-id-${id}`}
        className="flex flex-col justify-between relative"
        style={{
          backgroundColor: styles?.backgroundColor,
          color: styles?.textColor,
        }}
      >
        <div>
          <img
            data-testid={`offers-curated-tile-id-${id}-img`}
            alt={offer.imageAlt || `image for offer - ${offer?.title}`}
            src={
              offer?.image ||
              'https://assets.hiltonstatic.com/images/c_fill,w_780,h_520,q_60,f_auto,g_center/v1583957952/no-image/no-image.png'
            }
            width={1024}
            height={1024}
            style={{ objectFit: 'cover', aspectRatio: '1' }}
          />
          <div className="p-4">
            {offer?.title && (
              <HeadingStyle
                textColorInline={styles?.textColor}
                className="font-bold"
                styledAs="h5"
                text={offer?.title}
              />
            )}
            <div className="flex flex-wrap gap-2 my-4">
              {offer?.tags?.map((tag) => (
                <TagButtonOffersCurated
                  key={tag?.id}
                  tag={tag}
                  disabled={
                    !(
                      contextData.getActiveTagCount() <= 0 ||
                      contextData.isTagActive(tag?.id)
                    )
                  }
                ></TagButtonOffersCurated>
              ))}
            </div>
            <p>{offer?.copy}</p>
            {offer?.additionalData instanceof Array &&
            offer?.additionalData?.length > 0 ? (
              <div
                className="pt-4"
                style={{ color: styles?.additionalDataAccentColor }}
              >
                {' '}
                {additionalData}{' '}
              </div>
            ) : null}
          </div>
        </div>
        <div>
          <div className="flex flex-wrap gap-2 mt-8 p-4 pt-0">
            <a
              aria-label={offer?.CTA?.adaDescription}
              className={`btn btn-${styles?.bookButtonStyle} text-base xl:text-lg text-center py-2 px-2`}
              href={offer?.CTA?.url}
              target={offer?.CTA?.isNewWindow ? '_blank' : null}
              rel="noreferrer"
            >
              {offer?.CTA?.label || t('bookThisOffer')}
            </a>
            {offer?.terms ? (
              <button
                data-testid="terms-button"
                aria-expanded={active}
                className={`btn btn-${styles?.termsButtonStyle} !flex gap-2 text-base xl:text-lg text-center py-2 px-2`}
                onClick={() => {
                  setActive(id);
                }}
              >
                <span className="sr-only">{offer?.title} - </span>
                {t('termsAndConditions')}
                <ArrowHead
                  className={`transform transition-all h-5 my-auto ${
                    active ? 'rotate-180' : ''
                  }`}
                ></ArrowHead>
              </button>
            ) : null}
          </div>
        </div>
        <div
          className="absolute bottom-0 right-4 h-0 w-0 border-[12px] border-b-[16px] border-t-0 border-transparent"
          style={{
            borderBottomColor: styles?.arrowAccentColor,
            opacity: active ? 1 : 0,
          }}
        ></div>
      </div>
      <div
        data-testid="termsAndConditions"
        className="relative transition duration-300 md:col-span-2 2xl:col-span-3 p-8"
        style={{
          display: active ? 'block' : 'none',
          backgroundColor: styles?.backgroundColor,
          color: styles?.textColor,
        }}
      >
        <HeadingStyle
          text={t('offers.offerTermsAndConditions')}
          styledAs="h3"
        ></HeadingStyle>
        <div
          className="py-8"
          dangerouslySetInnerHTML={{ __html: sanitize(offer?.terms) }}
        ></div>
        <a
          aria-label={offer?.CTA?.adaDescription}
          className={`btn btn-${styles?.termsBookButtonStyle} py-2`}
          href={offer?.CTA?.url}
          target={offer?.CTA?.isNewWindow ? '_blank' : null}
          rel="noreferrer"
        >
          {offer?.CTA?.label || t('bookThisOffer')}
        </a>
      </div>
    </>
  );
}
