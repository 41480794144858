/* eslint-disable complexity */
import React, { ReactNode, useEffect, useState } from 'react';
import cx from 'classnames';
import GoogleMapReact, { Coords, Maps } from 'google-map-react';
import { GoogleMapPin } from '@curated-property/icons';
import googleMapReact from 'google-map-react';
import { useMediaQuery, HandleAnimations } from './functions/helper';
import { appliedCloudinaryParams } from '@curated-property/utils';
import {
  GIS_merge,
  GIS_TextAlignment,
  GIS_Padder,
  StyleObject,
} from './functions/global-instance-styles';
import { useTranslation } from 'next-i18next';
import { AnchorLink } from './global/anchor-link';
import { sanitize } from '@curated-property/utils';
import { HotelAddress, getHotelAddressLink } from '@dx-ui/osc-hotel-address';

export enum LocationGoogleMapAlignment {
  LEFT,
  RIGHT,
}

export enum LocationGoogleMapLayout {
  OVERLAY,
  HALFANDHALF,
}

export interface LocationGoogleMapProps {
  latLng: Coords;
  zoom?: number;
  api_key: string;
  title?: string;
  title_noTx?: string;
  address?: {
    addressStacked: string;
    addressLine1: string;
    city: string;
    state: string;
    country: string;
  };
  addressUrl?: string;
  phone?: string;
  email?: string;
  emailCtaText?: string;
  emailCtaOverride?: {
    target?: string;
    title?: string;
    url?: string;
  };
  hideEmailCta?: boolean;
  overlayAlignment?: LocationGoogleMapAlignment;
  globalStyles?: StyleObject;
  instanceStyles?: StyleObject;
}

type GoogleMap =
  | (typeof googleMapReact & {
      setCenter: (center: Coords) => void;
      panBy: (lat: number, lon: number) => void;
    })
  | null;

let google_map: GoogleMap;

export function LocationGoogleMap(props: LocationGoogleMapProps) {
  const [zoomInstrOp, setZoomInstrOp] = useState('0');
  const inlineStyles = GIS_merge(props.globalStyles, props.instanceStyles);
  const textAlignment = GIS_TextAlignment(inlineStyles?.textAlignment);
  const paddingStyles = GIS_Padder(
    inlineStyles?.paddingTop,
    inlineStyles?.paddingBottom
  );
  const latLng = props.latLng;
  const center = latLng;
  const key = props.api_key || '';
  const zoom = props.zoom || 10;

  const { t } = useTranslation();

  useEffect(() => {
    const observer = new MutationObserver(function (mutations) {
      mutations.forEach(function () {
        setZoomInstrOp('0');
      });
    });

    const windowScroller = () => {
      const googleZoom = document?.getElementsByClassName(
        'gm-style-moc'
      )[0] as HTMLElement;
      let googleZoomOp = '';
      if (googleZoom) {
        googleZoomOp =
          window?.getComputedStyle(googleZoom)?.getPropertyValue('opacity') ||
          '0';
        observer.observe(googleZoom, {
          attributes: true,
          attributeFilter: ['style'],
        });
      }
      setZoomInstrOp(googleZoomOp);
    };

    if (inlineStyles?.layout !== '50-50') {
      window?.addEventListener('scroll', windowScroller, false);
    }

    return () => {
      window?.removeEventListener('scroll', windowScroller, false);
    };
  }, [inlineStyles?.layout, zoomInstrOp]);

  const overlayAlignment =
    inlineStyles?.contentAlignment === 'content_right'
      ? LocationGoogleMapAlignment.RIGHT
      : LocationGoogleMapAlignment.LEFT;

  const layout =
    inlineStyles?.layout === '50-50'
      ? LocationGoogleMapLayout.HALFANDHALF
      : LocationGoogleMapLayout.OVERLAY;

  const title = props.title || '';
  const title_noTx = props.title_noTx || '';
  const address = props.address || null;
  const phone = props.phone || null;
  const email = props.email || null;
  const emailText = props?.emailCtaText || t('emailUs');
  const addressUrl =
    props?.addressUrl ||
    getHotelAddressLink({
      addressLine1: address?.addressLine1 || '',
      city: address?.city || '',
      countryCode: address?.country || '',
      hotelName: title,
      stateCode: address?.state,
    });

  const overlay = !useMediaQuery(1024);

  const onResize = function () {
    if (google_map) {
      google_map?.setCenter(center);
      if (overlay && layout === LocationGoogleMapLayout.OVERLAY) {
        if (overlayAlignment === LocationGoogleMapAlignment.RIGHT) {
          google_map?.panBy(300, 0);
        } else {
          google_map?.panBy(-300, 0);
        }
      }
    }
  };
  const handleMapApiLoaded = (map: GoogleMap) => {
    google_map = map;
    onResize();
    if (typeof document !== 'undefined') {
      const mapDivs = document?.querySelectorAll(
        'div[aria-label="Map"]'
      ) as NodeListOf<any>;
      mapDivs.forEach((m) => (m.ariaRoleDescription = null));
    }
  };

  handleMapApiLoaded(null);

  const getMapOptions = (map: Maps) => ({
    disableDefaultUI: true,
    zoomControl: true,
    zoomControlOptions: {
      position:
        overlayAlignment === 0
          ? map.ControlPosition.RIGHT_BOTTOM
          : map.ControlPosition.LEFT_BOTTOM,
    },
    mapTypeControl: inlineStyles?.enableSatelliteView ? true : false,
    mapTypeControlOptions: {
      position:
        overlayAlignment === 0
          ? map.ControlPosition.LEFT_TOP
          : map.ControlPosition.RIGHT_TOP,
    },
    streetViewControl: false,
    styles: [
      {
        featureType: 'poi',
        elementType: 'labels',
        stylers: [{ visibility: 'off' }],
      },
    ],
  });

  const alignmentCSS =
    overlayAlignment === LocationGoogleMapAlignment.RIGHT
      ? 'right-32'
      : 'left-32';

  const containerStyles =
    layout === LocationGoogleMapLayout.OVERLAY
      ? ''
      : 'flex lg:flex-row flex-col';

  const contentContainerStyles =
    layout === LocationGoogleMapLayout.OVERLAY
      ? `lg:max-w-md z-1 lg:absolute lg:top-1/2 lg:transform lg:-translate-y-1/2 lg:shadow-lg ${alignmentCSS}`
      : `flex flex-col justify-center items-center flex-grow lg:w-1/2 w-full ${
          overlayAlignment === LocationGoogleMapAlignment.RIGHT ? 'order-2' : ''
        }`;

  const mapContainerStyles =
    layout === LocationGoogleMapLayout.OVERLAY
      ? ''
      : 'lg:w-1/2 w-full flex-grow';

  const animations = HandleAnimations({
    hideAnimation: inlineStyles?.hideAnimations !== 'show',
    start: inlineStyles?.animationDirection
      ? `${inlineStyles?.animationDirection}-8`
      : overlayAlignment
      ? 'translate-x-8'
      : '-translate-x-8',
    delayOne: 'delay-300',
    delayTwo: 'delay-500',
    delayThree: 'delay-700',
    delayFour: 'delay-1000',
  });

  return (
    <div
      className={cx(paddingStyles, inlineStyles?.showHide && 'hidden')}
      style={{
        backgroundImage: inlineStyles?.componentBackgroundImage?.sourceUrl
          ? `url('${appliedCloudinaryParams(
              inlineStyles?.componentBackgroundImage?.sourceUrl,
              inlineStyles?.componentBackgroundRepeat
            )}')`
          : undefined,
        backgroundSize: inlineStyles?.componentBackgroundSize || 'cover',
        backgroundRepeat: inlineStyles?.componentBackgroundRepeat || '',
        backgroundPosition: inlineStyles?.componentBackgroundPosition || '',
        backgroundColor: inlineStyles?.componentBackgroundColor || '',
      }}
      data-element-id="google-map-wrapper"
    >
      <div
        className={`container px-0 relative w-full max-h-[1000px] location-google-map ${containerStyles}`}
      >
        <div
          // 'lg:opacity-10'
          ref={animations?.ref}
          className={`location-google-map-content bg-bg p-12 lg:py-14 transition-all duration-700 ${
            Number(zoomInstrOp) > 0 ? 'lg:opacity-10' : null
          } ${contentContainerStyles}`}
          style={{
            backgroundImage: inlineStyles?.contentBackgroundImage?.sourceUrl
              ? `url('${appliedCloudinaryParams(
                  inlineStyles?.contentBackgroundImage?.sourceUrl,
                  inlineStyles?.contentBackgroundRepeat
                )}')`
              : '',
            backgroundColor: inlineStyles?.contentBackgroundColor || undefined,
            backgroundSize: inlineStyles?.contentBackgroundSize || 'cover',
            backgroundRepeat:
              inlineStyles?.contentBackgroundRepeat || 'no-repeat',
            backgroundPosition:
              inlineStyles?.contentBackgroundPosition || 'left center',
            textAlign: textAlignment,
            color: inlineStyles?.textColor,
          }}
        >
          <div
            className="max-w-md"
            style={{
              justifyContent: inlineStyles?.textAlignment,
            }}
          >
            {title && (
              <h2
                data-testid="gmTitle"
                className={cx(
                  'text-2xl font-black leading-none font-headline md:text-2xl',
                  animations?.one,
                  {
                    OneLinkNoTx: inlineStyles?.headingsNoTranslate,
                  }
                )}
                dangerouslySetInnerHTML={{
                  __html: sanitize(
                    inlineStyles?.headingsNoTranslate ? title_noTx : title
                  ),
                }}
                style={{ color: inlineStyles?.titleColor }}
              ></h2>
            )}
            <div>
              {address && (
                <p className={cx('mt-4', animations?.two)}>
                  <HotelAddress
                    addressStacked={address?.addressStacked || ''}
                    linkProps={{
                      anchorClassName:
                        'text-primary font-normal text-center md:text-left text-base',
                      className:
                        'whitespace-pre-line underline hover:no-underline',
                      url: '',
                      style: {
                        color: inlineStyles?.textColor,
                      },
                      ...{ 'data-testid': 'gmAddress' },
                    }}
                    hasLink={true}
                    addressLine1={address?.addressLine1}
                    city={address?.city}
                    hotelName={title ?? ''}
                    countryCode={address?.country}
                    stateCode={address?.state}
                  />
                </p>
              )}
              {phone && (
                <p className={cx('mt-4', animations?.three)}>
                  <a
                    data-testid="gmPhone"
                    href={`tel: ${phone}`}
                    rel="noopener noreferrer"
                  >
                    {t('callUs')}
                    <br />
                    <span>{`${phone}`}</span>
                  </a>
                </p>
              )}

              <p className={cx('mt-4 -mx-1', animations?.four)}>
                {email &&
                !props?.hideEmailCta &&
                !props?.emailCtaOverride?.url ? (
                  <AnchorLink
                    dataTestId="gmEmail"
                    url={`mailto: ${props?.email}`}
                    buttonStyle={inlineStyles?.buttonStyle || 'primary'}
                    title={emailText}
                  />
                ) : null}
                {props?.emailCtaOverride?.url ? (
                  <AnchorLink
                    dataTestId="gmEmailCtaOverride"
                    url={props?.emailCtaOverride?.url}
                    title={props?.emailCtaOverride?.title}
                    target={props?.emailCtaOverride?.target}
                    buttonStyle={inlineStyles?.buttonStyle || 'primary'}
                  />
                ) : null}
                {address && (
                  <AnchorLink
                    dataTestId="getDirections"
                    className={`btn btn-${
                      inlineStyles?.buttonStyle || 'primary'
                    }`}
                    url={addressUrl}
                    target="_blank"
                    title={t('getDirections')}
                  />
                )}
              </p>
            </div>
          </div>
        </div>
        <div
          data-testid="googleMap"
          className={`location-google-map-map max-h-[1000px] ${mapContainerStyles}`}
        >
          <GoogleMapReact
            bootstrapURLKeys={{ key: key }}
            defaultCenter={center}
            defaultZoom={zoom}
            yesIWantToUseGoogleMapApiInternals
            options={getMapOptions}
            onGoogleApiLoaded={({ map }) => handleMapApiLoaded(map)}
          >
            <GoogleMapPin lat={latLng.lat} lng={latLng.lng} />
          </GoogleMapReact>
        </div>
      </div>
    </div>
  );
}

function GoogleMapTitleTextWrapper(props: {
  justify?: string;
  children: ReactNode;
  title: string;
}) {
  const justify = props?.justify
    ? 'justify-' + props?.justify?.replace('flex-', '')
    : 'justify-left';
  return (
    <div className={`flex flex-col md:flex-row md:items-center ${justify}`}>
      <h4 className="font-bold mb-4 mr-4 w-24">{props.title}</h4>
      <div className="mb-4">{props.children}</div>
    </div>
  );
}
