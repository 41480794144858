import React from 'react';
export function AudioOn({
  className,
  fill,
}: {
  className?: string;
  fill?: string;
}) {
  return (
    <svg
      fill={fill}
      className={className}
      height="18"
      width="21"
      viewBox="0 0 21 18"
    >
      <path d="m 11.8828,6.3148263 c -0.4219,-0.24609 -0.914,-0.10546 -1.1601,0.31641 -0.211,0.42188 -0.0704,0.91406 0.3515,1.16016 0.4219,0.24609 0.7383,0.70312 0.7383,1.19531 0,0.52734 -0.3164,0.9844 -0.7383,1.2304997 -0.4219,0.2461 -0.5625,0.7382 -0.3515,1.1601 0.2461,0.4219 0.7382,0.5625 1.1601,0.3164 0.9844,-0.5273 1.6172,-1.582 1.6172,-2.7069997 0,-1.08984 -0.6328,-2.14453 -1.6172,-2.67188 z m 4.9922,2.67188 c 0,-2.21484 -1.1602,-4.25391 -3.0234,-5.44922 -0.3868,-0.24609 -0.9141,-0.10547 -1.1602,0.28125 -0.2461,0.38672 -0.1406,0.91406 0.2461,1.16016 1.4063,0.8789 2.25,2.39062 2.25,4.00781 0,1.6522997 -0.8437,3.1640997 -2.25,4.0429997 -0.3867,0.2461 -0.4922,0.7734 -0.2461,1.1601 0.2109,0.3867 0.7383,0.5274 1.1602,0.2813 1.8632,-1.1953 3.0234,-3.2344 3.0234,-5.4843997 z M 15.75,0.68983033 c -0.3867,-0.246094 -0.9141,-0.140625 -1.1953,0.246096 -0.2461,0.42186997 -0.1406,0.94921997 0.2461,1.19530997 2.3554,1.51172 3.7265,4.11328 3.7265,6.85547 0,2.7772997 -1.3711,5.3788997 -3.7265,6.8905997 -0.3867,0.2461 -0.4922,0.7735 -0.2461,1.1953 0.2461,0.3516 0.7734,0.4922 1.1953,0.2461 2.8125,-1.8633 4.5,-4.957 4.5,-8.3319997 0,-3.33984 -1.6875,-6.43359 -4.5,-8.29687597 z M 8.12109,2.2367063 c -0.21093,0 -0.42187,0.10547 -0.5625,0.28125 l -3.1289,3.09375 H 0.84375 C 0.35156,5.6117063 0,5.9984263 0,6.4554563 v 5.0624497 c 0,0.4922 0.35156,0.8438 0.84375,0.8438 h 3.58594 l 3.1289,3.1289 c 0.14063,0.1758 0.35157,0.2461 0.5625,0.2461 0.45703,0 0.87891,-0.3164 0.87891,-0.8438 V 3.1156163 c 0,-0.52735 -0.42188,-0.87891 -0.87891,-0.87891 z M 7.3125,12.889006 5.09766,10.674206 H 1.6875 V 7.2992063 h 3.41016 l 2.21484,-2.17969 z" />
    </svg>
  );
}
