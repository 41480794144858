import dynamic from 'next/dynamic';

const HotelLogo = dynamic<{ className: string; fillColor: string }>(() =>
  import('./lrmdohh/hotel-logo').then((i) => i.HotelLogo)
);

export const hotelLogoHeader = (
  <HotelLogo
    className="fill-current text-current antialiased w-32 pr-4"
    fillColor="#ffffff"
  />
);

export const hotelLogoFooter = (
  <HotelLogo
    className="fill-current text-current antialiased w-32"
    fillColor="#ffffff"
  />
);
