export const AdditionalHead = () => {
  return (
    <>
      <link rel="stylesheet" href="https://use.typekit.net/uzt1oro.css"></link>
      <style>{`
            :root {
                --font-headline: 'Articulat CF';
                --font-sans: 'Articulat CF';
            }
            body {
                font-family: articulat-cf, sans-serif !important; 
            }
            h1,h2,h3,h4,h5,.font-black,.font-headline {
                font-family: articulat-cf, sans-serif !important;
                text-transform: uppercase !important;
                font-weight: normal!important;
            }
        `}</style>
    </>
  );
};
