import { format } from 'date-fns';
/**
 *
 * @param num
 * @param totalLength
 * @returns
 */
function leadingZero(num, totalLength) {
  return String(num).padStart(totalLength, '0');
}

// Test and convert time to hh:mm:ss if not formatted as such
/**
 *
 * @param origTime
 * @returns
 */
export function twelveHourClockToTwentyFour(origTime) {
  let returnedTimeStr = '';

  // Check to see if time string matches pattern hh:mm:ss;  it mayb hh:mm am/pm, which won't work for this conversion
  if (
    origTime.match(/^(?:2[0-3]|[01]?[0-9]):[0-5][0-9]:[0-5][0-9]$/) !== null
  ) {
    returnedTimeStr = origTime;
  } else {
    const [fullTime, modifier] = origTime.split(' ');
    let hours = fullTime.split(':')[0];
    const minutes = fullTime.split(':')[1];

    hours = parseInt(hours, 10);

    // Test modifier and add formatting based on time of day.
    const m = modifier.toLowerCase();
    if (m === 'a.m.' || m === 'am') {
      hours === 12 ? (hours = '00') : (hours = leadingZero(hours, 2));
    } else {
      if (m === 'p.m.' || m === 'pm') {
        hours = hours < 12 ? hours + 12 : hours;
      }
    }
    const reFormattedtime = `${hours}:${minutes}:00`;
    returnedTimeStr = reFormattedtime;
  }
  return returnedTimeStr;
}

/**
 *
 * @param dateStr
 * @param timeVal
 * @param locale
 * @returns
 */
export function formatHourDisplay(dateStr, timeVal, locale) {
  const str = format(
    new Date(`${dateStr}T${twelveHourClockToTwentyFour(timeVal)}`),
    'h:mm aaaa',
    { locale }
  );
  return str;
}

/**
 *
 * @param dateStr
 * @param openTime
 * @param closeTime
 * @param locale
 * @returns
 */
export function openCloseTimes(dateStr, openTime, closeTime = '', locale) {
  let string = '';
  if (openTime && dateStr) {
    const d = dateStr.split(' ');

    string += format(
      new Date(`${dateStr}T${twelveHourClockToTwentyFour(openTime)}`),
      'h:mm aaaa',
      { locale }
    );

    if (closeTime !== '' && closeTime !== null && dateStr) {
      string += ` to ${format(
        new Date(`${dateStr}T${twelveHourClockToTwentyFour(closeTime)}`),
        'h:mm aaaa',
        { locale }
      )}`;
    }
  }
  return string.toLowerCase();
}

// Examples: timeSpaced = 12:30 am | timeUnspaced = 12:30am | year (year, month, day) 20220531
export function formatDateTime(dateVal, timeVal, locale) {
  const str = format(new Date(`${dateVal}T${timeVal}`), 'h:mm aaa', { locale });
  return str;
}

export function formatDateTimeNoSpace(dateVal, timeVal, locale) {
  const str = format(new Date(`${dateVal}T${timeVal}`), 'h:mmaaa', { locale });
  return str;
}

/**
 * Convert an am/pm time to hh:mm:ss (needed for special event start times)
 * @param val
 * @returns
 */
export function convertTimeTo24Hour(val) {
  let functionVal = val;

  // If a value is supplied without minutes ('1 pm'), change to a hour:minute value ('1:00 pm'). This should be very edge case
  if (!functionVal.includes(':')) {
    const numberPattern = /\d+/g;
    const ltrPattern = /[a-zA-Z]+/g;
    const nums = functionVal.match(numberPattern);
    const amPm = functionVal.match(ltrPattern);
    functionVal = `${nums}:00 ${amPm}`;
  }

  const [hour, minutesAmPm] = functionVal.split(':');
  const [minutes, amPm] = minutesAmPm.split(' ');

  let hourReturned = hour;
  if (amPm === 'pm' && hour < 12) {
    hourReturned = parseInt(hourReturned) + 12;
  } else {
    if (amPm === 'am' && hourReturned === 12) {
      hourReturned = '00';
    }
  }
  return `${hourReturned.toString()}:${minutes.toString()}:00`;
}

/**
 * Compares times for time-sorting
 * @param a
 * @param b
 * @returns
 */
export function compareTimes(a, b) {
  if (a.start < b.start) {
    return -1;
  }
  if (a.start > b.start) {
    return 1;
  }
  return 0;
}

/**
 * Compares special events by start date for sorting
 * @param a
 * @param b
 */
export function sortByStartDate(a, b) {
  const aStart = parseInt(a?.node?.calendarOfEvents?.startDate);
  const bStart = parseInt(b?.node?.calendarOfEvents?.startDate);

  if (aStart < bStart) {
    return -1;
  }
  if (aStart > bStart) {
    return 1;
  }
  return 0;
}

/**
 * Multi-date width span selction
 */
export function multiDateWidth() {
  const table = document.getElementById('desktopCalendar');
  let tdWidth = null;
  if (table) {
    tdWidth = table.getElementsByTagName('td')[0].clientWidth;
  }

  if (tdWidth) {
    const items = table.querySelectorAll('[data-event-type="multidate"]');

    items?.forEach((el) => {
      const widthFactor = parseInt(el?.getAttribute('data-days-to-end'));
      if (widthFactor > 1) {
        const eventButtonWidth = widthFactor * tdWidth - tdWidth * 0.05;
        document.getElementById(
          el?.id
        ).style.width = `${eventButtonWidth.toString()}px`;
      }
    });
  }
}

/**
 * Style writer provides part of the style block which is injected into the <head> of the Calendar page
 * @param inlineStyles
 * @returns
 */
export function eventCalendarStyleBlock(inlineStyles) {
  let styleString = '';

  // This could be used for both regular and special event styling, so it's rendered as a separate text string.

  const defaultButtonCms = `
 ${
   inlineStyles?.eventsCalRegularBtnText
     ? `color: ${inlineStyles?.eventsCalRegularBtnText};`
     : ''
 }
 ${
   inlineStyles?.eventsCalRegularBtnBorderColor
     ? `border-color: ${inlineStyles?.eventsCalRegularBtnBorderColor};`
     : ''
 }
 ${
   inlineStyles?.eventsCalRegularBtnBg
     ? `background-color: ${inlineStyles?.eventsCalRegularBtnBg};`
     : ''
 }
 `;
  const defaultButtonCmsHover = `
 ${
   inlineStyles?.eventsCalRegularBtnTextHover
     ? `color: ${inlineStyles?.eventsCalRegularBtnTextHover};`
     : ''
 }
 ${
   inlineStyles?.eventsCalRegularBtnBorderColorHover
     ? `border-color: ${inlineStyles?.eventsCalRegularBtnBorderColorHover};`
     : ''
 }
 ${
   inlineStyles?.eventsCalRegularBtnBgHover
     ? `background-color: ${inlineStyles?.eventsCalRegularBtnBgHover}`
     : ''
 }
 `;

  // Special event button settings - used unless the option for substituting regular events is selected
  const specialEventButtonCms = `
 ${
   inlineStyles?.eventsCalSpecialBtnText
     ? `color: ${inlineStyles?.eventsCalSpecialBtnText};`
     : ''
 }
 ${
   inlineStyles?.eventsCalSpecialBtnBorderColor
     ? `border-color: ${inlineStyles?.eventsCalSpecialBtnBorderColor};`
     : ''
 }
 ${
   inlineStyles?.eventsCalSpecialBtnBg
     ? `background-color: ${inlineStyles?.eventsCalSpecialBtnBg};`
     : ''
 }
 `;

  const specialEventButtonCmsHover = `
 ${
   inlineStyles?.eventsCalSpecialBtnTextHover
     ? `color: ${inlineStyles?.eventsCalSpecialBtnTextHover};`
     : ''
 }
 ${
   inlineStyles?.eventsCalSpecialBtnBorderColorHover
     ? `border-color: ${inlineStyles?.eventsCalSpecialBtnBorderColorHover};`
     : ''
 }
 ${
   inlineStyles?.eventsCalSpecialBtnBgHover
     ? `background-color: ${inlineStyles?.eventsCalSpecialBtnBgHover};`
     : ''
 }
 `;

  // Special event button settings - used unless the option for substituting regular events is selected
  const additionalButtonCms = `
 ${
   inlineStyles?.eventsCalAdditionalBtnText
     ? ` color: ${inlineStyles?.eventsCalAdditionalBtnText} !important;`
     : ''
 }
 ${
   inlineStyles?.eventsCalAdditionalBtnBorderColor
     ? `border-color: ${inlineStyles?.eventsCalAdditionalBtnBorderColor} !important;`
     : ''
 }
 ${
   inlineStyles?.eventsCalAdditionalBtnBg
     ? `background-color: ${inlineStyles?.eventsCalAdditionalBtnBg} !important;`
     : ''
 }
 `;
  const additionalButtonCmsHover = `
 ${
   inlineStyles?.eventsCalAdditionalBtnTextHover
     ? `color: ${inlineStyles?.eventsCalAdditionalBtnTextHover} !important;`
     : ''
 }
 ${
   inlineStyles?.eventsCalAdditionalBtnBorderColorHover
     ? `border-color: ${inlineStyles?.eventsCalAdditionalBtnBorderColorHover} !important;`
     : ''
 }
 ${
   inlineStyles?.eventsCalAdditionalBtnBgHover
     ? `background-color: ${inlineStyles?.eventsCalAdditionalBtnBgHover} !important;`
     : ''
 }
 `;

  // Regular event button styling
  let regularButtonStyleString =
    defaultButtonCms.replace(/\s/g, '').length > 0
      ? ` .calendar-regular-event:not(.text-text-disabled) { ${defaultButtonCms} } `
      : '';
  regularButtonStyleString +=
    defaultButtonCmsHover.replace(/\s/g, '').length > 0
      ? ` .calendar-regular-event:hover:not(.text-text-disabled),.calendar-regular-event:focus:not(.text-text-disabled) { ${defaultButtonCmsHover} }`
      : '';

  styleString += regularButtonStyleString;

  let specialEventButtonStyleString = '';

  // Special event button styling
  if (inlineStyles?.eventsCalUsesRegularEventButtonColors) {
    specialEventButtonStyleString =
      defaultButtonCms.replace(/\s/g, '').length > 0 &&
      ` .calendar-special-event:not(.text-text-disabled) { ${defaultButtonCms} } `;
    specialEventButtonStyleString +=
      defaultButtonCmsHover.replace(/\s/g, '').length > 0
        ? ` .calendar-special-event:hover:not(.text-text-disabled),.calendar-special-event:focus:not(.text-text-disabled) { ${defaultButtonCmsHover} }`
        : '';
  } else {
    if (!inlineStyles?.eventsCalUsesRegularEventButtonColors) {
      specialEventButtonStyleString =
        specialEventButtonCms.replace(/\s/g, '').length > 0 &&
        ` .calendar-special-event:not(.text-text-disabled) { ${specialEventButtonCms} } `;
      specialEventButtonStyleString +=
        specialEventButtonCmsHover.replace(/\s/g, '').length > 0
          ? ` .calendar-special-event:hover:not(.text-text-disabled),.calendar-special-event:focus:not(.text-text-disabled) { ${specialEventButtonCmsHover} }`
          : '';
    }
  }

  styleString += specialEventButtonStyleString;
  styleString +=
    additionalButtonCms.replace(/\s/g, '').length > 0 &&
    ` .calendar-additional-button { ${additionalButtonCms} }`;
  styleString +=
    additionalButtonCmsHover.replace(/\s/g, '').length > 0 &&
    ` .calendar-additional-button:hover,.calendar-additional-button:focus { ${additionalButtonCmsHover} }`;

  // Events filter checkbox background when checked
  styleString +=
    inlineStyles?.eventsCalfilterCheckboxColor &&
    ` .calendar-filter:checked { background-color: ${inlineStyles?.eventsCalfilterCheckboxColor}; } `;

  return styleString;
}
