import Popover, { PopoverProps, positionDefault } from '@reach/popover';
import React, { useEffect, useRef } from 'react';
import cx from 'classnames';

interface PopupContentProps {
  targetRef: PopoverProps['targetRef'];
  children: PopoverProps['children'];
  onClose(): void;
  className?: string;
}

export function PopupContent({
  targetRef,
  children,
  onClose,
  className,
}: PopupContentProps) {
  const innerRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    function onMouseDown(e: MouseEvent | FocusEvent) {
      if (
        !innerRef?.current?.contains(e.target as Element) &&
        !targetRef?.current?.contains(e.target as Element)
      ) {
        onClose();
      }
    }
    function onKeyDown(e: KeyboardEvent) {
      if (e.key === 'Escape') {
        onClose();
      }
    }

    window.addEventListener('mousedown', onMouseDown);
    window.addEventListener('keydown', onKeyDown);
    window.addEventListener('focusin', onMouseDown);

    return () => {
      window.removeEventListener('mousedown', onMouseDown);
      window.removeEventListener('keydown', onKeyDown);
      window.removeEventListener('focusin', onMouseDown);
    };
  }, [onClose, targetRef]);

  return (
    <Popover
      targetRef={targetRef}
      position={positionDefault}
      className={cx(
        'p-2 border border-solid shadow-lg bg-bg border-border focus:outline-none z-30',
        className
      )}
    >
      <div
        data-testid="popoverContent"
        className="w-full h-full"
        ref={innerRef}
      >
        {children}
      </div>
    </Popover>
  );
}
