import React from 'react';
export function Luggage({
  className,
  fillColor,
}: {
  className?: string;
  fillColor?: string;
}) {
  return (
    <svg
      className={className}
      style={{ fill: fillColor }}
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 24 24"
    >
      <g>
        <g>
          <path style={{ fill: 'none' }} d="M0,0v24h24V0H0z" />
        </g>
        <g>
          <g>
            <path
              d="M15,8.625C14.7929,8.6251,14.6251,8.7929,14.625,9v8c0,0.2071,0.1679,0.375,0.375,0.375s0.375-0.1679,0.375-0.375V9
            C15.3749,8.7929,15.2071,8.6251,15,8.625z"
            />
            <path
              d="M9,8.625C8.7929,8.6251,8.6251,8.7929,8.625,9v8c0,0.2071,0.1679,0.375,0.375,0.375S9.375,17.2071,9.375,17V9
            C9.3749,8.7929,9.2071,8.6251,9,8.625z"
            />
            <path
              d="M15,6.625H9C7.6889,6.6265,6.6265,7.6889,6.625,9v8c0.003,1.1642,0.8502,2.1543,2,2.3371V20
            c0,0.2071,0.1679,0.375,0.375,0.375S9.375,20.2071,9.375,20v-0.625h5.25V20c0,0.2071,0.1679,0.375,0.375,0.375
            s0.375-0.1679,0.375-0.375v-0.6629c1.1498-0.1828,1.997-1.1729,2-2.3371V9C17.3735,7.6889,16.3111,6.6265,15,6.625z M16.625,17
            c-0.0007,0.8972-0.7278,1.6243-1.625,1.625H9C8.1028,18.6243,7.3757,17.8972,7.375,17V9C7.3757,8.1028,8.1028,7.3757,9,7.375h6
            C15.8972,7.3757,16.6243,8.1028,16.625,9V17z"
            />
            <path
              d="M10,6.375C10.2071,6.3749,10.3749,6.2071,10.375,6V5c0.0003-0.345,0.28-0.6247,0.625-0.625h2
            c0.345,0.0003,0.6247,0.28,0.625,0.625v1c0,0.2071,0.1679,0.375,0.375,0.375S14.375,6.2071,14.375,6V5
            C14.3739,4.2411,13.7589,3.6261,13,3.625h-2C10.2411,3.6261,9.6261,4.2411,9.625,5v1C9.6251,6.2071,9.7929,6.3749,10,6.375z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
