import React from 'react';
export function MassageTreatments({
  className,
  fillColor,
}: {
  className?: string;
  fillColor?: string;
}) {
  return (
    <svg className={className} viewBox="0 0 24 24" style={{ fill: fillColor }}>
      <g data-name="Bounding Box">
        <path style={{ fill: 'none' }} d="M0,0V24H24V0Z" />
      </g>
      <g data-name="Icons Outlined">
        <g>
          <path d="M11.5,7.375A1.875,1.875,0,1,0,9.625,5.5,1.8767,1.8767,0,0,0,11.5,7.375Zm0-3A1.125,1.125,0,1,1,10.375,5.5,1.1266,1.1266,0,0,1,11.5,4.375Z" />
          <path d="M18,16.5752A1.875,1.875,0,1,0,16.125,14.7,1.8767,1.8767,0,0,0,18,16.5752Zm0-3A1.125,1.125,0,1,1,16.875,14.7,1.1266,1.1266,0,0,1,18,13.5752Z" />
          <path d="M20.3076,17.834a.376.376,0,0,0-.2666-.1592l-3.27-.3633c-.2217-.29-.7676-1.0088-1.0186-1.3838a2.5088,2.5088,0,0,0-2.3086-1.3027h-5.26v-.0059a.3751.3751,0,0,0-.375-.375H4a.375.375,0,0,0,0,.75H7.4346v4.958a.375.375,0,0,0,.75,0V18.4229h5.7077l1.6731,1.4335a.3781.3781,0,0,0,.2442.09h2.667c1.4189,0,1.8691-1.7334,1.8877-1.8076A.3758.3758,0,0,0,20.3076,17.834Zm-1.831,1.3623H15.9482L13.3867,17.001a.3754.3754,0,0,0-.4883.57l.1186.1016H8.1846V15.375h5.26a1.7688,1.7688,0,0,1,1.6856.9707c.331.4941,1.1113,1.5078,1.1445,1.55a.3763.3763,0,0,0,.2559.1445l2.9511.3272C19.3105,18.7285,18.9912,19.1963,18.4766,19.1963Z" />
          <path d="M5.709,13.9463a.3728.3728,0,0,0,.4892-.2041l2.16-5.2471c.1738-.4248.4678-.667,1.333-.4629a1.6749,1.6749,0,0,1,1.0879,1.2032c.123.4208.4346,1.9218.4375,1.9375a.3767.3767,0,0,0,.1064.1933L13.1523,13.14a.9037.9037,0,0,1-1.2607.0069l-1.7744-1.2539L9.5957,9.8389a.375.375,0,0,0-.7266.1855l.5557,2.1875a.3774.3774,0,0,0,.1465.2139l1.873,1.3232a1.6607,1.6607,0,0,0,2.3194-.1533l.1914-.1914a.3761.3761,0,0,0-.0049-.5352l-2.0225-1.9619c-.0771-.3652-.3164-1.499-.4277-1.8808A2.4156,2.4156,0,0,0,9.8613,7.3018C8.76,7.041,8.0205,7.3477,7.665,8.209l-2.16,5.248A.3748.3748,0,0,0,5.709,13.9463Z" />
        </g>
      </g>
    </svg>
  );
}
