import dynamic from 'next/dynamic';

const HotelLogo = dynamic<{ className?: string; fillColor?: string }>(() =>
  import('./dxbwawa/hotel-logo').then((i) => i.HotelLogo)
);

export const hotelLogoHeader = (
  <HotelLogo
    className="fill-current text-bg antialiased w-36 md:w-40 lg:w-44"
    fillColor="#000"
  />
);

export const hotelLogoFooter = (
  <HotelLogo
    className="fill-current text-bg antialiased w-36 md:w-40 mr-2 md:mr-0"
    fillColor="#fff"
  />
);
