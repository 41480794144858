import React from 'react';
export function Towels({
  className,
  fillColor,
}: {
  className?: string;
  fillColor?: string;
}) {
  return (
    <svg className={className} viewBox="0 0 24 24" style={{ fill: fillColor }}>
      <g data-name="Bounding Box">
        <path style={{ fill: 'none' }} d="M0,0V24H24V0Z" />
      </g>
      <g data-name="Icons Outlined">
        <g>
          <path d="M16,11.625H8a1.123,1.123,0,0,0-.7617.3633,2.7242,2.7242,0,0,0-.5645,2.0088L6.625,19.98a.375.375,0,0,0,.3721.3779H7a.3751.3751,0,0,0,.375-.372l.0488-5.9942c-.03-1.2353.3731-1.5976.5762-1.6162h6.95A3.4325,3.4325,0,0,0,14.625,14v6a.375.375,0,0,0,.75,0V14c0-1.2422.418-1.6064.625-1.625a.375.375,0,0,0,0-.75Z" />
          <path d="M9,18.625A.3751.3751,0,0,0,8.625,19v1a.375.375,0,0,0,.75,0V19A.3751.3751,0,0,0,9,18.625Z" />
          <path d="M11,18.625a.3751.3751,0,0,0-.375.375v1a.375.375,0,0,0,.75,0V19A.3751.3751,0,0,0,11,18.625Z" />
          <path d="M13,18.625a.3751.3751,0,0,0-.375.375v1a.375.375,0,0,0,.75,0V19A.3751.3751,0,0,0,13,18.625Z" />
          <path d="M19.1475,10.6553,12.375,7.7529v-.416A1.8749,1.8749,0,1,0,10.125,5.5a.375.375,0,0,0,.75,0A1.125,1.125,0,1,1,12,6.625.3751.3751,0,0,0,11.625,7V8a.3755.3755,0,0,0,.2275.3447l7.0205,3.0078c.2364.086.752.3663.752.6475a.59.59,0,0,1-.625.625H17a.3751.3751,0,0,0-.375.375v6a.375.375,0,0,0,.75,0V13.375H19A1.3364,1.3364,0,0,0,20.375,12C20.375,11.1162,19.2539,10.6924,19.1475,10.6553Z" />
          <path d="M6,12.625H5.0029A.5906.5906,0,0,1,4.375,12c0-.2852.5205-.5791.7627-.6719l5-1.9795a.3748.3748,0,1,0-.2754-.6972L4.8682,10.6289c-.127.0479-1.2432.4922-1.2432,1.3711A1.3364,1.3364,0,0,0,5,13.375H6a.375.375,0,0,0,0-.75Z" />
        </g>
      </g>
    </svg>
  );
}
