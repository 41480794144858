import React from 'react';
export function Clock({
  className,
  fillColor,
}: {
  className?: string;
  fillColor?: string;
}) {
  return (
    <svg className={className} viewBox="0 0 24 24" style={{ fill: fillColor }}>
      <g data-name="Bounding Box">
        <path style={{ fill: 'none' }} d="M0,0V24H24V0Z" />
      </g>
      <g data-name="Icons Outlined">
        <g>
          <path d="M4.168,10.3633a.391.391,0,0,0,.0937.0117.3759.3759,0,0,0,.3633-.2812A7.6115,7.6115,0,0,1,10.0937,4.625a.3752.3752,0,1,0-.1875-.7266A8.3648,8.3648,0,0,0,3.8984,9.9062.3759.3759,0,0,0,4.168,10.3633Z" />
          <path d="M12,3.625A.3751.3751,0,0,0,11.625,4V6a.375.375,0,0,0,.75,0V4.3941a7.6243,7.6243,0,0,1,7.231,7.2309H18a.375.375,0,0,0,0,.75h1.606a7.6231,7.6231,0,0,1-7.231,7.231V18a.375.375,0,0,0-.75,0v1.6061A7.622,7.622,0,0,1,4.394,12.375H6a.375.375,0,0,0,0-.75H4A.3751.3751,0,0,0,3.625,12a8.384,8.384,0,0,0,8.374,8.375l.0008,0,0,0,0,0a8.375,8.375,0,0,0,0-16.75Z" />
          <path d="M9.7373,10.7324a.3749.3749,0,0,0-.0049.53l2.02,2.0635.0039.0026.0009.0023.006.0024a.3663.3663,0,0,0,.5134,0l.006-.0024.001-.0023.0039-.0026,3.9805-4.0635a.375.375,0,0,0-.5352-.5254l-3.7127,3.79-1.7521-1.79A.374.374,0,0,0,9.7373,10.7324Z" />
        </g>
      </g>
    </svg>
  );
}
