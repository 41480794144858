import React from 'react';
export function Arrow({
  className,
  fillColor,
}: {
  className?: string;
  fillColor?: string;
}) {
  return (
    <svg className={className} viewBox="0 0 24 24" style={{ fill: fillColor }}>
      <g data-name="Bounding Box">
        <path style={{ fill: 'none' }} d="M0,0V24H24V0Z" />
      </g>
      <g data-name="Icons Outlined">
        <path d="M18,11.625H6.9053l5.36-5.3594a.3756.3756,0,0,0-.5312-.5312l-6,6-.0013.0031a.3728.3728,0,0,0,0,.525l.0013.0031,6,6a.3756.3756,0,0,0,.5312-.5312l-5.36-5.3594H18a.375.375,0,0,0,0-.75Z" />
      </g>
    </svg>
  );
}
