import cx from 'classnames';
import { AccordionAddOnRowProps } from './accordion-add-on.types';

export function AccordionAddOnSectionRow({
  rowLabel,
  rowText,
}: AccordionAddOnRowProps) {
  return (
    <li
      className={cx('w-full px-2 py-2 block', { 'sm:flex': rowLabel !== null })}
    >
      {rowLabel && rowText && (
        <div className="block w-full sm:w-1/2 lg:w-1/3 font-extrabold">
          {rowLabel}
        </div>
      )}
      {rowText && (
        <div
          className={cx('block w-full', {
            'sm:w-1/2 lg:w-2/3': rowLabel !== null,
          })}
        >
          <p
            dangerouslySetInnerHTML={{
              __html: rowText ? rowText : rowText || '',
            }}
          />
        </div>
      )}
    </li>
  );
}
