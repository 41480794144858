import React from 'react';

export function User(props: React.SVGAttributes<SVGElement>) {
  return (
    <svg viewBox="0 0 26 30" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M13.138 17.5c6.17 0 11.481 4.607 12.358 10.715a.829.829 0 01-.195.663.83.83 0 01-.63.289H1.603a.836.836 0 01-.825-.952c.878-6.108 6.19-10.715 12.36-10.715zm0 1.667c-5.02 0-9.387 3.52-10.529 8.333h21.057c-1.142-4.813-5.508-8.333-10.528-8.333zM13 .833c4.135 0 7.5 3.365 7.5 7.5s-3.365 7.5-7.5 7.5-7.5-3.365-7.5-7.5 3.365-7.5 7.5-7.5zM13 2.5a5.84 5.84 0 00-5.833 5.833A5.84 5.84 0 0013 14.167a5.84 5.84 0 005.833-5.834A5.84 5.84 0 0013 2.5z"
        fill={props?.style?.stroke}
        fillRule="nonzero"
      />
    </svg>
  );
}
