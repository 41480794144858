import dynamic from 'next/dynamic';

const HotelLogo = dynamic<{ className: string; fillColor: string }>(() =>
  import('./phxbmwa/hotel-logo').then((i) => i.HotelLogo)
);

export const hotelLogoHeader = (
  <HotelLogo fillColor="#000000" className="antialiased w-44" />
);

export const hotelLogoFooter = (
  <HotelLogo fillColor="#ffffff" className="antialiased w-40" />
);
