import React from 'react';

export function OceanView(props: React.SVGAttributes<SVGElement>) {
  return (
    <svg
      viewBox="0 0 34 33"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <g fill={props?.style?.stroke} fillRule="evenodd">
        <g transform="translate(1)">
          <path
            d="M.374 20.062C9.12 20.062 7.427.694 18.26.694c5.697 0 8.581 6.83 4.842 10.178-2.029-1.013-4.427-.851-6.406.077a3.905 3.905 0 01-1.11-2.742c0-1.721 1.422-3.121 3.17-3.121 1.359 0 2.464 1.089 2.464 2.427a.347.347 0 00.696 0c0-1.72-1.418-3.121-3.16-3.121-2.131 0-3.865 1.711-3.865 3.815 0 1.137.423 2.236 1.175 3.083-4.578 2.938-6.24 14.717-15.622 12.047a.348.348 0 00-.19.668C10.488 26.917 12.198 14 16.782 11.675c1.869-.946 4.233-1.159 6.198-.082a5.745 5.745 0 013 5.026c0 2.686-2.383 4.795-5.002 4.505-1.822-.202-3.195-1.719-3.195-3.53 0-1.528 1.263-2.772 2.815-2.772 1.203 0 2.181.963 2.181 2.148a.347.347 0 00.696 0c0-1.567-1.29-2.842-2.877-2.842-1.936 0-3.51 1.555-3.51 3.466 0 2.102 1.546 3.868 3.625 4.19 1.69 3.065 7.272 4.095 11.04 2.96a.347.347 0 10-.201-.664c-3.296.993-8.217.184-9.983-2.24 1.888-.063 3.655-1.118 4.544-2.872 1.588 1.287 3.564 1.747 5.614 1.294a.347.347 0 10-.15-.678c-1.904.42-3.733-.039-5.18-1.288.801-2.32-.124-5.413-2.668-7.063C27.698 7.393 24.5 0 18.26 0 6.943 0 8.38 19.368.373 19.368a.348.348 0 00.001.694"
            strokeWidth={0.5}
            fill={props?.style?.stroke}
          />
        </g>
        <path
          d="M5.506 21.304c3.359-1.414 5.329-7.561 7.453-11.461a.342.342 0 00-.153-.47.368.368 0 00-.487.147c-2.012 3.693-4.08 9.874-7.102 11.146a.343.343 0 00-.187.459c.08.175.292.256.476.18M16.307 17.518c-.695 1.314-2.797 4.63-4.514 5.922a.292.292 0 00-.039.449.4.4 0 00.513.034c1.824-1.373 3.954-4.728 4.7-6.137.084-.16.004-.349-.178-.423-.182-.074-.398-.005-.482.155M32.652 26.667c-2.738 0-2.752 1.904-5.217 1.904s-2.48-1.904-5.218-1.904S19.467 28.57 17 28.57c-2.465 0-2.48-1.904-5.217-1.904-2.738 0-2.752 1.904-5.218 1.904-2.464 0-2.483-1.904-5.217-1.904-.192 0-.348.17-.348.38s.156.382.348.382c2.464 0 2.48 1.904 5.217 1.904 2.738 0 2.751-1.904 5.218-1.904 2.465 0 2.48 1.904 5.217 1.904 2.738 0 2.751-1.904 5.217-1.904 2.465 0 2.48 1.904 5.218 1.904s2.75-1.904 5.217-1.904c.192 0 .348-.171.348-.381s-.156-.381-.348-.381M32.652 29.333c-2.738 0-2.755 1.905-5.22 1.905-2.466 0-2.481-1.905-5.218-1.905-2.738 0-2.751 1.905-5.217 1.905-2.466 0-2.48-1.905-5.218-1.905s-2.751 1.905-5.217 1.905c-2.465 0-2.479-1.905-5.214-1.905-.192 0-.348.171-.348.381s.156.381.348.381C3.812 30.095 3.823 32 6.562 32c2.738 0 2.75-1.905 5.217-1.905 2.465 0 2.48 1.905 5.218 1.905 2.737 0 2.75-1.905 5.217-1.905 2.465 0 2.48 1.905 5.217 1.905 2.738 0 2.753-1.905 5.221-1.905.192 0 .348-.17.348-.38 0-.211-.156-.382-.348-.382"
          strokeWidth={0.5}
          fill={props?.style?.stroke}
        />
      </g>
    </svg>
  );
}
