import React from 'react';
export function HelpCircle({
  className,
  fillColor,
}: {
  className?: string;
  fillColor?: string;
}) {
  return (
    <svg className={className} viewBox="0 0 24 24" style={{ fill: fillColor }}>
      <g data-name="Bounding Box">
        <path style={{ fill: 'none' }} d="M0,0V24H24V0Z" />
      </g>
      <g data-name="Icons Outlined">
        <path d="M12,20.375A8.375,8.375,0,1,1,20.375,12,8.3842,8.3842,0,0,1,12,20.375Zm0-16A7.625,7.625,0,1,0,19.625,12,7.6341,7.6341,0,0,0,12,4.375Z" />
        <path d="M11.9971,16.626a.625.625,0,1,1,.625-.625A.6255.6255,0,0,1,11.9971,16.626Z" />
        <path d="M12,14.375A.3751.3751,0,0,1,11.625,14V13a2.4828,2.4828,0,0,1,1.4629-2.1377A1.74,1.74,0,0,0,14.125,9.4a2.1274,2.1274,0,0,0-4.25,0,.375.375,0,0,1-.75,0,2.8768,2.8768,0,0,1,5.75,0,2.4837,2.4837,0,0,1-1.4629,2.1377,1.7408,1.7408,0,0,0-1.0371,1.4648V14A.3751.3751,0,0,1,12,14.375Z" />
      </g>
    </svg>
  );
}
