import React from 'react';
export function City({
  className,
  fillColor,
}: {
  className?: string;
  fillColor?: string;
}) {
  return (
    <svg
      className={className}
      style={{ fill: fillColor }}
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 24 24"
    >
      <g>
        <g>
          <path style={{ fill: 'none' }} d="M0,0v24h24V0H0z" />
        </g>
        <g>
          <g>
            <path
              d="M14,5.625h-1.6328V4c0-0.2071-0.1679-0.375-0.375-0.375S11.6172,3.7929,11.6172,4v1.625H10
            C9.2215,5.646,8.6067,6.2928,8.625,7.0713V20c0,0.2071,0.1679,0.375,0.375,0.375S9.375,20.2071,9.375,20V7.0713
            C9.3568,6.707,9.6358,6.3961,10,6.375h4c0.3642,0.0211,0.6432,0.332,0.625,0.6963V20c0,0.2071,0.1679,0.375,0.375,0.375
            s0.375-0.1679,0.375-0.375V7.0713C15.3933,6.2928,14.7785,5.646,14,5.625z"
            />
            <path
              d="M13.5,9.625h-3c-0.2071,0-0.375,0.1679-0.375,0.375s0.1679,0.375,0.375,0.375h3c0.2071,0,0.375-0.1679,0.375-0.375
            S13.7071,9.625,13.5,9.625z"
            />
            <path
              d="M13.5,11.625h-3c-0.2071,0-0.375,0.1679-0.375,0.375s0.1679,0.375,0.375,0.375h3c0.2071,0,0.375-0.1679,0.375-0.375
            S13.7071,11.625,13.5,11.625z"
            />
            <path
              d="M13.5,13.625h-3c-0.2071,0-0.375,0.1679-0.375,0.375s0.1679,0.375,0.375,0.375h3c0.2071,0,0.375-0.1679,0.375-0.375
            S13.7071,13.625,13.5,13.625z"
            />
            <path
              d="M7,9.625H5C4.2411,9.6261,3.6261,10.2411,3.625,11v9c0,0.2071,0.1679,0.375,0.375,0.375
            c0.2071,0,0.375-0.1679,0.375-0.375v-9c0.0003-0.345,0.28-0.6247,0.625-0.625h2c0.2071,0,0.375-0.1679,0.375-0.375
            S7.2071,9.625,7,9.625z"
            />
            <path
              d="M13.5,15.625h-3c-0.2071,0-0.375,0.1679-0.375,0.375c0,0.2071,0.1679,0.375,0.375,0.375h3
            c0.2071,0,0.375-0.1679,0.375-0.375C13.875,15.7929,13.7071,15.625,13.5,15.625z"
            />
            <path
              d="M13.5,17.625h-3c-0.2071,0-0.375,0.1679-0.375,0.375s0.1679,0.375,0.375,0.375h3c0.2071,0,0.375-0.1679,0.375-0.375
            S13.7071,17.625,13.5,17.625z"
            />
            <path
              d="M18.5,15.625H17c-0.2071,0-0.375,0.1679-0.375,0.375c0,0.2071,0.1679,0.375,0.375,0.375h1.5
            c0.2071,0,0.375-0.1679,0.375-0.375C18.875,15.7929,18.7071,15.625,18.5,15.625z"
            />
            <path
              d="M7,15.625H5.5c-0.2071,0-0.375,0.1679-0.375,0.375c0,0.2071,0.1679,0.375,0.375,0.375H7
            c0.2071,0,0.375-0.1679,0.375-0.375C7.375,15.7929,7.2071,15.625,7,15.625z"
            />
            <path
              d="M19,11.625h-2c-0.2071,0-0.375,0.1679-0.375,0.375s0.1679,0.375,0.375,0.375h2c0.345,0.0003,0.6247,0.28,0.625,0.625v7
            c0,0.2071,0.1679,0.375,0.375,0.375s0.375-0.1679,0.375-0.375v-7C20.3739,12.2411,19.7589,11.6261,19,11.625z"
            />
            <path
              d="M18.5,17.625H17c-0.2071,0-0.375,0.1679-0.375,0.375s0.1679,0.375,0.375,0.375h1.5c0.2071,0,0.375-0.1679,0.375-0.375
            S18.7071,17.625,18.5,17.625z"
            />
            <path
              d="M7,17.625H5.5c-0.2071,0-0.375,0.1679-0.375,0.375s0.1679,0.375,0.375,0.375H7c0.2071,0,0.375-0.1679,0.375-0.375
            S7.2071,17.625,7,17.625z"
            />
            <path
              d="M13.5,7.625h-3c-0.2071,0-0.375,0.1679-0.375,0.375s0.1679,0.375,0.375,0.375h3c0.2071,0,0.375-0.1679,0.375-0.375
            S13.7071,7.625,13.5,7.625z"
            />
            <path
              d="M7,11.625H5.5c-0.2071,0-0.375,0.1679-0.375,0.375s0.1679,0.375,0.375,0.375H7c0.2071,0,0.375-0.1679,0.375-0.375
            S7.2071,11.625,7,11.625z"
            />
            <path
              d="M7,13.625H5.5c-0.2071,0-0.375,0.1679-0.375,0.375s0.1679,0.375,0.375,0.375H7c0.2071,0,0.375-0.1679,0.375-0.375
            S7.2071,13.625,7,13.625z"
            />
            <path
              d="M18.5,13.625H17c-0.2071,0-0.375,0.1679-0.375,0.375s0.1679,0.375,0.375,0.375h1.5c0.2071,0,0.375-0.1679,0.375-0.375
            S18.7071,13.625,18.5,13.625z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
