import React from 'react';

const ShuttleIcon = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg viewBox="0 0 44 44" focusable="false" {...props}>
      <g fill="none" fillRule="evenodd" stroke="currentColor">
        <path
          d="M38.4 32.976h.669c.77 0 1.871-.415 1.871-1.673v-4.006c0-.478-.088-.952-.26-1.399 0 0-.885-2.377-1.616-4.204-.762-1.903-1.832-2.436-3.865-2.436H19.05c-1.374 0-2.5 1.096-2.5 2.436v10.368a.93.93 0 0 0 .94.915h1.602m5.08 0l9.147-.001"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <path
          d="M38.4 32.977a2.54 2.54 0 1 1-5.081 0 2.54 2.54 0 0 1 5.08 0zM24.172 32.977a2.54 2.54 0 1 1-5.08 0 2.54 2.54 0 0 1 5.08 0z"
          strokeWidth="1.5"
        />
        <path
          d="M22.991 25.863h-3.228a1.18 1.18 0 0 1-1.18-1.18V22.47c0-.651.528-1.18 1.18-1.18h3.228c.652 0 1.18.529 1.18 1.18v2.213a1.18 1.18 0 0 1-1.18 1.18zM31.121 25.863h-3.737a1.18 1.18 0 0 1-1.18-1.18V22.47c0-.651.528-1.18 1.18-1.18h3.737c.652 0 1.18.529 1.18 1.18v2.213a1.18 1.18 0 0 1-1.18 1.18zM40.42 25.863h-4.905a1.18 1.18 0 0 1-1.18-1.18V22.47c0-.651.528-1.18 1.18-1.18h3.2"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <path d="M36.948 9.617c-.486.954-1.298 1.467-2.294 1.792-4.742 1.544-9.476 3.116-14.218 4.661-.31.102-.67.121-.996.087-1.306-.136-2.608-.305-3.91-.47-.176-.023-.344-.098-.517-.148a29.015 29.015 0 0 0-.01-.082l7.073-2.455-3.452-4.15c.265-.284.512-.562.778-.82.049-.046.19-.037.27-.006 1.11.437 2.217.882 3.325 1.325 1.454.58 2.906 1.162 4.363 1.733.115.045.273.05.39.01 2.217-.729 4.43-1.468 6.646-2.204.608-.202 1.223-.202 1.842-.058.296.069.546.206.71.473v.312z" />
        <path d="M13.066 13.043c.17-.143.332-.296.511-.425.07-.05.199-.09.265-.058 1.108.527 2.21 1.066 3.313 1.604.014.006.021.026.05.064-.118.043-.226.084-.336.123-.627.222-1.256.438-1.88.668-.154.057-.246.036-.353-.092-.448-.538-.907-1.067-1.366-1.597-.06-.069-.136-.122-.204-.183v-.104z" />
        <path
          d="M13.787 25h-2.794M13.755 28H7.573M13.755 31H3.508"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
      </g>
    </svg>
  );
};

export default ShuttleIcon;
