import {
  MediaBlocksMediaLayoutsInterface,
  MediaBlocksSingleImageInterface,
  MediaBlocksTwoHorizontalInterface,
  MediaBlocksTwoVerticalInterface,
  MediaBlocksThreeOneTopTwoBottomInterface,
  MediaBlocksThreeTwoTopOneBottomInterface,
  MediaBlocksThreeOneLeftTwoRightInterface,
  MediaBlocksThreeTwoLeftOneRightInterface,
  MediaBlocksFourImageInterface,
  MediaBlocksVideoInterface,
} from './interfaces';
import { MediaBlocksSingleImage } from './single-image';
import { MediaBlocksTwoHorizontal } from './two-horizontal';
import { MediaBlocksTwoVertical } from './two-vertical';
import { MediaBlocksThreeOneTopTwoBottom } from './three-one-top-two-bottom';
import { MediaBlocksThreeTwoTopOneBottom } from './three-two-top-one-bottom';
import { MediaBlocksThreeOneLeftTwoRight } from './three-one-left-two-right';
import { MediaBlocksThreeTwoLeftOneRight } from './three-two-left-one-right';
import { MediaBlocksFourImage } from './four-image';
import { MediaBlocksVideo } from './video';

export function mediaBlocksFactory(
  mediaBlocksData: MediaBlocksMediaLayoutsInterface,
  maxImageWidth: number | undefined = undefined
) {
  let result = null;
  mediaBlocksData?.mediaBlockLayouts?.mediaLayout?.every((mediaLayout) => {
    switch (mediaLayout?.fieldGroupName) {
      case 'Media_block_Mediablocklayouts_MediaLayout_SingleImage':
        result = (
          <MediaBlocksSingleImage
            maxImageWidth={maxImageWidth}
            {...(mediaLayout as MediaBlocksSingleImageInterface)}
          />
        );
        break;
      case 'Media_block_Mediablocklayouts_MediaLayout_TwoImageHoz':
        result = (
          <MediaBlocksTwoHorizontal
            maxImageWidth={maxImageWidth}
            {...(mediaLayout as MediaBlocksTwoHorizontalInterface)}
          />
        );
        break;
      case 'Media_block_Mediablocklayouts_MediaLayout_TwoImageVert':
        result = (
          <MediaBlocksTwoVertical
            {...(mediaLayout as MediaBlocksTwoVerticalInterface)}
          />
        );
        break;
      case 'Media_block_Mediablocklayouts_MediaLayout_ThreeImageOneTopTwoBottom':
        result = (
          <MediaBlocksThreeOneTopTwoBottom
            {...(mediaLayout as MediaBlocksThreeOneTopTwoBottomInterface)}
          />
        );
        break;
      case 'Media_block_Mediablocklayouts_MediaLayout_ThreeImageTwoTopOneBottom':
        result = (
          <MediaBlocksThreeTwoTopOneBottom
            {...(mediaLayout as MediaBlocksThreeTwoTopOneBottomInterface)}
          />
        );
        break;
      case 'Media_block_Mediablocklayouts_MediaLayout_ThreeImageOneLeftAndTwoRight':
        result = (
          <MediaBlocksThreeOneLeftTwoRight
            {...(mediaLayout as MediaBlocksThreeOneLeftTwoRightInterface)}
          />
        );
        break;
      case 'Media_block_Mediablocklayouts_MediaLayout_ThreeImageTwoLeftAndOneRight':
        result = (
          <MediaBlocksThreeTwoLeftOneRight
            {...(mediaLayout as MediaBlocksThreeTwoLeftOneRightInterface)}
          />
        );
        break;
      case 'Media_block_Mediablocklayouts_MediaLayout_FourImage':
        result = (
          <MediaBlocksFourImage
            maxImageWidth={maxImageWidth}
            {...(mediaLayout as MediaBlocksFourImageInterface)}
          />
        );
        break;
      case 'Media_block_Mediablocklayouts_MediaLayout_Video':
        result = (
          <MediaBlocksVideo {...(mediaLayout as MediaBlocksVideoInterface)} />
        );
        break;
      default:
        return false;
    }
  });
  return result;
}
