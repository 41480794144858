import dynamic from 'next/dynamic';

const HotelLogo = dynamic<{ className: string; fillColor: string }>(() =>
  import('./sinodci/hotel-logo').then((i) => i.HotelLogo)
);

export const hotelLogoHeader = (
  <HotelLogo
    className="fill-black text-bg antialiased w-30 md:w-32 md:mr-5 h-20"
    fillColor="#A98B5F"
  />
);

export const hotelLogoFooter = (
  <HotelLogo
    className="fill-black text-bg antialiased w-40 md:w-32 h-20"
    fillColor="#000"
  />
);
