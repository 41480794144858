import dynamic from 'next/dynamic';

const HotelLogo = dynamic<{ className: string; fillColor: string }>(() =>
  import('./mcobuhh/hotel-logo').then((i) => i.HotelLogo)
);

export const hotelLogoHeader = (
  <HotelLogo className="text-bg antialiased w-32 h-20" fillColor="#0a3971" />
);

export const hotelLogoFooter = (
  <HotelLogo className="text-bg antialiased w-32 h-20" fillColor="#fff" />
);
