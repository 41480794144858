import React from 'react';
export function Wedding({
  className,
  fillColor,
}: {
  className?: string;
  fillColor?: string;
}) {
  return (
    <svg className={className} viewBox="0 0 24 24" style={{ fill: fillColor }}>
      <g data-name="Bounding Box">
        <path style={{ fill: 'none' }} d="M0,0V24H24V0Z" />
      </g>
      <g data-name="Icons Outlined">
        <g>
          <path d="M10.6777,14.0322a.3756.3756,0,0,0-.5078.1524l-.5166.96L3.8008,12.001A2.6629,2.6629,0,0,1,2.6787,8.415,2.5783,2.5783,0,0,1,4.2471,7.1562a2.6991,2.6991,0,0,1,2.0381.2168l.9013.4854a.3731.3731,0,0,0,.2862.0293.3793.3793,0,0,0,.2216-.1816l.4844-.9024a2.7014,2.7014,0,0,1,1.58-1.3076,2.5909,2.5909,0,0,1,2.0019.1836,2.6653,2.6653,0,0,1,1.0381,3.6152L11.48,11.8271a.3752.3752,0,0,0,.666.3458l1.3164-2.5274A3.4143,3.4143,0,0,0,12.1162,5.02a3.3127,3.3127,0,0,0-2.58-.2392A3.4445,3.4445,0,0,0,7.5186,6.4482l-.3067.5723-.5713-.3076a3.4441,3.4441,0,0,0-2.6025-.2774A3.3217,3.3217,0,0,0,2.0186,8.06a3.4126,3.4126,0,0,0,1.4267,4.6006l6.1826,3.3213a.3746.3746,0,0,0,.5078-.1523l.6944-1.29A.374.374,0,0,0,10.6777,14.0322Z" />
          <path d="M21.9814,11.1846a3.409,3.409,0,0,0-4.622-1.3467l-.5713.3076-.3067-.5723a3.4382,3.4382,0,0,0-1.57-1.4931.3748.3748,0,1,0-.32.6777,2.7029,2.7029,0,0,1,1.2305,1.17l.4844.9034a.3729.3729,0,0,0,.5078.1533l.9013-.4864a2.627,2.627,0,1,1,2.4844,4.628L14.3467,18.27,11.1982,12.415a2.6667,2.6667,0,0,1,1.0625-3.6211l-.1543-.3427-.2226-.3067a3.4135,3.4135,0,0,0-1.3457,4.626l3.3262,6.1846a.3746.3746,0,0,0,.5078.1523l6.1826-3.3213A3.4134,3.4134,0,0,0,21.9814,11.1846Z" />
        </g>
      </g>
    </svg>
  );
}
