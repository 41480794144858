import React from 'react';
import { GenericModal } from '../global/generic-modal';
import Markdown from 'markdown-to-jsx';
import { IalertsData } from './alert';

export function AlertModal({
  alert,
  onClose,
  languageDirection,
  modalSettings,
  modalActive,
}: {
  alert: IalertsData;
  onClose(): void;
  languageDirection: string;
  modalSettings?: any;
  modalActive: boolean;
}) {
  return (
    <GenericModal
      modalTitle={alert?.headline || ''}
      setModalActive={() => onClose()}
      modalActive={modalActive}
      modalChildStyles="bg-white"
      modalStyles="justify-center pt-12"
      modalChild={
        <div className="pb-8" dir={languageDirection}>
          <header className="px-8 border-b pt-4 pb-2 text-center text-lg font-bold mb-4">
            {alert?.headline}
          </header>
          <div className="px-8">
            <Markdown>{alert?.description}</Markdown>
          </div>

          {alert?.link && (
            <div className="pt-8 text-center">
              <a
                href={alert?.link?.url}
                target={alert?.link?.isNewWindow ? '_blank' : '_self'}
                className="btn btn-primary-outline"
                rel="noreferrer"
              >
                {alert?.link?.label}
              </a>
            </div>
          )}
        </div>
      }
      modalSettings={modalSettings}
    />
  );
}

export default AlertModal;
