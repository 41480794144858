import { ImageModalImage } from '@curated-property/shared-components';
import { WACCalendarCellProps } from 'libs/shared-components/src/lib/wedding-availability-calendar/calendar';

type ChunkItem = ImageModalImage[] | React.FC<WACCalendarCellProps>[];

export function chunkArray(items: ChunkItem, chunkSize: number) {
  const chunks = [];
  let i = 0;
  while (i < items?.length) {
    chunks.push(items?.slice(i, (i += chunkSize)));
  }
  return chunks;
}
