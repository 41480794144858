import dynamic from 'next/dynamic';

const HotelLogo = dynamic<{ className: string }>(() =>
  import('./bjvlxol/hotel-logo').then((i) => i.HotelLogo)
);

const FooterLogo = dynamic<{ className: string }>(() =>
  import('./bjvlxol/footer-logo').then((i) => i.FooterLogo)
);

export const hotelLogoHeader = (
  <HotelLogo className="text-bg antialiased h-20" />
);

export const hotelLogoFooter = (
  <FooterLogo className="fill-current text-bg antialiased h-24" />
);
