import React from 'react';
export function HelpSquare({
  className,
  fillColor,
}: {
  className?: string;
  fillColor?: string;
}) {
  return (
    <svg className={className} viewBox="0 0 24 24" style={{ fill: fillColor }}>
      <g data-name="Bounding Box">
        <path style={{ fill: 'none' }} d="M0,0V24H24V0Z" />
      </g>
      <g data-name="Icons Outlined">
        <g>
          <path d="M17,4.625H7A2.3777,2.3777,0,0,0,4.625,7V17A2.3777,2.3777,0,0,0,7,19.375H17A2.3777,2.3777,0,0,0,19.375,17V7A2.3777,2.3777,0,0,0,17,4.625ZM18.625,17A1.6262,1.6262,0,0,1,17,18.625H7A1.6262,1.6262,0,0,1,5.375,17V7A1.6262,1.6262,0,0,1,7,5.375H17A1.6262,1.6262,0,0,1,18.625,7Z" />
          <path d="M12,7.625A2.83,2.83,0,0,0,9.125,10.4a.375.375,0,0,0,.75,0,2.1274,2.1274,0,0,1,4.25,0,1.74,1.74,0,0,1-1.0371,1.4619A2.4828,2.4828,0,0,0,11.625,14a.3727.3727,0,0,0,.3721.373H12a.3753.3753,0,0,0,.375-.37,1.7408,1.7408,0,0,1,1.0371-1.4648A2.4837,2.4837,0,0,0,14.875,10.4,2.83,2.83,0,0,0,12,7.625Z" />
          <path d="M12,15.375a.625.625,0,1,0,.625.625A.6256.6256,0,0,0,12,15.375Z" />
        </g>
      </g>
    </svg>
  );
}
