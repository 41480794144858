import React from 'react';
export function IndoorPool({
  className,
  fillColor,
}: {
  className?: string;
  fillColor?: string;
}) {
  return (
    <svg className={className} viewBox="0 0 24 24" style={{ fill: fillColor }}>
      <g data-name="Bounding Box">
        <path style={{ fill: 'none' }} d="M0,0V24H24V0Z" />
      </g>
      <g data-name="Icons Outlined">
        <g>
          <path d="M21,17.2793a.3751.3751,0,0,0,.375-.375V10.168a.3746.3746,0,0,0-.1885-.3252l-9.0117-5.168a.3712.3712,0,0,0-.374.001L2.8125,9.8818a.3734.3734,0,0,0-.1875.3243v6.7207a.3736.3736,0,0,0,.1338.2871,9.1194,9.1194,0,0,0,5.64,1.6777,16.5061,16.5061,0,0,0,4.2266-.6895,14.5333,14.5333,0,0,1,3.6465-.6171,7.7608,7.7608,0,0,1,4.4844,1.6992.375.375,0,0,0,.4892-.5684,8.3914,8.3914,0,0,0-4.9736-1.8808c-.2643,0-.5235.0213-.7822.044a1.9373,1.9373,0,0,0-.3047-1.7179c-.1963-.34-.8174-1.3007-1.4756-2.3193-.7178-1.11-1.459-2.2578-1.7666-2.7783a1.2458,1.2458,0,0,0-1.8574-.5323c-.0928.0489-.3145.16-.6153.3106-.9218.4619-2.64,1.3223-3.6689,1.916a1.205,1.205,0,0,0-.6719,1.5342,1.1381,1.1381,0,0,0,1.5234.4424c.1866-.07.6543-.2949,1.4-.6563a21.9909,21.9909,0,0,1,2.1455-.9629.5044.5044,0,0,1,.1407.26l.5595,1.2236L6.8428,15.9863a.3752.3752,0,0,0,.3808.6465l4.3467-2.56a.3755.3755,0,0,0,.15-.4794l-.6768-1.4629a1.1141,1.1141,0,0,0-.48-.6709.8215.8215,0,0,0-.6768-.0508c-.3017.0986-1.0859.4746-2.16.9941-.5772.2783-1.1739.5674-1.34.63-.3222.1211-.5254.0537-.58-.0615-.0654-.1377.0411-.375.3692-.5635,1.01-.583,2.7148-1.4375,3.63-1.8965.3086-.1533.5351-.2676.6308-.3183.378-.2022.586-.2129.8594.2509.3154.5332,1.0615,1.6885,1.7832,2.8047.6523,1.01,1.2695,1.9639,1.4551,2.2862.3542.6155.4652.9737-.0217,1.4716-.7072.1239-1.398.2952-2.074.4688a15.8844,15.8844,0,0,1-4.04.666A8.862,8.862,0,0,1,3.375,16.7393V10.4219l8.6143-4.9893,8.6357,4.9522v6.5195A.3751.3751,0,0,0,21,17.2793Z" />
          <path d="M14.625,12.499A1.875,1.875,0,1,0,16.5,10.625,1.8776,1.8776,0,0,0,14.625,12.499Zm3,0A1.125,1.125,0,1,1,16.5,11.375,1.1268,1.1268,0,0,1,17.625,12.499Z" />
        </g>
      </g>
    </svg>
  );
}
