import React from 'react';
export function Bath({
  className,
  fillColor,
}: {
  className?: string;
  fillColor?: string;
}) {
  return (
    <svg className={className} viewBox="0 0 24 24" style={{ fill: fillColor }}>
      <g data-name="Bounding Box">
        <path style={{ fill: 'none' }} d="M0,0V24H24V0Z" />
      </g>
      <g data-name="Icons Outlined">
        <g>
          <path d="M13.5,7.375A1.375,1.375,0,1,0,12.125,6,1.377,1.377,0,0,0,13.5,7.375Zm0-2A.625.625,0,1,1,12.875,6,.6256.6256,0,0,1,13.5,5.375Z" />
          <path d="M9,6.625A.625.625,0,1,0,8.375,6,.6256.6256,0,0,0,9,6.625Z" />
          <path d="M11,7.625A.625.625,0,1,0,10.375,7,.6256.6256,0,0,0,11,7.625Z" />
          <path d="M19,13.625a.3751.3751,0,0,0-.375.375v1A2.6281,2.6281,0,0,1,16,17.625H8A2.6281,2.6281,0,0,1,5.375,15V14a.375.375,0,0,0-.75,0v1a3.3674,3.3674,0,0,0,3,3.3371V19a.375.375,0,0,0,.75,0v-.625h7.25V19a.375.375,0,0,0,.75,0v-.6629a3.3674,3.3674,0,0,0,3-3.3371V14A.3751.3751,0,0,0,19,13.625Z" />
          <path d="M8,15.375h4A.3751.3751,0,0,0,12.375,15V10A.6256.6256,0,0,1,13,9.375a.375.375,0,0,0,0-.75H9A1.377,1.377,0,0,0,7.625,10v5A.3751.3751,0,0,0,8,15.375ZM8.375,10A.6256.6256,0,0,1,9,9.375h2.7825A1.3563,1.3563,0,0,0,11.625,10v2.625H8.375Zm0,3.375h3.25v1.25H8.375Z" />
          <path d="M19,9.625h-.6221V8a1.375,1.375,0,0,0-2.75,0,.375.375,0,0,0,.75,0,.625.625,0,0,1,1.25,0V9.625H14a.375.375,0,0,0,0,.75h5a.625.625,0,0,1,0,1.25H14a.375.375,0,0,0,0,.75h5a1.375,1.375,0,0,0,0-2.75Z" />
          <path d="M6,12.375a.375.375,0,0,0,0-.75H5a.625.625,0,0,1,0-1.25H6a.375.375,0,0,0,0-.75H5a1.375,1.375,0,0,0,0,2.75Z" />
        </g>
      </g>
    </svg>
  );
}
