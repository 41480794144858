import dynamic from 'next/dynamic';

const HotelLogo = dynamic<{ className?: string; fillColor?: string }>(() =>
  import('./axazbol/hotel-logo').then((i) => i.HotelLogo)
);

export const hotelLogoHeader = (
  <HotelLogo className="text-bg antialiased h-16" fillColor="#000000" />
);

export const hotelLogoFooter = (
  <HotelLogo className="text-bg antialiased h-20" fillColor="#FFFFFF" />
);
