import React from 'react';
export function Firearms({
  className,
  fillColor,
}: {
  className?: string;
  fillColor?: string;
}) {
  return (
    <svg
      className={className}
      style={{ fill: fillColor }}
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 24 24"
    >
      <g>
        <g>
          <path style={{ fill: 'none' }} d="M0,0v24h24V0H0z" />
        </g>
        <g>
          <g>
            <path
              d="M19.375,10c0-2.3477-1.3193-2.375-1.375-2.375H4.375V7c0-0.2071-0.1679-0.375-0.375-0.375
            C3.7929,6.625,3.625,6.7929,3.625,7v3C3.6251,10.2071,3.7929,10.3749,4,10.375h15C19.2071,10.3749,19.3749,10.2071,19.375,10z
             M4.375,9.625v-1.25h13.61c0.1972,0.0166,0.5507,0.3076,0.6259,1.25H4.375z"
            />
            <path
              d="M19.375,12c-0.0199-0.3252,0.2276-0.6051,0.5528-0.625c0.024-0.0015,0.0482-0.0015,0.0722,0
            c0.2071,0,0.375-0.1679,0.375-0.375S20.2071,10.625,20,10.625c-0.7378-0.0216-1.3534,0.5589-1.375,1.2967
            c-0.0008,0.0261-0.0008,0.0522,0,0.0783c0.0258,0.4222,0.1198,0.8374,0.2783,1.2295c0.4855,1.4141,0.7268,2.9005,0.7138,4.3955
            h-3.3813c0.0947-0.1946,0.1424-0.4086,0.1392-0.625c0.0275-1.7773-0.3271-3.5397-1.04-5.168
            c-0.0635-0.1268-0.1932-0.2069-0.335-0.207H5.375V11c0-0.2071-0.1679-0.375-0.375-0.375S4.625,10.7929,4.625,11v1
            C4.6251,12.2071,4.7929,12.3749,5,12.375h5.625v0.6562c-0.0121,0.7306,0.5704,1.3326,1.301,1.3446
            c0.0247,0.0004,0.0494,0.0001,0.074-0.0008h2c0.2071,0,0.375-0.1679,0.375-0.375S14.2071,13.625,14,13.625h-1.9971
            c-0.3192,0.0266-0.5995-0.2107-0.6261-0.5299c-0.0018-0.0213-0.0024-0.0426-0.0018-0.0639V12.375h2.075
            c-0.1166,0.1629-0.307,0.2568-0.5072,0.25c-0.2071,0-0.375,0.1679-0.375,0.375s0.1679,0.375,0.375,0.375
            c0.624,0.0092,1.177-0.4004,1.35-1h0.4647c0.5826,1.4719,0.8771,3.042,0.8675,4.625c-0.0047,0.3517-0.2935,0.633-0.6452,0.6283
            c-0.019-0.0003-0.038-0.0014-0.0569-0.0033c-0.2071,0-0.375,0.1679-0.375,0.375s0.1679,0.375,0.375,0.375l0,0H20
            c0.2071-0.0001,0.3749-0.1679,0.375-0.375c0.0519-1.7015-0.2056-3.3983-0.76-5.0078C19.4853,12.6755,19.4044,12.341,19.375,12z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
