import React from 'react';
export function Luxury({
  className,
  fillColor,
}: {
  className?: string;
  fillColor?: string;
}) {
  return (
    <svg className={className} viewBox="0 0 24 24" style={{ fill: fillColor }}>
      <g data-name="Bounding Box">
        <path style={{ fill: 'none' }} d="M0,0V24H24V0Z" />
      </g>
      <g data-name="Icons Outlined">
        <g>
          <path d="M21.3213,8.8125a.3911.3911,0,0,0-.335-.1982c-2.4033-.5528-4.1133-.1211-4.8174,1.2109a.3736.3736,0,0,0,.0674.4414,1.4221,1.4221,0,0,1,.1973,1.3525,2.6744,2.6744,0,0,1-1.4746,1.8291.6622.6622,0,0,1-.6953-.2148,1.3469,1.3469,0,0,1-.4248-1.06A4.5867,4.5867,0,0,1,15.74,9.2881a.3753.3753,0,0,0,.1026-.44.3691.3691,0,0,0-.3965-.2188c-1.1553.169-1.2412-.1914-1.4531-1.1484A3.7973,3.7973,0,0,0,12.19,4.6768a.379.379,0,0,0-.3808,0A3.8566,3.8566,0,0,0,9.99,7.499C9.7734,8.4561,9.69,8.8105,8.56,8.63a.3827.3827,0,0,0-.4.2139.3753.3753,0,0,0,.1006.4443,4.5963,4.5963,0,0,1,1.9218,2.8994,1.3191,1.3191,0,0,1-.4453,1.0459c-.4824.4756-1.0058.1475-1.3633-.2148-.7548-.7637-1.1845-2.1836-.6093-2.752a.3757.3757,0,0,0,.0644-.4482C6.6006,7.5957,3.0469,8.5977,2.8955,8.64a.3756.3756,0,0,0-.2139.56l5,8A.3774.3774,0,0,0,8,17.375h8a.3774.3774,0,0,0,.3184-.1758l5-8A.3856.3856,0,0,0,21.3213,8.8125ZM15.792,16.625H8.208L3.5986,9.25c.8662-.1621,2.6436-.334,3.4385.7236-.6885,1.0254-.0478,2.7119.8027,3.5723a1.6053,1.6053,0,0,0,2.419.2256,2.0787,2.0787,0,0,0,.6728-1.6084,4.4538,4.4538,0,0,0-1.47-2.7852c.9141-.1914,1.0957-.9892,1.26-1.7129A3.2051,3.2051,0,0,1,12,5.4443a3.1431,3.1431,0,0,1,1.2617,2.1983c.1612.7285.3389,1.5332,1.2871,1.7265a4.485,4.485,0,0,0-1.459,2.7725,2.1091,2.1091,0,0,0,.6426,1.6211,1.4219,1.4219,0,0,0,1.417.4111,3.3586,3.3586,0,0,0,2.0088-2.3613A2.2013,2.2013,0,0,0,16.96,9.9688c.7168-.99,2.3056-.918,3.4365-.711Z" />
          <path d="M15.6553,18.625H8.1621a.375.375,0,1,0,0,.75h7.4932a.375.375,0,0,0,0-.75Z" />
        </g>
      </g>
    </svg>
  );
}
