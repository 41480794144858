import React, { useState, useEffect, useRef } from 'react';
import { slugify, iconmapper } from '../functions/helper';
import { useTranslation } from 'next-i18next';
import cx from 'classnames';
import { Caret, LocationBlank } from '@curated-property/icon-list';
import { PinContent } from './interactive-map.types';
import { camelCase } from 'lodash';

export function MapPin({
  id,
  pinColor,
  pinAccentColor,
  pinTitle,
  pinX,
  pinY,
  pinCategory,
  pinIcon,
  pinModal,
  selectedCategory,
  openModal,
  modalContent,
  modalDescription,
  modalImage,
  modalLink,
  modalLink2,
  modalLinkText,
  pinActive,
  setPinActive,
  boxHeight,
  boxWidth,
}: PinContent) {
  const [pinInfoOffsetLeft, setPinInfoOffsetLeft] = useState<number | null>(
    null
  );
  const [pinInfoOffsetRight, setPinInfoOffsetRight] = useState<number | null>(
    null
  );
  pinIcon = pinIcon || 'hotel';
  const iconMap = iconmapper();
  const { t } = useTranslation();
  const Icon = iconMap[pinIcon as keyof typeof iconMap];

  const pinRef = useRef<HTMLButtonElement>(null);
  const pinInfoRef = useRef<HTMLDivElement>(null);
  const showPinTextAbove: boolean =
    (pinRef?.current && boxHeight - pinRef.current?.offsetTop < 100) || false;

  useEffect(() => {
    const offsetLeftAmount =
      (pinInfoRef?.current?.offsetWidth || 0) / 2 +
      15 +
      (pinRef?.current?.offsetLeft || 0);
    const offsetRightAmount =
      (pinInfoRef?.current?.offsetWidth || 0) / 2 -
      5 -
      (pinRef?.current?.offsetLeft || 0);
    setPinInfoOffsetLeft(
      offsetLeftAmount - boxWidth > 0 ? offsetLeftAmount - boxWidth : null
    );
    setPinInfoOffsetRight(offsetRightAmount > 0 ? offsetRightAmount : null);
  }, [
    setPinInfoOffsetLeft,
    setPinInfoOffsetRight,
    pinInfoRef,
    pinRef,
    boxWidth,
    pinActive,
  ]);

  return (
    <button
      data-cy={camelCase(pinCategory + 'MapPin')}
      data-testid={`${slugify(pinTitle)}-pin`}
      className={cx(
        'text-white absolute whitespace-nowrap cursor-pointer transition-all duration-100 ease-in-out z-10 -translate-x-1.5 -translate-y-full',
        pinActive?.id === id ? 'z-20' : '',
        selectedCategory
          ? 'visible opacity-1'
          : 'invisible opacity-0 -translate-y-3/4'
      )}
      style={{
        top: pinX + `%`,
        left: pinY + `%`,
      }}
      onMouseEnter={() => setPinActive({ id: id })}
      onMouseLeave={() => setPinActive({ id: undefined })}
      onMouseUp={(e) => {
        pinModal && openModal();
        pinModal &&
          modalContent({
            id: id,
            title: pinTitle,
            image: modalImage,
            description: modalDescription,
            link: modalLink,
            link2: modalLink2,
            linkText: modalLinkText,
          });
      }}
      onTouchEnd={(e) => {
        if (pinModal) {
          openModal();
          modalContent({
            id: id,
            title: pinTitle,
            image: modalImage,
            description: modalDescription,
            link: modalLink,
            link2: modalLink2,
            linkText: modalLinkText,
          });
          setPinActive({ id: undefined });
        } else if (pinActive?.id === id) {
          setPinActive({ id: undefined });
        } else setPinActive({ id: id });
      }}
      aria-label={`${pinModal ? t('openModal') + ' ' : ''}${pinTitle}`}
      ref={pinRef}
    >
      <div className="relative flex items-center justify-center">
        <Icon
          className="absolute top-1.5 h-6 fill-current"
          fillColor={pinAccentColor}
        />
        <LocationBlank className="h-12 fill-primary" fillColor={pinColor} />
      </div>
      {pinActive?.id === id ? (
        <div
          data-cy="hoveredPin"
          data-testid={`${slugify(pinTitle)}-pin-info`}
          className={cx(
            'absolute z-20 flex flex-col left-1/2 transform -translate-x-1/2',
            {
              'bottom-[calc(100%+45px)]': showPinTextAbove,
              'top-[calc(100%+2px)]': !showPinTextAbove,
            }
          )}
        >
          <Caret
            className={cx('h-2 transform rotate-180 translate-y-0.5', {
              '!rotate-0 translate-y-[39px]': showPinTextAbove,
            })}
            fillColor={'#fff'}
          />
          <span
            ref={pinInfoRef}
            className="absolute text-text bg-bg h-8 left-1/2 transform -translate-x-1/2 px-4 flex items-center justify-center rounded top-2"
            style={{
              left: pinInfoOffsetLeft
                ? `-${pinInfoOffsetLeft}px`
                : pinInfoOffsetRight
                ? `${pinInfoOffsetRight}px`
                : '',
            }}
          >
            {pinTitle}
          </span>
        </div>
      ) : null}
    </button>
  );
}
