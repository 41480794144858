import React from 'react';
export function Champagne({
  className,
  fillColor,
}: {
  className?: string;
  fillColor?: string;
}) {
  return (
    <svg className={className} viewBox="0 0 24 24" style={{ fill: fillColor }}>
      <g data-name="Bounding Box">
        <path style={{ fill: 'none' }} d="M0,0V24H24V0Z" />
      </g>
      <g data-name="Icons Outlined">
        <g>
          <path d="M12,3.625A.3751.3751,0,0,0,11.625,4V5.1377a.375.375,0,0,0,.75,0V4A.3751.3751,0,0,0,12,3.625Z" />
          <path d="M13.376,5.86a.3744.3744,0,0,0,.52-.1055l.6338-.958a.3749.3749,0,1,0-.625-.4141l-.6338.958A.3746.3746,0,0,0,13.376,5.86Z" />
          <path d="M10.1045,5.7549a.3749.3749,0,1,0,.625-.4141l-.6338-.958a.3749.3749,0,1,0-.625.4141Z" />
          <path d="M11.2871,7.0479,7.624,5.8623a.37.37,0,0,0-.45.1885L3.83,12.7207a3.3578,3.3578,0,0,0-.0889,2.7852A3.1848,3.1848,0,0,0,5.38,17.0765l-.6288,1.8113-1.6362-.5294a.3746.3746,0,0,0-.23.7129l3.9716,1.2851a.3649.3649,0,0,0,.1153.0186.3748.3748,0,0,0,.1152-.7314L5.4656,19.119l.626-1.8033a3.5228,3.5228,0,0,0,.7365.0886,3.2294,3.2294,0,0,0,1.5606-.3916,3.4686,3.4686,0,0,0,1.6562-2.2647l.0039-.0185,1.49-7.25A.3743.3743,0,0,0,11.2871,7.0479ZM10.3954,9.32H7.09a.375.375,0,0,0,0,.75h3.1515l-.9249,4.499a2.737,2.737,0,0,1-1.2891,1.7862,2.6227,2.6227,0,0,1-2.0634.1611l-.0186-.0059a2.4627,2.4627,0,0,1-1.5195-1.3115,2.621,2.621,0,0,1,.082-2.1592L7.6992,6.6748l3.0381.9824Z" />
          <path d="M6.4072,11.8018a.6263.6263,0,0,0,.3946.791.64.64,0,0,0,.1992.0332.6259.6259,0,1,0-.5938-.8242Z" />
          <path d="M21.3564,18.6a.3725.3725,0,0,0-.4716-.2412l-1.6362.5294L18.62,17.0764a3.1884,3.1884,0,0,0,1.639-1.57,3.37,3.37,0,0,0-.0977-2.8018L16.8262,6.0508a.37.37,0,0,0-.45-.1885L12.7129,7.0479a.3742.3742,0,0,0-.252.4316l1.49,7.25.0039.0185a3.4686,3.4686,0,0,0,1.6562,2.2647,3.2294,3.2294,0,0,0,1.5606.3916,3.5351,3.5351,0,0,0,.7367-.0883l.6258,1.803-1.6213.5246a.3748.3748,0,0,0,.1152.7314.3649.3649,0,0,0,.1153-.0186l3.9716-1.2851A.3733.3733,0,0,0,21.3564,18.6Zm-3.3007-2.0889-.02.0059a2.6227,2.6227,0,0,1-2.0634-.1611,2.737,2.737,0,0,1-1.2891-1.7862L13.2627,7.6572l3.0381-.9824L17.627,9.32H14.5684a.375.375,0,0,0,0,.75H18.003l1.48,2.9531a2.637,2.637,0,0,1,.0908,2.1758A2.4635,2.4635,0,0,1,18.0557,16.5107Z" />
          <path d="M7.375,14A.625.625,0,1,0,8,13.375.6256.6256,0,0,0,7.375,14Z" />
          <path d="M16.8018,12.5928a.64.64,0,0,0,.1992.0332.6289.6289,0,1,0-.1992-.0332Z" />
          <path d="M17,13.375a.625.625,0,1,0,.625.625A.6256.6256,0,0,0,17,13.375Z" />
        </g>
      </g>
    </svg>
  );
}
