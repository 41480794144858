import dynamic from 'next/dynamic';

const HotelLogo = dynamic<{
  className: string;
  fillColor?: string;
}>(() => import('./denirhh/hotel-logo').then((i) => i.HotelLogo));

export const hotelLogoHeader = (
  <HotelLogo className="fill-bg antialiased w-40 h-24" fillColor={'#fffff'} />
);

export const hotelLogoFooter = (
  <HotelLogo className="fill-bg antialiased w-48 h-24" fillColor={'#fffff'} />
);
