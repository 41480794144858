import React from 'react';
export function Spa({
  className,
  fillColor,
}: {
  className?: string;
  fillColor?: string;
}) {
  return (
    <svg className={className} viewBox="0 0 24 24" style={{ fill: fillColor }}>
      <g data-name="Bounding Box">
        <path style={{ fill: 'none' }} d="M0,0V24H24V0Z" />
      </g>
      <g data-name="Icons Outlined">
        <g>
          <path d="M17.1025,10.1738l-4.7851-6.415a.3748.3748,0,0,0-.3-.15h-.001a.3733.3733,0,0,0-.3.15l-4.8008,6.4a6.388,6.388,0,1,0,10.1865.0146Zm-5.0859,9.4346a5.626,5.626,0,0,1-4.501-9l4.5-5.999L16.502,10.623a5.6,5.6,0,0,1-4.4854,8.9854Z" />
          <path d="M13.8721,15.84A2.6247,2.6247,0,0,1,10.16,12.1279a.3757.3757,0,0,0-.5313-.5312,3.376,3.376,0,0,0,4.7744,4.7744.3757.3757,0,1,0-.5312-.5313Z" />
        </g>
      </g>
    </svg>
  );
}
