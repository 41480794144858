import React from 'react';

export function PinIcon({
  className,
  fillColor,
}: {
  className?: string;
  fillColor?: string;
}) {
  return (
    <svg
      className={className}
      fill={fillColor}
      viewBox="0 0 805 1024"
      aria-hidden="true"
    >
      <path d="M402.27 0C179.776 1.518-.004 182.236-.004 404.943c0 .632.001 1.263.004 1.894v-.097C0 545.3 117.552 725.874 168.059 797.389c76.878 109.06 183.256 226.165 234.21 226.165 81.348 0 402.27-402.27 402.27-616.814.003-.534.004-1.165.004-1.797 0-222.707-179.78-403.424-402.129-404.942L402.269 0zm204.71 778.168C505.519 919.409 421.042 990.477 402.269 990.477c-58.106 0-369.194-370.088-369.194-583.738-.002-.401-.002-.876-.002-1.351 0-204.439 164.971-370.348 369.052-371.866l.144-.001c204.226 1.518 369.197 167.427 369.197 371.866 0 .475-.001.95-.003 1.424v-.073c0 89.84-59.893 225.271-164.484 371.429z" />
      <path d="M402.27 198.9h-.443c-111.577 0-202.029 90.451-202.029 202.029s90.451 202.029 202.029 202.029c111.106 0 201.264-89.688 202.024-200.615v-.072c.008-.664.012-1.449.012-2.235 0-111.084-90.051-201.135-201.135-201.135l-.484.001h.025zm0 370.088c-93.059-.005-168.497-75.446-168.497-168.506 0-93.063 75.443-168.506 168.506-168.506s168.506 75.443 168.506 168.506c0 .629-.003 1.256-.01 1.883l.001-.095c-.253 92.378-75.198 167.168-167.612 167.168-.315 0-.629-.001-.943-.003h.048z" />
    </svg>
  );
}
