import dynamic from 'next/dynamic';

const HotelLogo = dynamic<{ className: string; fill?: string }>(() =>
  import('./fllcici/hotel-logo').then((i) => i.HotelLogo)
);

export const hotelLogoHeader = (
  <HotelLogo
    className="fill-current text-bg antialiased w-16 sm:w-24 lg:w-40"
    fill="#B08D59"
  />
);

export const hotelLogoFooter = (
  <HotelLogo className="fill-current text-bg antialiased w-40" fill="#000000" />
);
