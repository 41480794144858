import dynamic from 'next/dynamic';

const HotelLogo = dynamic<{ className: string; fillColor?: string }>(() =>
  import('./cunhihh/hotel-logo').then((i) => i.HotelLogo)
);

export const hotelLogoHeader = (
  <HotelLogo className="fill-bg antialiased w-24 sm:w-28" />
);

export const hotelLogoFooter = (
  <HotelLogo className="fill-bg antialiased w-24 mr-2 sm:mr-0 sm:w-28" />
);
