export function WhatsApp({
  className,
  fillColor,
}: {
  className?: string;
  fillColor?: string;
}) {
  return (
    <svg
      className={className}
      fill={fillColor}
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="m2 22 5.25447301-1.3794817c1.44640241.7901873 3.07586669 1.2053571 4.73658969 1.2053571h.0044905c5.4598127 0 10.0044468-4.4464111 10.0044468-9.9107143 0-2.64731268-1.1250087-5.13392854-2.9955183-7.00892854-1.8705531-1.875-4.3616158-2.90623256-7.0089285-2.90623256-5.4643032 0-9.91071433 4.44641113-9.91071433 9.9107143 0 1.7455183.45532226 3.4508841 1.32141113 4.9553571zm9.9955532-1.8437413c-1.4821516 0-2.93304448-.3973388-4.19642862-1.1473301l-.29911586-.1785715-3.11606271.8169556.8303397-3.0401611-.19644601-.3125c-.82589285-1.3125175-1.25889369-2.8258842-1.25889369-4.3839373 0-4.54018731 3.69641985-8.23660716 8.24105399-8.23660716 2.2008841 0 4.2678397.85715158 5.821446 2.41516114 1.5535627 1.55805315 2.5088937 3.62500871 2.5044623 5.82589282 0 4.5446778-3.7902115 8.2410976-8.3303551 8.2410976zm3.1740897-3.5178484c.4776872-.0714547 1.464277-.5982317 1.6696603-1.1785889.2053397-.5803571.2053397-1.0758754.1428222-1.1785888-.058027-.1116072-.2232143-.174081-.46875-.2946255-.2455357-.1249912-1.464277-.723223-1.6919381-.8035714-.2277047-.0848389-.3928484-.1249913-.5580357.1249913-.1651873.2500262-.6383842.8035714-.7857405.9732056-.1428222.1651873-.2901785.1875087-.5357143.0625174-1.4553397-.7276699-2.4107142-1.2990897-3.37053567-2.9464286-.25442941-.4374913.25447301-.4062325.72771347-1.3526698.0803484-.16518731.0401524-.30805318-.0223214-.43304446-.0312588-.06249564-.1707677-.39842878-.32813486-.78068324l-.15997205-.3892832c-.10615273-.25860014-.20685105-.5041407-.27531215-.66932799-.20089286-.48213414-.40623256-.41516985-.558027-.42410714-.14287458-.00893729-.30801827-.00893729-.47320556-.00893729s-.43304444.06251743-.66070557.30805315c-.22770473.24998256-.86608887.84821428-.86608887 2.06695556 0 1.21874131.8884103 2.39733011 1.00891114 2.56251741.12503487.1651873 1.74556187 2.6651873 4.23217772 3.7410627 1.5713937.6785802 2.1875.7366072 2.9731969.6205532z"
        fill={fillColor ?? '#212121'}
        transform="translate(-2 -2)"
      />
    </svg>
  );
}
