import dynamic from 'next/dynamic';

const HotelLogo = dynamic<{ className?: string; fillColor?: string }>(() =>
  import('./aytakdi/hotel-logo').then((i) => i.HotelLogo)
);

export const hotelLogoHeader = (
  <HotelLogo
    className="text-bg antialiased h-16 md:h-20 lg:h-24"
    fillColor="#09194E"
  />
);

export const hotelLogoFooter = (
  <HotelLogo
    className="text-bg antialiased h-16 md:h-20 mr-2 md:mr-0"
    fillColor="#ffffff"
  />
);
