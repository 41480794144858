import dynamic from 'next/dynamic';

const HotelLogo = dynamic<{ className: string; fillColor: string }>(() =>
  import('./rmfhihi/hotel-logo').then((i) => i.HotelLogo)
);

export const hotelLogoHeader = (
  <HotelLogo className="antialiased w-36 lg:w-40 pr-4" fillColor="#ffffff" />
);

export const hotelLogoFooter = (
  <HotelLogo className="antialiased w-28 md:w-32" fillColor="#ffffff" />
);
