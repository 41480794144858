import dynamic from 'next/dynamic';

const HotelLogo = dynamic<{ className: string; fillColor: string }>(() =>
  import('./sinorhi/hotel-logo').then((i) => i.HotelLogo)
);

export const hotelLogoHeader = (
  <HotelLogo
    className="fill-current text-bg antialiased w-30 md:w-32 md:mr-5 h-20"
    fillColor="#fff"
  />
);

export const hotelLogoFooter = (
  <HotelLogo
    className="fill-current text-bg antialiased w-32 h-20"
    fillColor="#fff"
  />
);
