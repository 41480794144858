import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'next-i18next';
import { PopupContent } from '../../includes/popover-content';
import { Criteria } from '../buildFilters';
import { MixedCheckbox } from '@reach/checkbox';
import cx from 'classnames';
import { CloseIcon } from '@curated-property/icons';

interface FilterFlyoutProps {
  label: string;
  items: Criteria[];
  onChange(item: string | number | boolean, value: boolean): void;
  className?: string;
  checkedItems: (string | number | boolean)[];
  setId?: any;
  labelButtonID: string;
  labelColor?: string;
}

export function FilterFlyout({
  label,
  items,
  onChange,
  className,
  checkedItems,
  setId,
  labelButtonID,
  labelColor,
}: FilterFlyoutProps) {
  const [open, setOpen] = useState(false);
  const buttonRef = useRef<HTMLButtonElement | null>(null);

  const [currentCheckbox, setCurrentCheckbox] = useState('');
  const [firstCheckboxInArray, setFirstCheckboxInArray] = useState('');

  function setNextFocus(id) {
    if (id) {
      document.getElementById(id).focus();
    }
  }
  useEffect(() => {
    function nextInput(dir) {
      const itemIdArray = [];
      const items = document.querySelectorAll(
        '[data-testid="popoverContent"] .form-checkbox'
      );

      for (let i = 0; i < items.length; i++) {
        if (items[i].id) {
          itemIdArray.push(items[i].id);
          if (i === 0) {
            setFirstCheckboxInArray(items[i].id);
          }
        }
      }
      const place = itemIdArray.indexOf(currentCheckbox);

      if (dir === 'down') {
        place + 1 > itemIdArray.length - 1
          ? setNextFocus(itemIdArray[0])
          : setNextFocus(itemIdArray[place + 1]);
      } else if (dir === 'up') {
        place === 0
          ? setNextFocus(itemIdArray[itemIdArray.length - 1])
          : setNextFocus(itemIdArray[place - 1]);
      }
    }
    function modalKeypress(e) {
      if (e.key === 'ArrowDown') {
        e.preventDefault();
        if (currentCheckbox.length) {
          nextInput('down');
        }
      } else if (e.key === 'Tab') {
        e.preventDefault();
        if (currentCheckbox.length) {
          nextInput('down');
        }
      } else if (e.key === 'ArrowUp') {
        e.preventDefault();
        if (currentCheckbox.length) {
          nextInput('up');
        }
      }
    }

    if (open === true) {
      window.addEventListener('keydown', modalKeypress);
      return () => {
        window.removeEventListener('keydown', modalKeypress);
      };
    }
  }, [open, currentCheckbox]);

  const { t } = useTranslation();
  if (!items.length || items.length === 1) {
    return null;
  }
  return (
    <div
      className={className}
      data-testid="filterFlyout"
      style={{
        color: labelColor || undefined,
      }}
    >
      <div className="filter-title text-sm mb-1">
        {t(`roomFilters.header.${label}`) !== `roomFilters.header.${label}`
          ? t(`roomFilters.header.${label}`)
          : label}
      </div>
      <button
        className="form-select w-32 text-left truncate"
        ref={buttonRef}
        onClick={() => setOpen((s) => !s)}
        aria-label={t('filtersFor', { filter: label })}
        aria-expanded={open}
        id={labelButtonID}
        data-cy="flyoutBtn"
        data-testid={labelButtonID}
      >
        {checkedItems?.length === 0
          ? t('any')
          : checkedItems?.length === 1
          ? items.find((i) => i.value === checkedItems?.[0])?.label
          : t('multiple')}
      </button>
      {open && (
        <PopupContent
          onClose={() => {
            setOpen(false);
            setId(labelButtonID);
          }}
          targetRef={buttonRef}
          className={`${labelButtonID}-flyout`}
        >
          <div className="p-4">
            <div className="flex pb-2 border-b mb-2">
              <header className="font-bold text-lg font-headline">
                {t(`roomFilters.header.${label}`) !==
                `roomFilters.header.${label}`
                  ? t(`roomFilters.header.${label}`)
                  : label}
              </header>
            </div>
            <div
              role="application"
              className="grid gap-2 lg:flex flex-wrap lg:space-x-4"
            >
              {items.map((item, i) => {
                if (item.label && item.value) {
                  const idName = item.label.replace(/\s/g, '') + i.toString();
                  return (
                    <label
                      className="label label-inline font-normal text-sm"
                      key={idName}
                    >
                      <MixedCheckbox
                        id={idName}
                        key={idName}
                        data-testid={`${labelButtonID}-${i}`}
                        type="checkbox"
                        checked={checkedItems.includes(item.value)}
                        className=" form-checkbox mr-2"
                        onChange={(e) => {
                          onChange(item.value, e.target.checked);
                        }}
                        onFocus={() => {
                          setCurrentCheckbox(idName);
                        }}
                      />
                      {t(`roomFilters.${labelButtonID}.${item.label}`) !==
                      `roomFilters.${labelButtonID}.${item.label}`
                        ? t(`roomFilters.${labelButtonID}.${item.label}`)
                        : item.label}
                    </label>
                  );
                }
                return null;
              })}
            </div>
          </div>
        </PopupContent>
      )}
    </div>
  );
}

interface FilterCheckboxProps {
  label: string;
  items: Criteria[];
  onChange(item: string | number | boolean, value: boolean): void;
  className?: string;
  checkedItems: (string | number | boolean)[];
  labelButtonID: string;
  labelColor?: string;
}

export function FilterCheckbox({
  label,
  items,
  onChange,
  className,
  checkedItems,
  labelButtonID,
  labelColor,
}: FilterCheckboxProps) {
  const { t } = useTranslation();
  if (!items.length || items.length === 1) {
    return null;
  }
  return (
    <div
      className={className}
      style={{
        color: labelColor || undefined,
      }}
    >
      <div className="filter-title text-sm mb-1">{label}</div>
      {items.map((item, key) => {
        if (item.label && item.value) {
          return (
            <label
              className="label label-inline-reverse lg:pr-4 h-10"
              key={item.label}
            >
              <span className="filter-title font-normal text-sm">
                {t('yes')}
              </span>
              <input
                data-testid={`${labelButtonID}-${key}`}
                type="checkbox"
                checked={checkedItems.includes(item.value)}
                className="form-checkbox mr-2"
                onChange={(e) => {
                  onChange(item.value, e.target.checked);
                }}
              />
            </label>
          );
        }
        return null;
      })}
    </div>
  );
}

interface FilterTagProps {
  className?: string;
  selectedFilters: { label: string; remove(): void }[];
  clearAll(): void;
  clearPriceSort?(): void;
  clearShowerState?(): void;
}

export function FilterTags({
  className,
  selectedFilters,
  clearAll,
  clearPriceSort,
  clearShowerState,
}: FilterTagProps) {
  const [t] = useTranslation();
  return (
    <div
      data-testid="filterTags"
      className={cx(
        'grid grid-cols-3 gap-2 md:flex justify-center items-end space-x-2 flex-wrap pt-4 filter-tags',
        className
      )}
    >
      {selectedFilters.map((f) => {
        if (f.label) {
          return (
            <button
              key={f.label}
              className="filter-results md:mb-2 btn btn-secondary-outline rounded-none pr-2 border-bg-disabled text-text font-normal flex items-center"
              onClick={
                f.label === t('highToLow') || f.label === t('lowToHigh')
                  ? clearPriceSort
                  : f.label === t('showerLabel')
                  ? clearShowerState
                  : f.remove
              }
              aria-label={t('remove', { label: f.label })}
            >
              {f.label}{' '}
              <CloseIcon className="inline-block h-2.5 w-2.5 ml-3 fill-current" />
            </button>
          );
        } else return null;
      })}
      <button
        className="clear-results btn-primary-outline btn rounded-none pr-2 md:mb-2 flex items-center"
        onClick={clearAll}
        data-testid="filter-tags-clear-all"
      >
        {t('clearAll')}{' '}
        <CloseIcon className="inline-block h-2.5 w-2.5 ml-3 fill-current" />
      </button>
    </div>
  );
}
