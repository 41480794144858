import React from 'react';
import Link from 'next/link';
import { useState, useRef, useEffect } from 'react';
import { BurgerMenu } from '@curated-property/icons';
import { CloseIcon } from '@curated-property/icons';
import { isRelativeUrl } from '../functions/helper';
import { INavUtilityStylingOptionProps } from './nav-utility';
import cx from 'classnames';
import { useTranslation } from 'next-i18next';
import { useLanguage } from '@curated-property/utils';
import { InavMenuItem } from '../header';
import { ArrowHead } from '@curated-property/icon-list';

interface MenuProps {
  menuItems: Array<InavMenuItem> | undefined;
  iconMenu?: Array<InavMenuItem>;
  activeHeader?: (active: boolean) => void;
  bgColor?: string;
  textColorOnActive?: string;
  stylingOptions?: {
    fontWeight?: 'light' | 'normal' | 'medium' | 'bold';
    case?: '' | 'uppercase' | 'lowercase' | 'capitalize';
    iconMenu?: INavUtilityStylingOptionProps;
  };
  urlPrefix?: string;
  shopFormInHeader?: boolean;
}

export function NavMobile({
  menuItems,
  iconMenu,
  bgColor,
  textColorOnActive,
  shopFormInHeader,
  stylingOptions,
}: MenuProps) {
  const [menuToggle, setMenuToggle] = useState(false);
  const [lastFocussed, setLastFocussed] = useState<any>(null);
  const { t } = useTranslation();
  const focusOnActive = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    function modalKeypress(e: KeyboardEvent) {
      switch (e.key) {
        case 'Escape':
          setMenuToggle(false);
          lastFocussed?.focus();
          break;
        default:
          return null;
      }
    }
    window.addEventListener('keyup', modalKeypress);
    return () => window.removeEventListener('keyup', modalKeypress);
  }, [setMenuToggle, lastFocussed]);

  useEffect(() => {
    const outer = document?.querySelector('.nav-mobile-outer');
    const aGroup = outer?.getElementsByTagName('a') || [];

    function closeMenu() {
      setMenuToggle(false);
    }

    menuToggle === true
      ? Array.from(aGroup).forEach((el) => {
          el.addEventListener('click', closeMenu);
        })
      : Array.from(aGroup).forEach((el) => {
          el.removeEventListener('click', closeMenu);
        });
  }, [menuToggle]);

  return (
    <div
      data-testid="navMobile"
      data-element-id="nav-mobile"
      className={`flex items-center justify-end rtl:justify-start w-full h-full ${
        stylingOptions?.fontWeight || 'font-bold'
      } nav-mobile-outer ${stylingOptions?.case || ''}`}
    >
      <button
        className={cx('flex flex-col items-center justify-end', {
          'mx-3': !shopFormInHeader,
        })}
        data-testid="mobile-nav-toggle"
        aria-expanded={menuToggle}
        onClick={(e) => {
          menuToggle ? setMenuToggle(false) : setMenuToggle(true);
          setLastFocussed(e?.target);
          setTimeout(() => {
            focusOnActive?.current?.focus();
          }, 50);
        }}
      >
        <span className="pointer-events-none">
          <BurgerMenu className="fill-current text-current" width="28" />
        </span>
        <span className="text-current h-5 mt-1 pointer-events-none">
          {t('menu')}
        </span>
      </button>
      <div
        role="dialog"
        className={cx(
          menuToggle ? 'flex' : 'hidden',
          'fixed flex-col top-0 left-0 h-full w-full bg-primary py-16 px-3 z-10 overflow-auto'
        )}
        style={{
          backgroundColor: bgColor,
          color: textColorOnActive,
        }}
      >
        <button
          data-testid="mobileMenuClose"
          className="absolute top-0 right-0 p-6 pr-3 flex flex-col items-end"
          onClick={() => {
            setMenuToggle(false);
            lastFocussed?.focus();
          }}
          ref={focusOnActive}
        >
          <span className="sr-only">{t('closeMenu')}</span>
          <span>
            <CloseIcon className="fill-current text-current mb-2" />
          </span>
          <span
            aria-hidden={true}
            className="text-lg text-current tracking-wider uppercase"
          >
            {t('close')}
          </span>
        </button>
        <ul className="mt-9">
          {menuItems?.concat(iconMenu || [])?.map((item, key) => {
            return (
              <MobileListItems
                key={key}
                url={item?.url || ''}
                noTx={item?.noTx}
                text={item?.label?.replace('&#038;', '&') || ''}
                textNoTx={item?.label_noTx?.replace('&#038;', '&')}
                childItems={item?.childItems}
                textColorOnActive={textColorOnActive || ''}
                fontWeight={stylingOptions?.fontWeight}
              />
            );
          })}
        </ul>
      </div>
    </div>
  );
}

interface MobileListItemProps {
  url: string;
  noTx?: boolean;
  text: string;
  textNoTx?: string;
  childItems: any;
  textColorOnActive: string;
  fontWeight?: string;
}

export function MobileListItems({
  url,
  noTx,
  text,
  textNoTx,
  childItems,
  textColorOnActive,
  fontWeight,
}: MobileListItemProps) {
  const [itemToggle, setItemToggle] = useState(false);
  const listClasses = `cp-mobileNav-subItems font-sans font-${
    fontWeight || 'normal'
  } px-6 py-2 hover:underline`;
  const { t } = useTranslation();
  const language = useLanguage();
  const hasChildItems = childItems?.length > 0;
  let topLevelItem;

  if (hasChildItems) {
    topLevelItem = (
      <button
        data-testid="top-level-button"
        style={{
          textTransform: 'inherit',
          fontWeight: 'inherit',
        }}
        className={cx(
          `text-lg w-full hover:underline flex justify-between items-center`,
          { OneLinkNoTx: noTx }
        )}
        aria-expanded={itemToggle}
        onClick={() =>
          itemToggle ? setItemToggle(false) : setItemToggle(true)
        }
      >
        {noTx ? textNoTx : text}
        <ArrowHead
          className={`transform transition-all h-7 ${
            itemToggle ? 'rotate-180' : ''
          }`}
        />
      </button>
    );
  } else {
    topLevelItem = (
      <Link
        href={isRelativeUrl(url) ? `/${language}${url}` : url}
        target={isRelativeUrl(url) ? '' : '_blank'}
        data-testid="top-level-link"
        className={cx(`text-lg text-left w-full inline-block hover:underline`, {
          OneLinkNoTx: noTx,
        })}
      >
        {noTx ? textNoTx : text}
      </Link>
    );
  }

  return (
    <li
      className="py-4 border-b w-full"
      style={{ borderColor: textColorOnActive }}
    >
      {topLevelItem}
      {hasChildItems && (
        <ul className={cx(itemToggle ? 'block' : 'hidden', 'py-2')}>
          <li
            className={cx(
              listClasses,
              `${childItems?.length <= 0 ? 'font-bold' : 'font-extrabold'}`
            )}
          >
            <Link
              href={isRelativeUrl(url) ? `/${language}${url}` : url}
              className={cx(noTx && 'OneLinkNoTx')}
            >
              {t('addOverview', { item: noTx ? textNoTx : text })}
            </Link>
          </li>
          {childItems?.map((cItem: InavMenuItem, cKey: number) => (
            <li key={cKey} className={cx(listClasses, 'font-extrabold')}>
              <MobileListSubItems itemObj={cItem} />
              {cItem?.childItems?.length ||
                (0 > 0 && (
                  <ul>
                    {cItem?.childItems?.map(
                      (subItem: InavMenuItem, subItemKey: number) => (
                        <li
                          key={subItemKey}
                          className={cx(listClasses, 'font-bold')}
                        >
                          <MobileListSubItems itemObj={subItem} />
                        </li>
                      )
                    )}
                  </ul>
                ))}
            </li>
          ))}
        </ul>
      )}
    </li>
  );
}

interface SubItemsProps {
  itemObj?: {
    url?: string;
    noTx?: boolean;
    label?: string;
    label_noTx?: string;
  };
}

export function MobileListSubItems({ itemObj }: SubItemsProps) {
  const language = useLanguage();

  return (
    <Link
      href={
        isRelativeUrl(itemObj?.url)
          ? `/${language}${itemObj?.url}`
          : itemObj?.url || ''
      }
      target={isRelativeUrl(itemObj?.url) ? '' : '_blank'}
      className={cx(itemObj?.noTx && 'OneLinkNoTx')}
    >
      {itemObj?.noTx ? itemObj?.label_noTx : itemObj?.label}
    </Link>
  );
}
