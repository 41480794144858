import React from 'react';
export function Refresh({
  className,
  fillColor,
}: {
  className?: string;
  fillColor?: string;
}) {
  return (
    <svg className={className} viewBox="0 0 24 24" style={{ fill: fillColor }}>
      <g data-name="Bounding Box">
        <path style={{ fill: 'none' }} d="M0,0V24H24V0Z" />
      </g>
      <g data-name="Icons Outlined" style={{ fill: fillColor }}>
        <path d="M19,11.4951a.3751.3751,0,0,0-.375.375A6.6768,6.6768,0,1,1,17.059,7.625H14a.375.375,0,0,0,0,.75h4A.3751.3751,0,0,0,18.375,8V4a.375.375,0,0,0-.75,0V7.1319a7.352,7.352,0,1,0,1.75,4.7382A.3751.3751,0,0,0,19,11.4951Z" />
      </g>
    </svg>
  );
}
