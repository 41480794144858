import { FooterProps } from '@curated-property/shared-components';
import dynamic from 'next/dynamic';
import { ForbesLogoRec } from '@curated-property/icons';

const SharedFooter = dynamic<FooterProps>(() =>
  import('@curated-property/shared-components').then((f) => f.Footer)
);

export const Footer = (props: FooterProps) => {
  return (
    <SharedFooter
      {...props}
      footerNavOverride={
        <div className="w-28 mx-auto mt-4 md:mt-0">
          <ForbesLogoRec className="" fillColor="#fff" />
        </div>
      }
    />
  );
};
