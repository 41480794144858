import React, { useState, useEffect, useRef } from 'react';
import cx from 'classnames';
import { imageUrl } from '@curated-property/utils';
import { MapInteractionCSS } from 'react-map-interaction';
import { MapPin } from './map-pin';
import { PinModal } from './pin-modal';
import { MapControls } from './map-controls';
import { MapCategories } from './map-categories';
import { GIS_Padder, GIS_Array } from '../functions/global-instance-styles';
import { HandleAnimations, useWindowSize } from '../functions/helper';
import { CroppedImage } from '@curated-property/utils';
import { useTranslation } from 'next-i18next';
import {
  InteractiveMap,
  MapStateContent,
  PinModalContent,
  MapSizes,
} from './interactive-map.types';

export function ResortMap({
  image,
  repeater,
  mapCategoryTitle,
  mapCategoryMobileButtonPosition,
  mapCategoryDescription,
  mapOverlayPosition,
  instanceStyles,
  globalStyles,
  modalSettings,
  pinCategories,
}: InteractiveMap) {
  const [mapSize, setMapSize] = useState<MapSizes>({
    width: image?.mediaDetails?.width || 1920,
    height: image?.mediaDetails?.height || 1080,
  });
  const [enableScrollOver, setEnableScrollOver] = useState<boolean>(false);
  const [tapCount, setTapCount] = useState<number>(0);
  const [pinActive, setPinActive] = useState<{ id?: number }>({
    id: undefined,
  });
  const [selectedCategory, setSelectedCategory] = useState<string | undefined>(
    undefined
  );
  const [mapScale, setMapScale] = useState<number>(1);
  const [mapState, setMapState] = useState<MapStateContent>({
    scale: mapScale,
    translation: {
      x: mapSize.width / mapScale / 2,
      y: mapSize.height / mapScale / 2,
    },
  });
  const [modalActive, setModalActive] = useState<boolean>(false);
  const [modalContent, setModalContent] = useState<PinModalContent>({
    id: 0,
    title: '',
    image: undefined,
    description: '',
    link: undefined,
    link2: undefined,
    linkText: '',
    lastFocussedElement: undefined,
  });
  const mapBoxRef = useRef<HTMLDivElement>(null);
  const mapBoxWidth: number = mapBoxRef?.current?.offsetWidth ?? 0;
  const mapBoxHeight: number = mapBoxRef?.current?.offsetHeight ?? 0;
  const wWidth: number = useWindowSize().width || 0;
  const showMobileCategoryAbove = mapCategoryMobileButtonPosition === 'above';
  const { t } = useTranslation();

  useEffect(() => {
    setMapScale(1);
    setMapState({ scale: mapScale, translation: { x: 0, y: 0 } });
  }, [wWidth, mapScale]);

  // allow touch screen users to scroll over map by double tapping
  useEffect(() => {
    if (tapCount === 2) {
      setEnableScrollOver(true);
      setTimeout(() => {
        setEnableScrollOver(false);
      }, 2500);
    }
  }, [tapCount]);

  const handleTouch = (e: TouchEvent) => {
    const targetEl = e?.target as HTMLElement;
    if (
      'touches' in e &&
      e.touches?.length === 1 &&
      targetEl?.tagName === 'IMG'
    ) {
      setTapCount(tapCount + 1);
      setTimeout(() => {
        setTapCount(0);
      }, 500);
    }
  };

  useEffect(() => {
    if (image?.sourceUrl) {
      const strippedUrl = image.sourceUrl.replace(/,w_300,h_300/gi, '');
      const img = new Image();
      img.onload = () =>
        setMapSize({ width: img.naturalWidth, height: img.naturalHeight });
      img.src = strippedUrl;
    }
  }, [image, setMapSize]);

  const mapImageWidth = 1920;
  const mapImageAspectRatio: number = mapSize.width / mapSize.height;
  const mapImageHeight: number = Math.round(
    mapImageWidth / mapImageAspectRatio
  );
  let mapImageUrl: string = imageUrl(image?.sourceUrl);
  if (mapImageUrl === '') {
    mapImageUrl = imageUrl(
      'https://assets.hiltonstatic.com/images/v1583957952/no-image/no-image.png'
    );
  }

  const inlineStyles = GIS_Array(globalStyles, instanceStyles);
  const paddingStyles = GIS_Padder(
    inlineStyles?.paddingTop,
    inlineStyles?.paddingBottom
  );

  let textAlignment = '';
  switch (inlineStyles?.textAlignment) {
    case 'center':
      textAlignment = 'justify-center';
      break;
    case 'flex-end':
      textAlignment = 'justify-end';
      break;
  }

  repeater?.forEach((pin) => {
    const matchingCatIdx: number =
      pinCategories?.findIndex(
        (cat) => cat?.categoryName === pin?.pinCategory
      ) || -1;
    if (matchingCatIdx >= 0) {
      pin.pinIcon = pinCategories?.[matchingCatIdx]?.iconList;
    }
  });

  const openModal = () => {
    setModalActive(true);
  };
  const hideAnimations = inlineStyles?.hideAnimations !== 'show';
  const animationDirection = inlineStyles?.animationDirection;
  const animations = HandleAnimations({
    hideAnimation: hideAnimations,
    start: inlineStyles?.animationDirection
      ? `${inlineStyles?.animationDirection}-8`
      : mapOverlayPosition === 'left'
      ? '-translate-x-8'
      : 'translate-x-8',
    delayOne: 'delay-200',
    delayTwo: 'delay-300',
    delayThree: 'delay-500',
  });

  return (
    <div
      className={inlineStyles?.showHide ? 'hidden' : ''}
      style={{
        backgroundImage: inlineStyles?.componentBackgroundImage
          ? `url('${inlineStyles?.componentBackgroundImage}')`
          : undefined,
        backgroundSize: inlineStyles?.componentBackgroundSize || undefined,
        backgroundRepeat: inlineStyles?.componentBackgroundRepeat || undefined,
        backgroundPosition:
          inlineStyles?.componentBackgroundPosition || undefined,
        backgroundColor: inlineStyles?.componentBackgroundColor || undefined,
      }}
      data-element-id="interactive-map-wrapper"
    >
      <div className={cx('container p-0', paddingStyles)}>
        <div
          ref={animations?.ref}
          className={cx('px-11 lg:hidden', {
            'pb-11': !showMobileCategoryAbove,
            'pb-[67px]': showMobileCategoryAbove,
          })}
          style={{
            backgroundImage: inlineStyles?.contentBackgroundImage
              ? `url('${inlineStyles?.contentBackgroundImage}')`
              : undefined,
            backgroundSize: inlineStyles?.contentBackgroundSize || undefined,
            backgroundRepeat:
              inlineStyles?.contentBackgroundRepeat || undefined,
            backgroundPosition:
              inlineStyles?.contentBackgroundPosition || undefined,
            backgroundColor: inlineStyles?.contentBackgroundColor || undefined,
          }}
        >
          <h2
            className={cx(
              'text-primary text-3xl font-extrabold font-headline pb-3 pt-7 flex items-center',
              textAlignment,
              animations?.one
            )}
            style={{
              color: inlineStyles?.titleColor,
            }}
          >
            {mapCategoryTitle ? mapCategoryTitle : t('exploreTheResort')}
          </h2>
          {mapCategoryDescription ? (
            <p
              className={cx('text-lg', animations?.two)}
              style={{
                color: inlineStyles?.subtitleColor,
              }}
            >
              {mapCategoryDescription}
            </p>
          ) : null}
        </div>
        {/* div below and '-mb-2 fixes weird extra space created by the map library' */}
        <div className="overflow-hidden">
          <div
            className={cx('flex h-full w-full -mb-2', {
              'flex-row-reverse': mapOverlayPosition === 'right',
            })}
          >
            {repeater?.length && (
              <MapCategories
                mapCategoryTitle={mapCategoryTitle}
                mapCategoryDescription={mapCategoryDescription}
                showMobileCategoryAbove={showMobileCategoryAbove}
                categories={repeater}
                selectedCategory={selectedCategory}
                setSelectedCategory={setSelectedCategory}
                textAlignment={textAlignment}
                titleColor={inlineStyles?.titleColor}
                subtitleColor={inlineStyles?.subtitleColor}
                selectedTabBackgroundColor={
                  inlineStyles?.selectedTabBackgroundColor
                }
                tabBackgroundColor={inlineStyles?.tabBackgroundColor}
                selectedTabTextColor={inlineStyles?.selectedTabTextColor}
                tabTextColor={inlineStyles?.tabTextColor}
                contentBackgroundColor={inlineStyles?.contentBackgroundColor}
                contentBackgroundImage={inlineStyles?.contentBackgroundImage}
                contentBackgroundSize={inlineStyles?.contentBackgroundSize}
                contentBackgroundPosition={
                  inlineStyles?.contentBackgroundPosition
                }
                contentBackgroundRepeat={inlineStyles?.contentBackgroundRepeat}
                mobileFilterDropdownBackgroundColor={
                  inlineStyles?.mobileFilterDropdownBackgroundColor
                }
                mobileFilterDropdownTextColor={
                  inlineStyles?.mobileFilterDropdownTextColor
                }
                wWidth={wWidth}
                hideAnimations={hideAnimations}
                animationDirection={animationDirection}
                mapOverlayPosition={mapOverlayPosition}
              />
            )}
            <div
              onTouchStart={() => handleTouch}
              ref={mapBoxRef}
              className="flex h-full w-full relative"
            >
              <MapInteractionCSS
                className="order-0"
                disablePan={false}
                disableZoom={true}
                value={{
                  scale: mapState.scale,
                  translation: {
                    x: mapState.translation.x,
                    y: mapState.translation.y,
                  },
                }}
                onChange={(value: MapStateContent) => {
                  const widthLimit = mapBoxWidth * value?.scale - mapBoxWidth;
                  const heightLimit =
                    mapBoxHeight * value?.scale - mapBoxHeight;
                  const limitPan = (dir: string) => {
                    const typedDir =
                      dir as keyof MapStateContent['translation'];
                    if (value?.translation[typedDir] > 0) {
                      return 0;
                    } else if (
                      dir === 'x' &&
                      value?.translation[typedDir] < -widthLimit
                    ) {
                      return -widthLimit;
                    } else if (
                      dir === 'y' &&
                      value?.translation[typedDir] < -heightLimit
                    ) {
                      return -heightLimit;
                    } else return value?.translation[typedDir];
                  };
                  setMapState({
                    scale: value?.scale,
                    translation: {
                      x: limitPan('x'),
                      y: limitPan('y'),
                    },
                  });
                }}
                defaultValue={{
                  scale: mapState.scale,
                  translation: {
                    x: mapState.translation.x,
                    y: mapState.translation.y,
                  },
                }}
              >
                {mapImageUrl && (
                  <CroppedImage
                    src={mapImageUrl}
                    width={mapImageWidth}
                    height={mapImageHeight}
                    alt={image?.altText}
                  />
                )}
                <div className="flex flex-wrap justify-around">
                  {repeater?.map((pin, key) => {
                    return (
                      <MapPin
                        key={key}
                        id={key}
                        pinTitle={pin?.pinTitle}
                        pinColor={inlineStyles?.pinColor}
                        pinAccentColor={inlineStyles?.pinAccentColor}
                        pinX={pin?.pinX}
                        pinY={pin?.pinY}
                        pinIcon={pin?.pinIcon}
                        pinModal={pin?.pinModal}
                        pinCategory={pin.pinCategory}
                        modalImage={pin?.pinModalImage}
                        modalDescription={pin?.pinModalDescription}
                        modalLink={pin?.modalLink}
                        modalLink2={pin?.modalLink2}
                        modalLinkText={pin?.modalLinkText}
                        selectedCategory={
                          !selectedCategory ||
                          selectedCategory === pin?.pinCategory
                        }
                        openModal={openModal}
                        modalContent={setModalContent}
                        pinActive={pinActive}
                        setPinActive={setPinActive}
                        boxHeight={mapBoxHeight || 0}
                        boxWidth={mapBoxWidth || 0}
                      />
                    );
                  })}
                </div>
              </MapInteractionCSS>
              <div
                className={cx(
                  'absolute opacity-0 transition-all duration-700 z-10 top-0 bg-[rgba(25,20,20,.65)] font-light text-white text-3xl font-bold h-full w-full flex items-center justify-center',
                  {
                    'opacity-95': enableScrollOver,
                    'pointer-events-none': !enableScrollOver,
                  }
                )}
              >
                <p>{t('doubleTapToScroll')}</p>
              </div>
              <MapControls
                mapOverlayPosition={mapOverlayPosition}
                mapControlsPlacement={inlineStyles?.mapControlsPlacement}
                iconColor={inlineStyles?.mapControlsColor}
                backgroundColor={inlineStyles?.mapControlsBackgroundColor}
                mapState={mapState}
                setMapState={setMapState}
                mapHeight={mapBoxHeight || 0}
                mapWidth={mapBoxWidth || 0}
              />
            </div>
            <PinModal
              title={modalContent.title}
              image={modalContent.image}
              content={modalContent.description}
              link={modalContent.link}
              link2={modalContent.link2}
              linkText={modalContent.linkText}
              active={modalActive}
              setActive={setModalActive}
              modalSettings={modalSettings}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
