import dynamic from 'next/dynamic';

const HotelLogo = dynamic<{ className?: string; fillColor?: string }>(() =>
  import('./indcici/hotel-logo').then((i) => i.HotelLogo)
);

export const hotelLogoHeader = (
  <HotelLogo
    className="fill-current antialiased w-28 lg:w-36 lg:pr-2"
    fillColor="#000000"
  />
);

export const hotelLogoFooter = (
  <HotelLogo
    className="fill-current antialiased w-28 lg:w-40"
    fillColor="#FFFFFF"
  />
);
