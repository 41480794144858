import React from 'react';
export function Yoga({
  className,
  fillColor,
}: {
  className?: string;
  fillColor?: string;
}) {
  return (
    <svg className={className} viewBox="0 0 24 24" style={{ fill: fillColor }}>
      <g data-name="Bounding Box">
        <path style={{ fill: 'none' }} d="M0,0V24H24V0Z" />
      </g>
      <g data-name="Icons Outlined">
        <path d="M21.3467,11.8564A.3769.3769,0,0,0,21,11.625H14a.375.375,0,0,0,0,.75h6.0947l-4.25,4.25H6.0566a2.559,2.559,0,0,1-.7216-.1025,2.8081,2.8081,0,0,1-1.96-2.712,2.754,2.754,0,0,1,2.6816-2.8154,2.6037,2.6037,0,0,1,1.875.7988,2.7709,2.7709,0,0,1,.7754,2.043,3.5668,3.5668,0,0,1-.0215.3721l-.0205.0957c-.56,1.334-2.3828.9892-2.7461.9043A1.2467,1.2467,0,0,1,4.792,13.8447a1.2625,1.2625,0,0,1,1.24-1.3525,1.0514,1.0514,0,0,1,1.2442,1.0566.3383.3383,0,0,1-.0694.2383.7478.7478,0,0,1-.5283.1885.8244.8244,0,0,1-.6445-.1875.3751.3751,0,0,0-.5645.4941,1.5435,1.5435,0,0,0,1.29.4385,1.3884,1.3884,0,0,0,1.042-.4766,1.0719,1.0719,0,0,0,.22-.7724,1.78,1.78,0,0,0-2.0088-1.7295,1.984,1.984,0,0,0-1.9678,2.041A1.9871,1.9871,0,0,0,5.75,15.94a3.4949,3.4949,0,0,0,3.1343-.6661c.0171-.0107.0386-.0068.0542-.0205l2.99-2.6416A3.3881,3.3881,0,0,0,10.4746,6.69a2.862,2.862,0,0,0-2.5332.6582L3.6826,11.2324c-.0069.0063-.0047.0169-.011.0235A3.6176,3.6176,0,0,0,2.625,13.8105a3.5591,3.5591,0,0,0,2.499,3.4307,3.292,3.292,0,0,0,.9326.1338H16a.3754.3754,0,0,0,.2656-.1094l5-5A.3778.3778,0,0,0,21.3467,11.8564ZM8.45,7.9a2.1029,2.1029,0,0,1,1.8633-.4775,2.6292,2.6292,0,0,1,2.083,2.371,2.6,2.6,0,0,1-.9824,2.2725L9.4562,13.7952a3.49,3.49,0,0,0-.9874-2.5237,3.3438,3.3438,0,0,0-2.4122-1.0264c-.0686,0-.1328.0169-.2005.0211Z" />
      </g>
    </svg>
  );
}
