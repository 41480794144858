import React from 'react';
const BreakfastIcon = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg focusable="false" viewBox="0 0 44 44" {...props}>
      <g strokeWidth="1.5" fill="none" fillRule="evenodd" stroke="currentColor">
        <path d="M39.616 22.562c-1.459-1.009-2.682.001-4.727.95-.032 1.688-.23 3.884-.895 5.976-.115.363-.249.713-.394 1.054 1.128.92 2.542 1.458 4.21.868 4.602-1.627 3.293-7.82 1.806-8.848zM34.652 22.952c.033-1.727-.111-2.923-.111-2.923H4.008s-.179 4.428 1.19 8.885C7.053 34.945 13.422 38 13.422 38h11.705s5.88-2.62 8.221-8.035c.399-1.05 1.285-4.67 1.31-6.867.001-.044-.044-.128-.005-.146z" />
        <g strokeLinecap="round">
          <path d="M23.88 5.229s2.842 2.329 0 4.482c-2.843 2.153 0 4.853 0 4.853M18.594 5.229s2.842 2.329 0 4.482c-2.843 2.153 0 4.853 0 4.853M13.308 5.229s2.843 2.329 0 4.482 0 4.853 0 4.853" />
        </g>
      </g>
    </svg>
  );
};

export default BreakfastIcon;
