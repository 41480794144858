import React from 'react';

export function LinkedinIcon({ className }: { className?: string }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="19"
      viewBox="0 0 106.0078 90.2676"
    >
      <g fill="inherit">
        <g>
          <path
            className={className}
            d="M99.8368,78.0435a6.1122,6.1122,0,1,0,.1176,0h-.1176m0,11.5243a5.36,5.36,0,1,1,5.27-5.4481v.09a5.2448,5.2448,0,0,1-5.13,5.3555h-.1384M76.3745,76.3737H63.0932V55.5746c0-4.96-.0886-11.3445-6.9077-11.3445-6.9173,0-7.9757,5.4039-7.9757,10.9834V76.3723H34.9285V33.6009h12.75v5.8452h.1784a13.973,13.973,0,0,1,12.58-6.9091c13.4612,0,15.9431,8.8542,15.9431,20.373ZM19.9427,27.7544a7.7074,7.7074,0,1,1,7.706-7.7087,7.7073,7.7073,0,0,1-7.706,7.7087h0m6.6407,48.6193H13.2882V33.6009H26.5834ZM82.9958.0061H6.6144A6.5411,6.5411,0,0,0,0,6.4669V83.1651a6.5452,6.5452,0,0,0,6.6144,6.4678H82.9958a6.5591,6.5591,0,0,0,6.6406-6.4678V6.4614A6.5548,6.5548,0,0,0,82.9958.0006m17.8108,84.5425A1.66,1.66,0,0,0,102.4832,82.9q.0006-.0561-.0026-.1122c0-1.2451-.7512-1.84-2.2925-1.84h-2.49v6.5189h.9366V84.6247h1.1511l.0263.0346,1.786,2.807H102.6l-1.9217-2.9052Zm-1.0833-.653h-1.086V81.6862h1.3766c.7111,0,1.5218.1162,1.5218,1.0473,0,1.0708-.82,1.1566-1.8179,1.1566"
          />
        </g>
      </g>
    </svg>
  );
}
