import React from 'react';

export function AudioMuted({
  className,
  fill,
}: {
  className?: string;
  fill?: string;
}) {
  return (
    <svg
      fill={fill}
      className={className}
      height="18"
      width="21"
      viewBox="0 0 21 18"
    >
      <path d="m 8.024799,2.25 c -0.20844,0 -0.41687,0.105469 -0.55583,0.28125 L 4.377159,5.625 H 0.833749 C 0.347394,5.625 0,6.01172 0,6.46875 v 5.0625 C 0,12.0234 0.347394,12.375 0.833749,12.375 h 3.54341 l 3.09181,3.1289 c 0.13896,0.1758 0.34739,0.2461 0.55583,0.2461 0.4516105,0 0.8684795,-0.3164 0.8684795,-0.8438 V 3.12891 C 8.8932785,2.601562 8.4764095,2.25 8.024799,2.25 Z M 7.225789,12.9023 5.558299,11.21484 5.037209,10.6875 h -3.36972 v -3.375 h 3.36972 l 0.52109,-0.49219 1.66749,-1.6875 z M 16.153769,9 17.612868,7.55859 c 0.2084,-0.21093 0.2084,-0.5625 0,-0.80859 l -0.3821,-0.38672 c -0.2432,-0.21094 -0.5906,-0.21094 -0.799,0 l -1.424399,1.47656 -1.459,-1.47656 c -0.2084,-0.21094 -0.5558,-0.21094 -0.7643,0 L 12.367269,6.75 c -0.2085,0.24609 -0.2085,0.59766 0,0.80859 l 1.459,1.44141 -1.459,1.47656 c -0.2085,0.21094 -0.2085,0.5625 0,0.77344 l 0.4168,0.42188 c 0.2085,0.21092 0.5559,0.21092 0.7643,0 l 1.459,-1.47657 1.424399,1.47657 c 0.208401,0.21092 0.555801,0.21092 0.799,0 l 0.3821,-0.42188 c 0.208401,-0.21094 0.208401,-0.5625 0,-0.77344 z" />
    </svg>
  );
}
