import dynamic from 'next/dynamic';

const HotelLogo = dynamic<{ className?: string; fillColor?: string }>(() =>
  import('./lonmetw/hotel-logo').then((i) => i.HotelLogo)
);

export const hotelLogoHeader = (
  <HotelLogo
    className="fill-white text-current antialiased w-28"
    fillColor="#d3d3d0"
  />
);

export const hotelLogoFooter = (
  <HotelLogo
    className="fill-black text-current antialiased mr-2 w-28 sm:mr-0 text-[#C07759]"
    fillColor="#d3d3d0"
  />
);
