import React from 'react';
export function Edit({
  className,
  fillColor,
}: {
  className?: string;
  fillColor?: string;
}) {
  return (
    <svg className={className} viewBox="0 0 24 24" style={{ fill: fillColor }}>
      <g data-name="Bounding Box">
        <path style={{ fill: 'none' }} d="M0,0V24H24V0Z" />
      </g>
      <g data-name="Icons Outlined">
        <g>
          <path d="M19,10.625a.3751.3751,0,0,0-.375.375v6A1.6262,1.6262,0,0,1,17,18.625H7A1.6262,1.6262,0,0,1,5.375,17V7A1.6262,1.6262,0,0,1,7,5.375h6a.375.375,0,0,0,0-.75H7A2.3777,2.3777,0,0,0,4.625,7V17A2.3777,2.3777,0,0,0,7,19.375H17A2.3777,2.3777,0,0,0,19.375,17V11A.3751.3751,0,0,0,19,10.625Z" />
          <path d="M21.2656,4.7344l-2-2a.376.376,0,0,0-.5312,0l-9,9a.3841.3841,0,0,0-.09.1474l-1,3A.3737.3737,0,0,0,9,15.375a.351.351,0,0,0,.1182-.02l2.9931-.9971a.3837.3837,0,0,0,.1533-.0918l.001-.001,9-9a.3771.3771,0,0,0,0-.5312Zm-9.4677,8.9375-2.2051.7353.7353-2.2051L17,5.5308l1.47,1.47Zm7.2026-7.2027L17.5308,5,19,3.53,20.47,5Z" />
        </g>
      </g>
    </svg>
  );
}
