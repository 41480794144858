import dynamic from 'next/dynamic';

const HotelLogo = dynamic<{ className?: string }>(() =>
  import('./msyrhwa/hotel-logo').then((i) => i.HotelLogo)
);

export const hotelLogoHeader = (
  <HotelLogo className="fill-black text-current antialiased w-48 md:w-52" />
);

export const hotelLogoFooter = (
  <HotelLogo className="fill-white text-current antialiased w-48 text-[#C07759]" />
);
