import React from 'react';
export function ArrowLeft({
  className,
  fillColor,
}: {
  className?: string;
  fillColor?: string;
}) {
  return (
    <svg
      className={className}
      viewBox="0 0 79.85 148.29"
      style={{ fill: fillColor }}
    >
      <g data-name="arrow-left">
        <path
          d="M0 74.14a5.69 5.69 0 011.67-4L70.1 1.67a5.71 5.71 0 018.08 8.07l-64.41 64.4 64.41 64.4a5.71 5.71 0 01-8.08 8.08L1.67 78.18a5.73 5.73 0 01-1.67-4"
          data-name="Arrow Left"
        />
      </g>
    </svg>
  );
}
