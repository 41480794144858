import React from 'react';

export function FaceBook({
  className,
  fillColor,
}: {
  className?: string;
  fillColor?: string;
}) {
  return (
    <svg
      className={className}
      fill={fillColor}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="m0 0v24h24v-24z" fill="none" />
      <path d="m13.26 20.5h-3.125c-.276 0-.5-.224-.5-.5v-7.125h-1.562c-.276 0-.5-.224-.5-.5v-2.5c0-.276.224-.5.5-.5h1.562v-1.292c0-.584.052-1.146.155-1.669.111-.564.331-1.07.651-1.504.331-.446.805-.8 1.409-1.049.586-.243 1.32-.361 2.243-.361.335 0 .663.015.983.044.323.029.648.082.965.157.226.054.385.255.385.486v2.467c0 .136-.056.266-.153.36-.099.095-.228.149-.366.14-.197-.008-.955-.029-1.146-.029-.369 0-.646.069-.798.202-.035.029-.203.211-.203.943v1.104l2.166-.005c.145 0 .282.062.377.172.096.109.139.255.118.398l-.354 2.506c-.035.246-.246.43-.495.43h-1.812v7.125c0 .276-.224.5-.5.5zm-2.625-1h2.125v-7.125c0-.276.224-.5.5-.5h1.878l.213-1.505-2.09.005c-.133 0-.26-.053-.353-.146-.094-.094-.147-.221-.147-.354v-1.604c0-.827.18-1.384.55-1.701.341-.295.83-.444 1.451-.444.109 0 .396.007.666.014v-1.542c-.145-.024-.291-.043-.44-.057-.292-.027-.589-.04-.893-.04-.778 0-1.404.096-1.861.285-.435.18-.766.422-.987.72-.232.313-.392.685-.474 1.103-.091.46-.137.956-.137 1.476v1.792c0 .276-.224.5-.5.5h-1.562v1.5h1.562c.276 0 .5.224.5.5v7.125z" />
    </svg>
  );
}
