import React from 'react';
export function Flower({
  className,
  fillColor,
}: {
  className?: string;
  fillColor?: string;
}) {
  return (
    <svg className={className} viewBox="0 0 24 24" style={{ fill: fillColor }}>
      <g data-name="Bounding Box">
        <path style={{ fill: 'none' }} d="M0,0V24H24V0Z" />
      </g>
      <g data-name="Icons Outlined">
        <g>
          <path d="M9.2285,16.3184a4.6745,4.6745,0,0,0-2.3887.3339.3748.3748,0,0,0-.19.4717,4.36,4.36,0,0,0,1.63,1.8721,2.6915,2.6915,0,0,0,1.4678.4385,2.1526,2.1526,0,0,0,.6982-.1094,1.2216,1.2216,0,0,0,.8076-.7471,1.2941,1.2941,0,0,0-.2021-1.09A2.6271,2.6271,0,0,0,9.2285,16.3184Zm1.3135,2.0234a.5275.5275,0,0,1-.34.2744,1.8417,1.8417,0,0,1-1.5068-.2432,3.9971,3.9971,0,0,1-1.1611-1.1748,4.0537,4.0537,0,0,1,1.6006-.1357,1.9054,1.9054,0,0,1,1.2812.8252.6.6,0,0,1,.126.4531Z" />
          <path d="M17.1992,13.4941a4.19,4.19,0,0,0-2.4043-.4687,2.6545,2.6545,0,0,0-1.9023,1.085,1.2732,1.2732,0,0,0-.208,1.0966,1.2412,1.2412,0,0,0,.8008.7451,2.402,2.402,0,0,0,.8369.1465,2.5627,2.5627,0,0,0,1.3144-.3564,4.958,4.958,0,0,0,1.6963-1.7578A.3751.3751,0,0,0,17.1992,13.4941Zm-1.9521,1.6075a1.8445,1.8445,0,0,1-1.4991.1484.57.57,0,0,1-.3486-.27V14.98a.5777.5777,0,0,1,.1172-.4522,1.89,1.89,0,0,1,1.34-.7539c.1153-.01.2266-.0136.334-.0136a3.7162,3.7162,0,0,1,1.2608.2129A4.2705,4.2705,0,0,1,15.2471,15.1016Z" />
          <path d="M12.27,12.3643h.07a3.8946,3.8946,0,0,0,2.8243-1.211,2.855,2.855,0,0,0,.6533-2.2324l-.0283-.2666a6.7274,6.7274,0,0,1-.087-1.3877l.0157-.3994a2.7906,2.7906,0,0,1,.7041-2.0127.3511.3511,0,0,0,.0846-.1628c.003-.008.009-.0143.0115-.0224a.3238.3238,0,0,0,.0134-.1385l.0008-.0083a.3655.3655,0,0,0-.0492-.1475c-.0089-.0143-.0032-.0325-.0142-.0459s-.0309-.0113-.0438-.0231a.3732.3732,0,0,0-.2034-.1071l-.0169-.0019a.3813.3813,0,0,0-.0836-.01,11.8571,11.8571,0,0,0-1.2983.2307A10.1241,10.1241,0,0,0,11.8623,2.65.3849.3849,0,0,0,11.6,2.6465a12.0325,12.0325,0,0,0-1.6523.75.3751.3751,0,1,0,.3672.6543A11.2608,11.2608,0,0,1,11.72,3.4023,10.6162,10.6162,0,0,1,13.96,4.6489C12.13,5.2134,9.8047,6.4221,9.8047,8.9277a.375.375,0,0,0,.75,0c0-2.5783,3.2653-3.5437,4.8263-3.8586a4.2558,4.2558,0,0,0-.4132,1.7747l-.0147.3906a7.2229,7.2229,0,0,0,.09,1.5l.0283.2666a2.0924,2.0924,0,0,1-.4736,1.6611,3.1853,3.1853,0,0,1-2.2715.9522h-.29c-.0078,0-.0142.0039-.0219.0044s-.0142-.0044-.022-.0044H11.624A3.1925,3.1925,0,0,1,9.34,10.6631,2.0963,2.0963,0,0,1,8.8652,9.001l.0264-.2471a7.3984,7.3984,0,0,0,.0928-1.5146L8.97,6.8438a4.2412,4.2412,0,0,0-.42-1.7881,13.4533,13.4533,0,0,1,2.0493.5293.37.37,0,0,0,.1241.0214.3748.3748,0,0,0,.124-.7285,14.364,14.364,0,0,0-3.0479-.69.3524.3524,0,0,0-.07.0073l-.0069.0007a.3579.3579,0,0,0-.12.035l-.0265.0144a.3715.3715,0,0,0-.08.064c-.007.0071-.0162.01-.0227.0182s-.0089.0186-.0151.0273a.3546.3546,0,0,0-.0331.06.3588.3588,0,0,0-.0288.0935c-.0009.0058-.0045.01-.0051.0163s.0019.01.0016.0151a.3683.3683,0,0,0,.0139.1332v0a.3687.3687,0,0,0,.1085.1815A2.79,2.79,0,0,1,8.22,6.8672l.0147.4033a6.8436,6.8436,0,0,1-.0879,1.4024l-.0274.248a2.8541,2.8541,0,0,0,.6543,2.2334,3.8579,3.8579,0,0,0,2.7057,1.1938,5.6446,5.6446,0,0,0,.1752,4.4263c.9639,1.6924-.01,4.0567-.0195,4.08a.3761.3761,0,0,0,.2.4912.3834.3834,0,0,0,.1455.0293.3747.3747,0,0,0,.3457-.23c.0459-.11,1.1289-2.7256-.02-4.7422A5.1389,5.1389,0,0,1,12.27,12.3643Z" />
        </g>
      </g>
    </svg>
  );
}
