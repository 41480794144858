import React from 'react';
export function Music({
  className,
  fillColor,
}: {
  className?: string;
  fillColor?: string;
}) {
  return (
    <svg className={className} viewBox="0 0 24 24" style={{ fill: fillColor }}>
      <g data-name="Bounding Box">
        <path style={{ fill: 'none' }} d="M0,0V24H24V0Z" />
      </g>
      <g data-name="Icons Outlined">
        <path d="M18.2412,4.7129a.3754.3754,0,0,0-.3047-.082L10.2549,5.9531a.3743.3743,0,0,0-.3115.3692V16.5859a1.9058,1.9058,0,0,1-1.5772,2.0127,1.7788,1.7788,0,0,1-1.3574-.3095,1.5415,1.5415,0,0,1-.6192-1.043,1.8694,1.8694,0,0,1,2.5792-1.8467.3749.3749,0,0,0,.2949-.6894,2.613,2.613,0,0,0-1.4385-.167,2.5727,2.5727,0,0,0-2.18,2.8027,2.29,2.29,0,0,0,.918,1.5469,2.4779,2.4779,0,0,0,1.4834.4834,2.7345,2.7345,0,0,0,.4424-.0371,2.6564,2.6564,0,0,0,2.2041-2.753V9.74L17.625,8.6531v6.6106a1.9058,1.9058,0,0,1-1.5771,2.0127,1.78,1.78,0,0,1-1.3575-.31,1.5418,1.5418,0,0,1-.6191-1.043,1.8678,1.8678,0,0,1,2.5625-1.8525.3751.3751,0,0,0,.291-.6914,2.5476,2.5476,0,0,0-1.418-.1592,2.5744,2.5744,0,0,0-2.18,2.8027,2.29,2.29,0,0,0,.918,1.5469,2.5144,2.5144,0,0,0,1.9258.4463,2.6562,2.6562,0,0,0,2.2041-2.7529V5A.375.375,0,0,0,18.2412,4.7129ZM17.625,7.8945,10.6934,8.9818V6.6387L17.625,5.4453Z" />
      </g>
    </svg>
  );
}
