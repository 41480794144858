import React from 'react';
export function Concierge({
  className,
  fillColor,
}: {
  className?: string;
  fillColor?: string;
}) {
  return (
    <svg className={className} viewBox="0 0 24 24" style={{ fill: fillColor }}>
      <g data-name="Bounding Box">
        <path style={{ fill: 'none' }} d="M0,0V24H24V0Z" />
      </g>
      <g data-name="Icons Outlined">
        <g>
          <path d="M10,16.375a.625.625,0,1,0,.625.625A.6256.6256,0,0,0,10,16.375Z" />
          <path d="M10,18.375a.625.625,0,1,0,.625.625A.6256.6256,0,0,0,10,18.375Z" />
          <path d="M14,16.375a.625.625,0,1,0,.625.625A.6256.6256,0,0,0,14,16.375Z" />
          <path d="M14,18.375a.625.625,0,1,0,.625.625A.6256.6256,0,0,0,14,18.375Z" />
          <path d="M11,13.625a1.6277,1.6277,0,0,1-1.625-1.57V12H9.374a.375.375,0,0,0-.75,0l.001,1.6406A4.3819,4.3819,0,0,0,4.625,18v2a.375.375,0,0,0,.75,0V18a3.6287,3.6287,0,0,1,3.624-3.625A.3751.3751,0,0,0,9.374,14V13.73a2.3723,2.3723,0,0,0,1.251.6162V15a.375.375,0,0,0,.75,0V14A.3751.3751,0,0,0,11,13.625Z" />
          <path d="M15.376,13.6406l-.001-1.5752c.001-.0215.001-.0429.001-.0654h-.001a.375.375,0,0,0-.75,0A1.6262,1.6262,0,0,1,13,13.625a.3751.3751,0,0,0-.375.375v1a.375.375,0,0,0,.75,0v-.6543a2.3751,2.3751,0,0,0,1.25-.6152V14a.3752.3752,0,0,0,.375.375A3.6289,3.6289,0,0,1,18.625,18v2a.375.375,0,0,0,.75,0V18A4.3818,4.3818,0,0,0,15.376,13.6406Z" />
          <path d="M9,6.875h6a.3751.3751,0,0,0,.375-.375c0-.4805-.0664-2.875-1.375-2.875H10C8.6914,3.625,8.625,6.02,8.625,6.5A.3751.3751,0,0,0,9,6.875Zm1-2.5h4c.3125,0,.5566.8613.6123,1.75H9.3877C9.4434,5.2363,9.6875,4.375,10,4.375Z" />
          <path d="M15.375,9a4.3632,4.3632,0,0,0-.2061-1.2988.38.38,0,0,0-.47-.2471.3758.3758,0,0,0-.2471.47A3.5635,3.5635,0,0,1,14.625,9,2.9116,2.9116,0,0,1,12,12.125,2.9116,2.9116,0,0,1,9.375,9a3.5635,3.5635,0,0,1,.1729-1.0762.3758.3758,0,0,0-.2471-.47.38.38,0,0,0-.47.2471A4.3632,4.3632,0,0,0,8.625,9,3.655,3.655,0,0,0,12,12.875,3.655,3.655,0,0,0,15.375,9Z" />
        </g>
      </g>
    </svg>
  );
}
