import cx from 'classnames';
import { useState } from 'react';

interface Props {
  allCatsText?: string;
  activeCat?: string;
  setActiveCat?: any;
  cats?: Array<{
    name?: string;
    slug?: string;
  }>;
  styleOptions?: {
    filterActiveTextColour?: string;
    filterTextColour?: string;
  };
}

export function CategoryFilters({
  allCatsText,
  activeCat,
  setActiveCat,
  cats,
  styleOptions,
}: Props) {
  const buttonClass = 'text-xl px-2 py-1 relative';
  const activeClass = 'font-black text-primary';
  const activeUnderline = (
    <span
      data-testid="activeUnderline"
      className="absolute inset-x-0 bottom-0 h-0.5 w-full bg-primary"
      style={{ backgroundColor: styleOptions?.filterActiveTextColour }}
    ></span>
  );
  const itemUnderline = (isActiveItem: boolean) => {
    return (
      <span
        className={cx(
          'absolute inset-x-0 bottom-0 h-0.5 w-full bg-primary transform transition-all duration-300 ease-in-out',
          {
            'opacity-100 w-full': isActiveItem,
            'opacity-0 w-4/5': !isActiveItem,
          }
        )}
      ></span>
    );
  };

  const [liHover, setLiHover] = useState<string | undefined>(undefined);

  const categories = cats?.map((item) => {
    const isActive = activeCat === item?.slug ? true : false;
    return (
      <li
        key={item?.slug}
        className="w-1/2 md:w-auto"
        onMouseEnter={() => {
          setLiHover(item?.slug);
        }}
        onMouseLeave={() => {
          setLiHover(undefined);
        }}
      >
        <button
          className={cx(buttonClass, isActive ? activeClass : 'text-text')}
          style={{
            color: isActive
              ? styleOptions?.filterActiveTextColour
              : styleOptions?.filterTextColour,
          }}
          onClick={() => setActiveCat(item?.slug)}
        >
          {item?.name}
          {isActive ? activeUnderline : itemUnderline(liHover === item?.slug)}
        </button>
      </li>
    );
  });

  return (
    <ul
      data-testid="categoryFilters"
      className="flex flex-wrap md:flex-nowrap max-w-screen-lg w-full m-auto justify-between items-center px-1 py-4"
    >
      <li className="w-1/2 md:w-auto">
        <button
          data-testid="allCategories"
          className={cx(
            buttonClass,
            activeCat === '' ? activeClass : 'text-text'
          )}
          style={{
            color:
              activeCat === ''
                ? styleOptions?.filterActiveTextColour
                : styleOptions?.filterTextColour,
          }}
          onClick={() => {
            setActiveCat('');
          }}
        >
          {allCatsText}
          {activeCat === '' ? activeUnderline : false}
        </button>
      </li>
      {categories}
    </ul>
  );
}
