import { GenericModal, GenericModalProps } from '../../global/generic-modal';

import cx from 'classnames';
import { RoomsCTA, RoomsCTAProps } from './rooms-cta';

export interface RoomVirtualTourModalProps extends GenericModalProps {
  assetUrl?: string;
  ctaData?: RoomsCTAProps;
}

export const RoomVirtualTourModal = (props: RoomVirtualTourModalProps) => {
  const { assetUrl, ctaData } = props;

  return (
    <GenericModal
      {...props}
      modalChild={
        <div className="flex flex-col p-2">
          <div className="p-4 bg-bg-primary text-sm font-bold mb-2">
            Tour is a representation. Property details may change without
            notice.
          </div>
          <iframe
            data-testid="virtual-tour-iframe"
            className="landscape:mx-auto lg:w-[100vh] max-w-[90vh] max-h-[80vh] portrait:aspect-[2/3] landscape:aspect-video mb-2"
            src={assetUrl}
          ></iframe>
          <div className="flex justify-end">
            <RoomsCTA {...ctaData} />
          </div>
        </div>
      }
    />
  );
};
