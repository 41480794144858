import React from 'react';
export function Breakfast({
  className,
  fillColor,
}: {
  className?: string;
  fillColor?: string;
}) {
  return (
    <svg className={className} viewBox="0 0 24 24" style={{ fill: fillColor }}>
      <g data-name="Bounding Box">
        <path style={{ fill: 'none' }} d="M0,0V24H24V0Z" />
      </g>
      <g data-name="Icons Outlined">
        <g>
          <path d="M19,17.625H16a.375.375,0,0,0,0,.75h3a.375.375,0,0,0,0-.75Z" />
          <path d="M14.2456,17.3986a1.7329,1.7329,0,0,0,.228-.8918,2.1406,2.1406,0,0,0-.5263-1.1289,2.8917,2.8917,0,0,0-2.3463-.959,2.3533,2.3533,0,0,0-.1-.49c-.0039-.0088-.0069-.0176-.0117-.0264a2.8171,2.8171,0,0,0-2.5-1.2773A2.8084,2.8084,0,0,0,6.498,13.9287a3.0322,3.0322,0,0,0,.0684,1.91l1.4093,3.9183a.49.49,0,0,1-.2687.19.658.658,0,0,1-.4248-.1182L4.627,17.374a1.283,1.283,0,0,1-.3575-.8281,1.4287,1.4287,0,0,1,.36-.6963,1.8494,1.8494,0,0,1,1.1172-.6318.3736.3736,0,0,0,.2929-.4414.3686.3686,0,0,0-.4414-.293,2.5959,2.5959,0,0,0-1.539.88,2.1418,2.1418,0,0,0-.5381,1.1289,1.7248,1.7248,0,0,0,.2324.9067,4.8632,4.8632,0,0,0-1.1133,2.4282c-.0664.4581.0283,1.3614.8389,1.504a1.8763,1.8763,0,0,0,.4424.0429A4.7759,4.7759,0,0,0,6.6984,20.31l.0926.0858a1.4552,1.4552,0,0,0,.8516.3076,1.1876,1.1876,0,0,0,.1826-.0146,1.1384,1.1384,0,0,0,.4368-.1772.8238.8238,0,0,0,1.4755.0044,1.1363,1.1363,0,0,0,.4314.1728,1.1879,1.1879,0,0,0,.1827.0146,1.4683,1.4683,0,0,0,.87-.3232l.0773-.0718a4.7864,4.7864,0,0,0,2.78,1.0679,1.7492,1.7492,0,0,0,.4131-.0391c.8389-.1484.9336-1.0517.8711-1.4717A4.9064,4.9064,0,0,0,14.2456,17.3986Zm-10.6069,3.2c-.334-.0595-.2569-.6582-.252-.7011a3.9123,3.9123,0,0,1,.8363-1.8793l1.909,1.7673A3.4655,3.4655,0,0,1,3.6387,20.5986Zm5.47-.5068c-.0068.0205-.0469.125-.0967.1309-.0771-.0049-.1074-.0811-.1269-.1465l-1.61-4.4854a2.3354,2.3354,0,0,1-.0849-1.374c.3554-.7656,1.5781-.8359,1.8-.8418.24.0059,1.4472.0752,1.8115.8252a2.3042,2.3042,0,0,1-.0733,1.3848Zm1.6221-.2783a.7019.7019,0,0,1-.4405.1347.4885.4885,0,0,1-.2667-.1856l1.4122-3.93a3.4281,3.4281,0,0,0,.1533-.6683,2.2108,2.2108,0,0,1,1.7882.7,1.428,1.428,0,0,1,.3486.6894,1.2368,1.2368,0,0,1-.3252.7832Zm3.6064.79a3.4727,3.4727,0,0,1-2.4727-.82l1.9077-1.7716a3.99,3.99,0,0,1,.8453,1.9229C14.6182,19.94,14.6953,20.5391,14.3369,20.6035Z" />
          <path d="M14,5.2578h1.25a.3332.3332,0,1,1,0,.6611A1.1054,1.1054,0,0,0,14.125,7a.375.375,0,0,0,.75,0,.3553.3553,0,0,1,.375-.3311,1.0814,1.0814,0,1,0,0-2.1611H14a.5984.5984,0,0,1-.625-.5664A.5984.5984,0,0,1,14,3.375h3a.375.375,0,0,0,0-.75H14a1.3486,1.3486,0,0,0-1.375,1.3164A1.3486,1.3486,0,0,0,14,5.2578Z" />
          <path d="M19.5,9.625h-.125V9A.3751.3751,0,0,0,19,8.625H11A.3751.3751,0,0,0,10.625,9v3a.375.375,0,0,0,.75,0V9.375h7.25V13.2A2.5082,2.5082,0,0,1,16,15.625h-.5a.375.375,0,0,0,0,.75h.5908a3.2332,3.2332,0,0,0,3.266-3H19.5a1.875,1.875,0,0,0,0-3.75Zm0,3h-.125v-2.25H19.5a1.125,1.125,0,0,1,0,2.25Z" />
        </g>
      </g>
    </svg>
  );
}
