import { FooterProps } from '@curated-property/shared-components';
import dynamic from 'next/dynamic';

const SharedFooter = dynamic<FooterProps>(() =>
  import('@curated-property/shared-components').then((f) => f.Footer)
);

// this override footer
export const Footer = (props: FooterProps) => {
  return (
    <SharedFooter
      {...props}
      styleOptions={{
        backgroundImage: 'linear-gradient(to right, #009775, #0095C8)',
      }}
    />
  );
};
