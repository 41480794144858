import dynamic from 'next/dynamic';

const HotelLogo = dynamic<{ className: string; fillColor?: string }>(() =>
  import('./laxbhhh/hotel-logo').then((i) => i.HotelLogo)
);
const HotelLogoVertical = dynamic<{ className: string; fillColor?: string }>(
  () => import('./laxbhhh/hotel-logo-vertical').then((i) => i.HotelLogo)
);

export const hotelLogoHeader = (
  <HotelLogo
    className="fill-gold text-bg antialiased w-44 md:w-48 lg:w-44"
    fillColor="#BA0C2F"
  />
);

export const hotelLogoFooter = (
  <HotelLogoVertical
    className="fill-gold antialiased w-28 md:w-auto h-20"
    fillColor="#FFFFFF"
  />
);
