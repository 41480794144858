import React from 'react';

export function EnvelopeIcon({
  className,
  fillColor,
}: {
  className?: string;
  fillColor?: string;
}) {
  return (
    <svg
      className={className}
      fill={fillColor}
      viewBox="0 0 1024 1024"
      aria-hidden="true"
    >
      <path d="M956.416 894.976H67.584c-34.816 0-63.488-28.672-63.488-65.536V235.52c0-34.816 28.672-63.488 63.488-63.488h890.88c34.816 0 65.536 28.672 65.536 63.488v593.92c-4.096 36.864-32.768 65.536-67.584 65.536zM67.584 202.752c-18.432 0-32.768 16.384-32.768 32.768v593.92c0 18.432 16.384 34.816 32.768 34.816h888.832c22.528 0 34.816-18.432 36.864-34.816V237.568c0-18.432-16.384-32.768-34.816-32.768H67.584z" />
      <path d="M512 636.928c-40.96 0-83.968-16.384-114.688-47.104L30.72 223.232l32.768-32.768L430.08 557.056c45.056 45.056 118.784 45.056 163.84 0l366.592-366.592 32.768 32.768-366.592 366.592c-30.72 30.72-73.728 47.104-114.688 47.104z" />
      <path d="M30.425 841.57l325.832-325.832 33.307 33.307L63.732 874.877 30.425 841.57zM634.518 549.106l33.307-33.307 325.832 325.832-33.307 33.307-325.832-325.832z" />
    </svg>
  );
}
