import { getOrdinal } from '@curated-property/utils';
import React, { SetStateAction } from 'react';
import { useContext } from 'react';
import { WACContext, WACStages } from '..';
import { WACStyledRadio } from '../utils';
import { WACStyles } from '../interfaces';

export const WAVenueDateSection = function ({ styles }: { styles: WACStyles }) {
  const {
    stage,
    selectedDate,
    venues,
    timeSlots,
    setSelectedTimeSlot,
    selectedTimeSlot,
    selectedVenue,
    setSelectedVenue,
    fullAvailability,
  } = useContext(WACContext);

  const toggleVenue = (new_val: SetStateAction<string>) => {
    if (new_val === selectedVenue) {
      setSelectedVenue?.('');
    } else {
      setSelectedVenue?.(new_val);
    }
  };

  const toggleTimeSlots = (new_val: SetStateAction<string>) => {
    if (new_val === selectedTimeSlot) {
      setSelectedTimeSlot?.('');
    } else {
      setSelectedTimeSlot?.(new_val);
    }
  };

  if (stage !== WACStages.VENUE && stage !== WACStages.SEND_EMAIL) return null;

  const dayData =
    fullAvailability?.[selectedDate?.getFullYear() || 0]?.[
      selectedDate?.getMonth() || 0
    ]?.[selectedDate?.getDate() || 0];
  return (
    <div
      className={`${
        stage !== WACStages.VENUE && stage !== WACStages.SEND_EMAIL && 'hidden'
      } w-full pt-4 pb-12 mx-auto`}
      style={{
        backgroundColor: styles?.background?.primary,
      }}
    >
      <div data-testid="chosenDate" className="text-center text-2xl">
        You have chosen -{' '}
        <strong>
          {selectedDate?.getDate()}
          {getOrdinal(selectedDate?.getDate() || 0)}{' '}
          {selectedDate?.toLocaleDateString('en-US', {
            month: 'long',
          })}{' '}
          {selectedDate?.getFullYear()}
        </strong>
      </div>
      <div className="text-center my-4">
        Select venues and times for your ceremony and reception.
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-8 max-w-3xl m-auto">
        <div
          data-testid="venueTimes"
          className="p-4"
          style={{
            backgroundColor: styles?.background?.secondary,
          }}
        >
          <fieldset className="flex flex-col gap-2">
            <legend className="font-bold mb-2">Wedding Venue Times:</legend>
            {timeSlots?.map((slot, idx) => (
              <WACStyledRadio
                autoFocus={idx === 0}
                disabled={!!dayData?.ceremonyTimes[slot]}
                value={slot}
                handler={toggleTimeSlots}
                checked={slot === selectedTimeSlot}
                styles={styles?.radio}
                name="venue-times"
              />
            ))}
          </fieldset>
        </div>
        <div
          data-testid="receptionVenues"
          className="p-4"
          style={{
            backgroundColor: styles?.background?.secondary,
          }}
        >
          <fieldset className="flex flex-col gap-2">
            <legend className="font-bold mb-2">Reception Venues:</legend>
            <WACStyledRadio
              disabled={false}
              label={'Venue not required'}
              value={'N/A'}
              handler={toggleVenue}
              checked={'N/A' === selectedVenue}
              styles={styles?.radio}
              name="reception-venue"
            />
            {venues?.map((venue) => (
              <WACStyledRadio
                disabled={!!dayData?.receptionVenues[venue]}
                value={venue}
                handler={toggleVenue}
                checked={venue === selectedVenue}
                styles={styles?.radio}
                name="reception-venue"
              />
            ))}
          </fieldset>
        </div>
        <div
          data-element-id="wac-you-have-chosen"
          className="col-span-1 md:col-span-2 xl:col-span-1 flex items-center justify-center"
          style={{
            backgroundColor:
              selectedTimeSlot || selectedVenue
                ? styles?.thirdPanel?.active.backgroundColor
                : styles?.thirdPanel?.inactive.backgroundColor,
            color:
              selectedTimeSlot || selectedVenue
                ? styles?.thirdPanel?.active.textColor
                : styles?.thirdPanel?.inactive.textColor,
          }}
        >
          <div className="w-48 text-center p-4">
            {selectedTimeSlot || selectedVenue ? (
              <div>
                <h3 className="font-bold mb-4">You have chosen</h3>
                <div className="flex flex-col gap-4">
                  {selectedTimeSlot && (
                    <div>
                      A wedding venue time slot:
                      <div className="text-2xl font-bold">
                        {selectedTimeSlot}
                      </div>
                    </div>
                  )}
                  {selectedVenue && (
                    <div>
                      The Reception Venue:
                      {selectedVenue === 'N/A' ? (
                        <div className="font-bold">Not applicable.</div>
                      ) : (
                        <div className="text-2xl font-bold">
                          {selectedVenue}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <strong>Choose a time and reception venue</strong>
            )}
          </div>
        </div>
      </div>
      <div className="text-center text-sm mt-4">
        * The Infinity/Ocean Garden must be booked as an accompanying reception
        venue with 5pm ceremony timeslots.
      </div>
    </div>
  );
};
