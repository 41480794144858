/* Higueron Hotel Malaga, Curio Collection by Hilton – agprhqq */
import { BreadcrumbProps } from '@curated-property/shared-components';
import dynamic from 'next/dynamic';

const SharedBreadCrumbs = dynamic<BreadcrumbProps>(() =>
  import('@curated-property/shared-components').then((b) => b.BreadCrumbs)
);

// this overrides breadcrumbs
export const BreadCrumbs = (props: BreadcrumbProps) => {
  return (
    <SharedBreadCrumbs
      {...props}
      delimiter={<span className="px-2 text-white -tx-2">/</span>}
    />
  );
};
