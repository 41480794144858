import React from 'react';
export function Dining({
  className,
  fillColor,
}: {
  className?: string;
  fillColor?: string;
}) {
  return (
    <svg className={className} viewBox="0 0 24 24" style={{ fill: fillColor }}>
      <g data-name="Bounding Box">
        <path style={{ fill: 'none' }} d="M0,0V24H24V0Z" />
      </g>
      <g data-name="Icons Outlined">
        <g>
          <path d="M12,7.375A1.375,1.375,0,1,0,10.625,6,1.377,1.377,0,0,0,12,7.375Zm0-2A.625.625,0,1,1,11.375,6,.6256.6256,0,0,1,12,5.375Z" />
          <path d="M5,16.375A.3751.3751,0,0,0,5.375,16a6.625,6.625,0,0,1,13.25,0,.375.375,0,0,0,.75,0,7.375,7.375,0,0,0-14.75,0A.3751.3751,0,0,0,5,16.375Z" />
          <path d="M9.9414,11.0322a5.3452,5.3452,0,0,0-2.9092,2.9092.3756.3756,0,0,0,.2032.49.3708.3708,0,0,0,.1435.0284.3765.3765,0,0,0,.3467-.2315,4.598,4.598,0,0,1,2.5029-2.5029.3752.3752,0,0,0-.2871-.6934Z" />
          <path d="M20,17.625H4a.375.375,0,0,0,0,.75H20a.375.375,0,0,0,0-.75Z" />
        </g>
      </g>
    </svg>
  );
}
