import {
  BreadcrumbProps,
  FooterProps,
} from '@curated-property/shared-components';
import dynamic from 'next/dynamic';
import { AdditionalHeadProps } from '../pages/_app';

// this override footer
const SharedFooter = dynamic<FooterProps>(() =>
  import('@curated-property/shared-components').then((f) => f.Footer)
);

export const Footer = (props: FooterProps) => {
  switch (props?.pageSlug) {
    case 'estate':
      return (
        <SharedFooter
          {...props}
          showCopyright={true}
          styleOptions={{
            backgroundColor: 'bg-[#00564c]',
            backgroundImage:
              'https://assets.hiltonstatic.com/images/v1639138829/dx/wp/sinorhi-hilton-singapore-orchard/media-library/paper-texture-green/paper-texture-green.jpg?_i=AA',
            textColor: 'text-white',
            copyrightStyles: {
              backgroundColor: 'bg-just-off-white',
              textColor: 'text-white',
            },
          }}
        />
      );
    default:
      return <SharedFooter {...props}></SharedFooter>;
  }
};

const SharedBreadCrumbs = dynamic<BreadcrumbProps>(() =>
  import('@curated-property/shared-components').then((b) => b.BreadCrumbs)
);

// this overrides breadcrumbs
export const BreadCrumbs = (props: BreadcrumbProps) => {
  switch (props?.pageSlug) {
    case 'estate':
      return (
        <SharedBreadCrumbs
          {...props}
          styleOptions={{
            background: 'bg-[#E2DCD9]',
            anchorClasses: 'text-black hover:underline',
            activeAnchorClasses: 'text-black font-bold inline-block',
          }}
          delimiter={<span className="text-black px-2">/</span>}
        />
      );
    default:
      return <SharedBreadCrumbs {...props} />;
  }
};

export const AdditionalHead = ({ pageSlug }: AdditionalHeadProps) => {
  switch (pageSlug) {
    case 'ginger-lily':
      return (
        <link rel="stylesheet" href="https://use.typekit.net/dtl6kqg.css" />
      );
    case 'estate':
      return (
        <>
          <style>{`
            @font-face {
              font-family: Avenir Next;
              font-weight: 200;
              font-display: swap;
              src: url('/modules/assets/fonts/avenir-next/regular.woff')
                format('woff'),
                url('/modules/assets/fonts/avenir-next/regular.woff2')
                format('woff2');
            }
            @font-face {
              font-family: Avenir Next;
              font-weight: 600;
              font-display: swap;
              src: url('/modules/assets/fonts/avenir-next/bold.woff')
                format('woff'),
                url('/modules/assets/fonts/avenir-next/bold.woff2')
                format('woff2');
            }
          `}</style>
        </>
      );
  }
};
