import React from 'react';

const NonSmokingIcon = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg viewBox="0 0 44 44" focusable="false" {...props}>
      <g strokeWidth="1.5" fill="none" fillRule="evenodd" stroke="currentColor">
        <path d="M40.047 21.862c0-9.45-7.678-17.112-17.149-17.112-9.47 0-17.148 7.662-17.148 17.112s7.677 17.111 17.148 17.111 17.149-7.661 17.149-17.111z" />
        <path d="M27.96 11.94c1.097 0 3.215.199 3.448 2.948 1.04 0 4.116.405 2.462 4.422M25.007 11.448c-.493.492-2.983 4.713 2.462 5.405 2.533.323 3.585.854 3.939 2.457M11.22 21.276h23.635v3.931H11.22zM32.22 21.276v3.93M29.22 21.276v3.93M10.5 10.5l24.454 23.758" />
      </g>
    </svg>
  );
};

export default NonSmokingIcon;
