/* Domes Aulus Elounda by Curio – herbsqq */

export const AdditionalHead = () => {
  return (
    <>
      <link rel="stylesheet" href="https://use.typekit.net/bpe2bcv.css"></link>
      <style>
        {`
                :root {
                  --font-headline: "fahkwang", sans-serif; !important;
                  --font-button: "effra", sans-serif !important; 
                  --font-sans:  "effra", sans-serif !important;
                }

                
                h1,h2,h3,
                h1 p,
                h2 p,
                h3 p,
                h1 p span,
                h2 p span,
                h3 p span,
                .font-headline,.font-black {
                  font-family: "fahkwang", sans-serif!important;
                  font-style: normal;
                  font-weight: 400 !important;
                }
  
      
                div,p,ul,ul li, blockquote,em, ul a, ol, ol li,label, label span {
                    font-family:  "effra", sans-serif !important; 
                    font-weight: 400 !important;
                    font-style: normal;
                }

                .btn, button {
                  font-family: "effra", sans-serif !important; 
                  font-style: normal;
                  font-weight: 400 !important;
                }
  
                [data-element-id="navDesktop"] {
                  font-family:  "effra", sans-serif !important; 
                  font-style: normal;
                  font-weight: 400;
                }
               
      
              `}
      </style>
    </>
  );
};
