import dynamic from 'next/dynamic';

const HotelLogo = dynamic<{ className?: string; fillColor?: string }>(() =>
  import('./bhxmetw/hotel-logo').then((i) => i.HotelLogo)
);

export const hotelLogoHeader = (
  <HotelLogo className="text-bg antialiased h-20" fillColor="#FFF" />
);

export const hotelLogoFooter = (
  <HotelLogo className="text-bg antialiased h-24" fillColor="#FFF" />
);
