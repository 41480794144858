import React from 'react';
export function Bed({
  className,
  fillColor,
}: {
  className?: string;
  fillColor?: string;
}) {
  return (
    <svg className={className} viewBox="0 0 24 24" style={{ fill: fillColor }}>
      <g data-name="Bounding Box">
        <path style={{ fill: 'none' }} d="M0,0V24H24V0Z" />
      </g>
      <g data-name="Icons Outlined">
        <g>
          <path d="M19.375,11.6629V7A2.3777,2.3777,0,0,0,17,4.625H7A2.3777,2.3777,0,0,0,4.625,7v4.6629A2.3726,2.3726,0,0,0,2.625,14v5a.375.375,0,0,0,.75,0V16.375h17.25V19a.375.375,0,0,0,.75,0V14A2.3726,2.3726,0,0,0,19.375,11.6629ZM5.375,7A1.6262,1.6262,0,0,1,7,5.375H17A1.6262,1.6262,0,0,1,18.625,7v4.625H5.375Zm15.25,8.625H3.375V14A1.6262,1.6262,0,0,1,5,12.375H19A1.6262,1.6262,0,0,1,20.625,14Z" />
          <path d="M10,7.625H8A1.377,1.377,0,0,0,6.625,9v1a.375.375,0,0,0,.75,0V9A.6256.6256,0,0,1,8,8.375h2A.6256.6256,0,0,1,10.625,9v1a.375.375,0,0,0,.75,0V9A1.377,1.377,0,0,0,10,7.625Z" />
          <path d="M16,7.625H14A1.377,1.377,0,0,0,12.625,9v1a.375.375,0,0,0,.75,0V9A.6256.6256,0,0,1,14,8.375h2A.6256.6256,0,0,1,16.625,9v1a.375.375,0,0,0,.75,0V9A1.377,1.377,0,0,0,16,7.625Z" />
        </g>
      </g>
    </svg>
  );
}
