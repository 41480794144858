import React from 'react';
export function ConnectingRooms({
  className,
  fillColor,
}: {
  className?: string;
  fillColor: string;
}) {
  return (
    <svg
      className={className}
      style={{ fill: fillColor }}
      viewBox="0 0 44 44"
      focusable="false"
    >
      <g
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke="currentColor"
      >
        <path
          d="M15.25 3.572M39.552 22.132c0 9.62-7.8 17.42-17.42 17.42-9.62 0-17.42-7.8-17.42-17.42 0-9.62 7.8-17.42 17.42-17.42 9.62 0 17.42 7.8 17.42 17.42z"
          strokeWidth="1.5"
        />
      </g>
      <g transform="translate(13.5, 13)">
        <path
          d="M10.275,10.1261023 C10.875,10.1261023 11.375,9.92610231 11.875,9.62610231 C11.975,9.52610231 12.075,9.42610231 12.175,9.32610231 L15.275,6.22610231 C16.375,5.12610231 16.275,3.42610231 15.275,2.32610231 C14.175,1.32610231 12.475,1.32610231 11.475,2.32610231 L9.175,4.62610231 C8.575,4.42610231 8.075,4.32610231 7.475,4.32610231 C7.375,4.32610231 7.275,4.32610231 7.275,4.32610231 L10.375,1.22610231 C12.075,-0.473897694 14.775,-0.373897694 16.475,1.32610231 C18.075,3.02610231 18.075,5.62610231 16.475,7.32610231 L13.375,10.4261023 C11.675,12.1261023 8.975,12.1261023 7.275,10.4261023 C7.175,10.3261023 7.075,10.2261023 6.975,10.1261023 C6.775,9.92610231 6.675,9.72610231 6.575,9.42610231 C6.875,8.92610231 7.575,8.82610231 8.075,9.12610231 C8.075,9.12610231 8.075,9.12610231 8.075,9.12610231 C8.675,9.72610231 9.475,10.1261023 10.275,10.1261023"
          strokeWidth="1.2"
        />
        <path
          d="M7.375,7.42610231 C6.775,7.42610231 6.275,7.62610231 5.775,7.92610231 C5.675,8.02610231 5.575,8.12610231 5.475,8.22610231 L2.375,11.3261023 C1.275,12.4261023 1.275,14.1261023 2.375,15.2261023 C3.475,16.3261023 5.175,16.3261023 6.275,15.2261023 L8.575,12.9261023 C9.175,13.1261023 9.675,13.2261023 10.275,13.2261023 C10.375,13.2261023 10.475,13.2261023 10.475,13.2261023 L7.375,16.3261023 C5.675,18.0261023 2.975,18.0261023 1.275,16.3261023 C-0.425,14.6261023 -0.425,11.9261023 1.275,10.2261023 L4.375,7.12610231 C6.075,5.42610231 8.775,5.42610231 10.475,7.12610231 C10.575,7.22610231 10.675,7.32610231 10.775,7.42610231 C10.975,7.62610231 11.075,7.82610231 11.175,8.12610231 C10.875,8.62610231 10.175,8.72610231 9.675,8.42610231 C9.675,8.42610231 9.675,8.42610231 9.675,8.42610231 C8.975,7.82610231 8.275,7.52610231 7.375,7.42610231"
          strokeWidth="1.2"
        />
      </g>
    </svg>
  );
}
