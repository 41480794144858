import React, { useContext } from 'react';
import { EventCalendarInfoContext } from './event-calendar-info-context';

interface DayProps {
  dayNameFull?: string;
  dayNameDisplay?: string;
}

const EventCalendarDayName = ({ dayNameDisplay, dayNameFull }: DayProps) => {
  const hotelInfoContext = useContext(EventCalendarInfoContext);
  return (
    <th
      key={dayNameDisplay}
      scope="col"
      className="p-2 box-border text-xs uppercase"
      abbr={dayNameFull}
      style={{ color: hotelInfoContext?.inlineStyles?.eventsCalBaseTextColor }}
    >
      <span aria-hidden="true">{dayNameDisplay}</span>
      <span className="sr-only">{dayNameFull}</span>
    </th>
  );
};

export default EventCalendarDayName;
