import dynamic from 'next/dynamic';

const HotelLogo = dynamic<{ className: string; fillColor?: string }>(() =>
  import('./olbbrqq/hotel-logo').then((i) => i.HotelLogo)
);

export const hotelLogoHeader = (
  <HotelLogo
    className="fill-current  antialiased w-32 lg:w-40"
    fillColor="#4e3629"
  />
);

export const hotelLogoFooter = (
  <HotelLogo
    className="fill-current  antialiased w-32 lg:w-40"
    fillColor="#4e3629"
  />
);
