import React from 'react';
export function BusinessCentre({
  className,
  fillColor,
}: {
  className?: string;
  fillColor?: string;
}) {
  return (
    <svg className={className} viewBox="0 0 24 24" style={{ fill: fillColor }}>
      <g data-name="Bounding Box">
        <path style={{ fill: 'none' }} d="M0,0V24H24V0Z" />
      </g>
      <g data-name="Icons Outlined">
        <g>
          <path d="M11,11.375h2a.375.375,0,0,0,0-.75H11a.375.375,0,0,0,0,.75Z" />
          <path d="M19,7.625H5A.3751.3751,0,0,0,4.625,8V9A4.38,4.38,0,0,0,9,13.375h6A4.38,4.38,0,0,0,19.375,9V8A.3751.3751,0,0,0,19,7.625ZM18.625,9A3.6289,3.6289,0,0,1,15,12.625H9A3.6289,3.6289,0,0,1,5.375,9V8.375h13.25Z" />
          <path d="M19,12.625a.3751.3751,0,0,0-.375.375v5.625H5.375v-5.5a.375.375,0,0,0-.75,0V19A.3751.3751,0,0,0,5,19.375H19A.3751.3751,0,0,0,19.375,19V13A.3751.3751,0,0,0,19,12.625Z" />
          <path d="M9,7.375A.3751.3751,0,0,0,9.375,7V6A.6256.6256,0,0,1,10,5.375h4A.6256.6256,0,0,1,14.625,6V7a.375.375,0,0,0,.75,0V6A1.377,1.377,0,0,0,14,4.625H10A1.377,1.377,0,0,0,8.625,6V7A.3751.3751,0,0,0,9,7.375Z" />
        </g>
      </g>
    </svg>
  );
}
