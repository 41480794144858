import React from 'react';
export function App({
  className,
  fillColor,
}: {
  className?: string;
  fillColor?: string;
}) {
  return (
    <svg className={className} viewBox="0 0 24 24" style={{ fill: fillColor }}>
      <g data-name="Bounding Box">
        <path style={{ fill: 'none' }} d="M0,0V24H24V0Z" />
      </g>
      <g data-name="Icons Outlined">
        <g>
          <path d="M11,4.625a.375.375,0,0,0,0,.75h2a.375.375,0,0,0,0-.75Z" />
          <path d="M21.2656,7.7354a.3747.3747,0,0,0-.53-.001L13,15.459l-2.7354-2.7246a.3749.3749,0,1,0-.5292.5312l3,2.9883a.3747.3747,0,0,0,.5292,0l8-7.9883A.3747.3747,0,0,0,21.2656,7.7354Z" />
          <path d="M17,14.625a.3751.3751,0,0,0-.375.375v4A1.6262,1.6262,0,0,1,15,20.625H9A1.6262,1.6262,0,0,1,7.375,19V5A1.6262,1.6262,0,0,1,9,3.375h6A1.6262,1.6262,0,0,1,16.625,5V9a.375.375,0,0,0,.75,0V5A2.3777,2.3777,0,0,0,15,2.625H9A2.3777,2.3777,0,0,0,6.625,5V19A2.3777,2.3777,0,0,0,9,21.375h6A2.3777,2.3777,0,0,0,17.375,19V15A.3751.3751,0,0,0,17,14.625Z" />
        </g>
      </g>
    </svg>
  );
}
