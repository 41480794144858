export function Salon({
  className,
  fillColor,
}: {
  className?: string;
  fillColor?: string;
}) {
  return (
    <svg
      className={className}
      x="0px"
      y="0px"
      viewBox="0 0 24 24"
      style={{ fill: fillColor }}
    >
      <path d="m0 0v24h24v-24z" fill="none" />
      <path d="m18.743 6.571-5 3c-.237.142-.313.449-.171.686.094.156.259.243.429.243.087 0 .176-.022.257-.071l5-3c.237-.142.313-.449.171-.686s-.449-.315-.686-.172z" />
      <path d="m11.509 16.02 2.979-12.907c.062-.269-.106-.537-.375-.6-.268-.062-.537.106-.6.375l-1.772 7.678-4.774 1.91c-.458-.589-1.165-.975-1.967-.975-1.378 0-2.5 1.121-2.5 2.5s1.122 2.5 2.5 2.5 2.5-1.121 2.5-2.5c0-.218-.037-.425-.089-.626l4.056-1.623-.883 3.825c-.188-.046-.382-.076-.585-.076-1.378 0-2.5 1.121-2.5 2.5 0 1.3 1.001 2.358 2.27 2.477l.877.877c.098.098.226.146.354.146s.256-.049.354-.146c.195-.195.195-.512 0-.707l-.357-.357c.883-.386 1.503-1.266 1.503-2.29 0-.81-.393-1.523-.991-1.98zm-6.509-.52c-.827 0-1.5-.673-1.5-1.5s.673-1.5 1.5-1.5 1.5.673 1.5 1.5-.673 1.5-1.5 1.5zm5 4c-.827 0-1.5-.673-1.5-1.5s.673-1.5 1.5-1.5 1.5.673 1.5 1.5-.673 1.5-1.5 1.5z" />
    </svg>
  );
}
