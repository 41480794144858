import React from 'react';
export function AlertCircle({
  className,
  fillColor,
}: {
  className?: string;
  fillColor?: string;
}) {
  return (
    <svg className={className} viewBox="0 0 24 24" style={{ fill: fillColor }}>
      <g data-name="Bounding Box">
        <path style={{ fill: 'none' }} d="M0,0V24H24V0Z" />
      </g>
      <g data-name="Icons Outlined">
        <g>
          <path d="M12,3.625A8.375,8.375,0,1,0,20.375,12,8.3842,8.3842,0,0,0,12,3.625Zm0,16A7.625,7.625,0,1,1,19.625,12,7.6341,7.6341,0,0,1,12,19.625Z" />
          <path d="M12,13.375A.3751.3751,0,0,0,12.375,13V8a.375.375,0,0,0-.75,0v5A.3751.3751,0,0,0,12,13.375Z" />
          <path d="M12,15.375a.625.625,0,1,0,.625.625A.6256.6256,0,0,0,12,15.375Z" />
        </g>
      </g>
    </svg>
  );
}
