import dynamic from 'next/dynamic';

const HotelLogo = dynamic<{ className: string; fillColor?: string }>(() =>
  import('./sfofhhh/hotel-logo').then((i) => i.HotelLogo)
);

export const hotelLogoHeader = (
  <HotelLogo className="antialiased w-24" fillColor="#FFFFFF" />
);

export const hotelLogoFooter = (
  <HotelLogo className="antialiased w-24 mr-2 sm:mr-0" fillColor="#FFFFFF" />
);
