import { useEffect } from 'react';

interface CarouselTouchSwipeProps {
  prev: () => void;
  next: () => void;
  targetId: string;
}

/**
 * This will add swipe navigation functionality to carousels for touch screen users.
 *
 * Since this uses useEffect, it's highly reccomended that this is placed as high up in the file as possible.
 *
 * @param prev function to handle navigation to previous carousel item
 * @param next function to handle navigation to next carousel item
 * @param targetId id of element user can swipe over to trigger carousel navigation
 */

export function CarouselTouchSwipe({
  prev,
  next,
  targetId,
}: CarouselTouchSwipeProps): void {
  useEffect(() => {
    let touchstart = 0;
    let touchend = 0;

    const handleTouchStart = (e: TouchEvent) =>
      (touchstart = e.changedTouches[0].screenX);

    const handleTouchEnd = (e: TouchEvent) => {
      touchend = e.changedTouches[0].screenX;
      if (touchend < touchstart) next();
      if (touchend > touchstart) prev();
    };

    const target: HTMLElement | null = document?.getElementById(targetId);
    target?.addEventListener('touchstart', handleTouchStart);
    target?.addEventListener('touchend', handleTouchEnd);

    return () => {
      target?.removeEventListener('touchstart', handleTouchStart);
      target?.removeEventListener('touchend', handleTouchEnd);
    };
  }, [targetId, next, prev]);
}
