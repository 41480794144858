import dynamic from 'next/dynamic';

const HotelLogo = dynamic<{ className?: string; fillColor?: string }>(() =>
  import('./xlvnfhf/hotel-logo').then((i) => i.HotelLogo)
);

export const hotelLogoHeader = (
  <HotelLogo className="text-bg antialiased h-20 md:h-24" fillColor="#ffffff" />
);

export const hotelLogoFooter = (
  <HotelLogo
    className="text-bg antialiased h-20 mr-2 sm:mr-0"
    fillColor="#ffffff"
  />
);
