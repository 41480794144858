import { CleanStayLogo } from '@curated-property/icons';
import {
  MenuProps,
  FooterProps,
  BreadcrumbProps,
} from '@curated-property/shared-components';
import dynamic from 'next/dynamic';

const SharedHeader = dynamic<MenuProps>(() =>
  import('@curated-property/shared-components').then((h) => h.Header)
);
const SharedFooter = dynamic<FooterProps>(() =>
  import('@curated-property/shared-components').then((f) => f.Footer)
);
const SharedBreadCrumbs = dynamic<BreadcrumbProps>(() =>
  import('@curated-property/shared-components').then((f) => f.BreadCrumbs)
);

export const Header = (props: MenuProps) => {
  return (
    <SharedHeader
      {...props}
      styleOptions={{
        ...props?.styleOptions,
        navStyles: '2xl:text-lg font-bold',
      }}
    />
  );
};
// this override footer
export const Footer = (props: FooterProps) => {
  return (
    <SharedFooter
      {...props}
      brandPhoneNumber="1-844-HI-CURIO"
      styleOptions={{
        backgroundColor: 'bg-secondary-alt',
        textColor: 'text-primary',
        linkStyles: {
          underline: true,
          fontSize: 'base-text',
        },
        columnStyles: {
          columnAlign: 'top',
          columnOne: {
            fontSize: 'text-base leading-8',
            logoPadding: 'px-4 py-8 sm:py-4 sm:px-4',
            logoClasses: 'border-C4C4C4',
            copyClasses: 'leading-8',
          },
          columnTwo: {
            padding: 'pt-8 sm:p-8 xl:p-0 leading-loose',
          },
          columnThree: {
            width: 'max-w-2xl',
          },
        },
        copyrightStyles: {
          backgroundColor: 'bg-brand-dark',
          textColor: 'text-white',
        },
      }}
      showCopyright={false}
      additionalLogosSection={
        <div className="px-4 pt-8 m-auto xl:m-0">
          <CleanStayLogo
            className="w-32 "
            mainColor="rgb(var(--color-primary))"
          />
        </div>
      }
    />
  );
};

// this overrides breadcrumbs
export const BreadCrumbs = (props: BreadcrumbProps) => {
  return (
    <SharedBreadCrumbs
      {...props}
      styleOptions={{
        background: 'rgb(var(--color-secondary))',
        anchorClasses: 'text-primary hover:underline',
        activeAnchorClasses: 'text-primary font-bold inline-block',
      }}
      delimiter={<span className="px-2 text-brand-5">/</span>}
    />
  );
};
