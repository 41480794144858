import React from 'react';
const ParkingIcon = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg viewBox="0 0 44 44" focusable="false" {...props}>
      <g
        strokeWidth="1.5"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        stroke="currentColor"
      >
        <path d="M30.654 23.679c-1.475 0-2.67 1.23-2.67 2.751 0 1.52 1.195 2.75 2.67 2.75 1.474 0 2.669-1.23 2.669-2.75s-1.195-2.751-2.669-2.751zM13.078 23.679c-1.475 0-2.669 1.23-2.669 2.751 0 1.52 1.194 2.75 2.669 2.75 1.474 0 2.67-1.23 2.67-2.75s-1.196-2.751-2.67-2.751z" />
        <path d="M38.773 16.342h-2.788l-4.07-7.54c-.503-.934-1.589-1.63-2.467-1.63H14.285c-.877 0-1.963.696-2.467 1.63l-4.07 7.54H4.96c-.491 0-.89.41-.89.918v1.834c0 .506.399.916.89.916h1.018c-.127.484-.127.784-.127.918V35.601c0 .506.397.916.889.916h3.56c.49 0 .89-.41.89-.916v-2.752h21.355v2.752c0 .506.398.916.89.916h3.559c.491 0 .89-.41.89-.916V20.928c0-.134 0-.433-.128-.918h1.017c.492 0 .89-.41.89-.916V17.26c0-.508-.398-.918-.89-.918z" />
        <path d="M33.434 19.093H10.3c-.705 0 4.199-7.665 4.199-7.665.178-.331.667-.589 1.1-.589h12.537c.434 0 .922.258 1.1.589 0 0 4.904 7.665 4.2 7.665z" />
      </g>
    </svg>
  );
};

export default ParkingIcon;
