import cx from 'classnames';
import { ACFAnchorProps } from '../global/anchor-link';
import {
  GIS_merge,
  GIS_Padder,
  StyleObject,
} from '../functions/global-instance-styles';

import { CropProps, appliedCloudinaryParams } from '@curated-property/utils';
import { MediaAndCopyRow } from './media-and-copy-row';
import { MediaBlocksMediaLayoutsInterface } from '../media-blocks';
import { WordpressPageInfoQuery } from '../generated/wp';

export function halfAndHalfPropMapper(
  componentData: HalfAndHalfProps & {
    halfAndHalfComponentSettings?: StyleObject;
  },
  globalData: NonNullable<
    NonNullable<
      WordpressPageInfoQuery['componentStyles']
    >['globalComponentSettings']
  >['globalComponentSettings']
) {
  return {
    repeater: componentData?.repeater,
    globalStyles: globalData?.halfAndHalfComponentSettings,
    instanceStyles: componentData?.halfAndHalfComponentSettings,
  };
}

export interface HalfAndHalfItemInterface {
  title?: string;
  copy?: string;
  accordionPanel?: {
    panelLabel?: string;
    panelRepeater?: {
      rowLabel?: string;
      rowText?: string;
    }[];
  }[];
  titleIcon?: {
    sourceUrl?: string;
    altText?: string;
  };
  image?: {
    sourceUrl?: string;
    altText?: string;
  };
  mediaCopyIconList?: {
    iconColor?: string;
    iconList?: string;
    text?: string;
  }[];
  imageSize?: string;
  imagePositioning?: string;
  layoutMode?: string;
  buttons?: Array<ACFAnchorProps>;
  imageGallery?: {
    sourceUrl?: string;
    altText?: string;
  }[];
  mediaBlock?: MediaBlocksMediaLayoutsInterface;
  tableData?: {
    title?: string;
    text?: string;
  }[];
}

export interface HalfAndHalfProps {
  repeater: Array<HalfAndHalfCombinedProps> | undefined;
  globalStyles?: StyleObject;
  instanceStyles?: StyleObject;
}

export type HalfAndHalfCombinedProps = CropProps & HalfAndHalfItemInterface;

export function HalfAndHalf({
  repeater,
  globalStyles,
  instanceStyles,
}: HalfAndHalfProps) {
  const inlineStyles: StyleObject = GIS_merge(globalStyles, instanceStyles);
  const paddingStyles = GIS_Padder(
    inlineStyles?.paddingTop,
    inlineStyles?.paddingBottom
  );

  let rowPadding: string;
  // gap-y-2 lg:gap-y-6 gap-y-8 lg:gap-y-12 gap-y-14 lg:gap-y-20 gap-y-0 lg:gap-y-0
  switch (inlineStyles?.mediaAndCopyRowPadding) {
    case 'Small':
      rowPadding = 'gap-y-2 lg:gap-y-6';
      break;
    case 'Medium':
      rowPadding = 'gap-y-8 lg:gap-y-12';
      break;
    case 'Large':
      rowPadding = 'gap-y-14 lg:gap-y-20';
      break;
    default:
      rowPadding = 'gap-y-0 lg:gap-y-0';
  }

  return (
    <div
      style={{
        backgroundImage: inlineStyles?.componentBackgroundImage?.sourceUrl
          ? 'url(' +
            appliedCloudinaryParams(
              inlineStyles?.componentBackgroundImage?.sourceUrl,
              inlineStyles?.componentBackgroundRepeat
            ) +
            ')'
          : undefined,
        backgroundSize: inlineStyles?.componentBackgroundSize || 'cover',
        backgroundRepeat:
          inlineStyles?.componentBackgroundRepeat || 'no-repeat',
        backgroundPosition:
          inlineStyles?.componentBackgroundPosition || 'top left',
        backgroundColor: inlineStyles?.componentBackgroundColor,
      }}
    >
      <div
        data-testid="mediaCopyRows"
        style={{
          maxWidth:
            inlineStyles?.containerMaxWidth &&
            `${inlineStyles?.containerMaxWidth}px`,
        }}
        className={cx(paddingStyles, rowPadding, 'container flex flex-col')}
      >
        {repeater?.map((item, key) => {
          return (
            <MediaAndCopyRow
              inlineStyles={inlineStyles}
              item={item}
              key={key}
              id={key}
            />
          );
        })}
      </div>
    </div>
  );
}
