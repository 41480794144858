import React from 'react';
import { format, addDays, Locale, startOfWeek } from 'date-fns';
import * as locales from 'date-fns/locale';
import EventCalendarDayName from './event-calendar-day-name';

// Retuns row of day names for use in a table.
export interface EventCalendarDaysOfWeekProps {
  dayDisplayType?: string;
}
export function EventCalendarDaysOfWeekRow({
  dayDisplayType,
}: EventCalendarDaysOfWeekProps) {
  const locale = locales?.enUS;
  const getDaysOfWeek = (locale: Locale) => {
    const startDateOfWeek = startOfWeek(new Date(), {
      locale,
      weekStartsOn: locale.options?.weekStartsOn,
    });

    const days = [...Array(7)].map((n, i) => {
      const day = addDays(startDateOfWeek, i);
      return {
        abbr: format(day, 'eee', { locale }),
        long: format(day, 'eeee', { locale }),
      };
    });
    return days;
  };

  return (
    <thead>
      <tr>
        {getDaysOfWeek(locale).map((i, e) => {
          return (
            <EventCalendarDayName
              key={`${!dayDisplayType ? 'n' : 'd'}${e}`}
              dayNameFull={i.long}
              dayNameDisplay={
                !dayDisplayType || dayDisplayType === 'long' ? i.long : i.abbr
              }
            />
          );
        })}
      </tr>
    </thead>
  );
}
