import React from 'react';
export function WiFi({
  className,
  fillColor,
}: {
  className?: string;
  fillColor?: string;
}) {
  return (
    <svg className={className} viewBox="0 0 24 24" style={{ fill: fillColor }}>
      <g data-name="Bounding Box">
        <path style={{ fill: 'none' }} d="M0,0V24H24V0Z" />
      </g>
      <g data-name="Icons Outlined">
        <g>
          <path d="M12,12.125a5.8508,5.8508,0,0,0-4.16,1.7207.3749.3749,0,0,0,.5293.5312,5.14,5.14,0,0,1,7.2618,0,.3749.3749,0,0,0,.5293-.5312A5.8508,5.8508,0,0,0,12,12.125Z" />
          <path d="M20.7646,9.249a12.4105,12.4105,0,0,0-17.5292,0,.3749.3749,0,0,0,.5292.5313,11.6609,11.6609,0,0,1,16.4708,0,.3749.3749,0,0,0,.5292-.5313Z" />
          <path d="M12,8.875a9.0939,9.0939,0,0,0-6.4629,2.6719.3749.3749,0,1,0,.5293.5312,8.4027,8.4027,0,0,1,11.8672,0,.3749.3749,0,1,0,.5293-.5312A9.0939,9.0939,0,0,0,12,8.875Z" />
          <path d="M12,15.125a1.625,1.625,0,1,0,1.627,1.625A1.6273,1.6273,0,0,0,12,15.125Zm0,2.5a.875.875,0,1,1,.877-.875A.8772.8772,0,0,1,12,17.625Z" />
        </g>
      </g>
    </svg>
  );
}
