import React from 'react';

export function SquareFootage(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={32}
      height={32}
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <g fill={props?.style?.stroke} fillRule="evenodd">
        <path d="M.314 0A.313.313 0 000 .314v31.372c0 .174.14.314.314.314h21.09a.313.313 0 100-.627H.628v-15.06h8.6v5.223a.314.314 0 10.627 0V10.463a.313.313 0 10-.627 0v5.223h-8.6V.627h16.377v7.728c0 .173.14.313.313.313h7.184a.314.314 0 100-.628h-6.87V.627h13.74v22.837H19.24a.313.313 0 100 .628h12.133v7.28h-4.958a.313.313 0 100 .628h5.271c.174 0 .314-.14.314-.314V.314A.313.313 0 0031.686 0H.314z" />
      </g>
    </svg>
  );
}
