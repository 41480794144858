import React from 'react';
export function Lock({
  className,
  fillColor,
}: {
  className?: string;
  fillColor?: string;
}) {
  return (
    <svg className={className} viewBox="0 0 24 24" style={{ fill: fillColor }}>
      <g data-name="Bounding Box">
        <path style={{ fill: 'none' }} d="M0,0V24H24V0Z" />
      </g>
      <g data-name="Icons Outlined">
        <g>
          <path d="M16.375,10.6629V9a4.375,4.375,0,0,0-8.75,0v1.6629A2.3726,2.3726,0,0,0,5.625,13v4A2.3777,2.3777,0,0,0,8,19.375h8A2.3777,2.3777,0,0,0,18.375,17V13A2.3726,2.3726,0,0,0,16.375,10.6629ZM8.375,9a3.625,3.625,0,0,1,7.25,0v1.625H8.375Zm9.25,8A1.6262,1.6262,0,0,1,16,18.625H8A1.6262,1.6262,0,0,1,6.375,17V13A1.6262,1.6262,0,0,1,8,11.375h8A1.6262,1.6262,0,0,1,17.625,13Z" />
          <path d="M12,13.125a.87.87,0,0,0-.375,1.6576V16a.375.375,0,0,0,.75,0V14.7827A.87.87,0,0,0,12,13.125Z" />
        </g>
      </g>
    </svg>
  );
}
