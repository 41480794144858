import React from 'react';

export function SaleIconSolid({
  className,
  fillColor,
}: {
  className?: string;
  fillColor?: string;
}) {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill={fillColor}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="m0 0v24h24v-24z" fill="none" />
      <path d="m17 8.5h-.5c-.6 0-1.12.36-1.35.87.22.32.35.71.35 1.13 0 1.1-.9 2-2 2s-2-.9-2-2 .81-1.9 1.84-1.98c-.21.45-.34.95-.34 1.48 0 .28.22.5.5.5s.5-.22.5-.5c0-1.38 1.12-2.5 2.5-2.5h.5c1.93 0 3.5-1.57 3.5-3.5 0-.28-.22-.5-.5-.5s-.5.22-.5.5c0 1.38-1.12 2.5-2.5 2.5h-.5c-.09 0-.18.02-.27.03l-.88-.88c-.09-.09-.22-.15-.35-.15h-4c-.13 0-.26.05-.35.15l-7 7c-.2.2-.2.51 0 .71l7 7c.1.1.23.15.35.15s.26-.05.35-.15l7-7c.09-.09.15-.22.15-.35v-4c0-.13-.05-.26-.15-.35l-.29-.29c-.34.08-.7.14-1.07.14z" />
    </svg>
  );
}
