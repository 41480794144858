import React, { useState, useEffect } from 'react';
import { EventCalendarMonth } from './event-calendar-month';
import { EventCalendarTypeFilters } from './event-calendar-type-filters';
import { addMonths } from 'date-fns';
import { ArrowLeftLong, ArrowRightLong } from '@curated-property/icons';
import { EventCalendarInfoContext } from './event-calendar-info-context';
import { EventCalendarDailyEvents } from './event-calendar-daily-events';
import { useTranslation } from 'next-i18next';
import { categoryArr } from './lib/event-calendar-constants';
import {
  GIS_Array,
  GIS_Padder,
  StyleObject,
} from '../functions/global-instance-styles';
import cx from 'classnames';
import {
  EventCalendarHotelInfoProps,
  EventCalendarSpecialEventProps,
  EventCalendarModalSettings,
  EventCalendarRegularEventProps,
} from './lib/event-calendar-props';
import { eventCalendarStyleBlock } from './event-calendar-helpers';

export function eventCalendarHotelInfoPropMapper(
  hotelName,
  hotelLocale,
  hotelAddress,
  hotelContact
) {
  return {
    hotelName: hotelName,
    hotelLocale: {
      coordinate: {
        latitude: hotelLocale?.coordinate?.latitude,
        longitude: hotelLocale?.coordinate?.longitude,
      },
      currency: {
        description: hotelLocale?.currency?.description,
      },
      gmtHours: hotelLocale?.gmtHours,
      gmtHoursFmt: hotelLocale?.gmtHoursFmt,
      timeZone: hotelLocale?.timeZone,
      currencyCode: hotelLocale?.currencyCode,
    },
    hotelAddress: {
      addressStacked: hotelAddress?.addressStacked,
      addressLine1: hotelAddress?.addressLine1,
      city: hotelAddress?.city,
      city_noTx: hotelAddress?.city_noTx,
      country: hotelAddress?.country,
      countryName: hotelAddress?.countryName,
      countryName_noTx: hotelAddress?.countryName_noTx,
      state: hotelAddress?.state,
      stateName: hotelAddress?.stateName,
      stateName_noTx: hotelAddress?.stateName_noTx,
    },
    hotelContact: {
      emailAddress1: hotelContact?.emailAddress1,
      faxNumber: hotelContact?.faxNumber,
      phoneNumber: hotelContact?.phoneNumber,
    },
  };
}

export type EventCalendarSpecialEventType = EventCalendarSpecialEventProps;

export type EventCalendarRegularEventType = EventCalendarRegularEventProps;

export interface EventCalendarCustomCategories {
  customCategoryNames?: string;
}

export function eventCalSplEventPropMapper(nodes) {
  return nodes;
}

export function eventCalRegEventPropMapper(nodes) {
  return nodes;
}

export function eventCalCustomCategoriesPropMapper(customEventCategories) {
  return customEventCategories;
}

export interface EventCalendarProps {
  customEventCategories?: Array<EventCalendarCustomCategories>;
  specialEvents?: Array<EventCalendarSpecialEventType>;
  regularEvents?: Array<EventCalendarRegularEventType>;
  hotelInfo?: EventCalendarHotelInfoProps;
  api_key?: string;
  latLng?: {
    lat?: number;
    lng?: number;
  };
  modalSettings?: EventCalendarModalSettings;
  globalStyles?: StyleObject;
  instanceStyles?: StyleObject;
}

export function EventCalendar({
  customEventCategories,
  specialEvents,
  regularEvents,
  hotelInfo,
  api_key,
  latLng,
  modalSettings,
  globalStyles,
  instanceStyles,
}: EventCalendarProps) {
  const localeStr = 'en';
  const currentDate = new Date();
  const [displayNextArrow, setDisplayNextArrow] = useState(true);
  const [displayPrevArrow, setDisplayPrevArrow] = useState(false);
  const numberOfAvailableMonths = 6;
  const [currentMonthKey, setCurrentMonthKey] = useState(0);
  // Collect which categories and locales (on-site or off-site) to show
  const [selectedLocales, setSelectedLocales] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const { t } = useTranslation();
  // Change select accepts a new value from the month selector and sets that value for all child components
  const changeSelect = (update) => {
    // Change integer for selected dropdown select value
    setCurrentMonthKey(update);
  };
  useEffect(() => {
    setDisplayNextArrow(
      currentMonthKey < numberOfAvailableMonths - 1 ? true : false
    );

    setDisplayPrevArrow(currentMonthKey === 0 ? false : true);
  }, [currentMonthKey]);

  const inlineStyles = GIS_Array(globalStyles, instanceStyles);

  // Make styles for <head> injection
  useEffect(() => {
    if (!document.querySelectorAll('[id^="eventCalHeadStyle"]').length) {
      // CMS-controlled Button Inline Values
      const styleString = eventCalendarStyleBlock(inlineStyles);

      const stylingElement = document.createElement('style');
      stylingElement.setAttribute('id', `eventCalHeadStyle`);
      stylingElement.setAttribute('type', 'text/css');
      document.head.appendChild(stylingElement);
      stylingElement.innerHTML = styleString;
    }
  }, []);

  const paddingStyles = GIS_Padder(
    inlineStyles?.paddingTop,
    inlineStyles?.paddingBottom
  );

  // Regularly-scheduled Events
  const sundayEvents = [];
  const mondayEvents = [];
  const tuesdayEvents = [];
  const wednesdayEvents = [];
  const thursdayEvents = [];
  const fridayEvents = [];
  const saturdayEvents = [];
  const recurringEvents = [
    sundayEvents,
    mondayEvents,
    tuesdayEvents,
    wednesdayEvents,
    thursdayEvents,
    fridayEvents,
    saturdayEvents,
  ];
  const dailyEvents = [];

  [...(regularEvents as any)]?.forEach((i, e) => {
    const schedule = i.node?.RegularlyScheduledEvents;

    if (i.node?.RegularlyScheduledEvents?.allDays?.heldDaily === true) {
      [...recurringEvents].forEach((el) => {
        el.push(i);
      });
      dailyEvents.push(i);
    } else {
      if (!schedule?.allDays?.heldDaily) {
        schedule?.sundaySchedule?.scheduled && recurringEvents[0].push(i);
        schedule?.mondaySchedule?.scheduled && recurringEvents[1].push(i);
        schedule?.tuesdaySchedule?.scheduled && recurringEvents[2].push(i);
        schedule?.wednesdaySchedule?.scheduled && recurringEvents[3].push(i);
        schedule?.thursdaySchedule?.scheduled && recurringEvents[4].push(i);
        schedule?.fridaySchedule?.scheduled && recurringEvents[5].push(i);
        schedule?.saturdaySchedule?.scheduled && recurringEvents[6].push(i);
      }
    }
  });

  // This sorts the correct line items to show based on category and locale
  // The updated selectedLocales, selectedCategories and selectedDateIds useState consts are what are passed to the month to show/hide content accordingly
  const whichEntriesToShow = (filterSelection) => {
    const filterChecked = filterSelection[0];
    const filterValue = filterSelection[1];
    const arrayType = filterSelection[2];

    // Empty all useState arrays if the value supplied is reset
    if (filterChecked === 'reset') {
      setSelectedLocales([]);
      setSelectedCategories([]);
    } else {
      if (arrayType === 'locale') {
        const valIndex = selectedLocales.indexOf(filterValue);
        if (filterChecked === true && valIndex === -1) {
          setSelectedLocales((selectedLocales) => [
            ...selectedLocales,
            filterValue,
          ]);
        } else {
          if (filterChecked === false && valIndex !== -1) {
            setSelectedLocales([
              ...selectedLocales.slice(0, valIndex),
              ...selectedLocales.slice(valIndex + 1, selectedLocales.length),
            ]);
          }
        }
      }

      if (arrayType === 'category') {
        const valIndex = selectedCategories.indexOf(filterValue);
        if (filterChecked === true && valIndex === -1) {
          setSelectedCategories((selectedCategories) => [
            ...selectedCategories,
            filterValue,
          ]);
        } else {
          if (filterChecked === false && valIndex !== -1) {
            setSelectedCategories([
              ...selectedCategories.slice(0, valIndex),
              ...selectedCategories.slice(
                valIndex + 1,
                selectedCategories.length
              ),
            ]);
          }
        }
      }
    }
  };

  const hotelInfoObj = {
    hotelName: hotelInfo?.hotelName,
    gmtHours: hotelInfo?.hotelLocale?.gmtHours,
    gmtHoursFmt: hotelInfo?.hotelLocale?.gmtHoursFmt,
    timeZone: hotelInfo?.hotelLocale?.timeZone,
    addressStacked: hotelInfo?.hotelAddress?.addressStacked,
    addressFormat: hotelInfo?.hotelAddress?.addressFmt,
    addressLine1: hotelInfo?.hotelAddress?.addressLine1,
    addressLine2: hotelInfo?.hotelAddress?.addressLine2,
    addressLine3: hotelInfo?.hotelAddress?.addressLine3,
    addressLine4: hotelInfo?.hotelAddress?.addressLine4,
    city: hotelInfo?.hotelAddress?.city,
    state: hotelInfo?.hotelAddress?.state,
    country: hotelInfo?.hotelAddress?.country,
    postalCode: hotelInfo?.hotelAddress?.postalCode,
    countryName: hotelInfo?.hotelAddress?.countryName,
    api_key: api_key,
    latLng: latLng,
    email: hotelInfo?.hotelContact?.emailAddress1,
    phone: hotelInfo?.hotelContact?.phoneNumber,
  };

  const allAvailableCategories = categoryArr;

  // Add custom categories to array of default category filter types
  if (customEventCategories?.length) {
    customEventCategories?.map((el) => {
      allAvailableCategories.push(el?.customCategoryNames);
    });
  }

  const eventCategoriesFromCMS = [...new Set(allAvailableCategories)];

  const contextObj = {
    hotelInfo: hotelInfoObj,
    recurringEvents: recurringEvents,
    specialEvents: specialEvents,
    inlineStyles: inlineStyles,
    modalSettings: modalSettings,
    eventCategoriesWithCustom: eventCategoriesFromCMS,
  };

  // Daily events row will return empty if there aren't any regular events scheduled every day of the week.
  const dailyEventsRow = dailyEvents.length ? (
    <div>
      <EventCalendarDailyEvents
        recurringEvents={dailyEvents}
        monthToDisplay={addMonths(currentDate, currentMonthKey)}
      />
    </div>
  ) : (
    ''
  );

  const arrowFillColor = inlineStyles?.eventsCalBaseIconColor || 'inherit';

  return (
    <EventCalendarInfoContext.Provider value={contextObj}>
      <div
        id="eventsCal"
        className={cx(
          'cp-eventCalendar',
          paddingStyles,
          inlineStyles?.showHide && 'hidden'
        )}
        style={{
          backgroundColor: inlineStyles?.eventsCalendarComponentBgColor || null,
        }}
      >
        <div
          data-testid="calendar-component"
          className="container px-2 lg:px-5 2xl:px-0 md:px-6 py-4"
        >
          <div className="flex flex-row justify-between ">
            <div className="py-4 md:py-0  w-1/3 md:w-1/4">
              <div className="pb-1.5 md:pb-2 flex flex-col">
                <label
                  id="monthSelect"
                  className="text-left block pb-1"
                  htmlFor="selectMonthDropdown"
                  style={{ color: inlineStyles?.eventsCalBaseTextColor }}
                >
                  {t('calendar.filterByMonth')}
                </label>
                <select
                  aria-labelledby="monthSelect"
                  aria-label={t('monthDropdown', {
                    numMonths: numberOfAvailableMonths,
                  })}
                  className="py-2 px-4 bg-bg border-primary border text-sm text-primary rounded w-full md:w-2/3 lg:w-1/2 bg-[center_top_1rem]"
                  value={currentMonthKey}
                  id="selectMonthDropdown"
                  onChange={(ev) => changeSelect(parseInt(ev.target.value))}
                  style={{
                    color: inlineStyles?.eventsCalAdditionalTextFilterColor,
                    borderColor:
                      inlineStyles?.eventsCalAdditionalTextFilterColor,
                  }}
                >
                  {[...Array(numberOfAvailableMonths)]?.map((i, e) => {
                    const month = addMonths(currentDate, e);
                    return (
                      <option
                        className="px-2 py-4 bg-bg-alt"
                        key={'dropdown_' + e.toString()}
                        value={e}
                      >
                        {month.toLocaleString(localeStr, {
                          month: 'long',
                          year: 'numeric',
                        })}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div
              aria-label={t('calendar.prevOrNextMonthButtons')}
              className="hidden mx-auto lg:flex flex-grow justify-center pt-6 pb-2"
            >
              <div className="w-16 flex justify-center">
                <button
                  tabIndex={0}
                  disabled={displayPrevArrow === false ? true : false}
                  className={displayPrevArrow === false ? 'hidden' : ''}
                  onClick={() => {
                    setCurrentMonthKey(
                      currentMonthKey - 1 < 0 ? 0 : currentMonthKey - 1
                    );
                  }}
                  aria-label={t('calendar.viewPrevMonth')}
                >
                  <span className="sr-only">{t('calendar.viewPrevMonth')}</span>
                  <span aria-hidden="true">
                    <ArrowLeftLong className="h-4" fillColor={arrowFillColor} />
                  </span>
                </button>
              </div>
              <div className="flex justify-center text-lg w-24 mx-12 font-bold items-center">
                <h2
                  aria-live="polite"
                  style={{ color: inlineStyles?.eventsCalBaseTextColor }}
                >
                  <span>
                    {addMonths(currentDate, currentMonthKey).toLocaleString(
                      localeStr,
                      {
                        month: 'long',
                      }
                    )}
                  </span>
                </h2>
              </div>
              <div className="w-16 flex justify-center">
                <button
                  tabIndex={0}
                  disabled={displayNextArrow === false ? true : false}
                  className={displayNextArrow === false ? 'hidden' : ''}
                  onClick={() => {
                    setCurrentMonthKey(
                      currentMonthKey + 1 >= numberOfAvailableMonths - 1
                        ? numberOfAvailableMonths - 1
                        : currentMonthKey + 1
                    );
                  }}
                  aria-label={t('calendar.viewNextMonth')}
                >
                  <span className="sr-only">{t('calendar.viewNextMonth')}</span>
                  <span aria-hidden="true">
                    <ArrowRightLong
                      className="h-4"
                      fillColor={arrowFillColor}
                    />
                  </span>
                </button>
              </div>
            </div>
            <div className="py-4 w-2/3 md:w-1/4">
              <label
                aria-hidden="true"
                id="typeSelect"
                className="text-right block pb-1"
                htmlFor="filterTypesBtn"
                style={{ color: inlineStyles?.eventsCalBaseTextColor }}
              >
                {t('calendar.filterByTypeAndLocation')}
              </label>
              <EventCalendarTypeFilters
                displayFilterInputs={whichEntriesToShow}
              />
            </div>
          </div>

          {dailyEventsRow}

          <div
            className=" w-full sm:w-2/3 flex justify-between mx-auto items-center lg:hidden"
            aria-hidden="true"
          >
            <div className="w-1/5 text-xs text-left pl-2 font-medium">
              <button
                data-testid="prevMonth"
                disabled={displayPrevArrow === false ? true : false}
                className={displayPrevArrow === false ? 'hidden' : ''}
                onClick={() => {
                  setCurrentMonthKey(
                    currentMonthKey - 1 < 0 ? 0 : currentMonthKey - 1
                  );
                }}
              >
                <span className="sr-only">{t('calendar.viewPrevMonth')}</span>
                <ArrowLeftLong className="h-3.5" fillColor={arrowFillColor} />
              </button>
            </div>
            <h3
              id="mobileMonth"
              tabIndex={0}
              className="block w-3/5 text-sm font-bold text-center"
              style={{ color: inlineStyles?.eventsCalBaseTextColor }}
            >
              {addMonths(currentDate, currentMonthKey).toLocaleString(
                localeStr,
                {
                  month: 'long',
                  year: 'numeric',
                }
              )}
            </h3>
            <div className="w-1/5 text-xs font-medium text-right pr-2">
              <button
                data-testid="nextMonth"
                disabled={displayNextArrow === false ? true : false}
                className={displayNextArrow === false ? 'hidden' : ''}
                onClick={() => {
                  setCurrentMonthKey(
                    currentMonthKey + 1 >= numberOfAvailableMonths - 1
                      ? numberOfAvailableMonths - 1
                      : currentMonthKey + 1
                  );
                }}
              >
                <span className="sr-only">{t('calendar.viewNextMonth')}</span>
                <ArrowRightLong className="h-3.5" fillColor={arrowFillColor} />
              </button>
            </div>
          </div>
          <div tabIndex={0}>
            <EventCalendarMonth
              monthToDisplay={addMonths(currentDate, currentMonthKey)}
              selectedLocales={selectedLocales}
              selectedCategories={selectedCategories}
            />
          </div>
        </div>
      </div>
    </EventCalendarInfoContext.Provider>
  );
}
