import React from 'react';
export function Resort({
  className,
  fillColor,
}: {
  className?: string;
  fillColor?: string;
}) {
  return (
    <svg className={className} viewBox="0 0 24 24" style={{ fill: fillColor }}>
      <g data-name="Bounding Box">
        <path style={{ fill: 'none' }} d="M0,0V24H24V0Z" />
      </g>
      <g data-name="Icons Outlined">
        <g>
          <path d="M21,18.125a3.2813,3.2813,0,0,1-1.332-.46A3.839,3.839,0,0,0,18,17.125a3.839,3.839,0,0,0-1.668.54,3.2813,3.2813,0,0,1-1.332.46,3.2813,3.2813,0,0,1-1.332-.46A3.839,3.839,0,0,0,12,17.125a3.839,3.839,0,0,0-1.668.54A3.2813,3.2813,0,0,1,9,18.125a3.2813,3.2813,0,0,1-1.332-.46A3.839,3.839,0,0,0,6,17.125a3.839,3.839,0,0,0-1.668.54A3.2813,3.2813,0,0,1,3,18.125a.375.375,0,0,0,0,.75,3.839,3.839,0,0,0,1.668-.54A3.2813,3.2813,0,0,1,6,17.875a3.2813,3.2813,0,0,1,1.332.46A3.839,3.839,0,0,0,9,18.875a3.839,3.839,0,0,0,1.668-.54A3.2813,3.2813,0,0,1,12,17.875a3.2813,3.2813,0,0,1,1.332.46,3.839,3.839,0,0,0,1.668.54,3.839,3.839,0,0,0,1.668-.54A3.2813,3.2813,0,0,1,18,17.875a3.2813,3.2813,0,0,1,1.332.46,3.839,3.839,0,0,0,1.668.54.375.375,0,0,0,0-.75Z" />
          <path d="M3,15.875a3.839,3.839,0,0,0,1.668-.54A3.2813,3.2813,0,0,1,6,14.875a3.2813,3.2813,0,0,1,1.332.46A3.839,3.839,0,0,0,9,15.875a3.839,3.839,0,0,0,1.668-.54A3.2813,3.2813,0,0,1,12,14.875a3.2813,3.2813,0,0,1,1.332.46,3.839,3.839,0,0,0,1.668.54,3.839,3.839,0,0,0,1.668-.54A3.2813,3.2813,0,0,1,18,14.875a3.2813,3.2813,0,0,1,1.332.46,3.839,3.839,0,0,0,1.668.54.375.375,0,0,0,0-.75,3.2813,3.2813,0,0,1-1.332-.46A3.839,3.839,0,0,0,18,14.125a3.839,3.839,0,0,0-1.668.54,3.2813,3.2813,0,0,1-1.332.46,3.2813,3.2813,0,0,1-1.332-.46A3.839,3.839,0,0,0,12,14.125a3.839,3.839,0,0,0-1.668.54A3.2813,3.2813,0,0,1,9,15.125a3.2813,3.2813,0,0,1-1.332-.46A3.839,3.839,0,0,0,6,14.125a3.839,3.839,0,0,0-1.668.54A3.2813,3.2813,0,0,1,3,15.125a.375.375,0,0,0,0,.75Z" />
          <path d="M4.5,12.375h2a.375.375,0,0,0,0-.75h-2a.375.375,0,0,0,0,.75Z" />
          <path d="M12,6.875a.3751.3751,0,0,0,.375-.375v-2a.375.375,0,0,0-.75,0v2A.3751.3751,0,0,0,12,6.875Z" />
          <path d="M17.125,12a.3751.3751,0,0,0,.375.375h2a.375.375,0,0,0,0-.75h-2A.3751.3751,0,0,0,17.125,12Z" />
          <path d="M9.3008,13.334a.3756.3756,0,0,0,.2314-.4776A2.5915,2.5915,0,0,1,9.375,12a2.625,2.625,0,0,1,5.25,0,2.5915,2.5915,0,0,1-.1572.8564.3756.3756,0,0,0,.2314.4776.3862.3862,0,0,0,.1231.0205.3758.3758,0,0,0,.3545-.252A3.3338,3.3338,0,0,0,15.375,12a3.375,3.375,0,0,0-6.75,0,3.3338,3.3338,0,0,0,.1982,1.1025A.377.377,0,0,0,9.3008,13.334Z" />
          <path d="M7.8457,8.377a.3757.3757,0,0,0,.5313-.5313L6.9619,6.4307a.3756.3756,0,0,0-.5312.5312Z" />
          <path d="M15.8887,8.4863a.3748.3748,0,0,0,.2656-.1093l1.415-1.4151a.3756.3756,0,0,0-.5312-.5312L15.623,7.8457a.375.375,0,0,0,.2657.6406Z" />
        </g>
      </g>
    </svg>
  );
}
