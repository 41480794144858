import React from 'react';
import cx from 'classnames';
import { useTranslation } from 'next-i18next';
import { GIS_Array, StyleObject } from './functions/global-instance-styles';
import {
  appliedCloudinaryParams,
  isReducedMotion,
} from '@curated-property/utils';
import { sanitize } from '@curated-property/utils';
import { CountUp, useWindowSize } from './functions/helper';

export function meetingCapacityPropMapper(componentData, globalData, dxGql) {
  return {
    largestMeetingRoomArea: dxGql?.hotel?.meetings?.largestMeetingRoomArea,
    numberOfMeetingRooms: dxGql?.hotel?.meetings?.numberOfMeetingRooms,
    totalArea: dxGql?.hotel?.meetings?.totalArea,
    totalMeetingRoomsArea: dxGql?.hotel?.meetings?.totalMeetingRoomsArea,
    country: dxGql?.hotel?.address?.country,
    totalRooms: dxGql?.hotel?.totalRooms,
    heading: componentData?.heading,
    ctaButtons: componentData?.buttons,
  };
}

interface MeetingCapacityProps {
  largestMeetingRoomArea?: number;
  numberOfMeetingRooms?: number;
  totalArea?: number;
  totalMeetingRoomsArea?: number;
  country?: string;
  totalRooms?: number;
  globalStyles?: StyleObject;
  instanceStyles?: StyleObject;
  heading?: string;
  ctaButtons?: any;
}

/**
 *
 * @param num
 * @returns An integer as a comma-formatted string
 */
function numberFormatter(num) {
  return num?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

/**
 *
 * @param val
 * @returns Square feet value to rounded square meter value
 */
function sqFeetToSqMeter(val: number) {
  const converted = Math.round(val * 0.09290304);
  return converted;
}

export function MeetingCapacity({
  totalArea,
  largestMeetingRoomArea,
  numberOfMeetingRooms,
  country,
  totalRooms,
  globalStyles,
  instanceStyles,
  heading,
  ctaButtons,
}: MeetingCapacityProps) {
  const { t } = useTranslation();

  const inlineStyles = GIS_Array(globalStyles, instanceStyles);

  const paddingSettings = {
    desktopPt: inlineStyles?.paddingTop?.paddingTopDesktop,
    desktopPb: inlineStyles?.paddingBottom?.paddingBottomDesktop,
    tabletPt: inlineStyles?.paddingTop?.paddingTopTablet,
    tabletPb: inlineStyles?.paddingBottom?.paddingBottomTablet,
    mobilePt: inlineStyles?.paddingTop?.paddingTopMobile,
    mobilePb: inlineStyles?.paddingBottom?.paddingBottomMobile,
  };

  const padding = {
    desktopPt:
      paddingSettings.desktopPt !== null && paddingSettings.desktopPt !== ''
        ? `lg:pt-${paddingSettings.desktopPt}`
        : 'lg:pt-4',
    desktopPb:
      paddingSettings.desktopPb !== null && paddingSettings.desktopPb !== ''
        ? `lg:pb-${paddingSettings.desktopPb} `
        : 'lg:pb-4',
    tabletPt:
      paddingSettings.tabletPt !== null && paddingSettings.tabletPt !== ''
        ? `md:pt-${paddingSettings.tabletPt} `
        : ' md:pt-4 ',
    tabletPb:
      paddingSettings.tabletPb !== null && paddingSettings.tabletPb !== ''
        ? `md:pb-${paddingSettings.tabletPb} `
        : 'md:pb-4',
    mobilePt:
      paddingSettings.mobilePt !== null && paddingSettings.mobilePt !== ''
        ? `pt-${paddingSettings.mobilePt}`
        : 'pt-4',
    mobilePb:
      paddingSettings.mobilePb !== null && paddingSettings.mobilePb !== ''
        ? `pb-${paddingSettings.mobilePb}`
        : 'pb-4',
  };

  const cardStyles = {
    textAlignment: inlineStyles?.textAlignment,
    dimensionColor: inlineStyles?.dimensionColor,
    statColor: inlineStyles?.statisticNumber,
    statLabelColor: inlineStyles?.statLabelNumber,
    separatorColor: inlineStyles?.separatorColor,
  };

  const componentPaddingClasses = `${padding.mobilePt} ${padding.mobilePb} ${padding.tabletPt} ${padding.tabletPb} ${padding.desktopPt} ${padding.desktopPb}`;

  const horizontal = inlineStyles?.contentBoxHorizontal || 'center';
  const horizAlign = {
    left: 'justify-start',
    center: 'justify-center',
    right: 'justify-end',
  };

  const vertical = inlineStyles?.contentBoxVertical;

  const vertAlign = {
    top: 'mt-0 mb-24',
    center: 'my-12',
    bottom: 'mb-0 mt-24',
  };

  const unitType =
    country === 'US' ? t('meetingCapacity.sqFt') : t('meetingCapacity.sqMeter');

  const unitTypeForScreenreader =
    country === 'US'
      ? t('meetingCapacity.squareFeet')
      : t('meetingCapacity.squareMeters');

  const totalExhibitSpaceAreaLocalized =
    country === 'US'
      ? numberFormatter(Math.round(totalArea))
      : numberFormatter(sqFeetToSqMeter(totalArea));

  const largestRoomAreaLocalized =
    country === 'US'
      ? numberFormatter(Math.round(largestMeetingRoomArea))
      : numberFormatter(sqFeetToSqMeter(largestMeetingRoomArea));

  const totalEventSpace = {
    itemName: t('meetingCapacity.totalEventSpace'),
    number: totalExhibitSpaceAreaLocalized,
    unitName: unitType,
    unitNameScreenreader: unitTypeForScreenreader,
  };

  const largestMeetingRoomSetup = {
    itemName: t('meetingCapacity.largestRoomSetup'),
    number: largestRoomAreaLocalized,
    unitName: unitType,
    unitNameScreenreader: unitTypeForScreenreader,
  };

  const meetingRooms = {
    itemName: t('meetingCapacity.meetingRooms'),
    number: numberFormatter(numberOfMeetingRooms),
    unitName: '',
    unitNameScreenreader: '',
  };

  const guestRooms = {
    itemName: t('meetingCapacity.guestRooms'),
    number: numberFormatter(totalRooms),
    unitName: '',
    unitNameScreenreader: '',
  };

  const hideAnimations =
    inlineStyles?.hideAnimations !== 'show' || isReducedMotion;
  const counter = hideAnimations
    ? null
    : CountUp({
        targetOne: totalEventSpace?.number,
        targetTwo: largestMeetingRoomSetup?.number,
        targetThree: meetingRooms?.number,
        targetFour: guestRooms?.number,
      });

  return (
    <div
      data-element-id="meeting-capacity"
      style={{
        backgroundImage: inlineStyles?.componentBackgroundImage
          ? `url(${appliedCloudinaryParams(
              inlineStyles?.componentBackgroundImage,
              inlineStyles?.componentBackgroundRepeat
            )})`
          : null,
        backgroundSize: inlineStyles?.componentBackgroundSize || null,
        backgroundRepeat:
          inlineStyles?.componentBackgroundRepeat || 'no-repeat',
        backgroundPosition: inlineStyles?.componentBackgroundPosition || '',
        backgroundColor: inlineStyles?.componentBackgroundColor || '',
      }}
    >
      <div
        data-testid="meeting-capacity"
        className={cx(
          'container flex items-start',
          horizAlign[horizontal],
          componentPaddingClasses,
          inlineStyles?.showHide && 'hidden'
        )}
      >
        <div
          data-element-id="meeting-capacity-content-area"
          style={{
            backgroundImage: inlineStyles?.contentBackgroundImage
              ? `url(${appliedCloudinaryParams(
                  inlineStyles?.contentBackgroundImage,
                  inlineStyles?.contentBackgroundRepeat
                )})`
              : null,
            backgroundSize: inlineStyles?.contentBackgroundSize || null,
            backgroundRepeat:
              inlineStyles?.contentBackgroundRepeat || 'no-repeat',
            backgroundPosition: inlineStyles?.contentBackgroundPosition || '',
            backgroundColor: inlineStyles?.contentBackgroundColor || '#ffffff',
          }}
          className={cx(
            'p-12 w-full md:w-5/6 xl:w-2/3',
            !vertical || vertical === null
              ? vertAlign['center']
              : vertAlign[vertical]
          )}
        >
          {heading && (
            <h2
              className={cx(
                'text-4xl font-extrabold w-full mb-8 font-headline',
                `text-${inlineStyles?.titleAlignment || 'left'}`
              )}
              style={{ color: inlineStyles?.titleColor }}
              dangerouslySetInnerHTML={{ __html: sanitize(heading) }}
              data-testid="meeting-capacity-headline"
            />
          )}

          <div ref={counter?.ref} className="w-full">
            <ul className="flex flex-wrap lg:flex-nowrap justify-between">
              {totalArea > 0 && (
                <MeetingCapacityInfoItem
                  itemName={totalEventSpace?.itemName}
                  number={counter?.[0] ?? totalEventSpace?.number}
                  total={totalEventSpace?.number}
                  unitName={totalEventSpace?.unitName}
                  unitNameScreenReader={totalEventSpace?.unitNameScreenreader}
                  styling={cardStyles}
                  testName="total-event-space"
                />
              )}

              {largestMeetingRoomArea > 0 && (
                <MeetingCapacityInfoItem
                  itemName={largestMeetingRoomSetup?.itemName}
                  number={counter?.[1] ?? largestMeetingRoomSetup?.number}
                  total={largestMeetingRoomSetup?.number}
                  unitName={largestMeetingRoomSetup?.unitName}
                  unitNameScreenReader={
                    largestMeetingRoomSetup?.unitNameScreenreader
                  }
                  styling={cardStyles}
                  testName="largest-meeting-room"
                />
              )}

              {numberOfMeetingRooms > 0 && (
                <MeetingCapacityInfoItem
                  itemName={meetingRooms?.itemName}
                  number={counter?.[2] ?? meetingRooms?.number}
                  total={meetingRooms?.number}
                  unitName={meetingRooms?.unitName}
                  unitNameScreenReader={meetingRooms?.unitNameScreenreader}
                  styling={cardStyles}
                  testName="meeting-rooms-name"
                />
              )}

              {totalRooms > 0 && (
                <MeetingCapacityInfoItem
                  itemName={guestRooms?.itemName}
                  number={counter?.[3] ?? guestRooms?.number}
                  total={guestRooms?.number}
                  unitName={guestRooms?.unitName}
                  unitNameScreenReader={guestRooms?.unitNameScreenreader}
                  styling={cardStyles}
                  testName="guest-rooms"
                />
              )}
            </ul>
          </div>
          {ctaButtons !== null && ctaButtons !== undefined && (
            <div className="w-full mt-8" data-testid="cta-buttons">
              {[...ctaButtons]?.map((i, e) => {
                return (
                  <a
                    href={i?.link?.url}
                    target={i?.link?.target || '_self'}
                    key={`cta${e}`}
                    className={cx(
                      'btn mr-2 last:mr-0',
                      `btn-${i?.buttonStyle || 'primary'}`
                    )}
                    dangerouslySetInnerHTML={{
                      __html: sanitize(i?.link?.title || ''),
                    }}
                  />
                );
              })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

interface MeetingCapacityCardProps {
  number?: number | string;
  total?: number | string;
  unitName?: string;
  unitNameScreenReader?: string;
  itemName?: string;
  styling?: any;
  testName?: string;
}

export function MeetingCapacityInfoItem({
  number,
  total,
  unitName,
  unitNameScreenReader,
  itemName,
  styling,
  testName,
}: MeetingCapacityCardProps) {
  // calculates width of section to prevent border jitters on animation
  let numWidth = 0;
  const itemNameWidth: number = itemName?.split('')?.length * 11;
  total
    ?.toString()
    ?.split('')
    ?.forEach((c) => (c === ',' ? (numWidth += 12) : (numWidth += 29)));
  let minNumWidth: number = (unitName ? 40 : 0) + numWidth;
  if (itemNameWidth > minNumWidth) minNumWidth = itemNameWidth;
  const wWidth: number = useWindowSize().width || 0;

  return (
    <li
      className={cx(
        `flex-shrink lg:flex-grow lg:w-auto w-1/2 flex justify-center relative border-none lg:border-solid lg:border-r-2 last:border-r-0`
      )}
      style={{ borderColor: styling?.separatorColor }}
      data-testid={testName}
    >
      {unitName && unitNameScreenReader ? (
        <span className="sr-only">
          {`${itemName} ${number} ${unitNameScreenReader}`}
        </span>
      ) : (
        <span className="sr-only">{`${number} ${itemName}`}</span>
      )}
      <span aria-hidden="true" className="flex flex-col items-center p-2">
        <div aria-hidden="true" tabIndex={-1}>
          <div
            className="items-baseline flex justify-center flex-row"
            style={{ minWidth: wWidth > 1024 ? minNumWidth : null }}
          >
            <span
              aria-hidden="true"
              style={{ color: styling?.statColor }}
              className="text-xl sm:text-2xl md:text-4xl  xl:text-5xl font-bold"
            >
              <span aria-hidden="true">{number}</span>
              <span
                aria-hidden="true"
                style={{ color: styling?.dimensionColor }}
                className={cx(
                  'inline-block font-semibold text-sm whitespace-nowrap'
                )}
                dangerouslySetInnerHTML={{
                  __html: sanitize(unitName),
                }}
              />
            </span>
          </div>
          <div aria-hidden="true" className="w-full flex justify-center">
            <span
              aria-hidden="true"
              style={{ color: styling?.statLabelColor }}
              className="text-xs md:text-xm lg:text-base font-semibold uppercase text-center"
              dangerouslySetInnerHTML={{ __html: sanitize(itemName) }}
            />
          </div>
        </div>
      </span>
    </li>
  );
}
