import React, { useState, useEffect } from 'react';
import { slugify, iconmapper, HandleAnimations } from '../functions/helper';
import { ArrowHead } from '@curated-property/icon-list';
import { Category } from './interactive-map.types';
import { useTranslation } from 'next-i18next';
import cx from 'classnames';
import { sanitize } from '@curated-property/utils';

export function MapCategories({
  mapCategoryTitle,
  mapCategoryDescription,
  showMobileCategoryAbove,
  categories,
  selectedCategory,
  setSelectedCategory,
  textAlignment,
  titleColor,
  subtitleColor,
  selectedTabBackgroundColor,
  tabBackgroundColor,
  selectedTabTextColor,
  tabTextColor,
  contentBackgroundColor,
  contentBackgroundImage,
  contentBackgroundSize,
  contentBackgroundPosition,
  contentBackgroundRepeat,
  mobileFilterDropdownBackgroundColor,
  mobileFilterDropdownTextColor,
  wWidth,
  mapOverlayPosition,
  hideAnimations,
  animationDirection,
}: Category) {
  const initCatToggle = wWidth >= 1024 ? true : false;
  const [catToggle, setCatToggle] = useState<boolean>(initCatToggle);
  const { t } = useTranslation();
  const iconMap = iconmapper();
  const categoriesHeight = (categories?.length ?? 0) * 40 + 10;

  useEffect(() => {
    setCatToggle(initCatToggle);
  }, [wWidth, setCatToggle]);

  const handleCategorySelect = (pin?: string) => {
    if (selectedCategory === pin) {
      setSelectedCategory(undefined);
    } else {
      setSelectedCategory(pin);
    }
    if (catToggle) {
      setCatToggle(false);
    }
  };

  const animations = HandleAnimations({
    hideAnimation: hideAnimations,
    start: animationDirection
      ? `${animationDirection}-8`
      : mapOverlayPosition === 'left'
      ? '-translate-x-8'
      : 'translate-x-8',
    delayOne: 'delay-200',
    delayTwo: 'delay-300',
    delayThree: 'delay-0',
  });

  return (
    <div
      ref={animations?.ref}
      className={cx(
        'absolute z-20 min-w-[35%] bg-bg shadow-md lg:p-11 lg:shadow-none lg:top-auto lg:left-auto lg:translate-x-0 lg:translate-y-0 lg:w-auto lg:static',
        {
          'w-full -translate-y-[46px]': showMobileCategoryAbove,
          'w-5/6 left-2/4 transform -translate-x-1/2 -translate-y-6':
            !showMobileCategoryAbove,
        }
      )}
      style={{
        backgroundImage: contentBackgroundImage
          ? `url('${contentBackgroundImage}')`
          : undefined,
        backgroundSize: contentBackgroundSize || undefined,
        backgroundRepeat: contentBackgroundRepeat || undefined,
        backgroundPosition: contentBackgroundPosition || undefined,
        backgroundColor: contentBackgroundColor || undefined,
      }}
    >
      <button
        data-testid="categoryToggle"
        onClick={() => setCatToggle(!catToggle)}
        className="text-lg font-bold text-primary bg-bg px-3 py-2 w-full border-[1px] border-overlay lg:hidden"
        style={{
          backgroundColor: mobileFilterDropdownBackgroundColor,
          color: mobileFilterDropdownTextColor || titleColor,
        }}
        aria-expanded={catToggle}
      >
        <div
          className={cx('flex justify-between items-center', animations?.one)}
        >
          {t('filterMap')}
          <ArrowHead
            className={cx('h-5 transform transition-all ml-3', {
              'rotate-180': catToggle,
            })}
            fillColor={mobileFilterDropdownTextColor || contentBackgroundColor}
          />
        </div>
      </button>
      <h2
        className={cx(
          'text-primary text-3xl font-extrabold font-headline pb-4 flex items-center hidden lg:flex',
          textAlignment,
          animations?.one
        )}
        style={{
          color: titleColor,
        }}
      >
        {mapCategoryTitle ? mapCategoryTitle : t('exploreTheResort')}
      </h2>
      {mapCategoryDescription ? (
        <p
          className={cx(
            'pb-6 text-lg hidden lg:flex',
            textAlignment,
            animations?.two
          )}
          style={{
            color: subtitleColor,
          }}
        >
          {mapCategoryDescription}
        </p>
      ) : null}
      <div
        className={cx(
          'flex flex-col gap-px lg:pb-4 overflow-hidden max-h-0 lg:overflow-visible transition-all duration-500',
          animations?.three
        )}
        style={{ maxHeight: catToggle ? categoriesHeight : undefined }}
      >
        {categories
          ?.filter(
            (pin, idx, self) =>
              idx === self.findIndex((p) => p?.pinCategory === pin?.pinCategory)
          )
          ?.map((pin, key) => {
            const iconKey: string = pin?.pinIcon || 'hotel';
            const isCatSelected: boolean =
              selectedCategory === pin?.pinCategory || !selectedCategory;
            const Icon = iconMap[iconKey as keyof typeof iconMap];
            const buttonColor = isCatSelected
              ? selectedTabTextColor
              : tabTextColor;
            const buttonBackground = isCatSelected
              ? selectedTabBackgroundColor
              : tabBackgroundColor;
            return (
              <button
                data-testid={`${slugify(pin?.pinCategory)}-category`}
                key={key}
                className={cx('flex items-center h-10 py-2 transition-all', {
                  'bg-primary text-bg z-10': isCatSelected,
                  'bg-bg-alt text-text z-0':
                    selectedCategory && selectedCategory !== pin?.pinCategory,
                })}
                style={{
                  backgroundColor: buttonBackground,
                  color: buttonColor,
                }}
                onClick={() => handleCategorySelect(pin?.pinCategory)}
                aria-label={`${pin?.pinCategory}, ${
                  isCatSelected ? t('selected') : t('unselected')
                }`}
              >
                <Icon className="h-7 mx-2 fill-current" />
                <span
                  className="pb-0.5 text-base color-text-alt"
                  dangerouslySetInnerHTML={{
                    __html: sanitize(pin?.pinCategory),
                  }}
                ></span>
              </button>
            );
          })}
      </div>
    </div>
  );
}
