import React from 'react';

export function SaleIcon({
  className,
  fillColor,
}: {
  className?: string;
  fillColor?: string;
}) {
  return (
    <svg
      className={className}
      fill={fillColor}
      viewBox="0 0 24 24"
      aria-hidden="true"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path fill="none" />
      <path
        d="M13.9,10.1c0.4,0,0.6-0.3,0.6-0.6c0-1.8,1.4-3.2,3.2-3.2h0.6c2.5,0,4.5-2,4.5-4.5C23,1.3,22.7,1,22.4,1
	c-0.4,0-0.6,0.3-0.6,0.6c0,1.8-1.4,3.2-3.2,3.2h-0.6c-0.1,0-0.2,0-0.3,0l-1.1-1.1c-0.1-0.1-0.3-0.2-0.5-0.2h-5.2
	c-0.2,0-0.3,0.1-0.5,0.2l-9.1,9.1c-0.3,0.3-0.3,0.7,0,0.9l9.1,9.1c0.1,0.1,0.3,0.2,0.5,0.2c0.2,0,0.3-0.1,0.5-0.2l9.1-9.1
	c0.1-0.1,0.2-0.3,0.2-0.5V8.1c0-0.4-0.3-0.6-0.6-0.6c-0.4,0-0.6,0.3-0.6,0.6V13l-8.4,8.4l-8.1-8.1L11,4.9h4.6L16,5.3
	c-0.8,0.3-1.4,0.9-1.9,1.6c-0.1,0-0.1,0-0.2,0c-1.8,0-3.2,1.4-3.2,3.2s1.4,3.2,3.2,3.2s3.2-1.4,3.2-3.2c0-0.4-0.3-0.6-0.6-0.6
	c-0.4,0-0.6,0.3-0.6,0.6c0,1.1-0.9,1.9-1.9,1.9S12,11.1,12,10.1c0-0.9,0.6-1.7,1.5-1.9c-0.1,0.4-0.2,0.8-0.2,1.2
	C13.3,9.8,13.6,10.1,13.9,10.1L13.9,10.1z"
      />
    </svg>
  );
}
