import React from 'react';
import cx from 'classnames';
import { Arrow } from '@curated-property/icon-list';
import { useTranslation } from 'next-i18next';
import { useAdobeCarouselEvent } from './adobe-analytics';

interface Props {
  className?: string;
  prev: (e?: any) => void;
  next: (e?: any) => void;
  current: number;
  count: number;
  styleOptions?: {
    primaryColour?: string;
    secondaryColour?: string;
  };
  component?: string;
  children?: any;
  visibleSlides?: number;
  sectionHeading?: string;
}

export function SliderControlsWrapper({
  className,
  prev,
  next,
  current,
  count,
  styleOptions,
  component,
  children,
  visibleSlides,
  sectionHeading,
}: Props) {
  const { t } = useTranslation();
  const hideCount = current || count ? 'visible' : 'invisible';
  const multiColumnSlideCount =
    component === 'multiColumnTileCarousel' && count <= (visibleSlides || 3)
      ? 'visible lg:hidden'
      : 'visible';

  useAdobeCarouselEvent({
    sectionHeading: sectionHeading,
    currentIndex: current + 1,
    totalCount: count,
  });

  const sliderControls = () => {
    return (
      <div className="w-full flex flex-col-reverse md:flex-col">
        <div className="flex">{children}</div>
        {count > 1 ? (
          <div
            data-testid={
              component === 'multiColumnTileCarousel' &&
              count <= (visibleSlides || 3)
                ? 'controlsNotVisibleLg'
                : 'controlsVisible'
            }
            className={cx(
              hideCount,
              multiColumnSlideCount,
              'flex items-center justify-end pb-6 md:pb-0 md:pt-5 md:px-10'
            )}
          >
            <button
              className="w-8 mr-7"
              data-testid="backArrow"
              onClick={prev}
              aria-label={`${t('goToPrevious')}, ${t('slideAnnounce', {
                currentSlideNum: current + 1,
                totalSlides: count,
                slideTitle: sectionHeading,
              })}`}
            >
              <Arrow
                className="fill-current text-primary w-8 rtl:transform rtl:rotate-180"
                fillColor={styleOptions?.primaryColour}
              />
            </button>
            <span
              data-testid="carousel-current"
              className="text-2xl text-primary font-bold"
              style={{ color: styleOptions?.secondaryColour }}
            >
              {current + 1}
            </span>
            <span
              className="text-2xl m-1 text-primary"
              style={{ color: styleOptions?.secondaryColour }}
            >
              /
            </span>
            <span
              data-testid="carousel-count"
              className="text-2xl text-primary"
              style={{ color: styleOptions?.secondaryColour }}
            >
              {count}
            </span>
            <button
              className="w-8 ml-7"
              data-testid="fwdArrow"
              onClick={next}
              aria-label={`${t('goToNext')}, ${t('slideAnnounce', {
                currentSlideNum: current + 1,
                totalSlides: count,
                slideTitle: sectionHeading,
              })}`}
            >
              <Arrow
                className="fill-current text-primary w-8 transform rotate-180 rtl:rotate-0"
                fillColor={styleOptions?.primaryColour}
              />
            </button>
          </div>
        ) : null}
      </div>
    );
  };

  return (
    <div data-element-id="media-slider-controls" className={cx(className)}>
      {sliderControls()}
    </div>
  );
}
