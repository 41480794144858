import React from 'react';
export function TurndownService({
  className,
  fillColor,
}: {
  className?: string;
  fillColor?: string;
}) {
  return (
    <svg
      className={className}
      style={{ fill: fillColor }}
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 24 24"
    >
      <g>
        <g>
          <path style={{ fill: 'none' }} d="M0,0v24h24V0H0z" />
        </g>
        <g>
          <path
            style={{ fill: 'none' }}
            d="M6.9648,17.375c-0.1186,0-0.2301-0.0562-0.3007-0.1514L4.375,14.1364V18
                C4.3757,18.8972,5.1028,19.6243,6,19.625h12c0.8972-0.0007,1.6243-0.7278,1.625-1.625v-7.3731L7.1426,17.33
                C7.088,17.3595,7.0269,17.3749,6.9648,17.375z"
          />
          <polygon
            style={{ fill: 'none' }}
            points="7.073,16.517 17.69,10.814 4.658,13.258 		"
          />
          <path
            style={{ fill: 'none' }}
            d="M17,8.375H7C6.2411,8.3739,5.6261,7.7589,5.625,7V6.4229C4.8936,6.5961,4.3766,7.2484,4.375,8v4.5485
                l15.25-2.8594V8c-0.0016-0.7516-0.5186-1.4039-1.25-1.5771V7C18.3739,7.7589,17.7589,8.3739,17,8.375z"
          />
          <path
            d="M18.375,5.6629V5C18.3739,4.2411,17.7589,3.6261,17,3.625H7C6.2411,3.6261,5.6261,4.2411,5.625,5v0.6629
                C4.4752,5.8457,3.628,6.8358,3.625,8v10C3.6265,19.3111,4.6889,20.3735,6,20.375h12c1.3111-0.0015,2.3735-1.0639,2.375-2.375V8
                C20.372,6.8358,19.5248,5.8457,18.375,5.6629z M12.375,6.375v-2H17c0.345,0.0003,0.6247,0.28,0.625,0.625v2
                C17.6247,7.345,17.345,7.6247,17,7.625h-4.625V6.375z M6.375,6.375V5C6.3753,4.655,6.655,4.3753,7,4.375h4.625v3.25H7
                C6.655,7.6247,6.3753,7.345,6.375,7V6.375z M19.625,18c-0.0007,0.8972-0.7278,1.6243-1.625,1.625H6
                C5.1028,19.6243,4.3757,18.8972,4.375,18v-3.8636l2.2891,3.0872c0.1109,0.1495,0.3147,0.1949,0.4785,0.1065l12.4824-6.7032V18z
                 M4.6582,13.2578L17.69,10.8145L7.0732,16.5166L4.6582,13.2578z M19.625,9.6891l-15.25,2.8594V8
                c0.0016-0.7516,0.5186-1.4039,1.25-1.5771V7C5.6261,7.7589,6.2411,8.3739,7,8.375h10C17.7589,8.3739,18.3739,7.7589,18.375,7
                V6.4229C19.1064,6.5961,19.6234,7.2484,19.625,8V9.6891z"
          />
        </g>
      </g>
    </svg>
  );
}
