import React from 'react';
export function PetFriendly({
  className,
  fillColor,
}: {
  className?: string;
  fillColor?: string;
}) {
  return (
    <svg className={className} viewBox="0 0 24 24" style={{ fill: fillColor }}>
      <g data-name="Bounding Box">
        <path style={{ fill: 'none' }} d="M0,0V24H24V0Z" />
      </g>
      <g data-name="Icons Outlined">
        <g>
          <path d="M20.2979,7.51a.3751.3751,0,0,0-.2686-.1465l-2.1758-.1709A3.0326,3.0326,0,0,0,15.4678,6.16a2.1382,2.1382,0,0,0-2.1885.9531l-.0557.1064c-.8125,1.8321-.9306,3.0469-.3623,3.7129a1.6035,1.6035,0,0,0,1.5008.4331,4.0125,4.0125,0,0,0,1.42,1.2425,5.6094,5.6094,0,0,1-.3515,1.1789,5.9749,5.9749,0,0,1-1.6,1.5762.3777.3777,0,0,0-.169.3262l.124,3.5546a1.2527,1.2527,0,0,1-.0644.3858,1.2458,1.2458,0,0,0-.4522-.04l-.6689-3.5839a.374.374,0,0,0-.2344-.2823.3786.3786,0,0,0-.3633.0537,2.58,2.58,0,0,1-3.2343-.12,5.0469,5.0469,0,0,0-2.1436-.8154.3729.3729,0,0,0-.4219.292L5.3574,19.041a.8951.8951,0,0,1-.1816.4365.749.749,0,0,1-.4092-.1035V12.6279a2.9631,2.9631,0,0,1,.248-1.539,19.4515,19.4515,0,0,0,6.95.09.3748.3748,0,0,0-.1054-.7422,18.7175,18.7175,0,0,1-6.7989-.1133.3693.3693,0,0,0-.059-.0083,3.1878,3.1878,0,0,1-.5191-2.6938.3752.3752,0,0,0-.7119-.2373,3.9553,3.9553,0,0,0,.5967,3.3269,3.8227,3.8227,0,0,0-.3506,1.9172v6.8926a.3753.3753,0,0,0,.0479.1836,1.18,1.18,0,0,0,1.0664.5186h.0107c.0791.0146.7041.0791.9482-1.0206L6.8584,15.65a3.6945,3.6945,0,0,1,1.42.5752,3.3593,3.3593,0,0,0,3.6875.4639l.6006,3.22a.3636.3636,0,0,0,.0674.1553c.0967.1308.335.2861.917.2949a.7889.7889,0,0,0,.1562.0156.7409.7409,0,0,0,.42-.1279,1.1748,1.1748,0,0,0,.4091-1.0049l-.1172-3.375a6.2833,6.2833,0,0,0,1.66-1.7041,11.4085,11.4085,0,0,0,.66-2.5235,9.2054,9.2054,0,0,0,2.499-.7285c.65-.416.7012-.7011.9424-2.0683.0507-.2862.1113-.627.1875-1.0362A.3714.3714,0,0,0,20.2979,7.51ZM14.32,10.6172c-.0068.0019-.62.1455-.89-.1729-.1612-.19-.4366-.8564.4668-2.8964l.0459-.087a1.0074,1.0074,0,0,1,.6152-.498C14.8447,7.74,15.5449,9.9893,14.32,10.6172Zm5.1221-1.9053c-.2354,1.3291-.2422,1.333-.6084,1.5674a9.8629,9.8629,0,0,1-2.4795.666.3754.3754,0,0,0-.3106.31c-.0278.1712-.0637.3726-.1034.5828a3.2767,3.2767,0,0,1-.9215-.84c1.1568-1.0481.6417-3.136.313-4.1.0293.0029.05.0058.06.0078,1.4756.0556,1.9072.7939,1.9219.82a.3778.3778,0,0,0,.3037.2012l1.9385.1513C19.5127,8.3154,19.4756,8.5254,19.4424,8.7119Z" />
          <path d="M11.4785,8.4785a.3752.3752,0,1,0,.6192-.4238L9.1729,3.7881a.3752.3752,0,1,0-.6192.4238Z" />
        </g>
      </g>
    </svg>
  );
}
