import dynamic from 'next/dynamic';

const HotelLogo = dynamic<{
  className: string;
  fillColor?: string;
  fillColor2?: string;
}>(() => import('./agprhqq/hotel-logo').then((i) => i.HotelLogo));

export const hotelLogoHeader = (
  <HotelLogo className="fill-bg antialiased w-40 h-24" fillColor2={'#00A7E1'} />
);

export const hotelLogoFooter = (
  <HotelLogo className="fill-bg antialiased w-48 h-24" />
);
