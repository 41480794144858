import React from 'react';
export function Cocktails({
  className,
  fillColor,
}: {
  className?: string;
  fillColor?: string;
}) {
  return (
    <svg className={className} viewBox="0 0 24 24" style={{ fill: fillColor }}>
      <g data-name="Bounding Box">
        <path style={{ fill: 'none' }} d="M0,0V24H24V0Z" />
      </g>
      <g data-name="Icons Outlined">
        <g>
          <path d="M16,19.625H10a.375.375,0,0,0,0,.75h6a.375.375,0,0,0,0-.75Z" />
          <path d="M20.0908,4.6357l-4-1A.3745.3745,0,0,0,16.04,3.634a.3939.3939,0,0,0-.324.127.369.369,0,0,0-.0383.061.3568.3568,0,0,0-.0283.0452L14.6035,6.625H10.3371A3.367,3.367,0,1,0,7,10.375a.375.375,0,0,0,0-.75,2.625,2.625,0,1,1,2.5871-3H7a.3746.3746,0,0,0-.2852.6182l5.876,6.8946V18a.375.375,0,0,0,.75,0V14.14l5.9434-6.8947A.3753.3753,0,0,0,19,6.625H15.4054l.8268-2.18,3.677.9193A.4138.4138,0,0,0,20,5.375a.3752.3752,0,0,0,.0908-.7393Zm-7.123,8.7881L9.517,9.375H12a.375.375,0,0,0,0-.75H9a.3629.3629,0,0,0-.1042.0211L7.8125,7.375H14.319l-.5609,1.4789,0,0-1.1427,3.013a.3749.3749,0,1,0,.7012.2656l1.046-2.7578h2.0953ZM18.1816,7.375l-1.0774,1.25H14.6469l.474-1.25Z" />
        </g>
      </g>
    </svg>
  );
}
