import React from 'react';
const EveningReceptionIcon = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg viewBox="0 0 44 44" focusable="false" {...props}>
      <path
        d="M15.927 5.502s-3.354 11.003-.612 14.785c1.778 2.452 6.68 4.977 6.62 7.43-.016.614.268 10.237 0 10.507-.27.269-6.62.563-6.62.854 0 .292 5.409.293 7.213.292 1.72-.003 6.871-.061 6.871-.292 0-.231-5.922-.588-6.188-.854s0-9.707 0-10.507c0-2.405 3.939-4.974 5.897-7.43 2.923-3.666-.751-14.785-.751-14.785h-12.43z"
        strokeWidth="1.5"
        fill="none"
        fillRule="evenodd"
        stroke="currentColor"
      />
    </svg>
  );
};

export default EveningReceptionIcon;
