import React from 'react';

interface FormChangeMonitorProps {
  onAny?: (value: any) => void;
  onChange?: (value: any) => void;
  onClick?: (value: any) => void;
}

export const FormChangeMonitor: React.FC<
  React.PropsWithChildren<FormChangeMonitorProps>
> = ({ children, onAny, onChange, onClick }) => {
  return (
    <div
      onChange={(val) => {
        if (typeof onChange == 'function') onChange(val);
        if (typeof onAny == 'function') onAny(val);
      }}
      onClick={(val) => {
        if (typeof onClick == 'function') onClick(val);
        if (typeof onAny == 'function') onAny(val);
      }}
    >
      {children}
    </div>
  );
};
