import React from 'react';
export function DigitalKey({
  className,
  fillColor,
}: {
  className?: string;
  fillColor?: string;
}) {
  return (
    <svg className={className} viewBox="0 0 24 24" style={{ fill: fillColor }}>
      <g data-name="Bounding Box">
        <path style={{ fill: 'none' }} d="M0,0V24H24V0Z" />
      </g>
      <g data-name="Icons Outlined">
        <g>
          <path d="M18.8008,5.2a5.3631,5.3631,0,0,0-8.6792,6.0258l-5.95,5.95a1.8755,1.8755,0,0,0,2.6523,2.6523l.3726-.3725.5171.5171a1.3665,1.3665,0,0,0,.9726.4023h.001a1.363,1.363,0,0,0,.9707-.4023l.709-.7081a1.3788,1.3788,0,0,0-.001-1.9443l-.5174-.5167,1.4774-1.4774a.3757.3757,0,0,0-.5313-.5313L6.293,19.2969a1.153,1.153,0,0,1-1.59,0,1.1271,1.1271,0,0,1,0-1.59l5.789-5.789a5.4042,5.4042,0,0,0,.7071.8818,5.3731,5.3731,0,0,0,7.6006.001l.001-.001a5.3731,5.3731,0,0,0,0-7.6ZM9.3185,17.3339l.5174.5167a.6274.6274,0,0,1,.001.8838l-.709.708a.6189.6189,0,0,1-.44.1826h-.001a.618.618,0,0,1-.4414-.1836l-.5171-.5171Zm8.952-5.0644-.001.001a4.6263,4.6263,0,1,1,.001-.001Z" />
          <path d="M14.6738,6.6738a1.8755,1.8755,0,1,0,2.6524,0A1.8792,1.8792,0,0,0,14.6738,6.6738Zm2.1211,2.1211a1.124,1.124,0,1,1,0-1.59A1.1233,1.1233,0,0,1,16.7949,8.7949Z" />
        </g>
      </g>
    </svg>
  );
}
