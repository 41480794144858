import React from 'react';

export function Luxury({
  className,
  fillColor,
}: {
  className?: string;
  fillColor?: string;
}) {
  return (
    <svg className={className} viewBox="0 0 42 34" fill={fillColor}>
      <g
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <g
          transform="translate(1.500000, 1.500000)"
          stroke="currentColor"
          strokeWidth="1.75"
        >
          <path d="M29.3,11.9 C32.2,14.8 26.7,22 23.5,18.7 C20.9,16.1 23.2,11.7 27.1,8.4 C20.9,9.3 25.3,3.4 19.6,0 C13.9,3.4 18.2,9.4 12.1,8.4 C16,11.7 18.4,16.1 15.7,18.7 C12.4,22 7.1,14.7 9.9,11.9 C7.5,7.5 0,8.8 0,8.8 L11,26.9 L28.4,26.9 L39.4,8.8 C39.2,8.8 31.6,7.5 29.3,11.9 Z" />
          <line x1="11.3" y1="31" x2="27.7" y2="31" />
        </g>
      </g>
    </svg>
  );
}
