import { FooterProps, MenuProps } from '@curated-property/shared-components';
import dynamic from 'next/dynamic';

export const AdditionalHead = () => {
  return (
    <>
      <link rel="stylesheet" href="https://use.typekit.net/kgh3nst.css"></link>
      <style>{`
              body {
                font-family: zeitung, sans-serif !important;
              }
              .btn, button:not([data-element-id="language-wrapper"] button, [data-element-id="honors-header-login"] button, [data-osc-product="shop-form-dates"] div span), [data-element-id="navDesktop"], [data-element-id="navDesktop"] a, [data-element-id="subtitle-wrapper"] {
                font-family: zeitung, sans-serif !important;
                font-weight: 600 !important;
              }
          `}</style>
    </>
  );
};

const SharedHeader = dynamic<MenuProps>(() =>
  import('@curated-property/shared-components').then((f) => f.Header)
);

export const Header = (props: MenuProps) => {
  return <SharedHeader {...props} hideGroupLink={true} maxRooms={1} />;
};

const SharedFooter = dynamic<FooterProps>(() =>
  import('@curated-property/shared-components').then((f) => f.Footer)
);

export const Footer = (props: FooterProps) => {
  return <SharedFooter {...props} brandPhoneNumber="+1-833-STAYEVER" />;
};
