import React from 'react';
const FirearmsIcon = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg viewBox="0 0 44 44" focusable="false" {...props}>
      <g
        strokeWidth="1.5"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke="currentColor"
      >
        <path d="M3.576 10.149h2.353l.783-.786h.785l.785.786h22.744l1.57-1.572 1.567 1.572h1.568c.677 0 1.7.957 2.528 4.717.302 1.373 2.165 1.275 2.165 1.582 0 .676-1.034 1.56-1.827 1.56-2.414 0-2.148 2.03-2.046 3.371.124 1.652 1.805 3.616 2.775 7.925.996 4.425-.637 7.564-2.811 7.564H30.24c-.764 0-2.162-1.327-2.539-2.836-.261-1.045-.816-3.27-1.434-12.332-.051-.733-1.14-3.692-4.654-3.692H6.712l-.784-2.358-2.352-1.572v-3.93z" />
        <path d="M17.693 18.128v2.78c0 1.391 1.387 4.172 4.162 4.172h4.594" />
        <path d="M25.995 21.076c-.78.914-1.966 1.361-3.14 1.361-.558 0-1.01-.403-1.01-.964 0-.83 1.821-.451 2.05-2.577" />
      </g>
    </svg>
  );
};

export default FirearmsIcon;
