import React from 'react';
export function Restaurant({
  className,
  fillColor,
}: {
  className?: string;
  fillColor?: string;
}) {
  return (
    <svg className={className} viewBox="0 0 24 24" style={{ fill: fillColor }}>
      <g data-name="Bounding Box">
        <path style={{ fill: 'none' }} d="M0,0V24H24V0Z" />
      </g>
      <g data-name="Icons Outlined">
        <g>
          <path d="M15.4521,9.5518a.3751.3751,0,0,0,.53-.001l2.8555-2.8574a.375.375,0,1,0-.5313-.5293L15.4512,9.0215A.375.375,0,0,0,15.4521,9.5518Z" />
          <path d="M20.2646,7.5918a.3747.3747,0,0,0-.53.001l-2.8555,2.8564a1.6882,1.6882,0,0,1-2.3242-.001,1.6461,1.6461,0,0,1,0-2.3261L17.41,5.2646a.3749.3749,0,0,0-.5313-.5292L14.0234,7.5918a2.3916,2.3916,0,0,0-.2219,3.1148c-.0211.0139-.0486.0093-.0671.0278L12.5,11.9688,5.2656,4.7344a.36.36,0,0,0-.2973-.0968.3606.3606,0,0,0-.2974.0968l-.3535.3545a2.38,2.38,0,0,0,0,3.3584L8.5605,12.69a.3772.3772,0,0,0,.5313,0l1.5337-1.5337L11.9687,12.5,5.7344,18.7344a.3756.3756,0,0,0,.5312.5312l8-8c.018-.0181.0135-.0451.0271-.0658a2.3795,2.3795,0,0,0,3.1175-.2213l2.8554-2.8564A.3749.3749,0,0,0,20.2646,7.5918ZM8.8262,11.8945,4.8486,7.916a1.625,1.625,0,0,1,0-2.2969l.12-.12,5.126,5.126Z" />
          <path d="M14.2656,13.7344a.3756.3756,0,0,0-.5312.5312l5,5a.3756.3756,0,0,0,.5312-.5312Z" />
        </g>
      </g>
    </svg>
  );
}
