import React from 'react';

const InternetIcon = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg viewBox="0 0 44 44" focusable="false" {...props}>
      <g strokeWidth="1.5" fill="none" fillRule="evenodd" stroke="currentColor">
        <path
          d="M32.811 22.478a14.637 14.637 0 0 0-10.865-4.816 14.6 14.6 0 0 0-10.861 4.803M27.266 27.488a7.166 7.166 0 0 0-5.319-2.358 7.148 7.148 0 0 0-5.317 2.351M37.896 16.578A22.543 22.543 0 0 0 21.952 10 22.531 22.531 0 0 0 6 16.567"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M23.701 33.17a1.753 1.753 0 1 1-3.505 0 1.753 1.753 0 0 1 3.505 0z" />
      </g>
    </svg>
  );
};

export default InternetIcon;
