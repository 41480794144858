import React from 'react';
export function Language({
  className,
  fillColor,
}: {
  className?: string;
  fillColor?: string;
}) {
  return (
    <svg className={className} viewBox="0 0 24 24" style={{ fill: fillColor }}>
      <g data-name="Bounding Box">
        <path style={{ fill: 'none' }} d="M0,0V24H24V0Z" />
      </g>
      <g data-name="Icons Outlined">
        <path d="M12,3.625c-.0153,0-.03.0022-.0451.0023l-.0063,0a8.3737,8.3737,0,0,0,.05,16.7473l0,0,0,0,.0008,0,.0008,0,0,0,0,0A8.375,8.375,0,0,0,12,3.625Zm-1.2892.8676a10.8214,10.8214,0,0,0-2.21,2.5926A8.6559,8.6559,0,0,1,7.06,6.2029,7.592,7.592,0,0,1,10.7108,4.4926ZM6.5079,6.7244A9.418,9.418,0,0,0,8.139,7.7357a9.3434,9.3434,0,0,0-.995,3.8893H4.394A7.59,7.59,0,0,1,6.5079,6.7244ZM4.394,12.375h2.75a9.3434,9.3434,0,0,0,.995,3.8893,9.418,9.418,0,0,0-1.6311,1.0113A7.59,7.59,0,0,1,4.394,12.375ZM7.06,17.7971a8.6559,8.6559,0,0,1,1.4409-.8823,10.8214,10.8214,0,0,0,2.21,2.5926A7.592,7.592,0,0,1,7.06,17.7971Zm4.5652,1.4776a10.3827,10.3827,0,0,1-2.4122-2.6632,8.0062,8.0062,0,0,1,2.4122-.4708Zm0-3.8835a8.8083,8.8083,0,0,0-2.7817.5694,8.5627,8.5627,0,0,1-.9487-3.5856h3.73Zm0-3.7662h-3.73a8.5632,8.5632,0,0,1,.9487-3.5856,8.8083,8.8083,0,0,0,2.7817.5694Zm0-3.7657a8.0062,8.0062,0,0,1-2.4122-.4708A10.3827,10.3827,0,0,1,11.625,4.7253Zm7.981,3.7657h-2.75a9.3434,9.3434,0,0,0-.995-3.8893,9.418,9.418,0,0,0,1.6311-1.0113A7.59,7.59,0,0,1,19.606,11.625ZM16.94,6.2029a8.6559,8.6559,0,0,1-1.4409.8823,10.8214,10.8214,0,0,0-2.21-2.5926A7.592,7.592,0,0,1,16.94,6.2029ZM12.375,4.7253a10.3827,10.3827,0,0,1,2.4122,2.6632,8.0062,8.0062,0,0,1-2.4122.4708Zm0,3.8835a8.8083,8.8083,0,0,0,2.7817-.5694,8.5632,8.5632,0,0,1,.9487,3.5856h-3.73Zm0,3.7662h3.73a8.5627,8.5627,0,0,1-.9487,3.5856,8.8083,8.8083,0,0,0-2.7817-.5694Zm0,3.7657a8.0062,8.0062,0,0,1,2.4122.4708,10.3827,10.3827,0,0,1-2.4122,2.6632Zm.9142,3.3667a10.8214,10.8214,0,0,0,2.21-2.5926,8.6559,8.6559,0,0,1,1.4409.8823A7.592,7.592,0,0,1,13.2892,19.5074Zm4.2029-2.2318a9.418,9.418,0,0,0-1.6311-1.0113,9.3434,9.3434,0,0,0,.995-3.8893h2.75A7.59,7.59,0,0,1,17.4921,17.2756Z" />
      </g>
    </svg>
  );
}
