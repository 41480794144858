import { format, isAfter } from 'date-fns';

/*
 *
 * takes in the hotels timezone offset and opening time
 * and calculates the correct starting date.
 */

export function parseBookingDate(gmtHours?: number, arrivalDate?: string) {
  let startDate = new Date();
  let formattedTimezoneOffset = format(
    new Date(),
    "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"
  );
  formattedTimezoneOffset = formattedTimezoneOffset.slice(-6);
  const offsetHours =
    gmtHours === undefined || gmtHours === null
      ? 0
      : gmtHours - Number(formattedTimezoneOffset?.split(':')[0]);
  startDate.setHours(startDate.getHours() + offsetHours);
  if (arrivalDate) {
    const manualStartDate = new Date(arrivalDate);
    const splitDate = arrivalDate?.split('-');
    manualStartDate.setDate(parseInt(splitDate[2]));
    manualStartDate.setMonth(parseInt(splitDate[1]) - 1);
    manualStartDate.setFullYear(parseInt(splitDate[0]));
    isAfter(manualStartDate, startDate) && (startDate = manualStartDate);
  }
  return startDate;
}
