import React from 'react';
export function EvCharging({
  className,
  fillColor,
}: {
  className?: string;
  fillColor?: string;
}) {
  return (
    <svg
      className={className}
      style={{ fill: fillColor }}
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 24 24"
    >
      <g>
        <g>
          <path style={{ fill: 'none' }} d="M0,0v24h24V0H0z" />
        </g>
        <g>
          <g>
            <path
              d="M20,5.625h-0.625V4c0-0.2071-0.1679-0.375-0.375-0.375S18.625,3.7929,18.625,4v1.625h-1.25V4
            c0-0.2071-0.1679-0.375-0.375-0.375S16.625,3.7929,16.625,4v1.625H16C15.7929,5.6251,15.6251,5.7929,15.625,6v2
            C15.6261,8.7589,16.2411,9.3739,17,9.375h0.625V17c0,0.3452-0.2798,0.625-0.625,0.625S16.375,17.3452,16.375,17v-5
            c0-0.7594-0.6156-1.375-1.375-1.375S13.625,11.2406,13.625,12v2c-0.0003,0.345-0.28,0.6247-0.625,0.625h-0.625V8
            C12.3735,6.6889,11.3111,5.6265,10,5.625H6C4.6889,5.6265,3.6265,6.6889,3.625,8v10c0,0.2071,0.1679,0.375,0.375,0.375
            c0.2071,0,0.375-0.1679,0.375-0.375V8C4.3757,7.1028,5.1028,6.3757,6,6.375h4C10.8972,6.3757,11.6243,7.1028,11.625,8v10
            c0,0.2071,0.1679,0.375,0.375,0.375s0.375-0.1679,0.375-0.375v-2.625H13c0.7589-0.0011,1.3739-0.6161,1.375-1.375v-2
            c0-0.3452,0.2798-0.625,0.625-0.625s0.625,0.2798,0.625,0.625v5c0,0.7594,0.6156,1.375,1.375,1.375s1.375-0.6156,1.375-1.375
            V9.375H19C19.7589,9.3739,20.3739,8.7589,20.375,8V6C20.3749,5.7929,20.2071,5.6251,20,5.625z M19.625,8
            C19.6247,8.345,19.345,8.6247,19,8.625h-2C16.655,8.6247,16.3753,8.345,16.375,8V6.375h3.25V8z"
            />
            <path
              d="M10.375,11V8C10.3749,7.7929,10.2071,7.6251,10,7.625H6C5.7929,7.6251,5.6251,7.7929,5.625,8v3
            C5.6251,11.2071,5.7929,11.3749,6,11.375h4C10.2071,11.3749,10.3749,11.2071,10.375,11z M9.625,10.625h-3.25v-2.25h3.25V10.625z"
            />
            <path
              d="M7.832,17.335c0.0521,0.0264,0.1096,0.0401,0.168,0.04c0.1419,0.0001,0.2716-0.0801,0.335-0.207l1-2
            c0.0931-0.1846,0.0189-0.4098-0.1657-0.5029C9.1168,14.6386,9.0588,14.6249,9,14.625H7.6064l0.7286-1.457
            c0.0953-0.1838,0.0236-0.41-0.1602-0.5053c-0.1838-0.0953-0.41-0.0236-0.5053,0.1602c-0.0016,0.003-0.0031,0.006-0.0046,0.0091
            l-1,2c-0.0931,0.1846-0.0189,0.4098,0.1657,0.5029C6.8832,15.3614,6.9412,15.3751,7,15.375h1.3936L7.665,16.832
            C7.5722,17.017,7.647,17.2422,7.832,17.335z"
            />
            <path
              d="M12,19.625H4c-0.2071,0-0.375,0.1679-0.375,0.375S3.7929,20.375,4,20.375h8c0.2071,0,0.375-0.1679,0.375-0.375
            S12.2071,19.625,12,19.625z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
