import React from 'react';
export function Remote({
  className,
  fillColor,
}: {
  className?: string;
  fillColor?: string;
}) {
  return (
    <svg className={className} viewBox="0 0 24 24" style={{ fill: fillColor }}>
      <g data-name="Bounding Box">
        <path style={{ fill: 'none' }} d="M0,0V24H24V0Z" />
      </g>
      <g data-name="Icons Outlined">
        <g>
          <path d="M10.27,5.2041a.375.375,0,0,0,.23.6709.3707.3707,0,0,0,.23-.0791,2.1873,2.1873,0,0,1,2.54-.002.3745.3745,0,1,0,.459-.5918A2.9573,2.9573,0,0,0,10.27,5.2041Z" />
          <path d="M9,4.375a.372.372,0,0,0,.2285-.0781,4.7527,4.7527,0,0,1,5.543-.001.3754.3754,0,1,0,.457-.5957,5.5247,5.5247,0,0,0-6.457.0029A.3749.3749,0,0,0,9,4.375Z" />
          <path d="M15,6.625H9A1.377,1.377,0,0,0,7.625,8V20A1.377,1.377,0,0,0,9,21.375h6A1.377,1.377,0,0,0,16.375,20V8A1.377,1.377,0,0,0,15,6.625ZM15.625,20a.6256.6256,0,0,1-.625.625H9A.6256.6256,0,0,1,8.375,20V8A.6256.6256,0,0,1,9,7.375h6A.6256.6256,0,0,1,15.625,8Z" />
          <path d="M12,13.375A2.375,2.375,0,1,0,9.625,11,2.3777,2.3777,0,0,0,12,13.375Zm0-4A1.625,1.625,0,1,1,10.375,11,1.6262,1.6262,0,0,1,12,9.375Z" />
          <path d="M12,14.375a.625.625,0,1,0,.625.625A.6256.6256,0,0,0,12,14.375Z" />
          <path d="M14,14.375a.625.625,0,1,0,.625.625A.6256.6256,0,0,0,14,14.375Z" />
          <path d="M10,14.375a.625.625,0,1,0,.625.625A.6256.6256,0,0,0,10,14.375Z" />
          <path d="M12,16.375a.625.625,0,1,0,.625.625A.6256.6256,0,0,0,12,16.375Z" />
          <path d="M14,16.375a.625.625,0,1,0,.625.625A.6256.6256,0,0,0,14,16.375Z" />
          <path d="M10,16.375a.625.625,0,1,0,.625.625A.6256.6256,0,0,0,10,16.375Z" />
          <path d="M12,18.375a.625.625,0,1,0,.625.625A.6256.6256,0,0,0,12,18.375Z" />
          <path d="M14,18.375a.625.625,0,1,0,.625.625A.6256.6256,0,0,0,14,18.375Z" />
          <path d="M10,18.375a.625.625,0,1,0,.625.625A.6256.6256,0,0,0,10,18.375Z" />
        </g>
      </g>
    </svg>
  );
}
