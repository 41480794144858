import React from 'react';
export function Keys({
  className,
  fillColor,
}: {
  className?: string;
  fillColor?: string;
}) {
  return (
    <svg
      className={className}
      style={{ fill: fillColor }}
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 24 24"
    >
      <g>
        <g>
          <path style={{ fill: 'none' }} d="M0,0v24h24V0H0z" />
        </g>
        <g>
          <path
            d="M20.2656,12.7344l-3.0873-3.0873c0.124-0.37,0.1903-0.7569,0.1967-1.1471c0.0057-2.1346-1.7201-3.8697-3.8547-3.8754
          c-2.0207-0.0054-3.7045,1.5465-3.8635,3.5609c-1.0643,0.5209-1.927,1.3786-2.454,2.44H6
          c-0.0995-0.0002-0.1951,0.0392-0.2656,0.1094l-2,2C3.6643,12.8053,3.6249,12.9006,3.625,13v7
          C3.6251,20.2071,3.7929,20.3749,4,20.375h6c0.2071-0.0001,0.3749-0.1679,0.375-0.375v-7
          c0.0002-0.0995-0.0392-0.1951-0.1094-0.2656l-2-2c-0.0597-0.0566-0.1364-0.0918-0.2182-0.1
          c0.4-0.6651,0.9607-1.2189,1.6306-1.6107c0.2685,2.1023,2.1904,3.5889,4.2927,3.3204c0.3159-0.0403,0.6256-0.1199,0.9219-0.2367
          c0.1931-0.0746,0.2892-0.2915,0.2146-0.4846s-0.2915-0.2892-0.4846-0.2146c-1.5898,0.6286-3.3881-0.1505-4.0168-1.7403
          c-0.1248-0.3156-0.1968-0.6495-0.2132-0.9884c0.4178-0.1538,0.8557-0.2459,1.3-0.2735c0.2259,0.7233,0.9953,1.1265,1.7186,0.9006
          c0.7233-0.2259,1.1265-0.9953,0.9006-1.7186C14.1327,7.0143,13.601,6.624,13,6.625c-0.2071,0-0.375,0.1679-0.375,0.375
          S12.7929,7.375,13,7.375c0.3452,0,0.625,0.2798,0.625,0.625c0,0.3452-0.2798,0.625-0.625,0.625S12.375,8.3452,12.375,8
          C12.3749,7.7929,12.2071,7.6251,12,7.625c-0.5309,0.0076-1.0577,0.0948-1.5627,0.2589c0.3288-1.6929,1.9677-2.7988,3.6606-2.4701
          s2.7988,1.9677,2.47,3.6606c-0.0364,0.1873-0.0899,0.371-0.1598,0.5485c-0.0148,0.0407-0.0223,0.0838-0.022,0.1271l-0.0019,0.0108
          c0.0011,0.0951,0.0386,0.1861,0.1046,0.2545l0.0033,0.008l3.1328,3.1319v1.47h-1.25V14c-0.0001-0.2071-0.1679-0.3749-0.375-0.375
          h-0.625V13c-0.0001-0.2071-0.1679-0.3749-0.375-0.375h-1c-0.2071,0-0.375,0.1679-0.375,0.375s0.1679,0.375,0.375,0.375h0.625V14
          c0.0001,0.2071,0.1679,0.3749,0.375,0.375h0.625V15c0.0001,0.2071,0.1679,0.3749,0.375,0.375h2
          c0.2071-0.0001,0.3749-0.1679,0.375-0.375v-2C20.3752,12.9005,20.3358,12.8049,20.2656,12.7344z M7.8447,11.375l1.78,1.78v6.47
          H4.375v-6.47l1.78-1.78h0.748C6.7262,11.8989,6.6324,12.4472,6.625,13C6.6251,13.2071,6.7929,13.3749,7,13.375
          c0.3452,0,0.625,0.2798,0.625,0.625S7.3452,14.625,7,14.625S6.375,14.3452,6.375,14c0-0.2071-0.1679-0.375-0.375-0.375
          S5.625,13.7929,5.625,14c-0.0039,0.7594,0.6086,1.3781,1.368,1.382c0.7594,0.0039,1.3781-0.6086,1.382-1.368
          c0.0031-0.6056-0.3904-1.1419-0.969-1.3207c0.0276-0.4508,0.1217-0.895,0.2791-1.3183H7.8447z"
          />
        </g>
      </g>
    </svg>
  );
}
