import dynamic from 'next/dynamic';

const HotelLogo = dynamic<{ className: string }>(() =>
  import('./dxbpjhi/hotel-logo').then((i) => i.HotelLogo)
);

export const hotelLogoHeader = (
  <HotelLogo className="fill-current text-bg antialiased w-32" />
);

export const hotelLogoFooter = (
  <HotelLogo className="fill-current text-bg antialiased w-32" />
);
