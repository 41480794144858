import dynamic from 'next/dynamic';

const HotelLogo = dynamic<{ className: string }>(() =>
  import('./dfwanhh/hotel-logo').then((i) => i.HotelLogo)
);

export const hotelLogoHeader = (
  <HotelLogo className="fill-current text-bg antialiased w-40 md:w-48 h-20" />
);

export const hotelLogoFooter = (
  <HotelLogo className="fill-current text-bg antialiased w-32 md:w-48 h-20" />
);
