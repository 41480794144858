import {
  FooterProps,
  BreadcrumbProps,
} from '@curated-property/shared-components';
import dynamic from 'next/dynamic';

const copyrightStatement = `© ${new Date().getFullYear()} Hilton.`;

const SharedBreadCrumbs = dynamic<BreadcrumbProps>(() =>
  import('@curated-property/shared-components').then((b) => b.BreadCrumbs)
);

// this overrides breadcrumbs
export const BreadCrumbs = (props: BreadcrumbProps) => {
  return (
    <SharedBreadCrumbs
      {...props}
      delimiter={<span className="text-secondary mx-2">/</span>}
      styleOptions={{
        anchorClasses: 'hover:underline',
        activeAnchorClasses: 'inline-block',
      }}
    />
  );
};

// this override footer
const SharedFooter = dynamic<FooterProps>(() =>
  import('@curated-property/shared-components').then((f) => f.Footer)
);

export const Footer = (props: FooterProps) => {
  return (
    <SharedFooter
      {...props}
      brandPhoneNumber="1-800-HILTONS"
      styleOptions={{
        backgroundColor: 'bg-bg',
        textColor: 'text-text',
        copyrightStyles: {
          backgroundColor: 'bg-secondary',
          textColor: 'text-bg',
        },
        columnStyles: {
          columnOne: {
            logoClasses: 'border-text-alt',
          },
        },
      }}
      showCopyright={true}
      copyrightContent={copyrightStatement}
    />
  );
};
