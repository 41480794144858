import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import { useTranslation } from 'next-i18next';
import { sqFeetToSqMeter, numberFormatter } from './functions/helper';
import { appliedCloudinaryParams } from '@curated-property/utils';
import { sanitize } from '@curated-property/utils';
import {
  GIS_Array,
  GIS_TextAlignment,
  GIS_Padder,
  StyleObject,
} from './functions/global-instance-styles';
import { PageProps } from '../../../../apps/single-site/mapping/default-props-to-components';

interface MeetingCapacityChartsProps {
  meetings?: PageProps['dxGqlInfo']['hotel']['meetings'];
  content?: {
    title?: string;
    subtitle?: string;
    copy?: string;
  };
  country?: string;
  globalStyles?: StyleObject;
  instanceStyles?: StyleObject;
}

export function MeetingCapacityCharts({
  meetings,
  content,
  country,
  globalStyles,
  instanceStyles,
}: MeetingCapacityChartsProps) {
  const { t } = useTranslation();

  const categorySlugs = [
    'banquet',
    'conference',
    'hollowSquare',
    'reception',
    'schoolRoom',
    'theatre',
    'uShape',
  ];

  const inlineStyles = GIS_Array(globalStyles, instanceStyles);
  const textAlignment = GIS_TextAlignment(inlineStyles?.textAlignment);
  const paddingStyles = GIS_Padder(
    inlineStyles?.paddingTop,
    inlineStyles?.paddingBottom
  );
  const showHide = inlineStyles?.showHide && 'hidden';

  const notAvailable = (
    <>
      <span className="sr-only">{t('meetingCapacityCharts.notAvailable')}</span>
      <span aria-hidden={true}>-</span>
    </>
  );

  const [showSqFeet, setShowSqFeet] = useState(country === 'US' ? true : false);

  useEffect(() => {
    const prefUnit = JSON.parse(
      localStorage.getItem('meetingCapacityShowSqFeet')
    );
    if (prefUnit) {
      setShowSqFeet(prefUnit);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem(
      'meetingCapacityShowSqFeet',
      JSON.stringify(showSqFeet)
    );
  }, [showSqFeet]);

  function handleClickEvent(e) {
    showSqFeet === true ? setShowSqFeet(false) : setShowSqFeet(true);
  }

  const containerClasses = 'w-full lg:w-4/5 mx-auto';
  const tdClass = 'py-3 px-4 text-center relative';
  const leftColWidth = 'w-84 min-w-[144px]';
  const switchCommonClasses =
    'flex h-6 items-center justify-center text-black pointer-events-none';

  const toggleSwitchColour = inlineStyles?.mtgChartToggleSwitchColour;

  return (
    <div
      className={cx(showHide)}
      style={{
        backgroundImage: inlineStyles?.componentBackgroundImage
          ? `url('${appliedCloudinaryParams(
              inlineStyles?.componentBackgroundImage,
              inlineStyles?.componentBackgroundRepeat
            )}')`
          : null,
        backgroundSize: inlineStyles?.componentBackgroundSize || 'cover',
        backgroundRepeat: inlineStyles?.componentBackgroundRepeat || null,
        backgroundPosition: inlineStyles?.componentBackgroundPosition || null,
        backgroundColor: inlineStyles?.componentBackgroundColor || null,
      }}
      data-element-id="meeting-capacity-charts"
      data-testid="meetingCapacityCharts"
    >
      <div
        className={cx('container', paddingStyles)}
        style={{
          backgroundImage: inlineStyles?.contentBackgroundImage
            ? `url('${appliedCloudinaryParams(
                inlineStyles?.contentBackgroundImage,
                inlineStyles?.contentBackgroundRepeat
              )}')`
            : null,
          backgroundSize: inlineStyles?.contentBackgroundSize || 'cover',
          backgroundRepeat: inlineStyles?.contentBackgroundRepeat || null,
          backgroundPosition: inlineStyles?.contentBackgroundPosition || null,
          backgroundColor: inlineStyles?.contentBackgroundColor || null,
        }}
      >
        {content && content?.title && (
          <div className={cx('mb-6', containerClasses)}>
            {content?.title && (
              <h2
                className={cx(
                  'text-2xl md:text-4xl font-black leading-none font-headline pb-4',
                  `text-${!textAlignment ? 'center' : textAlignment}`
                )}
                dangerouslySetInnerHTML={{
                  __html: sanitize(content?.title),
                }}
                style={{
                  color: inlineStyles?.titleColor,
                }}
              />
            )}

            {content?.subtitle && (
              <h3
                className={cx(
                  'font-headline font-black',
                  `text-${!textAlignment ? 'center' : textAlignment}`
                )}
                dangerouslySetInnerHTML={{
                  __html: sanitize(content?.subtitle),
                }}
                style={{
                  color: inlineStyles?.subtitleColor,
                }}
              />
            )}
            {content?.copy && (
              <div
                className={`text-${!textAlignment ? 'center' : textAlignment}`}
                dangerouslySetInnerHTML={{
                  __html: sanitize(content?.copy),
                }}
                style={{
                  color: inlineStyles?.textColor,
                }}
              />
            )}
          </div>
        )}
        <div className={cx(containerClasses)}>
          <div
            className={cx(
              'w-full flex flex-row-reverse items-center pt-4 md:pt-2'
            )}
          >
            <button
              data-testid="switchButton"
              tabIndex={1}
              type="button"
              role="switch"
              aria-checked={showSqFeet === true ? true : false}
              id="unitType"
              className={cx(
                'border rounded-full slider-switch lowercase p-0.5 flex text-xs',
                !inlineStyles?.mtgChartToggleBgColour && 'bg-white'
              )}
              onClick={(e) => {
                handleClickEvent(e);
              }}
              style={{
                borderColor: inlineStyles?.mtgChartToggleOutlineColour,
                backgroundColor: inlineStyles?.mtgChartToggleBgColour,
              }}
            >
              <span
                className={cx(
                  'switch-option',
                  switchCommonClasses,
                  showSqFeet ? 'selected w-12' : 'unselected w-6'
                )}
              >
                <span
                  className={cx(
                    'screen-text text-right rounded-full',
                    showSqFeet
                      ? 'selected pl-1 visible'
                      : `unselected justify-start w-6 h-6 ${
                          toggleSwitchColour ? '' : 'bg-primary'
                        }`
                  )}
                  style={{
                    backgroundColor: `${
                      toggleSwitchColour && !showSqFeet
                        ? toggleSwitchColour
                        : ''
                    }`,
                    color: inlineStyles?.mtgChartMeasureTypeColour,
                  }}
                >
                  {t('meetingCapacity.sqFt')}
                </span>
                <span className="sr-only">
                  {t('meetingCapacityCharts.squareFeet')}
                </span>
              </span>
              <span
                className={cx(
                  'switch-option',
                  switchCommonClasses,
                  !showSqFeet ? 'selected w-12' : 'unselected w-6'
                )}
              >
                <span
                  className={cx(
                    'screen-text text-left rounded-full ',
                    !showSqFeet
                      ? 'selected pr-1 visible'
                      : `unselected justify-end w-6 h-6 ${
                          toggleSwitchColour ? '' : 'bg-primary'
                        }`
                  )}
                  style={{
                    backgroundColor: `${
                      toggleSwitchColour && showSqFeet ? toggleSwitchColour : ''
                    }`,
                    color: inlineStyles?.mtgChartMeasureTypeColour,
                  }}
                >
                  {t('meetingCapacity.sqMeter')}
                </span>
                <span className="sr-only">
                  {t('meetingCapacityCharts.squareMeters')}
                </span>
              </span>
            </button>
            <label
              htmlFor="unitType"
              className="switch text-xs mr-2"
              style={{ color: inlineStyles?.mtgChartUnitTypeLabelColour }}
            >
              {t('meetingCapacityCharts.unitLabel')}{' '}
              <span className="sr-only">
                {t('meetingCapacityCharts.typeChoice')}
              </span>
            </label>
          </div>
        </div>
        <div className={cx('mt-6 sm:flex sm:justify-center', containerClasses)}>
          <div className="overflow-x-auto lg:w-full">
            <table
              className="text-xs sm:text-base lg:w-full"
              tabIndex={1}
              data-testid="meeting-capacity-charts-table"
            >
              <thead>
                <tr
                  className="bg-text-alt text-text-inverse"
                  style={{
                    backgroundColor: inlineStyles?.mtgChartTableHeadingBgColour,
                    color: inlineStyles?.mtgChartTableHeadingTextColour,
                  }}
                >
                  <th className={cx('text-left py-6 px-3', leftColWidth)}>
                    {t('meetingCapacityCharts.eventRoomNameLabel')}
                  </th>
                  {categorySlugs?.map((i, e) => {
                    return (
                      <th
                        className="text-center py-6 px-4"
                        key={`th${e}`}
                        scope="col"
                      >
                        {t(`meetingCapacityCharts.${i}`)}
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                {meetings?.meetingRooms?.map((row, index) => {
                  const sizeValue =
                    showSqFeet === true && row?.sqrFootage
                      ? row?.sqrFootage
                      : sqFeetToSqMeter(row?.sqrFootage);
                  const measurementUnits =
                    showSqFeet === true
                      ? `${t('meetingCapacityCharts.squareFeet')}`
                      : `  ${t('meetingCapacityCharts.squareMeters')}`;

                  // Determine alternating row styling
                  const rowBgColor =
                    index % 2 === 0
                      ? inlineStyles?.mtgChartTableBodyRowBgColour
                      : inlineStyles?.mtgChartTableBodyAltRowBgColour;
                  const rowTextColor =
                    index % 2 === 0
                      ? inlineStyles?.mtgChartTableBodyRowTextColour
                      : inlineStyles?.mtgChartTableBodyAltRowTextColour;

                  return (
                    <tr
                      key={`rowItem${index}`}
                      className={index % 2 === 0 ? 'bg-bg' : 'bg-bg-alt'}
                      style={{
                        backgroundColor: rowBgColor,
                        color: rowTextColor,
                      }}
                    >
                      <th
                        className={cx('p-3 text-left', leftColWidth)}
                        scope="row"
                      >
                        <span className="OneLinkNoTx">{row?.name_noTx}</span>
                        {sizeValue !== null && sizeValue > 0 && (
                          <span
                            className="block text-sm font-semibold italic text-text-alt"
                            style={{
                              color:
                                index % 2 === 0
                                  ? inlineStyles?.mtgChartTableBodyRowTextColourTwo
                                  : inlineStyles?.mtgChartTableBodyAltRowTextColourTwo,
                            }}
                          >
                            {`${numberFormatter(
                              sizeValue
                            )} ${measurementUnits}`}
                          </span>
                        )}
                      </th>
                      <td className={tdClass}>
                        {row?.configurationCapacities?.banquet || notAvailable}
                      </td>
                      <td className={tdClass}>
                        {row?.configurationCapacities?.conference ||
                          notAvailable}
                      </td>
                      <td className={tdClass}>
                        {row?.configurationCapacities?.hollowSquare ||
                          notAvailable}
                      </td>
                      <td className={tdClass}>
                        {row?.configurationCapacities?.reception ||
                          notAvailable}
                      </td>
                      <td className={tdClass}>
                        {row?.configurationCapacities?.classroom ||
                          notAvailable}
                      </td>
                      <td className={tdClass}>
                        {row?.configurationCapacities?.theater || notAvailable}
                      </td>
                      <td className={tdClass}>
                        {row?.configurationCapacities?.uShaped || notAvailable}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
