export type Maybe<T> = T | undefined;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum ActivitiesIdType {
  /** Identify a resource by the Database ID. */
  DatabaseId = 'DATABASE_ID',
  /** Identify a resource by the (hashed) Global ID. */
  Id = 'ID',
  /** Identify a resource by the URI. */
  Uri = 'URI',
}

/** Arguments for filtering the activitiesToRevisionConnection connection */
export type ActivitiesToRevisionConnectionWhereArgs = {
  /** Filter the connection based on dates */
  dateQuery?: Maybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: Maybe<Scalars['Boolean']>;
  /** Specific ID of the object */
  id?: Maybe<Scalars['Int']>;
  /** Array of IDs for the objects to retrieve */
  in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: Maybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: Maybe<Scalars['String']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** What paramater to use to order the objects by. */
  orderby?: Maybe<Array<Maybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: Maybe<Scalars['ID']>;
  /** Specify objects whose parent is in an array */
  parentIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Show posts with a specific password. */
  password?: Maybe<Scalars['String']>;
  /** Show Posts based on a keyword search */
  search?: Maybe<Scalars['String']>;
  /** Retrieve posts where post status is in an array. */
  stati?: Maybe<Array<Maybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: Maybe<PostStatusEnum>;
  /** Title of the object */
  title?: Maybe<Scalars['String']>;
};

/**
 * What rating to display avatars up to. Accepts 'G', 'PG', 'R', 'X', and are
 * judged in that order. Default is the value of the 'avatar_rating' option
 */
export enum AvatarRatingEnum {
  /** Indicates a G level avatar rating level. */
  G = 'G',
  /** Indicates a PG level avatar rating level. */
  Pg = 'PG',
  /** Indicates an R level avatar rating level. */
  R = 'R',
  /** Indicates an X level avatar rating level. */
  X = 'X',
}

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum CalendarIdType {
  /** Identify a resource by the Database ID. */
  DatabaseId = 'DATABASE_ID',
  /** Identify a resource by the (hashed) Global ID. */
  Id = 'ID',
  /** Identify a resource by the slug. Available to non-hierarchcial Types where the slug is a unique identifier. */
  Slug = 'SLUG',
  /** Identify a resource by the URI. */
  Uri = 'URI',
}

/** Arguments for filtering the calendarToRevisionConnection connection */
export type CalendarToRevisionConnectionWhereArgs = {
  /** Filter the connection based on dates */
  dateQuery?: Maybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: Maybe<Scalars['Boolean']>;
  /** Specific ID of the object */
  id?: Maybe<Scalars['Int']>;
  /** Array of IDs for the objects to retrieve */
  in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: Maybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: Maybe<Scalars['String']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** What paramater to use to order the objects by. */
  orderby?: Maybe<Array<Maybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: Maybe<Scalars['ID']>;
  /** Specify objects whose parent is in an array */
  parentIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Show posts with a specific password. */
  password?: Maybe<Scalars['String']>;
  /** Show Posts based on a keyword search */
  search?: Maybe<Scalars['String']>;
  /** Retrieve posts where post status is in an array. */
  stati?: Maybe<Array<Maybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: Maybe<PostStatusEnum>;
  /** Title of the object */
  title?: Maybe<Scalars['String']>;
};

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum CategoryIdType {
  /** The Database ID for the node */
  DatabaseId = 'DATABASE_ID',
  /** The hashed Global ID */
  Id = 'ID',
  /** The name of the node */
  Name = 'NAME',
  /** Url friendly name of the node */
  Slug = 'SLUG',
  /** The URI for the node */
  Uri = 'URI',
}

/** Arguments for filtering the CategoryToCategoryConnection connection */
export type CategoryToCategoryConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: Maybe<Scalars['String']>;
  /**
   * True to limit results to terms that have no children. This parameter has no
   * effect on non-hierarchical taxonomies. Default false.
   */
  childless?: Maybe<Scalars['Boolean']>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: Maybe<Scalars['Int']>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: Maybe<Scalars['String']>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /**
   * Array of term ids to exclude along with all of their descendant terms. If
   * $include is non-empty, $exclude_tree is ignored. Default empty array.
   */
  excludeTree?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: Maybe<Scalars['Boolean']>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: Maybe<Scalars['Boolean']>;
  /** Array of term ids to include. Default empty array. */
  include?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Array of names to return term(s) for. Default empty. */
  name?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: Maybe<Scalars['String']>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Direction the connection should be ordered in */
  order?: Maybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: Maybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: Maybe<Scalars['Boolean']>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: Maybe<Scalars['Int']>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: Maybe<Scalars['String']>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: Maybe<Scalars['Boolean']>;
};

/** Arguments for filtering the CategoryToContentNodeConnection connection */
export type CategoryToContentNodeConnectionWhereArgs = {
  /** The Types of content to filter */
  contentTypes?: Maybe<Array<Maybe<ContentTypesOfCategoryEnum>>>;
  /** Filter the connection based on dates */
  dateQuery?: Maybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: Maybe<Scalars['Boolean']>;
  /** Specific ID of the object */
  id?: Maybe<Scalars['Int']>;
  /** Array of IDs for the objects to retrieve */
  in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: Maybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: Maybe<Scalars['String']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** What paramater to use to order the objects by. */
  orderby?: Maybe<Array<Maybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: Maybe<Scalars['ID']>;
  /** Specify objects whose parent is in an array */
  parentIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Show posts with a specific password. */
  password?: Maybe<Scalars['String']>;
  /** Show Posts based on a keyword search */
  search?: Maybe<Scalars['String']>;
  /** Retrieve posts where post status is in an array. */
  stati?: Maybe<Array<Maybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: Maybe<PostStatusEnum>;
  /** Title of the object */
  title?: Maybe<Scalars['String']>;
};

/** Arguments for filtering the CategoryToPostConnection connection */
export type CategoryToPostConnectionWhereArgs = {
  /** The user that's connected as the author of the object. Use the userId for the author object. */
  author?: Maybe<Scalars['Int']>;
  /** Find objects connected to author(s) in the array of author's userIds */
  authorIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Find objects connected to the author by the author's nicename */
  authorName?: Maybe<Scalars['String']>;
  /** Find objects NOT connected to author(s) in the array of author's userIds */
  authorNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Category ID */
  categoryId?: Maybe<Scalars['Int']>;
  /** Array of category IDs, used to display objects from one category OR another */
  categoryIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Use Category Slug */
  categoryName?: Maybe<Scalars['String']>;
  /** Array of category IDs, used to display objects from one category OR another */
  categoryNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Filter the connection based on dates */
  dateQuery?: Maybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: Maybe<Scalars['Boolean']>;
  /** Specific ID of the object */
  id?: Maybe<Scalars['Int']>;
  /** Array of IDs for the objects to retrieve */
  in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: Maybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: Maybe<Scalars['String']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** What paramater to use to order the objects by. */
  orderby?: Maybe<Array<Maybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: Maybe<Scalars['ID']>;
  /** Specify objects whose parent is in an array */
  parentIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Show posts with a specific password. */
  password?: Maybe<Scalars['String']>;
  /** Show Posts based on a keyword search */
  search?: Maybe<Scalars['String']>;
  /** Retrieve posts where post status is in an array. */
  stati?: Maybe<Array<Maybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: Maybe<PostStatusEnum>;
  /** Tag Slug */
  tag?: Maybe<Scalars['String']>;
  /** Use Tag ID */
  tagId?: Maybe<Scalars['String']>;
  /** Array of tag IDs, used to display objects from one tag OR another */
  tagIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Array of tag IDs, used to display objects from one tag OR another */
  tagNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Array of tag slugs, used to display objects from one tag OR another */
  tagSlugAnd?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Array of tag slugs, used to exclude objects in specified tags */
  tagSlugIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Title of the object */
  title?: Maybe<Scalars['String']>;
};

/** Options for ordering the connection */
export enum CommentsConnectionOrderbyEnum {
  /** Order by browser user agent of the commenter. */
  CommentAgent = 'COMMENT_AGENT',
  /** Order by true/false approval of the comment. */
  CommentApproved = 'COMMENT_APPROVED',
  /** Order by name of the comment author. */
  CommentAuthor = 'COMMENT_AUTHOR',
  /** Order by e-mail of the comment author. */
  CommentAuthorEmail = 'COMMENT_AUTHOR_EMAIL',
  /** Order by IP address of the comment author. */
  CommentAuthorIp = 'COMMENT_AUTHOR_IP',
  /** Order by URL address of the comment author. */
  CommentAuthorUrl = 'COMMENT_AUTHOR_URL',
  /** Order by the comment contents. */
  CommentContent = 'COMMENT_CONTENT',
  /** Order by date/time timestamp of the comment. */
  CommentDate = 'COMMENT_DATE',
  /** Order by GMT timezone date/time timestamp of the comment. */
  CommentDateGmt = 'COMMENT_DATE_GMT',
  /** Order by the globally unique identifier for the comment object */
  CommentId = 'COMMENT_ID',
  /** Order by the array list of comment IDs listed in the where clause. */
  CommentIn = 'COMMENT_IN',
  /** Order by the comment karma score. */
  CommentKarma = 'COMMENT_KARMA',
  /** Order by the comment parent ID. */
  CommentParent = 'COMMENT_PARENT',
  /** Order by the post object ID. */
  CommentPostId = 'COMMENT_POST_ID',
  /** Order by the the type of comment, such as 'comment', 'pingback', or 'trackback'. */
  CommentType = 'COMMENT_TYPE',
  /** Order by the user ID. */
  UserId = 'USER_ID',
}

/** Arguments for filtering the CommentToCommentConnection connection */
export type CommentToCommentConnectionWhereArgs = {
  /** Comment author email address. */
  authorEmail?: Maybe<Scalars['String']>;
  /** Array of author IDs to include comments for. */
  authorIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Array of author IDs to exclude comments for. */
  authorNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Comment author URL. */
  authorUrl?: Maybe<Scalars['String']>;
  /** Array of comment IDs to include. */
  commentIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Array of IDs of users whose unapproved comments will be returned by the query regardless of status. */
  commentNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Include comments of a given type. */
  commentType?: Maybe<Scalars['String']>;
  /** Include comments from a given array of comment types. */
  commentTypeIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Exclude comments from a given array of comment types. */
  commentTypeNotIn?: Maybe<Scalars['String']>;
  /** Content object author ID to limit results by. */
  contentAuthor?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Array of author IDs to retrieve comments for. */
  contentAuthorIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Array of author IDs *not* to retrieve comments for. */
  contentAuthorNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Limit results to those affiliated with a given content object ID. */
  contentId?: Maybe<Scalars['ID']>;
  /** Array of content object IDs to include affiliated comments for. */
  contentIdIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Array of content object IDs to exclude affiliated comments for. */
  contentIdNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Content object name to retrieve affiliated comments for. */
  contentName?: Maybe<Scalars['String']>;
  /** Content Object parent ID to retrieve affiliated comments for. */
  contentParent?: Maybe<Scalars['Int']>;
  /** Array of content object statuses to retrieve affiliated comments for. Pass 'any' to match any value. */
  contentStatus?: Maybe<Array<Maybe<PostStatusEnum>>>;
  /** Content object type or array of types to retrieve affiliated comments for. Pass 'any' to match any value. */
  contentType?: Maybe<Array<Maybe<ContentTypeEnum>>>;
  /**
   * Array of IDs or email addresses of users whose unapproved comments will be
   * returned by the query regardless of $status. Default empty
   */
  includeUnapproved?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Karma score to retrieve matching comments for. */
  karma?: Maybe<Scalars['Int']>;
  /** The cardinality of the order of the connection */
  order?: Maybe<OrderEnum>;
  /** Field to order the comments by. */
  orderby?: Maybe<CommentsConnectionOrderbyEnum>;
  /** Parent ID of comment to retrieve children of. */
  parent?: Maybe<Scalars['Int']>;
  /** Array of parent IDs of comments to retrieve children for. */
  parentIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Array of parent IDs of comments *not* to retrieve children for. */
  parentNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Search term(s) to retrieve matching comments for. */
  search?: Maybe<Scalars['String']>;
  /** Comment status to limit results by. */
  status?: Maybe<Scalars['String']>;
  /** Include comments for a specific user ID. */
  userId?: Maybe<Scalars['ID']>;
};

/** Arguments for filtering the CommentToParentCommentConnection connection */
export type CommentToParentCommentConnectionWhereArgs = {
  /** Comment author email address. */
  authorEmail?: Maybe<Scalars['String']>;
  /** Array of author IDs to include comments for. */
  authorIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Array of author IDs to exclude comments for. */
  authorNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Comment author URL. */
  authorUrl?: Maybe<Scalars['String']>;
  /** Array of comment IDs to include. */
  commentIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Array of IDs of users whose unapproved comments will be returned by the query regardless of status. */
  commentNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Include comments of a given type. */
  commentType?: Maybe<Scalars['String']>;
  /** Include comments from a given array of comment types. */
  commentTypeIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Exclude comments from a given array of comment types. */
  commentTypeNotIn?: Maybe<Scalars['String']>;
  /** Content object author ID to limit results by. */
  contentAuthor?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Array of author IDs to retrieve comments for. */
  contentAuthorIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Array of author IDs *not* to retrieve comments for. */
  contentAuthorNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Limit results to those affiliated with a given content object ID. */
  contentId?: Maybe<Scalars['ID']>;
  /** Array of content object IDs to include affiliated comments for. */
  contentIdIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Array of content object IDs to exclude affiliated comments for. */
  contentIdNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Content object name to retrieve affiliated comments for. */
  contentName?: Maybe<Scalars['String']>;
  /** Content Object parent ID to retrieve affiliated comments for. */
  contentParent?: Maybe<Scalars['Int']>;
  /** Array of content object statuses to retrieve affiliated comments for. Pass 'any' to match any value. */
  contentStatus?: Maybe<Array<Maybe<PostStatusEnum>>>;
  /** Content object type or array of types to retrieve affiliated comments for. Pass 'any' to match any value. */
  contentType?: Maybe<Array<Maybe<ContentTypeEnum>>>;
  /**
   * Array of IDs or email addresses of users whose unapproved comments will be
   * returned by the query regardless of $status. Default empty
   */
  includeUnapproved?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Karma score to retrieve matching comments for. */
  karma?: Maybe<Scalars['Int']>;
  /** The cardinality of the order of the connection */
  order?: Maybe<OrderEnum>;
  /** Field to order the comments by. */
  orderby?: Maybe<CommentsConnectionOrderbyEnum>;
  /** Parent ID of comment to retrieve children of. */
  parent?: Maybe<Scalars['Int']>;
  /** Array of parent IDs of comments to retrieve children for. */
  parentIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Array of parent IDs of comments *not* to retrieve children for. */
  parentNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Search term(s) to retrieve matching comments for. */
  search?: Maybe<Scalars['String']>;
  /** Comment status to limit results by. */
  status?: Maybe<Scalars['String']>;
  /** Include comments for a specific user ID. */
  userId?: Maybe<Scalars['ID']>;
};

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum ContentNodeIdTypeEnum {
  /** Identify a resource by the Database ID. */
  DatabaseId = 'DATABASE_ID',
  /** Identify a resource by the (hashed) Global ID. */
  Id = 'ID',
  /** Identify a resource by the URI. */
  Uri = 'URI',
}

/** Allowed Content Types */
export enum ContentTypeEnum {
  /** The Type of Content object */
  Activities = 'ACTIVITIES',
  /** The Type of Content object */
  Attachment = 'ATTACHMENT',
  /** The Type of Content object */
  Calendar = 'CALENDAR',
  /** The Type of Content object */
  HtmlMenu = 'HTML_MENU',
  /** The Type of Content object */
  MediaBlocks = 'MEDIA_BLOCKS',
  /** The Type of Content object */
  Page = 'PAGE',
  /** The Type of Content object */
  Post = 'POST',
  /** The Type of Content object */
  Recurringevents = 'RECURRINGEVENTS',
  /** The Type of Content object */
  Rooms = 'ROOMS',
  /** The Type of Content object */
  Specialoffers = 'SPECIALOFFERS',
  /** The Type of Content object */
  Weddingbookings = 'WEDDINGBOOKINGS',
}

/** The Type of Identifier used to fetch a single Content Type node. To be used along with the "id" field. Default is "ID". */
export enum ContentTypeIdTypeEnum {
  /** The globally unique ID */
  Id = 'ID',
  /** The name of the content type. */
  Name = 'NAME',
}

/** Allowed Content Types of the Category taxonomy. */
export enum ContentTypesOfCategoryEnum {
  /** The Type of Content object */
  Post = 'POST',
}

/** Allowed Content Types of the PostFormat taxonomy. */
export enum ContentTypesOfPostFormatEnum {
  /** The Type of Content object */
  Post = 'POST',
}

/** Allowed Content Types of the Tag taxonomy. */
export enum ContentTypesOfTagEnum {
  /** The Type of Content object */
  Post = 'POST',
  /** The Type of Content object */
  Specialoffers = 'SPECIALOFFERS',
}

/** Arguments for filtering the ContentTypeToContentNodeConnection connection */
export type ContentTypeToContentNodeConnectionWhereArgs = {
  /** The Types of content to filter */
  contentTypes?: Maybe<Array<Maybe<ContentTypeEnum>>>;
  /** Filter the connection based on dates */
  dateQuery?: Maybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: Maybe<Scalars['Boolean']>;
  /** Specific ID of the object */
  id?: Maybe<Scalars['Int']>;
  /** Array of IDs for the objects to retrieve */
  in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: Maybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: Maybe<Scalars['String']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** What paramater to use to order the objects by. */
  orderby?: Maybe<Array<Maybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: Maybe<Scalars['ID']>;
  /** Specify objects whose parent is in an array */
  parentIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Show posts with a specific password. */
  password?: Maybe<Scalars['String']>;
  /** Show Posts based on a keyword search */
  search?: Maybe<Scalars['String']>;
  /** Retrieve posts where post status is in an array. */
  stati?: Maybe<Array<Maybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: Maybe<PostStatusEnum>;
  /** Title of the object */
  title?: Maybe<Scalars['String']>;
};

/** Input for the createActivities mutation */
export type CreateActivitiesInput = {
  /**
   * This is an ID that can be passed to a mutation by the client to track the
   * progress of mutations and catch possible duplicate mutation submissions.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The content of the object */
  content?: Maybe<Scalars['String']>;
  /**
   * The date of the object. Preferable to enter as year/month/day (e.g.
   * 01/31/2017) as it will rearrange date as fit if it is not specified.
   * Incomplete dates may have unintended results for example, "2017" as the input
   * will use current date with timestamp 20:17
   */
  date?: Maybe<Scalars['String']>;
  /**
   * A field used for ordering posts. This is typically used with nav menu items or
   * for special ordering of hierarchical content types.
   */
  menuOrder?: Maybe<Scalars['Int']>;
  /** The ID of the parent object */
  parentId?: Maybe<Scalars['ID']>;
  /** The password used to protect the content of the object */
  password?: Maybe<Scalars['String']>;
  /** The slug of the object */
  slug?: Maybe<Scalars['String']>;
  /** The status of the object */
  status?: Maybe<PostStatusEnum>;
  /** The title of the object */
  title?: Maybe<Scalars['String']>;
};

/** Input for the createCalendar mutation */
export type CreateCalendarInput = {
  /**
   * This is an ID that can be passed to a mutation by the client to track the
   * progress of mutations and catch possible duplicate mutation submissions.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The content of the object */
  content?: Maybe<Scalars['String']>;
  /**
   * The date of the object. Preferable to enter as year/month/day (e.g.
   * 01/31/2017) as it will rearrange date as fit if it is not specified.
   * Incomplete dates may have unintended results for example, "2017" as the input
   * will use current date with timestamp 20:17
   */
  date?: Maybe<Scalars['String']>;
  /**
   * A field used for ordering posts. This is typically used with nav menu items or
   * for special ordering of hierarchical content types.
   */
  menuOrder?: Maybe<Scalars['Int']>;
  /** The password used to protect the content of the object */
  password?: Maybe<Scalars['String']>;
  /** The slug of the object */
  slug?: Maybe<Scalars['String']>;
  /** The status of the object */
  status?: Maybe<PostStatusEnum>;
  /** The title of the object */
  title?: Maybe<Scalars['String']>;
};

/** Input for the createCategory mutation */
export type CreateCategoryInput = {
  /** The slug that the category will be an alias of */
  aliasOf?: Maybe<Scalars['String']>;
  /**
   * This is an ID that can be passed to a mutation by the client to track the
   * progress of mutations and catch possible duplicate mutation submissions.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The description of the category object */
  description?: Maybe<Scalars['String']>;
  /** The name of the category object to mutate */
  name: Scalars['String'];
  /** The ID of the category that should be set as the parent */
  parentId?: Maybe<Scalars['ID']>;
  /**
   * If this argument exists then the slug will be checked to see if it is not an
   * existing valid term. If that check succeeds (it is not a valid term), then it
   * is added and the term id is given. If it fails, then a check is made to
   * whether the taxonomy is hierarchical and the parent argument is not empty. If
   * the second check succeeds, the term will be inserted and the term id will be
   * given. If the slug argument is empty, then it will be calculated from the term name.
   */
  slug?: Maybe<Scalars['String']>;
};

/** Input for the createComment mutation */
export type CreateCommentInput = {
  /** The approval status of the comment. */
  approved?: Maybe<Scalars['String']>;
  /** The name of the comment's author. */
  author?: Maybe<Scalars['String']>;
  /** The email of the comment's author. */
  authorEmail?: Maybe<Scalars['String']>;
  /** The url of the comment's author. */
  authorUrl?: Maybe<Scalars['String']>;
  /**
   * This is an ID that can be passed to a mutation by the client to track the
   * progress of mutations and catch possible duplicate mutation submissions.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The ID of the post object the comment belongs to. */
  commentOn?: Maybe<Scalars['Int']>;
  /** Content of the comment. */
  content?: Maybe<Scalars['String']>;
  /**
   * The date of the object. Preferable to enter as year/month/day ( e.g.
   * 01/31/2017 ) as it will rearrange date as fit if it is not specified.
   * Incomplete dates may have unintended results for example, "2017" as the input
   * will use current date with timestamp 20:17
   */
  date?: Maybe<Scalars['String']>;
  /** Parent comment of current comment. */
  parent?: Maybe<Scalars['ID']>;
  /** Type of comment. */
  type?: Maybe<Scalars['String']>;
};

/** Input for the createHtml_menu mutation */
export type CreateHtml_MenuInput = {
  /**
   * This is an ID that can be passed to a mutation by the client to track the
   * progress of mutations and catch possible duplicate mutation submissions.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The content of the object */
  content?: Maybe<Scalars['String']>;
  /**
   * The date of the object. Preferable to enter as year/month/day (e.g.
   * 01/31/2017) as it will rearrange date as fit if it is not specified.
   * Incomplete dates may have unintended results for example, "2017" as the input
   * will use current date with timestamp 20:17
   */
  date?: Maybe<Scalars['String']>;
  /**
   * A field used for ordering posts. This is typically used with nav menu items or
   * for special ordering of hierarchical content types.
   */
  menuOrder?: Maybe<Scalars['Int']>;
  /** The password used to protect the content of the object */
  password?: Maybe<Scalars['String']>;
  /** The slug of the object */
  slug?: Maybe<Scalars['String']>;
  /** The status of the object */
  status?: Maybe<PostStatusEnum>;
  /** The title of the object */
  title?: Maybe<Scalars['String']>;
};

/** Input for the createMedia_block mutation */
export type CreateMedia_BlockInput = {
  /**
   * This is an ID that can be passed to a mutation by the client to track the
   * progress of mutations and catch possible duplicate mutation submissions.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The content of the object */
  content?: Maybe<Scalars['String']>;
  /**
   * The date of the object. Preferable to enter as year/month/day (e.g.
   * 01/31/2017) as it will rearrange date as fit if it is not specified.
   * Incomplete dates may have unintended results for example, "2017" as the input
   * will use current date with timestamp 20:17
   */
  date?: Maybe<Scalars['String']>;
  /**
   * A field used for ordering posts. This is typically used with nav menu items or
   * for special ordering of hierarchical content types.
   */
  menuOrder?: Maybe<Scalars['Int']>;
  /** The password used to protect the content of the object */
  password?: Maybe<Scalars['String']>;
  /** The slug of the object */
  slug?: Maybe<Scalars['String']>;
  /** The status of the object */
  status?: Maybe<PostStatusEnum>;
  /** The title of the object */
  title?: Maybe<Scalars['String']>;
};

/** Input for the createMediaItem mutation */
export type CreateMediaItemInput = {
  /** Alternative text to display when mediaItem is not displayed */
  altText?: Maybe<Scalars['String']>;
  /** The userId to assign as the author of the mediaItem */
  authorId?: Maybe<Scalars['ID']>;
  /** The caption for the mediaItem */
  caption?: Maybe<Scalars['String']>;
  /**
   * This is an ID that can be passed to a mutation by the client to track the
   * progress of mutations and catch possible duplicate mutation submissions.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The comment status for the mediaItem */
  commentStatus?: Maybe<Scalars['String']>;
  /** The date of the mediaItem */
  date?: Maybe<Scalars['String']>;
  /** The date (in GMT zone) of the mediaItem */
  dateGmt?: Maybe<Scalars['String']>;
  /** Description of the mediaItem */
  description?: Maybe<Scalars['String']>;
  /** The file name of the mediaItem */
  filePath?: Maybe<Scalars['String']>;
  /** The file type of the mediaItem */
  fileType?: Maybe<MimeTypeEnum>;
  /** The WordPress post ID or the graphQL postId of the parent object */
  parentId?: Maybe<Scalars['ID']>;
  /** The ping status for the mediaItem */
  pingStatus?: Maybe<Scalars['String']>;
  /** The slug of the mediaItem */
  slug?: Maybe<Scalars['String']>;
  /** The status of the mediaItem */
  status?: Maybe<MediaItemStatusEnum>;
  /** The title of the mediaItem */
  title?: Maybe<Scalars['String']>;
};

/** Input for the createPage mutation */
export type CreatePageInput = {
  /** The userId to assign as the author of the object */
  authorId?: Maybe<Scalars['ID']>;
  /**
   * This is an ID that can be passed to a mutation by the client to track the
   * progress of mutations and catch possible duplicate mutation submissions.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The comment status for the object */
  commentStatus?: Maybe<Scalars['String']>;
  /** The content of the object */
  content?: Maybe<Scalars['String']>;
  /**
   * The date of the object. Preferable to enter as year/month/day (e.g.
   * 01/31/2017) as it will rearrange date as fit if it is not specified.
   * Incomplete dates may have unintended results for example, "2017" as the input
   * will use current date with timestamp 20:17
   */
  date?: Maybe<Scalars['String']>;
  /**
   * A field used for ordering posts. This is typically used with nav menu items or
   * for special ordering of hierarchical content types.
   */
  menuOrder?: Maybe<Scalars['Int']>;
  /** The ID of the parent object */
  parentId?: Maybe<Scalars['ID']>;
  /** The password used to protect the content of the object */
  password?: Maybe<Scalars['String']>;
  /** The slug of the object */
  slug?: Maybe<Scalars['String']>;
  /** The status of the object */
  status?: Maybe<PostStatusEnum>;
  /** The title of the object */
  title?: Maybe<Scalars['String']>;
};

/** Input for the createPostFormat mutation */
export type CreatePostFormatInput = {
  /** The slug that the post_format will be an alias of */
  aliasOf?: Maybe<Scalars['String']>;
  /**
   * This is an ID that can be passed to a mutation by the client to track the
   * progress of mutations and catch possible duplicate mutation submissions.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The description of the post_format object */
  description?: Maybe<Scalars['String']>;
  /** The name of the post_format object to mutate */
  name: Scalars['String'];
  /**
   * If this argument exists then the slug will be checked to see if it is not an
   * existing valid term. If that check succeeds (it is not a valid term), then it
   * is added and the term id is given. If it fails, then a check is made to
   * whether the taxonomy is hierarchical and the parent argument is not empty. If
   * the second check succeeds, the term will be inserted and the term id will be
   * given. If the slug argument is empty, then it will be calculated from the term name.
   */
  slug?: Maybe<Scalars['String']>;
};

/** Input for the createPost mutation */
export type CreatePostInput = {
  /** The userId to assign as the author of the object */
  authorId?: Maybe<Scalars['ID']>;
  /** Set connections between the post and categories */
  categories?: Maybe<PostCategoriesInput>;
  /**
   * This is an ID that can be passed to a mutation by the client to track the
   * progress of mutations and catch possible duplicate mutation submissions.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The comment status for the object */
  commentStatus?: Maybe<Scalars['String']>;
  /** The content of the object */
  content?: Maybe<Scalars['String']>;
  /**
   * The date of the object. Preferable to enter as year/month/day (e.g.
   * 01/31/2017) as it will rearrange date as fit if it is not specified.
   * Incomplete dates may have unintended results for example, "2017" as the input
   * will use current date with timestamp 20:17
   */
  date?: Maybe<Scalars['String']>;
  /** The excerpt of the object */
  excerpt?: Maybe<Scalars['String']>;
  /**
   * A field used for ordering posts. This is typically used with nav menu items or
   * for special ordering of hierarchical content types.
   */
  menuOrder?: Maybe<Scalars['Int']>;
  /** The password used to protect the content of the object */
  password?: Maybe<Scalars['String']>;
  /** URLs that have been pinged. */
  pinged?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** The ping status for the object */
  pingStatus?: Maybe<Scalars['String']>;
  /** Set connections between the post and postFormats */
  postFormats?: Maybe<PostPostFormatsInput>;
  /** The slug of the object */
  slug?: Maybe<Scalars['String']>;
  /** The status of the object */
  status?: Maybe<PostStatusEnum>;
  /** Set connections between the post and tags */
  tags?: Maybe<PostTagsInput>;
  /** The title of the object */
  title?: Maybe<Scalars['String']>;
  /** URLs queued to be pinged. */
  toPing?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** Input for the createRecurringevent mutation */
export type CreateRecurringeventInput = {
  /**
   * This is an ID that can be passed to a mutation by the client to track the
   * progress of mutations and catch possible duplicate mutation submissions.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The content of the object */
  content?: Maybe<Scalars['String']>;
  /**
   * The date of the object. Preferable to enter as year/month/day (e.g.
   * 01/31/2017) as it will rearrange date as fit if it is not specified.
   * Incomplete dates may have unintended results for example, "2017" as the input
   * will use current date with timestamp 20:17
   */
  date?: Maybe<Scalars['String']>;
  /**
   * A field used for ordering posts. This is typically used with nav menu items or
   * for special ordering of hierarchical content types.
   */
  menuOrder?: Maybe<Scalars['Int']>;
  /** The password used to protect the content of the object */
  password?: Maybe<Scalars['String']>;
  /** The slug of the object */
  slug?: Maybe<Scalars['String']>;
  /** The status of the object */
  status?: Maybe<PostStatusEnum>;
  /** The title of the object */
  title?: Maybe<Scalars['String']>;
};

/** Input for the createRoom mutation */
export type CreateRoomInput = {
  /**
   * This is an ID that can be passed to a mutation by the client to track the
   * progress of mutations and catch possible duplicate mutation submissions.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The content of the object */
  content?: Maybe<Scalars['String']>;
  /**
   * The date of the object. Preferable to enter as year/month/day (e.g.
   * 01/31/2017) as it will rearrange date as fit if it is not specified.
   * Incomplete dates may have unintended results for example, "2017" as the input
   * will use current date with timestamp 20:17
   */
  date?: Maybe<Scalars['String']>;
  /**
   * A field used for ordering posts. This is typically used with nav menu items or
   * for special ordering of hierarchical content types.
   */
  menuOrder?: Maybe<Scalars['Int']>;
  /** The ID of the parent object */
  parentId?: Maybe<Scalars['ID']>;
  /** The password used to protect the content of the object */
  password?: Maybe<Scalars['String']>;
  /** The slug of the object */
  slug?: Maybe<Scalars['String']>;
  /** The status of the object */
  status?: Maybe<PostStatusEnum>;
  /** The title of the object */
  title?: Maybe<Scalars['String']>;
};

/** Input for the createSpecialoffer mutation */
export type CreateSpecialofferInput = {
  /**
   * This is an ID that can be passed to a mutation by the client to track the
   * progress of mutations and catch possible duplicate mutation submissions.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The content of the object */
  content?: Maybe<Scalars['String']>;
  /**
   * The date of the object. Preferable to enter as year/month/day (e.g.
   * 01/31/2017) as it will rearrange date as fit if it is not specified.
   * Incomplete dates may have unintended results for example, "2017" as the input
   * will use current date with timestamp 20:17
   */
  date?: Maybe<Scalars['String']>;
  /**
   * A field used for ordering posts. This is typically used with nav menu items or
   * for special ordering of hierarchical content types.
   */
  menuOrder?: Maybe<Scalars['Int']>;
  /** The ID of the parent object */
  parentId?: Maybe<Scalars['ID']>;
  /** The password used to protect the content of the object */
  password?: Maybe<Scalars['String']>;
  /** The slug of the object */
  slug?: Maybe<Scalars['String']>;
  /** The status of the object */
  status?: Maybe<PostStatusEnum>;
  /** Set connections between the specialoffer and tags */
  tags?: Maybe<SpecialofferTagsInput>;
  /** The title of the object */
  title?: Maybe<Scalars['String']>;
};

/** Input for the createTag mutation */
export type CreateTagInput = {
  /** The slug that the post_tag will be an alias of */
  aliasOf?: Maybe<Scalars['String']>;
  /**
   * This is an ID that can be passed to a mutation by the client to track the
   * progress of mutations and catch possible duplicate mutation submissions.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The description of the post_tag object */
  description?: Maybe<Scalars['String']>;
  /** The name of the post_tag object to mutate */
  name: Scalars['String'];
  /**
   * If this argument exists then the slug will be checked to see if it is not an
   * existing valid term. If that check succeeds (it is not a valid term), then it
   * is added and the term id is given. If it fails, then a check is made to
   * whether the taxonomy is hierarchical and the parent argument is not empty. If
   * the second check succeeds, the term will be inserted and the term id will be
   * given. If the slug argument is empty, then it will be calculated from the term name.
   */
  slug?: Maybe<Scalars['String']>;
};

/** Input for the createUser mutation */
export type CreateUserInput = {
  /** User's AOL IM account. */
  aim?: Maybe<Scalars['String']>;
  /**
   * This is an ID that can be passed to a mutation by the client to track the
   * progress of mutations and catch possible duplicate mutation submissions.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** A string containing content about the user. */
  description?: Maybe<Scalars['String']>;
  /**
   * A string that will be shown on the site. Defaults to user's username. It is
   * likely that you will want to change this, for both appearance and security
   * through obscurity (that is if you dont use and delete the default admin user).
   */
  displayName?: Maybe<Scalars['String']>;
  /** A string containing the user's email address. */
  email?: Maybe<Scalars['String']>;
  /** 	The user's first name. */
  firstName?: Maybe<Scalars['String']>;
  /** User's Jabber account. */
  jabber?: Maybe<Scalars['String']>;
  /** The user's last name. */
  lastName?: Maybe<Scalars['String']>;
  /** User's locale. */
  locale?: Maybe<Scalars['String']>;
  /** A string that contains a URL-friendly name for the user. The default is the user's username. */
  nicename?: Maybe<Scalars['String']>;
  /** The user's nickname, defaults to the user's username. */
  nickname?: Maybe<Scalars['String']>;
  /** A string that contains the plain text password for the user. */
  password?: Maybe<Scalars['String']>;
  /** If true, this will refresh the users JWT secret. */
  refreshJwtUserSecret?: Maybe<Scalars['Boolean']>;
  /** The date the user registered. Format is Y-m-d H:i:s. */
  registered?: Maybe<Scalars['String']>;
  /**
   * If true, this will revoke the users JWT secret. If false, this will unrevoke
   * the JWT secret AND issue a new one. To revoke, the user must have proper
   * capabilities to edit users JWT secrets.
   */
  revokeJwtUserSecret?: Maybe<Scalars['Boolean']>;
  /** A string for whether to enable the rich editor or not. False if not empty. */
  richEditing?: Maybe<Scalars['String']>;
  /** An array of roles to be assigned to the user. */
  roles?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** A string that contains the user's username for logging in. */
  username: Scalars['String'];
  /** A string containing the user's URL for the user's web site. */
  websiteUrl?: Maybe<Scalars['String']>;
  /** User's Yahoo IM account. */
  yim?: Maybe<Scalars['String']>;
};

/** Input for the createWeddingbooking mutation */
export type CreateWeddingbookingInput = {
  /**
   * This is an ID that can be passed to a mutation by the client to track the
   * progress of mutations and catch possible duplicate mutation submissions.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The content of the object */
  content?: Maybe<Scalars['String']>;
  /**
   * The date of the object. Preferable to enter as year/month/day (e.g.
   * 01/31/2017) as it will rearrange date as fit if it is not specified.
   * Incomplete dates may have unintended results for example, "2017" as the input
   * will use current date with timestamp 20:17
   */
  date?: Maybe<Scalars['String']>;
  /**
   * A field used for ordering posts. This is typically used with nav menu items or
   * for special ordering of hierarchical content types.
   */
  menuOrder?: Maybe<Scalars['Int']>;
  /** The ID of the parent object */
  parentId?: Maybe<Scalars['ID']>;
  /** The password used to protect the content of the object */
  password?: Maybe<Scalars['String']>;
  /** The slug of the object */
  slug?: Maybe<Scalars['String']>;
  /** The status of the object */
  status?: Maybe<PostStatusEnum>;
  /** The title of the object */
  title?: Maybe<Scalars['String']>;
};

/** Date values */
export type DateInput = {
  /** Day of the month (from 1 to 31) */
  day?: Maybe<Scalars['Int']>;
  /** Month number (from 1 to 12) */
  month?: Maybe<Scalars['Int']>;
  /** 4 digit year (e.g. 2017) */
  year?: Maybe<Scalars['Int']>;
};

/** Filter the connection based on input */
export type DateQueryInput = {
  /** Nodes should be returned after this date */
  after?: Maybe<DateInput>;
  /** Nodes should be returned before this date */
  before?: Maybe<DateInput>;
  /** Column to query against */
  column?: Maybe<PostObjectsConnectionDateColumnEnum>;
  /** For after/before, whether exact value should be matched or not */
  compare?: Maybe<Scalars['String']>;
  /** Day of the month (from 1 to 31) */
  day?: Maybe<Scalars['Int']>;
  /** Hour (from 0 to 23) */
  hour?: Maybe<Scalars['Int']>;
  /** For after/before, whether exact value should be matched or not */
  inclusive?: Maybe<Scalars['Boolean']>;
  /** Minute (from 0 to 59) */
  minute?: Maybe<Scalars['Int']>;
  /** Month number (from 1 to 12) */
  month?: Maybe<Scalars['Int']>;
  /** OR or AND, how the sub-arrays should be compared */
  relation?: Maybe<RelationEnum>;
  /** Second (0 to 59) */
  second?: Maybe<Scalars['Int']>;
  /** Week of the year (from 0 to 53) */
  week?: Maybe<Scalars['Int']>;
  /** 4 digit year (e.g. 2017) */
  year?: Maybe<Scalars['Int']>;
};

/** Input for the deleteActivities mutation */
export type DeleteActivitiesInput = {
  /**
   * This is an ID that can be passed to a mutation by the client to track the
   * progress of mutations and catch possible duplicate mutation submissions.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Whether the object should be force deleted instead of being moved to the trash */
  forceDelete?: Maybe<Scalars['Boolean']>;
  /** The ID of the activities to delete */
  id: Scalars['ID'];
};

/** Input for the deleteCalendar mutation */
export type DeleteCalendarInput = {
  /**
   * This is an ID that can be passed to a mutation by the client to track the
   * progress of mutations and catch possible duplicate mutation submissions.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Whether the object should be force deleted instead of being moved to the trash */
  forceDelete?: Maybe<Scalars['Boolean']>;
  /** The ID of the calendar to delete */
  id: Scalars['ID'];
};

/** Input for the deleteCategory mutation */
export type DeleteCategoryInput = {
  /**
   * This is an ID that can be passed to a mutation by the client to track the
   * progress of mutations and catch possible duplicate mutation submissions.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The ID of the category to delete */
  id: Scalars['ID'];
};

/** Input for the deleteComment mutation */
export type DeleteCommentInput = {
  /**
   * This is an ID that can be passed to a mutation by the client to track the
   * progress of mutations and catch possible duplicate mutation submissions.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Whether the comment should be force deleted instead of being moved to the trash */
  forceDelete?: Maybe<Scalars['Boolean']>;
  /** The deleted comment ID */
  id: Scalars['ID'];
};

/** Input for the deleteHtml_menu mutation */
export type DeleteHtml_MenuInput = {
  /**
   * This is an ID that can be passed to a mutation by the client to track the
   * progress of mutations and catch possible duplicate mutation submissions.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Whether the object should be force deleted instead of being moved to the trash */
  forceDelete?: Maybe<Scalars['Boolean']>;
  /** The ID of the html_menu to delete */
  id: Scalars['ID'];
};

/** Input for the deleteMedia_block mutation */
export type DeleteMedia_BlockInput = {
  /**
   * This is an ID that can be passed to a mutation by the client to track the
   * progress of mutations and catch possible duplicate mutation submissions.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Whether the object should be force deleted instead of being moved to the trash */
  forceDelete?: Maybe<Scalars['Boolean']>;
  /** The ID of the media_block to delete */
  id: Scalars['ID'];
};

/** Input for the deleteMediaItem mutation */
export type DeleteMediaItemInput = {
  /**
   * This is an ID that can be passed to a mutation by the client to track the
   * progress of mutations and catch possible duplicate mutation submissions.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Whether the mediaItem should be force deleted instead of being moved to the trash */
  forceDelete?: Maybe<Scalars['Boolean']>;
  /** The ID of the mediaItem to delete */
  id: Scalars['ID'];
};

/** Input for the deletePage mutation */
export type DeletePageInput = {
  /**
   * This is an ID that can be passed to a mutation by the client to track the
   * progress of mutations and catch possible duplicate mutation submissions.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Whether the object should be force deleted instead of being moved to the trash */
  forceDelete?: Maybe<Scalars['Boolean']>;
  /** The ID of the page to delete */
  id: Scalars['ID'];
};

/** Input for the deletePostFormat mutation */
export type DeletePostFormatInput = {
  /**
   * This is an ID that can be passed to a mutation by the client to track the
   * progress of mutations and catch possible duplicate mutation submissions.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The ID of the postFormat to delete */
  id: Scalars['ID'];
};

/** Input for the deletePost mutation */
export type DeletePostInput = {
  /**
   * This is an ID that can be passed to a mutation by the client to track the
   * progress of mutations and catch possible duplicate mutation submissions.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Whether the object should be force deleted instead of being moved to the trash */
  forceDelete?: Maybe<Scalars['Boolean']>;
  /** The ID of the post to delete */
  id: Scalars['ID'];
};

/** Input for the deleteRecurringevent mutation */
export type DeleteRecurringeventInput = {
  /**
   * This is an ID that can be passed to a mutation by the client to track the
   * progress of mutations and catch possible duplicate mutation submissions.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Whether the object should be force deleted instead of being moved to the trash */
  forceDelete?: Maybe<Scalars['Boolean']>;
  /** The ID of the recurringevent to delete */
  id: Scalars['ID'];
};

/** Input for the deleteRoom mutation */
export type DeleteRoomInput = {
  /**
   * This is an ID that can be passed to a mutation by the client to track the
   * progress of mutations and catch possible duplicate mutation submissions.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Whether the object should be force deleted instead of being moved to the trash */
  forceDelete?: Maybe<Scalars['Boolean']>;
  /** The ID of the room to delete */
  id: Scalars['ID'];
};

/** Input for the deleteSpecialoffer mutation */
export type DeleteSpecialofferInput = {
  /**
   * This is an ID that can be passed to a mutation by the client to track the
   * progress of mutations and catch possible duplicate mutation submissions.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Whether the object should be force deleted instead of being moved to the trash */
  forceDelete?: Maybe<Scalars['Boolean']>;
  /** The ID of the specialoffer to delete */
  id: Scalars['ID'];
};

/** Input for the deleteTag mutation */
export type DeleteTagInput = {
  /**
   * This is an ID that can be passed to a mutation by the client to track the
   * progress of mutations and catch possible duplicate mutation submissions.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The ID of the tag to delete */
  id: Scalars['ID'];
};

/** Input for the deleteUser mutation */
export type DeleteUserInput = {
  /**
   * This is an ID that can be passed to a mutation by the client to track the
   * progress of mutations and catch possible duplicate mutation submissions.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The ID of the user you want to delete */
  id: Scalars['ID'];
  /** Reassign posts and links to new User ID. */
  reassignId?: Maybe<Scalars['ID']>;
};

/** Input for the deleteWeddingbooking mutation */
export type DeleteWeddingbookingInput = {
  /**
   * This is an ID that can be passed to a mutation by the client to track the
   * progress of mutations and catch possible duplicate mutation submissions.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Whether the object should be force deleted instead of being moved to the trash */
  forceDelete?: Maybe<Scalars['Boolean']>;
  /** The ID of the weddingbooking to delete */
  id: Scalars['ID'];
};

/** Arguments for filtering the HierarchicalContentNodeToContentNodeAncestorsConnection connection */
export type HierarchicalContentNodeToContentNodeAncestorsConnectionWhereArgs = {
  /** The Types of content to filter */
  contentTypes?: Maybe<Array<Maybe<ContentTypeEnum>>>;
  /** Filter the connection based on dates */
  dateQuery?: Maybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: Maybe<Scalars['Boolean']>;
  /** Specific ID of the object */
  id?: Maybe<Scalars['Int']>;
  /** Array of IDs for the objects to retrieve */
  in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: Maybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: Maybe<Scalars['String']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** What paramater to use to order the objects by. */
  orderby?: Maybe<Array<Maybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: Maybe<Scalars['ID']>;
  /** Specify objects whose parent is in an array */
  parentIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Show posts with a specific password. */
  password?: Maybe<Scalars['String']>;
  /** Show Posts based on a keyword search */
  search?: Maybe<Scalars['String']>;
  /** Retrieve posts where post status is in an array. */
  stati?: Maybe<Array<Maybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: Maybe<PostStatusEnum>;
  /** Title of the object */
  title?: Maybe<Scalars['String']>;
};

/** Arguments for filtering the HierarchicalContentNodeToContentNodeChildrenConnection connection */
export type HierarchicalContentNodeToContentNodeChildrenConnectionWhereArgs = {
  /** The Types of content to filter */
  contentTypes?: Maybe<Array<Maybe<ContentTypeEnum>>>;
  /** Filter the connection based on dates */
  dateQuery?: Maybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: Maybe<Scalars['Boolean']>;
  /** Specific ID of the object */
  id?: Maybe<Scalars['Int']>;
  /** Array of IDs for the objects to retrieve */
  in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: Maybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: Maybe<Scalars['String']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** What paramater to use to order the objects by. */
  orderby?: Maybe<Array<Maybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: Maybe<Scalars['ID']>;
  /** Specify objects whose parent is in an array */
  parentIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Show posts with a specific password. */
  password?: Maybe<Scalars['String']>;
  /** Show Posts based on a keyword search */
  search?: Maybe<Scalars['String']>;
  /** Retrieve posts where post status is in an array. */
  stati?: Maybe<Array<Maybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: Maybe<PostStatusEnum>;
  /** Title of the object */
  title?: Maybe<Scalars['String']>;
};

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum Html_MenuIdType {
  /** Identify a resource by the Database ID. */
  DatabaseId = 'DATABASE_ID',
  /** Identify a resource by the (hashed) Global ID. */
  Id = 'ID',
  /** Identify a resource by the slug. Available to non-hierarchcial Types where the slug is a unique identifier. */
  Slug = 'SLUG',
  /** Identify a resource by the URI. */
  Uri = 'URI',
}

/** Arguments for filtering the html_menuToRevisionConnection connection */
export type Html_MenuToRevisionConnectionWhereArgs = {
  /** Filter the connection based on dates */
  dateQuery?: Maybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: Maybe<Scalars['Boolean']>;
  /** Specific ID of the object */
  id?: Maybe<Scalars['Int']>;
  /** Array of IDs for the objects to retrieve */
  in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: Maybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: Maybe<Scalars['String']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** What paramater to use to order the objects by. */
  orderby?: Maybe<Array<Maybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: Maybe<Scalars['ID']>;
  /** Specify objects whose parent is in an array */
  parentIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Show posts with a specific password. */
  password?: Maybe<Scalars['String']>;
  /** Show Posts based on a keyword search */
  search?: Maybe<Scalars['String']>;
  /** Retrieve posts where post status is in an array. */
  stati?: Maybe<Array<Maybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: Maybe<PostStatusEnum>;
  /** Title of the object */
  title?: Maybe<Scalars['String']>;
};

/** Input for the login mutation */
export type LoginInput = {
  /**
   * This is an ID that can be passed to a mutation by the client to track the
   * progress of mutations and catch possible duplicate mutation submissions.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The plain-text password for the user logging in. */
  password: Scalars['String'];
  /** The username used for login. Typically a unique or email address depending on specific configuration */
  username: Scalars['String'];
};

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum Media_BlockIdType {
  /** Identify a resource by the Database ID. */
  DatabaseId = 'DATABASE_ID',
  /** Identify a resource by the (hashed) Global ID. */
  Id = 'ID',
  /** Identify a resource by the slug. Available to non-hierarchcial Types where the slug is a unique identifier. */
  Slug = 'SLUG',
  /** Identify a resource by the URI. */
  Uri = 'URI',
}

/** Arguments for filtering the media_blockToRevisionConnection connection */
export type Media_BlockToRevisionConnectionWhereArgs = {
  /** Filter the connection based on dates */
  dateQuery?: Maybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: Maybe<Scalars['Boolean']>;
  /** Specific ID of the object */
  id?: Maybe<Scalars['Int']>;
  /** Array of IDs for the objects to retrieve */
  in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: Maybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: Maybe<Scalars['String']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** What paramater to use to order the objects by. */
  orderby?: Maybe<Array<Maybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: Maybe<Scalars['ID']>;
  /** Specify objects whose parent is in an array */
  parentIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Show posts with a specific password. */
  password?: Maybe<Scalars['String']>;
  /** Show Posts based on a keyword search */
  search?: Maybe<Scalars['String']>;
  /** Retrieve posts where post status is in an array. */
  stati?: Maybe<Array<Maybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: Maybe<PostStatusEnum>;
  /** Title of the object */
  title?: Maybe<Scalars['String']>;
};

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum MediaItemIdType {
  /** Identify a resource by the Database ID. */
  DatabaseId = 'DATABASE_ID',
  /** Identify a resource by the (hashed) Global ID. */
  Id = 'ID',
  /** Identify a resource by the slug. Available to non-hierarchcial Types where the slug is a unique identifier. */
  Slug = 'SLUG',
  /** Identify a media item by its source url */
  SourceUrl = 'SOURCE_URL',
  /** Identify a resource by the URI. */
  Uri = 'URI',
}

/** The size of the media item object. */
export enum MediaItemSizeEnum {
  /** MediaItem with the 1023x520 size */
  '1023X520' = '_1023X520',
  /** MediaItem with the 1080x710 size */
  '1080X710' = '_1080X710',
  /** MediaItem with the 1536x1536 size */
  '1536X1536' = '_1536X1536',
  /** MediaItem with the 1920x1080 size */
  '1920X1080' = '_1920X1080',
  /** MediaItem with the 1920x800 size */
  '1920X800' = '_1920X800',
  /** MediaItem with the 2048x2048 size */
  '2048X2048' = '_2048X2048',
  /** MediaItem with the 360x348 size */
  '360X348' = '_360X348',
  /** MediaItem with the 540x300 size */
  '540X300' = '_540X300',
  /** MediaItem with the 540x390 size */
  '540X390' = '_540X390',
  /** MediaItem with the 540x485 size */
  '540X485' = '_540X485',
  /** MediaItem with the 720x1010 size */
  '720X1010' = '_720X1010',
  /** MediaItem with the 720x348 size */
  '720X348' = '_720X348',
  /** MediaItem with the 720x505 size */
  '720X505' = '_720X505',
  /** MediaItem with the 723x487 size */
  '723X487' = '_723X487',
  /** MediaItem with the 723x760 size */
  '723X760' = '_723X760',
  /** MediaItem with the 785x590 size */
  '785X590' = '_785X590',
  /** MediaItem with the 800x450 size */
  '800X450' = '_800X450',
  /** MediaItem with the 840x525 size */
  '840X525' = '_840X525',
  /** MediaItem with the 840x760 size */
  '840X760' = '_840X760',
  /** MediaItem with the 840x910 size */
  '840X910' = '_840X910',
  /** MediaItem with the 880x580 size */
  '880X580' = '_880X580',
  /** MediaItem with the large size */
  Large = 'LARGE',
  /** MediaItem with the medium size */
  Medium = 'MEDIUM',
  /** MediaItem with the medium_large size */
  MediumLarge = 'MEDIUM_LARGE',
  /** MediaItem with the thumbnail size */
  Thumbnail = 'THUMBNAIL',
  /** MediaItem with the venue-carousel-desktop size */
  VenueCarouselDesktop = 'VENUE_CAROUSEL_DESKTOP',
  /** MediaItem with the venue-carousel-mobile size */
  VenueCarouselMobile = 'VENUE_CAROUSEL_MOBILE',
  /** MediaItem with the venue-carousel-mobile-small size */
  VenueCarouselMobileSmall = 'VENUE_CAROUSEL_MOBILE_SMALL',
}

/** The status of the media item object. */
export enum MediaItemStatusEnum {
  /** Objects with the auto-draft status */
  AutoDraft = 'AUTO_DRAFT',
  /** Objects with the inherit status */
  Inherit = 'INHERIT',
  /** Objects with the private status */
  Private = 'PRIVATE',
  /** Objects with the trash status */
  Trash = 'TRASH',
}

/** Arguments for filtering the MediaItemToCommentConnection connection */
export type MediaItemToCommentConnectionWhereArgs = {
  /** Comment author email address. */
  authorEmail?: Maybe<Scalars['String']>;
  /** Array of author IDs to include comments for. */
  authorIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Array of author IDs to exclude comments for. */
  authorNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Comment author URL. */
  authorUrl?: Maybe<Scalars['String']>;
  /** Array of comment IDs to include. */
  commentIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Array of IDs of users whose unapproved comments will be returned by the query regardless of status. */
  commentNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Include comments of a given type. */
  commentType?: Maybe<Scalars['String']>;
  /** Include comments from a given array of comment types. */
  commentTypeIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Exclude comments from a given array of comment types. */
  commentTypeNotIn?: Maybe<Scalars['String']>;
  /** Content object author ID to limit results by. */
  contentAuthor?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Array of author IDs to retrieve comments for. */
  contentAuthorIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Array of author IDs *not* to retrieve comments for. */
  contentAuthorNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Limit results to those affiliated with a given content object ID. */
  contentId?: Maybe<Scalars['ID']>;
  /** Array of content object IDs to include affiliated comments for. */
  contentIdIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Array of content object IDs to exclude affiliated comments for. */
  contentIdNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Content object name to retrieve affiliated comments for. */
  contentName?: Maybe<Scalars['String']>;
  /** Content Object parent ID to retrieve affiliated comments for. */
  contentParent?: Maybe<Scalars['Int']>;
  /** Array of content object statuses to retrieve affiliated comments for. Pass 'any' to match any value. */
  contentStatus?: Maybe<Array<Maybe<PostStatusEnum>>>;
  /** Content object type or array of types to retrieve affiliated comments for. Pass 'any' to match any value. */
  contentType?: Maybe<Array<Maybe<ContentTypeEnum>>>;
  /**
   * Array of IDs or email addresses of users whose unapproved comments will be
   * returned by the query regardless of $status. Default empty
   */
  includeUnapproved?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Karma score to retrieve matching comments for. */
  karma?: Maybe<Scalars['Int']>;
  /** The cardinality of the order of the connection */
  order?: Maybe<OrderEnum>;
  /** Field to order the comments by. */
  orderby?: Maybe<CommentsConnectionOrderbyEnum>;
  /** Parent ID of comment to retrieve children of. */
  parent?: Maybe<Scalars['Int']>;
  /** Array of parent IDs of comments to retrieve children for. */
  parentIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Array of parent IDs of comments *not* to retrieve children for. */
  parentNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Search term(s) to retrieve matching comments for. */
  search?: Maybe<Scalars['String']>;
  /** Comment status to limit results by. */
  status?: Maybe<Scalars['String']>;
  /** Include comments for a specific user ID. */
  userId?: Maybe<Scalars['ID']>;
};

/** Arguments for filtering the MediaItemToTermNodeConnection connection */
export type MediaItemToTermNodeConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: Maybe<Scalars['String']>;
  /**
   * True to limit results to terms that have no children. This parameter has no
   * effect on non-hierarchical taxonomies. Default false.
   */
  childless?: Maybe<Scalars['Boolean']>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: Maybe<Scalars['Int']>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: Maybe<Scalars['String']>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /**
   * Array of term ids to exclude along with all of their descendant terms. If
   * $include is non-empty, $exclude_tree is ignored. Default empty array.
   */
  excludeTree?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: Maybe<Scalars['Boolean']>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: Maybe<Scalars['Boolean']>;
  /** Array of term ids to include. Default empty array. */
  include?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Array of names to return term(s) for. Default empty. */
  name?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: Maybe<Scalars['String']>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Direction the connection should be ordered in */
  order?: Maybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: Maybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: Maybe<Scalars['Boolean']>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: Maybe<Scalars['Int']>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: Maybe<Scalars['String']>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** The Taxonomy to filter terms by */
  taxonomies?: Maybe<Array<Maybe<TaxonomyEnum>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: Maybe<Scalars['Boolean']>;
};

/** The Type of Identifier used to fetch a single node. Default is "ID". To be used along with the "id" field. */
export enum MenuItemNodeIdTypeEnum {
  /** Identify a resource by the Database ID. */
  DatabaseId = 'DATABASE_ID',
  /** Identify a resource by the (hashed) Global ID. */
  Id = 'ID',
}

/** Arguments for filtering the MenuItemToMenuItemConnection connection */
export type MenuItemToMenuItemConnectionWhereArgs = {
  /** The ID of the object */
  id?: Maybe<Scalars['Int']>;
  /** The menu location for the menu being queried */
  location?: Maybe<MenuLocationEnum>;
  /** The database ID of the parent menu object */
  parentDatabaseId?: Maybe<Scalars['Int']>;
  /** The ID of the parent menu object */
  parentId?: Maybe<Scalars['ID']>;
};

/** Registered menu locations */
export enum MenuLocationEnum {
  /** Put the menu in the footer-navigation location */
  FooterNavigation = 'FOOTER_NAVIGATION',
  /** Put the menu in the language-navigation location */
  LanguageNavigation = 'LANGUAGE_NAVIGATION',
  /** Put the menu in the main-navigation location */
  MainNavigation = 'MAIN_NAVIGATION',
  /** Put the menu in the mini-navigation location */
  MiniNavigation = 'MINI_NAVIGATION',
}

/** The Type of Identifier used to fetch a single node. Default is "ID". To be used along with the "id" field. */
export enum MenuNodeIdTypeEnum {
  /** Identify a menu node by the Database ID. */
  DatabaseId = 'DATABASE_ID',
  /** Identify a menu node by the (hashed) Global ID. */
  Id = 'ID',
  /** Identify a menu node by it's name */
  Name = 'NAME',
}

/** Arguments for filtering the MenuToMenuItemConnection connection */
export type MenuToMenuItemConnectionWhereArgs = {
  /** The ID of the object */
  id?: Maybe<Scalars['Int']>;
  /** The menu location for the menu being queried */
  location?: Maybe<MenuLocationEnum>;
  /** The database ID of the parent menu object */
  parentDatabaseId?: Maybe<Scalars['Int']>;
  /** The ID of the parent menu object */
  parentId?: Maybe<Scalars['ID']>;
};

/** The MimeType of the object */
export enum MimeTypeEnum {
  /** MimeType application/java */
  ApplicationJava = 'APPLICATION_JAVA',
  /** MimeType application/msword */
  ApplicationMsword = 'APPLICATION_MSWORD',
  /** MimeType application/octet-stream */
  ApplicationOctetStream = 'APPLICATION_OCTET_STREAM',
  /** MimeType application/onenote */
  ApplicationOnenote = 'APPLICATION_ONENOTE',
  /** MimeType application/oxps */
  ApplicationOxps = 'APPLICATION_OXPS',
  /** MimeType application/pdf */
  ApplicationPdf = 'APPLICATION_PDF',
  /** MimeType application/rar */
  ApplicationRar = 'APPLICATION_RAR',
  /** MimeType application/rtf */
  ApplicationRtf = 'APPLICATION_RTF',
  /** MimeType application/ttaf+xml */
  ApplicationTtafXml = 'APPLICATION_TTAF_XML',
  /** MimeType application/vnd.apple.keynote */
  ApplicationVndAppleKeynote = 'APPLICATION_VND_APPLE_KEYNOTE',
  /** MimeType application/vnd.apple.numbers */
  ApplicationVndAppleNumbers = 'APPLICATION_VND_APPLE_NUMBERS',
  /** MimeType application/vnd.apple.pages */
  ApplicationVndApplePages = 'APPLICATION_VND_APPLE_PAGES',
  /** MimeType application/vnd.ms-access */
  ApplicationVndMsAccess = 'APPLICATION_VND_MS_ACCESS',
  /** MimeType application/vnd.ms-excel */
  ApplicationVndMsExcel = 'APPLICATION_VND_MS_EXCEL',
  /** MimeType application/vnd.ms-excel.addin.macroEnabled.12 */
  ApplicationVndMsExcelAddinMacroenabled_12 = 'APPLICATION_VND_MS_EXCEL_ADDIN_MACROENABLED_12',
  /** MimeType application/vnd.ms-excel.sheet.binary.macroEnabled.12 */
  ApplicationVndMsExcelSheetBinaryMacroenabled_12 = 'APPLICATION_VND_MS_EXCEL_SHEET_BINARY_MACROENABLED_12',
  /** MimeType application/vnd.ms-excel.sheet.macroEnabled.12 */
  ApplicationVndMsExcelSheetMacroenabled_12 = 'APPLICATION_VND_MS_EXCEL_SHEET_MACROENABLED_12',
  /** MimeType application/vnd.ms-excel.template.macroEnabled.12 */
  ApplicationVndMsExcelTemplateMacroenabled_12 = 'APPLICATION_VND_MS_EXCEL_TEMPLATE_MACROENABLED_12',
  /** MimeType application/vnd.ms-powerpoint */
  ApplicationVndMsPowerpoint = 'APPLICATION_VND_MS_POWERPOINT',
  /** MimeType application/vnd.ms-powerpoint.addin.macroEnabled.12 */
  ApplicationVndMsPowerpointAddinMacroenabled_12 = 'APPLICATION_VND_MS_POWERPOINT_ADDIN_MACROENABLED_12',
  /** MimeType application/vnd.ms-powerpoint.presentation.macroEnabled.12 */
  ApplicationVndMsPowerpointPresentationMacroenabled_12 = 'APPLICATION_VND_MS_POWERPOINT_PRESENTATION_MACROENABLED_12',
  /** MimeType application/vnd.ms-powerpoint.slide.macroEnabled.12 */
  ApplicationVndMsPowerpointSlideMacroenabled_12 = 'APPLICATION_VND_MS_POWERPOINT_SLIDE_MACROENABLED_12',
  /** MimeType application/vnd.ms-powerpoint.slideshow.macroEnabled.12 */
  ApplicationVndMsPowerpointSlideshowMacroenabled_12 = 'APPLICATION_VND_MS_POWERPOINT_SLIDESHOW_MACROENABLED_12',
  /** MimeType application/vnd.ms-powerpoint.template.macroEnabled.12 */
  ApplicationVndMsPowerpointTemplateMacroenabled_12 = 'APPLICATION_VND_MS_POWERPOINT_TEMPLATE_MACROENABLED_12',
  /** MimeType application/vnd.ms-project */
  ApplicationVndMsProject = 'APPLICATION_VND_MS_PROJECT',
  /** MimeType application/vnd.ms-word.document.macroEnabled.12 */
  ApplicationVndMsWordDocumentMacroenabled_12 = 'APPLICATION_VND_MS_WORD_DOCUMENT_MACROENABLED_12',
  /** MimeType application/vnd.ms-word.template.macroEnabled.12 */
  ApplicationVndMsWordTemplateMacroenabled_12 = 'APPLICATION_VND_MS_WORD_TEMPLATE_MACROENABLED_12',
  /** MimeType application/vnd.ms-write */
  ApplicationVndMsWrite = 'APPLICATION_VND_MS_WRITE',
  /** MimeType application/vnd.ms-xpsdocument */
  ApplicationVndMsXpsdocument = 'APPLICATION_VND_MS_XPSDOCUMENT',
  /** MimeType application/vnd.oasis.opendocument.chart */
  ApplicationVndOasisOpendocumentChart = 'APPLICATION_VND_OASIS_OPENDOCUMENT_CHART',
  /** MimeType application/vnd.oasis.opendocument.database */
  ApplicationVndOasisOpendocumentDatabase = 'APPLICATION_VND_OASIS_OPENDOCUMENT_DATABASE',
  /** MimeType application/vnd.oasis.opendocument.formula */
  ApplicationVndOasisOpendocumentFormula = 'APPLICATION_VND_OASIS_OPENDOCUMENT_FORMULA',
  /** MimeType application/vnd.oasis.opendocument.graphics */
  ApplicationVndOasisOpendocumentGraphics = 'APPLICATION_VND_OASIS_OPENDOCUMENT_GRAPHICS',
  /** MimeType application/vnd.oasis.opendocument.presentation */
  ApplicationVndOasisOpendocumentPresentation = 'APPLICATION_VND_OASIS_OPENDOCUMENT_PRESENTATION',
  /** MimeType application/vnd.oasis.opendocument.spreadsheet */
  ApplicationVndOasisOpendocumentSpreadsheet = 'APPLICATION_VND_OASIS_OPENDOCUMENT_SPREADSHEET',
  /** MimeType application/vnd.oasis.opendocument.text */
  ApplicationVndOasisOpendocumentText = 'APPLICATION_VND_OASIS_OPENDOCUMENT_TEXT',
  /** MimeType application/vnd.openxmlformats-officedocument.presentationml.presentation */
  ApplicationVndOpenxmlformatsOfficedocumentPresentationmlPresentation = 'APPLICATION_VND_OPENXMLFORMATS_OFFICEDOCUMENT_PRESENTATIONML_PRESENTATION',
  /** MimeType application/vnd.openxmlformats-officedocument.presentationml.slide */
  ApplicationVndOpenxmlformatsOfficedocumentPresentationmlSlide = 'APPLICATION_VND_OPENXMLFORMATS_OFFICEDOCUMENT_PRESENTATIONML_SLIDE',
  /** MimeType application/vnd.openxmlformats-officedocument.presentationml.slideshow */
  ApplicationVndOpenxmlformatsOfficedocumentPresentationmlSlideshow = 'APPLICATION_VND_OPENXMLFORMATS_OFFICEDOCUMENT_PRESENTATIONML_SLIDESHOW',
  /** MimeType application/vnd.openxmlformats-officedocument.presentationml.template */
  ApplicationVndOpenxmlformatsOfficedocumentPresentationmlTemplate = 'APPLICATION_VND_OPENXMLFORMATS_OFFICEDOCUMENT_PRESENTATIONML_TEMPLATE',
  /** MimeType application/vnd.openxmlformats-officedocument.spreadsheetml.sheet */
  ApplicationVndOpenxmlformatsOfficedocumentSpreadsheetmlSheet = 'APPLICATION_VND_OPENXMLFORMATS_OFFICEDOCUMENT_SPREADSHEETML_SHEET',
  /** MimeType application/vnd.openxmlformats-officedocument.spreadsheetml.template */
  ApplicationVndOpenxmlformatsOfficedocumentSpreadsheetmlTemplate = 'APPLICATION_VND_OPENXMLFORMATS_OFFICEDOCUMENT_SPREADSHEETML_TEMPLATE',
  /** MimeType application/vnd.openxmlformats-officedocument.wordprocessingml.document */
  ApplicationVndOpenxmlformatsOfficedocumentWordprocessingmlDocument = 'APPLICATION_VND_OPENXMLFORMATS_OFFICEDOCUMENT_WORDPROCESSINGML_DOCUMENT',
  /** MimeType application/vnd.openxmlformats-officedocument.wordprocessingml.template */
  ApplicationVndOpenxmlformatsOfficedocumentWordprocessingmlTemplate = 'APPLICATION_VND_OPENXMLFORMATS_OFFICEDOCUMENT_WORDPROCESSINGML_TEMPLATE',
  /** MimeType application/wordperfect */
  ApplicationWordperfect = 'APPLICATION_WORDPERFECT',
  /** MimeType application/x-7z-compressed */
  ApplicationX_7ZCompressed = 'APPLICATION_X_7Z_COMPRESSED',
  /** MimeType application/x-gzip */
  ApplicationXGzip = 'APPLICATION_X_GZIP',
  /** MimeType application/x-tar */
  ApplicationXTar = 'APPLICATION_X_TAR',
  /** MimeType application/zip */
  ApplicationZip = 'APPLICATION_ZIP',
  /** MimeType audio/aac */
  AudioAac = 'AUDIO_AAC',
  /** MimeType audio/flac */
  AudioFlac = 'AUDIO_FLAC',
  /** MimeType audio/midi */
  AudioMidi = 'AUDIO_MIDI',
  /** MimeType audio/mpeg */
  AudioMpeg = 'AUDIO_MPEG',
  /** MimeType audio/ogg */
  AudioOgg = 'AUDIO_OGG',
  /** MimeType audio/wav */
  AudioWav = 'AUDIO_WAV',
  /** MimeType audio/x-matroska */
  AudioXMatroska = 'AUDIO_X_MATROSKA',
  /** MimeType audio/x-ms-wax */
  AudioXMsWax = 'AUDIO_X_MS_WAX',
  /** MimeType audio/x-ms-wma */
  AudioXMsWma = 'AUDIO_X_MS_WMA',
  /** MimeType audio/x-realaudio */
  AudioXRealaudio = 'AUDIO_X_REALAUDIO',
  /** MimeType image/avif */
  ImageAvif = 'IMAGE_AVIF',
  /** MimeType image/bmp */
  ImageBmp = 'IMAGE_BMP',
  /** MimeType image/gif */
  ImageGif = 'IMAGE_GIF',
  /** MimeType image/heic */
  ImageHeic = 'IMAGE_HEIC',
  /** MimeType image/jpeg */
  ImageJpeg = 'IMAGE_JPEG',
  /** MimeType image/png */
  ImagePng = 'IMAGE_PNG',
  /** MimeType image/svg+xml */
  ImageSvgXml = 'IMAGE_SVG_XML',
  /** MimeType image/tiff */
  ImageTiff = 'IMAGE_TIFF',
  /** MimeType image/webp */
  ImageWebp = 'IMAGE_WEBP',
  /** MimeType image/x-icon */
  ImageXIcon = 'IMAGE_X_ICON',
  /** MimeType text/calendar */
  TextCalendar = 'TEXT_CALENDAR',
  /** MimeType text/css */
  TextCss = 'TEXT_CSS',
  /** MimeType text/csv */
  TextCsv = 'TEXT_CSV',
  /** MimeType text/plain */
  TextPlain = 'TEXT_PLAIN',
  /** MimeType text/richtext */
  TextRichtext = 'TEXT_RICHTEXT',
  /** MimeType text/tab-separated-values */
  TextTabSeparatedValues = 'TEXT_TAB_SEPARATED_VALUES',
  /** MimeType text/vtt */
  TextVtt = 'TEXT_VTT',
  /** MimeType video/3gpp */
  Video_3Gpp = 'VIDEO_3GPP',
  /** MimeType video/3gpp2 */
  Video_3Gpp2 = 'VIDEO_3GPP2',
  /** MimeType video/avi */
  VideoAvi = 'VIDEO_AVI',
  /** MimeType video/divx */
  VideoDivx = 'VIDEO_DIVX',
  /** MimeType video/mp4 */
  VideoMp4 = 'VIDEO_MP4',
  /** MimeType video/mpeg */
  VideoMpeg = 'VIDEO_MPEG',
  /** MimeType video/ogg */
  VideoOgg = 'VIDEO_OGG',
  /** MimeType video/quicktime */
  VideoQuicktime = 'VIDEO_QUICKTIME',
  /** MimeType video/webm */
  VideoWebm = 'VIDEO_WEBM',
  /** MimeType video/x-flv */
  VideoXFlv = 'VIDEO_X_FLV',
  /** MimeType video/x-matroska */
  VideoXMatroska = 'VIDEO_X_MATROSKA',
  /** MimeType video/x-ms-asf */
  VideoXMsAsf = 'VIDEO_X_MS_ASF',
  /** MimeType video/x-ms-wm */
  VideoXMsWm = 'VIDEO_X_MS_WM',
  /** MimeType video/x-ms-wmv */
  VideoXMsWmv = 'VIDEO_X_MS_WMV',
  /** MimeType video/x-ms-wmx */
  VideoXMsWmx = 'VIDEO_X_MS_WMX',
}

/** The cardinality of the connection order */
export enum OrderEnum {
  /** Sort the query result set in an ascending order */
  Asc = 'ASC',
  /** Sort the query result set in a descending order */
  Desc = 'DESC',
}

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum PageIdType {
  /** Identify a resource by the Database ID. */
  DatabaseId = 'DATABASE_ID',
  /** Identify a resource by the (hashed) Global ID. */
  Id = 'ID',
  /** Identify a resource by the URI. */
  Uri = 'URI',
}

/** Arguments for filtering the PageToCommentConnection connection */
export type PageToCommentConnectionWhereArgs = {
  /** Comment author email address. */
  authorEmail?: Maybe<Scalars['String']>;
  /** Array of author IDs to include comments for. */
  authorIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Array of author IDs to exclude comments for. */
  authorNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Comment author URL. */
  authorUrl?: Maybe<Scalars['String']>;
  /** Array of comment IDs to include. */
  commentIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Array of IDs of users whose unapproved comments will be returned by the query regardless of status. */
  commentNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Include comments of a given type. */
  commentType?: Maybe<Scalars['String']>;
  /** Include comments from a given array of comment types. */
  commentTypeIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Exclude comments from a given array of comment types. */
  commentTypeNotIn?: Maybe<Scalars['String']>;
  /** Content object author ID to limit results by. */
  contentAuthor?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Array of author IDs to retrieve comments for. */
  contentAuthorIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Array of author IDs *not* to retrieve comments for. */
  contentAuthorNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Limit results to those affiliated with a given content object ID. */
  contentId?: Maybe<Scalars['ID']>;
  /** Array of content object IDs to include affiliated comments for. */
  contentIdIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Array of content object IDs to exclude affiliated comments for. */
  contentIdNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Content object name to retrieve affiliated comments for. */
  contentName?: Maybe<Scalars['String']>;
  /** Content Object parent ID to retrieve affiliated comments for. */
  contentParent?: Maybe<Scalars['Int']>;
  /** Array of content object statuses to retrieve affiliated comments for. Pass 'any' to match any value. */
  contentStatus?: Maybe<Array<Maybe<PostStatusEnum>>>;
  /** Content object type or array of types to retrieve affiliated comments for. Pass 'any' to match any value. */
  contentType?: Maybe<Array<Maybe<ContentTypeEnum>>>;
  /**
   * Array of IDs or email addresses of users whose unapproved comments will be
   * returned by the query regardless of $status. Default empty
   */
  includeUnapproved?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Karma score to retrieve matching comments for. */
  karma?: Maybe<Scalars['Int']>;
  /** The cardinality of the order of the connection */
  order?: Maybe<OrderEnum>;
  /** Field to order the comments by. */
  orderby?: Maybe<CommentsConnectionOrderbyEnum>;
  /** Parent ID of comment to retrieve children of. */
  parent?: Maybe<Scalars['Int']>;
  /** Array of parent IDs of comments to retrieve children for. */
  parentIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Array of parent IDs of comments *not* to retrieve children for. */
  parentNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Search term(s) to retrieve matching comments for. */
  search?: Maybe<Scalars['String']>;
  /** Comment status to limit results by. */
  status?: Maybe<Scalars['String']>;
  /** Include comments for a specific user ID. */
  userId?: Maybe<Scalars['ID']>;
};

/** Arguments for filtering the pageToRevisionConnection connection */
export type PageToRevisionConnectionWhereArgs = {
  /** The user that's connected as the author of the object. Use the userId for the author object. */
  author?: Maybe<Scalars['Int']>;
  /** Find objects connected to author(s) in the array of author's userIds */
  authorIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Find objects connected to the author by the author's nicename */
  authorName?: Maybe<Scalars['String']>;
  /** Find objects NOT connected to author(s) in the array of author's userIds */
  authorNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Filter the connection based on dates */
  dateQuery?: Maybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: Maybe<Scalars['Boolean']>;
  /** Specific ID of the object */
  id?: Maybe<Scalars['Int']>;
  /** Array of IDs for the objects to retrieve */
  in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: Maybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: Maybe<Scalars['String']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** What paramater to use to order the objects by. */
  orderby?: Maybe<Array<Maybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: Maybe<Scalars['ID']>;
  /** Specify objects whose parent is in an array */
  parentIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Show posts with a specific password. */
  password?: Maybe<Scalars['String']>;
  /** Show Posts based on a keyword search */
  search?: Maybe<Scalars['String']>;
  /** Retrieve posts where post status is in an array. */
  stati?: Maybe<Array<Maybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: Maybe<PostStatusEnum>;
  /** Title of the object */
  title?: Maybe<Scalars['String']>;
};

/** Arguments for filtering the PageToTermNodeConnection connection */
export type PageToTermNodeConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: Maybe<Scalars['String']>;
  /**
   * True to limit results to terms that have no children. This parameter has no
   * effect on non-hierarchical taxonomies. Default false.
   */
  childless?: Maybe<Scalars['Boolean']>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: Maybe<Scalars['Int']>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: Maybe<Scalars['String']>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /**
   * Array of term ids to exclude along with all of their descendant terms. If
   * $include is non-empty, $exclude_tree is ignored. Default empty array.
   */
  excludeTree?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: Maybe<Scalars['Boolean']>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: Maybe<Scalars['Boolean']>;
  /** Array of term ids to include. Default empty array. */
  include?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Array of names to return term(s) for. Default empty. */
  name?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: Maybe<Scalars['String']>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Direction the connection should be ordered in */
  order?: Maybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: Maybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: Maybe<Scalars['Boolean']>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: Maybe<Scalars['Int']>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: Maybe<Scalars['String']>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** The Taxonomy to filter terms by */
  taxonomies?: Maybe<Array<Maybe<TaxonomyEnum>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: Maybe<Scalars['Boolean']>;
};

/** Set relationships between the post to categories */
export type PostCategoriesInput = {
  /**
   * If true, this will append the category to existing related categories. If
   * false, this will replace existing relationships. Default true.
   */
  append?: Maybe<Scalars['Boolean']>;
  /** The input list of items to set. */
  nodes?: Maybe<Array<Maybe<PostCategoriesNodeInput>>>;
};

/**
 * List of categories to connect the post to. If an ID is set, it will be used to
 * create the connection. If not, it will look for a slug. If neither are valid
 * existing terms, and the site is configured to allow terms to be created during
 * post mutations, a term will be created using the Name if it exists in the input,
 * then fallback to the slug if it exists.
 */
export type PostCategoriesNodeInput = {
  /**
   * The description of the category. This field is used to set a description of
   * the category if a new one is created during the mutation.
   */
  description?: Maybe<Scalars['String']>;
  /**
   * The ID of the category. If present, this will be used to connect to the post.
   * If no existing category exists with this ID, no connection will be made.
   */
  id?: Maybe<Scalars['ID']>;
  /**
   * The name of the category. This field is used to create a new term, if term
   * creation is enabled in nested mutations, and if one does not already exist
   * with the provided slug or ID or if a slug or ID is not provided. If no name is
   * included and a term is created, the creation will fallback to the slug field.
   */
  name?: Maybe<Scalars['String']>;
  /**
   * The slug of the category. If no ID is present, this field will be used to make
   * a connection. If no existing term exists with this slug, this field will be
   * used as a fallback to the Name field when creating a new term to connect to,
   * if term creation is enabled as a nested mutation.
   */
  slug?: Maybe<Scalars['String']>;
};

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum PostFormatIdType {
  /** The Database ID for the node */
  DatabaseId = 'DATABASE_ID',
  /** The hashed Global ID */
  Id = 'ID',
  /** The name of the node */
  Name = 'NAME',
  /** Url friendly name of the node */
  Slug = 'SLUG',
  /** The URI for the node */
  Uri = 'URI',
}

/** Arguments for filtering the PostFormatToContentNodeConnection connection */
export type PostFormatToContentNodeConnectionWhereArgs = {
  /** The Types of content to filter */
  contentTypes?: Maybe<Array<Maybe<ContentTypesOfPostFormatEnum>>>;
  /** Filter the connection based on dates */
  dateQuery?: Maybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: Maybe<Scalars['Boolean']>;
  /** Specific ID of the object */
  id?: Maybe<Scalars['Int']>;
  /** Array of IDs for the objects to retrieve */
  in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: Maybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: Maybe<Scalars['String']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** What paramater to use to order the objects by. */
  orderby?: Maybe<Array<Maybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: Maybe<Scalars['ID']>;
  /** Specify objects whose parent is in an array */
  parentIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Show posts with a specific password. */
  password?: Maybe<Scalars['String']>;
  /** Show Posts based on a keyword search */
  search?: Maybe<Scalars['String']>;
  /** Retrieve posts where post status is in an array. */
  stati?: Maybe<Array<Maybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: Maybe<PostStatusEnum>;
  /** Title of the object */
  title?: Maybe<Scalars['String']>;
};

/** Arguments for filtering the PostFormatToPostConnection connection */
export type PostFormatToPostConnectionWhereArgs = {
  /** The user that's connected as the author of the object. Use the userId for the author object. */
  author?: Maybe<Scalars['Int']>;
  /** Find objects connected to author(s) in the array of author's userIds */
  authorIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Find objects connected to the author by the author's nicename */
  authorName?: Maybe<Scalars['String']>;
  /** Find objects NOT connected to author(s) in the array of author's userIds */
  authorNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Category ID */
  categoryId?: Maybe<Scalars['Int']>;
  /** Array of category IDs, used to display objects from one category OR another */
  categoryIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Use Category Slug */
  categoryName?: Maybe<Scalars['String']>;
  /** Array of category IDs, used to display objects from one category OR another */
  categoryNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Filter the connection based on dates */
  dateQuery?: Maybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: Maybe<Scalars['Boolean']>;
  /** Specific ID of the object */
  id?: Maybe<Scalars['Int']>;
  /** Array of IDs for the objects to retrieve */
  in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: Maybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: Maybe<Scalars['String']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** What paramater to use to order the objects by. */
  orderby?: Maybe<Array<Maybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: Maybe<Scalars['ID']>;
  /** Specify objects whose parent is in an array */
  parentIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Show posts with a specific password. */
  password?: Maybe<Scalars['String']>;
  /** Show Posts based on a keyword search */
  search?: Maybe<Scalars['String']>;
  /** Retrieve posts where post status is in an array. */
  stati?: Maybe<Array<Maybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: Maybe<PostStatusEnum>;
  /** Tag Slug */
  tag?: Maybe<Scalars['String']>;
  /** Use Tag ID */
  tagId?: Maybe<Scalars['String']>;
  /** Array of tag IDs, used to display objects from one tag OR another */
  tagIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Array of tag IDs, used to display objects from one tag OR another */
  tagNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Array of tag slugs, used to display objects from one tag OR another */
  tagSlugAnd?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Array of tag slugs, used to exclude objects in specified tags */
  tagSlugIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Title of the object */
  title?: Maybe<Scalars['String']>;
};

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum PostIdType {
  /** Identify a resource by the Database ID. */
  DatabaseId = 'DATABASE_ID',
  /** Identify a resource by the (hashed) Global ID. */
  Id = 'ID',
  /** Identify a resource by the slug. Available to non-hierarchcial Types where the slug is a unique identifier. */
  Slug = 'SLUG',
  /** Identify a resource by the URI. */
  Uri = 'URI',
}

/** The format of post field data. */
export enum PostObjectFieldFormatEnum {
  /** Provide the field value directly from database */
  Raw = 'RAW',
  /** Apply the default WordPress rendering */
  Rendered = 'RENDERED',
}

/** The column to use when filtering by date */
export enum PostObjectsConnectionDateColumnEnum {
  /** The date the comment was created in local time. */
  Date = 'DATE',
  /** The most recent modification date of the comment. */
  Modified = 'MODIFIED',
}

/** Field to order the connection by */
export enum PostObjectsConnectionOrderbyEnum {
  /** Order by author */
  Author = 'AUTHOR',
  /** Order by the number of comments it has acquired */
  CommentCount = 'COMMENT_COUNT',
  /** Order by publish date */
  Date = 'DATE',
  /** Preserve the ID order given in the IN array */
  In = 'IN',
  /** Order by the menu order value */
  MenuOrder = 'MENU_ORDER',
  /** Order by last modified date */
  Modified = 'MODIFIED',
  /** Preserve slug order given in the NAME_IN array */
  NameIn = 'NAME_IN',
  /** Order by parent ID */
  Parent = 'PARENT',
  /** Order by slug */
  Slug = 'SLUG',
  /** Order by title */
  Title = 'TITLE',
}

/** Options for ordering the connection */
export type PostObjectsConnectionOrderbyInput = {
  /** The field to order the connection by */
  field: PostObjectsConnectionOrderbyEnum;
  /** Possible directions in which to order a list of items */
  order: OrderEnum;
};

/** Set relationships between the post to postFormats */
export type PostPostFormatsInput = {
  /**
   * If true, this will append the postFormat to existing related postFormats. If
   * false, this will replace existing relationships. Default true.
   */
  append?: Maybe<Scalars['Boolean']>;
  /** The input list of items to set. */
  nodes?: Maybe<Array<Maybe<PostPostFormatsNodeInput>>>;
};

/**
 * List of postFormats to connect the post to. If an ID is set, it will be used to
 * create the connection. If not, it will look for a slug. If neither are valid
 * existing terms, and the site is configured to allow terms to be created during
 * post mutations, a term will be created using the Name if it exists in the input,
 * then fallback to the slug if it exists.
 */
export type PostPostFormatsNodeInput = {
  /**
   * The description of the postFormat. This field is used to set a description of
   * the postFormat if a new one is created during the mutation.
   */
  description?: Maybe<Scalars['String']>;
  /**
   * The ID of the postFormat. If present, this will be used to connect to the
   * post. If no existing postFormat exists with this ID, no connection will be made.
   */
  id?: Maybe<Scalars['ID']>;
  /**
   * The name of the postFormat. This field is used to create a new term, if term
   * creation is enabled in nested mutations, and if one does not already exist
   * with the provided slug or ID or if a slug or ID is not provided. If no name is
   * included and a term is created, the creation will fallback to the slug field.
   */
  name?: Maybe<Scalars['String']>;
  /**
   * The slug of the postFormat. If no ID is present, this field will be used to
   * make a connection. If no existing term exists with this slug, this field will
   * be used as a fallback to the Name field when creating a new term to connect
   * to, if term creation is enabled as a nested mutation.
   */
  slug?: Maybe<Scalars['String']>;
};

/** The status of the object. */
export enum PostStatusEnum {
  /** Objects with the acf-disabled status */
  AcfDisabled = 'ACF_DISABLED',
  /** Objects with the auto-draft status */
  AutoDraft = 'AUTO_DRAFT',
  /** Objects with the draft status */
  Draft = 'DRAFT',
  /** Objects with the future status */
  Future = 'FUTURE',
  /** Objects with the inherit status */
  Inherit = 'INHERIT',
  /** Objects with the pending status */
  Pending = 'PENDING',
  /** Objects with the private status */
  Private = 'PRIVATE',
  /** Objects with the publish status */
  Publish = 'PUBLISH',
  /** Objects with the request-completed status */
  RequestCompleted = 'REQUEST_COMPLETED',
  /** Objects with the request-confirmed status */
  RequestConfirmed = 'REQUEST_CONFIRMED',
  /** Objects with the request-failed status */
  RequestFailed = 'REQUEST_FAILED',
  /** Objects with the request-pending status */
  RequestPending = 'REQUEST_PENDING',
  /** Objects with the trash status */
  Trash = 'TRASH',
}

/** Set relationships between the post to tags */
export type PostTagsInput = {
  /**
   * If true, this will append the tag to existing related tags. If false, this
   * will replace existing relationships. Default true.
   */
  append?: Maybe<Scalars['Boolean']>;
  /** The input list of items to set. */
  nodes?: Maybe<Array<Maybe<PostTagsNodeInput>>>;
};

/**
 * List of tags to connect the post to. If an ID is set, it will be used to create
 * the connection. If not, it will look for a slug. If neither are valid existing
 * terms, and the site is configured to allow terms to be created during post
 * mutations, a term will be created using the Name if it exists in the input, then
 * fallback to the slug if it exists.
 */
export type PostTagsNodeInput = {
  /**
   * The description of the tag. This field is used to set a description of the tag
   * if a new one is created during the mutation.
   */
  description?: Maybe<Scalars['String']>;
  /**
   * The ID of the tag. If present, this will be used to connect to the post. If no
   * existing tag exists with this ID, no connection will be made.
   */
  id?: Maybe<Scalars['ID']>;
  /**
   * The name of the tag. This field is used to create a new term, if term creation
   * is enabled in nested mutations, and if one does not already exist with the
   * provided slug or ID or if a slug or ID is not provided. If no name is included
   * and a term is created, the creation will fallback to the slug field.
   */
  name?: Maybe<Scalars['String']>;
  /**
   * The slug of the tag. If no ID is present, this field will be used to make a
   * connection. If no existing term exists with this slug, this field will be used
   * as a fallback to the Name field when creating a new term to connect to, if
   * term creation is enabled as a nested mutation.
   */
  slug?: Maybe<Scalars['String']>;
};

/** Arguments for filtering the PostToCategoryConnection connection */
export type PostToCategoryConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: Maybe<Scalars['String']>;
  /**
   * True to limit results to terms that have no children. This parameter has no
   * effect on non-hierarchical taxonomies. Default false.
   */
  childless?: Maybe<Scalars['Boolean']>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: Maybe<Scalars['Int']>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: Maybe<Scalars['String']>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /**
   * Array of term ids to exclude along with all of their descendant terms. If
   * $include is non-empty, $exclude_tree is ignored. Default empty array.
   */
  excludeTree?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: Maybe<Scalars['Boolean']>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: Maybe<Scalars['Boolean']>;
  /** Array of term ids to include. Default empty array. */
  include?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Array of names to return term(s) for. Default empty. */
  name?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: Maybe<Scalars['String']>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Direction the connection should be ordered in */
  order?: Maybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: Maybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: Maybe<Scalars['Boolean']>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: Maybe<Scalars['Int']>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: Maybe<Scalars['String']>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: Maybe<Scalars['Boolean']>;
};

/** Arguments for filtering the PostToCommentConnection connection */
export type PostToCommentConnectionWhereArgs = {
  /** Comment author email address. */
  authorEmail?: Maybe<Scalars['String']>;
  /** Array of author IDs to include comments for. */
  authorIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Array of author IDs to exclude comments for. */
  authorNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Comment author URL. */
  authorUrl?: Maybe<Scalars['String']>;
  /** Array of comment IDs to include. */
  commentIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Array of IDs of users whose unapproved comments will be returned by the query regardless of status. */
  commentNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Include comments of a given type. */
  commentType?: Maybe<Scalars['String']>;
  /** Include comments from a given array of comment types. */
  commentTypeIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Exclude comments from a given array of comment types. */
  commentTypeNotIn?: Maybe<Scalars['String']>;
  /** Content object author ID to limit results by. */
  contentAuthor?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Array of author IDs to retrieve comments for. */
  contentAuthorIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Array of author IDs *not* to retrieve comments for. */
  contentAuthorNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Limit results to those affiliated with a given content object ID. */
  contentId?: Maybe<Scalars['ID']>;
  /** Array of content object IDs to include affiliated comments for. */
  contentIdIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Array of content object IDs to exclude affiliated comments for. */
  contentIdNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Content object name to retrieve affiliated comments for. */
  contentName?: Maybe<Scalars['String']>;
  /** Content Object parent ID to retrieve affiliated comments for. */
  contentParent?: Maybe<Scalars['Int']>;
  /** Array of content object statuses to retrieve affiliated comments for. Pass 'any' to match any value. */
  contentStatus?: Maybe<Array<Maybe<PostStatusEnum>>>;
  /** Content object type or array of types to retrieve affiliated comments for. Pass 'any' to match any value. */
  contentType?: Maybe<Array<Maybe<ContentTypeEnum>>>;
  /**
   * Array of IDs or email addresses of users whose unapproved comments will be
   * returned by the query regardless of $status. Default empty
   */
  includeUnapproved?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Karma score to retrieve matching comments for. */
  karma?: Maybe<Scalars['Int']>;
  /** The cardinality of the order of the connection */
  order?: Maybe<OrderEnum>;
  /** Field to order the comments by. */
  orderby?: Maybe<CommentsConnectionOrderbyEnum>;
  /** Parent ID of comment to retrieve children of. */
  parent?: Maybe<Scalars['Int']>;
  /** Array of parent IDs of comments to retrieve children for. */
  parentIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Array of parent IDs of comments *not* to retrieve children for. */
  parentNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Search term(s) to retrieve matching comments for. */
  search?: Maybe<Scalars['String']>;
  /** Comment status to limit results by. */
  status?: Maybe<Scalars['String']>;
  /** Include comments for a specific user ID. */
  userId?: Maybe<Scalars['ID']>;
};

/** Arguments for filtering the PostToPostFormatConnection connection */
export type PostToPostFormatConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: Maybe<Scalars['String']>;
  /**
   * True to limit results to terms that have no children. This parameter has no
   * effect on non-hierarchical taxonomies. Default false.
   */
  childless?: Maybe<Scalars['Boolean']>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: Maybe<Scalars['Int']>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: Maybe<Scalars['String']>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /**
   * Array of term ids to exclude along with all of their descendant terms. If
   * $include is non-empty, $exclude_tree is ignored. Default empty array.
   */
  excludeTree?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: Maybe<Scalars['Boolean']>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: Maybe<Scalars['Boolean']>;
  /** Array of term ids to include. Default empty array. */
  include?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Array of names to return term(s) for. Default empty. */
  name?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: Maybe<Scalars['String']>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Direction the connection should be ordered in */
  order?: Maybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: Maybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: Maybe<Scalars['Boolean']>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: Maybe<Scalars['Int']>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: Maybe<Scalars['String']>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: Maybe<Scalars['Boolean']>;
};

/** Arguments for filtering the postToRevisionConnection connection */
export type PostToRevisionConnectionWhereArgs = {
  /** The user that's connected as the author of the object. Use the userId for the author object. */
  author?: Maybe<Scalars['Int']>;
  /** Find objects connected to author(s) in the array of author's userIds */
  authorIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Find objects connected to the author by the author's nicename */
  authorName?: Maybe<Scalars['String']>;
  /** Find objects NOT connected to author(s) in the array of author's userIds */
  authorNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Category ID */
  categoryId?: Maybe<Scalars['Int']>;
  /** Array of category IDs, used to display objects from one category OR another */
  categoryIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Use Category Slug */
  categoryName?: Maybe<Scalars['String']>;
  /** Array of category IDs, used to display objects from one category OR another */
  categoryNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Filter the connection based on dates */
  dateQuery?: Maybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: Maybe<Scalars['Boolean']>;
  /** Specific ID of the object */
  id?: Maybe<Scalars['Int']>;
  /** Array of IDs for the objects to retrieve */
  in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: Maybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: Maybe<Scalars['String']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** What paramater to use to order the objects by. */
  orderby?: Maybe<Array<Maybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: Maybe<Scalars['ID']>;
  /** Specify objects whose parent is in an array */
  parentIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Show posts with a specific password. */
  password?: Maybe<Scalars['String']>;
  /** Show Posts based on a keyword search */
  search?: Maybe<Scalars['String']>;
  /** Retrieve posts where post status is in an array. */
  stati?: Maybe<Array<Maybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: Maybe<PostStatusEnum>;
  /** Tag Slug */
  tag?: Maybe<Scalars['String']>;
  /** Use Tag ID */
  tagId?: Maybe<Scalars['String']>;
  /** Array of tag IDs, used to display objects from one tag OR another */
  tagIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Array of tag IDs, used to display objects from one tag OR another */
  tagNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Array of tag slugs, used to display objects from one tag OR another */
  tagSlugAnd?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Array of tag slugs, used to exclude objects in specified tags */
  tagSlugIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Title of the object */
  title?: Maybe<Scalars['String']>;
};

/** Arguments for filtering the PostToTagConnection connection */
export type PostToTagConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: Maybe<Scalars['String']>;
  /**
   * True to limit results to terms that have no children. This parameter has no
   * effect on non-hierarchical taxonomies. Default false.
   */
  childless?: Maybe<Scalars['Boolean']>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: Maybe<Scalars['Int']>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: Maybe<Scalars['String']>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /**
   * Array of term ids to exclude along with all of their descendant terms. If
   * $include is non-empty, $exclude_tree is ignored. Default empty array.
   */
  excludeTree?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: Maybe<Scalars['Boolean']>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: Maybe<Scalars['Boolean']>;
  /** Array of term ids to include. Default empty array. */
  include?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Array of names to return term(s) for. Default empty. */
  name?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: Maybe<Scalars['String']>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Direction the connection should be ordered in */
  order?: Maybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: Maybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: Maybe<Scalars['Boolean']>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: Maybe<Scalars['Int']>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: Maybe<Scalars['String']>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: Maybe<Scalars['Boolean']>;
};

/** Arguments for filtering the PostToTermNodeConnection connection */
export type PostToTermNodeConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: Maybe<Scalars['String']>;
  /**
   * True to limit results to terms that have no children. This parameter has no
   * effect on non-hierarchical taxonomies. Default false.
   */
  childless?: Maybe<Scalars['Boolean']>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: Maybe<Scalars['Int']>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: Maybe<Scalars['String']>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /**
   * Array of term ids to exclude along with all of their descendant terms. If
   * $include is non-empty, $exclude_tree is ignored. Default empty array.
   */
  excludeTree?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: Maybe<Scalars['Boolean']>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: Maybe<Scalars['Boolean']>;
  /** Array of term ids to include. Default empty array. */
  include?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Array of names to return term(s) for. Default empty. */
  name?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: Maybe<Scalars['String']>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Direction the connection should be ordered in */
  order?: Maybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: Maybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: Maybe<Scalars['Boolean']>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: Maybe<Scalars['Int']>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: Maybe<Scalars['String']>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** The Taxonomy to filter terms by */
  taxonomies?: Maybe<Array<Maybe<TaxonomyEnum>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: Maybe<Scalars['Boolean']>;
};

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum RecurringeventIdType {
  /** Identify a resource by the Database ID. */
  DatabaseId = 'DATABASE_ID',
  /** Identify a resource by the (hashed) Global ID. */
  Id = 'ID',
  /** Identify a resource by the slug. Available to non-hierarchcial Types where the slug is a unique identifier. */
  Slug = 'SLUG',
  /** Identify a resource by the URI. */
  Uri = 'URI',
}

/** Input for the refreshJwtAuthToken mutation */
export type RefreshJwtAuthTokenInput = {
  /**
   * This is an ID that can be passed to a mutation by the client to track the
   * progress of mutations and catch possible duplicate mutation submissions.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /**
   * A valid, previously issued JWT refresh token. If valid a new Auth token will
   * be provided. If invalid, expired, revoked or otherwise invalid, a new
   * AuthToken will not be provided.
   */
  jwtRefreshToken: Scalars['String'];
};

/** Input for the registerUser mutation */
export type RegisterUserInput = {
  /** User's AOL IM account. */
  aim?: Maybe<Scalars['String']>;
  /**
   * This is an ID that can be passed to a mutation by the client to track the
   * progress of mutations and catch possible duplicate mutation submissions.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** A string containing content about the user. */
  description?: Maybe<Scalars['String']>;
  /**
   * A string that will be shown on the site. Defaults to user's username. It is
   * likely that you will want to change this, for both appearance and security
   * through obscurity (that is if you dont use and delete the default admin user).
   */
  displayName?: Maybe<Scalars['String']>;
  /** A string containing the user's email address. */
  email?: Maybe<Scalars['String']>;
  /** 	The user's first name. */
  firstName?: Maybe<Scalars['String']>;
  /** User's Jabber account. */
  jabber?: Maybe<Scalars['String']>;
  /** The user's last name. */
  lastName?: Maybe<Scalars['String']>;
  /** User's locale. */
  locale?: Maybe<Scalars['String']>;
  /** A string that contains a URL-friendly name for the user. The default is the user's username. */
  nicename?: Maybe<Scalars['String']>;
  /** The user's nickname, defaults to the user's username. */
  nickname?: Maybe<Scalars['String']>;
  /** A string that contains the plain text password for the user. */
  password?: Maybe<Scalars['String']>;
  /** If true, this will refresh the users JWT secret. */
  refreshJwtUserSecret?: Maybe<Scalars['Boolean']>;
  /** The date the user registered. Format is Y-m-d H:i:s. */
  registered?: Maybe<Scalars['String']>;
  /**
   * If true, this will revoke the users JWT secret. If false, this will unrevoke
   * the JWT secret AND issue a new one. To revoke, the user must have proper
   * capabilities to edit users JWT secrets.
   */
  revokeJwtUserSecret?: Maybe<Scalars['Boolean']>;
  /** A string for whether to enable the rich editor or not. False if not empty. */
  richEditing?: Maybe<Scalars['String']>;
  /** A string that contains the user's username. */
  username: Scalars['String'];
  /** A string containing the user's URL for the user's web site. */
  websiteUrl?: Maybe<Scalars['String']>;
  /** User's Yahoo IM account. */
  yim?: Maybe<Scalars['String']>;
};

/** The logical relation between each item in the array when there are more than one. */
export enum RelationEnum {
  /** The logical AND condition returns true if both operands are true, otherwise, it returns false. */
  And = 'AND',
  /** The logical OR condition returns false if both operands are false, otherwise, it returns true. */
  Or = 'OR',
}

/** Input for the resetUserPassword mutation */
export type ResetUserPasswordInput = {
  /**
   * This is an ID that can be passed to a mutation by the client to track the
   * progress of mutations and catch possible duplicate mutation submissions.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Password reset key */
  key?: Maybe<Scalars['String']>;
  /** The user's login (username). */
  login?: Maybe<Scalars['String']>;
  /** The new password. */
  password?: Maybe<Scalars['String']>;
};

/** Input for the restoreComment mutation */
export type RestoreCommentInput = {
  /**
   * This is an ID that can be passed to a mutation by the client to track the
   * progress of mutations and catch possible duplicate mutation submissions.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The ID of the comment to be restored */
  id: Scalars['ID'];
};

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum RoomIdType {
  /** Identify a resource by the Database ID. */
  DatabaseId = 'DATABASE_ID',
  /** Identify a resource by the (hashed) Global ID. */
  Id = 'ID',
  /** Identify a resource by the URI. */
  Uri = 'URI',
}

/** Arguments for filtering the roomToRevisionConnection connection */
export type RoomToRevisionConnectionWhereArgs = {
  /** Filter the connection based on dates */
  dateQuery?: Maybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: Maybe<Scalars['Boolean']>;
  /** Specific ID of the object */
  id?: Maybe<Scalars['Int']>;
  /** Array of IDs for the objects to retrieve */
  in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: Maybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: Maybe<Scalars['String']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** What paramater to use to order the objects by. */
  orderby?: Maybe<Array<Maybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: Maybe<Scalars['ID']>;
  /** Specify objects whose parent is in an array */
  parentIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Show posts with a specific password. */
  password?: Maybe<Scalars['String']>;
  /** Show Posts based on a keyword search */
  search?: Maybe<Scalars['String']>;
  /** Retrieve posts where post status is in an array. */
  stati?: Maybe<Array<Maybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: Maybe<PostStatusEnum>;
  /** Title of the object */
  title?: Maybe<Scalars['String']>;
};

/** Arguments for filtering the RootQueryToActivitiesConnection connection */
export type RootQueryToActivitiesConnectionWhereArgs = {
  /** Filter the connection based on dates */
  dateQuery?: Maybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: Maybe<Scalars['Boolean']>;
  /** Specific ID of the object */
  id?: Maybe<Scalars['Int']>;
  /** Array of IDs for the objects to retrieve */
  in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: Maybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: Maybe<Scalars['String']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** What paramater to use to order the objects by. */
  orderby?: Maybe<Array<Maybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: Maybe<Scalars['ID']>;
  /** Specify objects whose parent is in an array */
  parentIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Show posts with a specific password. */
  password?: Maybe<Scalars['String']>;
  /** Show Posts based on a keyword search */
  search?: Maybe<Scalars['String']>;
  /** Retrieve posts where post status is in an array. */
  stati?: Maybe<Array<Maybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: Maybe<PostStatusEnum>;
  /** Title of the object */
  title?: Maybe<Scalars['String']>;
};

/** Arguments for filtering the RootQueryToCalendarConnection connection */
export type RootQueryToCalendarConnectionWhereArgs = {
  /** Filter the connection based on dates */
  dateQuery?: Maybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: Maybe<Scalars['Boolean']>;
  /** Specific ID of the object */
  id?: Maybe<Scalars['Int']>;
  /** Array of IDs for the objects to retrieve */
  in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: Maybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: Maybe<Scalars['String']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** What paramater to use to order the objects by. */
  orderby?: Maybe<Array<Maybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: Maybe<Scalars['ID']>;
  /** Specify objects whose parent is in an array */
  parentIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Show posts with a specific password. */
  password?: Maybe<Scalars['String']>;
  /** Show Posts based on a keyword search */
  search?: Maybe<Scalars['String']>;
  /** Retrieve posts where post status is in an array. */
  stati?: Maybe<Array<Maybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: Maybe<PostStatusEnum>;
  /** Title of the object */
  title?: Maybe<Scalars['String']>;
};

/** Arguments for filtering the RootQueryToCategoryConnection connection */
export type RootQueryToCategoryConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: Maybe<Scalars['String']>;
  /**
   * True to limit results to terms that have no children. This parameter has no
   * effect on non-hierarchical taxonomies. Default false.
   */
  childless?: Maybe<Scalars['Boolean']>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: Maybe<Scalars['Int']>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: Maybe<Scalars['String']>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /**
   * Array of term ids to exclude along with all of their descendant terms. If
   * $include is non-empty, $exclude_tree is ignored. Default empty array.
   */
  excludeTree?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: Maybe<Scalars['Boolean']>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: Maybe<Scalars['Boolean']>;
  /** Array of term ids to include. Default empty array. */
  include?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Array of names to return term(s) for. Default empty. */
  name?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: Maybe<Scalars['String']>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Direction the connection should be ordered in */
  order?: Maybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: Maybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: Maybe<Scalars['Boolean']>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: Maybe<Scalars['Int']>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: Maybe<Scalars['String']>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: Maybe<Scalars['Boolean']>;
};

/** Arguments for filtering the RootQueryToCommentConnection connection */
export type RootQueryToCommentConnectionWhereArgs = {
  /** Comment author email address. */
  authorEmail?: Maybe<Scalars['String']>;
  /** Array of author IDs to include comments for. */
  authorIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Array of author IDs to exclude comments for. */
  authorNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Comment author URL. */
  authorUrl?: Maybe<Scalars['String']>;
  /** Array of comment IDs to include. */
  commentIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Array of IDs of users whose unapproved comments will be returned by the query regardless of status. */
  commentNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Include comments of a given type. */
  commentType?: Maybe<Scalars['String']>;
  /** Include comments from a given array of comment types. */
  commentTypeIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Exclude comments from a given array of comment types. */
  commentTypeNotIn?: Maybe<Scalars['String']>;
  /** Content object author ID to limit results by. */
  contentAuthor?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Array of author IDs to retrieve comments for. */
  contentAuthorIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Array of author IDs *not* to retrieve comments for. */
  contentAuthorNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Limit results to those affiliated with a given content object ID. */
  contentId?: Maybe<Scalars['ID']>;
  /** Array of content object IDs to include affiliated comments for. */
  contentIdIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Array of content object IDs to exclude affiliated comments for. */
  contentIdNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Content object name to retrieve affiliated comments for. */
  contentName?: Maybe<Scalars['String']>;
  /** Content Object parent ID to retrieve affiliated comments for. */
  contentParent?: Maybe<Scalars['Int']>;
  /** Array of content object statuses to retrieve affiliated comments for. Pass 'any' to match any value. */
  contentStatus?: Maybe<Array<Maybe<PostStatusEnum>>>;
  /** Content object type or array of types to retrieve affiliated comments for. Pass 'any' to match any value. */
  contentType?: Maybe<Array<Maybe<ContentTypeEnum>>>;
  /**
   * Array of IDs or email addresses of users whose unapproved comments will be
   * returned by the query regardless of $status. Default empty
   */
  includeUnapproved?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Karma score to retrieve matching comments for. */
  karma?: Maybe<Scalars['Int']>;
  /** The cardinality of the order of the connection */
  order?: Maybe<OrderEnum>;
  /** Field to order the comments by. */
  orderby?: Maybe<CommentsConnectionOrderbyEnum>;
  /** Parent ID of comment to retrieve children of. */
  parent?: Maybe<Scalars['Int']>;
  /** Array of parent IDs of comments to retrieve children for. */
  parentIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Array of parent IDs of comments *not* to retrieve children for. */
  parentNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Search term(s) to retrieve matching comments for. */
  search?: Maybe<Scalars['String']>;
  /** Comment status to limit results by. */
  status?: Maybe<Scalars['String']>;
  /** Include comments for a specific user ID. */
  userId?: Maybe<Scalars['ID']>;
};

/** Arguments for filtering the RootQueryToContentNodeConnection connection */
export type RootQueryToContentNodeConnectionWhereArgs = {
  /** The Types of content to filter */
  contentTypes?: Maybe<Array<Maybe<ContentTypeEnum>>>;
  /** Filter the connection based on dates */
  dateQuery?: Maybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: Maybe<Scalars['Boolean']>;
  /** Specific ID of the object */
  id?: Maybe<Scalars['Int']>;
  /** Array of IDs for the objects to retrieve */
  in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: Maybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: Maybe<Scalars['String']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** What paramater to use to order the objects by. */
  orderby?: Maybe<Array<Maybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: Maybe<Scalars['ID']>;
  /** Specify objects whose parent is in an array */
  parentIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Show posts with a specific password. */
  password?: Maybe<Scalars['String']>;
  /** Show Posts based on a keyword search */
  search?: Maybe<Scalars['String']>;
  /** Retrieve posts where post status is in an array. */
  stati?: Maybe<Array<Maybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: Maybe<PostStatusEnum>;
  /** Title of the object */
  title?: Maybe<Scalars['String']>;
};

/** Arguments for filtering the RootQueryToContentRevisionUnionConnection connection */
export type RootQueryToContentRevisionUnionConnectionWhereArgs = {
  /** The Types of content to filter */
  contentTypes?: Maybe<Array<Maybe<ContentTypeEnum>>>;
  /** Filter the connection based on dates */
  dateQuery?: Maybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: Maybe<Scalars['Boolean']>;
  /** Specific ID of the object */
  id?: Maybe<Scalars['Int']>;
  /** Array of IDs for the objects to retrieve */
  in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: Maybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: Maybe<Scalars['String']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** What paramater to use to order the objects by. */
  orderby?: Maybe<Array<Maybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: Maybe<Scalars['ID']>;
  /** Specify objects whose parent is in an array */
  parentIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Show posts with a specific password. */
  password?: Maybe<Scalars['String']>;
  /** Show Posts based on a keyword search */
  search?: Maybe<Scalars['String']>;
  /** Retrieve posts where post status is in an array. */
  stati?: Maybe<Array<Maybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: Maybe<PostStatusEnum>;
  /** Title of the object */
  title?: Maybe<Scalars['String']>;
};

/** Arguments for filtering the RootQueryToHtml_menuConnection connection */
export type RootQueryToHtml_MenuConnectionWhereArgs = {
  /** Filter the connection based on dates */
  dateQuery?: Maybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: Maybe<Scalars['Boolean']>;
  /** Specific ID of the object */
  id?: Maybe<Scalars['Int']>;
  /** Array of IDs for the objects to retrieve */
  in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: Maybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: Maybe<Scalars['String']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** What paramater to use to order the objects by. */
  orderby?: Maybe<Array<Maybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: Maybe<Scalars['ID']>;
  /** Specify objects whose parent is in an array */
  parentIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Show posts with a specific password. */
  password?: Maybe<Scalars['String']>;
  /** Show Posts based on a keyword search */
  search?: Maybe<Scalars['String']>;
  /** Retrieve posts where post status is in an array. */
  stati?: Maybe<Array<Maybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: Maybe<PostStatusEnum>;
  /** Title of the object */
  title?: Maybe<Scalars['String']>;
};

/** Arguments for filtering the RootQueryToMedia_blockConnection connection */
export type RootQueryToMedia_BlockConnectionWhereArgs = {
  /** Filter the connection based on dates */
  dateQuery?: Maybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: Maybe<Scalars['Boolean']>;
  /** Specific ID of the object */
  id?: Maybe<Scalars['Int']>;
  /** Array of IDs for the objects to retrieve */
  in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: Maybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: Maybe<Scalars['String']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** What paramater to use to order the objects by. */
  orderby?: Maybe<Array<Maybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: Maybe<Scalars['ID']>;
  /** Specify objects whose parent is in an array */
  parentIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Show posts with a specific password. */
  password?: Maybe<Scalars['String']>;
  /** Show Posts based on a keyword search */
  search?: Maybe<Scalars['String']>;
  /** Retrieve posts where post status is in an array. */
  stati?: Maybe<Array<Maybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: Maybe<PostStatusEnum>;
  /** Title of the object */
  title?: Maybe<Scalars['String']>;
};

/** Arguments for filtering the RootQueryToMediaItemConnection connection */
export type RootQueryToMediaItemConnectionWhereArgs = {
  /** The user that's connected as the author of the object. Use the userId for the author object. */
  author?: Maybe<Scalars['Int']>;
  /** Find objects connected to author(s) in the array of author's userIds */
  authorIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Find objects connected to the author by the author's nicename */
  authorName?: Maybe<Scalars['String']>;
  /** Find objects NOT connected to author(s) in the array of author's userIds */
  authorNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Filter the connection based on dates */
  dateQuery?: Maybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: Maybe<Scalars['Boolean']>;
  /** Specific ID of the object */
  id?: Maybe<Scalars['Int']>;
  /** Array of IDs for the objects to retrieve */
  in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: Maybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: Maybe<Scalars['String']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** What paramater to use to order the objects by. */
  orderby?: Maybe<Array<Maybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: Maybe<Scalars['ID']>;
  /** Specify objects whose parent is in an array */
  parentIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Show posts with a specific password. */
  password?: Maybe<Scalars['String']>;
  /** Show Posts based on a keyword search */
  search?: Maybe<Scalars['String']>;
  /** Retrieve posts where post status is in an array. */
  stati?: Maybe<Array<Maybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: Maybe<PostStatusEnum>;
  /** Title of the object */
  title?: Maybe<Scalars['String']>;
};

/** Arguments for filtering the RootQueryToMenuConnection connection */
export type RootQueryToMenuConnectionWhereArgs = {
  /** The ID of the object */
  id?: Maybe<Scalars['Int']>;
  /** filters the menus by language */
  language?: Maybe<Scalars['String']>;
  /** The menu location for the menu being queried */
  location?: Maybe<MenuLocationEnum>;
  /** The slug of the menu to query items for */
  slug?: Maybe<Scalars['String']>;
};

/** Arguments for filtering the RootQueryToMenuItemConnection connection */
export type RootQueryToMenuItemConnectionWhereArgs = {
  /** The ID of the object */
  id?: Maybe<Scalars['Int']>;
  /** filters the menu items by language */
  language?: Maybe<Scalars['String']>;
  /** The menu location for the menu being queried */
  location?: Maybe<MenuLocationEnum>;
  /** The database ID of the parent menu object */
  parentDatabaseId?: Maybe<Scalars['Int']>;
  /** The ID of the parent menu object */
  parentId?: Maybe<Scalars['ID']>;
};

/** Arguments for filtering the RootQueryToPageConnection connection */
export type RootQueryToPageConnectionWhereArgs = {
  /** The user that's connected as the author of the object. Use the userId for the author object. */
  author?: Maybe<Scalars['Int']>;
  /** Find objects connected to author(s) in the array of author's userIds */
  authorIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Find objects connected to the author by the author's nicename */
  authorName?: Maybe<Scalars['String']>;
  /** Find objects NOT connected to author(s) in the array of author's userIds */
  authorNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Filter the connection based on dates */
  dateQuery?: Maybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: Maybe<Scalars['Boolean']>;
  /** Specific ID of the object */
  id?: Maybe<Scalars['Int']>;
  /** Array of IDs for the objects to retrieve */
  in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: Maybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: Maybe<Scalars['String']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** What paramater to use to order the objects by. */
  orderby?: Maybe<Array<Maybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: Maybe<Scalars['ID']>;
  /** Specify objects whose parent is in an array */
  parentIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Show posts with a specific password. */
  password?: Maybe<Scalars['String']>;
  /** Show Posts based on a keyword search */
  search?: Maybe<Scalars['String']>;
  /** Retrieve posts where post status is in an array. */
  stati?: Maybe<Array<Maybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: Maybe<PostStatusEnum>;
  /** Title of the object */
  title?: Maybe<Scalars['String']>;
};

/** Arguments for filtering the RootQueryToPostConnection connection */
export type RootQueryToPostConnectionWhereArgs = {
  /** The user that's connected as the author of the object. Use the userId for the author object. */
  author?: Maybe<Scalars['Int']>;
  /** Find objects connected to author(s) in the array of author's userIds */
  authorIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Find objects connected to the author by the author's nicename */
  authorName?: Maybe<Scalars['String']>;
  /** Find objects NOT connected to author(s) in the array of author's userIds */
  authorNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Category ID */
  categoryId?: Maybe<Scalars['Int']>;
  /** Array of category IDs, used to display objects from one category OR another */
  categoryIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Use Category Slug */
  categoryName?: Maybe<Scalars['String']>;
  /** Array of category IDs, used to display objects from one category OR another */
  categoryNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Filter the connection based on dates */
  dateQuery?: Maybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: Maybe<Scalars['Boolean']>;
  /** Specific ID of the object */
  id?: Maybe<Scalars['Int']>;
  /** Array of IDs for the objects to retrieve */
  in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: Maybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: Maybe<Scalars['String']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** What paramater to use to order the objects by. */
  orderby?: Maybe<Array<Maybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: Maybe<Scalars['ID']>;
  /** Specify objects whose parent is in an array */
  parentIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Show posts with a specific password. */
  password?: Maybe<Scalars['String']>;
  /** Show Posts based on a keyword search */
  search?: Maybe<Scalars['String']>;
  /** Retrieve posts where post status is in an array. */
  stati?: Maybe<Array<Maybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: Maybe<PostStatusEnum>;
  /** Tag Slug */
  tag?: Maybe<Scalars['String']>;
  /** Use Tag ID */
  tagId?: Maybe<Scalars['String']>;
  /** Array of tag IDs, used to display objects from one tag OR another */
  tagIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Array of tag IDs, used to display objects from one tag OR another */
  tagNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Array of tag slugs, used to display objects from one tag OR another */
  tagSlugAnd?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Array of tag slugs, used to exclude objects in specified tags */
  tagSlugIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Title of the object */
  title?: Maybe<Scalars['String']>;
};

/** Arguments for filtering the RootQueryToPostFormatConnection connection */
export type RootQueryToPostFormatConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: Maybe<Scalars['String']>;
  /**
   * True to limit results to terms that have no children. This parameter has no
   * effect on non-hierarchical taxonomies. Default false.
   */
  childless?: Maybe<Scalars['Boolean']>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: Maybe<Scalars['Int']>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: Maybe<Scalars['String']>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /**
   * Array of term ids to exclude along with all of their descendant terms. If
   * $include is non-empty, $exclude_tree is ignored. Default empty array.
   */
  excludeTree?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: Maybe<Scalars['Boolean']>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: Maybe<Scalars['Boolean']>;
  /** Array of term ids to include. Default empty array. */
  include?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Array of names to return term(s) for. Default empty. */
  name?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: Maybe<Scalars['String']>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Direction the connection should be ordered in */
  order?: Maybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: Maybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: Maybe<Scalars['Boolean']>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: Maybe<Scalars['Int']>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: Maybe<Scalars['String']>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: Maybe<Scalars['Boolean']>;
};

/** Arguments for filtering the RootQueryToRecurringeventConnection connection */
export type RootQueryToRecurringeventConnectionWhereArgs = {
  /** Filter the connection based on dates */
  dateQuery?: Maybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: Maybe<Scalars['Boolean']>;
  /** Specific ID of the object */
  id?: Maybe<Scalars['Int']>;
  /** Array of IDs for the objects to retrieve */
  in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: Maybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: Maybe<Scalars['String']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** What paramater to use to order the objects by. */
  orderby?: Maybe<Array<Maybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: Maybe<Scalars['ID']>;
  /** Specify objects whose parent is in an array */
  parentIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Show posts with a specific password. */
  password?: Maybe<Scalars['String']>;
  /** Show Posts based on a keyword search */
  search?: Maybe<Scalars['String']>;
  /** Retrieve posts where post status is in an array. */
  stati?: Maybe<Array<Maybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: Maybe<PostStatusEnum>;
  /** Title of the object */
  title?: Maybe<Scalars['String']>;
};

/** Arguments for filtering the RootQueryToRoomConnection connection */
export type RootQueryToRoomConnectionWhereArgs = {
  /** Filter the connection based on dates */
  dateQuery?: Maybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: Maybe<Scalars['Boolean']>;
  /** Specific ID of the object */
  id?: Maybe<Scalars['Int']>;
  /** Array of IDs for the objects to retrieve */
  in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: Maybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: Maybe<Scalars['String']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** What paramater to use to order the objects by. */
  orderby?: Maybe<Array<Maybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: Maybe<Scalars['ID']>;
  /** Specify objects whose parent is in an array */
  parentIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Show posts with a specific password. */
  password?: Maybe<Scalars['String']>;
  /** Show Posts based on a keyword search */
  search?: Maybe<Scalars['String']>;
  /** Retrieve posts where post status is in an array. */
  stati?: Maybe<Array<Maybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: Maybe<PostStatusEnum>;
  /** Title of the object */
  title?: Maybe<Scalars['String']>;
};

/** Arguments for filtering the RootQueryToSpecialofferConnection connection */
export type RootQueryToSpecialofferConnectionWhereArgs = {
  /** Filter the connection based on dates */
  dateQuery?: Maybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: Maybe<Scalars['Boolean']>;
  /** Specific ID of the object */
  id?: Maybe<Scalars['Int']>;
  /** Array of IDs for the objects to retrieve */
  in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: Maybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: Maybe<Scalars['String']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** What paramater to use to order the objects by. */
  orderby?: Maybe<Array<Maybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: Maybe<Scalars['ID']>;
  /** Specify objects whose parent is in an array */
  parentIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Show posts with a specific password. */
  password?: Maybe<Scalars['String']>;
  /** Show Posts based on a keyword search */
  search?: Maybe<Scalars['String']>;
  /** Retrieve posts where post status is in an array. */
  stati?: Maybe<Array<Maybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: Maybe<PostStatusEnum>;
  /** Tag Slug */
  tag?: Maybe<Scalars['String']>;
  /** Use Tag ID */
  tagId?: Maybe<Scalars['String']>;
  /** Array of tag IDs, used to display objects from one tag OR another */
  tagIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Array of tag IDs, used to display objects from one tag OR another */
  tagNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Array of tag slugs, used to display objects from one tag OR another */
  tagSlugAnd?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Array of tag slugs, used to exclude objects in specified tags */
  tagSlugIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Title of the object */
  title?: Maybe<Scalars['String']>;
};

/** Arguments for filtering the RootQueryToTagConnection connection */
export type RootQueryToTagConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: Maybe<Scalars['String']>;
  /**
   * True to limit results to terms that have no children. This parameter has no
   * effect on non-hierarchical taxonomies. Default false.
   */
  childless?: Maybe<Scalars['Boolean']>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: Maybe<Scalars['Int']>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: Maybe<Scalars['String']>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /**
   * Array of term ids to exclude along with all of their descendant terms. If
   * $include is non-empty, $exclude_tree is ignored. Default empty array.
   */
  excludeTree?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: Maybe<Scalars['Boolean']>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: Maybe<Scalars['Boolean']>;
  /** Array of term ids to include. Default empty array. */
  include?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Array of names to return term(s) for. Default empty. */
  name?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: Maybe<Scalars['String']>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Direction the connection should be ordered in */
  order?: Maybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: Maybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: Maybe<Scalars['Boolean']>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: Maybe<Scalars['Int']>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: Maybe<Scalars['String']>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: Maybe<Scalars['Boolean']>;
};

/** Arguments for filtering the RootQueryToTermNodeConnection connection */
export type RootQueryToTermNodeConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: Maybe<Scalars['String']>;
  /**
   * True to limit results to terms that have no children. This parameter has no
   * effect on non-hierarchical taxonomies. Default false.
   */
  childless?: Maybe<Scalars['Boolean']>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: Maybe<Scalars['Int']>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: Maybe<Scalars['String']>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /**
   * Array of term ids to exclude along with all of their descendant terms. If
   * $include is non-empty, $exclude_tree is ignored. Default empty array.
   */
  excludeTree?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: Maybe<Scalars['Boolean']>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: Maybe<Scalars['Boolean']>;
  /** Array of term ids to include. Default empty array. */
  include?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Array of names to return term(s) for. Default empty. */
  name?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: Maybe<Scalars['String']>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Direction the connection should be ordered in */
  order?: Maybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: Maybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: Maybe<Scalars['Boolean']>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: Maybe<Scalars['Int']>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: Maybe<Scalars['String']>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** The Taxonomy to filter terms by */
  taxonomies?: Maybe<Array<Maybe<TaxonomyEnum>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: Maybe<Scalars['Boolean']>;
};

/** Arguments for filtering the RootQueryToUserConnection connection */
export type RootQueryToUserConnectionWhereArgs = {
  /** Array of userIds to exclude. */
  exclude?: Maybe<Array<Maybe<Scalars['Int']>>>;
  /** Pass an array of post types to filter results to users who have published posts in those post types. */
  hasPublishedPosts?: Maybe<Array<Maybe<ContentTypeEnum>>>;
  /** Array of userIds to include. */
  include?: Maybe<Array<Maybe<Scalars['Int']>>>;
  /** The user login. */
  login?: Maybe<Scalars['String']>;
  /** An array of logins to include. Users matching one of these logins will be included in results. */
  loginIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** An array of logins to exclude. Users matching one of these logins will not be included in results. */
  loginNotIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** The user nicename. */
  nicename?: Maybe<Scalars['String']>;
  /** An array of nicenames to include. Users matching one of these nicenames will be included in results. */
  nicenameIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** An array of nicenames to exclude. Users matching one of these nicenames will not be included in results. */
  nicenameNotIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** What paramater to use to order the objects by. */
  orderby?: Maybe<Array<Maybe<UsersConnectionOrderbyInput>>>;
  /**
   * An array of role names that users must match to be included in results. Note
   * that this is an inclusive list: users must match *each* role.
   */
  role?: Maybe<UserRoleEnum>;
  /** An array of role names. Matched users must have at least one of these roles. */
  roleIn?: Maybe<Array<Maybe<UserRoleEnum>>>;
  /** An array of role names to exclude. Users matching one or more of these roles will not be included in results. */
  roleNotIn?: Maybe<Array<Maybe<UserRoleEnum>>>;
  /**
   * Search keyword. Searches for possible string matches on columns. When
   * "searchColumns" is left empty, it tries to determine which column to search in
   * based on search string.
   */
  search?: Maybe<Scalars['String']>;
  /** Array of column names to be searched. Accepts 'ID', 'login', 'nicename', 'email', 'url'. */
  searchColumns?: Maybe<Array<Maybe<UsersConnectionSearchColumnEnum>>>;
};

/** Arguments for filtering the RootQueryToWeddingbookingConnection connection */
export type RootQueryToWeddingbookingConnectionWhereArgs = {
  /** Filter the connection based on dates */
  dateQuery?: Maybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: Maybe<Scalars['Boolean']>;
  /** Specific ID of the object */
  id?: Maybe<Scalars['Int']>;
  /** Array of IDs for the objects to retrieve */
  in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** The maximum timestamp to include */
  maxTimestamp?: Maybe<Scalars['Float']>;
  /** Get objects with a specific mimeType property */
  mimeType?: Maybe<MimeTypeEnum>;
  /** The minimum timestamp to include */
  minTimestamp?: Maybe<Scalars['Float']>;
  /** Slug / post_name of the object */
  name?: Maybe<Scalars['String']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** What paramater to use to order the objects by. */
  orderby?: Maybe<Array<Maybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: Maybe<Scalars['ID']>;
  /** Specify objects whose parent is in an array */
  parentIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Show posts with a specific password. */
  password?: Maybe<Scalars['String']>;
  /** Show Posts based on a keyword search */
  search?: Maybe<Scalars['String']>;
  /** Retrieve posts where post status is in an array. */
  stati?: Maybe<Array<Maybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: Maybe<PostStatusEnum>;
  /** The exact timestamp to include */
  timestamp?: Maybe<Scalars['Float']>;
  /** Title of the object */
  title?: Maybe<Scalars['String']>;
};

/** Input for the sendPasswordResetEmail mutation */
export type SendPasswordResetEmailInput = {
  /**
   * This is an ID that can be passed to a mutation by the client to track the
   * progress of mutations and catch possible duplicate mutation submissions.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** A string that contains the user's username or email address. */
  username: Scalars['String'];
};

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum SpecialofferIdType {
  /** Identify a resource by the Database ID. */
  DatabaseId = 'DATABASE_ID',
  /** Identify a resource by the (hashed) Global ID. */
  Id = 'ID',
  /** Identify a resource by the URI. */
  Uri = 'URI',
}

/** Set relationships between the specialoffer to tags */
export type SpecialofferTagsInput = {
  /**
   * If true, this will append the tag to existing related tags. If false, this
   * will replace existing relationships. Default true.
   */
  append?: Maybe<Scalars['Boolean']>;
  /** The input list of items to set. */
  nodes?: Maybe<Array<Maybe<SpecialofferTagsNodeInput>>>;
};

/**
 * List of tags to connect the specialoffer to. If an ID is set, it will be used to
 * create the connection. If not, it will look for a slug. If neither are valid
 * existing terms, and the site is configured to allow terms to be created during
 * post mutations, a term will be created using the Name if it exists in the input,
 * then fallback to the slug if it exists.
 */
export type SpecialofferTagsNodeInput = {
  /**
   * The description of the tag. This field is used to set a description of the tag
   * if a new one is created during the mutation.
   */
  description?: Maybe<Scalars['String']>;
  /**
   * The ID of the tag. If present, this will be used to connect to the
   * specialoffer. If no existing tag exists with this ID, no connection will be made.
   */
  id?: Maybe<Scalars['ID']>;
  /**
   * The name of the tag. This field is used to create a new term, if term creation
   * is enabled in nested mutations, and if one does not already exist with the
   * provided slug or ID or if a slug or ID is not provided. If no name is included
   * and a term is created, the creation will fallback to the slug field.
   */
  name?: Maybe<Scalars['String']>;
  /**
   * The slug of the tag. If no ID is present, this field will be used to make a
   * connection. If no existing term exists with this slug, this field will be used
   * as a fallback to the Name field when creating a new term to connect to, if
   * term creation is enabled as a nested mutation.
   */
  slug?: Maybe<Scalars['String']>;
};

/** Arguments for filtering the specialofferToRevisionConnection connection */
export type SpecialofferToRevisionConnectionWhereArgs = {
  /** Filter the connection based on dates */
  dateQuery?: Maybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: Maybe<Scalars['Boolean']>;
  /** Specific ID of the object */
  id?: Maybe<Scalars['Int']>;
  /** Array of IDs for the objects to retrieve */
  in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: Maybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: Maybe<Scalars['String']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** What paramater to use to order the objects by. */
  orderby?: Maybe<Array<Maybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: Maybe<Scalars['ID']>;
  /** Specify objects whose parent is in an array */
  parentIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Show posts with a specific password. */
  password?: Maybe<Scalars['String']>;
  /** Show Posts based on a keyword search */
  search?: Maybe<Scalars['String']>;
  /** Retrieve posts where post status is in an array. */
  stati?: Maybe<Array<Maybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: Maybe<PostStatusEnum>;
  /** Tag Slug */
  tag?: Maybe<Scalars['String']>;
  /** Use Tag ID */
  tagId?: Maybe<Scalars['String']>;
  /** Array of tag IDs, used to display objects from one tag OR another */
  tagIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Array of tag IDs, used to display objects from one tag OR another */
  tagNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Array of tag slugs, used to display objects from one tag OR another */
  tagSlugAnd?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Array of tag slugs, used to exclude objects in specified tags */
  tagSlugIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Title of the object */
  title?: Maybe<Scalars['String']>;
};

/** Arguments for filtering the SpecialofferToTagConnection connection */
export type SpecialofferToTagConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: Maybe<Scalars['String']>;
  /**
   * True to limit results to terms that have no children. This parameter has no
   * effect on non-hierarchical taxonomies. Default false.
   */
  childless?: Maybe<Scalars['Boolean']>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: Maybe<Scalars['Int']>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: Maybe<Scalars['String']>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /**
   * Array of term ids to exclude along with all of their descendant terms. If
   * $include is non-empty, $exclude_tree is ignored. Default empty array.
   */
  excludeTree?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: Maybe<Scalars['Boolean']>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: Maybe<Scalars['Boolean']>;
  /** Array of term ids to include. Default empty array. */
  include?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Array of names to return term(s) for. Default empty. */
  name?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: Maybe<Scalars['String']>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Direction the connection should be ordered in */
  order?: Maybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: Maybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: Maybe<Scalars['Boolean']>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: Maybe<Scalars['Int']>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: Maybe<Scalars['String']>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: Maybe<Scalars['Boolean']>;
};

/** Arguments for filtering the SpecialofferToTermNodeConnection connection */
export type SpecialofferToTermNodeConnectionWhereArgs = {
  /** Unique cache key to be produced when this query is stored in an object cache. Default is 'core'. */
  cacheDomain?: Maybe<Scalars['String']>;
  /**
   * True to limit results to terms that have no children. This parameter has no
   * effect on non-hierarchical taxonomies. Default false.
   */
  childless?: Maybe<Scalars['Boolean']>;
  /** Term ID to retrieve child terms of. If multiple taxonomies are passed, $child_of is ignored. Default 0. */
  childOf?: Maybe<Scalars['Int']>;
  /** Retrieve terms where the description is LIKE the input value. Default empty. */
  descriptionLike?: Maybe<Scalars['String']>;
  /** Array of term ids to exclude. If $include is non-empty, $exclude is ignored. Default empty array. */
  exclude?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /**
   * Array of term ids to exclude along with all of their descendant terms. If
   * $include is non-empty, $exclude_tree is ignored. Default empty array.
   */
  excludeTree?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Whether to hide terms not assigned to any posts. Accepts true or false. Default false */
  hideEmpty?: Maybe<Scalars['Boolean']>;
  /** Whether to include terms that have non-empty descendants (even if $hide_empty is set to true). Default true. */
  hierarchical?: Maybe<Scalars['Boolean']>;
  /** Array of term ids to include. Default empty array. */
  include?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Array of names to return term(s) for. Default empty. */
  name?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Retrieve terms where the name is LIKE the input value. Default empty. */
  nameLike?: Maybe<Scalars['String']>;
  /** Array of object IDs. Results will be limited to terms associated with these objects. */
  objectIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Direction the connection should be ordered in */
  order?: Maybe<OrderEnum>;
  /** Field(s) to order terms by. Defaults to 'name'. */
  orderby?: Maybe<TermObjectsConnectionOrderbyEnum>;
  /** Whether to pad the quantity of a term's children in the quantity of each term's "count" object variable. Default false. */
  padCounts?: Maybe<Scalars['Boolean']>;
  /** Parent term ID to retrieve direct-child terms of. Default empty. */
  parent?: Maybe<Scalars['Int']>;
  /** Search criteria to match terms. Will be SQL-formatted with wildcards before and after. Default empty. */
  search?: Maybe<Scalars['String']>;
  /** Array of slugs to return term(s) for. Default empty. */
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** The Taxonomy to filter terms by */
  taxonomies?: Maybe<Array<Maybe<TaxonomyEnum>>>;
  /** Array of term taxonomy IDs, to match when querying terms. */
  termTaxonomId?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Whether to prime meta caches for matched terms. Default true. */
  updateTermMetaCache?: Maybe<Scalars['Boolean']>;
};

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum TagIdType {
  /** The Database ID for the node */
  DatabaseId = 'DATABASE_ID',
  /** The hashed Global ID */
  Id = 'ID',
  /** The name of the node */
  Name = 'NAME',
  /** Url friendly name of the node */
  Slug = 'SLUG',
  /** The URI for the node */
  Uri = 'URI',
}

/** Arguments for filtering the TagToContentNodeConnection connection */
export type TagToContentNodeConnectionWhereArgs = {
  /** The Types of content to filter */
  contentTypes?: Maybe<Array<Maybe<ContentTypesOfTagEnum>>>;
  /** Filter the connection based on dates */
  dateQuery?: Maybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: Maybe<Scalars['Boolean']>;
  /** Specific ID of the object */
  id?: Maybe<Scalars['Int']>;
  /** Array of IDs for the objects to retrieve */
  in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: Maybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: Maybe<Scalars['String']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** What paramater to use to order the objects by. */
  orderby?: Maybe<Array<Maybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: Maybe<Scalars['ID']>;
  /** Specify objects whose parent is in an array */
  parentIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Show posts with a specific password. */
  password?: Maybe<Scalars['String']>;
  /** Show Posts based on a keyword search */
  search?: Maybe<Scalars['String']>;
  /** Retrieve posts where post status is in an array. */
  stati?: Maybe<Array<Maybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: Maybe<PostStatusEnum>;
  /** Title of the object */
  title?: Maybe<Scalars['String']>;
};

/** Arguments for filtering the TagToPostConnection connection */
export type TagToPostConnectionWhereArgs = {
  /** The user that's connected as the author of the object. Use the userId for the author object. */
  author?: Maybe<Scalars['Int']>;
  /** Find objects connected to author(s) in the array of author's userIds */
  authorIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Find objects connected to the author by the author's nicename */
  authorName?: Maybe<Scalars['String']>;
  /** Find objects NOT connected to author(s) in the array of author's userIds */
  authorNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Category ID */
  categoryId?: Maybe<Scalars['Int']>;
  /** Array of category IDs, used to display objects from one category OR another */
  categoryIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Use Category Slug */
  categoryName?: Maybe<Scalars['String']>;
  /** Array of category IDs, used to display objects from one category OR another */
  categoryNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Filter the connection based on dates */
  dateQuery?: Maybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: Maybe<Scalars['Boolean']>;
  /** Specific ID of the object */
  id?: Maybe<Scalars['Int']>;
  /** Array of IDs for the objects to retrieve */
  in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: Maybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: Maybe<Scalars['String']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** What paramater to use to order the objects by. */
  orderby?: Maybe<Array<Maybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: Maybe<Scalars['ID']>;
  /** Specify objects whose parent is in an array */
  parentIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Show posts with a specific password. */
  password?: Maybe<Scalars['String']>;
  /** Show Posts based on a keyword search */
  search?: Maybe<Scalars['String']>;
  /** Retrieve posts where post status is in an array. */
  stati?: Maybe<Array<Maybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: Maybe<PostStatusEnum>;
  /** Tag Slug */
  tag?: Maybe<Scalars['String']>;
  /** Use Tag ID */
  tagId?: Maybe<Scalars['String']>;
  /** Array of tag IDs, used to display objects from one tag OR another */
  tagIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Array of tag IDs, used to display objects from one tag OR another */
  tagNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Array of tag slugs, used to display objects from one tag OR another */
  tagSlugAnd?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Array of tag slugs, used to exclude objects in specified tags */
  tagSlugIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Title of the object */
  title?: Maybe<Scalars['String']>;
};

/** Arguments for filtering the TagToSpecialofferConnection connection */
export type TagToSpecialofferConnectionWhereArgs = {
  /** Filter the connection based on dates */
  dateQuery?: Maybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: Maybe<Scalars['Boolean']>;
  /** Specific ID of the object */
  id?: Maybe<Scalars['Int']>;
  /** Array of IDs for the objects to retrieve */
  in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: Maybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: Maybe<Scalars['String']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** What paramater to use to order the objects by. */
  orderby?: Maybe<Array<Maybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: Maybe<Scalars['ID']>;
  /** Specify objects whose parent is in an array */
  parentIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Show posts with a specific password. */
  password?: Maybe<Scalars['String']>;
  /** Show Posts based on a keyword search */
  search?: Maybe<Scalars['String']>;
  /** Retrieve posts where post status is in an array. */
  stati?: Maybe<Array<Maybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: Maybe<PostStatusEnum>;
  /** Tag Slug */
  tag?: Maybe<Scalars['String']>;
  /** Use Tag ID */
  tagId?: Maybe<Scalars['String']>;
  /** Array of tag IDs, used to display objects from one tag OR another */
  tagIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Array of tag IDs, used to display objects from one tag OR another */
  tagNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Array of tag slugs, used to display objects from one tag OR another */
  tagSlugAnd?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Array of tag slugs, used to exclude objects in specified tags */
  tagSlugIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Title of the object */
  title?: Maybe<Scalars['String']>;
};

/** Allowed taxonomies */
export enum TaxonomyEnum {
  /** Taxonomy enum category */
  Category = 'CATEGORY',
  /** Taxonomy enum post_format */
  Postformat = 'POSTFORMAT',
  /** Taxonomy enum post_tag */
  Tag = 'TAG',
}

/** The Type of Identifier used to fetch a single Taxonomy node. To be used along with the "id" field. Default is "ID". */
export enum TaxonomyIdTypeEnum {
  /** The globally unique ID */
  Id = 'ID',
  /** The name of the taxonomy */
  Name = 'NAME',
}

/** The Type of Identifier used to fetch a single resource. Default is "ID". To be used along with the "id" field. */
export enum TermNodeIdTypeEnum {
  /** The Database ID for the node */
  DatabaseId = 'DATABASE_ID',
  /** The hashed Global ID */
  Id = 'ID',
  /** The name of the node */
  Name = 'NAME',
  /** Url friendly name of the node */
  Slug = 'SLUG',
  /** The URI for the node */
  Uri = 'URI',
}

/** Options for ordering the connection by */
export enum TermObjectsConnectionOrderbyEnum {
  /** Order the connection by item count. */
  Count = 'COUNT',
  /** Order the connection by description. */
  Description = 'DESCRIPTION',
  /** Order the connection by name. */
  Name = 'NAME',
  /** Order the connection by slug. */
  Slug = 'SLUG',
  /** Order the connection by term group. */
  TermGroup = 'TERM_GROUP',
  /** Order the connection by term id. */
  TermId = 'TERM_ID',
  /** Order the connection by term order. */
  TermOrder = 'TERM_ORDER',
}

/** Input for the updateActivities mutation */
export type UpdateActivitiesInput = {
  /**
   * This is an ID that can be passed to a mutation by the client to track the
   * progress of mutations and catch possible duplicate mutation submissions.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The content of the object */
  content?: Maybe<Scalars['String']>;
  /**
   * The date of the object. Preferable to enter as year/month/day (e.g.
   * 01/31/2017) as it will rearrange date as fit if it is not specified.
   * Incomplete dates may have unintended results for example, "2017" as the input
   * will use current date with timestamp 20:17
   */
  date?: Maybe<Scalars['String']>;
  /** The ID of the activities object */
  id: Scalars['ID'];
  /**
   * A field used for ordering posts. This is typically used with nav menu items or
   * for special ordering of hierarchical content types.
   */
  menuOrder?: Maybe<Scalars['Int']>;
  /** The ID of the parent object */
  parentId?: Maybe<Scalars['ID']>;
  /** The password used to protect the content of the object */
  password?: Maybe<Scalars['String']>;
  /** The slug of the object */
  slug?: Maybe<Scalars['String']>;
  /** The status of the object */
  status?: Maybe<PostStatusEnum>;
  /** The title of the object */
  title?: Maybe<Scalars['String']>;
};

/** Input for the updateCalendar mutation */
export type UpdateCalendarInput = {
  /**
   * This is an ID that can be passed to a mutation by the client to track the
   * progress of mutations and catch possible duplicate mutation submissions.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The content of the object */
  content?: Maybe<Scalars['String']>;
  /**
   * The date of the object. Preferable to enter as year/month/day (e.g.
   * 01/31/2017) as it will rearrange date as fit if it is not specified.
   * Incomplete dates may have unintended results for example, "2017" as the input
   * will use current date with timestamp 20:17
   */
  date?: Maybe<Scalars['String']>;
  /** The ID of the calendar object */
  id: Scalars['ID'];
  /**
   * A field used for ordering posts. This is typically used with nav menu items or
   * for special ordering of hierarchical content types.
   */
  menuOrder?: Maybe<Scalars['Int']>;
  /** The password used to protect the content of the object */
  password?: Maybe<Scalars['String']>;
  /** The slug of the object */
  slug?: Maybe<Scalars['String']>;
  /** The status of the object */
  status?: Maybe<PostStatusEnum>;
  /** The title of the object */
  title?: Maybe<Scalars['String']>;
};

/** Input for the UpdateCategory mutation */
export type UpdateCategoryInput = {
  /** The slug that the category will be an alias of */
  aliasOf?: Maybe<Scalars['String']>;
  /**
   * This is an ID that can be passed to a mutation by the client to track the
   * progress of mutations and catch possible duplicate mutation submissions.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The description of the category object */
  description?: Maybe<Scalars['String']>;
  /** The ID of the category object to update */
  id: Scalars['ID'];
  /** The name of the category object to mutate */
  name?: Maybe<Scalars['String']>;
  /** The ID of the category that should be set as the parent */
  parentId?: Maybe<Scalars['ID']>;
  /**
   * If this argument exists then the slug will be checked to see if it is not an
   * existing valid term. If that check succeeds (it is not a valid term), then it
   * is added and the term id is given. If it fails, then a check is made to
   * whether the taxonomy is hierarchical and the parent argument is not empty. If
   * the second check succeeds, the term will be inserted and the term id will be
   * given. If the slug argument is empty, then it will be calculated from the term name.
   */
  slug?: Maybe<Scalars['String']>;
};

/** Input for the updateComment mutation */
export type UpdateCommentInput = {
  /** The approval status of the comment. */
  approved?: Maybe<Scalars['String']>;
  /** The name of the comment's author. */
  author?: Maybe<Scalars['String']>;
  /** The email of the comment's author. */
  authorEmail?: Maybe<Scalars['String']>;
  /** The url of the comment's author. */
  authorUrl?: Maybe<Scalars['String']>;
  /**
   * This is an ID that can be passed to a mutation by the client to track the
   * progress of mutations and catch possible duplicate mutation submissions.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The ID of the post object the comment belongs to. */
  commentOn?: Maybe<Scalars['Int']>;
  /** Content of the comment. */
  content?: Maybe<Scalars['String']>;
  /**
   * The date of the object. Preferable to enter as year/month/day ( e.g.
   * 01/31/2017 ) as it will rearrange date as fit if it is not specified.
   * Incomplete dates may have unintended results for example, "2017" as the input
   * will use current date with timestamp 20:17
   */
  date?: Maybe<Scalars['String']>;
  /** The ID of the comment being updated. */
  id: Scalars['ID'];
  /** Parent comment of current comment. */
  parent?: Maybe<Scalars['ID']>;
  /** Type of comment. */
  type?: Maybe<Scalars['String']>;
};

/** Input for the updateHtml_menu mutation */
export type UpdateHtml_MenuInput = {
  /**
   * This is an ID that can be passed to a mutation by the client to track the
   * progress of mutations and catch possible duplicate mutation submissions.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The content of the object */
  content?: Maybe<Scalars['String']>;
  /**
   * The date of the object. Preferable to enter as year/month/day (e.g.
   * 01/31/2017) as it will rearrange date as fit if it is not specified.
   * Incomplete dates may have unintended results for example, "2017" as the input
   * will use current date with timestamp 20:17
   */
  date?: Maybe<Scalars['String']>;
  /** The ID of the html_menu object */
  id: Scalars['ID'];
  /**
   * A field used for ordering posts. This is typically used with nav menu items or
   * for special ordering of hierarchical content types.
   */
  menuOrder?: Maybe<Scalars['Int']>;
  /** The password used to protect the content of the object */
  password?: Maybe<Scalars['String']>;
  /** The slug of the object */
  slug?: Maybe<Scalars['String']>;
  /** The status of the object */
  status?: Maybe<PostStatusEnum>;
  /** The title of the object */
  title?: Maybe<Scalars['String']>;
};

/** Input for the updateMedia_block mutation */
export type UpdateMedia_BlockInput = {
  /**
   * This is an ID that can be passed to a mutation by the client to track the
   * progress of mutations and catch possible duplicate mutation submissions.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The content of the object */
  content?: Maybe<Scalars['String']>;
  /**
   * The date of the object. Preferable to enter as year/month/day (e.g.
   * 01/31/2017) as it will rearrange date as fit if it is not specified.
   * Incomplete dates may have unintended results for example, "2017" as the input
   * will use current date with timestamp 20:17
   */
  date?: Maybe<Scalars['String']>;
  /** The ID of the media_block object */
  id: Scalars['ID'];
  /**
   * A field used for ordering posts. This is typically used with nav menu items or
   * for special ordering of hierarchical content types.
   */
  menuOrder?: Maybe<Scalars['Int']>;
  /** The password used to protect the content of the object */
  password?: Maybe<Scalars['String']>;
  /** The slug of the object */
  slug?: Maybe<Scalars['String']>;
  /** The status of the object */
  status?: Maybe<PostStatusEnum>;
  /** The title of the object */
  title?: Maybe<Scalars['String']>;
};

/** Input for the updateMediaItem mutation */
export type UpdateMediaItemInput = {
  /** Alternative text to display when mediaItem is not displayed */
  altText?: Maybe<Scalars['String']>;
  /** The userId to assign as the author of the mediaItem */
  authorId?: Maybe<Scalars['ID']>;
  /** The caption for the mediaItem */
  caption?: Maybe<Scalars['String']>;
  /**
   * This is an ID that can be passed to a mutation by the client to track the
   * progress of mutations and catch possible duplicate mutation submissions.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The comment status for the mediaItem */
  commentStatus?: Maybe<Scalars['String']>;
  /** The date of the mediaItem */
  date?: Maybe<Scalars['String']>;
  /** The date (in GMT zone) of the mediaItem */
  dateGmt?: Maybe<Scalars['String']>;
  /** Description of the mediaItem */
  description?: Maybe<Scalars['String']>;
  /** The file name of the mediaItem */
  filePath?: Maybe<Scalars['String']>;
  /** The file type of the mediaItem */
  fileType?: Maybe<MimeTypeEnum>;
  /** The ID of the mediaItem object */
  id: Scalars['ID'];
  /** The WordPress post ID or the graphQL postId of the parent object */
  parentId?: Maybe<Scalars['ID']>;
  /** The ping status for the mediaItem */
  pingStatus?: Maybe<Scalars['String']>;
  /** The slug of the mediaItem */
  slug?: Maybe<Scalars['String']>;
  /** The status of the mediaItem */
  status?: Maybe<MediaItemStatusEnum>;
  /** The title of the mediaItem */
  title?: Maybe<Scalars['String']>;
};

/** Input for the updatePage mutation */
export type UpdatePageInput = {
  /** The userId to assign as the author of the object */
  authorId?: Maybe<Scalars['ID']>;
  /**
   * This is an ID that can be passed to a mutation by the client to track the
   * progress of mutations and catch possible duplicate mutation submissions.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The comment status for the object */
  commentStatus?: Maybe<Scalars['String']>;
  /** The content of the object */
  content?: Maybe<Scalars['String']>;
  /**
   * The date of the object. Preferable to enter as year/month/day (e.g.
   * 01/31/2017) as it will rearrange date as fit if it is not specified.
   * Incomplete dates may have unintended results for example, "2017" as the input
   * will use current date with timestamp 20:17
   */
  date?: Maybe<Scalars['String']>;
  /** The ID of the page object */
  id: Scalars['ID'];
  /**
   * A field used for ordering posts. This is typically used with nav menu items or
   * for special ordering of hierarchical content types.
   */
  menuOrder?: Maybe<Scalars['Int']>;
  /** The ID of the parent object */
  parentId?: Maybe<Scalars['ID']>;
  /** The password used to protect the content of the object */
  password?: Maybe<Scalars['String']>;
  /** The slug of the object */
  slug?: Maybe<Scalars['String']>;
  /** The status of the object */
  status?: Maybe<PostStatusEnum>;
  /** The title of the object */
  title?: Maybe<Scalars['String']>;
};

/** Input for the UpdatePostFormat mutation */
export type UpdatePostFormatInput = {
  /** The slug that the post_format will be an alias of */
  aliasOf?: Maybe<Scalars['String']>;
  /**
   * This is an ID that can be passed to a mutation by the client to track the
   * progress of mutations and catch possible duplicate mutation submissions.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The description of the post_format object */
  description?: Maybe<Scalars['String']>;
  /** The ID of the postFormat object to update */
  id: Scalars['ID'];
  /** The name of the post_format object to mutate */
  name?: Maybe<Scalars['String']>;
  /**
   * If this argument exists then the slug will be checked to see if it is not an
   * existing valid term. If that check succeeds (it is not a valid term), then it
   * is added and the term id is given. If it fails, then a check is made to
   * whether the taxonomy is hierarchical and the parent argument is not empty. If
   * the second check succeeds, the term will be inserted and the term id will be
   * given. If the slug argument is empty, then it will be calculated from the term name.
   */
  slug?: Maybe<Scalars['String']>;
};

/** Input for the updatePost mutation */
export type UpdatePostInput = {
  /** The userId to assign as the author of the object */
  authorId?: Maybe<Scalars['ID']>;
  /** Set connections between the post and categories */
  categories?: Maybe<PostCategoriesInput>;
  /**
   * This is an ID that can be passed to a mutation by the client to track the
   * progress of mutations and catch possible duplicate mutation submissions.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The comment status for the object */
  commentStatus?: Maybe<Scalars['String']>;
  /** The content of the object */
  content?: Maybe<Scalars['String']>;
  /**
   * The date of the object. Preferable to enter as year/month/day (e.g.
   * 01/31/2017) as it will rearrange date as fit if it is not specified.
   * Incomplete dates may have unintended results for example, "2017" as the input
   * will use current date with timestamp 20:17
   */
  date?: Maybe<Scalars['String']>;
  /** The excerpt of the object */
  excerpt?: Maybe<Scalars['String']>;
  /** The ID of the post object */
  id: Scalars['ID'];
  /**
   * A field used for ordering posts. This is typically used with nav menu items or
   * for special ordering of hierarchical content types.
   */
  menuOrder?: Maybe<Scalars['Int']>;
  /** The password used to protect the content of the object */
  password?: Maybe<Scalars['String']>;
  /** URLs that have been pinged. */
  pinged?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** The ping status for the object */
  pingStatus?: Maybe<Scalars['String']>;
  /** Set connections between the post and postFormats */
  postFormats?: Maybe<PostPostFormatsInput>;
  /** The slug of the object */
  slug?: Maybe<Scalars['String']>;
  /** The status of the object */
  status?: Maybe<PostStatusEnum>;
  /** Set connections between the post and tags */
  tags?: Maybe<PostTagsInput>;
  /** The title of the object */
  title?: Maybe<Scalars['String']>;
  /** URLs queued to be pinged. */
  toPing?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** Input for the updateRecurringevent mutation */
export type UpdateRecurringeventInput = {
  /**
   * This is an ID that can be passed to a mutation by the client to track the
   * progress of mutations and catch possible duplicate mutation submissions.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The content of the object */
  content?: Maybe<Scalars['String']>;
  /**
   * The date of the object. Preferable to enter as year/month/day (e.g.
   * 01/31/2017) as it will rearrange date as fit if it is not specified.
   * Incomplete dates may have unintended results for example, "2017" as the input
   * will use current date with timestamp 20:17
   */
  date?: Maybe<Scalars['String']>;
  /** The ID of the recurringevent object */
  id: Scalars['ID'];
  /**
   * A field used for ordering posts. This is typically used with nav menu items or
   * for special ordering of hierarchical content types.
   */
  menuOrder?: Maybe<Scalars['Int']>;
  /** The password used to protect the content of the object */
  password?: Maybe<Scalars['String']>;
  /** The slug of the object */
  slug?: Maybe<Scalars['String']>;
  /** The status of the object */
  status?: Maybe<PostStatusEnum>;
  /** The title of the object */
  title?: Maybe<Scalars['String']>;
};

/** Input for the updateRoom mutation */
export type UpdateRoomInput = {
  /**
   * This is an ID that can be passed to a mutation by the client to track the
   * progress of mutations and catch possible duplicate mutation submissions.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The content of the object */
  content?: Maybe<Scalars['String']>;
  /**
   * The date of the object. Preferable to enter as year/month/day (e.g.
   * 01/31/2017) as it will rearrange date as fit if it is not specified.
   * Incomplete dates may have unintended results for example, "2017" as the input
   * will use current date with timestamp 20:17
   */
  date?: Maybe<Scalars['String']>;
  /** The ID of the room object */
  id: Scalars['ID'];
  /**
   * A field used for ordering posts. This is typically used with nav menu items or
   * for special ordering of hierarchical content types.
   */
  menuOrder?: Maybe<Scalars['Int']>;
  /** The ID of the parent object */
  parentId?: Maybe<Scalars['ID']>;
  /** The password used to protect the content of the object */
  password?: Maybe<Scalars['String']>;
  /** The slug of the object */
  slug?: Maybe<Scalars['String']>;
  /** The status of the object */
  status?: Maybe<PostStatusEnum>;
  /** The title of the object */
  title?: Maybe<Scalars['String']>;
};

/** Input for the updateSettings mutation */
export type UpdateSettingsInput = {
  /**
   * This is an ID that can be passed to a mutation by the client to track the
   * progress of mutations and catch possible duplicate mutation submissions.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Allow people to submit comments on new posts. */
  discussionSettingsDefaultCommentStatus?: Maybe<Scalars['String']>;
  /** Allow link notifications from other blogs (pingbacks and trackbacks) on new articles. */
  discussionSettingsDefaultPingStatus?: Maybe<Scalars['String']>;
  /** A date format for all date strings. */
  generalSettingsDateFormat?: Maybe<Scalars['String']>;
  /** Site tagline. */
  generalSettingsDescription?: Maybe<Scalars['String']>;
  /** This address is used for admin purposes, like new user notification. */
  generalSettingsEmail?: Maybe<Scalars['String']>;
  /** WordPress locale code. */
  generalSettingsLanguage?: Maybe<Scalars['String']>;
  /** A day number of the week that the week should start on. */
  generalSettingsStartOfWeek?: Maybe<Scalars['Int']>;
  /** A time format for all time strings. */
  generalSettingsTimeFormat?: Maybe<Scalars['String']>;
  /** A city in the same timezone as you. */
  generalSettingsTimezone?: Maybe<Scalars['String']>;
  /** Site title. */
  generalSettingsTitle?: Maybe<Scalars['String']>;
  /** Site URL. */
  generalSettingsUrl?: Maybe<Scalars['String']>;
  /** The ID of the page that should display the latest posts */
  readingSettingsPageForPosts?: Maybe<Scalars['Int']>;
  /** The ID of the page that should be displayed on the front page */
  readingSettingsPageOnFront?: Maybe<Scalars['Int']>;
  /** Blog pages show at most. */
  readingSettingsPostsPerPage?: Maybe<Scalars['Int']>;
  /** What to show on the front page */
  readingSettingsShowOnFront?: Maybe<Scalars['String']>;
  /** Default post category. */
  writingSettingsDefaultCategory?: Maybe<Scalars['Int']>;
  /** Default post format. */
  writingSettingsDefaultPostFormat?: Maybe<Scalars['String']>;
  /** Convert emoticons like :-) and :-P to graphics on display. */
  writingSettingsUseSmilies?: Maybe<Scalars['Boolean']>;
};

/** Input for the updateSpecialoffer mutation */
export type UpdateSpecialofferInput = {
  /**
   * This is an ID that can be passed to a mutation by the client to track the
   * progress of mutations and catch possible duplicate mutation submissions.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The content of the object */
  content?: Maybe<Scalars['String']>;
  /**
   * The date of the object. Preferable to enter as year/month/day (e.g.
   * 01/31/2017) as it will rearrange date as fit if it is not specified.
   * Incomplete dates may have unintended results for example, "2017" as the input
   * will use current date with timestamp 20:17
   */
  date?: Maybe<Scalars['String']>;
  /** The ID of the specialoffer object */
  id: Scalars['ID'];
  /**
   * A field used for ordering posts. This is typically used with nav menu items or
   * for special ordering of hierarchical content types.
   */
  menuOrder?: Maybe<Scalars['Int']>;
  /** The ID of the parent object */
  parentId?: Maybe<Scalars['ID']>;
  /** The password used to protect the content of the object */
  password?: Maybe<Scalars['String']>;
  /** The slug of the object */
  slug?: Maybe<Scalars['String']>;
  /** The status of the object */
  status?: Maybe<PostStatusEnum>;
  /** Set connections between the specialoffer and tags */
  tags?: Maybe<SpecialofferTagsInput>;
  /** The title of the object */
  title?: Maybe<Scalars['String']>;
};

/** Input for the UpdateTag mutation */
export type UpdateTagInput = {
  /** The slug that the post_tag will be an alias of */
  aliasOf?: Maybe<Scalars['String']>;
  /**
   * This is an ID that can be passed to a mutation by the client to track the
   * progress of mutations and catch possible duplicate mutation submissions.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The description of the post_tag object */
  description?: Maybe<Scalars['String']>;
  /** The ID of the tag object to update */
  id: Scalars['ID'];
  /** The name of the post_tag object to mutate */
  name?: Maybe<Scalars['String']>;
  /**
   * If this argument exists then the slug will be checked to see if it is not an
   * existing valid term. If that check succeeds (it is not a valid term), then it
   * is added and the term id is given. If it fails, then a check is made to
   * whether the taxonomy is hierarchical and the parent argument is not empty. If
   * the second check succeeds, the term will be inserted and the term id will be
   * given. If the slug argument is empty, then it will be calculated from the term name.
   */
  slug?: Maybe<Scalars['String']>;
};

/** Input for the updateUser mutation */
export type UpdateUserInput = {
  /** User's AOL IM account. */
  aim?: Maybe<Scalars['String']>;
  /**
   * This is an ID that can be passed to a mutation by the client to track the
   * progress of mutations and catch possible duplicate mutation submissions.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** A string containing content about the user. */
  description?: Maybe<Scalars['String']>;
  /**
   * A string that will be shown on the site. Defaults to user's username. It is
   * likely that you will want to change this, for both appearance and security
   * through obscurity (that is if you dont use and delete the default admin user).
   */
  displayName?: Maybe<Scalars['String']>;
  /** A string containing the user's email address. */
  email?: Maybe<Scalars['String']>;
  /** 	The user's first name. */
  firstName?: Maybe<Scalars['String']>;
  /** The ID of the user */
  id: Scalars['ID'];
  /** User's Jabber account. */
  jabber?: Maybe<Scalars['String']>;
  /** The user's last name. */
  lastName?: Maybe<Scalars['String']>;
  /** User's locale. */
  locale?: Maybe<Scalars['String']>;
  /** A string that contains a URL-friendly name for the user. The default is the user's username. */
  nicename?: Maybe<Scalars['String']>;
  /** The user's nickname, defaults to the user's username. */
  nickname?: Maybe<Scalars['String']>;
  /** A string that contains the plain text password for the user. */
  password?: Maybe<Scalars['String']>;
  /** If true, this will refresh the users JWT secret. */
  refreshJwtUserSecret?: Maybe<Scalars['Boolean']>;
  /** The date the user registered. Format is Y-m-d H:i:s. */
  registered?: Maybe<Scalars['String']>;
  /**
   * If true, this will revoke the users JWT secret. If false, this will unrevoke
   * the JWT secret AND issue a new one. To revoke, the user must have proper
   * capabilities to edit users JWT secrets.
   */
  revokeJwtUserSecret?: Maybe<Scalars['Boolean']>;
  /** A string for whether to enable the rich editor or not. False if not empty. */
  richEditing?: Maybe<Scalars['String']>;
  /** An array of roles to be assigned to the user. */
  roles?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** A string containing the user's URL for the user's web site. */
  websiteUrl?: Maybe<Scalars['String']>;
  /** User's Yahoo IM account. */
  yim?: Maybe<Scalars['String']>;
};

/** Input for the updateWeddingbooking mutation */
export type UpdateWeddingbookingInput = {
  /**
   * This is an ID that can be passed to a mutation by the client to track the
   * progress of mutations and catch possible duplicate mutation submissions.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The content of the object */
  content?: Maybe<Scalars['String']>;
  /**
   * The date of the object. Preferable to enter as year/month/day (e.g.
   * 01/31/2017) as it will rearrange date as fit if it is not specified.
   * Incomplete dates may have unintended results for example, "2017" as the input
   * will use current date with timestamp 20:17
   */
  date?: Maybe<Scalars['String']>;
  /** The ID of the weddingbooking object */
  id: Scalars['ID'];
  /**
   * A field used for ordering posts. This is typically used with nav menu items or
   * for special ordering of hierarchical content types.
   */
  menuOrder?: Maybe<Scalars['Int']>;
  /** The ID of the parent object */
  parentId?: Maybe<Scalars['ID']>;
  /** The password used to protect the content of the object */
  password?: Maybe<Scalars['String']>;
  /** The slug of the object */
  slug?: Maybe<Scalars['String']>;
  /** The status of the object */
  status?: Maybe<PostStatusEnum>;
  /** The title of the object */
  title?: Maybe<Scalars['String']>;
};

/** The Type of Identifier used to fetch a single User node. To be used along with the "id" field. Default is "ID". */
export enum UserNodeIdTypeEnum {
  /** The Database ID for the node */
  DatabaseId = 'DATABASE_ID',
  /** The Email of the User */
  Email = 'EMAIL',
  /** The hashed Global ID */
  Id = 'ID',
  /** The slug of the User */
  Slug = 'SLUG',
  /** The URI for the node */
  Uri = 'URI',
  /** The username the User uses to login with */
  Username = 'USERNAME',
}

/** Names of available user roles */
export enum UserRoleEnum {
  /** User role with specific capabilities */
  Administrator = 'ADMINISTRATOR',
  /** User role with specific capabilities */
  Author = 'AUTHOR',
  /** User role with specific capabilities */
  Contributor = 'CONTRIBUTOR',
  /** User role with specific capabilities */
  Editor = 'EDITOR',
  /** User role with specific capabilities */
  HotelUser = 'HOTEL_USER',
  /** User role with specific capabilities */
  Subscriber = 'SUBSCRIBER',
}

/** Field to order the connection by */
export enum UsersConnectionOrderbyEnum {
  /** Order by display name */
  DisplayName = 'DISPLAY_NAME',
  /** Order by email address */
  Email = 'EMAIL',
  /** Order by login */
  Login = 'LOGIN',
  /** Preserve the login order given in the LOGIN_IN array */
  LoginIn = 'LOGIN_IN',
  /** Order by nice name */
  NiceName = 'NICE_NAME',
  /** Preserve the nice name order given in the NICE_NAME_IN array */
  NiceNameIn = 'NICE_NAME_IN',
  /** Order by registration date */
  Registered = 'REGISTERED',
  /** Order by URL */
  Url = 'URL',
}

/** Options for ordering the connection */
export type UsersConnectionOrderbyInput = {
  /** The field name used to sort the results. */
  field: UsersConnectionOrderbyEnum;
  /** The cardinality of the order of the connection */
  order?: Maybe<OrderEnum>;
};

/** Column used for searching for users. */
export enum UsersConnectionSearchColumnEnum {
  /** The user's email address. */
  Email = 'EMAIL',
  /** The globally unique ID. */
  Id = 'ID',
  /** The username the User uses to login with. */
  Login = 'LOGIN',
  /** A URL-friendly name for the user. The default is the user's username. */
  Nicename = 'NICENAME',
  /** The URL of the user\s website. */
  Url = 'URL',
}

/** Arguments for filtering the UserToCommentConnection connection */
export type UserToCommentConnectionWhereArgs = {
  /** Comment author email address. */
  authorEmail?: Maybe<Scalars['String']>;
  /** Array of author IDs to include comments for. */
  authorIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Array of author IDs to exclude comments for. */
  authorNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Comment author URL. */
  authorUrl?: Maybe<Scalars['String']>;
  /** Array of comment IDs to include. */
  commentIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Array of IDs of users whose unapproved comments will be returned by the query regardless of status. */
  commentNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Include comments of a given type. */
  commentType?: Maybe<Scalars['String']>;
  /** Include comments from a given array of comment types. */
  commentTypeIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Exclude comments from a given array of comment types. */
  commentTypeNotIn?: Maybe<Scalars['String']>;
  /** Content object author ID to limit results by. */
  contentAuthor?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Array of author IDs to retrieve comments for. */
  contentAuthorIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Array of author IDs *not* to retrieve comments for. */
  contentAuthorNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Limit results to those affiliated with a given content object ID. */
  contentId?: Maybe<Scalars['ID']>;
  /** Array of content object IDs to include affiliated comments for. */
  contentIdIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Array of content object IDs to exclude affiliated comments for. */
  contentIdNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Content object name to retrieve affiliated comments for. */
  contentName?: Maybe<Scalars['String']>;
  /** Content Object parent ID to retrieve affiliated comments for. */
  contentParent?: Maybe<Scalars['Int']>;
  /** Array of content object statuses to retrieve affiliated comments for. Pass 'any' to match any value. */
  contentStatus?: Maybe<Array<Maybe<PostStatusEnum>>>;
  /** Content object type or array of types to retrieve affiliated comments for. Pass 'any' to match any value. */
  contentType?: Maybe<Array<Maybe<ContentTypeEnum>>>;
  /**
   * Array of IDs or email addresses of users whose unapproved comments will be
   * returned by the query regardless of $status. Default empty
   */
  includeUnapproved?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Karma score to retrieve matching comments for. */
  karma?: Maybe<Scalars['Int']>;
  /** The cardinality of the order of the connection */
  order?: Maybe<OrderEnum>;
  /** Field to order the comments by. */
  orderby?: Maybe<CommentsConnectionOrderbyEnum>;
  /** Parent ID of comment to retrieve children of. */
  parent?: Maybe<Scalars['Int']>;
  /** Array of parent IDs of comments to retrieve children for. */
  parentIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Array of parent IDs of comments *not* to retrieve children for. */
  parentNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Search term(s) to retrieve matching comments for. */
  search?: Maybe<Scalars['String']>;
  /** Comment status to limit results by. */
  status?: Maybe<Scalars['String']>;
  /** Include comments for a specific user ID. */
  userId?: Maybe<Scalars['ID']>;
};

/** Arguments for filtering the UserToContentRevisionUnionConnection connection */
export type UserToContentRevisionUnionConnectionWhereArgs = {
  /** The Types of content to filter */
  contentTypes?: Maybe<Array<Maybe<ContentTypeEnum>>>;
  /** Filter the connection based on dates */
  dateQuery?: Maybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: Maybe<Scalars['Boolean']>;
  /** Specific ID of the object */
  id?: Maybe<Scalars['Int']>;
  /** Array of IDs for the objects to retrieve */
  in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: Maybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: Maybe<Scalars['String']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** What paramater to use to order the objects by. */
  orderby?: Maybe<Array<Maybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: Maybe<Scalars['ID']>;
  /** Specify objects whose parent is in an array */
  parentIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Show posts with a specific password. */
  password?: Maybe<Scalars['String']>;
  /** Show Posts based on a keyword search */
  search?: Maybe<Scalars['String']>;
  /** Retrieve posts where post status is in an array. */
  stati?: Maybe<Array<Maybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: Maybe<PostStatusEnum>;
  /** Title of the object */
  title?: Maybe<Scalars['String']>;
};

/** Arguments for filtering the UserToMediaItemConnection connection */
export type UserToMediaItemConnectionWhereArgs = {
  /** The user that's connected as the author of the object. Use the userId for the author object. */
  author?: Maybe<Scalars['Int']>;
  /** Find objects connected to author(s) in the array of author's userIds */
  authorIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Find objects connected to the author by the author's nicename */
  authorName?: Maybe<Scalars['String']>;
  /** Find objects NOT connected to author(s) in the array of author's userIds */
  authorNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Filter the connection based on dates */
  dateQuery?: Maybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: Maybe<Scalars['Boolean']>;
  /** Specific ID of the object */
  id?: Maybe<Scalars['Int']>;
  /** Array of IDs for the objects to retrieve */
  in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: Maybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: Maybe<Scalars['String']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** What paramater to use to order the objects by. */
  orderby?: Maybe<Array<Maybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: Maybe<Scalars['ID']>;
  /** Specify objects whose parent is in an array */
  parentIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Show posts with a specific password. */
  password?: Maybe<Scalars['String']>;
  /** Show Posts based on a keyword search */
  search?: Maybe<Scalars['String']>;
  /** Retrieve posts where post status is in an array. */
  stati?: Maybe<Array<Maybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: Maybe<PostStatusEnum>;
  /** Title of the object */
  title?: Maybe<Scalars['String']>;
};

/** Arguments for filtering the UserToPageConnection connection */
export type UserToPageConnectionWhereArgs = {
  /** The user that's connected as the author of the object. Use the userId for the author object. */
  author?: Maybe<Scalars['Int']>;
  /** Find objects connected to author(s) in the array of author's userIds */
  authorIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Find objects connected to the author by the author's nicename */
  authorName?: Maybe<Scalars['String']>;
  /** Find objects NOT connected to author(s) in the array of author's userIds */
  authorNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Filter the connection based on dates */
  dateQuery?: Maybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: Maybe<Scalars['Boolean']>;
  /** Specific ID of the object */
  id?: Maybe<Scalars['Int']>;
  /** Array of IDs for the objects to retrieve */
  in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: Maybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: Maybe<Scalars['String']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** What paramater to use to order the objects by. */
  orderby?: Maybe<Array<Maybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: Maybe<Scalars['ID']>;
  /** Specify objects whose parent is in an array */
  parentIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Show posts with a specific password. */
  password?: Maybe<Scalars['String']>;
  /** Show Posts based on a keyword search */
  search?: Maybe<Scalars['String']>;
  /** Retrieve posts where post status is in an array. */
  stati?: Maybe<Array<Maybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: Maybe<PostStatusEnum>;
  /** Title of the object */
  title?: Maybe<Scalars['String']>;
};

/** Arguments for filtering the UserToPostConnection connection */
export type UserToPostConnectionWhereArgs = {
  /** The user that's connected as the author of the object. Use the userId for the author object. */
  author?: Maybe<Scalars['Int']>;
  /** Find objects connected to author(s) in the array of author's userIds */
  authorIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Find objects connected to the author by the author's nicename */
  authorName?: Maybe<Scalars['String']>;
  /** Find objects NOT connected to author(s) in the array of author's userIds */
  authorNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Category ID */
  categoryId?: Maybe<Scalars['Int']>;
  /** Array of category IDs, used to display objects from one category OR another */
  categoryIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Use Category Slug */
  categoryName?: Maybe<Scalars['String']>;
  /** Array of category IDs, used to display objects from one category OR another */
  categoryNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Filter the connection based on dates */
  dateQuery?: Maybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: Maybe<Scalars['Boolean']>;
  /** Specific ID of the object */
  id?: Maybe<Scalars['Int']>;
  /** Array of IDs for the objects to retrieve */
  in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: Maybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: Maybe<Scalars['String']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** What paramater to use to order the objects by. */
  orderby?: Maybe<Array<Maybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: Maybe<Scalars['ID']>;
  /** Specify objects whose parent is in an array */
  parentIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Show posts with a specific password. */
  password?: Maybe<Scalars['String']>;
  /** Show Posts based on a keyword search */
  search?: Maybe<Scalars['String']>;
  /** Retrieve posts where post status is in an array. */
  stati?: Maybe<Array<Maybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: Maybe<PostStatusEnum>;
  /** Tag Slug */
  tag?: Maybe<Scalars['String']>;
  /** Use Tag ID */
  tagId?: Maybe<Scalars['String']>;
  /** Array of tag IDs, used to display objects from one tag OR another */
  tagIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Array of tag IDs, used to display objects from one tag OR another */
  tagNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Array of tag slugs, used to display objects from one tag OR another */
  tagSlugAnd?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Array of tag slugs, used to exclude objects in specified tags */
  tagSlugIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Title of the object */
  title?: Maybe<Scalars['String']>;
};

/** The Type of Identifier used to fetch a single resource. Default is ID. */
export enum WeddingbookingIdType {
  /** Identify a resource by the Database ID. */
  DatabaseId = 'DATABASE_ID',
  /** Identify a resource by the (hashed) Global ID. */
  Id = 'ID',
  /** Identify a resource by the URI. */
  Uri = 'URI',
}

/** Arguments for filtering the weddingbookingToRevisionConnection connection */
export type WeddingbookingToRevisionConnectionWhereArgs = {
  /** Filter the connection based on dates */
  dateQuery?: Maybe<DateQueryInput>;
  /** True for objects with passwords; False for objects without passwords; null for all objects with or without passwords */
  hasPassword?: Maybe<Scalars['Boolean']>;
  /** Specific ID of the object */
  id?: Maybe<Scalars['Int']>;
  /** Array of IDs for the objects to retrieve */
  in?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Get objects with a specific mimeType property */
  mimeType?: Maybe<MimeTypeEnum>;
  /** Slug / post_name of the object */
  name?: Maybe<Scalars['String']>;
  /** Specify objects to retrieve. Use slugs */
  nameIn?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Specify IDs NOT to retrieve. If this is used in the same query as "in", it will be ignored */
  notIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** What paramater to use to order the objects by. */
  orderby?: Maybe<Array<Maybe<PostObjectsConnectionOrderbyInput>>>;
  /** Use ID to return only children. Use 0 to return only top-level items */
  parent?: Maybe<Scalars['ID']>;
  /** Specify objects whose parent is in an array */
  parentIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Specify posts whose parent is not in an array */
  parentNotIn?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Show posts with a specific password. */
  password?: Maybe<Scalars['String']>;
  /** Show Posts based on a keyword search */
  search?: Maybe<Scalars['String']>;
  /** Retrieve posts where post status is in an array. */
  stati?: Maybe<Array<Maybe<PostStatusEnum>>>;
  /** Show posts with a specific status. */
  status?: Maybe<PostStatusEnum>;
  /** Title of the object */
  title?: Maybe<Scalars['String']>;
};

export type NavFragmentFragment = {
  __typename?: 'RootQuery';
  mainNav?: Maybe<{
    __typename?: 'RootQueryToMenuItemConnection';
    edges?: Maybe<
      Array<
        Maybe<{
          __typename?: 'RootQueryToMenuItemConnectionEdge';
          node?: Maybe<{
            __typename?: 'MenuItem';
            label?: Maybe<string>;
            databaseId: number;
            path?: Maybe<string>;
            url?: Maybe<string>;
            label_noTx?: Maybe<string>;
            NoTranslate?: Maybe<{
              __typename?: 'MenuItem_Notranslate';
              noTx?: Maybe<boolean>;
            }>;
            connectedNode?: Maybe<{
              __typename?: 'MenuItemToMenuItemLinkableConnectionEdge';
              node?: Maybe<
                | { __typename?: 'Activities'; databaseId: number }
                | { __typename?: 'Page'; databaseId: number }
                | { __typename?: 'Media_block'; databaseId: number }
                | { __typename?: 'Html_menu'; databaseId: number }
                | { __typename?: 'Category'; databaseId: number }
                | { __typename?: 'Post'; databaseId: number }
                | { __typename?: 'Tag'; databaseId: number }
                | { __typename?: 'Specialoffer'; databaseId: number }
                | { __typename?: 'Calendar'; databaseId: number }
                | { __typename?: 'Room'; databaseId: number }
                | { __typename?: 'Weddingbooking'; databaseId: number }
                | { __typename?: 'Recurringevent'; databaseId: number }
              >;
            }>;
            childItems?: Maybe<{
              __typename?: 'MenuItemToMenuItemConnection';
              edges?: Maybe<
                Array<
                  Maybe<{
                    __typename?: 'MenuItemToMenuItemConnectionEdge';
                    node?: Maybe<{
                      __typename?: 'MenuItem';
                      databaseId: number;
                      label?: Maybe<string>;
                      path?: Maybe<string>;
                      url?: Maybe<string>;
                      label_noTx?: Maybe<string>;
                      NoTranslate?: Maybe<{
                        __typename?: 'MenuItem_Notranslate';
                        noTx?: Maybe<boolean>;
                      }>;
                      connectedNode?: Maybe<{
                        __typename?: 'MenuItemToMenuItemLinkableConnectionEdge';
                        node?: Maybe<
                          | { __typename?: 'Activities'; databaseId: number }
                          | { __typename?: 'Page'; databaseId: number }
                          | { __typename?: 'Media_block'; databaseId: number }
                          | { __typename?: 'Html_menu'; databaseId: number }
                          | { __typename?: 'Category'; databaseId: number }
                          | { __typename?: 'Post'; databaseId: number }
                          | { __typename?: 'Tag'; databaseId: number }
                          | { __typename?: 'Specialoffer'; databaseId: number }
                          | { __typename?: 'Calendar'; databaseId: number }
                          | { __typename?: 'Room'; databaseId: number }
                          | {
                              __typename?: 'Weddingbooking';
                              databaseId: number;
                            }
                          | {
                              __typename?: 'Recurringevent';
                              databaseId: number;
                            }
                        >;
                      }>;
                      childItems?: Maybe<{
                        __typename?: 'MenuItemToMenuItemConnection';
                        edges?: Maybe<
                          Array<
                            Maybe<{
                              __typename?: 'MenuItemToMenuItemConnectionEdge';
                              node?: Maybe<{
                                __typename?: 'MenuItem';
                                databaseId: number;
                                label?: Maybe<string>;
                                path?: Maybe<string>;
                                url?: Maybe<string>;
                                label_noTx?: Maybe<string>;
                                NoTranslate?: Maybe<{
                                  __typename?: 'MenuItem_Notranslate';
                                  noTx?: Maybe<boolean>;
                                }>;
                                connectedNode?: Maybe<{
                                  __typename?: 'MenuItemToMenuItemLinkableConnectionEdge';
                                  node?: Maybe<
                                    | {
                                        __typename?: 'Activities';
                                        databaseId: number;
                                      }
                                    | {
                                        __typename?: 'Page';
                                        databaseId: number;
                                      }
                                    | {
                                        __typename?: 'Media_block';
                                        databaseId: number;
                                      }
                                    | {
                                        __typename?: 'Html_menu';
                                        databaseId: number;
                                      }
                                    | {
                                        __typename?: 'Category';
                                        databaseId: number;
                                      }
                                    | {
                                        __typename?: 'Post';
                                        databaseId: number;
                                      }
                                    | { __typename?: 'Tag'; databaseId: number }
                                    | {
                                        __typename?: 'Specialoffer';
                                        databaseId: number;
                                      }
                                    | {
                                        __typename?: 'Calendar';
                                        databaseId: number;
                                      }
                                    | {
                                        __typename?: 'Room';
                                        databaseId: number;
                                      }
                                    | {
                                        __typename?: 'Weddingbooking';
                                        databaseId: number;
                                      }
                                    | {
                                        __typename?: 'Recurringevent';
                                        databaseId: number;
                                      }
                                  >;
                                }>;
                              }>;
                            }>
                          >
                        >;
                      }>;
                    }>;
                  }>
                >
              >;
            }>;
          }>;
        }>
      >
    >;
  }>;
  iconNav?: Maybe<{
    __typename?: 'RootQueryToMenuItemConnection';
    edges?: Maybe<
      Array<
        Maybe<{
          __typename?: 'RootQueryToMenuItemConnectionEdge';
          node?: Maybe<{
            __typename?: 'MenuItem';
            label?: Maybe<string>;
            databaseId: number;
            path?: Maybe<string>;
            iconSelect?: Maybe<{
              __typename?: 'MenuItem_Iconselect';
              iconSelect?: Maybe<string>;
            }>;
            menu?: Maybe<{
              __typename?: 'MenuItemToMenuConnectionEdge';
              node?: Maybe<{
                __typename?: 'Menu';
                locations?: Maybe<Array<Maybe<MenuLocationEnum>>>;
              }>;
            }>;
          }>;
        }>
      >
    >;
  }>;
  languageNavStyles?: Maybe<{
    __typename?: 'Menu';
    name?: Maybe<string>;
    languageSelector?: Maybe<{
      __typename?: 'Menu_Languageselector';
      languageNavAccentColour?: Maybe<string>;
      languageNavTextColour?: Maybe<string>;
    }>;
  }>;
  footerNav?: Maybe<{
    __typename?: 'RootQueryToMenuItemConnection';
    edges?: Maybe<
      Array<
        Maybe<{
          __typename?: 'RootQueryToMenuItemConnectionEdge';
          node?: Maybe<{
            __typename?: 'MenuItem';
            label?: Maybe<string>;
            databaseId: number;
            path?: Maybe<string>;
            menu?: Maybe<{
              __typename?: 'MenuItemToMenuConnectionEdge';
              node?: Maybe<{
                __typename?: 'Menu';
                locations?: Maybe<Array<Maybe<MenuLocationEnum>>>;
                count?: Maybe<number>;
              }>;
            }>;
          }>;
        }>
      >
    >;
  }>;
};

export type AllWpMenusQueryVariables = Exact<{
  language?: Maybe<Scalars['String']>;
}>;

export type AllWpMenusQuery = {
  __typename?: 'RootQuery';
} & NavFragmentFragment;

export type WordpressPagesQueryVariables = Exact<{ [key: string]: never }>;

export type WordpressPagesQuery = {
  __typename?: 'RootQuery';
  pages?: Maybe<{
    __typename?: 'RootQueryToPageConnection';
    nodes?: Maybe<
      Array<
        Maybe<{
          __typename?: 'Page';
          uri?: Maybe<string>;
          title?: Maybe<string>;
        }>
      >
    >;
  }>;
};

export type WpSettingsFragment = {
  __typename?: 'RootQuery';
  allSettings?: Maybe<{
    __typename?: 'Settings';
    generalSettingsTitle?: Maybe<string>;
    generalSettingsDescription?: Maybe<string>;
  }>;
  themeSettings?: Maybe<{
    __typename?: 'ThemeSettings';
    acfVersion?: Maybe<string>;
    SettingsThemeSettings?: Maybe<{
      __typename?: 'ThemeSettings_Settingsthemesettings';
      brandTheme?: Maybe<string>;
      customEventCategories?: Maybe<
        Array<
          Maybe<{
            __typename?: 'ThemeSettings_Settingsthemesettings_customEventCategories';
            customCategoryNames?: Maybe<string>;
            fieldGroupName?: Maybe<string>;
          }>
        >
      >;
    }>;
  }>;
  propertyInfo?: Maybe<{
    __typename?: 'PropertyInfo';
    propertyInfo?: Maybe<{
      __typename?: 'PropertyInfo_Propertyinfo';
      liveUrl?: Maybe<string>;
      brand?: Maybe<string>;
      ctyhocn?: Maybe<string>;
      faxNumber?: Maybe<string>;
      openingHours?: Maybe<string>;
      phoneNumber?: Maybe<string>;
      address?: Maybe<{
        __typename?: 'PropertyInfo_Propertyinfo_Address';
        addressCountry?: Maybe<string>;
        addressLocality?: Maybe<string>;
        addressRegion?: Maybe<string>;
        postalCode?: Maybe<string>;
        streetAddress?: Maybe<string>;
      }>;
      geo?: Maybe<{
        __typename?: 'PropertyInfo_Propertyinfo_Geo';
        latitude?: Maybe<string>;
        longitude?: Maybe<string>;
      }>;
    }>;
  }>;
  socialLink?: Maybe<{
    __typename?: 'SocialLink';
    pageSlug?: Maybe<string>;
    pageTitle?: Maybe<string>;
    socialLinks?: Maybe<{
      __typename?: 'SocialLink_Sociallinks';
      fieldGroupName?: Maybe<string>;
      facebookLink?: Maybe<string>;
      instagramLink?: Maybe<string>;
      twitterLink?: Maybe<string>;
      linkedinLink?: Maybe<string>;
    }>;
  }>;
  headerSettings?: Maybe<{
    __typename?: 'HeaderSettings';
    pageTitle?: Maybe<string>;
    SettingsHeaderSettings?: Maybe<{
      __typename?: 'HeaderSettings_Settingsheadersettings';
      fieldGroupName?: Maybe<string>;
      backgroundColour?: Maybe<string>;
      backgroundColourActive?: Maybe<string>;
      backgroundColourGradient?: Maybe<string>;
      backgroundBlur?: Maybe<number>;
      textColour?: Maybe<string>;
      textColourActive?: Maybe<string>;
      dividerColour?: Maybe<string>;
      dividerColourActive?: Maybe<string>;
      bookingWidgetButtonStyles?: Maybe<{
        __typename?: 'HeaderSettings_Settingsheadersettings_BookingWidgetButtonStyles';
        fieldGroupName?: Maybe<string>;
        buttonStyle?: Maybe<string>;
        backgroundColour?: Maybe<string>;
        backgroundColourHover?: Maybe<string>;
        textCase?: Maybe<string>;
        textColour?: Maybe<string>;
        textColourHover?: Maybe<string>;
        borderColour?: Maybe<string>;
        borderColourHover?: Maybe<string>;
        hideBookingWidget?: Maybe<boolean>;
      }>;
      iconNavStyles?: Maybe<{
        __typename?: 'HeaderSettings_Settingsheadersettings_IconNavStyles';
        fieldGroupName?: Maybe<string>;
        iconColour?: Maybe<string>;
        iconColourActive?: Maybe<string>;
        separatorColour?: Maybe<string>;
        textColour?: Maybe<string>;
        textColourActive?: Maybe<string>;
        underline?: Maybe<boolean>;
      }>;
      mainNavStyles?: Maybe<{
        __typename?: 'HeaderSettings_Settingsheadersettings_MainNavStyles';
        fieldGroupName?: Maybe<string>;
        fontCase?: Maybe<string>;
        fontSize?: Maybe<string>;
        fontWeight?: Maybe<string>;
        menuItemActiveBackgroundColour?: Maybe<string>;
        menuItemActiveState?: Maybe<string>;
        menuItemActiveTextColour?: Maybe<string>;
        menuItemHoverColour?: Maybe<string>;
        menuItemPadding?: Maybe<string>;
        menuItemUnderlineColour?: Maybe<string>;
        menuItemUnderlineToggle?: Maybe<boolean>;
        submenuBackgroundColour?: Maybe<string>;
      }>;
      honorsSignInStyles?: Maybe<{
        __typename?: 'HeaderSettings_Settingsheadersettings_HonorsSignInStyles';
        backgroundColour?: Maybe<string>;
        menuLinkBackgroundHighlightColour?: Maybe<string>;
        menuLinkTextHighlightColour?: Maybe<string>;
        textColour?: Maybe<string>;
      }>;
      languageSwitchButtonStyles?: Maybe<{
        __typename?: 'HeaderSettings_Settingsheadersettings_LanguageSwitchButtonStyles';
        backgroundColour?: Maybe<string>;
        backgroundColourHover?: Maybe<string>;
        textCase?: Maybe<string>;
        textColour?: Maybe<string>;
        textColourHover?: Maybe<string>;
      }>;
      languageSwitchDropdownStyles?: Maybe<{
        __typename?: 'HeaderSettings_Settingsheadersettings_LanguageSwitchDropdownStyles';
        backgroundColour?: Maybe<string>;
        backgroundColourHover?: Maybe<string>;
        dividerColour?: Maybe<string>;
        textColour?: Maybe<string>;
        textColourHover?: Maybe<string>;
      }>;
      languageSwitchTitleStyles?: Maybe<{
        __typename?: 'HeaderSettings_Settingsheadersettings_LanguageSwitchTitleStyles';
        textCase?: Maybe<string>;
        textColour?: Maybe<string>;
        textColourActive?: Maybe<string>;
        textColourActiveHover?: Maybe<string>;
        textColourHover?: Maybe<string>;
      }>;
      languageSwitchButtonStylesActive?: Maybe<{
        __typename?: 'HeaderSettings_Settingsheadersettings_LanguageSwitchButtonStylesActive';
        backgroundColour?: Maybe<string>;
        backgroundColourHover?: Maybe<string>;
        textCase?: Maybe<string>;
        textColour?: Maybe<string>;
        textColourHover?: Maybe<string>;
      }>;
    }>;
  }>;
  logoSettings?: Maybe<{
    __typename?: 'LogoSettings';
    SettingsLogoSettings?: Maybe<{
      __typename?: 'LogoSettings_Settingslogosettings';
      brandLogoClasses?: Maybe<string>;
      brandLogoClassesFooter?: Maybe<string>;
      brandLogoColourActive?: Maybe<string>;
      brandLogoColourFooter?: Maybe<string>;
      brandLogoColourInactive?: Maybe<string>;
      hotelLogoClasses?: Maybe<string>;
      hotelLogoClassesFooter?: Maybe<string>;
      hotelLogoColourActive?: Maybe<string>;
      hotelLogoColourFooter?: Maybe<string>;
      hotelLogoColourInactive?: Maybe<string>;
      hotelLogo?: Maybe<{
        __typename?: 'MediaItem';
        altText?: Maybe<string>;
        sourceUrl?: Maybe<string>;
      }>;
      brandLogo?: Maybe<{
        __typename?: 'MediaItem';
        altText?: Maybe<string>;
        sourceUrl?: Maybe<string>;
      }>;
      additionalLogos?: Maybe<
        Array<
          Maybe<{
            __typename?: 'LogoSettings_Settingslogosettings_additionalLogos';
            margin?: Maybe<string>;
            maxWidth?: Maybe<string>;
            tailwindClasses?: Maybe<string>;
            image?: Maybe<{
              __typename?: 'MediaItem';
              altText?: Maybe<string>;
              sourceUrl?: Maybe<string>;
            }>;
          }>
        >
      >;
    }>;
  }>;
  translationSettings?: Maybe<{
    __typename?: 'TranslationSettings';
    SettingsTranslations?: Maybe<{
      __typename?: 'TranslationSettings_Settingstranslations';
      languageSelectorOverrides?: Maybe<
        Array<
          Maybe<{
            __typename?: 'TranslationSettings_Settingstranslations_languageSelectorOverrides';
            languageCode?: Maybe<string>;
            reservationsOnly?: Maybe<boolean>;
            urlOverride?: Maybe<string>;
          }>
        >
      >;
      translations?: Maybe<
        Array<
          Maybe<{
            __typename?: 'TranslationSettings_Settingstranslations_translations';
            translationString?: Maybe<string>;
          }>
        >
      >;
    }>;
  }>;
  buttonSettings?: Maybe<{
    __typename?: 'ButtonSettings';
    buttonsSettings?: Maybe<{
      __typename?: 'ButtonSettings_Buttonssettings';
      buttonStyles?: Maybe<
        Array<
          Maybe<{
            __typename?: 'ButtonSettings_Buttonssettings_buttonStyles';
            backgroundColour?: Maybe<string>;
            backgroundColourHover?: Maybe<string>;
            borderColour?: Maybe<string>;
            borderColourHover?: Maybe<string>;
            borderRadius?: Maybe<string>;
            buttonType?: Maybe<string>;
            fieldGroupName?: Maybe<string>;
            fontFamily?: Maybe<string>;
            hoverStyle?: Maybe<string>;
            textColour?: Maybe<string>;
            textColourHover?: Maybe<string>;
            textTransform?: Maybe<string>;
            buttonHeight?: Maybe<string>;
            buttonPadding?: Maybe<string>;
            fontSize?: Maybe<string>;
            fontWeight?: Maybe<string>;
            pageRestrictions?: Maybe<string>;
            lineHeight?: Maybe<string>;
          }>
        >
      >;
      waldorfButtonStyles?: Maybe<
        Array<
          Maybe<{
            __typename?: 'ButtonSettings_Buttonssettings_waldorfButtonStyles';
            buttonType?: Maybe<string>;
            textColour?: Maybe<string>;
            textColourHover?: Maybe<string>;
            underlineColour?: Maybe<string>;
            underlineColourHover?: Maybe<string>;
            pageRestrictions?: Maybe<string>;
          }>
        >
      >;
    }>;
  }>;
  footerSettings?: Maybe<{
    __typename?: 'FooterSettings';
    SettingsFooterSettings?: Maybe<{
      __typename?: 'FooterSettings_Settingsfootersettings';
      footer?: Maybe<{
        __typename?: 'FooterSettings_Settingsfootersettings_Footer';
        backgroundColour?: Maybe<string>;
        disclaimerText?: Maybe<string>;
        textColour?: Maybe<string>;
        dividerColour?: Maybe<string>;
        backgroundImage?: Maybe<{
          __typename?: 'MediaItem';
          sourceUrl?: Maybe<string>;
        }>;
      }>;
      breadcrumbs?: Maybe<{
        __typename?: 'FooterSettings_Settingsfootersettings_Breadcrumbs';
        activeTextColour?: Maybe<string>;
        backgroundColour?: Maybe<string>;
        textColour?: Maybe<string>;
        delimiter?: Maybe<string>;
        delimiterColour?: Maybe<string>;
        delimiterPadding?: Maybe<string>;
      }>;
      copyright?: Maybe<{
        __typename?: 'FooterSettings_Settingsfootersettings_Copyright';
        backgroundColour?: Maybe<string>;
        enable?: Maybe<boolean>;
        textColour?: Maybe<string>;
      }>;
    }>;
  }>;
  roomSettings?: Maybe<{
    __typename?: 'RoomSettings';
    pageTitle?: Maybe<string>;
    SettingsRoomSettings?: Maybe<{
      __typename?: 'RoomSettings_Settingsroomsettings';
      roomFilters1Title?: Maybe<string>;
      roomFilters2Title?: Maybe<string>;
    }>;
  }>;
  alertBarSettings?: Maybe<{
    __typename?: 'AlertBarSettings';
    alertBarSettings?: Maybe<{
      __typename?: 'AlertBarSettings_Alertbarsettings';
      backgroundColor?: Maybe<string>;
      linkColor?: Maybe<string>;
      textColor?: Maybe<string>;
    }>;
  }>;
  interactiveMapSettings?: Maybe<{
    __typename?: 'InteractiveMapSettings';
    interactiveMapSettings?: Maybe<{
      __typename?: 'InteractiveMapSettings_Interactivemapsettings';
      mapCategories?: Maybe<
        Array<
          Maybe<{
            __typename?: 'InteractiveMapSettings_Interactivemapsettings_mapCategories';
            categoryName?: Maybe<string>;
            iconList?: Maybe<string>;
          }>
        >
      >;
    }>;
  }>;
  shopFormSettings?: Maybe<{
    __typename?: 'ShopFormSettings';
    pageSlug?: Maybe<string>;
    pageTitle?: Maybe<string>;
    shopFormSettings?: Maybe<{
      __typename?: 'ShopFormSettings_Shopformsettings';
      fieldGroupName?: Maybe<string>;
      attachToHeader?: Maybe<boolean>;
      checkboxBackgroundColor?: Maybe<string>;
      checkboxOutlineCheckmarkColor?: Maybe<string>;
      dateNumberAndRoomIconColor?: Maybe<string>;
      dateTextColor?: Maybe<string>;
      dayOfTheWeekColor?: Maybe<string>;
      dividingLineColor?: Maybe<string>;
      horizontalDividingLineColor?: Maybe<string>;
      inputBorderColor?: Maybe<string>;
      mainTextColor?: Maybe<string>;
      modalBackgroundColor?: Maybe<string>;
      modalDividingLineColor?: Maybe<string>;
      modalHyperlinkColor?: Maybe<string>;
      modalHyperlinkColorCopy?: Maybe<string>;
      modalTextColor?: Maybe<string>;
      monthColor?: Maybe<string>;
      oscBookButtonBackgroundColor?: Maybe<string>;
      oscBookButtonBackgroundColorOnHover?: Maybe<string>;
      oscBookButtonBorderColor?: Maybe<string>;
      oscBookButtonBorderColorOnHover?: Maybe<string>;
      oscBookButtonTextColor?: Maybe<string>;
      oscBookButtonTextColorOnHover?: Maybe<string>;
      searchAndFilterIcons?: Maybe<string>;
      searchButtonBackgroundColor?: Maybe<string>;
      searchButtonBackgroundColorOnHover?: Maybe<string>;
      searchButtonBorderColor?: Maybe<string>;
      searchButtonBorderColorOnHover?: Maybe<string>;
      searchButtonTextColor?: Maybe<string>;
      searchButtonTextColorOnHover?: Maybe<string>;
      selectedBookDatePickerDateBackground?: Maybe<string>;
      selectedBookDatePickerDateText?: Maybe<string>;
      selectedBookPickerDateRangeBackground?: Maybe<string>;
      selectedBookPickerDateRangeText?: Maybe<string>;
      shopFormBgColor?: Maybe<string>;
      specialRatesNumberBackground?: Maybe<string>;
      specialRatesTextBackground?: Maybe<string>;
      bookButtonStyleFromGlobal?: Maybe<{
        __typename?: 'ShopFormSettings_Shopformsettings_BookButtonStyleFromGlobal';
        fieldGroupName?: Maybe<string>;
        shopFormBookGlobalButtonStyleButtonStyle?: Maybe<string>;
      }>;
      searchButtonStyleFromGlobal?: Maybe<{
        __typename?: 'ShopFormSettings_Shopformsettings_SearchButtonStyleFromGlobal';
        fieldGroupName?: Maybe<string>;
        globalButtonStyleSettingButtonStyle?: Maybe<string>;
      }>;
    }>;
  }>;
  componentStyles?: Maybe<{
    __typename?: 'ComponentStyles';
    globalComponentSettings?: Maybe<{
      __typename?: 'ComponentStyles_Globalcomponentsettings';
      globalComponentSettings?: Maybe<{
        __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings';
        addressBarSettings?: Maybe<{
          __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_AddressBarSettings';
          fieldGroupName?: Maybe<string>;
          componentBackgroundColor?: Maybe<string>;
          componentBackgroundSize?: Maybe<string>;
          componentBackgroundRepeat?: Maybe<string>;
          contentBackgroundColor?: Maybe<string>;
          contentBackgroundSize?: Maybe<string>;
          contentBackgroundRepeat?: Maybe<string>;
          contentBackgroundPosition?: Maybe<string>;
          subtitleColor?: Maybe<string>;
          textAlignment?: Maybe<string>;
          textColor?: Maybe<string>;
          titleColor?: Maybe<string>;
          borderTopColour?: Maybe<string>;
          borderBottomColour?: Maybe<string>;
          seperatorColor?: Maybe<string>;
          showHide?: Maybe<boolean>;
          hideAnimations?: Maybe<string>;
          animationDirection?: Maybe<string>;
          hideParallax?: Maybe<string>;
          componentBackgroundImage?: Maybe<{
            __typename?: 'MediaItem';
            sourceUrl?: Maybe<string>;
          }>;
          contentBackgroundImage?: Maybe<{
            __typename?: 'MediaItem';
            sourceUrl?: Maybe<string>;
          }>;
          paddingTop?: Maybe<{
            __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_AddressBarSettings_PaddingTop';
            paddingTopDesktop?: Maybe<string>;
            paddingTopTablet?: Maybe<string>;
            paddingTopMobile?: Maybe<string>;
          }>;
          paddingBottom?: Maybe<{
            __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_AddressBarSettings_PaddingBottom';
            paddingBottomDesktop?: Maybe<string>;
            paddingBottomTablet?: Maybe<string>;
            paddingBottomMobile?: Maybe<string>;
          }>;
        }>;
        mastheadComponentSettings?: Maybe<{
          __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_MastheadComponentSettings';
          titleColor?: Maybe<string>;
          textColor?: Maybe<string>;
          textAlignment?: Maybe<string>;
          subtitleColor?: Maybe<string>;
          overlayYPosition?: Maybe<string>;
          overlayXPosition?: Maybe<string>;
          overlayMobilePosition?: Maybe<string>;
          overlayBackgroundOpacity?: Maybe<number>;
          overlayBackgroundColour?: Maybe<string>;
          overlayMobileBackgroundColour?: Maybe<string>;
          mobileTitleColour?: Maybe<string>;
          mobileSubtitleColour?: Maybe<string>;
          overlayBlur?: Maybe<number>;
          overlayFullWidth?: Maybe<string>;
          mastheadHeight?: Maybe<string>;
          mastheadGradient?: Maybe<string>;
          videoCtaBackgroundColor?: Maybe<string>;
          videoCtaBorderColor?: Maybe<string>;
          videoCtaMuteColor?: Maybe<string>;
          videoCtaPlaypauseColor?: Maybe<string>;
          videoCtaUnmuteColor?: Maybe<string>;
          videoMastheadCtaPosition?: Maybe<string>;
          videoCtaShadow?: Maybe<string>;
          contentBackgroundPosition?: Maybe<string>;
          logoXPosition?: Maybe<string>;
          logoYPosition?: Maybe<string>;
          heroCarouselArrowColour?: Maybe<string>;
          heroCarouselBackgroundColour?: Maybe<string>;
          heroCarouselTextColour?: Maybe<string>;
          heroCarouselCounterColour?: Maybe<string>;
          heroCarouselDividerColour?: Maybe<string>;
          heroCarouselRoundedCorners?: Maybe<string>;
          showHide?: Maybe<boolean>;
          hideAnimations?: Maybe<string>;
          animationDirection?: Maybe<string>;
          hideParallax?: Maybe<string>;
          sliderAnimations?: Maybe<string>;
          mastheadSpacer?: Maybe<boolean>;
          mastheadSpacerColour?: Maybe<string>;
          paddingTop?: Maybe<{
            __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_MastheadComponentSettings_PaddingTop';
            paddingTopDesktop?: Maybe<string>;
            paddingTopTablet?: Maybe<string>;
            paddingTopMobile?: Maybe<string>;
          }>;
          paddingBottom?: Maybe<{
            __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_MastheadComponentSettings_PaddingBottom';
            paddingBottomDesktop?: Maybe<string>;
            paddingBottomTablet?: Maybe<string>;
            paddingBottomMobile?: Maybe<string>;
          }>;
        }>;
        halfAndHalfComponentSettings?: Maybe<{
          __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_HalfAndHalfComponentSettings';
          fieldGroupName?: Maybe<string>;
          accordionBorderColor?: Maybe<string>;
          accordionIconColor?: Maybe<string>;
          accordionIconColorActive?: Maybe<string>;
          accordionPanelBackgroundColor?: Maybe<string>;
          accordionPanelLabelColor?: Maybe<string>;
          accordionRowLabelColor?: Maybe<string>;
          accordionRowTextColor?: Maybe<string>;
          componentBackgroundColor?: Maybe<string>;
          componentBackgroundSize?: Maybe<string>;
          componentBackgroundRepeat?: Maybe<string>;
          componentBackgroundPosition?: Maybe<string>;
          contentBackgroundColor?: Maybe<string>;
          contentBackgroundSize?: Maybe<string>;
          contentBackgroundRepeat?: Maybe<string>;
          contentBackgroundPosition?: Maybe<string>;
          contentBackgroundColorLayerTwo?: Maybe<string>;
          subtitleColor?: Maybe<string>;
          textAlignment?: Maybe<string>;
          textColor?: Maybe<string>;
          titleColor?: Maybe<string>;
          showHide?: Maybe<boolean>;
          hideAnimations?: Maybe<string>;
          animationDirection?: Maybe<string>;
          hideParallax?: Maybe<string>;
          sliderAnimations?: Maybe<string>;
          zoomOnHover?: Maybe<string>;
          containerMaxWidth?: Maybe<string>;
          headingsNoTranslate?: Maybe<boolean>;
          componentBackgroundImage?: Maybe<{
            __typename?: 'MediaItem';
            sourceUrl?: Maybe<string>;
          }>;
          contentBackgroundImage?: Maybe<{
            __typename?: 'MediaItem';
            sourceUrl?: Maybe<string>;
          }>;
          paddingTop?: Maybe<{
            __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_HalfAndHalfComponentSettings_PaddingTop';
            paddingTopDesktop?: Maybe<string>;
            paddingTopTablet?: Maybe<string>;
            paddingTopMobile?: Maybe<string>;
          }>;
          paddingBottom?: Maybe<{
            __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_HalfAndHalfComponentSettings_PaddingBottom';
            paddingBottomDesktop?: Maybe<string>;
            paddingBottomTablet?: Maybe<string>;
            paddingBottomMobile?: Maybe<string>;
          }>;
        }>;
        singleItemSliderComponentSettings?: Maybe<{
          __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_SingleItemSliderComponentSettings';
          fieldGroupName?: Maybe<string>;
          componentBackgroundColor?: Maybe<string>;
          componentBackgroundSize?: Maybe<string>;
          componentBackgroundRepeat?: Maybe<string>;
          componentBackgroundPosition?: Maybe<string>;
          contentBackgroundColor?: Maybe<string>;
          contentBackgroundSize?: Maybe<string>;
          contentBackgroundRepeat?: Maybe<string>;
          contentBackgroundPosition?: Maybe<string>;
          contentBackgroundColorLayerTwo?: Maybe<string>;
          subtitleColor?: Maybe<string>;
          textAlignment?: Maybe<string>;
          textColor?: Maybe<string>;
          titleColor?: Maybe<string>;
          showHide?: Maybe<boolean>;
          hideAnimations?: Maybe<string>;
          animationDirection?: Maybe<string>;
          hideParallax?: Maybe<string>;
          sliderAnimations?: Maybe<string>;
          zoomOnHover?: Maybe<string>;
          containerMaxWidth?: Maybe<string>;
          headingsNoTranslate?: Maybe<boolean>;
          componentBackgroundImage?: Maybe<{
            __typename?: 'MediaItem';
            sourceUrl?: Maybe<string>;
          }>;
          contentBackgroundImage?: Maybe<{
            __typename?: 'MediaItem';
            sourceUrl?: Maybe<string>;
          }>;
          paddingTop?: Maybe<{
            __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_SingleItemSliderComponentSettings_PaddingTop';
            paddingTopDesktop?: Maybe<string>;
            paddingTopTablet?: Maybe<string>;
            paddingTopMobile?: Maybe<string>;
          }>;
          paddingBottom?: Maybe<{
            __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_SingleItemSliderComponentSettings_PaddingBottom';
            paddingBottomDesktop?: Maybe<string>;
            paddingBottomTablet?: Maybe<string>;
            paddingBottomMobile?: Maybe<string>;
          }>;
        }>;
        fullWidthImageComponentSettings?: Maybe<{
          __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_FullWidthImageComponentSettings';
          fieldGroupName?: Maybe<string>;
          componentBackgroundColor?: Maybe<string>;
          componentBackgroundSize?: Maybe<string>;
          componentBackgroundRepeat?: Maybe<string>;
          componentBackgroundPosition?: Maybe<string>;
          contentBackgroundColor?: Maybe<string>;
          contentBackgroundSize?: Maybe<string>;
          contentBackgroundRepeat?: Maybe<string>;
          contentBackgroundPosition?: Maybe<string>;
          contentBackgroundColorLayerTwo?: Maybe<string>;
          subtitleColor?: Maybe<string>;
          textAlignment?: Maybe<string>;
          textColor?: Maybe<string>;
          titleColor?: Maybe<string>;
          showHide?: Maybe<boolean>;
          hideAnimations?: Maybe<string>;
          animationDirection?: Maybe<string>;
          hideParallax?: Maybe<string>;
          sliderAnimations?: Maybe<string>;
          zoomOnHover?: Maybe<string>;
          containerMaxWidth?: Maybe<string>;
          headingsNoTranslate?: Maybe<boolean>;
          componentBackgroundImage?: Maybe<{
            __typename?: 'MediaItem';
            sourceUrl?: Maybe<string>;
          }>;
          contentBackgroundImage?: Maybe<{
            __typename?: 'MediaItem';
            sourceUrl?: Maybe<string>;
          }>;
          paddingTop?: Maybe<{
            __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_FullWidthImageComponentSettings_PaddingTop';
            paddingTopDesktop?: Maybe<string>;
            paddingTopTablet?: Maybe<string>;
            paddingTopMobile?: Maybe<string>;
          }>;
          paddingBottom?: Maybe<{
            __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_FullWidthImageComponentSettings_PaddingBottom';
            paddingBottomDesktop?: Maybe<string>;
            paddingBottomTablet?: Maybe<string>;
            paddingBottomMobile?: Maybe<string>;
          }>;
        }>;
        introductionComponentSettings?: Maybe<{
          __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_IntroductionComponentSettings';
          fieldGroupName?: Maybe<string>;
          componentBackgroundColor?: Maybe<string>;
          componentBackgroundSize?: Maybe<string>;
          componentBackgroundRepeat?: Maybe<string>;
          componentBackgroundPosition?: Maybe<string>;
          contentBackgroundColor?: Maybe<string>;
          contentBackgroundSize?: Maybe<string>;
          contentBackgroundRepeat?: Maybe<string>;
          contentBackgroundPosition?: Maybe<string>;
          contentBackgroundColorLayerTwo?: Maybe<string>;
          subtitleColor?: Maybe<string>;
          textAlignment?: Maybe<string>;
          textColor?: Maybe<string>;
          titleColor?: Maybe<string>;
          showHide?: Maybe<boolean>;
          hideAnimations?: Maybe<string>;
          animationDirection?: Maybe<string>;
          hideParallax?: Maybe<string>;
          sliderAnimations?: Maybe<string>;
          zoomOnHover?: Maybe<string>;
          containerMaxWidth?: Maybe<string>;
          headingsNoTranslate?: Maybe<boolean>;
          componentBackgroundImage?: Maybe<{
            __typename?: 'MediaItem';
            sourceUrl?: Maybe<string>;
          }>;
          contentBackgroundImage?: Maybe<{
            __typename?: 'MediaItem';
            sourceUrl?: Maybe<string>;
          }>;
          paddingTop?: Maybe<{
            __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_IntroductionComponentSettings_PaddingTop';
            paddingTopDesktop?: Maybe<string>;
            paddingTopTablet?: Maybe<string>;
            paddingTopMobile?: Maybe<string>;
          }>;
          paddingBottom?: Maybe<{
            __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_IntroductionComponentSettings_PaddingBottom';
            paddingBottomDesktop?: Maybe<string>;
            paddingBottomTablet?: Maybe<string>;
            paddingBottomMobile?: Maybe<string>;
          }>;
        }>;
        hotelTitleComponentSettings?: Maybe<{
          __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_HotelTitleComponentSettings';
          fieldGroupName?: Maybe<string>;
          componentBackgroundColor?: Maybe<string>;
          componentBackgroundSize?: Maybe<string>;
          componentBackgroundRepeat?: Maybe<string>;
          componentBackgroundPosition?: Maybe<string>;
          contentBackgroundColor?: Maybe<string>;
          contentBackgroundSize?: Maybe<string>;
          contentBackgroundRepeat?: Maybe<string>;
          contentBackgroundPosition?: Maybe<string>;
          contentBackgroundColorLayerTwo?: Maybe<string>;
          subtitleColor?: Maybe<string>;
          textAlignment?: Maybe<string>;
          textColor?: Maybe<string>;
          titleColor?: Maybe<string>;
          showHide?: Maybe<boolean>;
          hideAnimations?: Maybe<string>;
          animationDirection?: Maybe<string>;
          hideParallax?: Maybe<string>;
          sliderAnimations?: Maybe<string>;
          zoomOnHover?: Maybe<string>;
          containerMaxWidth?: Maybe<string>;
          headingsNoTranslate?: Maybe<boolean>;
          componentBackgroundImage?: Maybe<{
            __typename?: 'MediaItem';
            sourceUrl?: Maybe<string>;
          }>;
          contentBackgroundImage?: Maybe<{
            __typename?: 'MediaItem';
            sourceUrl?: Maybe<string>;
          }>;
          paddingTop?: Maybe<{
            __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_HotelTitleComponentSettings_PaddingTop';
            paddingTopDesktop?: Maybe<string>;
            paddingTopTablet?: Maybe<string>;
            paddingTopMobile?: Maybe<string>;
          }>;
          paddingBottom?: Maybe<{
            __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_HotelTitleComponentSettings_PaddingBottom';
            paddingBottomDesktop?: Maybe<string>;
            paddingBottomTablet?: Maybe<string>;
            paddingBottomMobile?: Maybe<string>;
          }>;
        }>;
        locationGoogleMapComponentSettings?: Maybe<{
          __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_LocationGoogleMapComponentSettings';
          fieldGroupName?: Maybe<string>;
          componentBackgroundColor?: Maybe<string>;
          componentBackgroundSize?: Maybe<string>;
          componentBackgroundRepeat?: Maybe<string>;
          componentBackgroundPosition?: Maybe<string>;
          contentBackgroundColor?: Maybe<string>;
          contentBackgroundSize?: Maybe<string>;
          contentBackgroundRepeat?: Maybe<string>;
          contentBackgroundPosition?: Maybe<string>;
          contentBackgroundColorLayerTwo?: Maybe<string>;
          subtitleColor?: Maybe<string>;
          textAlignment?: Maybe<string>;
          textColor?: Maybe<string>;
          titleColor?: Maybe<string>;
          showHide?: Maybe<boolean>;
          hideAnimations?: Maybe<string>;
          animationDirection?: Maybe<string>;
          hideParallax?: Maybe<string>;
          sliderAnimations?: Maybe<string>;
          zoomOnHover?: Maybe<string>;
          containerMaxWidth?: Maybe<string>;
          headingsNoTranslate?: Maybe<boolean>;
          componentBackgroundImage?: Maybe<{
            __typename?: 'MediaItem';
            sourceUrl?: Maybe<string>;
          }>;
          contentBackgroundImage?: Maybe<{
            __typename?: 'MediaItem';
            sourceUrl?: Maybe<string>;
          }>;
          paddingTop?: Maybe<{
            __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_LocationGoogleMapComponentSettings_PaddingTop';
            paddingTopDesktop?: Maybe<string>;
            paddingTopTablet?: Maybe<string>;
            paddingTopMobile?: Maybe<string>;
          }>;
          paddingBottom?: Maybe<{
            __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_LocationGoogleMapComponentSettings_PaddingBottom';
            paddingBottomDesktop?: Maybe<string>;
            paddingBottomTablet?: Maybe<string>;
            paddingBottomMobile?: Maybe<string>;
          }>;
        }>;
        imageRevealComponentSettings?: Maybe<{
          __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_ImageRevealComponentSettings';
          fieldGroupName?: Maybe<string>;
          componentBackgroundColor?: Maybe<string>;
          componentBackgroundSize?: Maybe<string>;
          componentBackgroundRepeat?: Maybe<string>;
          componentBackgroundPosition?: Maybe<string>;
          contentBackgroundColor?: Maybe<string>;
          contentBackgroundSize?: Maybe<string>;
          contentBackgroundRepeat?: Maybe<string>;
          contentBackgroundPosition?: Maybe<string>;
          contentBackgroundColorLayerTwo?: Maybe<string>;
          subtitleColor?: Maybe<string>;
          textAlignment?: Maybe<string>;
          textColor?: Maybe<string>;
          titleColor?: Maybe<string>;
          showHide?: Maybe<boolean>;
          hideAnimations?: Maybe<string>;
          animationDirection?: Maybe<string>;
          hideParallax?: Maybe<string>;
          sliderAnimations?: Maybe<string>;
          zoomOnHover?: Maybe<string>;
          containerMaxWidth?: Maybe<string>;
          headingsNoTranslate?: Maybe<boolean>;
          componentBackgroundImage?: Maybe<{
            __typename?: 'MediaItem';
            sourceUrl?: Maybe<string>;
          }>;
          contentBackgroundImage?: Maybe<{
            __typename?: 'MediaItem';
            sourceUrl?: Maybe<string>;
          }>;
          paddingTop?: Maybe<{
            __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_ImageRevealComponentSettings_PaddingTop';
            paddingTopDesktop?: Maybe<string>;
            paddingTopTablet?: Maybe<string>;
            paddingTopMobile?: Maybe<string>;
          }>;
          paddingBottom?: Maybe<{
            __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_ImageRevealComponentSettings_PaddingBottom';
            paddingBottomDesktop?: Maybe<string>;
            paddingBottomTablet?: Maybe<string>;
            paddingBottomMobile?: Maybe<string>;
          }>;
        }>;
        twoColumnIconListSettings?: Maybe<{
          __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_TwoColumnIconListSettings';
          fieldGroupName?: Maybe<string>;
          componentBackgroundColor?: Maybe<string>;
          componentBackgroundSize?: Maybe<string>;
          componentBackgroundRepeat?: Maybe<string>;
          componentBackgroundPosition?: Maybe<string>;
          contentBackgroundColor?: Maybe<string>;
          contentBackgroundSize?: Maybe<string>;
          contentBackgroundRepeat?: Maybe<string>;
          contentBackgroundPosition?: Maybe<string>;
          contentBackgroundColorLayerTwo?: Maybe<string>;
          subtitleColor?: Maybe<string>;
          textAlignment?: Maybe<string>;
          textColor?: Maybe<string>;
          titleColor?: Maybe<string>;
          showHide?: Maybe<boolean>;
          hideAnimations?: Maybe<string>;
          animationDirection?: Maybe<string>;
          hideParallax?: Maybe<string>;
          sliderAnimations?: Maybe<string>;
          zoomOnHover?: Maybe<string>;
          containerMaxWidth?: Maybe<string>;
          headingsNoTranslate?: Maybe<boolean>;
          componentBackgroundImage?: Maybe<{
            __typename?: 'MediaItem';
            sourceUrl?: Maybe<string>;
          }>;
          contentBackgroundImage?: Maybe<{
            __typename?: 'MediaItem';
            sourceUrl?: Maybe<string>;
          }>;
          paddingTop?: Maybe<{
            __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_TwoColumnIconListSettings_PaddingTop';
            paddingTopDesktop?: Maybe<string>;
            paddingTopTablet?: Maybe<string>;
            paddingTopMobile?: Maybe<string>;
          }>;
          paddingBottom?: Maybe<{
            __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_TwoColumnIconListSettings_PaddingBottom';
            paddingBottomDesktop?: Maybe<string>;
            paddingBottomTablet?: Maybe<string>;
            paddingBottomMobile?: Maybe<string>;
          }>;
        }>;
        twoOneMediaCopySettings?: Maybe<{
          __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_TwoOneMediaCopySettings';
          fieldGroupName?: Maybe<string>;
          componentBackgroundColor?: Maybe<string>;
          componentBackgroundSize?: Maybe<string>;
          componentBackgroundRepeat?: Maybe<string>;
          componentBackgroundPosition?: Maybe<string>;
          contentBackgroundColor?: Maybe<string>;
          contentBackgroundSize?: Maybe<string>;
          contentBackgroundRepeat?: Maybe<string>;
          contentBackgroundPosition?: Maybe<string>;
          contentBackgroundColorLayerTwo?: Maybe<string>;
          subtitleColor?: Maybe<string>;
          textAlignment?: Maybe<string>;
          textColor?: Maybe<string>;
          titleColor?: Maybe<string>;
          showHide?: Maybe<boolean>;
          hideAnimations?: Maybe<string>;
          animationDirection?: Maybe<string>;
          hideParallax?: Maybe<string>;
          sliderAnimations?: Maybe<string>;
          zoomOnHover?: Maybe<string>;
          containerMaxWidth?: Maybe<string>;
          headingsNoTranslate?: Maybe<boolean>;
          componentBackgroundImage?: Maybe<{
            __typename?: 'MediaItem';
            sourceUrl?: Maybe<string>;
          }>;
          contentBackgroundImage?: Maybe<{
            __typename?: 'MediaItem';
            sourceUrl?: Maybe<string>;
          }>;
          paddingTop?: Maybe<{
            __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_TwoOneMediaCopySettings_PaddingTop';
            paddingTopDesktop?: Maybe<string>;
            paddingTopTablet?: Maybe<string>;
            paddingTopMobile?: Maybe<string>;
          }>;
          paddingBottom?: Maybe<{
            __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_TwoOneMediaCopySettings_PaddingBottom';
            paddingBottomDesktop?: Maybe<string>;
            paddingBottomTablet?: Maybe<string>;
            paddingBottomMobile?: Maybe<string>;
          }>;
        }>;
        twoTwoMediaCopySettings?: Maybe<{
          __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_TwoTwoMediaCopySettings';
          fieldGroupName?: Maybe<string>;
          horizontalDirection?: Maybe<string>;
          componentBackgroundColor?: Maybe<string>;
          componentBackgroundSize?: Maybe<string>;
          componentBackgroundRepeat?: Maybe<string>;
          componentBackgroundPosition?: Maybe<string>;
          contentBackgroundColor?: Maybe<string>;
          contentBackgroundSize?: Maybe<string>;
          contentBackgroundRepeat?: Maybe<string>;
          contentBackgroundPosition?: Maybe<string>;
          contentBackgroundColorLayerTwo?: Maybe<string>;
          subtitleColor?: Maybe<string>;
          textAlignment?: Maybe<string>;
          textColor?: Maybe<string>;
          titleColor?: Maybe<string>;
          showHide?: Maybe<boolean>;
          hideAnimations?: Maybe<string>;
          animationDirection?: Maybe<string>;
          hideParallax?: Maybe<string>;
          sliderAnimations?: Maybe<string>;
          zoomOnHover?: Maybe<string>;
          containerMaxWidth?: Maybe<string>;
          headingsNoTranslate?: Maybe<boolean>;
          componentBackgroundImage?: Maybe<{
            __typename?: 'MediaItem';
            sourceUrl?: Maybe<string>;
          }>;
          contentBackgroundImage?: Maybe<{
            __typename?: 'MediaItem';
            sourceUrl?: Maybe<string>;
          }>;
          paddingTop?: Maybe<{
            __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_TwoTwoMediaCopySettings_PaddingTop';
            paddingTopDesktop?: Maybe<string>;
            paddingTopTablet?: Maybe<string>;
            paddingTopMobile?: Maybe<string>;
          }>;
          paddingBottom?: Maybe<{
            __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_TwoTwoMediaCopySettings_PaddingBottom';
            paddingBottomDesktop?: Maybe<string>;
            paddingBottomTablet?: Maybe<string>;
            paddingBottomMobile?: Maybe<string>;
          }>;
        }>;
        offersCuratedComponentSettings?: Maybe<{
          __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_OffersCuratedComponentSettings';
          fieldGroupName?: Maybe<string>;
          componentBackgroundColor?: Maybe<string>;
          componentBackgroundSize?: Maybe<string>;
          componentBackgroundRepeat?: Maybe<string>;
          componentBackgroundPosition?: Maybe<string>;
          contentBackgroundColor?: Maybe<string>;
          contentBackgroundSize?: Maybe<string>;
          contentBackgroundRepeat?: Maybe<string>;
          contentBackgroundPosition?: Maybe<string>;
          contentBackgroundColorLayerTwo?: Maybe<string>;
          subtitleColor?: Maybe<string>;
          textAlignment?: Maybe<string>;
          textColor?: Maybe<string>;
          titleColor?: Maybe<string>;
          showHide?: Maybe<boolean>;
          hideAnimations?: Maybe<string>;
          animationDirection?: Maybe<string>;
          hideParallax?: Maybe<string>;
          sliderAnimations?: Maybe<string>;
          zoomOnHover?: Maybe<string>;
          containerMaxWidth?: Maybe<string>;
          headingsNoTranslate?: Maybe<boolean>;
          componentBackgroundImage?: Maybe<{
            __typename?: 'MediaItem';
            sourceUrl?: Maybe<string>;
          }>;
          contentBackgroundImage?: Maybe<{
            __typename?: 'MediaItem';
            sourceUrl?: Maybe<string>;
          }>;
          paddingTop?: Maybe<{
            __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_OffersCuratedComponentSettings_PaddingTop';
            paddingTopDesktop?: Maybe<string>;
            paddingTopTablet?: Maybe<string>;
            paddingTopMobile?: Maybe<string>;
          }>;
          paddingBottom?: Maybe<{
            __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_OffersCuratedComponentSettings_PaddingBottom';
            paddingBottomDesktop?: Maybe<string>;
            paddingBottomTablet?: Maybe<string>;
            paddingBottomMobile?: Maybe<string>;
          }>;
        }>;
        multiItemCarouselComponentSettings?: Maybe<{
          __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_MultiItemCarouselComponentSettings';
          fieldGroupName?: Maybe<string>;
          componentBackgroundColor?: Maybe<string>;
          componentBackgroundSize?: Maybe<string>;
          componentBackgroundRepeat?: Maybe<string>;
          componentBackgroundPosition?: Maybe<string>;
          contentBackgroundColor?: Maybe<string>;
          contentBackgroundSize?: Maybe<string>;
          contentBackgroundRepeat?: Maybe<string>;
          contentBackgroundPosition?: Maybe<string>;
          contentBackgroundColorLayerTwo?: Maybe<string>;
          subtitleColor?: Maybe<string>;
          textAlignment?: Maybe<string>;
          textColor?: Maybe<string>;
          titleColor?: Maybe<string>;
          showHide?: Maybe<boolean>;
          hideAnimations?: Maybe<string>;
          animationDirection?: Maybe<string>;
          hideParallax?: Maybe<string>;
          sliderAnimations?: Maybe<string>;
          zoomOnHover?: Maybe<string>;
          containerMaxWidth?: Maybe<string>;
          headingsNoTranslate?: Maybe<boolean>;
          headerTitleColour?: Maybe<string>;
          headerCopyColour?: Maybe<string>;
          uiPrimaryColour?: Maybe<string>;
          uiSecondaryColour?: Maybe<string>;
          titleSize?: Maybe<string>;
          tileTitleBackgroundColour?: Maybe<string>;
          headingStyle?: Maybe<string>;
          tileTitleAlignment?: Maybe<string>;
          tileTitleLayout?: Maybe<string>;
          componentBackgroundImage?: Maybe<{
            __typename?: 'MediaItem';
            sourceUrl?: Maybe<string>;
          }>;
          contentBackgroundImage?: Maybe<{
            __typename?: 'MediaItem';
            sourceUrl?: Maybe<string>;
          }>;
          paddingTop?: Maybe<{
            __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_MultiItemCarouselComponentSettings_PaddingTop';
            paddingTopDesktop?: Maybe<string>;
            paddingTopTablet?: Maybe<string>;
            paddingTopMobile?: Maybe<string>;
          }>;
          paddingBottom?: Maybe<{
            __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_MultiItemCarouselComponentSettings_PaddingBottom';
            paddingBottomDesktop?: Maybe<string>;
            paddingBottomTablet?: Maybe<string>;
            paddingBottomMobile?: Maybe<string>;
          }>;
        }>;
        socialMediaFeedSettings?: Maybe<{
          __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_SocialMediaFeedSettings';
          fieldGroupName?: Maybe<string>;
          socialMediaIconColor?: Maybe<string>;
          componentBackgroundColor?: Maybe<string>;
          componentBackgroundSize?: Maybe<string>;
          componentBackgroundRepeat?: Maybe<string>;
          componentBackgroundPosition?: Maybe<string>;
          contentBackgroundColor?: Maybe<string>;
          contentBackgroundSize?: Maybe<string>;
          contentBackgroundRepeat?: Maybe<string>;
          contentBackgroundPosition?: Maybe<string>;
          contentBackgroundColorLayerTwo?: Maybe<string>;
          subtitleColor?: Maybe<string>;
          textAlignment?: Maybe<string>;
          textColor?: Maybe<string>;
          titleColor?: Maybe<string>;
          showHide?: Maybe<boolean>;
          hideAnimations?: Maybe<string>;
          animationDirection?: Maybe<string>;
          hideParallax?: Maybe<string>;
          sliderAnimations?: Maybe<string>;
          zoomOnHover?: Maybe<string>;
          containerMaxWidth?: Maybe<string>;
          headingsNoTranslate?: Maybe<boolean>;
          componentBackgroundImage?: Maybe<{
            __typename?: 'MediaItem';
            sourceUrl?: Maybe<string>;
          }>;
          contentBackgroundImage?: Maybe<{
            __typename?: 'MediaItem';
            sourceUrl?: Maybe<string>;
          }>;
          paddingTop?: Maybe<{
            __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_SocialMediaFeedSettings_PaddingTop';
            paddingTopDesktop?: Maybe<string>;
            paddingTopTablet?: Maybe<string>;
            paddingTopMobile?: Maybe<string>;
          }>;
          paddingBottom?: Maybe<{
            __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_SocialMediaFeedSettings_PaddingBottom';
            paddingBottomDesktop?: Maybe<string>;
            paddingBottomTablet?: Maybe<string>;
            paddingBottomMobile?: Maybe<string>;
          }>;
        }>;
        twoColumnCompareComponentSettings?: Maybe<{
          __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_TwoColumnCompareComponentSettings';
          fieldGroupName?: Maybe<string>;
          showHide?: Maybe<boolean>;
          componentBackgroundColor?: Maybe<string>;
          componentBackgroundSize?: Maybe<string>;
          componentBackgroundRepeat?: Maybe<string>;
          componentBackgroundPosition?: Maybe<string>;
          contentBackgroundColor?: Maybe<string>;
          contentBackgroundSize?: Maybe<string>;
          contentBackgroundRepeat?: Maybe<string>;
          contentBackgroundPosition?: Maybe<string>;
          contentBackgroundColorLayerTwo?: Maybe<string>;
          subtitleColor?: Maybe<string>;
          textAlignment?: Maybe<string>;
          textColor?: Maybe<string>;
          titleColor?: Maybe<string>;
          hideAnimations?: Maybe<string>;
          animationDirection?: Maybe<string>;
          hideParallax?: Maybe<string>;
          sliderAnimations?: Maybe<string>;
          zoomOnHover?: Maybe<string>;
          containerMaxWidth?: Maybe<string>;
          headingsNoTranslate?: Maybe<boolean>;
          componentBackgroundImage?: Maybe<{
            __typename?: 'MediaItem';
            sourceUrl?: Maybe<string>;
          }>;
          contentBackgroundImage?: Maybe<{
            __typename?: 'MediaItem';
            sourceUrl?: Maybe<string>;
          }>;
          paddingTop?: Maybe<{
            __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_TwoColumnCompareComponentSettings_PaddingTop';
            paddingTopDesktop?: Maybe<string>;
            paddingTopTablet?: Maybe<string>;
            paddingTopMobile?: Maybe<string>;
          }>;
          paddingBottom?: Maybe<{
            __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_TwoColumnCompareComponentSettings_PaddingBottom';
            paddingBottomDesktop?: Maybe<string>;
            paddingBottomTablet?: Maybe<string>;
            paddingBottomMobile?: Maybe<string>;
          }>;
        }>;
        multiColumnComponentSettings?: Maybe<{
          __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_MultiColumnComponentSettings';
          fieldGroupName?: Maybe<string>;
          showHide?: Maybe<boolean>;
          accordionBorderColor?: Maybe<string>;
          accordionIconColor?: Maybe<string>;
          accordionIconColorActive?: Maybe<string>;
          accordionPanelBackgroundColor?: Maybe<string>;
          accordionPanelLabelColor?: Maybe<string>;
          accordionRowLabelColor?: Maybe<string>;
          accordionRowTextColor?: Maybe<string>;
          componentBackgroundColor?: Maybe<string>;
          componentBackgroundSize?: Maybe<string>;
          componentBackgroundRepeat?: Maybe<string>;
          componentBackgroundPosition?: Maybe<string>;
          contentBackgroundColor?: Maybe<string>;
          contentBackgroundSize?: Maybe<string>;
          contentBackgroundRepeat?: Maybe<string>;
          contentBackgroundPosition?: Maybe<string>;
          contentBackgroundColorLayerTwo?: Maybe<string>;
          subtitleColor?: Maybe<string>;
          textAlignment?: Maybe<string>;
          textColor?: Maybe<string>;
          titleColor?: Maybe<string>;
          hideAnimations?: Maybe<string>;
          animationDirection?: Maybe<string>;
          hideParallax?: Maybe<string>;
          sliderAnimations?: Maybe<string>;
          zoomOnHover?: Maybe<string>;
          containerMaxWidth?: Maybe<string>;
          headingsNoTranslate?: Maybe<boolean>;
          filterActiveTextColour?: Maybe<string>;
          filterBackgroundColour?: Maybe<string>;
          filterTextColour?: Maybe<string>;
          uiBackgroundColour?: Maybe<string>;
          uiControlsColour?: Maybe<string>;
          headerTitleColour?: Maybe<string>;
          headerCopyColour?: Maybe<string>;
          componentBackgroundImage?: Maybe<{
            __typename?: 'MediaItem';
            sourceUrl?: Maybe<string>;
          }>;
          contentBackgroundImage?: Maybe<{
            __typename?: 'MediaItem';
            sourceUrl?: Maybe<string>;
          }>;
          paddingTop?: Maybe<{
            __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_MultiColumnComponentSettings_PaddingTop';
            paddingTopDesktop?: Maybe<string>;
            paddingTopTablet?: Maybe<string>;
            paddingTopMobile?: Maybe<string>;
          }>;
          paddingBottom?: Maybe<{
            __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_MultiColumnComponentSettings_PaddingBottom';
            paddingBottomDesktop?: Maybe<string>;
            paddingBottomTablet?: Maybe<string>;
            paddingBottomMobile?: Maybe<string>;
          }>;
        }>;
        spacerComponentSettings?: Maybe<{
          __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_SpacerComponentSettings';
          fieldGroupName?: Maybe<string>;
          componentBackgroundColor?: Maybe<string>;
          componentBackgroundSize?: Maybe<string>;
          componentBackgroundRepeat?: Maybe<string>;
          componentBackgroundPosition?: Maybe<string>;
          contentBackgroundColor?: Maybe<string>;
          contentBackgroundSize?: Maybe<string>;
          contentBackgroundRepeat?: Maybe<string>;
          contentBackgroundPosition?: Maybe<string>;
          contentBackgroundColorLayerTwo?: Maybe<string>;
          subtitleColor?: Maybe<string>;
          textAlignment?: Maybe<string>;
          textColor?: Maybe<string>;
          titleColor?: Maybe<string>;
          showHide?: Maybe<boolean>;
          hideAnimations?: Maybe<string>;
          animationDirection?: Maybe<string>;
          hideParallax?: Maybe<string>;
          sliderAnimations?: Maybe<string>;
          zoomOnHover?: Maybe<string>;
          containerMaxWidth?: Maybe<string>;
          headingsNoTranslate?: Maybe<boolean>;
          componentBackgroundImage?: Maybe<{
            __typename?: 'MediaItem';
            sourceUrl?: Maybe<string>;
          }>;
          contentBackgroundImage?: Maybe<{
            __typename?: 'MediaItem';
            sourceUrl?: Maybe<string>;
          }>;
          paddingTop?: Maybe<{
            __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_SpacerComponentSettings_PaddingTop';
            paddingTopDesktop?: Maybe<string>;
            paddingTopTablet?: Maybe<string>;
            paddingTopMobile?: Maybe<string>;
          }>;
          paddingBottom?: Maybe<{
            __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_SpacerComponentSettings_PaddingBottom';
            paddingBottomDesktop?: Maybe<string>;
            paddingBottomTablet?: Maybe<string>;
            paddingBottomMobile?: Maybe<string>;
          }>;
        }>;
        retailFlyoutComponentSettings?: Maybe<{
          __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_RetailFlyoutComponentSettings';
          fieldGroupName?: Maybe<string>;
          componentBackgroundColor?: Maybe<string>;
          componentBackgroundSize?: Maybe<string>;
          componentBackgroundRepeat?: Maybe<string>;
          componentBackgroundPosition?: Maybe<string>;
          contentBackgroundColor?: Maybe<string>;
          contentBackgroundSize?: Maybe<string>;
          contentBackgroundRepeat?: Maybe<string>;
          contentBackgroundPosition?: Maybe<string>;
          contentBackgroundColorLayerTwo?: Maybe<string>;
          subtitleColor?: Maybe<string>;
          textAlignment?: Maybe<string>;
          textColor?: Maybe<string>;
          titleColor?: Maybe<string>;
          showHide?: Maybe<boolean>;
          hideAnimations?: Maybe<string>;
          animationDirection?: Maybe<string>;
          hideParallax?: Maybe<string>;
          sliderAnimations?: Maybe<string>;
          zoomOnHover?: Maybe<string>;
          containerMaxWidth?: Maybe<string>;
          headingsNoTranslate?: Maybe<boolean>;
          componentBackgroundImage?: Maybe<{
            __typename?: 'MediaItem';
            sourceUrl?: Maybe<string>;
          }>;
          contentBackgroundImage?: Maybe<{
            __typename?: 'MediaItem';
            sourceUrl?: Maybe<string>;
          }>;
          paddingTop?: Maybe<{
            __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_RetailFlyoutComponentSettings_PaddingTop';
            paddingTopDesktop?: Maybe<string>;
            paddingTopTablet?: Maybe<string>;
            paddingTopMobile?: Maybe<string>;
          }>;
          paddingBottom?: Maybe<{
            __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_RetailFlyoutComponentSettings_PaddingBottom';
            paddingBottomDesktop?: Maybe<string>;
            paddingBottomTablet?: Maybe<string>;
            paddingBottomMobile?: Maybe<string>;
          }>;
        }>;
        retailRailComponentSettings?: Maybe<{
          __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_RetailRailComponentSettings';
          componentBackgroundColor?: Maybe<string>;
          componentBackgroundPosition?: Maybe<string>;
          componentBackgroundRepeat?: Maybe<string>;
          componentBackgroundSize?: Maybe<string>;
          contentBackgroundColor?: Maybe<string>;
          contentBackgroundPosition?: Maybe<string>;
          contentBackgroundRepeat?: Maybe<string>;
          contentBackgroundSize?: Maybe<string>;
          textAlignment?: Maybe<string>;
          textColor?: Maybe<string>;
          titleColor?: Maybe<string>;
          closeIconColour?: Maybe<string>;
          closeTextColour?: Maybe<string>;
          showHide?: Maybe<boolean>;
          componentBackgroundImage?: Maybe<{
            __typename?: 'MediaItem';
            sourceUrl?: Maybe<string>;
          }>;
          contentBackgroundImage?: Maybe<{
            __typename?: 'MediaItem';
            sourceUrl?: Maybe<string>;
          }>;
        }>;
        galleryWallComponentSettings?: Maybe<{
          __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_GalleryWallComponentSettings';
          fieldGroupName?: Maybe<string>;
          componentBackgroundColor?: Maybe<string>;
          componentBackgroundSize?: Maybe<string>;
          componentBackgroundRepeat?: Maybe<string>;
          componentBackgroundPosition?: Maybe<string>;
          contentBackgroundColor?: Maybe<string>;
          contentBackgroundSize?: Maybe<string>;
          contentBackgroundRepeat?: Maybe<string>;
          contentBackgroundPosition?: Maybe<string>;
          contentBackgroundColorLayerTwo?: Maybe<string>;
          subtitleColor?: Maybe<string>;
          textAlignment?: Maybe<string>;
          textColor?: Maybe<string>;
          titleColor?: Maybe<string>;
          showHide?: Maybe<boolean>;
          hideAnimations?: Maybe<string>;
          animationDirection?: Maybe<string>;
          hideParallax?: Maybe<string>;
          sliderAnimations?: Maybe<string>;
          zoomOnHover?: Maybe<string>;
          containerMaxWidth?: Maybe<string>;
          headingsNoTranslate?: Maybe<boolean>;
          componentBackgroundImage?: Maybe<{
            __typename?: 'MediaItem';
            sourceUrl?: Maybe<string>;
          }>;
          contentBackgroundImage?: Maybe<{
            __typename?: 'MediaItem';
            sourceUrl?: Maybe<string>;
          }>;
          paddingTop?: Maybe<{
            __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_GalleryWallComponentSettings_PaddingTop';
            paddingTopDesktop?: Maybe<string>;
            paddingTopTablet?: Maybe<string>;
            paddingTopMobile?: Maybe<string>;
          }>;
          paddingBottom?: Maybe<{
            __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_GalleryWallComponentSettings_PaddingBottom';
            paddingBottomDesktop?: Maybe<string>;
            paddingBottomTablet?: Maybe<string>;
            paddingBottomMobile?: Maybe<string>;
          }>;
        }>;
        imageGalleryComponentSettings?: Maybe<{
          __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_ImageGalleryComponentSettings';
          fieldGroupName?: Maybe<string>;
          componentBackgroundColor?: Maybe<string>;
          componentBackgroundSize?: Maybe<string>;
          componentBackgroundRepeat?: Maybe<string>;
          componentBackgroundPosition?: Maybe<string>;
          contentBackgroundColor?: Maybe<string>;
          contentBackgroundSize?: Maybe<string>;
          contentBackgroundRepeat?: Maybe<string>;
          contentBackgroundPosition?: Maybe<string>;
          contentBackgroundColorLayerTwo?: Maybe<string>;
          subtitleColor?: Maybe<string>;
          textAlignment?: Maybe<string>;
          textColor?: Maybe<string>;
          titleColor?: Maybe<string>;
          showHide?: Maybe<boolean>;
          hideAnimations?: Maybe<string>;
          animationDirection?: Maybe<string>;
          hideParallax?: Maybe<string>;
          sliderAnimations?: Maybe<string>;
          zoomOnHover?: Maybe<string>;
          containerMaxWidth?: Maybe<string>;
          headingsNoTranslate?: Maybe<boolean>;
          componentBackgroundImage?: Maybe<{
            __typename?: 'MediaItem';
            sourceUrl?: Maybe<string>;
          }>;
          contentBackgroundImage?: Maybe<{
            __typename?: 'MediaItem';
            sourceUrl?: Maybe<string>;
          }>;
          paddingTop?: Maybe<{
            __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_ImageGalleryComponentSettings_PaddingTop';
            paddingTopDesktop?: Maybe<string>;
            paddingTopTablet?: Maybe<string>;
            paddingTopMobile?: Maybe<string>;
          }>;
          paddingBottom?: Maybe<{
            __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_ImageGalleryComponentSettings_PaddingBottom';
            paddingBottomDesktop?: Maybe<string>;
            paddingBottomTablet?: Maybe<string>;
            paddingBottomMobile?: Maybe<string>;
          }>;
        }>;
        mediaAndCopyOverlayComponentSettings?: Maybe<{
          __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_MediaAndCopyOverlayComponentSettings';
          fieldGroupName?: Maybe<string>;
          componentBackgroundColor?: Maybe<string>;
          componentBackgroundSize?: Maybe<string>;
          componentBackgroundRepeat?: Maybe<string>;
          componentBackgroundPosition?: Maybe<string>;
          contentBackgroundColor?: Maybe<string>;
          contentBackgroundSize?: Maybe<string>;
          contentBackgroundRepeat?: Maybe<string>;
          contentBackgroundPosition?: Maybe<string>;
          subtitleColor?: Maybe<string>;
          textAlignment?: Maybe<string>;
          textColor?: Maybe<string>;
          titleColor?: Maybe<string>;
          mediaAndCopyPadding?: Maybe<string>;
          horizontalDirection?: Maybe<string>;
          zoomOnHover?: Maybe<string>;
          showHide?: Maybe<boolean>;
          hideAnimations?: Maybe<string>;
          animationDirection?: Maybe<string>;
          hideParallax?: Maybe<string>;
          containerMaxWidth?: Maybe<string>;
          componentBackgroundImage?: Maybe<{
            __typename?: 'MediaItem';
            sourceUrl?: Maybe<string>;
          }>;
          contentBackgroundImage?: Maybe<{
            __typename?: 'MediaItem';
            sourceUrl?: Maybe<string>;
          }>;
          paddingTop?: Maybe<{
            __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_MediaAndCopyOverlayComponentSettings_PaddingTop';
            paddingTopDesktop?: Maybe<string>;
            paddingTopTablet?: Maybe<string>;
            paddingTopMobile?: Maybe<string>;
          }>;
          paddingBottom?: Maybe<{
            __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_MediaAndCopyOverlayComponentSettings_PaddingBottom';
            paddingBottomDesktop?: Maybe<string>;
            paddingBottomTablet?: Maybe<string>;
            paddingBottomMobile?: Maybe<string>;
          }>;
        }>;
        iconBlockComponentSettings?: Maybe<{
          __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_IconBlockComponentSettings';
          fieldGroupName?: Maybe<string>;
          componentBackgroundColor?: Maybe<string>;
          componentBackgroundSize?: Maybe<string>;
          componentBackgroundRepeat?: Maybe<string>;
          componentBackgroundPosition?: Maybe<string>;
          contentBackgroundColor?: Maybe<string>;
          contentBackgroundSize?: Maybe<string>;
          contentBackgroundRepeat?: Maybe<string>;
          contentBackgroundPosition?: Maybe<string>;
          contentBackgroundColorLayerTwo?: Maybe<string>;
          subtitleColor?: Maybe<string>;
          textAlignment?: Maybe<string>;
          textColor?: Maybe<string>;
          titleColor?: Maybe<string>;
          showHide?: Maybe<boolean>;
          hideAnimations?: Maybe<string>;
          animationDirection?: Maybe<string>;
          hideParallax?: Maybe<string>;
          sliderAnimations?: Maybe<string>;
          zoomOnHover?: Maybe<string>;
          containerMaxWidth?: Maybe<string>;
          headingsNoTranslate?: Maybe<boolean>;
          componentBackgroundImage?: Maybe<{
            __typename?: 'MediaItem';
            sourceUrl?: Maybe<string>;
          }>;
          contentBackgroundImage?: Maybe<{
            __typename?: 'MediaItem';
            sourceUrl?: Maybe<string>;
          }>;
          paddingTop?: Maybe<{
            __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_IconBlockComponentSettings_PaddingTop';
            paddingTopDesktop?: Maybe<string>;
            paddingTopTablet?: Maybe<string>;
            paddingTopMobile?: Maybe<string>;
          }>;
          paddingBottom?: Maybe<{
            __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_IconBlockComponentSettings_PaddingBottom';
            paddingBottomDesktop?: Maybe<string>;
            paddingBottomTablet?: Maybe<string>;
            paddingBottomMobile?: Maybe<string>;
          }>;
        }>;
        htmlMenuSettings?: Maybe<{
          __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_HtmlMenuSettings';
          fieldGroupName?: Maybe<string>;
          componentBackgroundColor?: Maybe<string>;
          componentBackgroundSize?: Maybe<string>;
          componentBackgroundRepeat?: Maybe<string>;
          componentBackgroundPosition?: Maybe<string>;
          contentBackgroundColor?: Maybe<string>;
          contentBackgroundSize?: Maybe<string>;
          contentBackgroundRepeat?: Maybe<string>;
          contentBackgroundPosition?: Maybe<string>;
          contentBackgroundColorLayerTwo?: Maybe<string>;
          subtitleColor?: Maybe<string>;
          textAlignment?: Maybe<string>;
          textColor?: Maybe<string>;
          titleColor?: Maybe<string>;
          showHide?: Maybe<boolean>;
          hideAnimations?: Maybe<string>;
          animationDirection?: Maybe<string>;
          hideParallax?: Maybe<string>;
          sliderAnimations?: Maybe<string>;
          zoomOnHover?: Maybe<string>;
          containerMaxWidth?: Maybe<string>;
          headingsNoTranslate?: Maybe<boolean>;
          componentBackgroundImage?: Maybe<{
            __typename?: 'MediaItem';
            sourceUrl?: Maybe<string>;
          }>;
          contentBackgroundImage?: Maybe<{
            __typename?: 'MediaItem';
            sourceUrl?: Maybe<string>;
          }>;
          paddingTop?: Maybe<{
            __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_HtmlMenuSettings_PaddingTop';
            paddingTopDesktop?: Maybe<string>;
            paddingTopTablet?: Maybe<string>;
            paddingTopMobile?: Maybe<string>;
          }>;
          paddingBottom?: Maybe<{
            __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_HtmlMenuSettings_PaddingBottom';
            paddingBottomDesktop?: Maybe<string>;
            paddingBottomTablet?: Maybe<string>;
            paddingBottomMobile?: Maybe<string>;
          }>;
        }>;
        iconBlockCorePlusComponentSettings?: Maybe<{
          __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_IconBlockCorePlusComponentSettings';
          fieldGroupName?: Maybe<string>;
          componentBackgroundColor?: Maybe<string>;
          componentBackgroundSize?: Maybe<string>;
          componentBackgroundRepeat?: Maybe<string>;
          componentBackgroundPosition?: Maybe<string>;
          contentBackgroundColor?: Maybe<string>;
          contentBackgroundSize?: Maybe<string>;
          contentBackgroundRepeat?: Maybe<string>;
          contentBackgroundPosition?: Maybe<string>;
          contentBackgroundColorLayerTwo?: Maybe<string>;
          subtitleColor?: Maybe<string>;
          textAlignment?: Maybe<string>;
          textColor?: Maybe<string>;
          titleColor?: Maybe<string>;
          showHide?: Maybe<boolean>;
          hideAnimations?: Maybe<string>;
          animationDirection?: Maybe<string>;
          hideParallax?: Maybe<string>;
          sliderAnimations?: Maybe<string>;
          zoomOnHover?: Maybe<string>;
          containerMaxWidth?: Maybe<string>;
          headingsNoTranslate?: Maybe<boolean>;
          componentBackgroundImage?: Maybe<{
            __typename?: 'MediaItem';
            sourceUrl?: Maybe<string>;
          }>;
          contentBackgroundImage?: Maybe<{
            __typename?: 'MediaItem';
            sourceUrl?: Maybe<string>;
          }>;
          paddingTop?: Maybe<{
            __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_IconBlockCorePlusComponentSettings_PaddingTop';
            paddingTopDesktop?: Maybe<string>;
            paddingTopTablet?: Maybe<string>;
            paddingTopMobile?: Maybe<string>;
          }>;
          paddingBottom?: Maybe<{
            __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_IconBlockCorePlusComponentSettings_PaddingBottom';
            paddingBottomDesktop?: Maybe<string>;
            paddingBottomTablet?: Maybe<string>;
            paddingBottomMobile?: Maybe<string>;
          }>;
        }>;
        keyInfoGridSettings?: Maybe<{
          __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_KeyInfoGridSettings';
          fieldGroupName?: Maybe<string>;
          componentBackgroundColor?: Maybe<string>;
          componentBackgroundSize?: Maybe<string>;
          componentBackgroundRepeat?: Maybe<string>;
          componentBackgroundPosition?: Maybe<string>;
          contentBackgroundColor?: Maybe<string>;
          contentBackgroundSize?: Maybe<string>;
          contentBackgroundRepeat?: Maybe<string>;
          contentBackgroundPosition?: Maybe<string>;
          contentBackgroundColorLayerTwo?: Maybe<string>;
          subtitleColor?: Maybe<string>;
          textAlignment?: Maybe<string>;
          textColor?: Maybe<string>;
          titleColor?: Maybe<string>;
          showHide?: Maybe<boolean>;
          hideAnimations?: Maybe<string>;
          animationDirection?: Maybe<string>;
          hideParallax?: Maybe<string>;
          componentBackgroundImage?: Maybe<{
            __typename?: 'MediaItem';
            sourceUrl?: Maybe<string>;
          }>;
          contentBackgroundImage?: Maybe<{
            __typename?: 'MediaItem';
            sourceUrl?: Maybe<string>;
          }>;
          paddingTop?: Maybe<{
            __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_KeyInfoGridSettings_PaddingTop';
            paddingTopDesktop?: Maybe<string>;
            paddingTopTablet?: Maybe<string>;
            paddingTopMobile?: Maybe<string>;
          }>;
          paddingBottom?: Maybe<{
            __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_KeyInfoGridSettings_PaddingBottom';
            paddingBottomDesktop?: Maybe<string>;
            paddingBottomTablet?: Maybe<string>;
            paddingBottomMobile?: Maybe<string>;
          }>;
        }>;
        roomsComponentSettings?: Maybe<{
          __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_RoomsComponentSettings';
          fieldGroupName?: Maybe<string>;
          componentBackgroundColor?: Maybe<string>;
          componentBackgroundSize?: Maybe<string>;
          componentBackgroundRepeat?: Maybe<string>;
          componentBackgroundPosition?: Maybe<string>;
          contentBackgroundColor?: Maybe<string>;
          contentBackgroundSize?: Maybe<string>;
          contentBackgroundRepeat?: Maybe<string>;
          contentBackgroundPosition?: Maybe<string>;
          contentBackgroundColorLayerTwo?: Maybe<string>;
          subtitleColor?: Maybe<string>;
          textAlignment?: Maybe<string>;
          textColor?: Maybe<string>;
          titleColor?: Maybe<string>;
          showHide?: Maybe<boolean>;
          hideAnimations?: Maybe<string>;
          animationDirection?: Maybe<string>;
          hideParallax?: Maybe<string>;
          sliderAnimations?: Maybe<string>;
          zoomOnHover?: Maybe<string>;
          containerMaxWidth?: Maybe<string>;
          headingsNoTranslate?: Maybe<boolean>;
          componentBackgroundImage?: Maybe<{
            __typename?: 'MediaItem';
            sourceUrl?: Maybe<string>;
          }>;
          contentBackgroundImage?: Maybe<{
            __typename?: 'MediaItem';
            sourceUrl?: Maybe<string>;
          }>;
          paddingTop?: Maybe<{
            __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_RoomsComponentSettings_PaddingTop';
            paddingTopDesktop?: Maybe<string>;
            paddingTopTablet?: Maybe<string>;
            paddingTopMobile?: Maybe<string>;
          }>;
          paddingBottom?: Maybe<{
            __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_RoomsComponentSettings_PaddingBottom';
            paddingBottomDesktop?: Maybe<string>;
            paddingBottomTablet?: Maybe<string>;
            paddingBottomMobile?: Maybe<string>;
          }>;
        }>;
        hotelPoliciesComponentSettings?: Maybe<{
          __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_HotelPoliciesComponentSettings';
          fieldGroupName?: Maybe<string>;
          componentBackgroundColor?: Maybe<string>;
          componentBackgroundSize?: Maybe<string>;
          componentBackgroundRepeat?: Maybe<string>;
          componentBackgroundPosition?: Maybe<string>;
          contentBackgroundColor?: Maybe<string>;
          contentBackgroundSize?: Maybe<string>;
          contentBackgroundRepeat?: Maybe<string>;
          contentBackgroundPosition?: Maybe<string>;
          contentBackgroundColorLayerTwo?: Maybe<string>;
          subtitleColor?: Maybe<string>;
          textAlignment?: Maybe<string>;
          textColor?: Maybe<string>;
          titleColor?: Maybe<string>;
          showHide?: Maybe<boolean>;
          hideAnimations?: Maybe<string>;
          animationDirection?: Maybe<string>;
          hideParallax?: Maybe<string>;
          sliderAnimations?: Maybe<string>;
          zoomOnHover?: Maybe<string>;
          containerMaxWidth?: Maybe<string>;
          headingsNoTranslate?: Maybe<boolean>;
          componentBackgroundImage?: Maybe<{
            __typename?: 'MediaItem';
            sourceUrl?: Maybe<string>;
          }>;
          contentBackgroundImage?: Maybe<{
            __typename?: 'MediaItem';
            sourceUrl?: Maybe<string>;
          }>;
          paddingTop?: Maybe<{
            __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_HotelPoliciesComponentSettings_PaddingTop';
            paddingTopDesktop?: Maybe<string>;
            paddingTopTablet?: Maybe<string>;
            paddingTopMobile?: Maybe<string>;
          }>;
          paddingBottom?: Maybe<{
            __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_HotelPoliciesComponentSettings_PaddingBottom';
            paddingBottomDesktop?: Maybe<string>;
            paddingBottomTablet?: Maybe<string>;
            paddingBottomMobile?: Maybe<string>;
          }>;
        }>;
        resortMapV2Settings?: Maybe<{
          __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_ResortMapV2Settings';
          fieldGroupName?: Maybe<string>;
          showHide?: Maybe<boolean>;
          mapControlsColor?: Maybe<string>;
          mapControlsBackgroundColor?: Maybe<string>;
          tabBackgroundColor?: Maybe<string>;
          tabTextColor?: Maybe<string>;
          selectedTabBackgroundColor?: Maybe<string>;
          selectedTabTextColor?: Maybe<string>;
          mobileFilterDropdownBackgroundColor?: Maybe<string>;
          mapControlsPlacement?: Maybe<string>;
          mobileFilterDropdownTextColor?: Maybe<string>;
          pinAccentColor?: Maybe<string>;
          pinColor?: Maybe<string>;
          subtitleColor?: Maybe<string>;
          textAlignment?: Maybe<string>;
          titleColor?: Maybe<string>;
          componentBackgroundColor?: Maybe<string>;
          componentBackgroundSize?: Maybe<string>;
          componentBackgroundRepeat?: Maybe<string>;
          componentBackgroundPosition?: Maybe<string>;
          contentBackgroundColor?: Maybe<string>;
          contentBackgroundSize?: Maybe<string>;
          contentBackgroundRepeat?: Maybe<string>;
          contentBackgroundPosition?: Maybe<string>;
          contentBackgroundColorLayerTwo?: Maybe<string>;
          textColor?: Maybe<string>;
          hideAnimations?: Maybe<string>;
          animationDirection?: Maybe<string>;
          hideParallax?: Maybe<string>;
          sliderAnimations?: Maybe<string>;
          zoomOnHover?: Maybe<string>;
          containerMaxWidth?: Maybe<string>;
          headingsNoTranslate?: Maybe<boolean>;
          componentBackgroundImage?: Maybe<{
            __typename?: 'MediaItem';
            sourceUrl?: Maybe<string>;
          }>;
          contentBackgroundImage?: Maybe<{
            __typename?: 'MediaItem';
            sourceUrl?: Maybe<string>;
          }>;
          paddingTop?: Maybe<{
            __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_ResortMapV2Settings_PaddingTop';
            paddingTopDesktop?: Maybe<string>;
            paddingTopTablet?: Maybe<string>;
            paddingTopMobile?: Maybe<string>;
          }>;
          paddingBottom?: Maybe<{
            __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_ResortMapV2Settings_PaddingBottom';
            paddingBottomDesktop?: Maybe<string>;
            paddingBottomTablet?: Maybe<string>;
            paddingBottomMobile?: Maybe<string>;
          }>;
        }>;
        comparisonTableSettings?: Maybe<{
          __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_ComparisonTableSettings';
          fieldGroupName?: Maybe<string>;
          componentBackgroundColor?: Maybe<string>;
          componentBackgroundSize?: Maybe<string>;
          componentBackgroundRepeat?: Maybe<string>;
          componentBackgroundPosition?: Maybe<string>;
          contentBackgroundColor?: Maybe<string>;
          contentBackgroundSize?: Maybe<string>;
          contentBackgroundRepeat?: Maybe<string>;
          contentBackgroundPosition?: Maybe<string>;
          contentBackgroundColorLayerTwo?: Maybe<string>;
          subtitleColor?: Maybe<string>;
          textAlignment?: Maybe<string>;
          textColor?: Maybe<string>;
          titleColor?: Maybe<string>;
          showHide?: Maybe<boolean>;
          hideAnimations?: Maybe<string>;
          animationDirection?: Maybe<string>;
          hideParallax?: Maybe<string>;
          sliderAnimations?: Maybe<string>;
          zoomOnHover?: Maybe<string>;
          containerMaxWidth?: Maybe<string>;
          headingsNoTranslate?: Maybe<boolean>;
          componentBackgroundImage?: Maybe<{
            __typename?: 'MediaItem';
            sourceUrl?: Maybe<string>;
          }>;
          contentBackgroundImage?: Maybe<{
            __typename?: 'MediaItem';
            sourceUrl?: Maybe<string>;
          }>;
          paddingTop?: Maybe<{
            __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_ComparisonTableSettings_PaddingTop';
            paddingTopDesktop?: Maybe<string>;
            paddingTopTablet?: Maybe<string>;
            paddingTopMobile?: Maybe<string>;
          }>;
          paddingBottom?: Maybe<{
            __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_ComparisonTableSettings_PaddingBottom';
            paddingBottomDesktop?: Maybe<string>;
            paddingBottomTablet?: Maybe<string>;
            paddingBottomMobile?: Maybe<string>;
          }>;
        }>;
        onPageMenuSettings?: Maybe<{
          __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_OnPageMenuSettings';
          fieldGroupName?: Maybe<string>;
          componentBackgroundColor?: Maybe<string>;
          componentBackgroundSize?: Maybe<string>;
          componentBackgroundRepeat?: Maybe<string>;
          componentBackgroundPosition?: Maybe<string>;
          contentBackgroundColor?: Maybe<string>;
          contentBackgroundSize?: Maybe<string>;
          contentBackgroundRepeat?: Maybe<string>;
          contentBackgroundPosition?: Maybe<string>;
          contentBackgroundColorLayerTwo?: Maybe<string>;
          subtitleColor?: Maybe<string>;
          textAlignment?: Maybe<string>;
          textColor?: Maybe<string>;
          titleColor?: Maybe<string>;
          showHide?: Maybe<boolean>;
          hideAnimations?: Maybe<string>;
          animationDirection?: Maybe<string>;
          hideParallax?: Maybe<string>;
          sliderAnimations?: Maybe<string>;
          zoomOnHover?: Maybe<string>;
          containerMaxWidth?: Maybe<string>;
          headingsNoTranslate?: Maybe<boolean>;
          componentBackgroundImage?: Maybe<{
            __typename?: 'MediaItem';
            sourceUrl?: Maybe<string>;
          }>;
          contentBackgroundImage?: Maybe<{
            __typename?: 'MediaItem';
            sourceUrl?: Maybe<string>;
          }>;
          paddingTop?: Maybe<{
            __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_OnPageMenuSettings_PaddingTop';
            paddingTopDesktop?: Maybe<string>;
            paddingTopTablet?: Maybe<string>;
            paddingTopMobile?: Maybe<string>;
          }>;
          paddingBottom?: Maybe<{
            __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_OnPageMenuSettings_PaddingBottom';
            paddingBottomDesktop?: Maybe<string>;
            paddingBottomTablet?: Maybe<string>;
            paddingBottomMobile?: Maybe<string>;
          }>;
        }>;
        fourStaggeredTilesComponentSettings?: Maybe<{
          __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_FourStaggeredTilesComponentSettings';
          fieldGroupName?: Maybe<string>;
          componentBackgroundColor?: Maybe<string>;
          componentBackgroundSize?: Maybe<string>;
          componentBackgroundRepeat?: Maybe<string>;
          componentBackgroundPosition?: Maybe<string>;
          contentBackgroundColor?: Maybe<string>;
          contentBackgroundSize?: Maybe<string>;
          contentBackgroundRepeat?: Maybe<string>;
          contentBackgroundPosition?: Maybe<string>;
          contentBackgroundColorLayerTwo?: Maybe<string>;
          subtitleColor?: Maybe<string>;
          textAlignment?: Maybe<string>;
          textColor?: Maybe<string>;
          titleColor?: Maybe<string>;
          showHide?: Maybe<boolean>;
          hideAnimations?: Maybe<string>;
          animationDirection?: Maybe<string>;
          hideParallax?: Maybe<string>;
          sliderAnimations?: Maybe<string>;
          zoomOnHover?: Maybe<string>;
          containerMaxWidth?: Maybe<string>;
          headingsNoTranslate?: Maybe<boolean>;
          componentBackgroundImage?: Maybe<{
            __typename?: 'MediaItem';
            sourceUrl?: Maybe<string>;
          }>;
          contentBackgroundImage?: Maybe<{
            __typename?: 'MediaItem';
            sourceUrl?: Maybe<string>;
          }>;
          paddingTop?: Maybe<{
            __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_FourStaggeredTilesComponentSettings_PaddingTop';
            paddingTopDesktop?: Maybe<string>;
            paddingTopTablet?: Maybe<string>;
            paddingTopMobile?: Maybe<string>;
          }>;
          paddingBottom?: Maybe<{
            __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_FourStaggeredTilesComponentSettings_PaddingBottom';
            paddingBottomDesktop?: Maybe<string>;
            paddingBottomTablet?: Maybe<string>;
            paddingBottomMobile?: Maybe<string>;
          }>;
        }>;
        restaurantsComponentSettings?: Maybe<{
          __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_RestaurantsComponentSettings';
          fieldGroupName?: Maybe<string>;
          componentBackgroundColor?: Maybe<string>;
          componentBackgroundSize?: Maybe<string>;
          componentBackgroundRepeat?: Maybe<string>;
          componentBackgroundPosition?: Maybe<string>;
          contentBackgroundColor?: Maybe<string>;
          contentBackgroundSize?: Maybe<string>;
          contentBackgroundRepeat?: Maybe<string>;
          contentBackgroundPosition?: Maybe<string>;
          contentBackgroundColorLayerTwo?: Maybe<string>;
          subtitleColor?: Maybe<string>;
          textAlignment?: Maybe<string>;
          textColor?: Maybe<string>;
          titleColor?: Maybe<string>;
          showHide?: Maybe<boolean>;
          hideAnimations?: Maybe<string>;
          animationDirection?: Maybe<string>;
          hideParallax?: Maybe<string>;
          sliderAnimations?: Maybe<string>;
          zoomOnHover?: Maybe<string>;
          containerMaxWidth?: Maybe<string>;
          headingsNoTranslate?: Maybe<boolean>;
          hideTileBorder?: Maybe<boolean>;
          cuisineTypeTextColour?: Maybe<string>;
          cuisineTypeIconColour?: Maybe<string>;
          tileBorderColour?: Maybe<string>;
          topSectionBackgroundColour?: Maybe<string>;
          componentBackgroundImage?: Maybe<{
            __typename?: 'MediaItem';
            sourceUrl?: Maybe<string>;
          }>;
          contentBackgroundImage?: Maybe<{
            __typename?: 'MediaItem';
            sourceUrl?: Maybe<string>;
          }>;
          paddingTop?: Maybe<{
            __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_RestaurantsComponentSettings_PaddingTop';
            paddingTopDesktop?: Maybe<string>;
            paddingTopTablet?: Maybe<string>;
            paddingTopMobile?: Maybe<string>;
          }>;
          paddingBottom?: Maybe<{
            __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_RestaurantsComponentSettings_PaddingBottom';
            paddingBottomDesktop?: Maybe<string>;
            paddingBottomTablet?: Maybe<string>;
            paddingBottomMobile?: Maybe<string>;
          }>;
        }>;
        accordionComponentSettings?: Maybe<{
          __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_AccordionComponentSettings';
          fieldGroupName?: Maybe<string>;
          componentBackgroundColor?: Maybe<string>;
          componentBackgroundSize?: Maybe<string>;
          componentBackgroundRepeat?: Maybe<string>;
          componentBackgroundPosition?: Maybe<string>;
          contentBackgroundColor?: Maybe<string>;
          contentBackgroundSize?: Maybe<string>;
          contentBackgroundRepeat?: Maybe<string>;
          contentBackgroundPosition?: Maybe<string>;
          contentBackgroundColorLayerTwo?: Maybe<string>;
          subtitleColor?: Maybe<string>;
          textAlignment?: Maybe<string>;
          textColor?: Maybe<string>;
          titleColor?: Maybe<string>;
          showHide?: Maybe<boolean>;
          hideAnimations?: Maybe<string>;
          animationDirection?: Maybe<string>;
          hideParallax?: Maybe<string>;
          sliderAnimations?: Maybe<string>;
          zoomOnHover?: Maybe<string>;
          containerMaxWidth?: Maybe<string>;
          headingsNoTranslate?: Maybe<boolean>;
          componentBackgroundImage?: Maybe<{
            __typename?: 'MediaItem';
            sourceUrl?: Maybe<string>;
          }>;
          contentBackgroundImage?: Maybe<{
            __typename?: 'MediaItem';
            sourceUrl?: Maybe<string>;
          }>;
          paddingTop?: Maybe<{
            __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_AccordionComponentSettings_PaddingTop';
            paddingTopDesktop?: Maybe<string>;
            paddingTopTablet?: Maybe<string>;
            paddingTopMobile?: Maybe<string>;
          }>;
          paddingBottom?: Maybe<{
            __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_AccordionComponentSettings_PaddingBottom';
            paddingBottomDesktop?: Maybe<string>;
            paddingBottomTablet?: Maybe<string>;
            paddingBottomMobile?: Maybe<string>;
          }>;
        }>;
        meetingCapacitySummaryComponentSettings?: Maybe<{
          __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_MeetingCapacitySummaryComponentSettings';
          fieldGroupName?: Maybe<string>;
          componentBackgroundColor?: Maybe<string>;
          componentBackgroundSize?: Maybe<string>;
          componentBackgroundRepeat?: Maybe<string>;
          componentBackgroundPosition?: Maybe<string>;
          contentBackgroundColor?: Maybe<string>;
          contentBackgroundSize?: Maybe<string>;
          contentBackgroundRepeat?: Maybe<string>;
          contentBackgroundPosition?: Maybe<string>;
          contentBackgroundColorLayerTwo?: Maybe<string>;
          subtitleColor?: Maybe<string>;
          textAlignment?: Maybe<string>;
          textColor?: Maybe<string>;
          titleColor?: Maybe<string>;
          showHide?: Maybe<boolean>;
          hideAnimations?: Maybe<string>;
          animationDirection?: Maybe<string>;
          hideParallax?: Maybe<string>;
          sliderAnimations?: Maybe<string>;
          zoomOnHover?: Maybe<string>;
          containerMaxWidth?: Maybe<string>;
          headingsNoTranslate?: Maybe<boolean>;
          componentBackgroundImage?: Maybe<{
            __typename?: 'MediaItem';
            sourceUrl?: Maybe<string>;
          }>;
          contentBackgroundImage?: Maybe<{
            __typename?: 'MediaItem';
            sourceUrl?: Maybe<string>;
          }>;
          paddingTop?: Maybe<{
            __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_MeetingCapacitySummaryComponentSettings_PaddingTop';
            paddingTopDesktop?: Maybe<string>;
            paddingTopTablet?: Maybe<string>;
            paddingTopMobile?: Maybe<string>;
          }>;
          paddingBottom?: Maybe<{
            __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_MeetingCapacitySummaryComponentSettings_PaddingBottom';
            paddingBottomDesktop?: Maybe<string>;
            paddingBottomTablet?: Maybe<string>;
            paddingBottomMobile?: Maybe<string>;
          }>;
        }>;
        meetingGroupComponentSettings?: Maybe<{
          __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_MeetingGroupComponentSettings';
          fieldGroupName?: Maybe<string>;
          componentBackgroundColor?: Maybe<string>;
          componentBackgroundSize?: Maybe<string>;
          componentBackgroundRepeat?: Maybe<string>;
          componentBackgroundPosition?: Maybe<string>;
          contentBackgroundColor?: Maybe<string>;
          contentBackgroundSize?: Maybe<string>;
          contentBackgroundRepeat?: Maybe<string>;
          contentBackgroundPosition?: Maybe<string>;
          contentBackgroundColorLayerTwo?: Maybe<string>;
          subtitleColor?: Maybe<string>;
          textAlignment?: Maybe<string>;
          textColor?: Maybe<string>;
          titleColor?: Maybe<string>;
          showHide?: Maybe<boolean>;
          hideAnimations?: Maybe<string>;
          animationDirection?: Maybe<string>;
          hideParallax?: Maybe<string>;
          sliderAnimations?: Maybe<string>;
          zoomOnHover?: Maybe<string>;
          containerMaxWidth?: Maybe<string>;
          headingsNoTranslate?: Maybe<boolean>;
          componentBackgroundImage?: Maybe<{
            __typename?: 'MediaItem';
            sourceUrl?: Maybe<string>;
          }>;
          contentBackgroundImage?: Maybe<{
            __typename?: 'MediaItem';
            sourceUrl?: Maybe<string>;
          }>;
          paddingTop?: Maybe<{
            __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_MeetingGroupComponentSettings_PaddingTop';
            paddingTopDesktop?: Maybe<string>;
            paddingTopTablet?: Maybe<string>;
            paddingTopMobile?: Maybe<string>;
          }>;
          paddingBottom?: Maybe<{
            __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_MeetingGroupComponentSettings_PaddingBottom';
            paddingBottomDesktop?: Maybe<string>;
            paddingBottomTablet?: Maybe<string>;
            paddingBottomMobile?: Maybe<string>;
          }>;
        }>;
        meetingCapacityChartsSettings?: Maybe<{
          __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_MeetingCapacityChartsSettings';
          fieldGroupName?: Maybe<string>;
          componentBackgroundColor?: Maybe<string>;
          componentBackgroundSize?: Maybe<string>;
          componentBackgroundRepeat?: Maybe<string>;
          componentBackgroundPosition?: Maybe<string>;
          contentBackgroundColor?: Maybe<string>;
          contentBackgroundSize?: Maybe<string>;
          contentBackgroundRepeat?: Maybe<string>;
          contentBackgroundPosition?: Maybe<string>;
          contentBackgroundColorLayerTwo?: Maybe<string>;
          subtitleColor?: Maybe<string>;
          textAlignment?: Maybe<string>;
          textColor?: Maybe<string>;
          titleColor?: Maybe<string>;
          showHide?: Maybe<boolean>;
          hideAnimations?: Maybe<string>;
          animationDirection?: Maybe<string>;
          hideParallax?: Maybe<string>;
          sliderAnimations?: Maybe<string>;
          zoomOnHover?: Maybe<string>;
          containerMaxWidth?: Maybe<string>;
          headingsNoTranslate?: Maybe<boolean>;
          componentBackgroundImage?: Maybe<{
            __typename?: 'MediaItem';
            sourceUrl?: Maybe<string>;
          }>;
          contentBackgroundImage?: Maybe<{
            __typename?: 'MediaItem';
            sourceUrl?: Maybe<string>;
          }>;
          paddingTop?: Maybe<{
            __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_MeetingCapacityChartsSettings_PaddingTop';
            paddingTopDesktop?: Maybe<string>;
            paddingTopTablet?: Maybe<string>;
            paddingTopMobile?: Maybe<string>;
          }>;
          paddingBottom?: Maybe<{
            __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_MeetingCapacityChartsSettings_PaddingBottom';
            paddingBottomDesktop?: Maybe<string>;
            paddingBottomTablet?: Maybe<string>;
            paddingBottomMobile?: Maybe<string>;
          }>;
        }>;
        eventsCalendarSettings?: Maybe<{
          __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_EventsCalendarSettings';
          fieldGroupName?: Maybe<string>;
          showHide?: Maybe<boolean>;
        }>;
        gallerySliderComponentSettings?: Maybe<{
          __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_GallerySliderComponentSettings';
          fieldGroupName?: Maybe<string>;
          showHide?: Maybe<boolean>;
          componentBackgroundColor?: Maybe<string>;
          componentBackgroundSize?: Maybe<string>;
          componentBackgroundRepeat?: Maybe<string>;
          componentBackgroundPosition?: Maybe<string>;
          contentBackgroundColor?: Maybe<string>;
          contentBackgroundSize?: Maybe<string>;
          contentBackgroundRepeat?: Maybe<string>;
          contentBackgroundPosition?: Maybe<string>;
          contentBackgroundColorLayerTwo?: Maybe<string>;
          subtitleColor?: Maybe<string>;
          textAlignment?: Maybe<string>;
          textColor?: Maybe<string>;
          titleColor?: Maybe<string>;
          hideAnimations?: Maybe<string>;
          animationDirection?: Maybe<string>;
          hideParallax?: Maybe<string>;
          sliderAnimations?: Maybe<string>;
          zoomOnHover?: Maybe<string>;
          containerMaxWidth?: Maybe<string>;
          headingsNoTranslate?: Maybe<boolean>;
          componentBackgroundImage?: Maybe<{
            __typename?: 'MediaItem';
            sourceUrl?: Maybe<string>;
          }>;
          contentBackgroundImage?: Maybe<{
            __typename?: 'MediaItem';
            sourceUrl?: Maybe<string>;
          }>;
          paddingTop?: Maybe<{
            __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_GallerySliderComponentSettings_PaddingTop';
            paddingTopDesktop?: Maybe<string>;
            paddingTopTablet?: Maybe<string>;
            paddingTopMobile?: Maybe<string>;
          }>;
          paddingBottom?: Maybe<{
            __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_GallerySliderComponentSettings_PaddingBottom';
            paddingBottomDesktop?: Maybe<string>;
            paddingBottomTablet?: Maybe<string>;
            paddingBottomMobile?: Maybe<string>;
          }>;
        }>;
        informationBarComponentSettings?: Maybe<{
          __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_InformationBarComponentSettings';
          fieldGroupName?: Maybe<string>;
          showHide?: Maybe<boolean>;
          componentBackgroundColor?: Maybe<string>;
          componentBackgroundSize?: Maybe<string>;
          componentBackgroundRepeat?: Maybe<string>;
          componentBackgroundPosition?: Maybe<string>;
          contentBackgroundColor?: Maybe<string>;
          contentBackgroundSize?: Maybe<string>;
          contentBackgroundRepeat?: Maybe<string>;
          contentBackgroundPosition?: Maybe<string>;
          contentBackgroundColorLayerTwo?: Maybe<string>;
          subtitleColor?: Maybe<string>;
          textAlignment?: Maybe<string>;
          textColor?: Maybe<string>;
          titleColor?: Maybe<string>;
          hideAnimations?: Maybe<string>;
          animationDirection?: Maybe<string>;
          hideParallax?: Maybe<string>;
          sliderAnimations?: Maybe<string>;
          zoomOnHover?: Maybe<string>;
          containerMaxWidth?: Maybe<string>;
          headingsNoTranslate?: Maybe<boolean>;
          componentBackgroundImage?: Maybe<{
            __typename?: 'MediaItem';
            sourceUrl?: Maybe<string>;
          }>;
          contentBackgroundImage?: Maybe<{
            __typename?: 'MediaItem';
            sourceUrl?: Maybe<string>;
          }>;
          paddingTop?: Maybe<{
            __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_InformationBarComponentSettings_PaddingTop';
            paddingTopDesktop?: Maybe<string>;
            paddingTopTablet?: Maybe<string>;
            paddingTopMobile?: Maybe<string>;
          }>;
          paddingBottom?: Maybe<{
            __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_InformationBarComponentSettings_PaddingBottom';
            paddingBottomDesktop?: Maybe<string>;
            paddingBottomTablet?: Maybe<string>;
            paddingBottomMobile?: Maybe<string>;
          }>;
        }>;
        videoMediaCopySettings?: Maybe<{
          __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_VideoMediaCopySettings';
          fieldGroupName?: Maybe<string>;
          showHide?: Maybe<boolean>;
          componentBackgroundColor?: Maybe<string>;
          componentBackgroundSize?: Maybe<string>;
          componentBackgroundRepeat?: Maybe<string>;
          componentBackgroundPosition?: Maybe<string>;
          contentBackgroundColor?: Maybe<string>;
          contentBackgroundSize?: Maybe<string>;
          contentBackgroundRepeat?: Maybe<string>;
          contentBackgroundPosition?: Maybe<string>;
          contentBackgroundColorLayerTwo?: Maybe<string>;
          subtitleColor?: Maybe<string>;
          textAlignment?: Maybe<string>;
          textColor?: Maybe<string>;
          titleColor?: Maybe<string>;
          animationDirection?: Maybe<string>;
          hideAnimations?: Maybe<string>;
          hideParallax?: Maybe<string>;
          sliderAnimations?: Maybe<string>;
          zoomOnHover?: Maybe<string>;
          containerMaxWidth?: Maybe<string>;
          headingsNoTranslate?: Maybe<boolean>;
          componentBackgroundImage?: Maybe<{
            __typename?: 'MediaItem';
            sourceUrl?: Maybe<string>;
          }>;
          contentBackgroundImage?: Maybe<{
            __typename?: 'MediaItem';
            sourceUrl?: Maybe<string>;
          }>;
          paddingTop?: Maybe<{
            __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_VideoMediaCopySettings_PaddingTop';
            paddingTopDesktop?: Maybe<string>;
            paddingTopTablet?: Maybe<string>;
            paddingTopMobile?: Maybe<string>;
          }>;
          paddingBottom?: Maybe<{
            __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_VideoMediaCopySettings_PaddingBottom';
            paddingBottomDesktop?: Maybe<string>;
            paddingBottomTablet?: Maybe<string>;
            paddingBottomMobile?: Maybe<string>;
          }>;
        }>;
        multiColumnListComponentSettings?: Maybe<{
          __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_MultiColumnListComponentSettings';
          componentBackgroundColor?: Maybe<string>;
          componentBackgroundSize?: Maybe<string>;
          componentBackgroundRepeat?: Maybe<string>;
          componentBackgroundPosition?: Maybe<string>;
          contentBackgroundColor?: Maybe<string>;
          contentBackgroundSize?: Maybe<string>;
          contentBackgroundRepeat?: Maybe<string>;
          contentBackgroundPosition?: Maybe<string>;
          contentBackgroundColorLayerTwo?: Maybe<string>;
          subtitleColor?: Maybe<string>;
          textAlignment?: Maybe<string>;
          textColor?: Maybe<string>;
          titleColor?: Maybe<string>;
          showHide?: Maybe<boolean>;
          animationDirection?: Maybe<string>;
          hideAnimations?: Maybe<string>;
          hideParallax?: Maybe<string>;
          sliderAnimations?: Maybe<string>;
          zoomOnHover?: Maybe<string>;
          containerMaxWidth?: Maybe<string>;
          headingsNoTranslate?: Maybe<boolean>;
          componentBackgroundImage?: Maybe<{
            __typename?: 'MediaItem';
            sourceUrl?: Maybe<string>;
          }>;
          contentBackgroundImage?: Maybe<{
            __typename?: 'MediaItem';
            sourceUrl?: Maybe<string>;
          }>;
          paddingTop?: Maybe<{
            __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_MultiColumnListComponentSettings_PaddingTop';
            paddingTopDesktop?: Maybe<string>;
            paddingTopTablet?: Maybe<string>;
            paddingTopMobile?: Maybe<string>;
          }>;
          paddingBottom?: Maybe<{
            __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_MultiColumnListComponentSettings_PaddingBottom';
            paddingBottomDesktop?: Maybe<string>;
            paddingBottomTablet?: Maybe<string>;
            paddingBottomMobile?: Maybe<string>;
          }>;
        }>;
        patchworkGridSettings?: Maybe<{
          __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_PatchworkGridSettings';
          componentBackgroundColor?: Maybe<string>;
          componentBackgroundSize?: Maybe<string>;
          componentBackgroundRepeat?: Maybe<string>;
          componentBackgroundPosition?: Maybe<string>;
          contentBackgroundColor?: Maybe<string>;
          contentBackgroundSize?: Maybe<string>;
          contentBackgroundRepeat?: Maybe<string>;
          contentBackgroundPosition?: Maybe<string>;
          contentBackgroundColorLayerTwo?: Maybe<string>;
          subtitleColor?: Maybe<string>;
          textAlignment?: Maybe<string>;
          textColor?: Maybe<string>;
          titleColor?: Maybe<string>;
          showHide?: Maybe<boolean>;
          animationDirection?: Maybe<string>;
          hideAnimations?: Maybe<string>;
          hideParallax?: Maybe<string>;
          sliderAnimations?: Maybe<string>;
          zoomOnHover?: Maybe<string>;
          containerMaxWidth?: Maybe<string>;
          headingsNoTranslate?: Maybe<boolean>;
          componentBackgroundImage?: Maybe<{
            __typename?: 'MediaItem';
            sourceUrl?: Maybe<string>;
          }>;
          contentBackgroundImage?: Maybe<{
            __typename?: 'MediaItem';
            sourceUrl?: Maybe<string>;
          }>;
          paddingTop?: Maybe<{
            __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_PatchworkGridSettings_PaddingTop';
            paddingTopDesktop?: Maybe<string>;
            paddingTopTablet?: Maybe<string>;
            paddingTopMobile?: Maybe<string>;
          }>;
          paddingBottom?: Maybe<{
            __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_PatchworkGridSettings_PaddingBottom';
            paddingBottomDesktop?: Maybe<string>;
            paddingBottomTablet?: Maybe<string>;
            paddingBottomMobile?: Maybe<string>;
          }>;
        }>;
        editorialSnippetComponentSettings?: Maybe<{
          __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_EditorialSnippetComponentSettings';
          componentLabel?: Maybe<string>;
          fieldGroupName?: Maybe<string>;
          showHide?: Maybe<boolean>;
          componentBackgroundColor?: Maybe<string>;
          componentBackgroundSize?: Maybe<string>;
          componentBackgroundRepeat?: Maybe<string>;
          componentBackgroundPosition?: Maybe<string>;
          contentBackgroundColor?: Maybe<string>;
          contentBackgroundSize?: Maybe<string>;
          contentBackgroundRepeat?: Maybe<string>;
          contentBackgroundPosition?: Maybe<string>;
          contentBackgroundColorLayerTwo?: Maybe<string>;
          subtitleColor?: Maybe<string>;
          textAlignment?: Maybe<string>;
          textColor?: Maybe<string>;
          titleColor?: Maybe<string>;
          animationDirection?: Maybe<string>;
          hideAnimations?: Maybe<string>;
          hideParallax?: Maybe<string>;
          sliderAnimations?: Maybe<string>;
          zoomOnHover?: Maybe<string>;
          containerMaxWidth?: Maybe<string>;
          headingsNoTranslate?: Maybe<boolean>;
          componentBackgroundImage?: Maybe<{
            __typename?: 'MediaItem';
            sourceUrl?: Maybe<string>;
          }>;
          contentBackgroundImage?: Maybe<{
            __typename?: 'MediaItem';
            sourceUrl?: Maybe<string>;
          }>;
          paddingTop?: Maybe<{
            __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_EditorialSnippetComponentSettings_PaddingTop';
            paddingTopDesktop?: Maybe<string>;
            paddingTopTablet?: Maybe<string>;
            paddingTopMobile?: Maybe<string>;
          }>;
          paddingBottom?: Maybe<{
            __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_EditorialSnippetComponentSettings_PaddingBottom';
            paddingBottomDesktop?: Maybe<string>;
            paddingBottomTablet?: Maybe<string>;
            paddingBottomMobile?: Maybe<string>;
          }>;
        }>;
        offsetComponentSettings?: Maybe<{
          __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_OffsetComponentSettings';
          imageCaptionTextColor?: Maybe<string>;
          imageCaptionBackgroundColor?: Maybe<string>;
          componentBackgroundColor?: Maybe<string>;
          componentBackgroundSize?: Maybe<string>;
          componentBackgroundRepeat?: Maybe<string>;
          componentBackgroundPosition?: Maybe<string>;
          contentBackgroundColor?: Maybe<string>;
          contentBackgroundSize?: Maybe<string>;
          contentBackgroundRepeat?: Maybe<string>;
          contentBackgroundPosition?: Maybe<string>;
          contentBackgroundColorLayerTwo?: Maybe<string>;
          subtitleColor?: Maybe<string>;
          textAlignment?: Maybe<string>;
          textColor?: Maybe<string>;
          titleColor?: Maybe<string>;
          showHide?: Maybe<boolean>;
          animationDirection?: Maybe<string>;
          hideAnimations?: Maybe<string>;
          hideParallax?: Maybe<string>;
          sliderAnimations?: Maybe<string>;
          zoomOnHover?: Maybe<string>;
          containerMaxWidth?: Maybe<string>;
          headingsNoTranslate?: Maybe<boolean>;
          componentBackgroundImage?: Maybe<{
            __typename?: 'MediaItem';
            sourceUrl?: Maybe<string>;
          }>;
          contentBackgroundImage?: Maybe<{
            __typename?: 'MediaItem';
            sourceUrl?: Maybe<string>;
          }>;
          paddingTop?: Maybe<{
            __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_OffsetComponentSettings_PaddingTop';
            paddingTopDesktop?: Maybe<string>;
            paddingTopTablet?: Maybe<string>;
            paddingTopMobile?: Maybe<string>;
          }>;
          paddingBottom?: Maybe<{
            __typename?: 'ComponentStyles_Globalcomponentsettings_GlobalComponentSettings_OffsetComponentSettings_PaddingBottom';
            paddingBottomDesktop?: Maybe<string>;
            paddingBottomTablet?: Maybe<string>;
            paddingBottomMobile?: Maybe<string>;
          }>;
        }>;
      }>;
    }>;
  }>;
  modalSettings?: Maybe<{
    __typename?: 'ModalSettings';
    modalSettings?: Maybe<{
      __typename?: 'ModalSettings_Modalsettings';
      modalBackgroundOpacity?: Maybe<number>;
      modalBackgroundColour?: Maybe<string>;
      modalCloseButtonBackgroundColour?: Maybe<string>;
      modalCloseButtonIconColour?: Maybe<string>;
      contentBackgroundColour?: Maybe<string>;
      hideBoxShadow?: Maybe<boolean>;
    }>;
  }>;
};

export type RecurringEventsQueryVariables = Exact<{ [key: string]: never }>;

export type RecurringEventsQuery = {
  __typename?: 'RootQuery';
  recurringevents?: Maybe<{
    __typename?: 'RootQueryToRecurringeventConnection';
    edges?: Maybe<
      Array<
        Maybe<{
          __typename?: 'RootQueryToRecurringeventConnectionEdge';
          node?: Maybe<{
            __typename?: 'Recurringevent';
            title?: Maybe<string>;
            id: string;
            databaseId: number;
            slug?: Maybe<string>;
            RegularlyScheduledEvents?: Maybe<{
              __typename?: 'Recurringevent_Regularlyscheduledevents';
              attractionEventLocation?: Maybe<string>;
              category?: Maybe<string>;
              description?: Maybe<string>;
              distanceFromResort?: Maybe<string>;
              fieldGroupName?: Maybe<string>;
              locationAtResort?: Maybe<string>;
              onSiteOrOffSite?: Maybe<string>;
              eventDisplayEndDate?: Maybe<string>;
              eventDisplayStartDate?: Maybe<string>;
              allDays?: Maybe<{
                __typename?: 'Recurringevent_Regularlyscheduledevents_AllDays';
                allDay?: Maybe<boolean>;
                endTime?: Maybe<string>;
                startTime?: Maybe<string>;
                heldDaily?: Maybe<boolean>;
                fieldGroupName?: Maybe<string>;
              }>;
              fridaySchedule?: Maybe<{
                __typename?: 'Recurringevent_Regularlyscheduledevents_FridaySchedule';
                allDay?: Maybe<boolean>;
                endTime?: Maybe<string>;
                fieldGroupName?: Maybe<string>;
                scheduled?: Maybe<boolean>;
                startTime?: Maybe<string>;
              }>;
              image?: Maybe<{
                __typename?: 'MediaItem';
                sourceUrl?: Maybe<string>;
                altText?: Maybe<string>;
                title?: Maybe<string>;
                databaseId: number;
              }>;
              buttons?: Maybe<
                Array<
                  Maybe<{
                    __typename?: 'Recurringevent_Regularlyscheduledevents_buttons';
                    buttonStyle?: Maybe<string>;
                    link?: Maybe<{
                      __typename?: 'AcfLink';
                      target?: Maybe<string>;
                      title?: Maybe<string>;
                      url?: Maybe<string>;
                    }>;
                  }>
                >
              >;
              mondaySchedule?: Maybe<{
                __typename?: 'Recurringevent_Regularlyscheduledevents_MondaySchedule';
                allDay?: Maybe<boolean>;
                endTime?: Maybe<string>;
                fieldGroupName?: Maybe<string>;
                scheduled?: Maybe<boolean>;
                startTime?: Maybe<string>;
              }>;
              saturdaySchedule?: Maybe<{
                __typename?: 'Recurringevent_Regularlyscheduledevents_SaturdaySchedule';
                allDay?: Maybe<boolean>;
                endTime?: Maybe<string>;
                fieldGroupName?: Maybe<string>;
                scheduled?: Maybe<boolean>;
                startTime?: Maybe<string>;
              }>;
              sundaySchedule?: Maybe<{
                __typename?: 'Recurringevent_Regularlyscheduledevents_SundaySchedule';
                allDay?: Maybe<boolean>;
                endTime?: Maybe<string>;
                fieldGroupName?: Maybe<string>;
                scheduled?: Maybe<boolean>;
                startTime?: Maybe<string>;
              }>;
              thursdaySchedule?: Maybe<{
                __typename?: 'Recurringevent_Regularlyscheduledevents_ThursdaySchedule';
                allDay?: Maybe<boolean>;
                endTime?: Maybe<string>;
                fieldGroupName?: Maybe<string>;
                scheduled?: Maybe<boolean>;
                startTime?: Maybe<string>;
              }>;
              tuesdaySchedule?: Maybe<{
                __typename?: 'Recurringevent_Regularlyscheduledevents_TuesdaySchedule';
                allDay?: Maybe<boolean>;
                endTime?: Maybe<string>;
                fieldGroupName?: Maybe<string>;
                scheduled?: Maybe<boolean>;
                startTime?: Maybe<string>;
              }>;
              wednesdaySchedule?: Maybe<{
                __typename?: 'Recurringevent_Regularlyscheduledevents_WednesdaySchedule';
                startTime?: Maybe<string>;
                scheduled?: Maybe<boolean>;
                fieldGroupName?: Maybe<string>;
                endTime?: Maybe<string>;
                allDay?: Maybe<boolean>;
              }>;
            }>;
          }>;
        }>
      >
    >;
  }>;
  calendars?: Maybe<{
    __typename?: 'RootQueryToCalendarConnection';
    edges?: Maybe<
      Array<
        Maybe<{
          __typename?: 'RootQueryToCalendarConnectionEdge';
          node?: Maybe<{
            __typename?: 'Calendar';
            title?: Maybe<string>;
            calendarId: number;
            calendarOfEvents?: Maybe<{
              __typename?: 'Calendar_Calendarofevents';
              attractionEventLocation?: Maybe<string>;
              category?: Maybe<string>;
              description?: Maybe<string>;
              distanceFromResort?: Maybe<string>;
              endDate?: Maybe<string>;
              endTime?: Maybe<string>;
              fieldGroupName?: Maybe<string>;
              locationAtResort?: Maybe<string>;
              onSiteOrOffSite?: Maybe<string>;
              startDate?: Maybe<string>;
              startTime?: Maybe<string>;
              image?: Maybe<{
                __typename?: 'MediaItem';
                altText?: Maybe<string>;
                sourceUrl?: Maybe<string>;
                databaseId: number;
              }>;
              buttons?: Maybe<
                Array<
                  Maybe<{
                    __typename?: 'Calendar_Calendarofevents_buttons';
                    buttonStyle?: Maybe<string>;
                    link?: Maybe<{
                      __typename?: 'AcfLink';
                      target?: Maybe<string>;
                      title?: Maybe<string>;
                      url?: Maybe<string>;
                    }>;
                  }>
                >
              >;
            }>;
          }>;
        }>
      >
    >;
  }>;
};

export type WordpressThemeSettingsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type WordpressThemeSettingsQuery = {
  __typename?: 'RootQuery';
} & WpSettingsFragment;

export type WordpressPageTranslationInfoQueryVariables = Exact<{
  id: Scalars['ID'];
  idType: PageIdType;
  asPreview?: Maybe<Scalars['Boolean']>;
}>;

export type WordpressPageTranslationInfoQuery = {
  __typename?: 'RootQuery';
  page?: Maybe<{
    __typename?: 'Page';
    databaseId: number;
    translations?: Maybe<
      Array<
        Maybe<{
          __typename?: 'Translation';
          locale?: Maybe<string>;
          id: string;
        }>
      >
    >;
  }>;
};

export type PageFieldsFragment = {
  __typename?: 'Page';
  slug?: Maybe<string>;
  title?: Maybe<string>;
  pageId: number;
  isFrontPage: boolean;
  parent?: Maybe<{
    __typename?: 'HierarchicalContentNodeToParentContentNodeConnectionEdge';
    node?: Maybe<
      | { __typename?: 'Activities'; slug?: Maybe<string> }
      | { __typename?: 'MediaItem'; slug?: Maybe<string> }
      | {
          __typename?: 'Page';
          title?: Maybe<string>;
          slug?: Maybe<string>;
          parent?: Maybe<{
            __typename?: 'HierarchicalContentNodeToParentContentNodeConnectionEdge';
            node?: Maybe<
              | { __typename?: 'Activities'; slug?: Maybe<string> }
              | { __typename?: 'MediaItem'; slug?: Maybe<string> }
              | {
                  __typename?: 'Page';
                  title?: Maybe<string>;
                  slug?: Maybe<string>;
                }
              | { __typename?: 'Media_block'; slug?: Maybe<string> }
              | { __typename?: 'Html_menu'; slug?: Maybe<string> }
              | { __typename?: 'Post'; slug?: Maybe<string> }
              | { __typename?: 'Specialoffer'; slug?: Maybe<string> }
              | { __typename?: 'Calendar'; slug?: Maybe<string> }
              | { __typename?: 'Room'; slug?: Maybe<string> }
              | { __typename?: 'Weddingbooking'; slug?: Maybe<string> }
              | { __typename?: 'Recurringevent'; slug?: Maybe<string> }
            >;
          }>;
        }
      | { __typename?: 'Media_block'; slug?: Maybe<string> }
      | { __typename?: 'Html_menu'; slug?: Maybe<string> }
      | { __typename?: 'Post'; slug?: Maybe<string> }
      | { __typename?: 'Specialoffer'; slug?: Maybe<string> }
      | { __typename?: 'Calendar'; slug?: Maybe<string> }
      | { __typename?: 'Room'; slug?: Maybe<string> }
      | { __typename?: 'Weddingbooking'; slug?: Maybe<string> }
      | { __typename?: 'Recurringevent'; slug?: Maybe<string> }
    >;
  }>;
  metaDataFields?: Maybe<{
    __typename?: 'Page_Metadatafields';
    fieldGroupName?: Maybe<string>;
    metaTitle?: Maybe<string>;
    metaDescription?: Maybe<string>;
    adobeDigitaldataOverwriteList?: Maybe<string>;
    adobeDigitaldataOverwriteText?: Maybe<string>;
    adobeDigitaldataOverwriteBrand?: Maybe<string>;
    adobeDigitaldataOverwriteCtyhocn?: Maybe<string>;
    adobeDigitaldataPageDetail1?: Maybe<string>;
    adobeDigitaldataPageDetail2?: Maybe<string>;
    adobeDigitaldataPageDetail3?: Maybe<string>;
    openGraphDescription?: Maybe<string>;
    openGraphTitle?: Maybe<string>;
    ctyhocnOverride?: Maybe<string>;
    openGraphImage?: Maybe<{
      __typename?: 'MediaItem';
      sourceUrl?: Maybe<string>;
    }>;
  }>;
  acfFlexible?: Maybe<{
    __typename?: 'Page_Acfflexible';
    fieldGroupName?: Maybe<string>;
    flexibleComponents?: Maybe<
      Array<
        Maybe<
          | {
              __typename: 'Page_Acfflexible_FlexibleComponents_Accordion';
              content?: Maybe<string>;
              fieldGroupName?: Maybe<string>;
              heading?: Maybe<string>;
              subtitle?: Maybe<string>;
              panel?: Maybe<
                Array<
                  Maybe<{
                    __typename?: 'Page_Acfflexible_FlexibleComponents_Accordion_panel';
                    fieldGroupName?: Maybe<string>;
                    panelLabel?: Maybe<string>;
                    panelRepeater?: Maybe<
                      Array<
                        Maybe<{
                          __typename?: 'Page_Acfflexible_FlexibleComponents_Accordion_panel_panelRepeater';
                          fieldGroupName?: Maybe<string>;
                          rowText?: Maybe<string>;
                          rowLabel?: Maybe<string>;
                        }>
                      >
                    >;
                  }>
                >
              >;
              accordionComponentSettings?: Maybe<{
                __typename?: 'Page_Acfflexible_FlexibleComponents_Accordion_AccordionComponentSettings';
                fieldGroupName?: Maybe<string>;
                showHide?: Maybe<boolean>;
                componentBackgroundColor?: Maybe<string>;
                componentBackgroundSize?: Maybe<string>;
                componentBackgroundRepeat?: Maybe<string>;
                componentBackgroundPosition?: Maybe<string>;
                contentBackgroundColor?: Maybe<string>;
                contentBackgroundSize?: Maybe<string>;
                contentBackgroundRepeat?: Maybe<string>;
                contentBackgroundPosition?: Maybe<string>;
                contentBackgroundColorLayerTwo?: Maybe<string>;
                subtitleColor?: Maybe<string>;
                textAlignment?: Maybe<string>;
                textColor?: Maybe<string>;
                titleColor?: Maybe<string>;
                hideAnimations?: Maybe<string>;
                animationDirection?: Maybe<string>;
                hideParallax?: Maybe<string>;
                sliderAnimations?: Maybe<string>;
                zoomOnHover?: Maybe<string>;
                containerMaxWidth?: Maybe<string>;
                headingsNoTranslate?: Maybe<boolean>;
                componentBackgroundImage?: Maybe<{
                  __typename?: 'MediaItem';
                  sourceUrl?: Maybe<string>;
                }>;
                contentBackgroundImage?: Maybe<{
                  __typename?: 'MediaItem';
                  sourceUrl?: Maybe<string>;
                }>;
                paddingTop?: Maybe<{
                  __typename?: 'Page_Acfflexible_FlexibleComponents_Accordion_AccordionComponentSettings_PaddingTop';
                  paddingTopDesktop?: Maybe<string>;
                  paddingTopTablet?: Maybe<string>;
                  paddingTopMobile?: Maybe<string>;
                }>;
                paddingBottom?: Maybe<{
                  __typename?: 'Page_Acfflexible_FlexibleComponents_Accordion_AccordionComponentSettings_PaddingBottom';
                  paddingBottomDesktop?: Maybe<string>;
                  paddingBottomTablet?: Maybe<string>;
                  paddingBottomMobile?: Maybe<string>;
                }>;
              }>;
            }
          | {
              __typename: 'Page_Acfflexible_FlexibleComponents_AddressBar';
              addressLine1?: Maybe<string>;
              addressLine2?: Maybe<string>;
              checkInText?: Maybe<string>;
              arrivalTitleMobile?: Maybe<string>;
              arrivalTitle?: Maybe<string>;
              addressUrl?: Maybe<string>;
              addressTitleMobile?: Maybe<string>;
              addressTitle?: Maybe<string>;
              addressText?: Maybe<string>;
              checkInTitle?: Maybe<string>;
              checkOutText?: Maybe<string>;
              checkOutTitle?: Maybe<string>;
              fieldGroupName?: Maybe<string>;
              line2Icon?: Maybe<string>;
              line2Link?: Maybe<string>;
              line2Text?: Maybe<string>;
              line3Icon?: Maybe<string>;
              line3Link?: Maybe<string>;
              line3Text?: Maybe<string>;
              telephoneIcon?: Maybe<string>;
              telephoneLine2?: Maybe<string>;
              telephoneLine1?: Maybe<string>;
              telephoneLink?: Maybe<string>;
              telephoneNumber?: Maybe<string>;
              telephoneTitle?: Maybe<string>;
              telephoneTitleMobile?: Maybe<string>;
              tripAdvisorTitle?: Maybe<string>;
              tripAdvisorTitleMobile?: Maybe<string>;
              tripAdvisorToggle?: Maybe<boolean>;
              hideArrivalDeparture?: Maybe<boolean>;
              hideAddress?: Maybe<boolean>;
              hideContactInfo?: Maybe<boolean>;
              addressBarSettings?: Maybe<{
                __typename?: 'Page_Acfflexible_FlexibleComponents_AddressBar_AddressBarSettings';
                borderBottomColour?: Maybe<string>;
                borderTopColour?: Maybe<string>;
                seperatorColor?: Maybe<string>;
                componentBackgroundColor?: Maybe<string>;
                componentBackgroundSize?: Maybe<string>;
                componentBackgroundRepeat?: Maybe<string>;
                componentBackgroundPosition?: Maybe<string>;
                contentBackgroundColor?: Maybe<string>;
                contentBackgroundSize?: Maybe<string>;
                contentBackgroundRepeat?: Maybe<string>;
                contentBackgroundPosition?: Maybe<string>;
                fieldGroupName?: Maybe<string>;
                subtitleColor?: Maybe<string>;
                textAlignment?: Maybe<string>;
                textColor?: Maybe<string>;
                titleColor?: Maybe<string>;
                showHide?: Maybe<boolean>;
                hideAnimations?: Maybe<string>;
                animationDirection?: Maybe<string>;
                hideParallax?: Maybe<string>;
                componentBackgroundImage?: Maybe<{
                  __typename?: 'MediaItem';
                  sourceUrl?: Maybe<string>;
                }>;
                contentBackgroundImage?: Maybe<{
                  __typename?: 'MediaItem';
                  sourceUrl?: Maybe<string>;
                }>;
                paddingTop?: Maybe<{
                  __typename?: 'Page_Acfflexible_FlexibleComponents_AddressBar_AddressBarSettings_PaddingTop';
                  paddingTopDesktop?: Maybe<string>;
                  paddingTopTablet?: Maybe<string>;
                  paddingTopMobile?: Maybe<string>;
                }>;
                paddingBottom?: Maybe<{
                  __typename?: 'Page_Acfflexible_FlexibleComponents_AddressBar_AddressBarSettings_PaddingBottom';
                  paddingBottomDesktop?: Maybe<string>;
                  paddingBottomTablet?: Maybe<string>;
                  paddingBottomMobile?: Maybe<string>;
                }>;
              }>;
            }
          | {
              __typename: 'Page_Acfflexible_FlexibleComponents_ComparisonTable';
              fieldGroupName?: Maybe<string>;
              introduction?: Maybe<string>;
              title?: Maybe<string>;
              repeater?: Maybe<
                Array<
                  Maybe<{
                    __typename?: 'Page_Acfflexible_FlexibleComponents_ComparisonTable_repeater';
                    fieldGroupName?: Maybe<string>;
                    sectionRows?: Maybe<
                      Array<
                        Maybe<{
                          __typename?: 'Page_Acfflexible_FlexibleComponents_ComparisonTable_repeater_sectionRows';
                          fieldGroupName?: Maybe<string>;
                          rowTitle?: Maybe<string>;
                          subrows?: Maybe<
                            Array<
                              Maybe<{
                                __typename?: 'Page_Acfflexible_FlexibleComponents_ComparisonTable_repeater_sectionRows_subrows';
                                fieldGroupName?: Maybe<string>;
                                column?: Maybe<
                                  Array<
                                    Maybe<{
                                      __typename?: 'Page_Acfflexible_FlexibleComponents_ComparisonTable_repeater_sectionRows_subrows_column';
                                      fieldGroupName?: Maybe<string>;
                                      item?: Maybe<string>;
                                    }>
                                  >
                                >;
                              }>
                            >
                          >;
                        }>
                      >
                    >;
                    tableHeading?: Maybe<
                      Array<
                        Maybe<{
                          __typename?: 'Page_Acfflexible_FlexibleComponents_ComparisonTable_repeater_tableHeading';
                          fieldGroupName?: Maybe<string>;
                          text?: Maybe<string>;
                          backgroundColour?: Maybe<string>;
                          textColour?: Maybe<string>;
                          headerUrl?: Maybe<{
                            __typename?: 'AcfLink';
                            target?: Maybe<string>;
                            title?: Maybe<string>;
                            url?: Maybe<string>;
                          }>;
                        }>
                      >
                    >;
                  }>
                >
              >;
              comparisonTableSettings?: Maybe<{
                __typename?: 'Page_Acfflexible_FlexibleComponents_ComparisonTable_ComparisonTableSettings';
                fieldGroupName?: Maybe<string>;
                showHide?: Maybe<boolean>;
                componentBackgroundColor?: Maybe<string>;
                componentBackgroundSize?: Maybe<string>;
                componentBackgroundRepeat?: Maybe<string>;
                componentBackgroundPosition?: Maybe<string>;
                contentBackgroundColor?: Maybe<string>;
                contentBackgroundSize?: Maybe<string>;
                contentBackgroundRepeat?: Maybe<string>;
                contentBackgroundPosition?: Maybe<string>;
                contentBackgroundColorLayerTwo?: Maybe<string>;
                subtitleColor?: Maybe<string>;
                textAlignment?: Maybe<string>;
                textColor?: Maybe<string>;
                titleColor?: Maybe<string>;
                hideAnimations?: Maybe<string>;
                animationDirection?: Maybe<string>;
                hideParallax?: Maybe<string>;
                sliderAnimations?: Maybe<string>;
                zoomOnHover?: Maybe<string>;
                containerMaxWidth?: Maybe<string>;
                headingsNoTranslate?: Maybe<boolean>;
                componentBackgroundImage?: Maybe<{
                  __typename?: 'MediaItem';
                  sourceUrl?: Maybe<string>;
                }>;
                contentBackgroundImage?: Maybe<{
                  __typename?: 'MediaItem';
                  sourceUrl?: Maybe<string>;
                }>;
                paddingTop?: Maybe<{
                  __typename?: 'Page_Acfflexible_FlexibleComponents_ComparisonTable_ComparisonTableSettings_PaddingTop';
                  paddingTopDesktop?: Maybe<string>;
                  paddingTopTablet?: Maybe<string>;
                  paddingTopMobile?: Maybe<string>;
                }>;
                paddingBottom?: Maybe<{
                  __typename?: 'Page_Acfflexible_FlexibleComponents_ComparisonTable_ComparisonTableSettings_PaddingBottom';
                  paddingBottomDesktop?: Maybe<string>;
                  paddingBottomTablet?: Maybe<string>;
                  paddingBottomMobile?: Maybe<string>;
                }>;
              }>;
            }
          | {
              __typename: 'Page_Acfflexible_FlexibleComponents_Cvent';
              cventCode?: Maybe<string>;
              cventComponentSettings?: Maybe<{
                __typename?: 'Page_Acfflexible_FlexibleComponents_Cvent_CventComponentSettings';
                fieldGroupName?: Maybe<string>;
                componentBackgroundColor?: Maybe<string>;
                componentBackgroundSize?: Maybe<string>;
                componentBackgroundRepeat?: Maybe<string>;
                componentBackgroundPosition?: Maybe<string>;
                contentBackgroundColor?: Maybe<string>;
                contentBackgroundSize?: Maybe<string>;
                contentBackgroundRepeat?: Maybe<string>;
                contentBackgroundPosition?: Maybe<string>;
                contentBackgroundColorLayerTwo?: Maybe<string>;
                subtitleColor?: Maybe<string>;
                textAlignment?: Maybe<string>;
                textColor?: Maybe<string>;
                titleColor?: Maybe<string>;
                showHide?: Maybe<boolean>;
                hideAnimations?: Maybe<string>;
                animationDirection?: Maybe<string>;
                hideParallax?: Maybe<string>;
                sliderAnimations?: Maybe<string>;
                zoomOnHover?: Maybe<string>;
                containerMaxWidth?: Maybe<string>;
                headingsNoTranslate?: Maybe<boolean>;
                componentBackgroundImage?: Maybe<{
                  __typename?: 'MediaItem';
                  sourceUrl?: Maybe<string>;
                }>;
                contentBackgroundImage?: Maybe<{
                  __typename?: 'MediaItem';
                  sourceUrl?: Maybe<string>;
                }>;
                paddingTop?: Maybe<{
                  __typename?: 'Page_Acfflexible_FlexibleComponents_Cvent_CventComponentSettings_PaddingTop';
                  paddingTopDesktop?: Maybe<string>;
                  paddingTopTablet?: Maybe<string>;
                  paddingTopMobile?: Maybe<string>;
                }>;
                paddingBottom?: Maybe<{
                  __typename?: 'Page_Acfflexible_FlexibleComponents_Cvent_CventComponentSettings_PaddingBottom';
                  paddingBottomDesktop?: Maybe<string>;
                  paddingBottomTablet?: Maybe<string>;
                  paddingBottomMobile?: Maybe<string>;
                }>;
              }>;
            }
          | {
              __typename: 'Page_Acfflexible_FlexibleComponents_EditorialSnippet';
              fieldGroupName?: Maybe<string>;
              subtitle?: Maybe<string>;
              heading?: Maybe<string>;
              description?: Maybe<string>;
              iconList?: Maybe<string>;
              cropImageToCircle?: Maybe<boolean>;
              link?: Maybe<{
                __typename?: 'AcfLink';
                target?: Maybe<string>;
                title?: Maybe<string>;
                url?: Maybe<string>;
              }>;
              image?: Maybe<{
                __typename?: 'MediaItem';
                altText?: Maybe<string>;
                sourceUrl?: Maybe<string>;
                slug?: Maybe<string>;
                title?: Maybe<string>;
              }>;
              editorialSnippetComponentSettings?: Maybe<{
                __typename?: 'Page_Acfflexible_FlexibleComponents_EditorialSnippet_EditorialSnippetComponentSettings';
                componentLabel?: Maybe<string>;
                fieldGroupName?: Maybe<string>;
                showHide?: Maybe<boolean>;
                componentBackgroundColor?: Maybe<string>;
                componentBackgroundSize?: Maybe<string>;
                componentBackgroundRepeat?: Maybe<string>;
                componentBackgroundPosition?: Maybe<string>;
                contentBackgroundColor?: Maybe<string>;
                contentBackgroundSize?: Maybe<string>;
                contentBackgroundRepeat?: Maybe<string>;
                contentBackgroundPosition?: Maybe<string>;
                contentBackgroundColorLayerTwo?: Maybe<string>;
                subtitleColor?: Maybe<string>;
                textAlignment?: Maybe<string>;
                textColor?: Maybe<string>;
                titleColor?: Maybe<string>;
                animationDirection?: Maybe<string>;
                hideAnimations?: Maybe<string>;
                hideParallax?: Maybe<string>;
                sliderAnimations?: Maybe<string>;
                zoomOnHover?: Maybe<string>;
                containerMaxWidth?: Maybe<string>;
                headingsNoTranslate?: Maybe<boolean>;
                componentBackgroundImage?: Maybe<{
                  __typename?: 'MediaItem';
                  sourceUrl?: Maybe<string>;
                }>;
                contentBackgroundImage?: Maybe<{
                  __typename?: 'MediaItem';
                  sourceUrl?: Maybe<string>;
                }>;
                paddingTop?: Maybe<{
                  __typename?: 'Page_Acfflexible_FlexibleComponents_EditorialSnippet_EditorialSnippetComponentSettings_PaddingTop';
                  paddingTopDesktop?: Maybe<string>;
                  paddingTopTablet?: Maybe<string>;
                  paddingTopMobile?: Maybe<string>;
                }>;
                paddingBottom?: Maybe<{
                  __typename?: 'Page_Acfflexible_FlexibleComponents_EditorialSnippet_EditorialSnippetComponentSettings_PaddingBottom';
                  paddingBottomDesktop?: Maybe<string>;
                  paddingBottomTablet?: Maybe<string>;
                  paddingBottomMobile?: Maybe<string>;
                }>;
              }>;
            }
          | {
              __typename: 'Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid';
              fieldGroupName?: Maybe<string>;
              eventsCalendarComponentSettings?: Maybe<{
                __typename?: 'Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid_EventsCalendarComponentSettings';
                fieldGroupName?: Maybe<string>;
                eventsCalendarSettings?: Maybe<{
                  __typename?: 'Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid_EventsCalendarComponentSettings_EventsCalendarSettings';
                  fieldGroupName?: Maybe<string>;
                  showHide?: Maybe<boolean>;
                }>;
              }>;
            }
          | {
              __typename: 'Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles';
            }
          | {
              __typename: 'Page_Acfflexible_FlexibleComponents_FourStaggeredTiles';
              fieldGroupName?: Maybe<string>;
              repeater?: Maybe<
                Array<
                  Maybe<{
                    __typename?: 'Page_Acfflexible_FlexibleComponents_FourStaggeredTiles_repeater';
                    copy?: Maybe<string>;
                    tileSize?: Maybe<string>;
                    title?: Maybe<string>;
                    title_noTx?: Maybe<string>;
                    image?: Maybe<{
                      __typename?: 'MediaItem';
                      altText?: Maybe<string>;
                      sourceUrl?: Maybe<string>;
                    }>;
                  }>
                >
              >;
              fourStaggeredTilesComponentSettings?: Maybe<{
                __typename?: 'Page_Acfflexible_FlexibleComponents_FourStaggeredTiles_FourStaggeredTilesComponentSettings';
                fieldGroupName?: Maybe<string>;
                showHide?: Maybe<boolean>;
                componentBackgroundColor?: Maybe<string>;
                componentBackgroundSize?: Maybe<string>;
                componentBackgroundRepeat?: Maybe<string>;
                componentBackgroundPosition?: Maybe<string>;
                contentBackgroundColor?: Maybe<string>;
                contentBackgroundSize?: Maybe<string>;
                contentBackgroundRepeat?: Maybe<string>;
                contentBackgroundPosition?: Maybe<string>;
                contentBackgroundColorLayerTwo?: Maybe<string>;
                subtitleColor?: Maybe<string>;
                textAlignment?: Maybe<string>;
                textColor?: Maybe<string>;
                titleColor?: Maybe<string>;
                hideAnimations?: Maybe<string>;
                animationDirection?: Maybe<string>;
                hideParallax?: Maybe<string>;
                sliderAnimations?: Maybe<string>;
                zoomOnHover?: Maybe<string>;
                containerMaxWidth?: Maybe<string>;
                headingsNoTranslate?: Maybe<boolean>;
                componentBackgroundImage?: Maybe<{
                  __typename?: 'MediaItem';
                  sourceUrl?: Maybe<string>;
                }>;
                contentBackgroundImage?: Maybe<{
                  __typename?: 'MediaItem';
                  sourceUrl?: Maybe<string>;
                }>;
                paddingTop?: Maybe<{
                  __typename?: 'Page_Acfflexible_FlexibleComponents_FourStaggeredTiles_FourStaggeredTilesComponentSettings_PaddingTop';
                  paddingTopDesktop?: Maybe<string>;
                  paddingTopTablet?: Maybe<string>;
                  paddingTopMobile?: Maybe<string>;
                }>;
                paddingBottom?: Maybe<{
                  __typename?: 'Page_Acfflexible_FlexibleComponents_FourStaggeredTiles_FourStaggeredTilesComponentSettings_PaddingBottom';
                  paddingBottomDesktop?: Maybe<string>;
                  paddingBottomTablet?: Maybe<string>;
                  paddingBottomMobile?: Maybe<string>;
                }>;
              }>;
            }
          | {
              __typename: 'Page_Acfflexible_FlexibleComponents_FullWidthImageContent';
              copy?: Maybe<string>;
              fieldGroupName?: Maybe<string>;
              styles?: Maybe<string>;
              title?: Maybe<string>;
              flipImageHorizontally?: Maybe<boolean>;
              subtitle?: Maybe<string>;
              enableCarousel?: Maybe<boolean>;
              enableCropping?: Maybe<boolean>;
              cropType?: Maybe<string>;
              cropHeight?: Maybe<string>;
              cropWidth?: Maybe<string>;
              xPosition?: Maybe<string>;
              xPositionAdvanced?: Maybe<string>;
              yPosition?: Maybe<string>;
              yPositionAdvanced?: Maybe<string>;
              autoPosition?: Maybe<boolean>;
              title_noTx?: Maybe<string>;
              subtitle_noTx?: Maybe<string>;
              image?: Maybe<{
                __typename?: 'MediaItem';
                altText?: Maybe<string>;
                sourceUrl?: Maybe<string>;
              }>;
              mediaBlock?: Maybe<{ __typename?: 'Media_block'; id: string }>;
              buttons?: Maybe<
                Array<
                  Maybe<{
                    __typename?: 'Page_Acfflexible_FlexibleComponents_FullWidthImageContent_buttons';
                    buttonStyle?: Maybe<string>;
                    fieldGroupName?: Maybe<string>;
                    link?: Maybe<{
                      __typename?: 'AcfLink';
                      target?: Maybe<string>;
                      title?: Maybe<string>;
                      url?: Maybe<string>;
                    }>;
                  }>
                >
              >;
              carouselItems?: Maybe<
                Array<
                  Maybe<{
                    __typename?: 'Page_Acfflexible_FlexibleComponents_FullWidthImageContent_carouselItems';
                    styles?: Maybe<string>;
                    title?: Maybe<string>;
                    subtitle?: Maybe<string>;
                    copy?: Maybe<string>;
                    showHide?: Maybe<boolean>;
                    title_noTx?: Maybe<string>;
                    subtitle_noTx?: Maybe<string>;
                    image?: Maybe<{
                      __typename?: 'MediaItem';
                      altText?: Maybe<string>;
                      sourceUrl?: Maybe<string>;
                    }>;
                    buttons?: Maybe<
                      Array<
                        Maybe<{
                          __typename?: 'Page_Acfflexible_FlexibleComponents_FullWidthImageContent_carouselItems_buttons';
                          buttonStyle?: Maybe<string>;
                          fieldGroupName?: Maybe<string>;
                          link?: Maybe<{
                            __typename?: 'AcfLink';
                            target?: Maybe<string>;
                            title?: Maybe<string>;
                            url?: Maybe<string>;
                          }>;
                        }>
                      >
                    >;
                  }>
                >
              >;
              fullWidthImageComponentSettings?: Maybe<{
                __typename?: 'Page_Acfflexible_FlexibleComponents_FullWidthImageContent_FullWidthImageComponentSettings';
                fieldGroupName?: Maybe<string>;
                showHide?: Maybe<boolean>;
                componentBackgroundColor?: Maybe<string>;
                componentBackgroundSize?: Maybe<string>;
                componentBackgroundRepeat?: Maybe<string>;
                componentBackgroundPosition?: Maybe<string>;
                contentBackgroundColor?: Maybe<string>;
                contentBackgroundSize?: Maybe<string>;
                contentBackgroundRepeat?: Maybe<string>;
                contentBackgroundPosition?: Maybe<string>;
                contentBackgroundColorLayerTwo?: Maybe<string>;
                subtitleColor?: Maybe<string>;
                textAlignment?: Maybe<string>;
                textColor?: Maybe<string>;
                titleColor?: Maybe<string>;
                hideAnimations?: Maybe<string>;
                animationDirection?: Maybe<string>;
                hideParallax?: Maybe<string>;
                sliderAnimations?: Maybe<string>;
                zoomOnHover?: Maybe<string>;
                containerMaxWidth?: Maybe<string>;
                headingsNoTranslate?: Maybe<boolean>;
                componentBackgroundImage?: Maybe<{
                  __typename?: 'MediaItem';
                  sourceUrl?: Maybe<string>;
                }>;
                contentBackgroundImage?: Maybe<{
                  __typename?: 'MediaItem';
                  sourceUrl?: Maybe<string>;
                }>;
                paddingTop?: Maybe<{
                  __typename?: 'Page_Acfflexible_FlexibleComponents_FullWidthImageContent_FullWidthImageComponentSettings_PaddingTop';
                  paddingTopDesktop?: Maybe<string>;
                  paddingTopTablet?: Maybe<string>;
                  paddingTopMobile?: Maybe<string>;
                }>;
                paddingBottom?: Maybe<{
                  __typename?: 'Page_Acfflexible_FlexibleComponents_FullWidthImageContent_FullWidthImageComponentSettings_PaddingBottom';
                  paddingBottomDesktop?: Maybe<string>;
                  paddingBottomTablet?: Maybe<string>;
                  paddingBottomMobile?: Maybe<string>;
                }>;
              }>;
            }
          | {
              __typename: 'Page_Acfflexible_FlexibleComponents_GallerySlider';
              columnCount?: Maybe<number>;
              fieldGroupName?: Maybe<string>;
              tileHeight?: Maybe<string>;
              tilePadding?: Maybe<number>;
              repeater?: Maybe<
                Array<
                  Maybe<{
                    __typename?: 'Page_Acfflexible_FlexibleComponents_GallerySlider_repeater';
                    image?: Maybe<{
                      __typename?: 'MediaItem';
                      altText?: Maybe<string>;
                      sourceUrl?: Maybe<string>;
                    }>;
                  }>
                >
              >;
              gallerySliderComponentSettings?: Maybe<{
                __typename?: 'Page_Acfflexible_FlexibleComponents_GallerySlider_GallerySliderComponentSettings';
                fieldGroupName?: Maybe<string>;
                showHide?: Maybe<boolean>;
                componentBackgroundColor?: Maybe<string>;
                componentBackgroundSize?: Maybe<string>;
                componentBackgroundRepeat?: Maybe<string>;
                componentBackgroundPosition?: Maybe<string>;
                contentBackgroundColor?: Maybe<string>;
                contentBackgroundSize?: Maybe<string>;
                contentBackgroundRepeat?: Maybe<string>;
                contentBackgroundPosition?: Maybe<string>;
                contentBackgroundColorLayerTwo?: Maybe<string>;
                subtitleColor?: Maybe<string>;
                textAlignment?: Maybe<string>;
                textColor?: Maybe<string>;
                titleColor?: Maybe<string>;
                hideAnimations?: Maybe<string>;
                animationDirection?: Maybe<string>;
                hideParallax?: Maybe<string>;
                sliderAnimations?: Maybe<string>;
                zoomOnHover?: Maybe<string>;
                containerMaxWidth?: Maybe<string>;
                headingsNoTranslate?: Maybe<boolean>;
                componentBackgroundImage?: Maybe<{
                  __typename?: 'MediaItem';
                  sourceUrl?: Maybe<string>;
                }>;
                contentBackgroundImage?: Maybe<{
                  __typename?: 'MediaItem';
                  sourceUrl?: Maybe<string>;
                }>;
                paddingTop?: Maybe<{
                  __typename?: 'Page_Acfflexible_FlexibleComponents_GallerySlider_GallerySliderComponentSettings_PaddingTop';
                  paddingTopDesktop?: Maybe<string>;
                  paddingTopTablet?: Maybe<string>;
                  paddingTopMobile?: Maybe<string>;
                }>;
                paddingBottom?: Maybe<{
                  __typename?: 'Page_Acfflexible_FlexibleComponents_GallerySlider_GallerySliderComponentSettings_PaddingBottom';
                  paddingBottomDesktop?: Maybe<string>;
                  paddingBottomTablet?: Maybe<string>;
                  paddingBottomMobile?: Maybe<string>;
                }>;
              }>;
            }
          | {
              __typename: 'Page_Acfflexible_FlexibleComponents_HalfAndHalf';
              fieldGroupName?: Maybe<string>;
              repeater?: Maybe<
                Array<
                  Maybe<{
                    __typename?: 'Page_Acfflexible_FlexibleComponents_HalfAndHalf_repeater';
                    copy?: Maybe<string>;
                    title?: Maybe<string>;
                    imageSize?: Maybe<string>;
                    imagePositioning?: Maybe<string>;
                    layoutMode?: Maybe<string>;
                    accordionPanel?: Maybe<
                      Array<
                        Maybe<{
                          __typename?: 'Page_Acfflexible_FlexibleComponents_HalfAndHalf_repeater_accordionPanel';
                          panelLabel?: Maybe<string>;
                          panelRepeater?: Maybe<
                            Array<
                              Maybe<{
                                __typename?: 'Page_Acfflexible_FlexibleComponents_HalfAndHalf_repeater_accordionPanel_panelRepeater';
                                fieldGroupName?: Maybe<string>;
                                rowLabel?: Maybe<string>;
                                rowText?: Maybe<string>;
                              }>
                            >
                          >;
                        }>
                      >
                    >;
                    titleIcon?: Maybe<{
                      __typename?: 'MediaItem';
                      altText?: Maybe<string>;
                      sourceUrl?: Maybe<string>;
                    }>;
                    image?: Maybe<{
                      __typename?: 'MediaItem';
                      altText?: Maybe<string>;
                      sourceUrl?: Maybe<string>;
                    }>;
                    mediaBlock?: Maybe<{
                      __typename?: 'Media_block';
                      id: string;
                    }>;
                    mediaCopyIconList?: Maybe<
                      Array<
                        Maybe<{
                          __typename?: 'Page_Acfflexible_FlexibleComponents_HalfAndHalf_repeater_iconList';
                          iconColor?: Maybe<string>;
                          iconList?: Maybe<string>;
                          text?: Maybe<string>;
                        }>
                      >
                    >;
                    buttons?: Maybe<
                      Array<
                        Maybe<{
                          __typename?: 'Page_Acfflexible_FlexibleComponents_HalfAndHalf_repeater_buttons';
                          buttonStyle?: Maybe<string>;
                          fieldGroupName?: Maybe<string>;
                          link?: Maybe<{
                            __typename?: 'AcfLink';
                            target?: Maybe<string>;
                            title?: Maybe<string>;
                            url?: Maybe<string>;
                          }>;
                        }>
                      >
                    >;
                    tableData?: Maybe<
                      Array<
                        Maybe<{
                          __typename?: 'Page_Acfflexible_FlexibleComponents_HalfAndHalf_repeater_tableData';
                          title?: Maybe<string>;
                          text?: Maybe<string>;
                          fieldGroupName?: Maybe<string>;
                        }>
                      >
                    >;
                    imageGallery?: Maybe<
                      Array<
                        Maybe<{
                          __typename?: 'MediaItem';
                          sourceUrl?: Maybe<string>;
                          altText?: Maybe<string>;
                        }>
                      >
                    >;
                  }>
                >
              >;
              halfAndHalfComponentSettings?: Maybe<{
                __typename?: 'Page_Acfflexible_FlexibleComponents_HalfAndHalf_HalfAndHalfComponentSettings';
                fieldGroupName?: Maybe<string>;
                showHide?: Maybe<boolean>;
                accordionBorderColor?: Maybe<string>;
                accordionIconColor?: Maybe<string>;
                accordionIconColorActive?: Maybe<string>;
                accordionPanelBackgroundColor?: Maybe<string>;
                accordionPanelLabelColor?: Maybe<string>;
                accordionRowLabelColor?: Maybe<string>;
                accordionRowTextColor?: Maybe<string>;
                componentBackgroundColor?: Maybe<string>;
                componentBackgroundSize?: Maybe<string>;
                componentBackgroundRepeat?: Maybe<string>;
                componentBackgroundPosition?: Maybe<string>;
                contentBackgroundColor?: Maybe<string>;
                contentBackgroundSize?: Maybe<string>;
                contentBackgroundRepeat?: Maybe<string>;
                contentBackgroundPosition?: Maybe<string>;
                contentBackgroundColorLayerTwo?: Maybe<string>;
                subtitleColor?: Maybe<string>;
                textAlignment?: Maybe<string>;
                textColor?: Maybe<string>;
                titleColor?: Maybe<string>;
                hideAnimations?: Maybe<string>;
                animationDirection?: Maybe<string>;
                hideParallax?: Maybe<string>;
                sliderAnimations?: Maybe<string>;
                zoomOnHover?: Maybe<string>;
                containerMaxWidth?: Maybe<string>;
                headingsNoTranslate?: Maybe<boolean>;
                componentBackgroundImage?: Maybe<{
                  __typename?: 'MediaItem';
                  sourceUrl?: Maybe<string>;
                }>;
                contentBackgroundImage?: Maybe<{
                  __typename?: 'MediaItem';
                  sourceUrl?: Maybe<string>;
                }>;
                paddingTop?: Maybe<{
                  __typename?: 'Page_Acfflexible_FlexibleComponents_HalfAndHalf_HalfAndHalfComponentSettings_PaddingTop';
                  paddingTopDesktop?: Maybe<string>;
                  paddingTopTablet?: Maybe<string>;
                  paddingTopMobile?: Maybe<string>;
                }>;
                paddingBottom?: Maybe<{
                  __typename?: 'Page_Acfflexible_FlexibleComponents_HalfAndHalf_HalfAndHalfComponentSettings_PaddingBottom';
                  paddingBottomDesktop?: Maybe<string>;
                  paddingBottomTablet?: Maybe<string>;
                  paddingBottomMobile?: Maybe<string>;
                }>;
              }>;
            }
          | {
              __typename: 'Page_Acfflexible_FlexibleComponents_HotelAmenities';
              showHotelAmenitiesIcons?: Maybe<boolean>;
              ctyhocn?: Maybe<string>;
              header?: Maybe<string>;
              buttonStyling?: Maybe<string>;
              iconBlockCorePlusComponentSettings?: Maybe<{
                __typename?: 'Page_Acfflexible_FlexibleComponents_HotelAmenities_IconBlockCorePlusComponentSettings';
                fieldGroupName?: Maybe<string>;
                showHide?: Maybe<boolean>;
                componentBackgroundColor?: Maybe<string>;
                componentBackgroundSize?: Maybe<string>;
                componentBackgroundRepeat?: Maybe<string>;
                componentBackgroundPosition?: Maybe<string>;
                contentBackgroundColor?: Maybe<string>;
                contentBackgroundSize?: Maybe<string>;
                contentBackgroundRepeat?: Maybe<string>;
                contentBackgroundPosition?: Maybe<string>;
                contentBackgroundColorLayerTwo?: Maybe<string>;
                subtitleColor?: Maybe<string>;
                textAlignment?: Maybe<string>;
                textColor?: Maybe<string>;
                titleColor?: Maybe<string>;
                hideAnimations?: Maybe<string>;
                animationDirection?: Maybe<string>;
                hideParallax?: Maybe<string>;
                sliderAnimations?: Maybe<string>;
                zoomOnHover?: Maybe<string>;
                containerMaxWidth?: Maybe<string>;
                headingsNoTranslate?: Maybe<boolean>;
                componentBackgroundImage?: Maybe<{
                  __typename?: 'MediaItem';
                  sourceUrl?: Maybe<string>;
                }>;
                contentBackgroundImage?: Maybe<{
                  __typename?: 'MediaItem';
                  sourceUrl?: Maybe<string>;
                }>;
                paddingTop?: Maybe<{
                  __typename?: 'Page_Acfflexible_FlexibleComponents_HotelAmenities_IconBlockCorePlusComponentSettings_PaddingTop';
                  paddingTopDesktop?: Maybe<string>;
                  paddingTopTablet?: Maybe<string>;
                  paddingTopMobile?: Maybe<string>;
                }>;
                paddingBottom?: Maybe<{
                  __typename?: 'Page_Acfflexible_FlexibleComponents_HotelAmenities_IconBlockCorePlusComponentSettings_PaddingBottom';
                  paddingBottomDesktop?: Maybe<string>;
                  paddingBottomTablet?: Maybe<string>;
                  paddingBottomMobile?: Maybe<string>;
                }>;
              }>;
            }
          | {
              __typename: 'Page_Acfflexible_FlexibleComponents_HotelPolicies';
              fieldGroupName?: Maybe<string>;
              subtitle?: Maybe<string>;
              title?: Maybe<string>;
              copy?: Maybe<string>;
              displayHotelAccessibilityLink?: Maybe<boolean>;
              hotelAccessibilityGuideUrl?: Maybe<string>;
              hotelPoliciesComponentSettings?: Maybe<{
                __typename?: 'Page_Acfflexible_FlexibleComponents_HotelPolicies_HotelPoliciesComponentSettings';
                fieldGroupName?: Maybe<string>;
                showHide?: Maybe<boolean>;
                componentBackgroundColor?: Maybe<string>;
                componentBackgroundSize?: Maybe<string>;
                componentBackgroundRepeat?: Maybe<string>;
                componentBackgroundPosition?: Maybe<string>;
                contentBackgroundColor?: Maybe<string>;
                contentBackgroundSize?: Maybe<string>;
                contentBackgroundRepeat?: Maybe<string>;
                contentBackgroundPosition?: Maybe<string>;
                contentBackgroundColorLayerTwo?: Maybe<string>;
                subtitleColor?: Maybe<string>;
                textAlignment?: Maybe<string>;
                textColor?: Maybe<string>;
                titleColor?: Maybe<string>;
                hideAnimations?: Maybe<string>;
                animationDirection?: Maybe<string>;
                hideParallax?: Maybe<string>;
                sliderAnimations?: Maybe<string>;
                zoomOnHover?: Maybe<string>;
                containerMaxWidth?: Maybe<string>;
                headingsNoTranslate?: Maybe<boolean>;
                componentBackgroundImage?: Maybe<{
                  __typename?: 'MediaItem';
                  sourceUrl?: Maybe<string>;
                }>;
                contentBackgroundImage?: Maybe<{
                  __typename?: 'MediaItem';
                  sourceUrl?: Maybe<string>;
                }>;
                paddingTop?: Maybe<{
                  __typename?: 'Page_Acfflexible_FlexibleComponents_HotelPolicies_HotelPoliciesComponentSettings_PaddingTop';
                  paddingTopDesktop?: Maybe<string>;
                  paddingTopTablet?: Maybe<string>;
                  paddingTopMobile?: Maybe<string>;
                }>;
                paddingBottom?: Maybe<{
                  __typename?: 'Page_Acfflexible_FlexibleComponents_HotelPolicies_HotelPoliciesComponentSettings_PaddingBottom';
                  paddingBottomDesktop?: Maybe<string>;
                  paddingBottomTablet?: Maybe<string>;
                  paddingBottomMobile?: Maybe<string>;
                }>;
              }>;
            }
          | {
              __typename: 'Page_Acfflexible_FlexibleComponents_HotelTitle';
              fieldGroupName?: Maybe<string>;
              hotelTitleComponentSettings?: Maybe<{
                __typename?: 'Page_Acfflexible_FlexibleComponents_HotelTitle_HotelTitleComponentSettings';
                fieldGroupName?: Maybe<string>;
                showHide?: Maybe<boolean>;
                componentBackgroundColor?: Maybe<string>;
                componentBackgroundSize?: Maybe<string>;
                componentBackgroundRepeat?: Maybe<string>;
                componentBackgroundPosition?: Maybe<string>;
                contentBackgroundColor?: Maybe<string>;
                contentBackgroundSize?: Maybe<string>;
                contentBackgroundRepeat?: Maybe<string>;
                contentBackgroundPosition?: Maybe<string>;
                contentBackgroundColorLayerTwo?: Maybe<string>;
                subtitleColor?: Maybe<string>;
                textAlignment?: Maybe<string>;
                textColor?: Maybe<string>;
                titleColor?: Maybe<string>;
                hideAnimations?: Maybe<string>;
                animationDirection?: Maybe<string>;
                hideParallax?: Maybe<string>;
                sliderAnimations?: Maybe<string>;
                zoomOnHover?: Maybe<string>;
                containerMaxWidth?: Maybe<string>;
                headingsNoTranslate?: Maybe<boolean>;
                componentBackgroundImage?: Maybe<{
                  __typename?: 'MediaItem';
                  sourceUrl?: Maybe<string>;
                }>;
                contentBackgroundImage?: Maybe<{
                  __typename?: 'MediaItem';
                  sourceUrl?: Maybe<string>;
                }>;
                paddingTop?: Maybe<{
                  __typename?: 'Page_Acfflexible_FlexibleComponents_HotelTitle_HotelTitleComponentSettings_PaddingTop';
                  paddingTopDesktop?: Maybe<string>;
                  paddingTopTablet?: Maybe<string>;
                  paddingTopMobile?: Maybe<string>;
                }>;
                paddingBottom?: Maybe<{
                  __typename?: 'Page_Acfflexible_FlexibleComponents_HotelTitle_HotelTitleComponentSettings_PaddingBottom';
                  paddingBottomDesktop?: Maybe<string>;
                  paddingBottomTablet?: Maybe<string>;
                  paddingBottomMobile?: Maybe<string>;
                }>;
              }>;
            }
          | {
              __typename: 'Page_Acfflexible_FlexibleComponents_HtmlMenu';
              header?: Maybe<string>;
              tabTypeButtonStyle?: Maybe<string>;
              header_noTx?: Maybe<string>;
              menu?: Maybe<{ __typename?: 'Html_menu'; id: string }>;
              menuRepeater?: Maybe<
                Array<
                  Maybe<{
                    __typename?: 'Page_Acfflexible_FlexibleComponents_HtmlMenu_menuRepeater';
                    fieldGroupName?: Maybe<string>;
                    tabTitle?: Maybe<string>;
                    tabTitle_noTx?: Maybe<string>;
                    menuSections?: Maybe<
                      Array<
                        Maybe<{
                          __typename?: 'Page_Acfflexible_FlexibleComponents_HtmlMenu_menuRepeater_menuSections';
                          fieldGroupName?: Maybe<string>;
                          pricePosition?: Maybe<string>;
                          sectionBackgroundColour?: Maybe<string>;
                          sectionBorderColour?: Maybe<string>;
                          itemDescriptionColour?: Maybe<string>;
                          itemDisclaimerColour?: Maybe<string>;
                          itemPriceColour?: Maybe<string>;
                          itemTitleColour?: Maybe<string>;
                          sectionAlignment?: Maybe<string>;
                          sectionDisclaimer?: Maybe<boolean>;
                          sectionDisclaimerLine1?: Maybe<string>;
                          sectionDisclaimerLine2?: Maybe<string>;
                          sectionDisclaimerColour?: Maybe<string>;
                          spotlightBorderColour?: Maybe<string>;
                          sectionLayout?: Maybe<string>;
                          sectionSubtitle?: Maybe<string>;
                          sectionTitle?: Maybe<string>;
                          sectionDisclaimerLine1_noTx?: Maybe<string>;
                          sectionDisclaimerLine2_noTx?: Maybe<string>;
                          sectionSubtitle_noTx?: Maybe<string>;
                          sectionTitle_noTx?: Maybe<string>;
                          sectionItems?: Maybe<
                            Array<
                              Maybe<{
                                __typename?: 'Page_Acfflexible_FlexibleComponents_HtmlMenu_menuRepeater_menuSections_sectionItems';
                                fieldGroupName?: Maybe<string>;
                                itemDisclaimer?: Maybe<string>;
                                itemLine1?: Maybe<string>;
                                itemLine2?: Maybe<string>;
                                itemPrice?: Maybe<string>;
                                highlightItem?: Maybe<boolean>;
                                itemDisclaimer_noTx?: Maybe<string>;
                                itemLine1_noTx?: Maybe<string>;
                                itemLine2_noTx?: Maybe<string>;
                                itemPrice_noTx?: Maybe<string>;
                              }>
                            >
                          >;
                        }>
                      >
                    >;
                  }>
                >
              >;
              htmlMenuSettings?: Maybe<{
                __typename?: 'Page_Acfflexible_FlexibleComponents_HtmlMenu_HtmlMenuSettings';
                fieldGroupName?: Maybe<string>;
                componentBackgroundColor?: Maybe<string>;
                componentBackgroundSize?: Maybe<string>;
                componentBackgroundRepeat?: Maybe<string>;
                componentBackgroundPosition?: Maybe<string>;
                contentBackgroundColor?: Maybe<string>;
                contentBackgroundSize?: Maybe<string>;
                contentBackgroundRepeat?: Maybe<string>;
                contentBackgroundPosition?: Maybe<string>;
                contentBackgroundColorLayerTwo?: Maybe<string>;
                subtitleColor?: Maybe<string>;
                textAlignment?: Maybe<string>;
                textColor?: Maybe<string>;
                titleColor?: Maybe<string>;
                showHide?: Maybe<boolean>;
                hideAnimations?: Maybe<string>;
                animationDirection?: Maybe<string>;
                hideParallax?: Maybe<string>;
                sliderAnimations?: Maybe<string>;
                zoomOnHover?: Maybe<string>;
                containerMaxWidth?: Maybe<string>;
                headingsNoTranslate?: Maybe<boolean>;
                componentBackgroundImage?: Maybe<{
                  __typename?: 'MediaItem';
                  sourceUrl?: Maybe<string>;
                }>;
                contentBackgroundImage?: Maybe<{
                  __typename?: 'MediaItem';
                  sourceUrl?: Maybe<string>;
                }>;
                paddingTop?: Maybe<{
                  __typename?: 'Page_Acfflexible_FlexibleComponents_HtmlMenu_HtmlMenuSettings_PaddingTop';
                  paddingTopDesktop?: Maybe<string>;
                  paddingTopTablet?: Maybe<string>;
                  paddingTopMobile?: Maybe<string>;
                }>;
                paddingBottom?: Maybe<{
                  __typename?: 'Page_Acfflexible_FlexibleComponents_HtmlMenu_HtmlMenuSettings_PaddingBottom';
                  paddingBottomDesktop?: Maybe<string>;
                  paddingBottomTablet?: Maybe<string>;
                  paddingBottomMobile?: Maybe<string>;
                }>;
              }>;
            }
          | {
              __typename: 'Page_Acfflexible_FlexibleComponents_IconBlock';
              fieldGroupName?: Maybe<string>;
              header?: Maybe<string>;
              headerCopy?: Maybe<string>;
              iconColumnCount?: Maybe<string>;
              iconSize?: Maybe<string>;
              iconRepeater?: Maybe<
                Array<
                  Maybe<{
                    __typename?: 'Page_Acfflexible_FlexibleComponents_IconBlock_iconRepeater';
                    fieldGroupName?: Maybe<string>;
                    iconList?: Maybe<string>;
                    iconLabel?: Maybe<string>;
                    iconColor?: Maybe<string>;
                    labelColor?: Maybe<string>;
                  }>
                >
              >;
              iconBlockComponentSettings?: Maybe<{
                __typename?: 'Page_Acfflexible_FlexibleComponents_IconBlock_IconBlockComponentSettings';
                fieldGroupName?: Maybe<string>;
                showHide?: Maybe<boolean>;
                componentBackgroundColor?: Maybe<string>;
                componentBackgroundSize?: Maybe<string>;
                componentBackgroundRepeat?: Maybe<string>;
                componentBackgroundPosition?: Maybe<string>;
                contentBackgroundColor?: Maybe<string>;
                contentBackgroundSize?: Maybe<string>;
                contentBackgroundRepeat?: Maybe<string>;
                contentBackgroundPosition?: Maybe<string>;
                contentBackgroundColorLayerTwo?: Maybe<string>;
                subtitleColor?: Maybe<string>;
                textAlignment?: Maybe<string>;
                textColor?: Maybe<string>;
                titleColor?: Maybe<string>;
                hideAnimations?: Maybe<string>;
                animationDirection?: Maybe<string>;
                hideParallax?: Maybe<string>;
                sliderAnimations?: Maybe<string>;
                zoomOnHover?: Maybe<string>;
                containerMaxWidth?: Maybe<string>;
                headingsNoTranslate?: Maybe<boolean>;
                componentBackgroundImage?: Maybe<{
                  __typename?: 'MediaItem';
                  sourceUrl?: Maybe<string>;
                }>;
                contentBackgroundImage?: Maybe<{
                  __typename?: 'MediaItem';
                  sourceUrl?: Maybe<string>;
                }>;
                paddingTop?: Maybe<{
                  __typename?: 'Page_Acfflexible_FlexibleComponents_IconBlock_IconBlockComponentSettings_PaddingTop';
                  paddingTopDesktop?: Maybe<string>;
                  paddingTopTablet?: Maybe<string>;
                  paddingTopMobile?: Maybe<string>;
                }>;
                paddingBottom?: Maybe<{
                  __typename?: 'Page_Acfflexible_FlexibleComponents_IconBlock_IconBlockComponentSettings_PaddingBottom';
                  paddingBottomDesktop?: Maybe<string>;
                  paddingBottomTablet?: Maybe<string>;
                  paddingBottomMobile?: Maybe<string>;
                }>;
              }>;
            }
          | {
              __typename: 'Page_Acfflexible_FlexibleComponents_ImageGallery';
              fieldGroupName?: Maybe<string>;
              imageGallery?: Maybe<
                Array<
                  Maybe<{
                    __typename?: 'Page_Acfflexible_FlexibleComponents_ImageGallery_imageGallery';
                    galleryTitle?: Maybe<string>;
                    galleryThumbnail?: Maybe<{
                      __typename?: 'MediaItem';
                      altText?: Maybe<string>;
                      sourceUrl?: Maybe<string>;
                    }>;
                    galleryImages?: Maybe<
                      Array<
                        Maybe<{
                          __typename?: 'MediaItem';
                          altText?: Maybe<string>;
                          caption?: Maybe<string>;
                          sourceUrl?: Maybe<string>;
                          mediaDetails?: Maybe<{
                            __typename?: 'MediaDetails';
                            height?: Maybe<number>;
                            width?: Maybe<number>;
                          }>;
                        }>
                      >
                    >;
                  }>
                >
              >;
              imageGalleryComponentSettings?: Maybe<{
                __typename?: 'Page_Acfflexible_FlexibleComponents_ImageGallery_ImageGalleryComponentSettings';
                fieldGroupName?: Maybe<string>;
                showHide?: Maybe<boolean>;
                componentBackgroundColor?: Maybe<string>;
                componentBackgroundSize?: Maybe<string>;
                componentBackgroundRepeat?: Maybe<string>;
                componentBackgroundPosition?: Maybe<string>;
                contentBackgroundColor?: Maybe<string>;
                contentBackgroundSize?: Maybe<string>;
                contentBackgroundRepeat?: Maybe<string>;
                contentBackgroundPosition?: Maybe<string>;
                contentBackgroundColorLayerTwo?: Maybe<string>;
                subtitleColor?: Maybe<string>;
                textAlignment?: Maybe<string>;
                textColor?: Maybe<string>;
                titleColor?: Maybe<string>;
                hideAnimations?: Maybe<string>;
                animationDirection?: Maybe<string>;
                hideParallax?: Maybe<string>;
                sliderAnimations?: Maybe<string>;
                zoomOnHover?: Maybe<string>;
                containerMaxWidth?: Maybe<string>;
                headingsNoTranslate?: Maybe<boolean>;
                componentBackgroundImage?: Maybe<{
                  __typename?: 'MediaItem';
                  sourceUrl?: Maybe<string>;
                }>;
                contentBackgroundImage?: Maybe<{
                  __typename?: 'MediaItem';
                  sourceUrl?: Maybe<string>;
                }>;
                paddingTop?: Maybe<{
                  __typename?: 'Page_Acfflexible_FlexibleComponents_ImageGallery_ImageGalleryComponentSettings_PaddingTop';
                  paddingTopDesktop?: Maybe<string>;
                  paddingTopTablet?: Maybe<string>;
                  paddingTopMobile?: Maybe<string>;
                }>;
                paddingBottom?: Maybe<{
                  __typename?: 'Page_Acfflexible_FlexibleComponents_ImageGallery_ImageGalleryComponentSettings_PaddingBottom';
                  paddingBottomDesktop?: Maybe<string>;
                  paddingBottomTablet?: Maybe<string>;
                  paddingBottomMobile?: Maybe<string>;
                }>;
              }>;
            }
          | {
              __typename: 'Page_Acfflexible_FlexibleComponents_ImageGalleryWall';
              fieldGroupName?: Maybe<string>;
              gallerySubtitle?: Maybe<string>;
              columnCount?: Maybe<number>;
              imageVerticalAlignment?: Maybe<string>;
              galleryTitle?: Maybe<string>;
              repeater?: Maybe<
                Array<
                  Maybe<{
                    __typename?: 'Page_Acfflexible_FlexibleComponents_ImageGalleryWall_repeater';
                    title?: Maybe<string>;
                    selectOrientation?: Maybe<string>;
                    faceDetection?: Maybe<boolean>;
                    portraitModal?: Maybe<boolean>;
                    imageHeight?: Maybe<string>;
                    fieldGroupName?: Maybe<string>;
                    description?: Maybe<string>;
                    image?: Maybe<{
                      __typename?: 'MediaItem';
                      sourceUrl?: Maybe<string>;
                      altText?: Maybe<string>;
                      mediaDetails?: Maybe<{
                        __typename?: 'MediaDetails';
                        height?: Maybe<number>;
                        width?: Maybe<number>;
                      }>;
                    }>;
                  }>
                >
              >;
              galleryWallComponentSettings?: Maybe<{
                __typename?: 'Page_Acfflexible_FlexibleComponents_ImageGalleryWall_GalleryWallComponentSettings';
                fieldGroupName?: Maybe<string>;
                showHide?: Maybe<boolean>;
                componentBackgroundColor?: Maybe<string>;
                componentBackgroundSize?: Maybe<string>;
                componentBackgroundRepeat?: Maybe<string>;
                componentBackgroundPosition?: Maybe<string>;
                contentBackgroundColor?: Maybe<string>;
                contentBackgroundSize?: Maybe<string>;
                contentBackgroundRepeat?: Maybe<string>;
                contentBackgroundPosition?: Maybe<string>;
                contentBackgroundColorLayerTwo?: Maybe<string>;
                subtitleColor?: Maybe<string>;
                textAlignment?: Maybe<string>;
                textColor?: Maybe<string>;
                titleColor?: Maybe<string>;
                hideAnimations?: Maybe<string>;
                animationDirection?: Maybe<string>;
                hideParallax?: Maybe<string>;
                sliderAnimations?: Maybe<string>;
                zoomOnHover?: Maybe<string>;
                containerMaxWidth?: Maybe<string>;
                headingsNoTranslate?: Maybe<boolean>;
                componentBackgroundImage?: Maybe<{
                  __typename?: 'MediaItem';
                  sourceUrl?: Maybe<string>;
                }>;
                contentBackgroundImage?: Maybe<{
                  __typename?: 'MediaItem';
                  sourceUrl?: Maybe<string>;
                }>;
                paddingTop?: Maybe<{
                  __typename?: 'Page_Acfflexible_FlexibleComponents_ImageGalleryWall_GalleryWallComponentSettings_PaddingTop';
                  paddingTopDesktop?: Maybe<string>;
                  paddingTopTablet?: Maybe<string>;
                  paddingTopMobile?: Maybe<string>;
                }>;
                paddingBottom?: Maybe<{
                  __typename?: 'Page_Acfflexible_FlexibleComponents_ImageGalleryWall_GalleryWallComponentSettings_PaddingBottom';
                  paddingBottomDesktop?: Maybe<string>;
                  paddingBottomTablet?: Maybe<string>;
                  paddingBottomMobile?: Maybe<string>;
                }>;
              }>;
            }
          | {
              __typename: 'Page_Acfflexible_FlexibleComponents_ImageReveal';
              fieldGroupName?: Maybe<string>;
              header?: Maybe<string>;
              columnCount?: Maybe<number>;
              repeater?: Maybe<
                Array<
                  Maybe<{
                    __typename?: 'Page_Acfflexible_FlexibleComponents_ImageReveal_repeater';
                    copy?: Maybe<string>;
                    fieldGroupName?: Maybe<string>;
                    title?: Maybe<string>;
                    showHide?: Maybe<boolean>;
                    title_noTx?: Maybe<string>;
                    image?: Maybe<{
                      __typename?: 'MediaItem';
                      altText?: Maybe<string>;
                      sourceUrl?: Maybe<string>;
                    }>;
                    button?: Maybe<{
                      __typename?: 'AcfLink';
                      target?: Maybe<string>;
                      title?: Maybe<string>;
                      url?: Maybe<string>;
                    }>;
                  }>
                >
              >;
              imageRevealComponentSettings?: Maybe<{
                __typename?: 'Page_Acfflexible_FlexibleComponents_ImageReveal_ImageRevealComponentSettings';
                fieldGroupName?: Maybe<string>;
                showHide?: Maybe<boolean>;
                componentBackgroundColor?: Maybe<string>;
                componentBackgroundSize?: Maybe<string>;
                componentBackgroundRepeat?: Maybe<string>;
                componentBackgroundPosition?: Maybe<string>;
                contentBackgroundColor?: Maybe<string>;
                contentBackgroundSize?: Maybe<string>;
                contentBackgroundRepeat?: Maybe<string>;
                contentBackgroundPosition?: Maybe<string>;
                contentBackgroundColorLayerTwo?: Maybe<string>;
                subtitleColor?: Maybe<string>;
                textAlignment?: Maybe<string>;
                textColor?: Maybe<string>;
                titleColor?: Maybe<string>;
                hideAnimations?: Maybe<string>;
                animationDirection?: Maybe<string>;
                hideParallax?: Maybe<string>;
                sliderAnimations?: Maybe<string>;
                zoomOnHover?: Maybe<string>;
                containerMaxWidth?: Maybe<string>;
                headingsNoTranslate?: Maybe<boolean>;
                componentBackgroundImage?: Maybe<{
                  __typename?: 'MediaItem';
                  sourceUrl?: Maybe<string>;
                }>;
                contentBackgroundImage?: Maybe<{
                  __typename?: 'MediaItem';
                  sourceUrl?: Maybe<string>;
                }>;
                paddingTop?: Maybe<{
                  __typename?: 'Page_Acfflexible_FlexibleComponents_ImageReveal_ImageRevealComponentSettings_PaddingTop';
                  paddingTopDesktop?: Maybe<string>;
                  paddingTopTablet?: Maybe<string>;
                  paddingTopMobile?: Maybe<string>;
                }>;
                paddingBottom?: Maybe<{
                  __typename?: 'Page_Acfflexible_FlexibleComponents_ImageReveal_ImageRevealComponentSettings_PaddingBottom';
                  paddingBottomDesktop?: Maybe<string>;
                  paddingBottomTablet?: Maybe<string>;
                  paddingBottomMobile?: Maybe<string>;
                }>;
              }>;
            }
          | {
              __typename: 'Page_Acfflexible_FlexibleComponents_InformationBar';
              fieldGroupName?: Maybe<string>;
              emailAddress?: Maybe<string>;
              hideIcons?: Maybe<boolean>;
              phoneNumber?: Maybe<string>;
              bookCta?: Maybe<{
                __typename?: 'Page_Acfflexible_FlexibleComponents_InformationBar_BookCta';
                fieldGroupName?: Maybe<string>;
                buttonStyle?: Maybe<string>;
                link?: Maybe<{
                  __typename?: 'AcfLink';
                  target?: Maybe<string>;
                  title?: Maybe<string>;
                  url?: Maybe<string>;
                }>;
              }>;
              details?: Maybe<{
                __typename?: 'Page_Acfflexible_FlexibleComponents_InformationBar_Details';
                fieldGroupName?: Maybe<string>;
                copy?: Maybe<string>;
                buttons?: Maybe<
                  Array<
                    Maybe<{
                      __typename?: 'Page_Acfflexible_FlexibleComponents_InformationBar_Details_buttons';
                      buttonStyle?: Maybe<string>;
                      link?: Maybe<{
                        __typename?: 'AcfLink';
                        target?: Maybe<string>;
                        title?: Maybe<string>;
                        url?: Maybe<string>;
                      }>;
                    }>
                  >
                >;
              }>;
              location?: Maybe<{
                __typename?: 'Page_Acfflexible_FlexibleComponents_InformationBar_Location';
                fieldGroupName?: Maybe<string>;
                copy?: Maybe<string>;
                buttons?: Maybe<
                  Array<
                    Maybe<{
                      __typename?: 'Page_Acfflexible_FlexibleComponents_InformationBar_Location_buttons';
                      buttonStyle?: Maybe<string>;
                      link?: Maybe<{
                        __typename?: 'AcfLink';
                        target?: Maybe<string>;
                        title?: Maybe<string>;
                        url?: Maybe<string>;
                      }>;
                    }>
                  >
                >;
              }>;
              hours?: Maybe<{
                __typename?: 'Page_Acfflexible_FlexibleComponents_InformationBar_Hours';
                fieldGroupName?: Maybe<string>;
                copy?: Maybe<string>;
                buttons?: Maybe<
                  Array<
                    Maybe<{
                      __typename?: 'Page_Acfflexible_FlexibleComponents_InformationBar_Hours_buttons';
                      buttonStyle?: Maybe<string>;
                      link?: Maybe<{
                        __typename?: 'AcfLink';
                        target?: Maybe<string>;
                        title?: Maybe<string>;
                        url?: Maybe<string>;
                      }>;
                    }>
                  >
                >;
              }>;
              informationBarComponentSettings?: Maybe<{
                __typename?: 'Page_Acfflexible_FlexibleComponents_InformationBar_InformationBarComponentSettings';
                fieldGroupName?: Maybe<string>;
                showHide?: Maybe<boolean>;
                componentBackgroundColor?: Maybe<string>;
                componentBackgroundSize?: Maybe<string>;
                componentBackgroundRepeat?: Maybe<string>;
                componentBackgroundPosition?: Maybe<string>;
                contentBackgroundColor?: Maybe<string>;
                contentBackgroundSize?: Maybe<string>;
                contentBackgroundRepeat?: Maybe<string>;
                contentBackgroundPosition?: Maybe<string>;
                contentBackgroundColorLayerTwo?: Maybe<string>;
                subtitleColor?: Maybe<string>;
                textAlignment?: Maybe<string>;
                textColor?: Maybe<string>;
                titleColor?: Maybe<string>;
                hideAnimations?: Maybe<string>;
                animationDirection?: Maybe<string>;
                hideParallax?: Maybe<string>;
                sliderAnimations?: Maybe<string>;
                zoomOnHover?: Maybe<string>;
                containerMaxWidth?: Maybe<string>;
                headingsNoTranslate?: Maybe<boolean>;
                componentBackgroundImage?: Maybe<{
                  __typename?: 'MediaItem';
                  sourceUrl?: Maybe<string>;
                }>;
                contentBackgroundImage?: Maybe<{
                  __typename?: 'MediaItem';
                  sourceUrl?: Maybe<string>;
                }>;
                paddingTop?: Maybe<{
                  __typename?: 'Page_Acfflexible_FlexibleComponents_InformationBar_InformationBarComponentSettings_PaddingTop';
                  paddingTopDesktop?: Maybe<string>;
                  paddingTopTablet?: Maybe<string>;
                  paddingTopMobile?: Maybe<string>;
                }>;
                paddingBottom?: Maybe<{
                  __typename?: 'Page_Acfflexible_FlexibleComponents_InformationBar_InformationBarComponentSettings_PaddingBottom';
                  paddingBottomDesktop?: Maybe<string>;
                  paddingBottomTablet?: Maybe<string>;
                  paddingBottomMobile?: Maybe<string>;
                }>;
              }>;
            }
          | {
              __typename: 'Page_Acfflexible_FlexibleComponents_Introduction';
              copy?: Maybe<string>;
              fieldGroupName?: Maybe<string>;
              subtitle?: Maybe<string>;
              title?: Maybe<string>;
              combinedTitleSubtitle?: Maybe<string>;
              titleSize?: Maybe<string>;
              subtitleSize?: Maybe<string>;
              pullFromCorePlus?: Maybe<boolean>;
              pullQuote?: Maybe<boolean>;
              imageSize?: Maybe<string>;
              subtitle_noTx?: Maybe<string>;
              title_noTx?: Maybe<string>;
              image?: Maybe<{
                __typename?: 'MediaItem';
                sourceUrl?: Maybe<string>;
                altText?: Maybe<string>;
              }>;
              buttons?: Maybe<
                Array<
                  Maybe<{
                    __typename?: 'Page_Acfflexible_FlexibleComponents_Introduction_buttons';
                    buttonStyle?: Maybe<string>;
                    fieldGroupName?: Maybe<string>;
                    link?: Maybe<{
                      __typename?: 'AcfLink';
                      target?: Maybe<string>;
                      title?: Maybe<string>;
                      url?: Maybe<string>;
                    }>;
                  }>
                >
              >;
              introductionComponentSettings?: Maybe<{
                __typename?: 'Page_Acfflexible_FlexibleComponents_Introduction_IntroductionComponentSettings';
                fieldGroupName?: Maybe<string>;
                showHide?: Maybe<boolean>;
                componentBackgroundColor?: Maybe<string>;
                componentBackgroundSize?: Maybe<string>;
                componentBackgroundRepeat?: Maybe<string>;
                componentBackgroundPosition?: Maybe<string>;
                contentBackgroundColor?: Maybe<string>;
                contentBackgroundSize?: Maybe<string>;
                contentBackgroundRepeat?: Maybe<string>;
                contentBackgroundPosition?: Maybe<string>;
                contentBackgroundColorLayerTwo?: Maybe<string>;
                subtitleColor?: Maybe<string>;
                textAlignment?: Maybe<string>;
                textColor?: Maybe<string>;
                titleColor?: Maybe<string>;
                hideAnimations?: Maybe<string>;
                animationDirection?: Maybe<string>;
                hideParallax?: Maybe<string>;
                sliderAnimations?: Maybe<string>;
                zoomOnHover?: Maybe<string>;
                containerMaxWidth?: Maybe<string>;
                headingsNoTranslate?: Maybe<boolean>;
                componentBackgroundImage?: Maybe<{
                  __typename?: 'MediaItem';
                  sourceUrl?: Maybe<string>;
                }>;
                contentBackgroundImage?: Maybe<{
                  __typename?: 'MediaItem';
                  sourceUrl?: Maybe<string>;
                }>;
                paddingTop?: Maybe<{
                  __typename?: 'Page_Acfflexible_FlexibleComponents_Introduction_IntroductionComponentSettings_PaddingTop';
                  paddingTopDesktop?: Maybe<string>;
                  paddingTopTablet?: Maybe<string>;
                  paddingTopMobile?: Maybe<string>;
                }>;
                paddingBottom?: Maybe<{
                  __typename?: 'Page_Acfflexible_FlexibleComponents_Introduction_IntroductionComponentSettings_PaddingBottom';
                  paddingBottomDesktop?: Maybe<string>;
                  paddingBottomTablet?: Maybe<string>;
                  paddingBottomMobile?: Maybe<string>;
                }>;
              }>;
            }
          | {
              __typename: 'Page_Acfflexible_FlexibleComponents_KeyInfoGrid';
              gridContent?: Maybe<string>;
              gridSubtitle?: Maybe<string>;
              gridTitle?: Maybe<string>;
              columnTitles?: Maybe<{
                __typename?: 'Page_Acfflexible_FlexibleComponents_KeyInfoGrid_ColumnTitles';
                title1?: Maybe<string>;
                title2?: Maybe<string>;
                title3?: Maybe<string>;
              }>;
              repeater?: Maybe<
                Array<
                  Maybe<{
                    __typename?: 'Page_Acfflexible_FlexibleComponents_KeyInfoGrid_repeater';
                    title?: Maybe<string>;
                    contentBackground?: Maybe<string>;
                    content?: Maybe<string>;
                    backgroundOpacity?: Maybe<number>;
                    columnSelect?: Maybe<string>;
                    iconList?: Maybe<string>;
                  }>
                >
              >;
              keyInfoGridSettings?: Maybe<{
                __typename?: 'Page_Acfflexible_FlexibleComponents_KeyInfoGrid_KeyInfoGridSettings';
                fieldGroupName?: Maybe<string>;
                componentBackgroundColor?: Maybe<string>;
                componentBackgroundSize?: Maybe<string>;
                componentBackgroundRepeat?: Maybe<string>;
                componentBackgroundPosition?: Maybe<string>;
                contentBackgroundColor?: Maybe<string>;
                contentBackgroundSize?: Maybe<string>;
                contentBackgroundRepeat?: Maybe<string>;
                contentBackgroundPosition?: Maybe<string>;
                contentBackgroundColorLayerTwo?: Maybe<string>;
                subtitleColor?: Maybe<string>;
                textAlignment?: Maybe<string>;
                textColor?: Maybe<string>;
                titleColor?: Maybe<string>;
                showHide?: Maybe<boolean>;
                hideAnimations?: Maybe<string>;
                animationDirection?: Maybe<string>;
                hideParallax?: Maybe<string>;
                componentBackgroundImage?: Maybe<{
                  __typename?: 'MediaItem';
                  sourceUrl?: Maybe<string>;
                }>;
                contentBackgroundImage?: Maybe<{
                  __typename?: 'MediaItem';
                  sourceUrl?: Maybe<string>;
                }>;
                paddingTop?: Maybe<{
                  __typename?: 'Page_Acfflexible_FlexibleComponents_KeyInfoGrid_KeyInfoGridSettings_PaddingTop';
                  paddingTopDesktop?: Maybe<string>;
                  paddingTopTablet?: Maybe<string>;
                  paddingTopMobile?: Maybe<string>;
                }>;
                paddingBottom?: Maybe<{
                  __typename?: 'Page_Acfflexible_FlexibleComponents_KeyInfoGrid_KeyInfoGridSettings_PaddingBottom';
                  paddingBottomDesktop?: Maybe<string>;
                  paddingBottomTablet?: Maybe<string>;
                  paddingBottomMobile?: Maybe<string>;
                }>;
              }>;
            }
          | {
              __typename: 'Page_Acfflexible_FlexibleComponents_LocationGoogleMap';
              fieldGroupName?: Maybe<string>;
              title?: Maybe<string>;
              address?: Maybe<string>;
              addressUrl?: Maybe<string>;
              addressOverride?: Maybe<boolean>;
              phone?: Maybe<string>;
              email?: Maybe<string>;
              hideEmailCta?: Maybe<boolean>;
              emailCtaText?: Maybe<string>;
              zoomControl?: Maybe<number>;
              title_noTx?: Maybe<string>;
              emailCtaOverride?: Maybe<{
                __typename?: 'AcfLink';
                target?: Maybe<string>;
                title?: Maybe<string>;
                url?: Maybe<string>;
              }>;
              map?: Maybe<{
                __typename?: 'ACF_GoogleMap';
                latitude?: Maybe<number>;
                longitude?: Maybe<number>;
                zoom?: Maybe<string>;
              }>;
              locationGoogleMapComponentSettings?: Maybe<{
                __typename?: 'Page_Acfflexible_FlexibleComponents_LocationGoogleMap_LocationGoogleMapComponentSettings';
                fieldGroupName?: Maybe<string>;
                showHide?: Maybe<boolean>;
                componentBackgroundColor?: Maybe<string>;
                componentBackgroundSize?: Maybe<string>;
                componentBackgroundRepeat?: Maybe<string>;
                componentBackgroundPosition?: Maybe<string>;
                contentBackgroundColor?: Maybe<string>;
                contentBackgroundSize?: Maybe<string>;
                contentBackgroundRepeat?: Maybe<string>;
                contentBackgroundPosition?: Maybe<string>;
                contentBackgroundColorLayerTwo?: Maybe<string>;
                subtitleColor?: Maybe<string>;
                textAlignment?: Maybe<string>;
                textColor?: Maybe<string>;
                titleColor?: Maybe<string>;
                hideAnimations?: Maybe<string>;
                animationDirection?: Maybe<string>;
                hideParallax?: Maybe<string>;
                sliderAnimations?: Maybe<string>;
                zoomOnHover?: Maybe<string>;
                containerMaxWidth?: Maybe<string>;
                headingsNoTranslate?: Maybe<boolean>;
                componentBackgroundImage?: Maybe<{
                  __typename?: 'MediaItem';
                  sourceUrl?: Maybe<string>;
                }>;
                contentBackgroundImage?: Maybe<{
                  __typename?: 'MediaItem';
                  sourceUrl?: Maybe<string>;
                }>;
                paddingTop?: Maybe<{
                  __typename?: 'Page_Acfflexible_FlexibleComponents_LocationGoogleMap_LocationGoogleMapComponentSettings_PaddingTop';
                  paddingTopDesktop?: Maybe<string>;
                  paddingTopTablet?: Maybe<string>;
                  paddingTopMobile?: Maybe<string>;
                }>;
                paddingBottom?: Maybe<{
                  __typename?: 'Page_Acfflexible_FlexibleComponents_LocationGoogleMap_LocationGoogleMapComponentSettings_PaddingBottom';
                  paddingBottomDesktop?: Maybe<string>;
                  paddingBottomTablet?: Maybe<string>;
                  paddingBottomMobile?: Maybe<string>;
                }>;
              }>;
            }
          | {
              __typename: 'Page_Acfflexible_FlexibleComponents_Masthead';
              fieldGroupName?: Maybe<string>;
              subtitle?: Maybe<string>;
              subtitleSize?: Maybe<string>;
              title?: Maybe<string>;
              titleSize?: Maybe<string>;
              imagePositioning?: Maybe<string>;
              imageCarouselChoice?: Maybe<boolean>;
              logoOverlayColour?: Maybe<string>;
              logoOverlayOpacity?: Maybe<number>;
              logoWidth?: Maybe<number>;
              logoHeight?: Maybe<number>;
              videoChoice?: Maybe<boolean>;
              videoCtaPlay?: Maybe<string>;
              videoCtaPause?: Maybe<string>;
              videoMute?: Maybe<boolean>;
              videoQualityOverride?: Maybe<boolean>;
              videoQualitySlider?: Maybe<number>;
              bookingWidgetChoice?: Maybe<boolean>;
              bookingWidgetPosition?: Maybe<string>;
              bookingWidgetMobile?: Maybe<boolean>;
              mobileCroppingAutoPosition?: Maybe<boolean>;
              mobileCroppingCropHeight?: Maybe<string>;
              mobileCroppingCropWidth?: Maybe<string>;
              mobileCroppingXPosition?: Maybe<string>;
              mobileCroppingXPositionAdvanced?: Maybe<string>;
              mobileCroppingYPosition?: Maybe<string>;
              mobileCroppingYPositionAdvanced?: Maybe<string>;
              image?: Maybe<{
                __typename?: 'MediaItem';
                altText?: Maybe<string>;
                sourceUrl?: Maybe<string>;
              }>;
              imageCarousel?: Maybe<
                Array<
                  Maybe<{
                    __typename?: 'MediaItem';
                    altText?: Maybe<string>;
                    sourceUrl?: Maybe<string>;
                  }>
                >
              >;
              buttons?: Maybe<
                Array<
                  Maybe<{
                    __typename?: 'Page_Acfflexible_FlexibleComponents_Masthead_buttons';
                    buttonStyle?: Maybe<string>;
                    fieldGroupName?: Maybe<string>;
                    link?: Maybe<{
                      __typename?: 'AcfLink';
                      target?: Maybe<string>;
                      title?: Maybe<string>;
                      url?: Maybe<string>;
                    }>;
                  }>
                >
              >;
              logoImage?: Maybe<{
                __typename?: 'MediaItem';
                altText?: Maybe<string>;
                sourceUrl?: Maybe<string>;
                mediaDetails?: Maybe<{
                  __typename?: 'MediaDetails';
                  height?: Maybe<number>;
                  width?: Maybe<number>;
                }>;
              }>;
              videoUrl?: Maybe<{
                __typename?: 'MediaItem';
                mediaItemUrl?: Maybe<string>;
              }>;
              mastheadComponentSettings?: Maybe<{
                __typename?: 'Page_Acfflexible_FlexibleComponents_Masthead_MastheadComponentSettings';
                fieldGroupName?: Maybe<string>;
                mastheadHeight?: Maybe<string>;
                overlayBackgroundColour?: Maybe<string>;
                overlayMobileBackgroundColour?: Maybe<string>;
                mobileTitleColour?: Maybe<string>;
                mobileSubtitleColour?: Maybe<string>;
                overlayBackgroundOpacity?: Maybe<number>;
                overlayBlur?: Maybe<number>;
                overlayFullWidth?: Maybe<string>;
                overlayXPosition?: Maybe<string>;
                overlayYPosition?: Maybe<string>;
                mastheadGradient?: Maybe<string>;
                overlayMobilePosition?: Maybe<string>;
                titleColor?: Maybe<string>;
                subtitleColor?: Maybe<string>;
                videoCtaBackgroundColor?: Maybe<string>;
                videoCtaBorderColor?: Maybe<string>;
                videoCtaMuteColor?: Maybe<string>;
                videoCtaPlaypauseColor?: Maybe<string>;
                videoCtaUnmuteColor?: Maybe<string>;
                videoMastheadCtaPosition?: Maybe<string>;
                videoCtaShadow?: Maybe<string>;
                contentBackgroundPosition?: Maybe<string>;
                logoXPosition?: Maybe<string>;
                logoYPosition?: Maybe<string>;
                heroCarouselArrowColour?: Maybe<string>;
                heroCarouselBackgroundColour?: Maybe<string>;
                heroCarouselTextColour?: Maybe<string>;
                heroCarouselCounterColour?: Maybe<string>;
                heroCarouselDividerColour?: Maybe<string>;
                heroCarouselRoundedCorners?: Maybe<string>;
                showHide?: Maybe<boolean>;
                hideAnimations?: Maybe<string>;
                animationDirection?: Maybe<string>;
                hideParallax?: Maybe<string>;
                sliderAnimations?: Maybe<string>;
                mastheadSpacer?: Maybe<boolean>;
                mastheadSpacerColour?: Maybe<string>;
              }>;
            }
          | {
              __typename: 'Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay';
              title?: Maybe<string>;
              flipImageHorizontally?: Maybe<boolean>;
              layoutMode?: Maybe<string>;
              copy?: Maybe<string>;
              fieldGroupName?: Maybe<string>;
              title_noTx?: Maybe<string>;
              image?: Maybe<{
                __typename?: 'MediaItem';
                sourceUrl?: Maybe<string>;
                altText?: Maybe<string>;
                mediaDetails?: Maybe<{
                  __typename?: 'MediaDetails';
                  height?: Maybe<number>;
                  width?: Maybe<number>;
                }>;
              }>;
              mediaBlock?: Maybe<{ __typename?: 'Media_block'; id: string }>;
              buttons?: Maybe<
                Array<
                  Maybe<{
                    __typename?: 'Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay_buttons';
                    buttonStyle?: Maybe<string>;
                    fieldGroupName?: Maybe<string>;
                    link?: Maybe<{
                      __typename?: 'AcfLink';
                      target?: Maybe<string>;
                      title?: Maybe<string>;
                      url?: Maybe<string>;
                    }>;
                  }>
                >
              >;
              mediaAndCopyOverlayComponentSettings?: Maybe<{
                __typename?: 'Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay_MediaAndCopyOverlayComponentSettings';
                fieldGroupName?: Maybe<string>;
                componentBackgroundColor?: Maybe<string>;
                componentBackgroundSize?: Maybe<string>;
                componentBackgroundRepeat?: Maybe<string>;
                componentBackgroundPosition?: Maybe<string>;
                contentBackgroundColor?: Maybe<string>;
                contentBackgroundSize?: Maybe<string>;
                contentBackgroundRepeat?: Maybe<string>;
                contentBackgroundPosition?: Maybe<string>;
                subtitleColor?: Maybe<string>;
                textAlignment?: Maybe<string>;
                textColor?: Maybe<string>;
                titleColor?: Maybe<string>;
                mediaAndCopyPadding?: Maybe<string>;
                horizontalDirection?: Maybe<string>;
                zoomOnHover?: Maybe<string>;
                hideAnimations?: Maybe<string>;
                animationDirection?: Maybe<string>;
                hideParallax?: Maybe<string>;
                showHide?: Maybe<boolean>;
                containerMaxWidth?: Maybe<string>;
                componentBackgroundImage?: Maybe<{
                  __typename?: 'MediaItem';
                  sourceUrl?: Maybe<string>;
                }>;
                contentBackgroundImage?: Maybe<{
                  __typename?: 'MediaItem';
                  sourceUrl?: Maybe<string>;
                }>;
                paddingTop?: Maybe<{
                  __typename?: 'Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay_MediaAndCopyOverlayComponentSettings_PaddingTop';
                  paddingTopDesktop?: Maybe<string>;
                  paddingTopTablet?: Maybe<string>;
                  paddingTopMobile?: Maybe<string>;
                }>;
                paddingBottom?: Maybe<{
                  __typename?: 'Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay_MediaAndCopyOverlayComponentSettings_PaddingBottom';
                  paddingBottomDesktop?: Maybe<string>;
                  paddingBottomTablet?: Maybe<string>;
                  paddingBottomMobile?: Maybe<string>;
                }>;
              }>;
            }
          | {
              __typename: 'Page_Acfflexible_FlexibleComponents_MeetingCapacity';
              fieldGroupName?: Maybe<string>;
              heading?: Maybe<string>;
              meetingCapacitySummaryComponentSettings?: Maybe<{
                __typename?: 'Page_Acfflexible_FlexibleComponents_MeetingCapacity_MeetingCapacitySummaryComponentSettings';
                fieldGroupName?: Maybe<string>;
                showHide?: Maybe<boolean>;
                componentBackgroundColor?: Maybe<string>;
                componentBackgroundSize?: Maybe<string>;
                componentBackgroundRepeat?: Maybe<string>;
                componentBackgroundPosition?: Maybe<string>;
                contentBackgroundColor?: Maybe<string>;
                contentBackgroundSize?: Maybe<string>;
                contentBackgroundRepeat?: Maybe<string>;
                contentBackgroundPosition?: Maybe<string>;
                contentBackgroundColorLayerTwo?: Maybe<string>;
                subtitleColor?: Maybe<string>;
                textAlignment?: Maybe<string>;
                textColor?: Maybe<string>;
                titleColor?: Maybe<string>;
                hideAnimations?: Maybe<string>;
                animationDirection?: Maybe<string>;
                hideParallax?: Maybe<string>;
                sliderAnimations?: Maybe<string>;
                zoomOnHover?: Maybe<string>;
                containerMaxWidth?: Maybe<string>;
                headingsNoTranslate?: Maybe<boolean>;
                componentBackgroundImage?: Maybe<{
                  __typename?: 'MediaItem';
                  sourceUrl?: Maybe<string>;
                }>;
                contentBackgroundImage?: Maybe<{
                  __typename?: 'MediaItem';
                  sourceUrl?: Maybe<string>;
                }>;
                paddingTop?: Maybe<{
                  __typename?: 'Page_Acfflexible_FlexibleComponents_MeetingCapacity_MeetingCapacitySummaryComponentSettings_PaddingTop';
                  paddingTopDesktop?: Maybe<string>;
                  paddingTopTablet?: Maybe<string>;
                  paddingTopMobile?: Maybe<string>;
                }>;
                paddingBottom?: Maybe<{
                  __typename?: 'Page_Acfflexible_FlexibleComponents_MeetingCapacity_MeetingCapacitySummaryComponentSettings_PaddingBottom';
                  paddingBottomDesktop?: Maybe<string>;
                  paddingBottomTablet?: Maybe<string>;
                  paddingBottomMobile?: Maybe<string>;
                }>;
              }>;
            }
          | {
              __typename: 'Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts';
              fieldGroupName?: Maybe<string>;
              title?: Maybe<string>;
              subtitle?: Maybe<string>;
              copy?: Maybe<string>;
              meetingCapacityChartsSettings?: Maybe<{
                __typename?: 'Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts_MeetingCapacityChartsSettings';
                fieldGroupName?: Maybe<string>;
                showHide?: Maybe<boolean>;
                componentBackgroundColor?: Maybe<string>;
                componentBackgroundSize?: Maybe<string>;
                componentBackgroundRepeat?: Maybe<string>;
                componentBackgroundPosition?: Maybe<string>;
                contentBackgroundColor?: Maybe<string>;
                contentBackgroundSize?: Maybe<string>;
                contentBackgroundRepeat?: Maybe<string>;
                contentBackgroundPosition?: Maybe<string>;
                contentBackgroundColorLayerTwo?: Maybe<string>;
                subtitleColor?: Maybe<string>;
                textAlignment?: Maybe<string>;
                textColor?: Maybe<string>;
                titleColor?: Maybe<string>;
                hideAnimations?: Maybe<string>;
                animationDirection?: Maybe<string>;
                hideParallax?: Maybe<string>;
                sliderAnimations?: Maybe<string>;
                zoomOnHover?: Maybe<string>;
                containerMaxWidth?: Maybe<string>;
                headingsNoTranslate?: Maybe<boolean>;
                tableHeadings?: Maybe<{
                  __typename?: 'Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts_MeetingCapacityChartsSettings_TableHeadings';
                  backgroundColour?: Maybe<string>;
                  fieldGroupName?: Maybe<string>;
                  textColour?: Maybe<string>;
                }>;
                tableBody?: Maybe<{
                  __typename?: 'Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts_MeetingCapacityChartsSettings_TableBody';
                  rowTextColour2?: Maybe<string>;
                  rowTextColour?: Maybe<string>;
                  rowBackgroundColour?: Maybe<string>;
                  fieldGroupName?: Maybe<string>;
                  alternatingRowTextColour2?: Maybe<string>;
                  alternatingRowTextColour?: Maybe<string>;
                  alternatingRowBackgroundColour?: Maybe<string>;
                }>;
                unitTypeSwitch?: Maybe<{
                  __typename?: 'Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts_MeetingCapacityChartsSettings_UnitTypeSwitch';
                  toggleSwitchColour?: Maybe<string>;
                  toggleOutlineColour?: Maybe<string>;
                  toggleBackgroundColour?: Maybe<string>;
                  textColourOfUnitTypeLabel?: Maybe<string>;
                  textColourOfMeasurementType?: Maybe<string>;
                  fieldGroupName?: Maybe<string>;
                }>;
                componentBackgroundImage?: Maybe<{
                  __typename?: 'MediaItem';
                  sourceUrl?: Maybe<string>;
                }>;
                contentBackgroundImage?: Maybe<{
                  __typename?: 'MediaItem';
                  sourceUrl?: Maybe<string>;
                }>;
                paddingTop?: Maybe<{
                  __typename?: 'Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts_MeetingCapacityChartsSettings_PaddingTop';
                  paddingTopDesktop?: Maybe<string>;
                  paddingTopTablet?: Maybe<string>;
                  paddingTopMobile?: Maybe<string>;
                }>;
                paddingBottom?: Maybe<{
                  __typename?: 'Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts_MeetingCapacityChartsSettings_PaddingBottom';
                  paddingBottomDesktop?: Maybe<string>;
                  paddingBottomTablet?: Maybe<string>;
                  paddingBottomMobile?: Maybe<string>;
                }>;
              }>;
            }
          | {
              __typename: 'Page_Acfflexible_FlexibleComponents_MeetingsGroup';
              fieldGroupName?: Maybe<string>;
              meetingGroupComponentSettings?: Maybe<{
                __typename?: 'Page_Acfflexible_FlexibleComponents_MeetingsGroup_MeetingGroupComponentSettings';
                fieldGroupName?: Maybe<string>;
                showHide?: Maybe<boolean>;
                componentBackgroundColor?: Maybe<string>;
                componentBackgroundSize?: Maybe<string>;
                componentBackgroundRepeat?: Maybe<string>;
                componentBackgroundPosition?: Maybe<string>;
                contentBackgroundColor?: Maybe<string>;
                contentBackgroundSize?: Maybe<string>;
                contentBackgroundRepeat?: Maybe<string>;
                contentBackgroundPosition?: Maybe<string>;
                contentBackgroundColorLayerTwo?: Maybe<string>;
                subtitleColor?: Maybe<string>;
                textAlignment?: Maybe<string>;
                textColor?: Maybe<string>;
                titleColor?: Maybe<string>;
                hideAnimations?: Maybe<string>;
                animationDirection?: Maybe<string>;
                hideParallax?: Maybe<string>;
                sliderAnimations?: Maybe<string>;
                zoomOnHover?: Maybe<string>;
                containerMaxWidth?: Maybe<string>;
                headingsNoTranslate?: Maybe<boolean>;
                componentBackgroundImage?: Maybe<{
                  __typename?: 'MediaItem';
                  sourceUrl?: Maybe<string>;
                }>;
                contentBackgroundImage?: Maybe<{
                  __typename?: 'MediaItem';
                  sourceUrl?: Maybe<string>;
                }>;
                paddingTop?: Maybe<{
                  __typename?: 'Page_Acfflexible_FlexibleComponents_MeetingsGroup_MeetingGroupComponentSettings_PaddingTop';
                  paddingTopDesktop?: Maybe<string>;
                  paddingTopTablet?: Maybe<string>;
                  paddingTopMobile?: Maybe<string>;
                }>;
                paddingBottom?: Maybe<{
                  __typename?: 'Page_Acfflexible_FlexibleComponents_MeetingsGroup_MeetingGroupComponentSettings_PaddingBottom';
                  paddingBottomDesktop?: Maybe<string>;
                  paddingBottomTablet?: Maybe<string>;
                  paddingBottomMobile?: Maybe<string>;
                }>;
              }>;
            }
          | {
              __typename: 'Page_Acfflexible_FlexibleComponents_MultiColumn';
              fieldGroupName?: Maybe<string>;
              header?: Maybe<string>;
              headerCopy?: Maybe<string>;
              headingValue?: Maybe<string>;
              columnCount?: Maybe<number>;
              imageSize?: Maybe<string>;
              tileFilters?: Maybe<boolean>;
              buttons?: Maybe<
                Array<
                  Maybe<{
                    __typename?: 'Page_Acfflexible_FlexibleComponents_MultiColumn_buttons';
                    buttonStyle?: Maybe<string>;
                    fieldGroupName?: Maybe<string>;
                    link?: Maybe<{
                      __typename?: 'AcfLink';
                      target?: Maybe<string>;
                      title?: Maybe<string>;
                      url?: Maybe<string>;
                    }>;
                  }>
                >
              >;
              filterCategories?: Maybe<
                Array<
                  Maybe<{
                    __typename?: 'Category';
                    slug?: Maybe<string>;
                    name?: Maybe<string>;
                  }>
                >
              >;
              repeater?: Maybe<
                Array<
                  Maybe<{
                    __typename?: 'Page_Acfflexible_FlexibleComponents_MultiColumn_repeater';
                    title?: Maybe<string>;
                    copy?: Maybe<string>;
                    iconLabel?: Maybe<string>;
                    iconList?: Maybe<string>;
                    showHide?: Maybe<boolean>;
                    title_noTx?: Maybe<string>;
                    accordionPanel?: Maybe<
                      Array<
                        Maybe<{
                          __typename?: 'Page_Acfflexible_FlexibleComponents_MultiColumn_repeater_accordionPanel';
                          panelLabel?: Maybe<string>;
                          panelRepeater?: Maybe<
                            Array<
                              Maybe<{
                                __typename?: 'Page_Acfflexible_FlexibleComponents_MultiColumn_repeater_accordionPanel_panelRepeater';
                                fieldGroupName?: Maybe<string>;
                                rowLabel?: Maybe<string>;
                                rowText?: Maybe<string>;
                              }>
                            >
                          >;
                        }>
                      >
                    >;
                    image?: Maybe<{
                      __typename?: 'MediaItem';
                      altText?: Maybe<string>;
                      sourceUrl?: Maybe<string>;
                    }>;
                    images?: Maybe<
                      Array<
                        Maybe<{
                          __typename?: 'MediaItem';
                          sourceUrl?: Maybe<string>;
                          altText?: Maybe<string>;
                        }>
                      >
                    >;
                    category?: Maybe<{
                      __typename?: 'Category';
                      name?: Maybe<string>;
                      slug?: Maybe<string>;
                    }>;
                    tableData?: Maybe<
                      Array<
                        Maybe<{
                          __typename?: 'Page_Acfflexible_FlexibleComponents_MultiColumn_repeater_tableData';
                          title?: Maybe<string>;
                          copy?: Maybe<string>;
                        }>
                      >
                    >;
                    buttons?: Maybe<
                      Array<
                        Maybe<{
                          __typename?: 'Page_Acfflexible_FlexibleComponents_MultiColumn_repeater_buttons';
                          buttonStyle?: Maybe<string>;
                          fieldGroupName?: Maybe<string>;
                          link?: Maybe<{
                            __typename?: 'AcfLink';
                            target?: Maybe<string>;
                            title?: Maybe<string>;
                            url?: Maybe<string>;
                          }>;
                        }>
                      >
                    >;
                  }>
                >
              >;
              multiColumnComponentSettings?: Maybe<{
                __typename?: 'Page_Acfflexible_FlexibleComponents_MultiColumn_MultiColumnComponentSettings';
                fieldGroupName?: Maybe<string>;
                showHide?: Maybe<boolean>;
                accordionBorderColor?: Maybe<string>;
                accordionIconColor?: Maybe<string>;
                accordionIconColorActive?: Maybe<string>;
                accordionPanelBackgroundColor?: Maybe<string>;
                accordionPanelLabelColor?: Maybe<string>;
                accordionRowLabelColor?: Maybe<string>;
                accordionRowTextColor?: Maybe<string>;
                componentBackgroundColor?: Maybe<string>;
                componentBackgroundSize?: Maybe<string>;
                componentBackgroundRepeat?: Maybe<string>;
                componentBackgroundPosition?: Maybe<string>;
                contentBackgroundColor?: Maybe<string>;
                contentBackgroundSize?: Maybe<string>;
                contentBackgroundRepeat?: Maybe<string>;
                contentBackgroundPosition?: Maybe<string>;
                contentBackgroundColorLayerTwo?: Maybe<string>;
                subtitleColor?: Maybe<string>;
                textAlignment?: Maybe<string>;
                textColor?: Maybe<string>;
                titleColor?: Maybe<string>;
                hideAnimations?: Maybe<string>;
                animationDirection?: Maybe<string>;
                hideParallax?: Maybe<string>;
                sliderAnimations?: Maybe<string>;
                zoomOnHover?: Maybe<string>;
                containerMaxWidth?: Maybe<string>;
                headingsNoTranslate?: Maybe<boolean>;
                filterActiveTextColour?: Maybe<string>;
                filterBackgroundColour?: Maybe<string>;
                filterTextColour?: Maybe<string>;
                uiBackgroundColour?: Maybe<string>;
                uiControlsColour?: Maybe<string>;
                headerTitleColour?: Maybe<string>;
                headerCopyColour?: Maybe<string>;
                componentBackgroundImage?: Maybe<{
                  __typename?: 'MediaItem';
                  sourceUrl?: Maybe<string>;
                }>;
                contentBackgroundImage?: Maybe<{
                  __typename?: 'MediaItem';
                  sourceUrl?: Maybe<string>;
                }>;
                paddingTop?: Maybe<{
                  __typename?: 'Page_Acfflexible_FlexibleComponents_MultiColumn_MultiColumnComponentSettings_PaddingTop';
                  paddingTopDesktop?: Maybe<string>;
                  paddingTopTablet?: Maybe<string>;
                  paddingTopMobile?: Maybe<string>;
                }>;
                paddingBottom?: Maybe<{
                  __typename?: 'Page_Acfflexible_FlexibleComponents_MultiColumn_MultiColumnComponentSettings_PaddingBottom';
                  paddingBottomDesktop?: Maybe<string>;
                  paddingBottomTablet?: Maybe<string>;
                  paddingBottomMobile?: Maybe<string>;
                }>;
              }>;
            }
          | {
              __typename: 'Page_Acfflexible_FlexibleComponents_MultiColumnList';
              fieldGroupName?: Maybe<string>;
              title?: Maybe<string>;
              copy?: Maybe<string>;
              column?: Maybe<
                Array<
                  Maybe<{
                    __typename?: 'Page_Acfflexible_FlexibleComponents_MultiColumnList_column';
                    columnHeading?: Maybe<string>;
                    list?: Maybe<
                      Array<
                        Maybe<{
                          __typename?: 'Page_Acfflexible_FlexibleComponents_MultiColumnList_column_list';
                          listItem?: Maybe<string>;
                        }>
                      >
                    >;
                  }>
                >
              >;
              multiColumnListComponentSettings?: Maybe<{
                __typename?: 'Page_Acfflexible_FlexibleComponents_MultiColumnList_MultiColumnListComponentSettings';
                componentBackgroundColor?: Maybe<string>;
                componentBackgroundSize?: Maybe<string>;
                componentBackgroundRepeat?: Maybe<string>;
                componentBackgroundPosition?: Maybe<string>;
                contentBackgroundColor?: Maybe<string>;
                contentBackgroundSize?: Maybe<string>;
                contentBackgroundRepeat?: Maybe<string>;
                contentBackgroundPosition?: Maybe<string>;
                contentBackgroundColorLayerTwo?: Maybe<string>;
                subtitleColor?: Maybe<string>;
                textAlignment?: Maybe<string>;
                textColor?: Maybe<string>;
                titleColor?: Maybe<string>;
                showHide?: Maybe<boolean>;
                animationDirection?: Maybe<string>;
                hideAnimations?: Maybe<string>;
                hideParallax?: Maybe<string>;
                sliderAnimations?: Maybe<string>;
                zoomOnHover?: Maybe<string>;
                containerMaxWidth?: Maybe<string>;
                headingsNoTranslate?: Maybe<boolean>;
                componentBackgroundImage?: Maybe<{
                  __typename?: 'MediaItem';
                  sourceUrl?: Maybe<string>;
                }>;
                contentBackgroundImage?: Maybe<{
                  __typename?: 'MediaItem';
                  sourceUrl?: Maybe<string>;
                }>;
                paddingTop?: Maybe<{
                  __typename?: 'Page_Acfflexible_FlexibleComponents_MultiColumnList_MultiColumnListComponentSettings_PaddingTop';
                  paddingTopDesktop?: Maybe<string>;
                  paddingTopTablet?: Maybe<string>;
                  paddingTopMobile?: Maybe<string>;
                }>;
                paddingBottom?: Maybe<{
                  __typename?: 'Page_Acfflexible_FlexibleComponents_MultiColumnList_MultiColumnListComponentSettings_PaddingBottom';
                  paddingBottomDesktop?: Maybe<string>;
                  paddingBottomTablet?: Maybe<string>;
                  paddingBottomMobile?: Maybe<string>;
                }>;
              }>;
            }
          | {
              __typename: 'Page_Acfflexible_FlexibleComponents_MultiItemCarousel';
              fieldGroupName?: Maybe<string>;
              copy?: Maybe<string>;
              columnCount?: Maybe<number>;
              header?: Maybe<string>;
              headingValue?: Maybe<string>;
              headingOnActiveState?: Maybe<string>;
              titleAlignment?: Maybe<string>;
              tileLayout?: Maybe<string>;
              tileSizes?: Maybe<string>;
              buttons?: Maybe<
                Array<
                  Maybe<{
                    __typename?: 'Page_Acfflexible_FlexibleComponents_MultiItemCarousel_buttons';
                    buttonStyle?: Maybe<string>;
                    fieldGroupName?: Maybe<string>;
                    link?: Maybe<{
                      __typename?: 'AcfLink';
                      target?: Maybe<string>;
                      title?: Maybe<string>;
                      url?: Maybe<string>;
                    }>;
                  }>
                >
              >;
              repeater?: Maybe<
                Array<
                  Maybe<{
                    __typename?: 'Page_Acfflexible_FlexibleComponents_MultiItemCarousel_repeater';
                    copy?: Maybe<string>;
                    fieldGroupName?: Maybe<string>;
                    title?: Maybe<string>;
                    tileBackgroundLayer2?: Maybe<string>;
                    showHide?: Maybe<boolean>;
                    title_noTx?: Maybe<string>;
                    tableData?: Maybe<
                      Array<
                        Maybe<{
                          __typename?: 'Page_Acfflexible_FlexibleComponents_MultiItemCarousel_repeater_tableData';
                          title?: Maybe<string>;
                          text?: Maybe<string>;
                        }>
                      >
                    >;
                    image?: Maybe<{
                      __typename?: 'MediaItem';
                      sourceUrl?: Maybe<string>;
                      altText?: Maybe<string>;
                    }>;
                    logo?: Maybe<{
                      __typename?: 'MediaItem';
                      sourceUrl?: Maybe<string>;
                      altText?: Maybe<string>;
                    }>;
                    buttons?: Maybe<
                      Array<
                        Maybe<{
                          __typename?: 'Page_Acfflexible_FlexibleComponents_MultiItemCarousel_repeater_buttons';
                          buttonStyle?: Maybe<string>;
                          fieldGroupName?: Maybe<string>;
                          link?: Maybe<{
                            __typename?: 'AcfLink';
                            target?: Maybe<string>;
                            title?: Maybe<string>;
                            url?: Maybe<string>;
                          }>;
                        }>
                      >
                    >;
                  }>
                >
              >;
              multiItemCarouselComponentSettings?: Maybe<{
                __typename?: 'Page_Acfflexible_FlexibleComponents_MultiItemCarousel_MultiItemCarouselComponentSettings';
                fieldGroupName?: Maybe<string>;
                showHide?: Maybe<boolean>;
                componentBackgroundColor?: Maybe<string>;
                componentBackgroundSize?: Maybe<string>;
                componentBackgroundRepeat?: Maybe<string>;
                componentBackgroundPosition?: Maybe<string>;
                contentBackgroundColor?: Maybe<string>;
                contentBackgroundSize?: Maybe<string>;
                contentBackgroundRepeat?: Maybe<string>;
                contentBackgroundPosition?: Maybe<string>;
                contentBackgroundColorLayerTwo?: Maybe<string>;
                subtitleColor?: Maybe<string>;
                textAlignment?: Maybe<string>;
                textColor?: Maybe<string>;
                titleColor?: Maybe<string>;
                hideAnimations?: Maybe<string>;
                animationDirection?: Maybe<string>;
                hideParallax?: Maybe<string>;
                sliderAnimations?: Maybe<string>;
                zoomOnHover?: Maybe<string>;
                containerMaxWidth?: Maybe<string>;
                headingsNoTranslate?: Maybe<boolean>;
                headerTitleColour?: Maybe<string>;
                headerCopyColour?: Maybe<string>;
                uiPrimaryColour?: Maybe<string>;
                uiSecondaryColour?: Maybe<string>;
                titleSize?: Maybe<string>;
                tileTitleBackgroundColour?: Maybe<string>;
                headingStyle?: Maybe<string>;
                tileTitleAlignment?: Maybe<string>;
                tileTitleLayout?: Maybe<string>;
                componentBackgroundImage?: Maybe<{
                  __typename?: 'MediaItem';
                  sourceUrl?: Maybe<string>;
                }>;
                contentBackgroundImage?: Maybe<{
                  __typename?: 'MediaItem';
                  sourceUrl?: Maybe<string>;
                }>;
                paddingTop?: Maybe<{
                  __typename?: 'Page_Acfflexible_FlexibleComponents_MultiItemCarousel_MultiItemCarouselComponentSettings_PaddingTop';
                  paddingTopDesktop?: Maybe<string>;
                  paddingTopTablet?: Maybe<string>;
                  paddingTopMobile?: Maybe<string>;
                }>;
                paddingBottom?: Maybe<{
                  __typename?: 'Page_Acfflexible_FlexibleComponents_MultiItemCarousel_MultiItemCarouselComponentSettings_PaddingBottom';
                  paddingBottomDesktop?: Maybe<string>;
                  paddingBottomTablet?: Maybe<string>;
                  paddingBottomMobile?: Maybe<string>;
                }>;
              }>;
            }
          | {
              __typename: 'Page_Acfflexible_FlexibleComponents_OffersCurated';
              fieldGroupName?: Maybe<string>;
              title?: Maybe<string>;
              copy?: Maybe<string>;
              hideList?: Maybe<string>;
              offersCuratedComponentSettings?: Maybe<{
                __typename?: 'Page_Acfflexible_FlexibleComponents_OffersCurated_OffersCuratedComponentSettings';
                fieldGroupName?: Maybe<string>;
                showHide?: Maybe<boolean>;
                componentBackgroundColor?: Maybe<string>;
                componentBackgroundSize?: Maybe<string>;
                componentBackgroundRepeat?: Maybe<string>;
                componentBackgroundPosition?: Maybe<string>;
                contentBackgroundColor?: Maybe<string>;
                contentBackgroundSize?: Maybe<string>;
                contentBackgroundRepeat?: Maybe<string>;
                contentBackgroundPosition?: Maybe<string>;
                contentBackgroundColorLayerTwo?: Maybe<string>;
                subtitleColor?: Maybe<string>;
                textAlignment?: Maybe<string>;
                textColor?: Maybe<string>;
                titleColor?: Maybe<string>;
                hideAnimations?: Maybe<string>;
                animationDirection?: Maybe<string>;
                hideParallax?: Maybe<string>;
                sliderAnimations?: Maybe<string>;
                zoomOnHover?: Maybe<string>;
                containerMaxWidth?: Maybe<string>;
                headingsNoTranslate?: Maybe<boolean>;
                componentBackgroundImage?: Maybe<{
                  __typename?: 'MediaItem';
                  sourceUrl?: Maybe<string>;
                }>;
                contentBackgroundImage?: Maybe<{
                  __typename?: 'MediaItem';
                  sourceUrl?: Maybe<string>;
                }>;
                paddingTop?: Maybe<{
                  __typename?: 'Page_Acfflexible_FlexibleComponents_OffersCurated_OffersCuratedComponentSettings_PaddingTop';
                  paddingTopDesktop?: Maybe<string>;
                  paddingTopTablet?: Maybe<string>;
                  paddingTopMobile?: Maybe<string>;
                }>;
                paddingBottom?: Maybe<{
                  __typename?: 'Page_Acfflexible_FlexibleComponents_OffersCurated_OffersCuratedComponentSettings_PaddingBottom';
                  paddingBottomDesktop?: Maybe<string>;
                  paddingBottomTablet?: Maybe<string>;
                  paddingBottomMobile?: Maybe<string>;
                }>;
              }>;
            }
          | {
              __typename: 'Page_Acfflexible_FlexibleComponents_Offset';
              description?: Maybe<string>;
              fieldGroupName?: Maybe<string>;
              heading?: Maybe<string>;
              image1CroppingAutoPosition?: Maybe<boolean>;
              image1Cropping?: Maybe<string>;
              image1CroppingCropHeight?: Maybe<string>;
              image1CroppingCropType?: Maybe<string>;
              image1CroppingCropWidth?: Maybe<string>;
              image1CroppingEnableCropping?: Maybe<boolean>;
              image1CroppingOrientation?: Maybe<string>;
              image1CroppingXPosition?: Maybe<string>;
              image1CroppingXPositionAdvanced?: Maybe<string>;
              image1CroppingYPosition?: Maybe<string>;
              image1CroppingYPositionAdvanced?: Maybe<string>;
              image2CroppingYPositionAdvanced?: Maybe<string>;
              image2CroppingYPosition?: Maybe<string>;
              image2CroppingXPositionAdvanced?: Maybe<string>;
              image2CroppingXPosition?: Maybe<string>;
              image2CroppingOrientation?: Maybe<string>;
              image2CroppingEnableCropping?: Maybe<boolean>;
              image2CroppingCropWidth?: Maybe<string>;
              image2CroppingCropType?: Maybe<string>;
              image2CroppingCropHeight?: Maybe<string>;
              image2Cropping?: Maybe<string>;
              image2CroppingAutoPosition?: Maybe<boolean>;
              reverseDirection?: Maybe<boolean>;
              image1?: Maybe<{
                __typename?: 'MediaItem';
                altText?: Maybe<string>;
                slug?: Maybe<string>;
                sourceUrl?: Maybe<string>;
                title?: Maybe<string>;
                caption?: Maybe<string>;
              }>;
              image2?: Maybe<{
                __typename?: 'MediaItem';
                altText?: Maybe<string>;
                sourceUrl?: Maybe<string>;
                slug?: Maybe<string>;
                caption?: Maybe<string>;
              }>;
              image2CaptionImageCaption?: Maybe<{
                __typename?: 'Page_Acfflexible_FlexibleComponents_Offset_Image2CaptionImageCaption';
                captionText?: Maybe<string>;
                fieldGroupName?: Maybe<string>;
                captionLink?: Maybe<{
                  __typename?: 'AcfLink';
                  url?: Maybe<string>;
                  title?: Maybe<string>;
                  target?: Maybe<string>;
                }>;
              }>;
              image1CaptionImageCaption?: Maybe<{
                __typename?: 'Page_Acfflexible_FlexibleComponents_Offset_Image1CaptionImageCaption';
                captionText?: Maybe<string>;
                fieldGroupName?: Maybe<string>;
                captionLink?: Maybe<{
                  __typename?: 'AcfLink';
                  target?: Maybe<string>;
                  title?: Maybe<string>;
                  url?: Maybe<string>;
                }>;
              }>;
              buttons?: Maybe<
                Array<
                  Maybe<{
                    __typename?: 'Page_Acfflexible_FlexibleComponents_Offset_buttons';
                    buttonStyle?: Maybe<string>;
                    fieldGroupName?: Maybe<string>;
                    link?: Maybe<{
                      __typename?: 'AcfLink';
                      target?: Maybe<string>;
                      title?: Maybe<string>;
                      url?: Maybe<string>;
                    }>;
                  }>
                >
              >;
              offsetComponentSettings?: Maybe<{
                __typename?: 'Page_Acfflexible_FlexibleComponents_Offset_OffsetComponentSettings';
                imageCaptionTextColor?: Maybe<string>;
                imageCaptionBackgroundColor?: Maybe<string>;
                componentBackgroundColor?: Maybe<string>;
                componentBackgroundSize?: Maybe<string>;
                componentBackgroundRepeat?: Maybe<string>;
                componentBackgroundPosition?: Maybe<string>;
                contentBackgroundColor?: Maybe<string>;
                contentBackgroundSize?: Maybe<string>;
                contentBackgroundRepeat?: Maybe<string>;
                contentBackgroundPosition?: Maybe<string>;
                contentBackgroundColorLayerTwo?: Maybe<string>;
                subtitleColor?: Maybe<string>;
                textAlignment?: Maybe<string>;
                textColor?: Maybe<string>;
                titleColor?: Maybe<string>;
                showHide?: Maybe<boolean>;
                animationDirection?: Maybe<string>;
                hideAnimations?: Maybe<string>;
                hideParallax?: Maybe<string>;
                sliderAnimations?: Maybe<string>;
                zoomOnHover?: Maybe<string>;
                containerMaxWidth?: Maybe<string>;
                headingsNoTranslate?: Maybe<boolean>;
                componentBackgroundImage?: Maybe<{
                  __typename?: 'MediaItem';
                  sourceUrl?: Maybe<string>;
                }>;
                contentBackgroundImage?: Maybe<{
                  __typename?: 'MediaItem';
                  sourceUrl?: Maybe<string>;
                }>;
                paddingTop?: Maybe<{
                  __typename?: 'Page_Acfflexible_FlexibleComponents_Offset_OffsetComponentSettings_PaddingTop';
                  paddingTopDesktop?: Maybe<string>;
                  paddingTopTablet?: Maybe<string>;
                  paddingTopMobile?: Maybe<string>;
                }>;
                paddingBottom?: Maybe<{
                  __typename?: 'Page_Acfflexible_FlexibleComponents_Offset_OffsetComponentSettings_PaddingBottom';
                  paddingBottomDesktop?: Maybe<string>;
                  paddingBottomTablet?: Maybe<string>;
                  paddingBottomMobile?: Maybe<string>;
                }>;
              }>;
            }
          | {
              __typename: 'Page_Acfflexible_FlexibleComponents_OnPageMenus';
              fieldGroupName?: Maybe<string>;
              header?: Maybe<string>;
              menuRepeater?: Maybe<
                Array<
                  Maybe<{
                    __typename?: 'Page_Acfflexible_FlexibleComponents_OnPageMenus_menuRepeater';
                    fieldGroupName?: Maybe<string>;
                    menuName?: Maybe<string>;
                    contentSelection?: Maybe<string>;
                    gallery?: Maybe<
                      Array<
                        Maybe<{
                          __typename?: 'MediaItem';
                          altText?: Maybe<string>;
                          sourceUrl?: Maybe<string>;
                          mediaDetails?: Maybe<{
                            __typename?: 'MediaDetails';
                            height?: Maybe<number>;
                            width?: Maybe<number>;
                          }>;
                        }>
                      >
                    >;
                    tableHeadingSpan?: Maybe<
                      Array<
                        Maybe<{
                          __typename?: 'Page_Acfflexible_FlexibleComponents_OnPageMenus_menuRepeater_tableHeadingSpan';
                          text?: Maybe<string>;
                          columnSpan?: Maybe<number>;
                        }>
                      >
                    >;
                    menuColumn?: Maybe<
                      Array<
                        Maybe<{
                          __typename?: 'Page_Acfflexible_FlexibleComponents_OnPageMenus_menuRepeater_menuColumn';
                          fieldGroupName?: Maybe<string>;
                          menuHeader?: Maybe<string>;
                          menuItems?: Maybe<string>;
                        }>
                      >
                    >;
                    sectionTableColumns?: Maybe<
                      Array<
                        Maybe<{
                          __typename?: 'Page_Acfflexible_FlexibleComponents_OnPageMenus_menuRepeater_sectionTableColumns';
                          column1?: Maybe<string>;
                          column2?: Maybe<string>;
                          column3?: Maybe<string>;
                          column4?: Maybe<string>;
                          column5?: Maybe<string>;
                          column6?: Maybe<string>;
                          column7?: Maybe<string>;
                          column8?: Maybe<string>;
                        }>
                      >
                    >;
                  }>
                >
              >;
              onPageMenuSettings?: Maybe<{
                __typename?: 'Page_Acfflexible_FlexibleComponents_OnPageMenus_OnPageMenuSettings';
                fieldGroupName?: Maybe<string>;
                showHide?: Maybe<boolean>;
                componentBackgroundColor?: Maybe<string>;
                componentBackgroundSize?: Maybe<string>;
                componentBackgroundRepeat?: Maybe<string>;
                componentBackgroundPosition?: Maybe<string>;
                contentBackgroundColor?: Maybe<string>;
                contentBackgroundSize?: Maybe<string>;
                contentBackgroundRepeat?: Maybe<string>;
                contentBackgroundPosition?: Maybe<string>;
                contentBackgroundColorLayerTwo?: Maybe<string>;
                subtitleColor?: Maybe<string>;
                textAlignment?: Maybe<string>;
                textColor?: Maybe<string>;
                titleColor?: Maybe<string>;
                hideAnimations?: Maybe<string>;
                animationDirection?: Maybe<string>;
                hideParallax?: Maybe<string>;
                sliderAnimations?: Maybe<string>;
                zoomOnHover?: Maybe<string>;
                containerMaxWidth?: Maybe<string>;
                headingsNoTranslate?: Maybe<boolean>;
                componentBackgroundImage?: Maybe<{
                  __typename?: 'MediaItem';
                  sourceUrl?: Maybe<string>;
                }>;
                contentBackgroundImage?: Maybe<{
                  __typename?: 'MediaItem';
                  sourceUrl?: Maybe<string>;
                }>;
                paddingTop?: Maybe<{
                  __typename?: 'Page_Acfflexible_FlexibleComponents_OnPageMenus_OnPageMenuSettings_PaddingTop';
                  paddingTopDesktop?: Maybe<string>;
                  paddingTopTablet?: Maybe<string>;
                  paddingTopMobile?: Maybe<string>;
                }>;
                paddingBottom?: Maybe<{
                  __typename?: 'Page_Acfflexible_FlexibleComponents_OnPageMenus_OnPageMenuSettings_PaddingBottom';
                  paddingBottomDesktop?: Maybe<string>;
                  paddingBottomTablet?: Maybe<string>;
                  paddingBottomMobile?: Maybe<string>;
                }>;
              }>;
            }
          | {
              __typename: 'Page_Acfflexible_FlexibleComponents_PatchworkGrid';
              repeater?: Maybe<
                Array<
                  Maybe<{
                    __typename?: 'Page_Acfflexible_FlexibleComponents_PatchworkGrid_repeater';
                    horizontalDirection?: Maybe<string>;
                    title?: Maybe<string>;
                    copy?: Maybe<string>;
                    image1Cropping?: Maybe<string>;
                    image1CroppingAutoPosition?: Maybe<boolean>;
                    image1CroppingCropHeight?: Maybe<string>;
                    image1CroppingCropType?: Maybe<string>;
                    image1CroppingCropWidth?: Maybe<string>;
                    image1CroppingEnableCropping?: Maybe<boolean>;
                    image1CroppingOrientation?: Maybe<string>;
                    image1CroppingXPosition?: Maybe<string>;
                    image1CroppingXPositionAdvanced?: Maybe<string>;
                    image1CroppingYPosition?: Maybe<string>;
                    image1CroppingYPositionAdvanced?: Maybe<string>;
                    image2Cropping?: Maybe<string>;
                    image2CroppingAutoPosition?: Maybe<boolean>;
                    image2CroppingCropHeight?: Maybe<string>;
                    image2CroppingCropType?: Maybe<string>;
                    image2CroppingCropWidth?: Maybe<string>;
                    image2CroppingEnableCropping?: Maybe<boolean>;
                    image2CroppingOrientation?: Maybe<string>;
                    image2CroppingXPosition?: Maybe<string>;
                    image2CroppingXPositionAdvanced?: Maybe<string>;
                    image2CroppingYPosition?: Maybe<string>;
                    image2CroppingYPositionAdvanced?: Maybe<string>;
                    image3Cropping?: Maybe<string>;
                    image3CroppingAutoPosition?: Maybe<boolean>;
                    image3CroppingCropHeight?: Maybe<string>;
                    image3CroppingCropType?: Maybe<string>;
                    image3CroppingCropWidth?: Maybe<string>;
                    image3CroppingEnableCropping?: Maybe<boolean>;
                    image3CroppingOrientation?: Maybe<string>;
                    image3CroppingXPosition?: Maybe<string>;
                    image3CroppingXPositionAdvanced?: Maybe<string>;
                    image3CroppingYPosition?: Maybe<string>;
                    image3CroppingYPositionAdvanced?: Maybe<string>;
                    buttons?: Maybe<
                      Array<
                        Maybe<{
                          __typename?: 'Page_Acfflexible_FlexibleComponents_PatchworkGrid_repeater_buttons';
                          buttonStyle?: Maybe<string>;
                          fieldGroupName?: Maybe<string>;
                          link?: Maybe<{
                            __typename?: 'AcfLink';
                            target?: Maybe<string>;
                            title?: Maybe<string>;
                            url?: Maybe<string>;
                          }>;
                        }>
                      >
                    >;
                    image1?: Maybe<{
                      __typename?: 'MediaItem';
                      altText?: Maybe<string>;
                      sourceUrl?: Maybe<string>;
                      title?: Maybe<string>;
                      caption?: Maybe<string>;
                    }>;
                    image1CaptionImageCaption?: Maybe<{
                      __typename?: 'Page_Acfflexible_FlexibleComponents_PatchworkGrid_repeater_Image1CaptionImageCaption';
                      captionText?: Maybe<string>;
                      captionLink?: Maybe<{
                        __typename?: 'AcfLink';
                        target?: Maybe<string>;
                        title?: Maybe<string>;
                        url?: Maybe<string>;
                      }>;
                    }>;
                    image2?: Maybe<{
                      __typename?: 'MediaItem';
                      altText?: Maybe<string>;
                      sourceUrl?: Maybe<string>;
                      title?: Maybe<string>;
                      caption?: Maybe<string>;
                    }>;
                    image2CaptionImageCaption?: Maybe<{
                      __typename?: 'Page_Acfflexible_FlexibleComponents_PatchworkGrid_repeater_Image2CaptionImageCaption';
                      captionText?: Maybe<string>;
                      captionLink?: Maybe<{
                        __typename?: 'AcfLink';
                        target?: Maybe<string>;
                        title?: Maybe<string>;
                        url?: Maybe<string>;
                      }>;
                    }>;
                    image3?: Maybe<{
                      __typename?: 'MediaItem';
                      altText?: Maybe<string>;
                      sourceUrl?: Maybe<string>;
                      title?: Maybe<string>;
                    }>;
                    image3CaptionImageCaption?: Maybe<{
                      __typename?: 'Page_Acfflexible_FlexibleComponents_PatchworkGrid_repeater_Image3CaptionImageCaption';
                      captionText?: Maybe<string>;
                      captionLink?: Maybe<{
                        __typename?: 'AcfLink';
                        target?: Maybe<string>;
                        title?: Maybe<string>;
                        url?: Maybe<string>;
                      }>;
                    }>;
                  }>
                >
              >;
              patchworkGridSettings?: Maybe<{
                __typename?: 'Page_Acfflexible_FlexibleComponents_PatchworkGrid_PatchworkGridSettings';
                componentBackgroundColor?: Maybe<string>;
                componentBackgroundSize?: Maybe<string>;
                componentBackgroundRepeat?: Maybe<string>;
                componentBackgroundPosition?: Maybe<string>;
                contentBackgroundColor?: Maybe<string>;
                contentBackgroundSize?: Maybe<string>;
                contentBackgroundRepeat?: Maybe<string>;
                contentBackgroundPosition?: Maybe<string>;
                contentBackgroundColorLayerTwo?: Maybe<string>;
                subtitleColor?: Maybe<string>;
                textAlignment?: Maybe<string>;
                textColor?: Maybe<string>;
                titleColor?: Maybe<string>;
                showHide?: Maybe<boolean>;
                animationDirection?: Maybe<string>;
                hideAnimations?: Maybe<string>;
                hideParallax?: Maybe<string>;
                sliderAnimations?: Maybe<string>;
                zoomOnHover?: Maybe<string>;
                containerMaxWidth?: Maybe<string>;
                headingsNoTranslate?: Maybe<boolean>;
                componentBackgroundImage?: Maybe<{
                  __typename?: 'MediaItem';
                  sourceUrl?: Maybe<string>;
                }>;
                contentBackgroundImage?: Maybe<{
                  __typename?: 'MediaItem';
                  sourceUrl?: Maybe<string>;
                }>;
                paddingTop?: Maybe<{
                  __typename?: 'Page_Acfflexible_FlexibleComponents_PatchworkGrid_PatchworkGridSettings_PaddingTop';
                  paddingTopDesktop?: Maybe<string>;
                  paddingTopTablet?: Maybe<string>;
                  paddingTopMobile?: Maybe<string>;
                }>;
                paddingBottom?: Maybe<{
                  __typename?: 'Page_Acfflexible_FlexibleComponents_PatchworkGrid_PatchworkGridSettings_PaddingBottom';
                  paddingBottomDesktop?: Maybe<string>;
                  paddingBottomTablet?: Maybe<string>;
                  paddingBottomMobile?: Maybe<string>;
                }>;
              }>;
            }
          | { __typename: 'Page_Acfflexible_FlexibleComponents_ResortMap' }
          | {
              __typename: 'Page_Acfflexible_FlexibleComponents_ResortMapV2';
              fieldGroupName?: Maybe<string>;
              mapCategoryDescription?: Maybe<string>;
              mapCategoryMobileButtonPosition?: Maybe<string>;
              mapCategoryTitle?: Maybe<string>;
              mapOverlayPosition?: Maybe<string>;
              mapImage?: Maybe<{
                __typename?: 'MediaItem';
                sourceUrl?: Maybe<string>;
                altText?: Maybe<string>;
                mediaDetails?: Maybe<{
                  __typename?: 'MediaDetails';
                  height?: Maybe<number>;
                  width?: Maybe<number>;
                }>;
              }>;
              mapPins?: Maybe<
                Array<
                  Maybe<{
                    __typename?: 'Page_Acfflexible_FlexibleComponents_ResortMapV2_mapPins';
                    pinTitle?: Maybe<string>;
                    pinX?: Maybe<string>;
                    pinY?: Maybe<string>;
                    pinCategory?: Maybe<string>;
                    pinModal?: Maybe<boolean>;
                    pinModalDescription?: Maybe<string>;
                    pinModalTitle?: Maybe<string>;
                    modalLinkText?: Maybe<string>;
                    pinModalImage?: Maybe<{
                      __typename?: 'MediaItem';
                      sourceUrl?: Maybe<string>;
                      altText?: Maybe<string>;
                      mediaDetails?: Maybe<{
                        __typename?: 'MediaDetails';
                        height?: Maybe<number>;
                        width?: Maybe<number>;
                      }>;
                    }>;
                    modalLink2?: Maybe<{
                      __typename?: 'AcfLink';
                      url?: Maybe<string>;
                      target?: Maybe<string>;
                    }>;
                    modalLink?: Maybe<{
                      __typename?: 'Page';
                      id: string;
                      slug?: Maybe<string>;
                      link?: Maybe<string>;
                    }>;
                  }>
                >
              >;
              resortMapV2Settings?: Maybe<{
                __typename?: 'Page_Acfflexible_FlexibleComponents_ResortMapV2_ResortMapV2Settings';
                fieldGroupName?: Maybe<string>;
                showHide?: Maybe<boolean>;
                mapControlsColor?: Maybe<string>;
                mapControlsBackgroundColor?: Maybe<string>;
                tabBackgroundColor?: Maybe<string>;
                tabTextColor?: Maybe<string>;
                selectedTabBackgroundColor?: Maybe<string>;
                selectedTabTextColor?: Maybe<string>;
                mobileFilterDropdownBackgroundColor?: Maybe<string>;
                mapControlsPlacement?: Maybe<string>;
                mobileFilterDropdownTextColor?: Maybe<string>;
                pinAccentColor?: Maybe<string>;
                pinColor?: Maybe<string>;
                subtitleColor?: Maybe<string>;
                textAlignment?: Maybe<string>;
                titleColor?: Maybe<string>;
                componentBackgroundColor?: Maybe<string>;
                componentBackgroundSize?: Maybe<string>;
                componentBackgroundRepeat?: Maybe<string>;
                componentBackgroundPosition?: Maybe<string>;
                contentBackgroundColor?: Maybe<string>;
                contentBackgroundSize?: Maybe<string>;
                contentBackgroundRepeat?: Maybe<string>;
                contentBackgroundPosition?: Maybe<string>;
                contentBackgroundColorLayerTwo?: Maybe<string>;
                textColor?: Maybe<string>;
                hideAnimations?: Maybe<string>;
                animationDirection?: Maybe<string>;
                hideParallax?: Maybe<string>;
                sliderAnimations?: Maybe<string>;
                zoomOnHover?: Maybe<string>;
                containerMaxWidth?: Maybe<string>;
                headingsNoTranslate?: Maybe<boolean>;
                componentBackgroundImage?: Maybe<{
                  __typename?: 'MediaItem';
                  sourceUrl?: Maybe<string>;
                }>;
                contentBackgroundImage?: Maybe<{
                  __typename?: 'MediaItem';
                  sourceUrl?: Maybe<string>;
                }>;
                paddingTop?: Maybe<{
                  __typename?: 'Page_Acfflexible_FlexibleComponents_ResortMapV2_ResortMapV2Settings_PaddingTop';
                  paddingTopDesktop?: Maybe<string>;
                  paddingTopTablet?: Maybe<string>;
                  paddingTopMobile?: Maybe<string>;
                }>;
                paddingBottom?: Maybe<{
                  __typename?: 'Page_Acfflexible_FlexibleComponents_ResortMapV2_ResortMapV2Settings_PaddingBottom';
                  paddingBottomDesktop?: Maybe<string>;
                  paddingBottomTablet?: Maybe<string>;
                  paddingBottomMobile?: Maybe<string>;
                }>;
              }>;
            }
          | {
              __typename: 'Page_Acfflexible_FlexibleComponents_Restaurants';
              fieldGroupName?: Maybe<string>;
              readMoreLess?: Maybe<boolean>;
              hideList?: Maybe<string>;
              restaurants?: Maybe<
                Array<
                  Maybe<{
                    __typename?: 'Page_Acfflexible_FlexibleComponents_Restaurants_restaurants';
                    cuisineType?: Maybe<string>;
                    cuisineTypeSelect?: Maybe<string>;
                    iconList?: Maybe<string>;
                    fieldGroupName?: Maybe<string>;
                    restaurantId?: Maybe<string>;
                    buttons?: Maybe<
                      Array<
                        Maybe<{
                          __typename?: 'Page_Acfflexible_FlexibleComponents_Restaurants_restaurants_buttons';
                          buttonStyle?: Maybe<string>;
                          link?: Maybe<{
                            __typename?: 'AcfLink';
                            target?: Maybe<string>;
                            title?: Maybe<string>;
                            url?: Maybe<string>;
                          }>;
                        }>
                      >
                    >;
                    openingHours?: Maybe<
                      Array<
                        Maybe<{
                          __typename?: 'Page_Acfflexible_FlexibleComponents_Restaurants_restaurants_openingHours';
                          hours?: Maybe<string>;
                          title?: Maybe<string>;
                        }>
                      >
                    >;
                    image?: Maybe<{
                      __typename?: 'MediaItem';
                      sourceUrl?: Maybe<string>;
                      altText?: Maybe<string>;
                    }>;
                  }>
                >
              >;
              restaurantsComponentSettings?: Maybe<{
                __typename?: 'Page_Acfflexible_FlexibleComponents_Restaurants_RestaurantsComponentSettings';
                fieldGroupName?: Maybe<string>;
                showHide?: Maybe<boolean>;
                componentBackgroundColor?: Maybe<string>;
                componentBackgroundSize?: Maybe<string>;
                componentBackgroundRepeat?: Maybe<string>;
                componentBackgroundPosition?: Maybe<string>;
                contentBackgroundColor?: Maybe<string>;
                contentBackgroundSize?: Maybe<string>;
                contentBackgroundRepeat?: Maybe<string>;
                contentBackgroundPosition?: Maybe<string>;
                contentBackgroundColorLayerTwo?: Maybe<string>;
                subtitleColor?: Maybe<string>;
                textAlignment?: Maybe<string>;
                textColor?: Maybe<string>;
                titleColor?: Maybe<string>;
                hideAnimations?: Maybe<string>;
                animationDirection?: Maybe<string>;
                hideParallax?: Maybe<string>;
                sliderAnimations?: Maybe<string>;
                zoomOnHover?: Maybe<string>;
                containerMaxWidth?: Maybe<string>;
                headingsNoTranslate?: Maybe<boolean>;
                hoursSeperatorColour?: Maybe<string>;
                hideTileBorder?: Maybe<boolean>;
                cuisineTypeTextColour?: Maybe<string>;
                cuisineTypeIconColour?: Maybe<string>;
                tileBorderColour?: Maybe<string>;
                topSectionBackgroundColour?: Maybe<string>;
                componentBackgroundImage?: Maybe<{
                  __typename?: 'MediaItem';
                  sourceUrl?: Maybe<string>;
                }>;
                contentBackgroundImage?: Maybe<{
                  __typename?: 'MediaItem';
                  sourceUrl?: Maybe<string>;
                }>;
                paddingTop?: Maybe<{
                  __typename?: 'Page_Acfflexible_FlexibleComponents_Restaurants_RestaurantsComponentSettings_PaddingTop';
                  paddingTopDesktop?: Maybe<string>;
                  paddingTopTablet?: Maybe<string>;
                  paddingTopMobile?: Maybe<string>;
                }>;
                paddingBottom?: Maybe<{
                  __typename?: 'Page_Acfflexible_FlexibleComponents_Restaurants_RestaurantsComponentSettings_PaddingBottom';
                  paddingBottomDesktop?: Maybe<string>;
                  paddingBottomTablet?: Maybe<string>;
                  paddingBottomMobile?: Maybe<string>;
                }>;
              }>;
            }
          | {
              __typename: 'Page_Acfflexible_FlexibleComponents_RetailFlyout';
              copy?: Maybe<string>;
              fieldGroupName?: Maybe<string>;
              openByDefault?: Maybe<boolean>;
              pagePosition?: Maybe<string>;
              buttonStyle?: Maybe<string>;
              title?: Maybe<string>;
              link?: Maybe<{
                __typename?: 'AcfLink';
                target?: Maybe<string>;
                title?: Maybe<string>;
                url?: Maybe<string>;
              }>;
              retailFlyoutComponentSettings?: Maybe<{
                __typename?: 'Page_Acfflexible_FlexibleComponents_RetailFlyout_RetailFlyoutComponentSettings';
                fieldGroupName?: Maybe<string>;
                showHide?: Maybe<boolean>;
                componentBackgroundColor?: Maybe<string>;
                componentBackgroundSize?: Maybe<string>;
                componentBackgroundRepeat?: Maybe<string>;
                componentBackgroundPosition?: Maybe<string>;
                contentBackgroundColor?: Maybe<string>;
                contentBackgroundSize?: Maybe<string>;
                contentBackgroundRepeat?: Maybe<string>;
                contentBackgroundPosition?: Maybe<string>;
                contentBackgroundColorLayerTwo?: Maybe<string>;
                subtitleColor?: Maybe<string>;
                textAlignment?: Maybe<string>;
                textColor?: Maybe<string>;
                titleColor?: Maybe<string>;
                hideAnimations?: Maybe<string>;
                animationDirection?: Maybe<string>;
                hideParallax?: Maybe<string>;
                sliderAnimations?: Maybe<string>;
                zoomOnHover?: Maybe<string>;
                containerMaxWidth?: Maybe<string>;
                headingsNoTranslate?: Maybe<boolean>;
                componentBackgroundImage?: Maybe<{
                  __typename?: 'MediaItem';
                  sourceUrl?: Maybe<string>;
                }>;
                contentBackgroundImage?: Maybe<{
                  __typename?: 'MediaItem';
                  sourceUrl?: Maybe<string>;
                }>;
                paddingTop?: Maybe<{
                  __typename?: 'Page_Acfflexible_FlexibleComponents_RetailFlyout_RetailFlyoutComponentSettings_PaddingTop';
                  paddingTopDesktop?: Maybe<string>;
                  paddingTopTablet?: Maybe<string>;
                  paddingTopMobile?: Maybe<string>;
                }>;
                paddingBottom?: Maybe<{
                  __typename?: 'Page_Acfflexible_FlexibleComponents_RetailFlyout_RetailFlyoutComponentSettings_PaddingBottom';
                  paddingBottomDesktop?: Maybe<string>;
                  paddingBottomTablet?: Maybe<string>;
                  paddingBottomMobile?: Maybe<string>;
                }>;
              }>;
            }
          | {
              __typename: 'Page_Acfflexible_FlexibleComponents_RetailRail';
              fieldGroupName?: Maybe<string>;
              copy?: Maybe<string>;
              title?: Maybe<string>;
              buttons?: Maybe<
                Array<
                  Maybe<{
                    __typename?: 'Page_Acfflexible_FlexibleComponents_RetailRail_buttons';
                    buttonStyle?: Maybe<string>;
                    fieldGroupName?: Maybe<string>;
                    link?: Maybe<{
                      __typename?: 'AcfLink';
                      target?: Maybe<string>;
                      title?: Maybe<string>;
                      url?: Maybe<string>;
                    }>;
                  }>
                >
              >;
              retailRailComponentSettings?: Maybe<{
                __typename?: 'Page_Acfflexible_FlexibleComponents_RetailRail_RetailRailComponentSettings';
                componentBackgroundColor?: Maybe<string>;
                componentBackgroundPosition?: Maybe<string>;
                componentBackgroundRepeat?: Maybe<string>;
                componentBackgroundSize?: Maybe<string>;
                contentBackgroundColor?: Maybe<string>;
                contentBackgroundPosition?: Maybe<string>;
                contentBackgroundRepeat?: Maybe<string>;
                contentBackgroundSize?: Maybe<string>;
                textAlignment?: Maybe<string>;
                textColor?: Maybe<string>;
                titleColor?: Maybe<string>;
                closeIconColour?: Maybe<string>;
                closeTextColour?: Maybe<string>;
                showHide?: Maybe<boolean>;
                componentBackgroundImage?: Maybe<{
                  __typename?: 'MediaItem';
                  sourceUrl?: Maybe<string>;
                }>;
                contentBackgroundImage?: Maybe<{
                  __typename?: 'MediaItem';
                  sourceUrl?: Maybe<string>;
                }>;
              }>;
            }
          | {
              __typename: 'Page_Acfflexible_FlexibleComponents_RoomTypes';
              fieldGroupName?: Maybe<string>;
              ctyhocn?: Maybe<string>;
              showPricing?: Maybe<Array<Maybe<string>>>;
              hideLeadRates?: Maybe<Array<Maybe<string>>>;
              hideMaxGuestsFilter?: Maybe<Array<Maybe<string>>>;
              hideBedsFilter?: Maybe<Array<Maybe<string>>>;
              hidePriceFilter?: Maybe<Array<Maybe<string>>>;
              showFindYourBestRoomFilters?: Maybe<boolean>;
              arrivalDate?: Maybe<string>;
              displayRoomsFromPosts?: Maybe<boolean>;
              pricingDisclaimer?: Maybe<string>;
              sortRoomsBy?: Maybe<string>;
              room?: Maybe<
                Array<
                  Maybe<{
                    __typename?: 'Page_Acfflexible_FlexibleComponents_RoomTypes_room';
                    roomTypeCode?: Maybe<string>;
                    view?: Maybe<string>;
                    recommendedFor?: Maybe<Array<Maybe<string>>>;
                    premiumOptions?: Maybe<Array<Maybe<string>>>;
                    balconyDetail?: Maybe<string>;
                    customView?: Maybe<string>;
                    customBalcony?: Maybe<string>;
                    squareFootage?: Maybe<number>;
                    outdoorFeatures?: Maybe<Array<Maybe<string>>>;
                    roomFeatures?: Maybe<Array<Maybe<string>>>;
                    roomType?: Maybe<Array<Maybe<string>>>;
                    bathroomAmenities?: Maybe<Array<Maybe<string>>>;
                    locations?: Maybe<Array<Maybe<string>>>;
                    iconWidth?: Maybe<number>;
                    floorPlanCtaUrl?: Maybe<string>;
                    floorPlanCtaLabel?: Maybe<string>;
                    buttonStyle?: Maybe<string>;
                    enableUpsell?: Maybe<string>;
                    upsellModalTitle?: Maybe<string>;
                    upsellModalTitleHighlight?: Maybe<string>;
                    upsellRoomCode?: Maybe<string>;
                    image?: Maybe<{
                      __typename?: 'MediaItem';
                      sourceUrl?: Maybe<string>;
                      altText?: Maybe<string>;
                    }>;
                    icon?: Maybe<{
                      __typename?: 'MediaItem';
                      altText?: Maybe<string>;
                      sourceUrl?: Maybe<string>;
                    }>;
                    carouselImages?: Maybe<
                      Array<
                        Maybe<{
                          __typename?: 'Page_Acfflexible_FlexibleComponents_RoomTypes_room_carouselImages';
                          image?: Maybe<{
                            __typename?: 'MediaItem';
                            sourceUrl?: Maybe<string>;
                            altText?: Maybe<string>;
                            mediaDetails?: Maybe<{
                              __typename?: 'MediaDetails';
                              height?: Maybe<number>;
                              width?: Maybe<number>;
                            }>;
                          }>;
                        }>
                      >
                    >;
                    showCustomFilter1?: Maybe<{
                      __typename?: 'Page_Acfflexible_FlexibleComponents_RoomTypes_room_ShowCustomFilter1';
                      customFilter1Select?: Maybe<string>;
                      showCustomFilter1?: Maybe<boolean>;
                    }>;
                    showCustomFilter2?: Maybe<{
                      __typename?: 'Page_Acfflexible_FlexibleComponents_RoomTypes_room_ShowCustomFilter2';
                      customFilter2Select?: Maybe<string>;
                      showCustomFilter2?: Maybe<boolean>;
                    }>;
                    rooms?: Maybe<{
                      __typename?: 'Page_Acfflexible_FlexibleComponents_RoomTypes_room_Rooms';
                      fieldGroupName?: Maybe<string>;
                      selectedRoomTitle?: Maybe<string>;
                      selectedRoomTitleHighlight?: Maybe<string>;
                      upsellRoomTitle?: Maybe<string>;
                      upsellRoomTitleHighlight?: Maybe<string>;
                      selectedRoomFeatures?: Maybe<
                        Array<
                          Maybe<{
                            __typename?: 'Page_Acfflexible_FlexibleComponents_RoomTypes_room_Rooms_selectedRoomFeatures';
                            item?: Maybe<string>;
                          }>
                        >
                      >;
                      selectedRoomDownsellFeatures?: Maybe<
                        Array<
                          Maybe<{
                            __typename?: 'Page_Acfflexible_FlexibleComponents_RoomTypes_room_Rooms_selectedRoomDownsellFeatures';
                            item?: Maybe<string>;
                          }>
                        >
                      >;
                      upsellRoomFeatures?: Maybe<
                        Array<
                          Maybe<{
                            __typename?: 'Page_Acfflexible_FlexibleComponents_RoomTypes_room_Rooms_upsellRoomFeatures';
                            item?: Maybe<string>;
                          }>
                        >
                      >;
                      upsellRoomAdditionalFeatures?: Maybe<
                        Array<
                          Maybe<{
                            __typename?: 'Page_Acfflexible_FlexibleComponents_RoomTypes_room_Rooms_upsellRoomAdditionalFeatures';
                            item?: Maybe<string>;
                          }>
                        >
                      >;
                    }>;
                    balconyIconGroup?: Maybe<{
                      __typename?: 'Page_Acfflexible_FlexibleComponents_RoomTypes_room_BalconyIconGroup';
                      iconList?: Maybe<string>;
                    }>;
                    viewIconGroup?: Maybe<{
                      __typename?: 'Page_Acfflexible_FlexibleComponents_RoomTypes_room_ViewIconGroup';
                      iconList?: Maybe<string>;
                    }>;
                    squareFootageIconGroup?: Maybe<{
                      __typename?: 'Page_Acfflexible_FlexibleComponents_RoomTypes_room_SquareFootageIconGroup';
                      iconList?: Maybe<string>;
                    }>;
                  }>
                >
              >;
              roomsComponentSettings?: Maybe<{
                __typename?: 'Page_Acfflexible_FlexibleComponents_RoomTypes_RoomsComponentSettings';
                fieldGroupName?: Maybe<string>;
                showHide?: Maybe<boolean>;
                componentBackgroundColor?: Maybe<string>;
                componentBackgroundSize?: Maybe<string>;
                componentBackgroundRepeat?: Maybe<string>;
                componentBackgroundPosition?: Maybe<string>;
                contentBackgroundColor?: Maybe<string>;
                contentBackgroundSize?: Maybe<string>;
                contentBackgroundRepeat?: Maybe<string>;
                contentBackgroundPosition?: Maybe<string>;
                contentBackgroundColorLayerTwo?: Maybe<string>;
                subtitleColor?: Maybe<string>;
                textAlignment?: Maybe<string>;
                textColor?: Maybe<string>;
                titleColor?: Maybe<string>;
                hideAnimations?: Maybe<string>;
                animationDirection?: Maybe<string>;
                hideParallax?: Maybe<string>;
                sliderAnimations?: Maybe<string>;
                zoomOnHover?: Maybe<string>;
                containerMaxWidth?: Maybe<string>;
                headingsNoTranslate?: Maybe<boolean>;
                componentBackgroundImage?: Maybe<{
                  __typename?: 'MediaItem';
                  sourceUrl?: Maybe<string>;
                }>;
                contentBackgroundImage?: Maybe<{
                  __typename?: 'MediaItem';
                  sourceUrl?: Maybe<string>;
                }>;
                paddingTop?: Maybe<{
                  __typename?: 'Page_Acfflexible_FlexibleComponents_RoomTypes_RoomsComponentSettings_PaddingTop';
                  paddingTopDesktop?: Maybe<string>;
                  paddingTopTablet?: Maybe<string>;
                  paddingTopMobile?: Maybe<string>;
                }>;
                paddingBottom?: Maybe<{
                  __typename?: 'Page_Acfflexible_FlexibleComponents_RoomTypes_RoomsComponentSettings_PaddingBottom';
                  paddingBottomDesktop?: Maybe<string>;
                  paddingBottomTablet?: Maybe<string>;
                  paddingBottomMobile?: Maybe<string>;
                }>;
              }>;
            }
          | {
              __typename: 'Page_Acfflexible_FlexibleComponents_RoomTypesLite';
              fieldGroupName?: Maybe<string>;
              ctyhocn?: Maybe<string>;
              hideRoomCodes?: Maybe<string>;
              showFindYourBestRoomFilters?: Maybe<boolean>;
              roomsComponentSettings?: Maybe<{
                __typename?: 'Page_Acfflexible_FlexibleComponents_RoomTypesLite_RoomsComponentSettings';
                fieldGroupName?: Maybe<string>;
                showHide?: Maybe<boolean>;
                componentBackgroundColor?: Maybe<string>;
                componentBackgroundSize?: Maybe<string>;
                componentBackgroundRepeat?: Maybe<string>;
                componentBackgroundPosition?: Maybe<string>;
                contentBackgroundColor?: Maybe<string>;
                contentBackgroundSize?: Maybe<string>;
                contentBackgroundRepeat?: Maybe<string>;
                contentBackgroundPosition?: Maybe<string>;
                contentBackgroundColorLayerTwo?: Maybe<string>;
                subtitleColor?: Maybe<string>;
                textAlignment?: Maybe<string>;
                textColor?: Maybe<string>;
                titleColor?: Maybe<string>;
                hideAnimations?: Maybe<string>;
                animationDirection?: Maybe<string>;
                hideParallax?: Maybe<string>;
                sliderAnimations?: Maybe<string>;
                zoomOnHover?: Maybe<string>;
                containerMaxWidth?: Maybe<string>;
                headingsNoTranslate?: Maybe<boolean>;
                componentBackgroundImage?: Maybe<{
                  __typename?: 'MediaItem';
                  sourceUrl?: Maybe<string>;
                }>;
                contentBackgroundImage?: Maybe<{
                  __typename?: 'MediaItem';
                  sourceUrl?: Maybe<string>;
                }>;
                paddingTop?: Maybe<{
                  __typename?: 'Page_Acfflexible_FlexibleComponents_RoomTypesLite_RoomsComponentSettings_PaddingTop';
                  paddingTopDesktop?: Maybe<string>;
                  paddingTopTablet?: Maybe<string>;
                  paddingTopMobile?: Maybe<string>;
                }>;
                paddingBottom?: Maybe<{
                  __typename?: 'Page_Acfflexible_FlexibleComponents_RoomTypesLite_RoomsComponentSettings_PaddingBottom';
                  paddingBottomDesktop?: Maybe<string>;
                  paddingBottomTablet?: Maybe<string>;
                  paddingBottomMobile?: Maybe<string>;
                }>;
              }>;
            }
          | {
              __typename: 'Page_Acfflexible_FlexibleComponents_SingleItemSlider';
              fieldGroupName?: Maybe<string>;
              imageSizes?: Maybe<string>;
              layoutDirection?: Maybe<string>;
              layoutMode?: Maybe<string>;
              offsetImage?: Maybe<string>;
              header?: Maybe<string>;
              headingValue?: Maybe<string>;
              copy?: Maybe<string>;
              repeater?: Maybe<
                Array<
                  Maybe<{
                    __typename?: 'Page_Acfflexible_FlexibleComponents_SingleItemSlider_repeater';
                    copy?: Maybe<string>;
                    fieldGroupName?: Maybe<string>;
                    title?: Maybe<string>;
                    imagePositioning?: Maybe<string>;
                    showHide?: Maybe<boolean>;
                    title_noTx?: Maybe<string>;
                    image?: Maybe<{
                      __typename?: 'MediaItem';
                      altText?: Maybe<string>;
                      sourceUrl?: Maybe<string>;
                    }>;
                    tableData?: Maybe<
                      Array<
                        Maybe<{
                          __typename?: 'Page_Acfflexible_FlexibleComponents_SingleItemSlider_repeater_tableData';
                          text?: Maybe<string>;
                          title?: Maybe<string>;
                        }>
                      >
                    >;
                    singleLink?: Maybe<{
                      __typename?: 'AcfLink';
                      target?: Maybe<string>;
                      title?: Maybe<string>;
                      url?: Maybe<string>;
                    }>;
                  }>
                >
              >;
              singleItemSliderComponentSettings?: Maybe<{
                __typename?: 'Page_Acfflexible_FlexibleComponents_SingleItemSlider_SingleItemSliderComponentSettings';
                fieldGroupName?: Maybe<string>;
                showHide?: Maybe<boolean>;
                componentBackgroundColor?: Maybe<string>;
                componentBackgroundSize?: Maybe<string>;
                componentBackgroundRepeat?: Maybe<string>;
                componentBackgroundPosition?: Maybe<string>;
                contentBackgroundColor?: Maybe<string>;
                contentBackgroundSize?: Maybe<string>;
                contentBackgroundRepeat?: Maybe<string>;
                contentBackgroundPosition?: Maybe<string>;
                contentBackgroundColorLayerTwo?: Maybe<string>;
                subtitleColor?: Maybe<string>;
                textAlignment?: Maybe<string>;
                textColor?: Maybe<string>;
                titleColor?: Maybe<string>;
                hideAnimations?: Maybe<string>;
                animationDirection?: Maybe<string>;
                hideParallax?: Maybe<string>;
                sliderAnimations?: Maybe<string>;
                zoomOnHover?: Maybe<string>;
                containerMaxWidth?: Maybe<string>;
                headingsNoTranslate?: Maybe<boolean>;
                componentBackgroundImage?: Maybe<{
                  __typename?: 'MediaItem';
                  sourceUrl?: Maybe<string>;
                }>;
                contentBackgroundImage?: Maybe<{
                  __typename?: 'MediaItem';
                  sourceUrl?: Maybe<string>;
                }>;
                paddingTop?: Maybe<{
                  __typename?: 'Page_Acfflexible_FlexibleComponents_SingleItemSlider_SingleItemSliderComponentSettings_PaddingTop';
                  paddingTopDesktop?: Maybe<string>;
                  paddingTopTablet?: Maybe<string>;
                  paddingTopMobile?: Maybe<string>;
                }>;
                paddingBottom?: Maybe<{
                  __typename?: 'Page_Acfflexible_FlexibleComponents_SingleItemSlider_SingleItemSliderComponentSettings_PaddingBottom';
                  paddingBottomDesktop?: Maybe<string>;
                  paddingBottomTablet?: Maybe<string>;
                  paddingBottomMobile?: Maybe<string>;
                }>;
              }>;
            }
          | {
              __typename: 'Page_Acfflexible_FlexibleComponents_SocialMediaFeed';
              feedId?: Maybe<string>;
              fieldGroupName?: Maybe<string>;
              socialLinkTitle?: Maybe<string>;
              socialLinkUrl?: Maybe<string>;
              socialTitle?: Maybe<string>;
              socialMediaFeedSettings?: Maybe<{
                __typename?: 'Page_Acfflexible_FlexibleComponents_SocialMediaFeed_SocialMediaFeedSettings';
                fieldGroupName?: Maybe<string>;
                socialMediaIconColor?: Maybe<string>;
                showHide?: Maybe<boolean>;
                componentBackgroundColor?: Maybe<string>;
                componentBackgroundSize?: Maybe<string>;
                componentBackgroundRepeat?: Maybe<string>;
                componentBackgroundPosition?: Maybe<string>;
                contentBackgroundColor?: Maybe<string>;
                contentBackgroundSize?: Maybe<string>;
                contentBackgroundRepeat?: Maybe<string>;
                contentBackgroundPosition?: Maybe<string>;
                contentBackgroundColorLayerTwo?: Maybe<string>;
                subtitleColor?: Maybe<string>;
                textAlignment?: Maybe<string>;
                textColor?: Maybe<string>;
                titleColor?: Maybe<string>;
                hideAnimations?: Maybe<string>;
                animationDirection?: Maybe<string>;
                hideParallax?: Maybe<string>;
                sliderAnimations?: Maybe<string>;
                zoomOnHover?: Maybe<string>;
                containerMaxWidth?: Maybe<string>;
                headingsNoTranslate?: Maybe<boolean>;
                componentBackgroundImage?: Maybe<{
                  __typename?: 'MediaItem';
                  sourceUrl?: Maybe<string>;
                }>;
                contentBackgroundImage?: Maybe<{
                  __typename?: 'MediaItem';
                  sourceUrl?: Maybe<string>;
                }>;
                paddingTop?: Maybe<{
                  __typename?: 'Page_Acfflexible_FlexibleComponents_SocialMediaFeed_SocialMediaFeedSettings_PaddingTop';
                  paddingTopDesktop?: Maybe<string>;
                  paddingTopTablet?: Maybe<string>;
                  paddingTopMobile?: Maybe<string>;
                }>;
                paddingBottom?: Maybe<{
                  __typename?: 'Page_Acfflexible_FlexibleComponents_SocialMediaFeed_SocialMediaFeedSettings_PaddingBottom';
                  paddingBottomDesktop?: Maybe<string>;
                  paddingBottomTablet?: Maybe<string>;
                  paddingBottomMobile?: Maybe<string>;
                }>;
              }>;
            }
          | {
              __typename: 'Page_Acfflexible_FlexibleComponents_Spacer';
              componentHeight?: Maybe<string>;
              fieldGroupName?: Maybe<string>;
              group?: Maybe<{
                __typename?: 'Page_Acfflexible_FlexibleComponents_Spacer_Group';
                fieldGroupName?: Maybe<string>;
                large1024px?: Maybe<string>;
                medium768px?: Maybe<string>;
                small640px?: Maybe<string>;
                extraLarge1280px?: Maybe<string>;
                twoXExtraLarge1440px?: Maybe<string>;
              }>;
              spacerComponentSettings?: Maybe<{
                __typename?: 'Page_Acfflexible_FlexibleComponents_Spacer_SpacerComponentSettings';
                fieldGroupName?: Maybe<string>;
                showHide?: Maybe<boolean>;
                componentBackgroundColor?: Maybe<string>;
                componentBackgroundSize?: Maybe<string>;
                componentBackgroundRepeat?: Maybe<string>;
                componentBackgroundPosition?: Maybe<string>;
                contentBackgroundColor?: Maybe<string>;
                contentBackgroundSize?: Maybe<string>;
                contentBackgroundRepeat?: Maybe<string>;
                contentBackgroundPosition?: Maybe<string>;
                contentBackgroundColorLayerTwo?: Maybe<string>;
                subtitleColor?: Maybe<string>;
                textAlignment?: Maybe<string>;
                textColor?: Maybe<string>;
                titleColor?: Maybe<string>;
                hideAnimations?: Maybe<string>;
                animationDirection?: Maybe<string>;
                hideParallax?: Maybe<string>;
                sliderAnimations?: Maybe<string>;
                zoomOnHover?: Maybe<string>;
                containerMaxWidth?: Maybe<string>;
                headingsNoTranslate?: Maybe<boolean>;
                componentBackgroundImage?: Maybe<{
                  __typename?: 'MediaItem';
                  sourceUrl?: Maybe<string>;
                }>;
                contentBackgroundImage?: Maybe<{
                  __typename?: 'MediaItem';
                  sourceUrl?: Maybe<string>;
                }>;
                paddingTop?: Maybe<{
                  __typename?: 'Page_Acfflexible_FlexibleComponents_Spacer_SpacerComponentSettings_PaddingTop';
                  paddingTopDesktop?: Maybe<string>;
                  paddingTopTablet?: Maybe<string>;
                  paddingTopMobile?: Maybe<string>;
                }>;
                paddingBottom?: Maybe<{
                  __typename?: 'Page_Acfflexible_FlexibleComponents_Spacer_SpacerComponentSettings_PaddingBottom';
                  paddingBottomDesktop?: Maybe<string>;
                  paddingBottomTablet?: Maybe<string>;
                  paddingBottomMobile?: Maybe<string>;
                }>;
              }>;
            }
          | {
              __typename: 'Page_Acfflexible_FlexibleComponents_TwoColumnCompare';
              repeater?: Maybe<
                Array<
                  Maybe<{
                    __typename?: 'Page_Acfflexible_FlexibleComponents_TwoColumnCompare_repeater';
                    copy?: Maybe<string>;
                    title?: Maybe<string>;
                    subtitle?: Maybe<string>;
                    backgroundPosition?: Maybe<string>;
                    backgroundRepeat?: Maybe<string>;
                    backgroundSize?: Maybe<string>;
                    title_noTx?: Maybe<string>;
                    subtitle_noTx?: Maybe<string>;
                    backgroundImage?: Maybe<{
                      __typename?: 'MediaItem';
                      altText?: Maybe<string>;
                      sourceUrl?: Maybe<string>;
                    }>;
                    buttons?: Maybe<
                      Array<
                        Maybe<{
                          __typename?: 'Page_Acfflexible_FlexibleComponents_TwoColumnCompare_repeater_buttons';
                          buttonStyle?: Maybe<string>;
                          link?: Maybe<{
                            __typename?: 'AcfLink';
                            target?: Maybe<string>;
                            title?: Maybe<string>;
                            url?: Maybe<string>;
                          }>;
                        }>
                      >
                    >;
                    image?: Maybe<{
                      __typename?: 'MediaItem';
                      sourceUrl?: Maybe<string>;
                      altText?: Maybe<string>;
                    }>;
                    logo?: Maybe<{
                      __typename?: 'MediaItem';
                      altText?: Maybe<string>;
                      sourceUrl?: Maybe<string>;
                      mediaDetails?: Maybe<{
                        __typename?: 'MediaDetails';
                        height?: Maybe<number>;
                        width?: Maybe<number>;
                      }>;
                    }>;
                  }>
                >
              >;
              twoColumnCompareComponentSettings?: Maybe<{
                __typename?: 'Page_Acfflexible_FlexibleComponents_TwoColumnCompare_TwoColumnCompareComponentSettings';
                fieldGroupName?: Maybe<string>;
                showHide?: Maybe<boolean>;
                componentBackgroundColor?: Maybe<string>;
                componentBackgroundSize?: Maybe<string>;
                componentBackgroundRepeat?: Maybe<string>;
                componentBackgroundPosition?: Maybe<string>;
                contentBackgroundColor?: Maybe<string>;
                contentBackgroundSize?: Maybe<string>;
                contentBackgroundRepeat?: Maybe<string>;
                contentBackgroundPosition?: Maybe<string>;
                contentBackgroundColorLayerTwo?: Maybe<string>;
                subtitleColor?: Maybe<string>;
                textAlignment?: Maybe<string>;
                textColor?: Maybe<string>;
                titleColor?: Maybe<string>;
                hideAnimations?: Maybe<string>;
                animationDirection?: Maybe<string>;
                hideParallax?: Maybe<string>;
                sliderAnimations?: Maybe<string>;
                zoomOnHover?: Maybe<string>;
                containerMaxWidth?: Maybe<string>;
                headingsNoTranslate?: Maybe<boolean>;
                componentBackgroundImage?: Maybe<{
                  __typename?: 'MediaItem';
                  sourceUrl?: Maybe<string>;
                }>;
                contentBackgroundImage?: Maybe<{
                  __typename?: 'MediaItem';
                  sourceUrl?: Maybe<string>;
                }>;
                paddingTop?: Maybe<{
                  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoColumnCompare_TwoColumnCompareComponentSettings_PaddingTop';
                  paddingTopDesktop?: Maybe<string>;
                  paddingTopTablet?: Maybe<string>;
                  paddingTopMobile?: Maybe<string>;
                }>;
                paddingBottom?: Maybe<{
                  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoColumnCompare_TwoColumnCompareComponentSettings_PaddingBottom';
                  paddingBottomDesktop?: Maybe<string>;
                  paddingBottomTablet?: Maybe<string>;
                  paddingBottomMobile?: Maybe<string>;
                }>;
              }>;
            }
          | {
              __typename: 'Page_Acfflexible_FlexibleComponents_TwoColumnIconList';
              fieldGroupName?: Maybe<string>;
              subtitle?: Maybe<string>;
              title?: Maybe<string>;
              repeater?: Maybe<
                Array<
                  Maybe<{
                    __typename?: 'Page_Acfflexible_FlexibleComponents_TwoColumnIconList_repeater';
                    fieldGroupName?: Maybe<string>;
                    iconList?: Maybe<string>;
                    iconDescription?: Maybe<string>;
                    iconText?: Maybe<string>;
                  }>
                >
              >;
              twoColumnIconListSettings?: Maybe<{
                __typename?: 'Page_Acfflexible_FlexibleComponents_TwoColumnIconList_TwoColumnIconListSettings';
                fieldGroupName?: Maybe<string>;
                showHide?: Maybe<boolean>;
                componentBackgroundColor?: Maybe<string>;
                componentBackgroundSize?: Maybe<string>;
                componentBackgroundRepeat?: Maybe<string>;
                componentBackgroundPosition?: Maybe<string>;
                contentBackgroundColor?: Maybe<string>;
                contentBackgroundSize?: Maybe<string>;
                contentBackgroundRepeat?: Maybe<string>;
                contentBackgroundPosition?: Maybe<string>;
                contentBackgroundColorLayerTwo?: Maybe<string>;
                subtitleColor?: Maybe<string>;
                textAlignment?: Maybe<string>;
                textColor?: Maybe<string>;
                titleColor?: Maybe<string>;
                hideAnimations?: Maybe<string>;
                animationDirection?: Maybe<string>;
                hideParallax?: Maybe<string>;
                sliderAnimations?: Maybe<string>;
                zoomOnHover?: Maybe<string>;
                containerMaxWidth?: Maybe<string>;
                headingsNoTranslate?: Maybe<boolean>;
                componentBackgroundImage?: Maybe<{
                  __typename?: 'MediaItem';
                  sourceUrl?: Maybe<string>;
                }>;
                contentBackgroundImage?: Maybe<{
                  __typename?: 'MediaItem';
                  sourceUrl?: Maybe<string>;
                }>;
                paddingTop?: Maybe<{
                  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoColumnIconList_TwoColumnIconListSettings_PaddingTop';
                  paddingTopDesktop?: Maybe<string>;
                  paddingTopTablet?: Maybe<string>;
                  paddingTopMobile?: Maybe<string>;
                }>;
                paddingBottom?: Maybe<{
                  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoColumnIconList_TwoColumnIconListSettings_PaddingBottom';
                  paddingBottomDesktop?: Maybe<string>;
                  paddingBottomTablet?: Maybe<string>;
                  paddingBottomMobile?: Maybe<string>;
                }>;
              }>;
            }
          | {
              __typename: 'Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy';
              fieldGroupName?: Maybe<string>;
              subtitle?: Maybe<string>;
              title?: Maybe<string>;
              flipLargeImageHorizontally?: Maybe<boolean>;
              copy?: Maybe<string>;
              imageLargeCropHeight?: Maybe<string>;
              imageLargeCropWidth?: Maybe<string>;
              imageLargeEnableCropping?: Maybe<boolean>;
              imageLargeCropType?: Maybe<string>;
              imageLargeXPosition?: Maybe<string>;
              imageLargeYPosition?: Maybe<string>;
              imageLargeXPositionAdvanced?: Maybe<string>;
              imageLargeYPositionAdvanced?: Maybe<string>;
              imageSmallCropHeight?: Maybe<string>;
              imageSmallCropWidth?: Maybe<string>;
              imageSmallEnableCropping?: Maybe<boolean>;
              imageSmallCropType?: Maybe<string>;
              imageSmallXPosition?: Maybe<string>;
              imageSmallYPosition?: Maybe<string>;
              imageSmallXPositionAdvanced?: Maybe<string>;
              imageSmallYPositionAdvanced?: Maybe<string>;
              subtitle_noTx?: Maybe<string>;
              title_noTx?: Maybe<string>;
              largeImage?: Maybe<{
                __typename?: 'MediaItem';
                altText?: Maybe<string>;
                sourceUrl?: Maybe<string>;
              }>;
              smallImage?: Maybe<{
                __typename?: 'MediaItem';
                altText?: Maybe<string>;
                sourceUrl?: Maybe<string>;
              }>;
              twoOneMediaCopySettings?: Maybe<{
                __typename?: 'Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy_TwoOneMediaCopySettings';
                fieldGroupName?: Maybe<string>;
                showHide?: Maybe<boolean>;
                componentBackgroundColor?: Maybe<string>;
                componentBackgroundSize?: Maybe<string>;
                componentBackgroundRepeat?: Maybe<string>;
                componentBackgroundPosition?: Maybe<string>;
                contentBackgroundColor?: Maybe<string>;
                contentBackgroundSize?: Maybe<string>;
                contentBackgroundRepeat?: Maybe<string>;
                contentBackgroundPosition?: Maybe<string>;
                contentBackgroundColorLayerTwo?: Maybe<string>;
                subtitleColor?: Maybe<string>;
                textAlignment?: Maybe<string>;
                textColor?: Maybe<string>;
                titleColor?: Maybe<string>;
                hideAnimations?: Maybe<string>;
                animationDirection?: Maybe<string>;
                hideParallax?: Maybe<string>;
                sliderAnimations?: Maybe<string>;
                zoomOnHover?: Maybe<string>;
                containerMaxWidth?: Maybe<string>;
                headingsNoTranslate?: Maybe<boolean>;
                componentBackgroundImage?: Maybe<{
                  __typename?: 'MediaItem';
                  sourceUrl?: Maybe<string>;
                }>;
                contentBackgroundImage?: Maybe<{
                  __typename?: 'MediaItem';
                  sourceUrl?: Maybe<string>;
                }>;
                paddingTop?: Maybe<{
                  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy_TwoOneMediaCopySettings_PaddingTop';
                  paddingTopDesktop?: Maybe<string>;
                  paddingTopTablet?: Maybe<string>;
                  paddingTopMobile?: Maybe<string>;
                }>;
                paddingBottom?: Maybe<{
                  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy_TwoOneMediaCopySettings_PaddingBottom';
                  paddingBottomDesktop?: Maybe<string>;
                  paddingBottomTablet?: Maybe<string>;
                  paddingBottomMobile?: Maybe<string>;
                }>;
              }>;
            }
          | {
              __typename: 'Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy';
              fieldGroupName?: Maybe<string>;
              reverseDirection?: Maybe<boolean>;
              imageColumn1AutoPosition?: Maybe<boolean>;
              imageColumn1CropHeight?: Maybe<string>;
              imageColumn1CropType?: Maybe<string>;
              imageColumn1CropWidth?: Maybe<string>;
              imageColumn1EnableCropping?: Maybe<boolean>;
              imageColumn1XPosition?: Maybe<string>;
              imageColumn1XPositionAdvanced?: Maybe<string>;
              imageColumn1YPosition?: Maybe<string>;
              imageColumn1YPositionAdvanced?: Maybe<string>;
              imageColumn2AutoPosition?: Maybe<boolean>;
              imageColumn2CropHeight?: Maybe<string>;
              imageColumn2CropType?: Maybe<string>;
              imageColumn2CropWidth?: Maybe<string>;
              imageColumn2EnableCropping?: Maybe<boolean>;
              imageColumn2XPosition?: Maybe<string>;
              imageColumn2XPositionAdvanced?: Maybe<string>;
              imageColumn2YPosition?: Maybe<string>;
              imageColumn2YPositionAdvanced?: Maybe<string>;
              column1?: Maybe<{
                __typename?: 'Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy_Column1';
                title?: Maybe<string>;
                copy?: Maybe<string>;
                title_noTx?: Maybe<string>;
                image?: Maybe<{
                  __typename?: 'MediaItem';
                  altText?: Maybe<string>;
                  sourceUrl?: Maybe<string>;
                }>;
                buttons?: Maybe<
                  Array<
                    Maybe<{
                      __typename?: 'Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy_Column1_buttons';
                      buttonStyle?: Maybe<string>;
                      link?: Maybe<{
                        __typename?: 'AcfLink';
                        target?: Maybe<string>;
                        title?: Maybe<string>;
                        url?: Maybe<string>;
                      }>;
                    }>
                  >
                >;
              }>;
              column2?: Maybe<{
                __typename?: 'Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy_Column2';
                title?: Maybe<string>;
                copy?: Maybe<string>;
                title_noTx?: Maybe<string>;
                image?: Maybe<{
                  __typename?: 'MediaItem';
                  altText?: Maybe<string>;
                  sourceUrl?: Maybe<string>;
                }>;
                buttons?: Maybe<
                  Array<
                    Maybe<{
                      __typename?: 'Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy_Column2_buttons';
                      buttonStyle?: Maybe<string>;
                      link?: Maybe<{
                        __typename?: 'AcfLink';
                        target?: Maybe<string>;
                        title?: Maybe<string>;
                        url?: Maybe<string>;
                      }>;
                    }>
                  >
                >;
              }>;
              twoTwoMediaCopySettings?: Maybe<{
                __typename?: 'Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy_TwoTwoMediaCopySettings';
                fieldGroupName?: Maybe<string>;
                horizontalDirection?: Maybe<string>;
                showHide?: Maybe<boolean>;
                componentBackgroundColor?: Maybe<string>;
                componentBackgroundSize?: Maybe<string>;
                componentBackgroundRepeat?: Maybe<string>;
                componentBackgroundPosition?: Maybe<string>;
                contentBackgroundColor?: Maybe<string>;
                contentBackgroundSize?: Maybe<string>;
                contentBackgroundRepeat?: Maybe<string>;
                contentBackgroundPosition?: Maybe<string>;
                contentBackgroundColorLayerTwo?: Maybe<string>;
                subtitleColor?: Maybe<string>;
                textAlignment?: Maybe<string>;
                textColor?: Maybe<string>;
                titleColor?: Maybe<string>;
                hideAnimations?: Maybe<string>;
                animationDirection?: Maybe<string>;
                hideParallax?: Maybe<string>;
                sliderAnimations?: Maybe<string>;
                zoomOnHover?: Maybe<string>;
                containerMaxWidth?: Maybe<string>;
                headingsNoTranslate?: Maybe<boolean>;
                componentBackgroundImage?: Maybe<{
                  __typename?: 'MediaItem';
                  sourceUrl?: Maybe<string>;
                }>;
                contentBackgroundImage?: Maybe<{
                  __typename?: 'MediaItem';
                  sourceUrl?: Maybe<string>;
                }>;
                paddingTop?: Maybe<{
                  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy_TwoTwoMediaCopySettings_PaddingTop';
                  paddingTopDesktop?: Maybe<string>;
                  paddingTopTablet?: Maybe<string>;
                  paddingTopMobile?: Maybe<string>;
                }>;
                paddingBottom?: Maybe<{
                  __typename?: 'Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy_TwoTwoMediaCopySettings_PaddingBottom';
                  paddingBottomDesktop?: Maybe<string>;
                  paddingBottomTablet?: Maybe<string>;
                  paddingBottomMobile?: Maybe<string>;
                }>;
              }>;
            }
          | {
              __typename: 'Page_Acfflexible_FlexibleComponents_VideoMediaCopy';
              copy?: Maybe<string>;
              fieldGroupName?: Maybe<string>;
              videoMediaUrl?: Maybe<string>;
              videoTitle?: Maybe<string>;
              heading?: Maybe<string>;
              imageLarge?: Maybe<string>;
              imageLargeAutoPosition?: Maybe<boolean>;
              imageLargeCropHeight?: Maybe<string>;
              imageLargeCropType?: Maybe<string>;
              imageSmall?: Maybe<string>;
              imageLargeYPositionAdvanced?: Maybe<string>;
              imageLargeYPosition?: Maybe<string>;
              imageLargeXPositionAdvanced?: Maybe<string>;
              imageLargeXPosition?: Maybe<string>;
              imageLargeEnableCropping?: Maybe<boolean>;
              imageLargeCropWidth?: Maybe<string>;
              imageSmallYPositionAdvanced?: Maybe<string>;
              imageSmallYPosition?: Maybe<string>;
              imageSmallXPositionAdvanced?: Maybe<string>;
              imageSmallXPosition?: Maybe<string>;
              imageSmallEnableCropping?: Maybe<boolean>;
              imageSmallCropWidth?: Maybe<string>;
              imageSmallCropType?: Maybe<string>;
              imageSmallCropHeight?: Maybe<string>;
              imageSmallAutoPosition?: Maybe<boolean>;
              buttonsButtons?: Maybe<
                Array<
                  Maybe<{
                    __typename?: 'Page_Acfflexible_FlexibleComponents_VideoMediaCopy_buttonsButtons';
                    buttonStyle?: Maybe<string>;
                    fieldGroupName?: Maybe<string>;
                    link?: Maybe<{
                      __typename?: 'AcfLink';
                      target?: Maybe<string>;
                      title?: Maybe<string>;
                      url?: Maybe<string>;
                    }>;
                  }>
                >
              >;
              largeImage?: Maybe<{
                __typename?: 'MediaItem';
                altText?: Maybe<string>;
                sourceUrl?: Maybe<string>;
              }>;
              smallImage?: Maybe<{
                __typename?: 'MediaItem';
                altText?: Maybe<string>;
                sourceUrl?: Maybe<string>;
              }>;
              componentSettingsVideoMediaCopySettings?: Maybe<{
                __typename?: 'Page_Acfflexible_FlexibleComponents_VideoMediaCopy_ComponentSettingsVideoMediaCopySettings';
                fieldGroupName?: Maybe<string>;
                showHide?: Maybe<boolean>;
                componentBackgroundColor?: Maybe<string>;
                componentBackgroundSize?: Maybe<string>;
                componentBackgroundRepeat?: Maybe<string>;
                componentBackgroundPosition?: Maybe<string>;
                contentBackgroundColor?: Maybe<string>;
                contentBackgroundSize?: Maybe<string>;
                contentBackgroundRepeat?: Maybe<string>;
                contentBackgroundPosition?: Maybe<string>;
                contentBackgroundColorLayerTwo?: Maybe<string>;
                subtitleColor?: Maybe<string>;
                textAlignment?: Maybe<string>;
                textColor?: Maybe<string>;
                titleColor?: Maybe<string>;
                animationDirection?: Maybe<string>;
                hideAnimations?: Maybe<string>;
                hideParallax?: Maybe<string>;
                sliderAnimations?: Maybe<string>;
                zoomOnHover?: Maybe<string>;
                containerMaxWidth?: Maybe<string>;
                headingsNoTranslate?: Maybe<boolean>;
                componentBackgroundImage?: Maybe<{
                  __typename?: 'MediaItem';
                  sourceUrl?: Maybe<string>;
                }>;
                contentBackgroundImage?: Maybe<{
                  __typename?: 'MediaItem';
                  sourceUrl?: Maybe<string>;
                }>;
                paddingTop?: Maybe<{
                  __typename?: 'Page_Acfflexible_FlexibleComponents_VideoMediaCopy_ComponentSettingsVideoMediaCopySettings_PaddingTop';
                  paddingTopDesktop?: Maybe<string>;
                  paddingTopTablet?: Maybe<string>;
                  paddingTopMobile?: Maybe<string>;
                }>;
                paddingBottom?: Maybe<{
                  __typename?: 'Page_Acfflexible_FlexibleComponents_VideoMediaCopy_ComponentSettingsVideoMediaCopySettings_PaddingBottom';
                  paddingBottomDesktop?: Maybe<string>;
                  paddingBottomTablet?: Maybe<string>;
                  paddingBottomMobile?: Maybe<string>;
                }>;
              }>;
            }
          | {
              __typename: 'Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar';
              introCopy?: Maybe<string>;
              title?: Maybe<string>;
              componentSettings?: Maybe<{
                __typename?: 'Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar_ComponentSettings';
                backgroundPrimary?: Maybe<string>;
                backgroundSecondary?: Maybe<string>;
                backgroundTernary?: Maybe<string>;
                headerCopyAlign?: Maybe<string>;
                headerCopyColor?: Maybe<string>;
                headerTitleAlign?: Maybe<string>;
                headerTitleColor?: Maybe<string>;
                mainTextColor?: Maybe<string>;
                progressActive?: Maybe<string>;
                progressInactive?: Maybe<string>;
                radioButtonChecked?: Maybe<string>;
                radioButtonDisabled?: Maybe<string>;
                radioButtonUnchecked?: Maybe<string>;
                subheadingAlign?: Maybe<string>;
                subheadingColor?: Maybe<string>;
                emailAddress?: Maybe<string>;
                emailCopy?: Maybe<string>;
                emailTitle?: Maybe<string>;
                activeBackgroundColor?: Maybe<string>;
                activeTextColor?: Maybe<string>;
                inactiveBackgroundColor?: Maybe<string>;
                inactiveTextColor?: Maybe<string>;
                cellFreeGroup?: Maybe<{
                  __typename?: 'Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar_ComponentSettings_CellFreeGroup';
                  backgroundColor?: Maybe<string>;
                  cornerFlagColor?: Maybe<string>;
                  textColor?: Maybe<string>;
                }>;
                cellFullGroup?: Maybe<{
                  __typename?: 'Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar_ComponentSettings_CellFullGroup';
                  backgroundColor?: Maybe<string>;
                  cornerFlagColor?: Maybe<string>;
                  textColor?: Maybe<string>;
                }>;
                cellPartialGroup?: Maybe<{
                  __typename?: 'Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar_ComponentSettings_CellPartialGroup';
                  backgroundColor?: Maybe<string>;
                  cornerFlagColor?: Maybe<string>;
                  textColor?: Maybe<string>;
                }>;
                cellSelectedGroup?: Maybe<{
                  __typename?: 'Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar_ComponentSettings_CellSelectedGroup';
                  backgroundColor?: Maybe<string>;
                  cornerFlagColor?: Maybe<string>;
                  textColor?: Maybe<string>;
                }>;
                paddingBottom?: Maybe<{
                  __typename?: 'Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar_ComponentSettings_PaddingBottom';
                  paddingBottomDesktop?: Maybe<string>;
                  paddingBottomMobile?: Maybe<string>;
                  paddingBottomTablet?: Maybe<string>;
                }>;
                paddingTop?: Maybe<{
                  __typename?: 'Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar_ComponentSettings_PaddingTop';
                  paddingTopDesktop?: Maybe<string>;
                  paddingTopMobile?: Maybe<string>;
                  paddingTopTablet?: Maybe<string>;
                }>;
                cellDisabledGroup?: Maybe<{
                  __typename?: 'Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar_ComponentSettings_CellDisabledGroup';
                  backgroundColor?: Maybe<string>;
                  textColor?: Maybe<string>;
                  cornerFlagColor?: Maybe<string>;
                }>;
              }>;
            }
        >
      >
    >;
  }>;
};

export type AllWpPagesFragment = {
  __typename?: 'RootQuery';
  allWPPages?: Maybe<{
    __typename?: 'RootQueryToPageConnection';
    nodes?: Maybe<
      Array<
        Maybe<{
          __typename?: 'Page';
          uri?: Maybe<string>;
          title?: Maybe<string>;
          id: string;
          slug?: Maybe<string>;
          acfFlexible?: Maybe<{
            __typename?: 'Page_Acfflexible';
            flexibleComponents?: Maybe<
              Array<
                Maybe<
                  | {
                      __typename?: 'Page_Acfflexible_FlexibleComponents_Accordion';
                    }
                  | {
                      __typename?: 'Page_Acfflexible_FlexibleComponents_AddressBar';
                    }
                  | {
                      __typename?: 'Page_Acfflexible_FlexibleComponents_ComparisonTable';
                    }
                  | { __typename?: 'Page_Acfflexible_FlexibleComponents_Cvent' }
                  | {
                      __typename?: 'Page_Acfflexible_FlexibleComponents_EditorialSnippet';
                    }
                  | {
                      __typename?: 'Page_Acfflexible_FlexibleComponents_EventsCalendarRegularAndSpecialEventsGrid';
                    }
                  | {
                      __typename?: 'Page_Acfflexible_FlexibleComponents_EventsCalendarSpecialEventsTiles';
                    }
                  | {
                      __typename?: 'Page_Acfflexible_FlexibleComponents_FourStaggeredTiles';
                    }
                  | {
                      __typename?: 'Page_Acfflexible_FlexibleComponents_FullWidthImageContent';
                    }
                  | {
                      __typename?: 'Page_Acfflexible_FlexibleComponents_GallerySlider';
                    }
                  | {
                      __typename?: 'Page_Acfflexible_FlexibleComponents_HalfAndHalf';
                    }
                  | {
                      __typename?: 'Page_Acfflexible_FlexibleComponents_HotelAmenities';
                    }
                  | {
                      __typename?: 'Page_Acfflexible_FlexibleComponents_HotelPolicies';
                    }
                  | {
                      __typename?: 'Page_Acfflexible_FlexibleComponents_HotelTitle';
                    }
                  | {
                      __typename?: 'Page_Acfflexible_FlexibleComponents_HtmlMenu';
                    }
                  | {
                      __typename?: 'Page_Acfflexible_FlexibleComponents_IconBlock';
                    }
                  | {
                      __typename?: 'Page_Acfflexible_FlexibleComponents_ImageGallery';
                    }
                  | {
                      __typename?: 'Page_Acfflexible_FlexibleComponents_ImageGalleryWall';
                    }
                  | {
                      __typename?: 'Page_Acfflexible_FlexibleComponents_ImageReveal';
                    }
                  | {
                      __typename?: 'Page_Acfflexible_FlexibleComponents_InformationBar';
                    }
                  | {
                      __typename?: 'Page_Acfflexible_FlexibleComponents_Introduction';
                    }
                  | {
                      __typename?: 'Page_Acfflexible_FlexibleComponents_KeyInfoGrid';
                    }
                  | {
                      __typename?: 'Page_Acfflexible_FlexibleComponents_LocationGoogleMap';
                    }
                  | {
                      __typename?: 'Page_Acfflexible_FlexibleComponents_Masthead';
                      image?: Maybe<{
                        __typename?: 'MediaItem';
                        sourceUrl?: Maybe<string>;
                      }>;
                      imageCarousel?: Maybe<
                        Array<
                          Maybe<{
                            __typename?: 'MediaItem';
                            sourceUrl?: Maybe<string>;
                          }>
                        >
                      >;
                    }
                  | {
                      __typename?: 'Page_Acfflexible_FlexibleComponents_MediaAndCopyOverlay';
                    }
                  | {
                      __typename?: 'Page_Acfflexible_FlexibleComponents_MeetingCapacity';
                    }
                  | {
                      __typename?: 'Page_Acfflexible_FlexibleComponents_MeetingCapacityCharts';
                    }
                  | {
                      __typename?: 'Page_Acfflexible_FlexibleComponents_MeetingsGroup';
                    }
                  | {
                      __typename?: 'Page_Acfflexible_FlexibleComponents_MultiColumn';
                    }
                  | {
                      __typename?: 'Page_Acfflexible_FlexibleComponents_MultiColumnList';
                    }
                  | {
                      __typename?: 'Page_Acfflexible_FlexibleComponents_MultiItemCarousel';
                    }
                  | {
                      __typename?: 'Page_Acfflexible_FlexibleComponents_OffersCurated';
                    }
                  | {
                      __typename?: 'Page_Acfflexible_FlexibleComponents_Offset';
                    }
                  | {
                      __typename?: 'Page_Acfflexible_FlexibleComponents_OnPageMenus';
                    }
                  | {
                      __typename?: 'Page_Acfflexible_FlexibleComponents_PatchworkGrid';
                    }
                  | {
                      __typename?: 'Page_Acfflexible_FlexibleComponents_ResortMap';
                    }
                  | {
                      __typename?: 'Page_Acfflexible_FlexibleComponents_ResortMapV2';
                    }
                  | {
                      __typename?: 'Page_Acfflexible_FlexibleComponents_Restaurants';
                    }
                  | {
                      __typename?: 'Page_Acfflexible_FlexibleComponents_RetailFlyout';
                    }
                  | {
                      __typename?: 'Page_Acfflexible_FlexibleComponents_RetailRail';
                    }
                  | {
                      __typename?: 'Page_Acfflexible_FlexibleComponents_RoomTypes';
                    }
                  | {
                      __typename?: 'Page_Acfflexible_FlexibleComponents_RoomTypesLite';
                    }
                  | {
                      __typename?: 'Page_Acfflexible_FlexibleComponents_SingleItemSlider';
                    }
                  | {
                      __typename?: 'Page_Acfflexible_FlexibleComponents_SocialMediaFeed';
                    }
                  | {
                      __typename?: 'Page_Acfflexible_FlexibleComponents_Spacer';
                    }
                  | {
                      __typename?: 'Page_Acfflexible_FlexibleComponents_TwoColumnCompare';
                    }
                  | {
                      __typename?: 'Page_Acfflexible_FlexibleComponents_TwoColumnIconList';
                    }
                  | {
                      __typename?: 'Page_Acfflexible_FlexibleComponents_TwoOneMediaCopy';
                    }
                  | {
                      __typename?: 'Page_Acfflexible_FlexibleComponents_TwoTwoMediaCopy';
                    }
                  | {
                      __typename?: 'Page_Acfflexible_FlexibleComponents_VideoMediaCopy';
                    }
                  | {
                      __typename?: 'Page_Acfflexible_FlexibleComponents_WeddingsAvailabilityCalendar';
                    }
                >
              >
            >;
          }>;
          parent?: Maybe<{
            __typename?: 'HierarchicalContentNodeToParentContentNodeConnectionEdge';
            node?: Maybe<
              | {
                  __typename?: 'Activities';
                  uri?: Maybe<string>;
                  slug?: Maybe<string>;
                }
              | {
                  __typename?: 'MediaItem';
                  uri?: Maybe<string>;
                  slug?: Maybe<string>;
                }
              | {
                  __typename?: 'Page';
                  uri?: Maybe<string>;
                  slug?: Maybe<string>;
                }
              | {
                  __typename?: 'Media_block';
                  uri?: Maybe<string>;
                  slug?: Maybe<string>;
                }
              | {
                  __typename?: 'Html_menu';
                  uri?: Maybe<string>;
                  slug?: Maybe<string>;
                }
              | {
                  __typename?: 'Post';
                  uri?: Maybe<string>;
                  slug?: Maybe<string>;
                }
              | {
                  __typename?: 'Specialoffer';
                  uri?: Maybe<string>;
                  slug?: Maybe<string>;
                }
              | {
                  __typename?: 'Calendar';
                  uri?: Maybe<string>;
                  slug?: Maybe<string>;
                }
              | {
                  __typename?: 'Room';
                  uri?: Maybe<string>;
                  slug?: Maybe<string>;
                }
              | {
                  __typename?: 'Weddingbooking';
                  uri?: Maybe<string>;
                  slug?: Maybe<string>;
                }
              | {
                  __typename?: 'Recurringevent';
                  uri?: Maybe<string>;
                  slug?: Maybe<string>;
                }
            >;
          }>;
        }>
      >
    >;
  }>;
};

export type WeddingAvailabilityCalendarQueryVariables = Exact<{
  minTimestamp?: Maybe<Scalars['Float']>;
}>;

export type WeddingAvailabilityCalendarQuery = {
  __typename?: 'RootQuery';
  weddingbookings?: Maybe<{
    __typename?: 'RootQueryToWeddingbookingConnection';
    edges?: Maybe<
      Array<
        Maybe<{
          __typename?: 'RootQueryToWeddingbookingConnectionEdge';
          node?: Maybe<{
            __typename?: 'Weddingbooking';
            id: string;
            title?: Maybe<string>;
            weddingCalendar?: Maybe<{
              __typename?: 'Weddingbooking_Weddingcalendar';
              ceremonyTime?: Maybe<string>;
              date?: Maybe<string>;
              fieldGroupName?: Maybe<string>;
              receptionVenue?: Maybe<string>;
              status?: Maybe<string>;
              timestamp?: Maybe<string>;
            }>;
          }>;
        }>
      >
    >;
  }>;
  themeSettings?: Maybe<{
    __typename?: 'ThemeSettings';
    SettingsThemeSettings?: Maybe<{
      __typename?: 'ThemeSettings_Settingsthemesettings';
      venuesTimeSlots?: Maybe<
        Array<
          Maybe<{
            __typename?: 'ThemeSettings_Settingsthemesettings_venuesTimeSlots';
            timeSlot?: Maybe<string>;
          }>
        >
      >;
      weddingVenues?: Maybe<
        Array<
          Maybe<{
            __typename?: 'ThemeSettings_Settingsthemesettings_weddingVenues';
            venue?: Maybe<string>;
          }>
        >
      >;
    }>;
  }>;
};

export type RoomsPostsQueryVariables = Exact<{ [key: string]: never }>;

export type RoomsPostsQuery = {
  __typename?: 'RootQuery';
  rooms?: Maybe<{
    __typename?: 'RootQueryToRoomConnection';
    edges?: Maybe<
      Array<
        Maybe<{
          __typename?: 'RootQueryToRoomConnectionEdge';
          node?: Maybe<{
            __typename?: 'Room';
            title?: Maybe<string>;
            roomsContent?: Maybe<{
              __typename?: 'Room_Roomscontent';
              roomTypeCode?: Maybe<string>;
              view?: Maybe<string>;
              balconyDetail?: Maybe<string>;
              customView?: Maybe<string>;
              customBalcony?: Maybe<string>;
              squareFootage?: Maybe<number>;
              outdoorFeatures?: Maybe<Array<Maybe<string>>>;
              roomFeatures?: Maybe<Array<Maybe<string>>>;
              roomType?: Maybe<Array<Maybe<string>>>;
              bathroomAmenities?: Maybe<Array<Maybe<string>>>;
              locations?: Maybe<Array<Maybe<string>>>;
              iconWidth?: Maybe<number>;
              image?: Maybe<{
                __typename?: 'MediaItem';
                sourceUrl?: Maybe<string>;
                altText?: Maybe<string>;
              }>;
              icon?: Maybe<{
                __typename?: 'MediaItem';
                altText?: Maybe<string>;
                sourceUrl?: Maybe<string>;
              }>;
              carouselImages?: Maybe<
                Array<
                  Maybe<{
                    __typename?: 'Room_Roomscontent_carouselImages';
                    image?: Maybe<{
                      __typename?: 'MediaItem';
                      sourceUrl?: Maybe<string>;
                      altText?: Maybe<string>;
                      mediaDetails?: Maybe<{
                        __typename?: 'MediaDetails';
                        height?: Maybe<number>;
                        width?: Maybe<number>;
                      }>;
                    }>;
                  }>
                >
              >;
              showCustomFilter1?: Maybe<{
                __typename?: 'Room_Roomscontent_ShowCustomFilter1';
                customFilter1Select?: Maybe<string>;
                showCustomFilter1?: Maybe<boolean>;
              }>;
              showCustomFilter2?: Maybe<{
                __typename?: 'Room_Roomscontent_ShowCustomFilter2';
                customFilter2Select?: Maybe<string>;
                showCustomFilter2?: Maybe<boolean>;
              }>;
              balconyIconGroup?: Maybe<{
                __typename?: 'Room_Roomscontent_BalconyIconGroup';
                iconList?: Maybe<string>;
              }>;
              viewIconGroup?: Maybe<{
                __typename?: 'Room_Roomscontent_ViewIconGroup';
                iconList?: Maybe<string>;
              }>;
              squareFootageIconGroup?: Maybe<{
                __typename?: 'Room_Roomscontent_SquareFootageIconGroup';
                iconList?: Maybe<string>;
              }>;
            }>;
          }>;
        }>
      >
    >;
  }>;
};

export type HtmlMenusQueryVariables = Exact<{ [key: string]: never }>;

export type HtmlMenusQuery = {
  __typename?: 'RootQuery';
  htmlMenus?: Maybe<{
    __typename?: 'RootQueryToHtml_menuConnection';
    edges?: Maybe<
      Array<
        Maybe<{
          __typename?: 'RootQueryToHtml_menuConnectionEdge';
          node?: Maybe<{
            __typename?: 'Html_menu';
            id: string;
            title?: Maybe<string>;
            htmlMenuContent?: Maybe<{
              __typename?: 'Html_menu_Htmlmenucontent';
              menuRepeater?: Maybe<
                Array<
                  Maybe<{
                    __typename?: 'Html_menu_Htmlmenucontent_menuRepeater';
                    fieldGroupName?: Maybe<string>;
                    tabTitle?: Maybe<string>;
                    tabTitle_noTx?: Maybe<string>;
                    menuSections?: Maybe<
                      Array<
                        Maybe<{
                          __typename?: 'Html_menu_Htmlmenucontent_menuRepeater_menuSections';
                          fieldGroupName?: Maybe<string>;
                          itemDescriptionColour?: Maybe<string>;
                          itemDisclaimerColour?: Maybe<string>;
                          itemPriceColour?: Maybe<string>;
                          itemTitleColour?: Maybe<string>;
                          sectionAlignment?: Maybe<string>;
                          sectionDisclaimerColour?: Maybe<string>;
                          sectionBorderColour?: Maybe<string>;
                          sectionBackgroundColour?: Maybe<string>;
                          sectionDisclaimer?: Maybe<boolean>;
                          sectionDisclaimerLine1?: Maybe<string>;
                          sectionDisclaimerLine2?: Maybe<string>;
                          spotlightBorderColour?: Maybe<string>;
                          sectionLayout?: Maybe<string>;
                          sectionSubtitle?: Maybe<string>;
                          sectionTitle?: Maybe<string>;
                          sectionDisclaimerLine1_noTx?: Maybe<string>;
                          sectionDisclaimerLine2_noTx?: Maybe<string>;
                          sectionSubtitle_noTx?: Maybe<string>;
                          sectionTitle_noTx?: Maybe<string>;
                          sectionItems?: Maybe<
                            Array<
                              Maybe<{
                                __typename?: 'Html_menu_Htmlmenucontent_menuRepeater_menuSections_sectionItems';
                                fieldGroupName?: Maybe<string>;
                                itemDisclaimer?: Maybe<string>;
                                itemLine1?: Maybe<string>;
                                itemLine2?: Maybe<string>;
                                itemPrice?: Maybe<string>;
                                highlightItem?: Maybe<boolean>;
                                itemDisclaimer_noTx?: Maybe<string>;
                                itemLine1_noTx?: Maybe<string>;
                                itemLine2_noTx?: Maybe<string>;
                                itemPrice_noTx?: Maybe<string>;
                                itemPrices?: Maybe<
                                  Array<
                                    Maybe<{
                                      __typename?: 'Html_menu_Htmlmenucontent_menuRepeater_menuSections_sectionItems_itemPrices';
                                      itemPrice?: Maybe<string>;
                                      itemPrice_noTx?: Maybe<string>;
                                    }>
                                  >
                                >;
                              }>
                            >
                          >;
                        }>
                      >
                    >;
                  }>
                >
              >;
            }>;
          }>;
        }>
      >
    >;
  }>;
};

export type WordpressPageInfoQueryVariables = Exact<{
  id: Scalars['ID'];
  idType: PageIdType;
  asPreview?: Maybe<Scalars['Boolean']>;
  language?: Maybe<Scalars['String']>;
}>;

export type WordpressPageInfoQuery = {
  __typename?: 'RootQuery';
  allSettings?: Maybe<{
    __typename?: 'Settings';
    generalSettingsUrl?: Maybe<string>;
    generalSettingsTitle?: Maybe<string>;
    generalSettingsDescription?: Maybe<string>;
  }>;
  page?: Maybe<{ __typename?: 'Page' } & PageFieldsFragment>;
} & WpSettingsFragment &
  NavFragmentFragment &
  AllWpPagesFragment;
