import React from 'react';
const BrandTextConradLogo = ({
  className,
  fill,
}: {
  className?: string;
  fill?: string;
}) => {
  return (
    <svg
      className={className}
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 237.45 31.46"
    >
      <path
        fill={fill}
        d="M243.69 29c0 9.46-7.59 14.67-19.63 14.67h-10c2.08-.68 2.77-1.41 2.77-4.24V18.62c0-2.81-.14-3.46-2.77-4.38h11.65c10.17 0 18 4.78 18 14.71M226.08 40.2c7.08 0 12-4.21 12-11.13s-4.69-11.91-12.45-11.91h-4.43v20.22c0 2.09.64 2.82 2.55 2.82ZM158 21.44c0-5.7-5.65-7.18-9.77-7.18h-11c2.71.76 2.34 4.25 2.34 6.28v16.84c0 2 .21 5.41-2.8 6.24h10.16c-2.89-.83-2.91-4.19-2.91-6.24V17.61a10.65 10.65 0 0 1 3-.39c3.51 0 5.85 2.26 5.85 5.05 0 3.47-2.93 5.29-6.11 5.91L159.9 43.6h6.71c-2.67-1.58-5.45-4.89-8.13-8.09l-6-6.94a7 7 0 0 0 5.52-7.13M92.28 13v26.31c0 2.9-.75 3.63-2.65 4.33h10c-2.61-.92-3-1.43-3-4.34V22.61l23.88 21.61V18.77c0-3.26.14-3.61 2.35-4.6h-9.3c2.36.78 2.7 1.27 2.7 4.49v16Zm-29.56 0c9.28 0 15.61 6.23 15.61 15.27a15.65 15.65 0 1 1-31.29 0C47 19.23 53.42 13 62.72 13m-.2 27.54c6.18 0 10.62-5 10.62-12.23 0-7.36-4.08-12.14-10.26-12.14s-10.57 5.08-10.57 12 4.45 12.38 10.21 12.38M24 16c6.8 0 9 3.93 9 3.93v-7.17a1.82 1.82 0 0 1-1.76 1 80.22 80.22 0 0 0-8.24-.7c-9.76 0-16.78 6.47-16.78 15.38C6.25 37 12.62 44 23.08 44c3.42 0 5.63-.42 10.46-1.77l.58-6.23a13 13 0 0 1-10.21 4.53C16.51 40.55 12 35.65 12 28.2S16.68 16 24 16m165.4 5.52-7.74 18.75a4.15 4.15 0 0 0-.43 1.55c0 .79.64 1.32 1.94 1.82h-9.93c1.92-.83 2.45-1.27 3.19-2.83l13-27.86 12.94 27.86c.72 1.55 1.28 2 3.2 2.82h-10c1.34-.49 1.91-1 1.91-1.81a3.27 3.27 0 0 0-.42-1.55Z"
        transform="translate(-6.25 -12.76)"
      />
    </svg>
  );
};

export default BrandTextConradLogo;
