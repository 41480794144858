// actually belongs to the demo site - hilton in a box logo
import dynamic from 'next/dynamic';

const HotelLogo = dynamic<{ className?: string; fillColor?: string }>(() =>
  import('./eywqqqq/hotel-logo').then((i) => i.HotelLogo)
);

export const hotelLogoHeader = <HotelLogo className="antialiased w-40 h-24" />;

export const hotelLogoFooter = (
  <HotelLogo fillColor="#ffffff" className="antialiased w-40 h-24" />
);
