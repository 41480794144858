// actually belongs to the demo site - hilton in a box logo
import dynamic from 'next/dynamic';

const HotelLogo = dynamic<{ className: string; fillColor: string }>(() =>
  import('./quihihf/hotel-logo').then((i) => i.HotelLogo)
);

export const hotelLogoHeader = (
  <HotelLogo
    className="fill-current text-bg antialiased w-40 h-20"
    fillColor="#ffffff"
  />
);

export const hotelLogoFooter = (
  <HotelLogo
    className="fill-current text-bg antialiased w-40 h-20"
    fillColor="#ffffff"
  />
);
