import dynamic from 'next/dynamic';

const HotelLogo = dynamic<{ className: string }>(() =>
  import('./vcehihi/hotel-logo').then((i) => i.HotelLogo)
);

export const hotelLogoHeader = (
  <HotelLogo className="fill-current text-current antialiased w-28 sm:w-32" />
);

export const hotelLogoFooter = (
  <HotelLogo className="fill-current text-bg antialiased w-24 md:w-28 mr-2" />
);
