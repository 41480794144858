import React from 'react';
export function UserInfo({
  className,
  fillColor,
}: {
  className?: string;
  fillColor?: string;
}) {
  return (
    <svg className={className} viewBox="0 0 24 24" style={{ fill: fillColor }}>
      <g data-name="Bounding Box">
        <path style={{ fill: 'none' }} d="M0,0V24H24V0Z" />
      </g>
      <g data-name="Icons Outlined">
        <g>
          <path d="M18,5.625H6A2.3777,2.3777,0,0,0,3.625,8v8A2.3777,2.3777,0,0,0,6,18.375H18A2.3777,2.3777,0,0,0,20.375,16V8A2.3777,2.3777,0,0,0,18,5.625ZM19.625,16A1.6262,1.6262,0,0,1,18,17.625H6A1.6262,1.6262,0,0,1,4.375,16V8A1.6262,1.6262,0,0,1,6,6.375H18A1.6262,1.6262,0,0,1,19.625,8Z" />
          <path d="M17,9.625H14a.375.375,0,0,0,0,.75h3a.375.375,0,0,0,0-.75Z" />
          <path d="M17,13.625H14a.375.375,0,0,0,0,.75h3a.375.375,0,0,0,0-.75Z" />
          <path d="M17,11.625H14a.375.375,0,0,0,0,.75h3a.375.375,0,0,0,0-.75Z" />
          <path d="M10.7516,12.3528a2.0961,2.0961,0,0,0,.79-1.6389,2.0425,2.0425,0,1,0-4.084,0,2.0962,2.0962,0,0,0,.7916,1.64A2.922,2.922,0,0,0,6.625,15,.346.346,0,0,0,7,15.3389a.4068.4068,0,0,0,.375-.41,2.125,2.125,0,0,1,4.25,0V15a.346.346,0,0,0,.375.3389.4068.4068,0,0,0,.375-.41A2.87,2.87,0,0,0,10.7516,12.3528ZM9.5,12.0537a1.34,1.34,0,1,1,1.292-1.34A1.3175,1.3175,0,0,1,9.5,12.0537Z" />
        </g>
      </g>
    </svg>
  );
}
