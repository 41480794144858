import dynamic from 'next/dynamic';

const HotelLogo = dynamic<{ className: string; fillColor: string }>(() =>
  import('./rktmidi/hotel-logo').then((i) => i.HotelLogo)
);

export const hotelLogoHeader = (
  <HotelLogo className="text-bg antialiased w-32 sm:w-40" fillColor="#09194E" />
);

export const hotelLogoFooter = (
  <HotelLogo className="text-bg antialiased w-32 sm:w-40" fillColor="#FFFFFF" />
);
