import { ChevronRight } from '@curated-property/icons';
import { useContext } from 'react';
import { WACContext, WACStages } from '.';

export const WACBreadcrumbs = function ({
  activeColor,
  inactiveColor,
}: {
  activeColor?: string;
  inactiveColor?: string;
}) {
  const { stage, setStage } = useContext(WACContext);
  return (
    <div
      data-testid="WACbreadcrumbs"
      className="flex flex-wrap text-2xl align-center justify-center leading-0 mb-16"
      style={{
        color: inactiveColor,
      }}
    >
      <button
        onClick={() => setStage?.(WACStages.DATE)}
        disabled={stage === WACStages.DATE}
        style={{
          color: activeColor,
        }}
      >
        Dates
      </button>
      <ChevronRight
        className="w-2 h-8 mx-4"
        style={{
          color: activeColor,
        }}
      />
      <button
        onClick={() => setStage?.(WACStages.VENUE)}
        disabled={stage === WACStages.DATE || stage === WACStages.VENUE}
        style={{
          color:
            stage === WACStages.VENUE || stage === WACStages.SEND_EMAIL
              ? activeColor
              : undefined,
        }}
      >
        Venues &amp; Times
      </button>
      <ChevronRight
        className="w-2 h-8 mx-4"
        style={{
          color: activeColor,
        }}
      />
      <div
        style={{
          color: stage === WACStages.SEND_EMAIL ? activeColor : undefined,
        }}
      >
        Send Email
      </div>
    </div>
  );
};
