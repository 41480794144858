import React, { ReactChild } from 'react';
import { iconmapper } from './functions/helper';
import {
  WrappedSubtitle,
  appliedCloudinaryParams,
} from '@curated-property/utils';
import { Close } from '@curated-property/icon-list';
import cx from 'classnames';
import { sanitize } from '@curated-property/utils';
import {
  GIS_Array,
  GIS_TextAlignment,
  GIS_Padder,
  StyleObject,
} from './functions/global-instance-styles';
import { HandleWYSIWYGContent } from './functions/helper';

type IconKeyType = keyof typeof iconmapper;

export interface TwoColumnIconListProps {
  title?: string;
  subtitle?: string;
  repeater?: {
    iconList?: IconKeyType | string;
    iconText?: string;
    iconDescription?: string;
  }[];
  globalStyles?: StyleObject;
  instanceStyles?: StyleObject;
}

export function TwoColumnIconList({
  title,
  subtitle,
  repeater,
  globalStyles,
  instanceStyles,
}: TwoColumnIconListProps) {
  const inlineStyles = GIS_Array(globalStyles, instanceStyles);
  const textAlignment = GIS_TextAlignment(inlineStyles?.textAlignment);
  const paddingStyles = GIS_Padder(
    inlineStyles?.paddingTop,
    inlineStyles?.paddingBottom
  );

  const iconMap = iconmapper();

  return (
    <div
      data-testid="twoColIconList"
      className={inlineStyles?.showHide ? 'hidden' : ''}
    >
      <div
        className={cx('two-column-icon-list', paddingStyles)}
        style={{
          backgroundImage: inlineStyles?.componentBackgroundImage
            ? `url(${appliedCloudinaryParams(
                inlineStyles?.componentBackgroundImage,
                inlineStyles?.componentBackgroundRepeat
              )})`
            : undefined,
          backgroundColor: inlineStyles?.componentBackgroundColor || undefined,
          backgroundSize: inlineStyles?.componentBackgroundSize || 'cover',
          backgroundRepeat:
            inlineStyles?.componentBackgroundRepeat || 'no-repeat',
          backgroundPosition:
            inlineStyles?.componentBackgroundPosition || 'left center',
        }}
      >
        <div
          className="container"
          style={{
            backgroundImage: inlineStyles?.contentBackgroundImage
              ? `url(${appliedCloudinaryParams(
                  inlineStyles?.contentBackgroundImage,
                  inlineStyles?.contentBackgroundRepeat
                )})`
              : undefined,
            backgroundColor: inlineStyles?.contentBackgroundColor || undefined,
            backgroundSize: inlineStyles?.contentBackgroundSize || 'cover',
            backgroundRepeat:
              inlineStyles?.contentBackgroundRepeat || 'no-repeat',
            backgroundPosition:
              inlineStyles?.contentBackgroundPosition || 'left center',
          }}
        >
          <div
            className={`md:p-8 p-0 lg:mx-12 md:mx-4 mx-0 text-${textAlignment}`}
          >
            {title && (
              <h2
                className="font-headline text-2xl md:text-4xl font-black leading-none md:mb-6 mb-4"
                style={{ color: inlineStyles?.titleColor }}
                dangerouslySetInnerHTML={{
                  __html: sanitize(title || ''),
                }}
              ></h2>
            )}
            {subtitle ? (
              <WrappedSubtitle>
                <div
                  className={cx('my-4 text-lg')}
                  style={{ color: inlineStyles?.subtitleColor }}
                  dangerouslySetInnerHTML={{
                    __html: sanitize(subtitle || ''),
                  }}
                ></div>
              </WrappedSubtitle>
            ) : (
              ''
            )}
            <div className="md:flex md:flex-wrap flex-none lg:mx-6 mx-0">
              {repeater?.map((icon, key) => {
                const Icon =
                  iconMap[(icon?.iconList as keyof typeof iconMap) || ''];

                return (
                  <TwoColumnBlockItem
                    key={key}
                    description={icon?.iconDescription}
                    iconSymbol={
                      Icon ? (
                        <Icon
                          className="lg:w-16 lg:h-16 h-12 w-12 "
                          fillColor={inlineStyles?.twoColumnIconColour}
                        />
                      ) : (
                        <Close
                          className="lg:w-16 lg:h-16 h-12 w-12 "
                          fillColor={inlineStyles?.twoColumnIconColour}
                        />
                      )
                    }
                    iconText={icon?.iconText}
                    labelColor={inlineStyles?.twoColumnLabelColour}
                    textAlignment={textAlignment}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

interface IconProps {
  iconSymbol?: ReactChild;
  iconText?: string;
  description?: string;
  labelColor?: string;
  textAlignment?: string;
}

function TwoColumnBlockItem({
  iconSymbol,
  description,
  labelColor,
  textAlignment,
}: IconProps) {
  switch (textAlignment) {
    case 'left':
      textAlignment = 'start';
      break;
    case 'center':
      textAlignment = 'center';
      break;
    case 'right':
      textAlignment = 'end';
      break;
    default:
      textAlignment = 'start';
  }

  return (
    <div
      className="md:w-6/12 flex-shrink-0 w-full flex"
      data-testid="block-item"
    >
      <div
        className={`justify-${textAlignment} w-full flex flex-row items-center text-left md:mr-8 lg:odd:ml-0 lg:odd:mr-0 mx-0 py-8`}
      >
        {!iconSymbol ? (
          ''
        ) : (
          <div className="flex items-center justify-center pr-4">
            {iconSymbol}
          </div>
        )}

        <div
          className="flex items-center text-lg"
          style={{ color: labelColor }}
          dangerouslySetInnerHTML={{
            __html: HandleWYSIWYGContent(description, labelColor),
          }}
        ></div>
      </div>
    </div>
  );
}
