import React from 'react';

export function YouTube({
  className,
  fillColor,
}: {
  className?: string;
  fillColor?: string;
}) {
  return (
    <svg
      className={className}
      fill={fillColor}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="m12 18.1c-.207 0-5.084-.004-6.38-.351-.86-.229-1.538-.907-1.768-1.769-.348-1.296-.352-3.871-.352-3.98s.004-2.685.352-3.981c.23-.86.908-1.538 1.769-1.768 1.296-.347 6.173-.351 6.38-.351s5.084.004 6.38.351c.86.229 1.538.907 1.769 1.768.347 1.296.351 3.871.351 3.98s-.004 2.685-.351 3.98c-.231.861-.909 1.539-1.77 1.769-1.296.347-6.173.351-6.38.351zm0-11.199c-1.374 0-5.151.057-6.122.317-.517.138-.923.544-1.061 1.06-.313 1.17-.317 3.697-.317 3.723s.004 2.553.317 3.722c.138.517.544.923 1.061 1.061.971.261 4.749.317 6.122.317s5.15-.057 6.122-.317c.516-.138.922-.544 1.062-1.061.312-1.169.316-3.696.316-3.722s-.004-2.553-.317-3.723c-.139-.517-.545-.922-1.061-1.06-.972-.261-4.749-.317-6.122-.317zm-1.6 8c-.086 0-.173-.022-.25-.067-.155-.089-.25-.254-.25-.433v-4.801c0-.179.095-.344.25-.433.154-.09.346-.09.5 0l4.157 2.4c.154.089.25.254.25.433s-.096.344-.25.433l-4.157 2.4c-.077.045-.164.067-.25.067zm.5-4.435v3.068l2.657-1.534z" />
    </svg>
  );
}
