import dynamic from 'next/dynamic';

const HotelLogo = dynamic<{ className: string; fillColor?: string }>(() =>
  import('./pvrpahh/hotel-logo').then((i) => i.HotelLogo)
);

export const hotelLogoHeader = (
  <HotelLogo className="fill-bg antialiased w-48 h-24" />
);

export const hotelLogoFooter = (
  <HotelLogo className="fill-bg antialiased w-48 h-24" />
);
