import dynamic from 'next/dynamic';

const HotelLogo = dynamic<{ className?: string; fillColor?: string }>(() =>
  import('./sealxol/hotel-logo').then((i) => i.HotelLogo)
);

const HotelLogoLXR = dynamic<{ className?: string; fillColor?: string }>(() =>
  import('./sealxol/hotel-logo-lxr').then((i) => i.HotelLogoLXR)
);
export const hotelLogoHeader = (
  <HotelLogoLXR
    className="antialiased w-32 xl:w-[19rem] py-4"
    fillColor="#000"
  />
);

export const hotelLogoFooter = (
  <HotelLogo className="antialiased w-32 md:w-56 px-4" fillColor="#FFF" />
);
