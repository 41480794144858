import React from 'react';
export function SpeechBubble({
  className,
  fillColor,
}: {
  className?: string;
  fillColor?: string;
}) {
  return (
    <svg
      className={className}
      fill={fillColor}
      width="29"
      height="20"
      viewBox="0 0 29 30"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.7987 0.838277C6.00966 1.77628 1.33367 6.43128 0.353667 12.2133C-0.269333 15.8743 0.549667 19.3183 2.31367 22.1043C2.67767 22.6783 2.82467 23.3573 2.65667 24.0083L1.48067 28.7263C1.32667 29.3563 1.97067 29.8813 2.55867 29.6013L6.73066 27.5783C7.37466 27.2703 8.10966 27.2843 8.76066 27.5643C11.1547 28.5653 13.8847 28.9293 16.7407 28.4043C22.4807 27.3473 27.0587 22.6643 27.9547 16.8963C29.4527 7.38327 21.3327 -0.708723 11.7987 0.838277ZM8.30566 16.8963C7.17166 16.8963 6.25466 15.9793 6.25466 14.8453C6.25466 13.7113 7.17166 12.7943 8.30566 12.7943C9.43966 12.7943 10.3567 13.7113 10.3567 14.8453C10.3567 15.9793 9.43966 16.8963 8.30566 16.8963ZM14.1437 16.8963C13.0097 16.8963 12.0927 15.9793 12.0927 14.8453C12.0927 13.7113 13.0097 12.7943 14.1437 12.7943C15.2777 12.7943 16.1947 13.7113 16.1947 14.8453C16.1947 15.9793 15.2777 16.8963 14.1437 16.8963ZM19.9817 16.8963C18.8477 16.8963 17.9307 15.9793 17.9307 14.8453C17.9307 13.7113 18.8477 12.7943 19.9817 12.7943C21.1157 12.7943 22.0327 13.7113 22.0327 14.8453C22.0327 15.9793 21.1157 16.8963 19.9817 16.8963Z"
        fill={fillColor}
      />
    </svg>
  );
}
