import React from 'react';

export function CircleArrowLeft({
  props,
  bgColor,
  arrowColor,
}: {
  props?: React.SVGAttributes<SVGElement>;
  bgColor?: string;
  arrowColor?: string;
}) {
  return (
    <svg
      width={36}
      height={36}
      viewBox="0 0 36 36"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <circle
          fillOpacity={0.7}
          fill={bgColor ? bgColor : '#000'}
          cx={18}
          cy={18}
          r={18}
        />
        <path
          d="M21.107 9.614l-7.071 8.6 7.071 8.6"
          stroke={arrowColor ? arrowColor : '#fff'}
          strokeLinecap="round"
          strokeWidth={4}
        />
      </g>
    </svg>
  );
}
