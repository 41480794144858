import React, { useContext } from 'react';
import { EventCalendarDaysOfWeekRow } from '../event-calendar-days-of-week-row';
import { EventCalendarInfoContext } from '../event-calendar-info-context';

interface EventCalendarDatePickerProps {
  dateList?: any;
}

export default function EventCalendarDatePicker({
  dateList,
}: EventCalendarDatePickerProps) {
  const hotelInfoContext = useContext(EventCalendarInfoContext);

  return (
    <table
      data-testid="calendar-range-picker"
      aria-hidden="true"
      className="w-full sm:w-2/3 mx-auto table-fixed"
      style={{
        backgroundColor:
          hotelInfoContext?.inlineStyles?.eventsCalendarDatePickerBgColor,
      }}
    >
      <EventCalendarDaysOfWeekRow dayDisplayType="abbr" />
      <tbody>
        {dateList?.map((i, e) => {
          return i;
        })}
      </tbody>
    </table>
  );
}
