import { FooterProps } from '@curated-property/shared-components';
import dynamic from 'next/dynamic';

import { ForbesFiveStarLogoTwentyTwentyFour } from '@curated-property/icons';

const SharedFooter = dynamic<FooterProps>(() =>
  import('@curated-property/shared-components').then((f) => f.Footer)
);

export const Footer = (props: FooterProps) => {
  return (
    <SharedFooter
      {...props}
      footerNavOverride={
        <div className="w-28 md:w-32 mx-auto mt-8 sm:mt-6 sm:mb-8 xl:my-0">
          <ForbesFiveStarLogoTwentyTwentyFour fillColor="#fff" />
        </div>
      }
    />
  );
};
