export const dayKeys = [
  'sundaySchedule',
  'mondaySchedule',
  'tuesdaySchedule',
  'wednesdaySchedule',
  'thursdaySchedule',
  'fridaySchedule',
  'saturdaySchedule',
];

export const dayNameArray = [
  'sunday',
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
];

export const months = {
  Jan: '01',
  Feb: '02',
  Mar: '03',
  Apr: '04',
  May: '05',
  Jun: '06',
  Jul: '07',
  Aug: '08',
  Sep: '09',
  Oct: '10',
  Nov: '11',
  Dec: '12',
};

// Default Categories for events
export const categoryArr = ['Attractions', 'Entertainment', 'Activities'];

export const categoryDefault = 'Attractions';

export const eventDefaultDurationInHours = 2;
