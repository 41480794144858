/* Vogue Hotel Montreal Downtown – yulmqqq */

export const AdditionalHead = () => {
  return (
    <>
      <link rel="stylesheet" href="https://use.typekit.net/auj7wux.css"></link>
      <style>
        {`
                  :root {
                    --font-headline: transat, sans-serif; !important;
                    --font-button: transat, sans-serif !important; 
                  }
  
                  
                  h1,h2,h3,
                  h1 p,
                  h2 p,
                  h3 p,
                  h1 p span,
                  h2 p span,
                  h3 p span,
                  .font-headline,.font-black {
                    font-family: transat, sans-serif !important;
                    font-style: normal;
                    font-weight: 700 !important;
                  }
    
    
                  .btn, button,
                  .booking dd idgetButton {
                    font-family: transat, sans-serif !important; 
                    font-style: normal;
                    font-weight: 700 !important;
                  }


                [data-element-id="honors-wrapper"],       
                [data-element-id="honors-wrapper"] button,       
                ul[data-element-id="nav-desktop"] li > div > button,
                [data-element-id="honors-header-login"],
                [data-element-id="honors-header-login"] button
                [data-element-id="language-wrapper"],
                [data-element-id="language-wrapper"] button {
                    font-family:"Proxima Nova",sans-serif !important;
                    font-weight:400 !important;
                }

                [data-element-id="breadcrumb-component"],
                [data-element-id="breadcrumb-component"] li {
                    font-family:"Proxima Nova",sans-serif !important;
                                    
                }
        
                `}
      </style>
    </>
  );
};
