import React, { useState, useEffect, ReactNode } from 'react';
import { AnchorLink, ACFAnchorProps } from './global/anchor-link';
import {
  GIS_merge,
  GIS_TextAlignment,
  GIS_FontSizer,
  GIS_Padder,
  StyleObject,
} from './functions/global-instance-styles';
import { HoverTile } from './includes/hover-tile';
import { CopyTable } from './copy-table';
import { HeadingStyle } from './includes/heading-style';
import contentStyles from './css/contentStyle.module.css';
import { SliderControlsWrapper } from './global/slider-controls-wrapper';
import cx from 'classnames';
import { HandleWYSIWYGContent, HandleAnimations } from './functions/helper';
import { KeenSliderInstance, useKeenSlider } from 'keen-slider/react';
import {
  makeRandomInt,
  CropProps,
  CroppedImage,
  customLoader,
  appliedCloudinaryParams,
} from '@curated-property/utils';
import { WordpressPageInfoQuery } from './generated/wp';

export function multiItemCarouselPropMapper(
  data: Props & {
    copy?: string;
    multiItemCarouselComponentSettings?: StyleObject;
  },
  globalData: NonNullable<
    NonNullable<
      WordpressPageInfoQuery['componentStyles']
    >['globalComponentSettings']
  >['globalComponentSettings']
) {
  return {
    header: data.header,
    headingValue: data.headingValue,
    headingOnActiveState: data.headingOnActiveState,
    titleAlignment: data.titleAlignment,
    buttons: data.buttons,
    headerCopy: data.copy,
    repeater: data.repeater,
    instanceStyles: data?.multiItemCarouselComponentSettings,
    globalStyles: globalData?.multiItemCarouselComponentSettings,
    columnCount: data?.columnCount,
    tileLayout: data?.tileLayout,
  };
}
interface RepeaterProps {
  title?: string;
  title_noTx?: string;
  copy?: string;
  tileBackgroundLayer2?: string;
  tableData?: {
    title?: string;
    text?: string;
  }[];
  image?: {
    sourceUrl?: string;
    altText?: string;
  };
  logo?: {
    sourceUrl?: string;
    altText?: string;
  };
  buttons?: Array<ACFAnchorProps>;
  showHide?: boolean;
}

interface MultiItemCarouselStyles extends StyleObject {
  tileTitleBackgroundColour?: string;
  tileTitleAlignment?: string;
  tileTitleLayout?: string;
}

interface Props {
  header?: string;
  headingValue?: string;
  headingOnActiveState?: string;
  titleAlignment?: string;
  headerCopy?: string;
  buttons?: Array<ACFAnchorProps>;
  repeater?: Array<CombinedProps>;
  instanceStyles?: MultiItemCarouselStyles;
  globalStyles?: MultiItemCarouselStyles;
  columnCount?: number;
  tileLayout?: string;
}

interface CombinedProps extends CropProps, RepeaterProps {}

export function MultiItemCarousel({
  header,
  headingValue,
  headingOnActiveState,
  titleAlignment,
  headerCopy,
  buttons,
  repeater,
  instanceStyles,
  globalStyles,
  columnCount,
  tileLayout,
}: Props) {
  repeater = repeater?.filter((item) => !item?.showHide);
  const inlineStyles = GIS_merge(globalStyles, instanceStyles);
  const textAlignment = GIS_TextAlignment(inlineStyles?.textAlignment);
  const titleSize = GIS_FontSizer(inlineStyles?.titleSize);
  const paddingStyles = GIS_Padder(
    inlineStyles?.paddingTop,
    inlineStyles?.paddingBottom
  );
  const imageSizeArray = {
    width: 640,
    height: 819,
  };

  const headingClassesOnActiveState =
    headingOnActiveState === 'hide' ? 'hidden' : '';
  const randomBatchStr = makeRandomInt();
  const tileHeadingValue =
    headingValue === 'h3' ? 'h4' : headingValue === 'h4' ? 'h5' : undefined;
  const hasNoHover = tileLayout?.includes('NoHover');
  const isHorizontal = tileLayout === 'horizontalNoHover';
  const hoverTileContentBackground = inlineStyles?.contentBackgroundImage
    ?.sourceUrl
    ? `${inlineStyles?.contentBackgroundPosition || 'center'} / ${
        inlineStyles?.contentBackgroundSize || 'cover'
      }  url(${appliedCloudinaryParams(
        inlineStyles?.contentBackgroundImage?.sourceUrl,
        inlineStyles?.contentBackgroundRepeat
      )})  ${inlineStyles?.contentBackgroundRepeat || 'no-repeat'} `
    : hasNoHover
    ? inlineStyles?.contentBackgroundColor
    : inlineStyles?.contentBackgroundColor || 'var(--color-bg-primary)';

  let titleAlignmentClasses = '';
  switch (titleAlignment) {
    case 'center':
      titleAlignmentClasses = 'm-auto text-center';
      break;
    case 'right':
      titleAlignmentClasses = 'ml-auto text-right';
      break;
  }

  const animations = HandleAnimations({
    hideAnimation: inlineStyles?.hideAnimations !== 'show',
    start: inlineStyles?.animationDirection
      ? `lg:${inlineStyles?.animationDirection}-8`
      : titleAlignment === 'center'
      ? '-translate-y-12'
      : titleAlignment === 'right'
      ? 'lg:translate-x-8'
      : 'lg:-translate-x-8',
    delayOne: 'delay-300',
    delayTwo: 'delay-500',
    delayThree: 'delay-700',
  });

  const slides = repeater?.map((item, key) => {
    return (
      <div
        key={key}
        className={cx(
          `keen-slider__slide h-full ${
            isHorizontal ? 'flex flex-row w-full' : ''
          }`
        )}
      >
        {hasNoHover ? (
          <div className={isHorizontal ? 'w-1/2 relative' : ''}>
            <CroppedImage
              loader={() => {
                return customLoader({
                  src: item?.image?.sourceUrl || '',
                  width: isHorizontal ? undefined : 640,
                  height: isHorizontal ? undefined : 890,
                  crop: item?.enableCropping,
                  cropType: item?.cropType,
                  cropHeight: item?.cropHeight,
                  cropWidth: item?.cropWidth,
                  xPosition: item?.xPosition,
                  xPositionAdvanced: item?.xPositionAdvanced,
                  yPosition: item?.yPosition,
                  yPositionAdvanced: item?.yPositionAdvanced,
                  autoPosition: item?.autoPosition,
                });
              }}
              src={item?.image?.sourceUrl || ''}
              width={isHorizontal ? undefined : 640}
              height={isHorizontal ? undefined : 890}
              layout={isHorizontal ? 'fill' : undefined}
              objectFit={isHorizontal ? 'cover' : undefined}
              alt={item?.image?.altText}
            />
          </div>
        ) : (
          <HoverTile
            slideIdx={key}
            order={key + 200}
            key={key + 200 + parseInt(randomBatchStr)}
            batchNumber={randomBatchStr}
            title={
              inlineStyles?.tileTitleLayout === 'below'
                ? ''
                : inlineStyles?.headingsNoTranslate
                ? item?.title_noTx
                : item?.title
            }
            copy={item?.copy}
            tableData={item?.tableData}
            image={item?.image?.sourceUrl}
            imageAlt={item?.image?.altText}
            imageSizeArray={imageSizeArray}
            logo={item?.logo}
            tabInd={0}
            links={item?.buttons}
            headingValue={tileHeadingValue}
            headingClassesOnActiveState={headingClassesOnActiveState}
            cropping={{
              enableCropping: item?.enableCropping,
              cropType: item?.cropType,
              xPosition: item?.xPosition,
              xPositionAdvanced: item?.xPositionAdvanced,
              yPosition: item?.yPosition,
              yPositionAdvanced: item?.yPositionAdvanced,
              cropWidth: item?.cropWidth,
              cropHeight: item?.cropHeight,
              autoPosition: item?.autoPosition,
            }}
            styleOptions={{
              background: hoverTileContentBackground,
              backgroundLayerTwo:
                item?.tileBackgroundLayer2 ||
                inlineStyles?.contentBackgroundColorLayerTwo ||
                '',
              flexAlign: inlineStyles?.textAlignment || 'center',
              textAlignment: textAlignment || 'center',
              titleColor: inlineStyles?.titleColor,
              textColor: inlineStyles?.textColor,
              titleSize: titleSize,
              headingStyle: inlineStyles?.headingStyle || 'h4',
              tileTitleAlignment: inlineStyles?.tileTitleAlignment,
              tileTitleLayout: inlineStyles?.tileTitleLayout,
              tileTitleBackgroundColour:
                inlineStyles?.tileTitleBackgroundColour,
              headingsNoTranslate: inlineStyles?.headingsNoTranslate,
            }}
          />
        )}
        {inlineStyles?.tileTitleLayout === 'below' || hasNoHover ? (
          <div
            className={cx('h-full', {
              'px-3 pb-3': hoverTileContentBackground && !isHorizontal,
              'w-1/2 p-6 z-1': isHorizontal,
            })}
            style={{
              background:
                inlineStyles?.tileTitleBackgroundColour ||
                hoverTileContentBackground,
            }}
          >
            {item?.title ? (
              <div
                className={`pt-4 -mt-1 flex justify-${inlineStyles?.tileTitleAlignment}`}
              >
                <HeadingStyle
                  text={item?.title}
                  type={headingValue ?? 'h3'}
                  textColorInline={inlineStyles?.titleColor}
                  className={titleSize}
                  styledAs={inlineStyles?.headingStyle || 'h4'}
                />
              </div>
            ) : null}
            {hasNoHover ? (
              <div className={!item?.title ? 'pt-4' : ''}>
                {item?.copy ? (
                  <p
                    className={cx(
                      'text-base xl:text-lg leading-relaxed py-2',
                      item?.tableData ? 'pb-4' : '',
                      contentStyles.listStyle,
                      contentStyles.paragraphStyle
                    )}
                    style={{ color: inlineStyles?.textColor }}
                  >
                    {item?.copy}
                  </p>
                ) : null}
                {item?.tableData ? (
                  <CopyTable
                    repeater={item?.tableData}
                    textColor={inlineStyles?.textColor}
                  />
                ) : null}
                {item?.buttons?.map((link, key) => {
                  return (
                    <AnchorLink
                      key={key}
                      url={link?.link?.url}
                      title={link?.link?.title}
                      target={link?.link?.target}
                      buttonStyle={link?.buttonStyle || ''}
                      className="-ml-0"
                    />
                  );
                })}
              </div>
            ) : null}
          </div>
        ) : null}
      </div>
    );
  });

  return (
    <section
      data-testid="multiItemCarouselSection"
      className={cx('overflow-hidden', paddingStyles)}
      style={{
        backgroundImage: inlineStyles?.componentBackgroundImage?.sourceUrl
          ? `url('${appliedCloudinaryParams(
              inlineStyles?.componentBackgroundImage?.sourceUrl,
              inlineStyles?.componentBackgroundRepeat
            )}')`
          : '',
        backgroundColor: inlineStyles?.componentBackgroundColor || undefined,
        backgroundSize: inlineStyles?.componentBackgroundSize || undefined,
        backgroundRepeat:
          inlineStyles?.componentBackgroundRepeat || 'no-repeat',
        backgroundPosition:
          inlineStyles?.componentBackgroundPosition || 'top left',
      }}
      data-element-id="multi-column-tile-carousel-wrapper"
    >
      <div
        ref={animations?.ref}
        className="container mx-auto [&_ul]:list-disc [&_ul]:list-outside [&_ul]:pl-3 [&_ul]:my-4 [&_ol]:list-decimal [&_ol]:list-outside [&_ol]:pl-5 [&_ol]:my-4"
      >
        {header && (
          <div className="mb-4" data-testid="headerContent">
            <div
              className={cx(`mb-10 ${titleAlignmentClasses}`, animations?.one)}
            >
              <HeadingStyle
                text={header}
                styledAs={'h2'}
                type={headingValue ?? 'h2'}
                className="mb-6 text-inverse"
                textColorInline={inlineStyles?.headerTitleColour}
              />
              {headerCopy && (
                <div
                  suppressHydrationWarning
                  className={cx(
                    `mb-6 text-lg w-full lg:w-3/4 ${titleAlignmentClasses}`,
                    animations?.two
                  )}
                  dangerouslySetInnerHTML={{
                    __html: HandleWYSIWYGContent(
                      headerCopy,
                      inlineStyles?.headerCopyColour
                    ),
                  }}
                  style={{ color: inlineStyles?.headerCopyColour }}
                ></div>
              )}
              {buttons && buttons[0].link ? (
                <div className="mt-0">
                  {buttons?.map((link, key) => {
                    return (
                      <AnchorLink
                        key={key}
                        url={link?.link?.url}
                        title={link?.link?.title}
                        target={link?.link?.target}
                        buttonStyle={link?.buttonStyle ?? 'primary'}
                      />
                    );
                  })}
                </div>
              ) : null}
            </div>
          </div>
        )}
        {repeater?.length ? (
          <div className={cx(animations?.three)}>
            <Carousel
              slidesData={repeater}
              slides={slides}
              columnCount={columnCount}
              totalSlides={repeater.length}
              isHorizontal={isHorizontal}
              inlineStyles={inlineStyles}
            />
          </div>
        ) : null}
      </div>
    </section>
  );
}

interface CarouselProps {
  slidesData?: RepeaterProps[];
  slides?: ReactNode;
  columnCount?: number;
  totalSlides?: number;
  isHorizontal?: boolean;
  inlineStyles?: any;
}

export const Carousel = ({
  slidesData,
  slides,
  columnCount,
  totalSlides,
  isHorizontal,
  inlineStyles,
}: CarouselProps) => {
  /*
    Switched to use keen slider which is quite nice - https://keen-slider.io/docs
  */
  const [activeSlide, setActiveSlide] = useState(0);
  const [rtl, setRtl] = useState<boolean>(false);
  const slideCountLogic =
    (totalSlides || 0) <= (columnCount || 0) ? false : true;
  const [sliderRef, instanceRef] = useKeenSlider(
    {
      breakpoints: {
        '(min-width: 640px)': {
          slides: {
            perView: isHorizontal ? 1 : 2,
            spacing: 10,
          },
        },
        '(min-width: 1024px)': {
          slides: {
            perView: isHorizontal ? 2 : 3,
            spacing: 15,
          },
        },
        '(min-width: 1280px)': {
          slides: {
            perView: isHorizontal ? 2 : columnCount || 3,
            spacing: 15,
          },
        },
      },
      slides: {
        perView: 1,
        spacing: 0,
      },
      // setting to false fixes the tile focus issue
      loop: slideCountLogic,
      mode: 'snap',
      rtl: rtl,
      slideChanged: (slider) => {
        setActiveSlide(slider?.track?.details?.rel);
      },
    },
    [KeyboardControls]
  );

  useEffect(() => {
    document?.querySelector('html')?.getAttribute('dir') === 'rtl'
      ? setRtl(true)
      : setRtl(false);
  }, []);

  return (
    <div>
      <SliderControlsWrapper
        next={(e) => {
          e.stopPropagation() || instanceRef?.current?.next();
        }}
        prev={(e) => {
          e.stopPropagation() || instanceRef?.current?.prev();
        }}
        current={activeSlide}
        count={totalSlides || 0}
        visibleSlides={columnCount}
        sectionHeading={slidesData?.[activeSlide]?.title}
        component="multiColumnTileCarousel"
        styleOptions={{
          primaryColour: inlineStyles?.uiPrimaryColour,
          secondaryColour: inlineStyles?.uiSecondaryColour,
        }}
      >
        <div className="keen-slider" ref={sliderRef}>
          {slides}
        </div>
      </SliderControlsWrapper>
    </div>
  );
};

const KeyboardControls = (slider: KeenSliderInstance) => {
  let wWidth: number = window?.innerWidth;
  let mouseDown = false;

  function windowSize() {
    wWidth = window?.innerWidth;
    return wWidth;
  }

  function tileFocus(e: Event) {
    const target = e?.target as HTMLElement;
    const idx = parseInt(target?.dataset?.slideIdx || '');
    if (wWidth > 640 && idx !== undefined) {
      slider.moveToIdx(idx);
    }
  }

  function arrowControls(e: KeyboardEvent) {
    switch (e.code) {
      default:
        break;
      case 'Left':
      case 'ArrowLeft':
        slider.prev();
        break;
      case 'Right':
      case 'ArrowRight':
        slider.next();
        break;
    }
  }

  slider?.on('created', () => {
    // When slide gets focussed, grab the ID of the tile and shift the slider along
    slider?.slides?.forEach((slide) => {
      slide.querySelector('.cp-hoverTile')?.addEventListener('focus', (e) => {
        if (!mouseDown) {
          tileFocus(e);
        }
      });
    });
    // Move prev / next with left / right KB buttons
    slider.container.addEventListener('keyup', arrowControls);
  });
  // window size on resize
  window.addEventListener('resize', windowSize);
  window.addEventListener('mousedown', () => {
    mouseDown = true;
  });

  window.addEventListener('mouseup', () => {
    mouseDown = false;
  });
};
