import React from 'react';
export function KidFriendly({
  className,
  fillColor,
}: {
  className?: string;
  fillColor?: string;
}) {
  return (
    <svg
      className={className}
      style={{ fill: fillColor }}
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 24 24"
    >
      <g>
        <g>
          <path style={{ fill: 'none' }} d="M0,0v24h24V0H0z" />
        </g>
        <g>
          <g>
            <path
              d="M9,7.375c1.0355,0,1.875-0.8395,1.875-1.875S10.0355,3.625,9,3.625S7.125,4.4645,7.125,5.5
            C7.1259,6.5351,7.9649,7.3741,9,7.375z M9,4.375c0.6213,0,1.125,0.5037,1.125,1.125S9.6213,6.625,9,6.625S7.875,6.1213,7.875,5.5
            C7.8759,4.879,8.379,4.3759,9,4.375z"
            />
            <path
              d="M15,14.875c1.0355,0,1.875-0.8395,1.875-1.875S16.0355,11.125,15,11.125S13.125,11.9645,13.125,13
            C13.1259,14.0351,13.9649,14.8741,15,14.875z M15,11.875c0.6213,0,1.125,0.5037,1.125,1.125S15.6213,14.125,15,14.125
            S13.875,13.6213,13.875,13C13.8759,12.379,14.379,11.8759,15,11.875z"
            />
            <path
              d="M15.9971,17.625c-0.2071,0.0001-0.3749,0.1679-0.375,0.375v2c0,0.2071,0.1679,0.375,0.375,0.375
            c0.2071,0,0.375-0.1679,0.375-0.375v-2C16.372,17.7929,16.2042,17.6251,15.9971,17.625z"
            />
            <path
              d="M13.9971,17.625c-0.2071,0.0001-0.3749,0.1679-0.375,0.375v2c0,0.2071,0.1679,0.375,0.375,0.375
            s0.375-0.1679,0.375-0.375v-2C14.372,17.7929,14.2042,17.6251,13.9971,17.625z"
            />
            <path
              d="M10,13.625C9.7929,13.6251,9.6251,13.7929,9.625,14v6c0,0.2071,0.1679,0.375,0.375,0.375s0.375-0.1679,0.375-0.375v-6
            C10.3749,13.7929,10.2071,13.6251,10,13.625z"
            />
            <path
              d="M8,13.625C7.7929,13.6251,7.6251,13.7929,7.625,14v6c0,0.2071,0.1679,0.375,0.375,0.375S8.375,20.2071,8.375,20v-6
            C8.3749,13.7929,8.2071,13.6251,8,13.625z"
            />
            <path
              d="M18,13.125c-0.2071,0.0001-0.3749,0.1679-0.375,0.375c0,1.4497-1.1753,2.625-2.625,2.625s-2.625-1.1753-2.625-2.625V10
            C12.3739,9.2411,11.7589,8.6261,11,8.625H7C6.2411,8.6261,5.6261,9.2411,5.625,10v5c0,0.2071,0.1679,0.375,0.375,0.375
            S6.375,15.2071,6.375,15v-5C6.3753,9.655,6.655,9.3753,7,9.375h4c0.345,0.0003,0.6247,0.28,0.625,0.625v3.5
            c0,1.864,1.511,3.375,3.375,3.375s3.375-1.511,3.375-3.375C18.3749,13.2929,18.2071,13.1251,18,13.125z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
