import dynamic from 'next/dynamic';

const HotelLogo = dynamic<{
  className?: string;
  fill1?: string;
  fill2?: string;
}>(() => import('./flldhqq/hotel-logo').then((i) => i.HotelLogo));

const BrandLogo = dynamic<{
  className?: string;
  fill1?: string;
  fill2?: string;
}>(() => import('./flldhqq/brand-logo').then((i) => i.BrandLogo));

export const hotelLogoHeader = (
  <HotelLogo
    className="fill-current text-bg antialiased w-48 h-20"
    fill2="#000000"
  />
);

export const hotelLogoFooter = (
  <BrandLogo
    fill1="#fff"
    fill2="#fff"
    className="fill-current text-bg antialiased w-40 h-20"
  />
);
