import React from 'react';
export function Airport({
  className,
  fillColor,
}: {
  className?: string;
  fillColor?: string;
}) {
  return (
    <svg
      className={className}
      style={{ fill: fillColor }}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 24 24"
    >
      <g>
        <g>
          <path style={{ fill: 'none' }} d="M0,0v24h24V0H0z" />
        </g>
        <g>
          <path
            d="M13.4689,13.0643l5.8094-5.8094c1.3418-1.3428,1.2969-2.6113,0.6875-3.2207s-1.8779-0.6524-3.2207,0.6875L10.94,10.5336
          L4.6123,6.918l0.5068-0.5069l7.1631,1.5918c0.2016,0.0476,0.4037-0.0772,0.4513-0.2788s-0.0772-0.4037-0.2788-0.4513
          c-0.0035-0.0008-0.0069-0.0016-0.0104-0.0023L5.0811,5.6338C4.9559,5.6063,4.8254,5.6442,4.7344,5.7344l-1,1
          C3.6523,6.8176,3.613,6.9339,3.6279,7.0498c0.0157,0.1156,0.0843,0.2174,0.1856,0.2754l6.5778,3.7578L7.69,13.7881l-0.5711,0.8566
          l0,0L4.695,13.8361l0.461-0.4609H7c0.2071,0,0.375-0.1679,0.375-0.375S7.2071,12.6252,7,12.6252H5
          c-0.0995-0.0002-0.1951,0.0392-0.2656,0.1094l-1,1c-0.0915,0.0922-0.1293,0.2249-0.1,0.3515
          c0.0297,0.1269,0.1233,0.2292,0.247,0.27l2.8059,0.9353l-1,1.5c-0.0409,0.0616-0.0627,0.134-0.0625,0.208
          c0.0008,0.7596,0.6164,1.3752,1.376,1.376c0.074,0.0002,0.1464-0.0216,0.208-0.0625l1.4993-1.0039l0.9364,2.8092
          c0.0408,0.1237,0.1431,0.2173,0.27,0.247c0.0281,0.0067,0.057,0.0101,0.0859,0.01c0.0995-0.0002,0.1949-0.0395,0.2656-0.1094l1-1
          c0.0701-0.0706,0.1093-0.1663,0.109-0.2658v-2c0-0.2071-0.1679-0.375-0.375-0.375S10.625,16.7929,10.625,17v1.8447l-0.4609,0.461
          l-0.8086-2.4239l-0.0026-0.0045l0.9166-0.6136l2.65-2.65l3.7551,6.573c0.058,0.1013,0.1598,0.1699,0.2754,0.1856
          c0.0166,0.0019,0.0333,0.0029,0.05,0.0029c0.0995-0.0002,0.1949-0.0395,0.2656-0.1094l1-1
          c0.0901-0.0911,0.1279-0.2216,0.1006-0.3467L16.73,11.5557c-0.0419-0.2029-0.2403-0.3334-0.4432-0.2915
          c-0.2029,0.0419-0.3334,0.2403-0.2915,0.4432c0.0007,0.0035,0.0015,0.007,0.0023,0.0104l1.5918,7.1631l-0.5069,0.5068
          L13.4689,13.0643z M9.7959,15.6855l-2.8965,1.9317C6.6346,17.5725,6.4271,17.3649,6.3828,17.1l1.8848-2.8379l9.0088-9.0088
          c0.9345-0.9365,1.7929-1.0537,2.1591-0.6884s0.2471,1.2246-0.6884,2.1591l-6.03,6.0367c-0.0053,0.0062-0.0122,0.0107-0.017,0.0171
          L9.7959,15.6855z"
          />
        </g>
      </g>
    </svg>
  );
}
