import React from 'react';
export function Subtract({
  className,
  fillColor,
  bold,
}: {
  className?: string;
  fillColor?: string;
  bold?: boolean;
}) {
  return (
    <svg className={className} viewBox="0 0 24 24" style={{ fill: fillColor }}>
      <g data-name="Bounding Box">
        <path style={{ fill: 'none' }} d="M0,0V24H24V0Z" />
      </g>
      <g data-name="Icons Outlined">
        <path
          strokeWidth={1}
          stroke={bold ? fillColor : undefined}
          d="M17,11.625V11.625,11.625H7a.375.375,0,0,0,0,.75h4.375.375,0,0,0,.75,0V12.375H17a.375.375,0,0,0,0-.75Z"
        />
      </g>
    </svg>
  );
}
