import React from 'react';
export function Caret({
  className,
  fillColor,
}: {
  className?: string;
  fillColor?: string;
}) {
  return (
    <svg viewBox="0 0 8 5" className={className}>
      <g stroke="none" strokeWidth="1" fill={fillColor} fillRule="evenodd">
        <g fill={fillColor}>
          <path d="M7.25195785,0 C7.58850223,0 7.84212988,0.127798063 7.94862098,0.350055564 C8.05592498,0.573106842 7.99333098,0.845372281 7.77791006,1.0977933 L7.77791006,1.0977933 L4.754701,4.64041911 C4.56041572,4.86823305 4.28483952,5 4.00032132,5 C3.71499022,5 3.43941402,4.86823305 3.24512874,4.64041911 L3.24512874,4.64041911 L0.221919677,1.0977933 C0.00731166591,0.845372281 -0.0560952464,0.573106842 0.051208759,0.350055564 C0.157699855,0.127798063 0.412140414,0 0.748684794,0 L0.748684794,0 Z"></path>
        </g>
      </g>
    </svg>
  );
}
