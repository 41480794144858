import React from 'react';
export function GardenView({
  className,
  fillColor,
}: {
  className?: string;
  fillColor?: string;
}) {
  return (
    <svg
      className={className}
      style={{ fill: fillColor }}
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 24 24"
    >
      <g>
        <g>
          <path style={{ fill: 'none' }} d="M0,0v24h24V0H0z" />
        </g>
        <g>
          <g>
            <path
              d="M19.3,18.6748c-1.2194-0.6626-2.5494-1.0973-3.9248-1.2829v-1.2812c0.3525,0.1656,0.7357,0.2556,1.125,0.2643
            c1.588-0.0009,2.8746-1.2891,2.8737-2.8771c-0.0006-1.0788-0.605-2.0664-1.5653-2.5579C17.5431,9.598,16.368,8.6294,15,8.625
            c-0.9124-0.0021-1.7699,0.4361-2.3027,1.1768c-0.1244,0.1658-0.0908,0.4009,0.075,0.5253s0.4009,0.0908,0.5253-0.075
            c0.0017-0.0023,0.0035-0.0047,0.0051-0.007C13.695,9.6983,14.327,9.3743,15,9.375c1.0696,0.0045,1.9687,0.8044,2.0977,1.8662
            c0.0166,0.1332,0.1029,0.2476,0.2265,0.3c1.0817,0.4555,1.5893,1.7017,1.1338,2.7834c-0.3318,0.7878-1.1032,1.3001-1.958,1.3004
            c-0.4016-0.0073-0.7924-0.131-1.125-0.3561v-1.5677l1.333-0.8887c0.1719-0.1159,0.2173-0.3492,0.1014-0.5211
            c-0.1149-0.1705-0.3456-0.2168-0.5174-0.1039l-0.917,0.6113V12c0-0.2071-0.1679-0.375-0.375-0.375S14.625,11.7929,14.625,12
            v1.5938l-0.3594-0.3594c-0.1467-0.1467-0.3845-0.1467-0.5312,0s-0.1467,0.3845,0,0.5312l0.8906,0.8906v0.6114
            c-0.7368,0.4794-1.6944,0.4482-2.3984-0.0781c-0.165-0.1251-0.4003-0.0928-0.5254,0.0722s-0.0928,0.4003,0.0722,0.5254l0,0
            c0.8198,0.6174,1.9066,0.7511,2.8516,0.3507V17.29c-0.8356-0.1088-1.6773-0.164-2.52-0.1653
            c-1.2519-0.0049-2.5013,0.1149-3.7295,0.3575V15.35c1.2572,0.0731,2.4845-0.4009,3.3662-1.3
            c0.644-0.909,0.8285-2.0658,0.499-3.13C11.7617,8.7217,10.13,5.625,7.999,5.625c-2.2627,0-3.8994,3.4521-4.2724,5.3008
            c-0.2805,1.0576-0.0592,2.1854,0.6,3.0586c0.8557,0.9024,2.0553,1.3977,3.2984,1.3619v2.2959
            c-0.9561,0.2119-1.876,0.5631-2.73,1.0424c-0.1749,0.1108-0.2269,0.3424-0.1161,0.5173s0.3424,0.2269,0.5173,0.1161
            c0.0013-0.0009,0.0027-0.0017,0.004-0.0026c0.0225-0.0136,2.3164-1.44,6.8047-1.44c4.4756,0,6.7695,1.418,6.792,1.4326
            c0.1753,0.1105,0.407,0.0579,0.5175-0.1174c0.1097-0.174,0.0587-0.4039-0.1142-0.5154L19.3,18.6748z M4.9072,13.51
            c-0.5126-0.7012-0.677-1.5982-0.4463-2.4356C4.8018,9.3877,6.2754,6.375,7.999,6.375c1.6211,0,3.0791,2.7324,3.5088,4.7051
            c0.2732,0.8426,0.1439,1.7641-0.3506,2.499c-0.7409,0.7192-1.7519,1.0909-2.7822,1.0228V13.687l1.85-1.3872
            c0.1657-0.124,0.1994-0.3589,0.0754-0.5246s-0.3589-0.1994-0.5246-0.0754l-1.4,1.05v-1.5936l0.8906-0.8906
            c0.1467-0.1467,0.1467-0.3845,0-0.5312s-0.3845-0.1467-0.5312,0l-0.3594,0.3594V9c0-0.2071-0.1679-0.375-0.375-0.375
            S7.6258,8.7929,7.6258,9v1.0938L7.2664,9.7344c-0.1467-0.1467-0.3845-0.1467-0.5312,0c-0.1467,0.1467-0.1467,0.3845,0,0.5312
            l0.8906,0.8906v1.5943l-1.4-1.05c-0.1657-0.124-0.4006-0.0903-0.5246,0.0754c-0.124,0.1657-0.0903,0.4006,0.0754,0.5246
            l1.85,1.3872v0.9112C6.6077,14.6326,5.6213,14.2376,4.9072,13.51z"
            />
            <path
              d="M18.5,4.625c-1.0355,0-1.875,0.8395-1.875,1.875s0.8395,1.875,1.875,1.875s1.875-0.8395,1.875-1.875
            C20.3741,5.4649,19.5351,4.6259,18.5,4.625z M18.5,7.625c-0.6213,0-1.125-0.5037-1.125-1.125s0.5037-1.125,1.125-1.125
            s1.125,0.5037,1.125,1.125C19.6241,7.121,19.121,7.6241,18.5,7.625z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
