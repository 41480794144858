import React from 'react';
export function CreditCard({
  className,
  fillColor,
}: {
  className?: string;
  fillColor?: string;
}) {
  return (
    <svg className={className} viewBox="0 0 24 24" style={{ fill: fillColor }}>
      <g data-name="Bounding Box">
        <path style={{ fill: 'none' }} d="M0,0V24H24V0Z" />
      </g>
      <g data-name="Icons Outlined">
        <g>
          <path d="M19,6.625H5A1.377,1.377,0,0,0,3.625,8v8A1.377,1.377,0,0,0,5,17.375H19A1.377,1.377,0,0,0,20.375,16V8A1.377,1.377,0,0,0,19,6.625ZM19.625,16a.6256.6256,0,0,1-.625.625H5A.6256.6256,0,0,1,4.375,16V12.375h15.25Zm0-4.375H4.375v-1.25h15.25Zm0-2H4.375V8A.6256.6256,0,0,1,5,7.375H19A.6256.6256,0,0,1,19.625,8Z" />
          <path d="M6,15.375h4a.375.375,0,0,0,0-.75H6a.375.375,0,0,0,0,.75Z" />
        </g>
      </g>
    </svg>
  );
}
