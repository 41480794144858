import React from 'react';
export function Mobile({
  className,
  fillColor,
}: {
  className?: string;
  fillColor?: string;
}) {
  return (
    <svg className={className} viewBox="0 0 24 24" style={{ fill: fillColor }}>
      <g data-name="Bounding Box">
        <path style={{ fill: 'none' }} d="M0,0V24H24V0Z" />
      </g>
      <g data-name="Icons Outlined">
        <g>
          <path d="M15,2.625H9A2.3777,2.3777,0,0,0,6.625,5V19A2.3777,2.3777,0,0,0,9,21.375h6A2.3777,2.3777,0,0,0,17.375,19V5A2.3777,2.3777,0,0,0,15,2.625ZM16.625,19A1.6262,1.6262,0,0,1,15,20.625H9A1.6262,1.6262,0,0,1,7.375,19V5A1.6262,1.6262,0,0,1,9,3.375h6A1.6262,1.6262,0,0,1,16.625,5Z" />
          <path d="M13,4.625H11a.375.375,0,0,0,0,.75h2a.375.375,0,0,0,0-.75Z" />
        </g>
      </g>
    </svg>
  );
}
