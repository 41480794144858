import React from 'react';
const GuestServiceIcon = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg viewBox="0 0 44 44" focusable="false" {...props}>
      <defs>
        <path d="M.364 1.688L1.66.773 4.38 4.694l-1.295.915z" />
        <path d="M.735 1.308L1.815.14l6.94 6.53-1.079 1.166z" />
        <path d="M.713 1.888L1.504.506l4.11 2.393-.791 1.382z" />
        <path d="M4.72.621l.67 1.446L1.088 4.09.42 2.644z" />
        <path d="M7.845.367l.952 1.275-7.579 5.761L.266 6.13z" />
        <path d="M3.288.72l1.211 1.026-3.05 3.666L.238 4.387z" />
      </defs>
      <g fill="none" fillRule="evenodd" stroke="currentColor">
        <g strokeWidth="2" strokeLinecap="square">
          <path d="M19.45 29.338v3.397M19.45 11.579v2.289" />
        </g>
        <path
          d="M19.45 11.579c1.247 0 2.26-1.025 2.26-2.29C21.71 8.025 20.696 7 19.45 7c-1.248 0-2.26 1.025-2.26 2.29 0 1.264 1.012 2.289 2.26 2.289z"
          strokeWidth="2"
        />
        <path d="M5.892 33.267h26.55" strokeWidth="3" strokeLinecap="square" />
        <g transform="translate(6.118 9.393)">
          <mask>
            <use xlinkHref="#a" />
          </mask>
          <path
            mask="url(#b)"
            d="M-5.143.709L2.63-4.783 9.889 5.674l-7.774 5.49z"
          />
        </g>
        <g transform="translate(-1 9.393)">
          <mask>
            <use xlinkHref="#c" />
          </mask>
          <path
            mask="url(#d)"
            d="M-4.853 1.505l6.472-7.002 12.725 11.97-6.473 7.002z"
          />
        </g>
        <g transform="translate(-.21 15.776)">
          <mask>
            <use xlinkHref="#e" />
          </mask>
          <path
            mask="url(#f)"
            d="M-4.688 3.348L.057-4.943l10.958 6.382L6.27 9.73z"
          />
        </g>
        <g transform="translate(32.216 16.574)">
          <mask>
            <use xlinkHref="#g" />
          </mask>
          <path
            mask="url(#h)"
            d="M6.634-4.68l4.01 8.677L-.824 9.39-4.834.715z"
          />
        </g>
        <g transform="translate(30.635 10.191)">
          <mask>
            <use xlinkHref="#i" />
          </mask>
          <path
            mask="url(#j)"
            d="M8.624-5.219l5.711 7.646L.44 12.99l-5.712-7.646z"
          />
        </g>
        <g transform="translate(28.262 9.393)">
          <mask>
            <use xlinkHref="#k" />
          </mask>
          <path
            mask="url(#l)"
            d="M2.8-4.9l7.27 6.154-8.133 9.778-7.27-6.153z"
          />
        </g>
        <path
          d="M6.282 28.54h26.676s-1.814-13.66-13.395-13.66c-11.582 0-13.28 13.66-13.28 13.66z"
          strokeWidth="1.5"
        />
      </g>
    </svg>
  );
};

export default GuestServiceIcon;
