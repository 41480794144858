import dynamic from 'next/dynamic';

const HotelLogo = dynamic<{ className?: string; fillColor?: string }>(() =>
  import('./nyccici/hotel-logo').then((i) => i.HotelLogo)
);

export const hotelLogoHeader = (
  <HotelLogo className="antialiased w-32" fillColor="#B08D59" />
);

export const hotelLogoFooter = (
  <HotelLogo className="antialiased w-32" fillColor="#000000" />
);
