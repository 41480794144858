/**
 *
 *
 * DO NOT UPDATE THIS FILE, UPDATE ORLHHSA.TSX - THIS FILE IS THE OLD CTYHON FOR SIGNIA BY HILTON ORLANDO BONNET CREEK
 *
 * TODO: DELETE THIS FILE
 *
 */

import dynamic from 'next/dynamic';

const HotelLogo = dynamic<{ className: string; fillColor?: string }>(() =>
  import('./orlhhhh/hotel-logo').then((i) => i.HotelLogo)
);

export const hotelLogoHeader = (
  <HotelLogo
    className="fill-current text-current antialiased w-28 lg:w-40 lg:mr-5 h-20"
    fillColor="#000000"
  />
);

export const hotelLogoFooter = (
  <HotelLogo
    className="fill-current text-current antialiased w-28 lg:w-40"
    fillColor="#FFFFFF"
  />
);
