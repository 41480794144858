import React from 'react';
export function Meeting({
  className,
  fillColor,
}: {
  className?: string;
  fillColor?: string;
}) {
  return (
    <svg className={className} viewBox="0 0 24 24" style={{ fill: fillColor }}>
      <g data-name="Bounding Box">
        <path style={{ fill: 'none' }} d="M0,0V24H24V0Z" />
      </g>
      <g data-name="Icons Outlined">
        <g>
          <path d="M12,8.375A1.875,1.875,0,1,0,10.125,6.5,1.8767,1.8767,0,0,0,12,8.375Zm0-3A1.125,1.125,0,1,1,10.875,6.5,1.1266,1.1266,0,0,1,12,5.375Z" />
          <path d="M16.5,8.375A1.875,1.875,0,1,0,14.625,6.5,1.8767,1.8767,0,0,0,16.5,8.375Zm0-3A1.125,1.125,0,1,1,15.375,6.5,1.1266,1.1266,0,0,1,16.5,5.375Z" />
          <path d="M7.5,8.375A1.875,1.875,0,1,0,5.625,6.5,1.8767,1.8767,0,0,0,7.5,8.375Zm0-3A1.125,1.125,0,1,1,6.375,6.5,1.1266,1.1266,0,0,1,7.5,5.375Z" />
          <path d="M18,8.625c-1.0684,0-1.5293,1.5859-1.9355,2.9854-.1095.377-.2142.7354-.3179,1.0146H14.375V11.5a2.375,2.375,0,0,0-4.75,0v1.125H8.2832c-.1027-.2742-.2076-.6246-.3174-.9932C7.5469,10.2227,7.0713,8.625,6,8.625A2.3777,2.3777,0,0,0,3.625,11v4A2.29,2.29,0,0,0,6,17.375H7.7686l.8964,1.793A.3743.3743,0,0,0,9,19.375a.37.37,0,0,0,.168-.04.3748.3748,0,0,0,.167-.503l-1-2A.3748.3748,0,0,0,8,16.625H6A1.5634,1.5634,0,0,1,4.375,15V11A1.6262,1.6262,0,0,1,6,9.375c.5117,0,.9727,1.5469,1.2471,2.4707.082.2769.1623.538.243.7793H7a.375.375,0,0,0,0,.75H17a.375.375,0,0,0,0-.75h-.4617c.0822-.2489.1635-.5181.2469-.8047.2656-.915.71-2.4453,1.2148-2.4453A1.6262,1.6262,0,0,1,19.625,11v3.998A1.5639,1.5639,0,0,1,18,16.625H16a.3748.3748,0,0,0-.335.207l-1,2a.3748.3748,0,0,0,.167.503.37.37,0,0,0,.168.04.3743.3743,0,0,0,.335-.207l.8964-1.793H18A2.29,2.29,0,0,0,20.375,15V11A2.3777,2.3777,0,0,0,18,8.625Zm-4.375,4h-3.25V11.5a1.625,1.625,0,0,1,3.25,0Z" />
          <path d="M10.1143,15.9346a1.343,1.343,0,0,0-1.0206-.9815L7.0586,14.63a.3813.3813,0,0,0-.4287.3115.3737.3737,0,0,0,.3115.4287l2.0176.32a.6475.6475,0,0,1,.4453.4776l1.25,2.9775a.3745.3745,0,0,0,.3457.23.3837.3837,0,0,0,.1455-.0293.3755.3755,0,0,0,.2-.4912Z" />
          <path d="M15.0234,15.6934l2.0352-.3233a.3737.3737,0,0,0,.3115-.4287.3776.3776,0,0,0-.4287-.3115l-2.0527.3262a1.3059,1.3059,0,0,0-.9844.9209l-1.25,2.9775a.3755.3755,0,0,0,.2.4912A.3837.3837,0,0,0,13,19.375a.3745.3745,0,0,0,.3457-.23L14.6143,16.11A.595.595,0,0,1,15.0234,15.6934Z" />
        </g>
      </g>
    </svg>
  );
}
