import React from 'react';

const RoomServiceIcon = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg viewBox="0 0 44 44" focusable="false" {...props}>
      <g
        strokeWidth="1.5"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke="currentColor"
      >
        <path d="M28.21 23.717c1.272-4.599-.06-7.023.41-8.2.612-1.525 2.705-6.756 3.301-8.247l-3.747-1.5c-.598 1.491-2.69 6.722-3.301 8.248-.537 1.342-3.894 2.24-6.281 8.207l-.622 1.553M26.124 11.177l3.616 1.457M20.318 18.885l8.221 3.179M11.167 24.75l2.707 16.11h15.602l2.638-16.11zM9.77 24h23.74" />
      </g>
    </svg>
  );
};

export default RoomServiceIcon;
