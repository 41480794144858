import dynamic from 'next/dynamic';

const HotelLogo = dynamic<{ className: string }>(() =>
  import('./auhwbqq/hotel-logo').then((i) => i.HotelLogo)
);

const BrandLogo = dynamic<{ className: string }>(() =>
  import('./auhwbqq/brand-logo').then((i) => i.BrandLogo)
);

export const hotelLogoHeader = (
  <BrandLogo className="fill-current text-bg antialiased h-20 sm:h-24" />
);

export const hotelLogoFooter = (
  <HotelLogo className="fill-current text-bg antialiased h-24" />
);
