import React from 'react';
export function AddHotel({
  className,
  fillColor,
}: {
  className?: string;
  fillColor?: string;
}) {
  return (
    <svg
      className={className}
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 24 24"
      style={{ fill: fillColor }}
    >
      <g>
        <g>
          <path style={{ fill: 'none' }} d="M0,0v24h24V0H0z" />
        </g>
        <g>
          <g>
            <path
              d="M7.375,10C7.3749,9.7929,7.2071,9.6251,7,9.625H5C4.2411,9.6261,3.6261,10.2411,3.625,11v5
				c0,0.2071,0.1679,0.375,0.375,0.375c0.2071,0,0.375-0.1679,0.375-0.375v-5c0.0003-0.345,0.28-0.6247,0.625-0.625h2
				C7.2071,10.3749,7.3749,10.2071,7.375,10z"
            />
            <path
              d="M16.625,5v5c0,0.2071,0.1679,0.375,0.375,0.375s0.375-0.1679,0.375-0.375V5C17.3739,4.2411,16.7589,3.6261,16,3.625h-6
				C9.2411,3.6261,8.6261,4.2411,8.625,5v11c0,0.2071,0.1679,0.375,0.375,0.375S9.375,16.2071,9.375,16V5
				C9.3753,4.655,9.655,4.3753,10,4.375h6C16.345,4.3753,16.6247,4.655,16.625,5z"
            />
            <path
              d="M12.375,12c-0.0001-0.2071-0.1679-0.3749-0.375-0.375h-1c-0.2071,0-0.375,0.1679-0.375,0.375s0.1679,0.375,0.375,0.375h1
				C12.2071,12.3749,12.3749,12.2071,12.375,12z"
            />
            <path
              d="M15,9.625h-4c-0.2071,0-0.375,0.1679-0.375,0.375s0.1679,0.375,0.375,0.375h4c0.2071,0,0.375-0.1679,0.375-0.375
				S15.2071,9.625,15,9.625z"
            />
            <path
              d="M15,7.625h-4c-0.2071,0-0.375,0.1679-0.375,0.375S10.7929,8.375,11,8.375h4c0.2071,0,0.375-0.1679,0.375-0.375
				S15.2071,7.625,15,7.625z"
            />
            <path
              d="M15,5.625h-4c-0.2071,0-0.375,0.1679-0.375,0.375S10.7929,6.375,11,6.375h4c0.2071,0,0.375-0.1679,0.375-0.375
				S15.2071,5.625,15,5.625z"
            />
            <path
              d="M7.0068,13.625H6c-0.2071,0-0.375,0.1679-0.375,0.375S5.7929,14.375,6,14.375h1.0068c0.2071,0,0.375-0.1679,0.375-0.375
				S7.2139,13.625,7.0068,13.625z"
            />
            <path
              d="M7,11.625H6c-0.2071,0-0.375,0.1679-0.375,0.375S5.7929,12.375,6,12.375h1c0.2071,0,0.375-0.1679,0.375-0.375
				S7.2071,11.625,7,11.625z"
            />
            <path
              d="M16,11.625c-2.4162,0-4.375,1.9588-4.375,4.375s1.9588,4.375,4.375,4.375s4.375-1.9588,4.375-4.375l0,0
				C20.3722,13.5849,18.4151,11.6278,16,11.625z M16,19.625c-2.002,0-3.625-1.623-3.625-3.625s1.623-3.625,3.625-3.625
				s3.625,1.623,3.625,3.625C19.6229,18.0011,18.0011,19.6229,16,19.625z"
            />
            <path
              d="M18,15.625h-1.625V14c0-0.2071-0.1679-0.375-0.375-0.375c-0.2071,0-0.375,0.1679-0.375,0.375v1.625H14
				c-0.2071,0-0.375,0.1679-0.375,0.375c0,0.2071,0.1679,0.375,0.375,0.375h1.625V18c0,0.2071,0.1679,0.375,0.375,0.375
				c0.2071,0,0.375-0.1679,0.375-0.375v-1.625H18c0.2071,0,0.375-0.1679,0.375-0.375C18.375,15.7929,18.2071,15.625,18,15.625z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
