import dynamic from 'next/dynamic';

const HotelLogo = dynamic<{ className?: string; fillColor?: string }>(() =>
  import('./auhyihi/hotel-logo').then((i) => i.HotelLogo)
);

export const hotelLogoHeader = (
  <HotelLogo
    className="text-bg antialiased w-20 sm:w-24 md:w-28"
    fillColor="#FFFFFF"
  />
);

export const hotelLogoFooter = (
  <HotelLogo className="text-bg antialiased w-20 sm:w-28" fillColor="#FFFFFF" />
);
