import React from 'react';
export function Amazon({
  className,
  fillColor,
}: {
  className?: string;
  fillColor?: string;
}) {
  return (
    <svg className={className} viewBox="0 0 24 24" style={{ fill: fillColor }}>
      <g data-name="Bounding Box">
        <path style={{ fill: 'none' }} d="M0,0V24H24V0Z" />
      </g>
      <g data-name="Icons Outlined">
        <g>
          <path d="M20.3721,7.99a.3715.3715,0,0,0-.0371-.1579c-.0031-.0061-.0088-.01-.0121-.0154a.3575.3575,0,0,0-.027-.0346.3615.3615,0,0,0-.1071-.0979c-.0083-.005-.012-.0147-.0208-.0191l-8-4a.3768.3768,0,0,0-.336,0l-8,4c-.0089.0045-.0127.0143-.0211.0194a.3627.3627,0,0,0-.1063.0972.329.329,0,0,0-.0275.0351c-.0033.0057-.009.0093-.0121.0153a.3715.3715,0,0,0-.0371.1579L3.625,8v8a.3748.3748,0,0,0,.207.335l8,4,.01.0023a.35.35,0,0,0,.3162,0l.01-.0023,8-4A.3748.3748,0,0,0,20.375,16V8ZM12,4.4189,19.1621,8,16,9.5811,8.8379,6Zm-.375,14.9747-7.25-3.625V8.6064l7.25,3.625ZM12,11.5811,4.8379,8,8,6.4189,15.1621,10Zm7.625,4.1875-7.25,3.625V12.2314l3.25-1.625V13a.375.375,0,0,0,.75,0V10.2314l3.25-1.625Z" />
          <path d="M8.6641,15.7383a2.287,2.287,0,0,0,.3505.0283,1.7347,1.7347,0,0,0,1.251-.501.3756.3756,0,0,0-.5312-.5312,1.0359,1.0359,0,0,1-.95.2636A3.8515,3.8515,0,0,1,6.335,12.832a.3748.3748,0,0,0-.67.336A4.5484,4.5484,0,0,0,8.6641,15.7383Z" />
        </g>
      </g>
    </svg>
  );
}
