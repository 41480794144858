import React from 'react';
export function Movies({
  className,
  fillColor,
}: {
  className?: string;
  fillColor?: string;
}) {
  return (
    <svg className={className} viewBox="0 0 24 24" style={{ fill: fillColor }}>
      <g data-name="Bounding Box">
        <path style={{ fill: 'none' }} d="M0,0V24H24V0Z" />
      </g>
      <g data-name="Icons Outlined">
        <g>
          <path d="M19,14.625a.3751.3751,0,0,0-.375.375v4a.6256.6256,0,0,1-.625.625H7A.6256.6256,0,0,1,6.375,19V15a.375.375,0,0,0-.75,0v4A1.377,1.377,0,0,0,7,20.375H18A1.377,1.377,0,0,0,19.375,19V15A.3751.3751,0,0,0,19,14.625Z" />
          <path d="M8,16.375h9a.375.375,0,0,0,0-.75H8a.375.375,0,0,0,0,.75Z" />
          <path d="M8,18.375h9a.375.375,0,0,0,0-.75H8a.375.375,0,0,0,0,.75Z" />
          <path d="M19,10.625H6a.3616.3616,0,0,0-.098.02L18.0283,6.2314a.3754.3754,0,0,0,.2246-.48l-.6845-1.8789a.3747.3747,0,0,0-.48-.2246L4.8721,8.0938a.375.375,0,0,0-.2246.48l.6836,1.8789a.3731.3731,0,0,0,.1943.2119.3693.3693,0,0,0,.1582.0352.382.382,0,0,0,.1279-.0225l.0175-.0063A.3717.3717,0,0,0,5.625,11v2A.3751.3751,0,0,0,6,13.375H19A.3751.3751,0,0,0,19.375,13V11A.3751.3751,0,0,0,19,10.625ZM16.9922,4.4805,17.42,5.6553l-1.3817.5028L14.4359,5.411ZM13.44,5.7737l1.6022.7471-2.293.8344-1.6019-.7469Zm-3.289,1.1972,1.6018.7469-2.293.8345L7.8578,7.8055Zm-4.67,1.7,1.381-.5027L8.463,8.915l-2.5548.93ZM6.375,12.625v-1.25H7.8438l1.25,1.25Zm3.7812,0-1.25-1.25h2.4376l1.25,1.25Zm3.5,0-1.25-1.25h2.4376l1.25,1.25Zm4.9688,0H17.1562l-1.25-1.25H18.625Z" />
        </g>
      </g>
    </svg>
  );
}
