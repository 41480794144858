import React, { useRef, useState, useEffect, useContext } from 'react';
import { useTranslation } from 'next-i18next';
import { SettingSliders } from '@curated-property/icons';
import { CloseIcon } from '@curated-property/icons';
import cx from 'classnames';
import { EventCalendarInfoContext } from './event-calendar-info-context';
import { sanitize } from '@curated-property/utils';

export const EventCalendarTypeFilters = (filterProps) => {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  // const set to true on first filter dialog open to prevent premature focus
  const [filtersViewed, setFiltersViewed] = useState(false);
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const [currentCheckbox, setCurrentCheckbox] = useState('');

  const handleInputChange = (e) => {
    const { checked, value } = e.target;
    // Determine checked status (boolean)
    const tgt = document.getElementById(
      e.target.getAttribute('id')
    ) as HTMLInputElement;
    if (checked) {
      e.target.setAttribute('checked', true);
      tgt.checked = true;
    } else {
      if (!checked) {
        e.target.removeAttribute('checked');
        tgt.checked = false;
      }
    }

    filterProps.displayFilterInputs([
      checked,
      value,
      e.target.getAttribute('data-filter-type'),
    ]);
  };

  const localeArr = [{ name: 'On-site' }, { name: 'Off-site' }];

  function resetAllFilters() {
    document.querySelectorAll('input.calendar-filter').forEach((el) => {
      const inputId = el.getAttribute('id');
      const inputCheckbox = document.getElementById(
        inputId
      ) as HTMLInputElement;
      inputCheckbox.removeAttribute('checked');
      inputCheckbox.checked = false;

      // Pass along the change of props to the parent component
      filterProps.displayFilterInputs([
        'reset',
        inputCheckbox.getAttribute('value'),
        inputCheckbox.getAttribute('data-filter-type'),
      ]);
    });
  }

  function selectFirstCheckboxInForm() {
    const firstInputId = document.querySelector(
      '#filterForm input[type="checkbox"]'
    ).id;
    if (firstInputId) {
      document.getElementById(firstInputId).focus();
      setCurrentCheckbox(firstInputId);
    }
  }

  function returnFocusToFilterButton() {
    setCurrentCheckbox('');
    document.getElementById('filterTypesBtn').focus();
  }

  function setNextFocus(id) {
    if (id) {
      setCurrentCheckbox(id);
      document.getElementById(id).focus();
    }
  }

  useEffect(() => {
    open && selectFirstCheckboxInForm();
    !open && filtersViewed && returnFocusToFilterButton();
  }, [open, filtersViewed]);

  useEffect(() => {
    function nextFilterInput(dir) {
      const itemIdArray = [
        'resetFiltersButton',
        'doneButton',
        'closeFiltersButton',
      ];
      const items = document.querySelectorAll(
        '#filterForm input[type="checkbox"]'
      );

      for (let i = 0; i < items.length; i++) {
        if (items[i].id) {
          itemIdArray.push(items[i].id);
        }
      }

      const place = itemIdArray.indexOf(currentCheckbox);

      if (dir === 'down') {
        place + 1 > itemIdArray.length - 1
          ? setNextFocus(itemIdArray[0])
          : setNextFocus(itemIdArray[place + 1]);
      } else if (dir === 'up') {
        place === 0
          ? setNextFocus(itemIdArray[itemIdArray.length - 1])
          : setNextFocus(itemIdArray[place - 1]);
      }
    }
    function modalKeypress(e) {
      // Shift + Tab for up direction
      if (e.shiftKey && e.key === 'Tab') {
        e.preventDefault();
        currentCheckbox.length && nextFilterInput('up');
      } else {
        // Non-Shift related keypress actions
        if (!e.shiftKey) {
          switch (e.key) {
            case 'ArrowDown':
              e.preventDefault();
              currentCheckbox.length && nextFilterInput('down');
              break;
            case 'Tab':
              e.preventDefault();
              currentCheckbox.length && nextFilterInput('down');
              break;

            case 'ArrowUp':
              e.preventDefault();
              currentCheckbox.length && nextFilterInput('up');
              break;
            case 'Escape':
              e.preventDefault();
              resetAllFilters();
              setOpen(false);
              returnFocusToFilterButton();
              break;
          }
        }
      }
    }
    if (open) {
      window.addEventListener('keydown', modalKeypress);
      return () => {
        window.removeEventListener('keydown', modalKeypress);
      };
    }
  }, [open, currentCheckbox]);

  const hotelInfoContext = useContext(EventCalendarInfoContext);

  return (
    <>
      <div className="relative text-right ">
        <button
          type="button"
          tabIndex={0}
          ref={buttonRef}
          onClick={() => {
            setOpen((s) => !s);
            !filtersViewed && setFiltersViewed(true);
          }}
          aria-expanded={open}
          className="btn btn-primary-outline calendar-filter py-2 px-4 bg-white"
          id="filterTypesBtn"
          data-testid="filterTypesBtn"
          style={{
            color:
              hotelInfoContext?.inlineStyles
                ?.eventsCalAdditionalTextFilterColor,
            borderColor:
              hotelInfoContext?.inlineStyles
                ?.eventsCalAdditionalTextFilterColor,
          }}
        >
          <SettingSliders
            fill={
              !hotelInfoContext?.inlineStyles
                ?.eventsCalAdditionalTextFilterColor
                ? 'rgb(var(--color-primary))'
                : hotelInfoContext?.inlineStyles
                    ?.eventsCalAdditionalTextFilterColor
            }
            className="inline-block h-auto w-4 mr-2"
          />
          <span aria-hidden="true">{t('calendar.filters')}</span>
          <span className="sr-only">
            {t('calendar.filterByTypeAndLocation')}
          </span>
        </button>
      </div>
      <div className="relative">
        <div
          data-testid="filterFormDiv"
          id="filterFormDiv"
          className={cx(
            'p-2  absolute r-0 top-0 bg-white shadow-lg w-full z-10',
            open ? 'visible' : 'hidden'
          )}
          style={{
            color: hotelInfoContext?.inlineStyles?.eventsCalBaseTextColor,
          }}
        >
          <form id="filterForm">
            <div className="block">
              <div className="border-b pb-2">
                <h3 aria-label={t('calendar.filters')}>
                  {t('calendar.filters')}
                </h3>
                <button
                  tabIndex={0}
                  type="button"
                  onClick={() => {
                    setOpen(false);
                  }}
                  id="closeFiltersButton"
                  className="btn p-2 btn-primary-text btn-lg !absolute top-4 right-4 rtl:left-4 rtl:right-auto"
                >
                  <span className="sr-only">{t('close')}</span>
                  <CloseIcon
                    className="w-4 h-4"
                    fillColor={
                      hotelInfoContext?.inlineStyles?.eventsCalBaseIconColor ||
                      'inherit'
                    }
                  />
                </button>
              </div>
              <div className="py-4">
                <div className="block">
                  <h3>
                    <label htmlFor="filterByLocation">
                      {t('calendar.filterByLocation')}
                    </label>
                  </h3>
                  <ul
                    id="filterByLocation"
                    role="listbox"
                    aria-label={t('calendar.filterByLocation')}
                  >
                    {[...localeArr]?.map((i, e) => {
                      return (
                        <li key={`filter${e.toString()}`}>
                          <label
                            data-testid={i?.name + '-cb'}
                            className="py-2 block"
                            key={`lK${e}`}
                            htmlFor={`localeInput${e.toString()}`}
                          >
                            <input
                              id={`localeInput${e.toString()}`}
                              className="calendar-filter form-checkbox mr-2"
                              data-filter-type="locale"
                              type="checkbox"
                              onChange={handleInputChange}
                              value={i?.name}
                            />
                            <span>
                              <span
                                className="sr-only"
                                dangerouslySetInnerHTML={{
                                  __html: sanitize(
                                    t('calendar.filterBy') || ''
                                  ),
                                }}
                              />
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: sanitize(i?.name || ''),
                                }}
                              />
                              <span
                                className="sr-only"
                                dangerouslySetInnerHTML={{
                                  __html: sanitize(
                                    t('calendar.location') || ''
                                  ),
                                }}
                              />
                            </span>
                          </label>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
              <div className="py-2">
                <h3>
                  <label htmlFor="filterByType">
                    {t('calendar.filterByType')}
                  </label>
                </h3>
                <ul
                  id="filterByType"
                  aria-label={t('calendar.filterByType')}
                  role="listbox"
                >
                  {hotelInfoContext?.eventCategoriesWithCustom?.map((i, e) => {
                    return (
                      <li key={`lR${e}`}>
                        <label
                          className="my-2 block"
                          htmlFor={`categoryInput${e.toString()}`}
                        >
                          <input
                            id={`categoryInput${e.toString()}`}
                            className="calendar-filter form-checkbox mr-2"
                            data-filter-type="category"
                            type="checkbox"
                            onChange={handleInputChange}
                            value={i}
                          />
                          <span
                            dangerouslySetInnerHTML={{
                              __html: sanitize(i || ''),
                            }}
                          />
                        </label>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
            <div className="py-4 flex row-wrap justify-between">
              <button
                type="button"
                id="resetFiltersButton"
                className="py-2 px-4 underline text-primary"
                data-locale-type="All"
                onClick={(e) => {
                  e.preventDefault();
                  resetAllFilters();
                }}
                style={{
                  color:
                    hotelInfoContext?.inlineStyles
                      ?.eventsCalAdditionalTextFilterColor,
                }}
              >
                {t('calendar.resetFilters')}
              </button>
              <button
                id="doneButton"
                onClick={() => setOpen((s) => !s)}
                type="button"
                className="bg-primary text-white btn btn-primary calendar-additional-button"
              >
                {t('calendar.done')}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
