import React, { useRef, useState } from 'react';
import { PopupContent } from './popover-content';
import cx from 'classnames';

interface InfoPopupProps {
  label: string;
  children: React.ReactNode;
}
export function InfoPopup({ label, children }: InfoPopupProps) {
  const [open, setOpen] = useState(false);
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  return (
    <>
      <button
        className="btn btn-primary-text btn-lg"
        onClick={() => setOpen((s) => !s)}
        ref={buttonRef}
      >
        <span className="sr-only">{label}</span>
        <svg
          role="img"
          aria-hidden
          viewBox="0 0 9 9"
          width={16}
          height={16}
          className="fill-text"
        >
          <path d="M1.34 7.62A4.58 4.58 0 004.5 9a4.56 4.56 0 003.16-1.38A4.43 4.43 0 009 4.5a4.5 4.5 0 00-7.68-3.18A4.47 4.47 0 000 4.5a4.43 4.43 0 001.34 3.12m.51-5.77a3.75 3.75 0 016.4 2.65 3.67 3.67 0 01-1.12 2.59A3.82 3.82 0 014.5 8.25a3.82 3.82 0 01-2.63-1.16A3.67 3.67 0 01.75 4.5a3.72 3.72 0 011.1-2.65" />
          <path d="M4.88 6.85V3.46h-.75v3.39zM4.5 2.11a.45.45 0 00-.45.45.46.46 0 00.45.44.46.46 0 00.5-.44.45.45 0 00-.45-.45" />
        </svg>
      </button>
      {open && (
        <PopupContent targetRef={buttonRef} onClose={() => setOpen(false)}>
          <div className="flex justify-end">
            <button
              type="button"
              className={cx('btn btn-primary-text btn-lg', {})}
            >
              <span className="sr-only">Close</span>
              <svg
                role="img"
                aria-hidden
                viewBox="0 0 22 22"
                width="16"
                height="16"
              >
                <path
                  d="M1.4 1.4l18.425 18.425M1.4 19.501L20.144 1.4"
                  className={cx({})}
                  strokeWidth={2}
                  fill="none"
                  fillRule="evenodd"
                  strokeLinecap="square"
                />
              </svg>
            </button>
          </div>
          <div className="text-sm text-text text-normal">{children}</div>
        </PopupContent>
      )}
    </>
  );
}
