import dynamic from 'next/dynamic';

const HotelLogo = dynamic<{ className: string; fillColor?: string }>(() =>
  import('./orlochh/hotel-logo').then((i) => i.HotelLogo)
);

export const hotelLogoHeader = (
  <HotelLogo className="fill-bg antialiased w-30 md:w-40 md:mr-5 h-24" />
);

export const hotelLogoFooter = (
  <HotelLogo className="fill-bg antialiased w-36 md:w-32 h-24" />
);
