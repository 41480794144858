import React from 'react';
export function Unavailable({
  className,
  fillColor,
}: {
  className?: string;
  fillColor?: string;
}) {
  return (
    <svg
      className={className}
      style={{ fill: fillColor }}
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 24 24"
    >
      <g>
        <g>
          <path style={{ fill: 'none' }} d="M0,0v24h24V0H0z" />
        </g>
        <g>
          <g>
            <path
              d="M12,3.625c-4.6254,0-8.375,3.7496-8.375,8.375S7.3746,20.375,12,20.375s8.375-3.7496,8.375-8.375
            C20.3699,7.3767,16.6233,3.6301,12,3.625z M12,19.625c-4.2112,0-7.625-3.4138-7.625-7.625S7.7888,4.375,12,4.375
            S19.625,7.7888,19.625,12C19.62,16.2091,16.2091,19.62,12,19.625z"
            />
            <path
              d="M16.9854,8.9023c-0.0133-0.0168-0.0282-0.0324-0.0443-0.0466c-0.0052-0.006-0.0028-0.0151-0.0085-0.0207
            s-0.013-0.0029-0.0182-0.0076c-0.0628-0.0568-0.1434-0.0901-0.228-0.0942c-0.0119-0.0011-0.0239-0.0015-0.0358-0.0013
            c-0.064,0.0003-0.1267,0.0176-0.1819,0.05c-0.0169,0.0133-0.0326,0.0282-0.0469,0.0443c-0.0059,0.0052-0.0148,0.0028-0.02,0.0085
            L8.835,16.4014c-0.0057,0.0057-0.0033,0.0147-0.0086,0.0207c-0.0679,0.0561-0.1033,0.1424-0.0946,0.23
            c-0.0002,0.0106,0.0002,0.0212,0.0011,0.0317c0.0036,0.0856,0.0371,0.1673,0.0948,0.2307
            c0.0045,0.0051,0.0024,0.0128,0.0073,0.0177s0.0144,0.0033,0.02,0.0084c0.0143,0.0162,0.03,0.031,0.047,0.0444
            c2.7489,1.7153,6.3678,0.8775,8.0831-1.8714c1.1861-1.9007,1.1861-4.311,0-6.2117L16.9854,8.9023z M12,17.125
            c-0.791-0.0041-1.5702-0.1927-2.2755-0.5507l6.85-6.85c1.2724,2.511,0.2683,5.578-2.2426,6.8504
            C13.6094,16.9408,12.81,17.1295,12,17.125z"
            />
            <path
              d="M15.165,7.5986c0.0057-0.0056,0.0033-0.0147,0.0085-0.0207c0.0162-0.0142,0.031-0.0298,0.0443-0.0467
            c0.0326-0.0558,0.0499-0.1192,0.05-0.1838c0.0005-0.0115-0.0005-0.02-0.001-0.0315c-0.0035-0.0857-0.0372-0.1675-0.095-0.2309
            c-0.0045-0.0051-0.0023-0.0128-0.0072-0.0176s-0.0145-0.0033-0.02-0.0084c-0.0143-0.0162-0.03-0.031-0.047-0.0444
            C12.3487,5.2993,8.7298,6.1371,7.0145,8.886c-1.1861,1.9007-1.1861,4.311,0,6.2117c0.0105,0.0167,0.0284,0.0241,0.0409,0.0386
            c0.0071,0.0084,0.004,0.0208,0.0119,0.0287s0.0232,0.0057,0.0331,0.0136c0.0638,0.0583,0.1461,0.0922,0.2325,0.0958
            c0.0701,0,0.1387-0.0196,0.1982-0.0566c0.017-0.0133,0.0328-0.0282,0.0471-0.0444c0.0059-0.0052,0.0147-0.0027,0.02-0.0084
            L15.165,7.5986z M12,6.875c0.791,0.0041,1.5702,0.1926,2.2756,0.5506l-6.85,6.85c-1.2727-2.5104-0.2694-5.5771,2.2409-6.8499
            C10.3895,7.0592,11.1894,6.8704,12,6.875z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
