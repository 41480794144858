import React from 'react';
import cx from 'classnames';
import {
  GIS_TextAlignment,
  GIS_Padder,
  GIS_merge,
  StyleObject,
} from './functions/global-instance-styles';
import { HandleAnimations, HandleWYSIWYGContent } from './functions/helper';
import {
  WrappedSubtitle,
  appliedCloudinaryParams,
} from '@curated-property/utils';
import { iconmapper } from './functions/helper';
import { sanitize } from '@curated-property/utils';
export type IconBlockIconTypeKeyInfoGrid = keyof typeof iconmapper;

export function KeyInfoGridPropMapper(
  componentData?: Props & { keyInfoGridSettings?: StyleObject },
  globalData?: StyleObject
) {
  return {
    gridTitle: componentData?.gridTitle,
    gridSubtitle: componentData?.gridSubtitle,
    gridContent: componentData?.gridContent,
    columnTitles: componentData?.columnTitles,
    repeater: componentData?.repeater,
    globalStyles: globalData?.keyInfoGridSettings,
    instanceStyles: componentData?.keyInfoGridSettings,
  };
}

interface ikeyInfoGridTile {
  title?: string;
  content?: string;
  contentBackground?: string;
  backgroundOpacity?: number;
  columnSelect?: string;
  iconList?: IconBlockIconTypeKeyInfoGrid | string;
}

interface Props {
  gridTitle?: string;
  gridSubtitle?: string;
  gridContent?: string;
  repeater?: ikeyInfoGridTile[];
  columnTitles?: {
    title1?: string;
    title2?: string;
    title3?: string;
  };
  globalStyles?: StyleObject;
  instanceStyles?: StyleObject;
}

function hex2rgbaConst(hex: string, alpha: string) {
  const rgb_values = hex.match(/\w\w/g);
  if (rgb_values) {
    const [r, g, b] = rgb_values.map((x) => parseInt(x, 16));
    return `rgba(${r},${g},${b},${alpha})`;
  } else return `rgba(0,0,0,${alpha})`;
}

export function KeyInfoGrid({
  gridTitle,
  gridSubtitle,
  gridContent,
  repeater,
  columnTitles,
  globalStyles,
  instanceStyles,
}: Props) {
  const inlineStyles = GIS_merge(globalStyles, instanceStyles);
  const textAlignment = GIS_TextAlignment(inlineStyles?.textAlignment);
  const paddingStyles = GIS_Padder(
    inlineStyles?.paddingTop,
    inlineStyles?.paddingBottom
  );

  const layout = inlineStyles?.layoutOptions;

  let gridCellTextAlignment = 'text-center';
  if (
    inlineStyles?.columnBoxIconAlignment &&
    inlineStyles?.columnBoxIconAlignment !== 'center'
  ) {
    gridCellTextAlignment =
      inlineStyles?.columnBoxIconAlignment === 'left'
        ? 'text-center md:text-left'
        : 'text-center md:text-right';
  }

  // Translate flex alignment to text alignment classes for heading, subtitle and body content (not grid content)

  /**
   * Grid cell background settings can be controlled globally, per instance or per individual cell
   *
   * Background Color: Component instance settings override global settings. Individual cell color setting, if unique, will override component global/intance color setting.
   *
   * Background Opacity: All three types' opacities should be 100% by default. An individual cell's background opacity will override global or component instance if any one or more cell's setting is unique and/or less than 100%
   * Original CMS defaults were 0/null but have since been changed to 100% opacity by default
   *
   * If indiviudual cells' opacities are 100% (the default), but the component instance UI setting uses less than 100%, the component UI setting will be displayed.
   *
   * All three opacity settings (global, instance and individual cell) must be 0%/null for cell backgrounds to all appear at 0% opacity.
   *
   */

  // Inline indiviudal grid cell opacity from inline styles
  let gridCellInlineStylesBgOpacity = 10;

  // Determine global or instance cell background opacity a setting returned as null = 0% opacity
  const instanceGridCellBgOpacity = instanceStyles?.contentBackgroundOpacity;
  const globalGridCellBgOpacity = globalStyles?.contentBackgroundOpacity;

  if (instanceGridCellBgOpacity === null && globalGridCellBgOpacity === null)
    gridCellInlineStylesBgOpacity = 0;
  if (instanceGridCellBgOpacity === null || instanceGridCellBgOpacity < 10) {
    gridCellInlineStylesBgOpacity =
      instanceGridCellBgOpacity === null ? 0 : instanceGridCellBgOpacity;
  }

  // Determine whether there are any unique non-null (i.e. > 0) AND unique background opacity settings among grid cell backgrounds.
  // True = any one or more cells' backgrounds are less than 100% opacity and not null (greater than 0% opacity)
  const customIndividualCellOpacityExists = repeater?.some(
    (element) => element?.backgroundOpacity !== 10
  );

  const col1Arr: ikeyInfoGridTile[] = [];
  const col2Arr: ikeyInfoGridTile[] = [];
  const col3Arr: ikeyInfoGridTile[] = [];
  repeater?.map((item) => {
    const columnNumber = !item?.columnSelect ? '1' : item?.columnSelect;
    switch (columnNumber) {
      case '1':
        col1Arr.push(item);
        break;
      case '2':
        col2Arr.push(item);
        break;
      case '3':
        col3Arr.push(item);
        break;
    }
  });

  const allColumnCounts = [col1Arr?.length, col2Arr?.length, col3Arr?.length];

  // Column display count. Determine number of table columns based on entered content
  const totalColumns = col3Arr?.length === 0 && !columnTitles?.title3 ? 2 : 3;

  const tableRowCount = Math.max(...allColumnCounts);

  // Make <table> version of arrays
  const fullTableArray: ikeyInfoGridTile[][] = [[], [], []];
  for (let i = 0; i < tableRowCount; i++) {
    fullTableArray[0].push(col1Arr[i]);
    fullTableArray[1].push(col2Arr[i]);
    if (totalColumns === 3) {
      fullTableArray[2].push(col3Arr[i]);
    }
  }

  const animations = HandleAnimations({
    hideAnimation: inlineStyles?.hideAnimations !== 'show',
    start: `${inlineStyles?.animationDirection ?? '-translate-x'}-8`,
    delayOne: 'delay-100',
    delayTwo: 'delay-200',
    delayThree: 'delay-300',
  });

  const columnTitlesArray = Object.values(columnTitles ?? {});

  const columnTitlesArrayTotal =
    totalColumns === 2 ? columnTitlesArray.slice(0, -1) : columnTitlesArray;

  const columnTitlesPresent = columnTitlesArrayTotal.some(
    (col) => col !== null
  );

  const tableContent = [];

  for (let i = 0; i < tableRowCount; i++) {
    const trArray =
      totalColumns === 2
        ? [col1Arr[i] || null, col2Arr[i] || null]
        : [col1Arr[i] || null, col2Arr[i] || null, col3Arr[i] || null];
    tableContent.push(
      <TableRowObject
        colArray={trArray}
        key={`row${i.toString()}`}
        index={i}
        gridCellTextAlignment={gridCellTextAlignment}
        columnBoxContentAlignment={inlineStyles?.columnBoxIconAlignment}
        customIndividualCellOpacityExists={customIndividualCellOpacityExists}
        inlineStylesTextColor={
          inlineStyles?.columnBoxTextColor || inlineStyles?.textColor
        }
        inlineStylesCellIconColor={inlineStyles?.columnBoxIconColor}
        inlineStylesCellBg={inlineStyles?.columnBoxBackgroundColor}
        inlineStylesBgOpacity={gridCellInlineStylesBgOpacity}
        hideAnimations={inlineStyles?.hideAnimations !== 'show'}
        animationDirection={inlineStyles?.animationDirection}
        totalColumns={totalColumns}
      />
    );
  }

  // Gradient opacity
  const bgGradientOpacity =
    inlineStyles?.backgroundGradientOpacity !== null &&
    inlineStyles?.backgroundGradientOpacity <= 100
      ? (inlineStyles?.backgroundGradientOpacity / 100).toString()
      : '1';

  // Gradient direction - maps Tailwind options to degree settings
  const gradientDirections = {
    'bg-gradient-to-r': '90deg',
    'bg-gradient-to-l': '270deg',
    'bg-gradient-to-b': '180deg',
    'bg-gradient-to-t': '0deg',
    'bg-gradient-to-tr': '45deg',
    'bg-gradient-to-br': '135deg',
    'bg-gradient-to-tl': '315deg',
    'bg-gradient-to-bl': '225deg',
  };
  const bgGradientDirection = inlineStyles?.gradientPosition
    ? gradientDirections[
        inlineStyles?.gradientPosition as keyof typeof gradientDirections
      ]
    : '90deg';
  // Complete style for gradient
  const linearGradient = inlineStyles?.backgroundGradientColor
    ? `linear-gradient(${bgGradientDirection},${hex2rgbaConst(
        inlineStyles?.backgroundGradientColor,
        bgGradientOpacity
      )},${hex2rgbaConst(inlineStyles?.backgroundGradientColor, '0')})`
    : 'null';

  return (
    <div
      data-testid="keyInfoContainer"
      data-element-id="key-info-grid"
      className={cx(
        'bg-cover relative key-info-grid-background-w-overlay z-0',
        !inlineStyles?.backgroundGradientColor ? paddingStyles : '',
        inlineStyles?.showHide && 'hidden'
      )}
      style={{
        backgroundImage: inlineStyles?.componentBackgroundImage?.sourceUrl
          ? `url('${appliedCloudinaryParams(
              inlineStyles?.componentBackgroundImage?.sourceUrl,
              inlineStyles?.componentBackgroundRepeat
            )})`
          : '',
        backgroundSize: inlineStyles?.componentBackgroundSize || 'cover',
        backgroundRepeat: inlineStyles?.componentBackgroundRepeat || '',
        backgroundPosition: inlineStyles?.componentBackgroundPosition || '',
        backgroundColor: inlineStyles?.componentBackgroundColor || '',
      }}
    >
      {inlineStyles?.backgroundGradientColor && (
        <div
          data-testid="key-info-bg-gradient"
          data-element-id="key-info-gradient"
          className={cx(
            `w-full h-full min-w-full min-h-full absolute block`,
            paddingStyles
          )}
          style={{ backgroundImage: linearGradient }}
        />
      )}
      <div
        data-testid="key-info-content-container"
        className={cx(
          'block lg:flex background-cover container lg:z-50 py-8 lg:px-4 mx-auto',
          layout === 'center' ? 'lg:flex-col lg:items-center' : 'lg:flex-row'
        )}
        style={{
          maxWidth:
            inlineStyles?.containerMaxWidth &&
            `${inlineStyles?.containerMaxWidth}px`,
          backgroundImage: inlineStyles?.contentBackgroundImage?.sourceUrl
            ? `url(${appliedCloudinaryParams(
                inlineStyles?.contentBackgroundImage?.sourceUrl,
                inlineStyles?.contentBackgroundRepeat
              )})`
            : '',
          backgroundSize: inlineStyles?.contentBackgroundSize || '',
          backgroundRepeat: inlineStyles?.contentBackgroundRepeat || '',
          backgroundPosition: inlineStyles?.contentBackgroundPosition || '',
          backgroundColor: inlineStyles?.contentBackgroundColor || '',
        }}
      >
        <div
          ref={animations?.ref}
          className={cx(
            columnTitles && Object.values(columnTitles).some((title) => title)
              ? 'lg:pt-12'
              : '',
            `w-full  flex flex-col text-white justify-start pr-0 lg:pr-4 xl:pr-6 text-${textAlignment}`,
            totalColumns === 2 ? ' lg:w-1/2' : 'lg:w-1/3',
            layout === 'center' && 'pb-6 lg:pb-12 lg:w-full'
          )}
        >
          <h2
            className={cx(
              'text-2xl font-headline font-black leading-none text-center text-primary z-50',
              animations?.one
            )}
            style={{
              color: inlineStyles?.gridTitleColor || inlineStyles?.titleColor,
            }}
            dangerouslySetInnerHTML={{
              __html: sanitize(gridTitle || ''),
            }}
          />
          {gridSubtitle ? (
            <WrappedSubtitle className={cx('z-50', animations?.two)}>
              <p
                className={cx(
                  'lg:mr-4 mr-0 mt-4 my-2 font-headline font-black leading-none text-primary text-xl'
                )}
                style={{
                  color:
                    inlineStyles?.gridSubtitleColor ||
                    inlineStyles?.subtitleColor,
                }}
                dangerouslySetInnerHTML={{
                  __html: sanitize(gridSubtitle || ''),
                }}
              />
            </WrappedSubtitle>
          ) : (
            ''
          )}
          {gridContent && (
            <div
              dangerouslySetInnerHTML={{
                __html: HandleWYSIWYGContent(
                  gridContent,
                  inlineStyles?.gridContentColor || inlineStyles?.titleColor
                ),
              }}
              className={cx('my-2 text-black text-lg z-50', animations?.three)}
              style={{
                color:
                  inlineStyles?.gridContentColor || inlineStyles?.titleColor,
              }}
            />
          )}
        </div>
        <div
          className={cx(
            'w-full  mt-2 p-0 lg:mt-0',
            totalColumns === 2 ? 'lg:pl-4 xl:pl-6 lg:w-1/2' : 'lg:w-2/3',
            layout === 'center' && '!w-full'
          )}
          data-element-id="key-grid-tile-wrapper"
          data-testid="key-grid-tiles"
        >
          <table
            className={cx(
              'key-info-grid-table z-50 border-collapse table-fixed mx-auto w-full'
            )}
            data-testid="key-info-grid-table"
            role="table"
          >
            {columnTitlesPresent && (
              <thead>
                <tr className={cx(animations?.one)}>
                  {columnTitlesArrayTotal.map((heading, e) => {
                    return (
                      <th
                        key={`th${e.toString()}`}
                        className={cx(
                          gridCellTextAlignment,
                          'px-2 md:px-8 font-headline text-lg lg:text-2xl transition-[transform,opacity] transform duration-1000 delay-100 opacity-100 translate-x-0 font-bold',
                          {
                            'border-l-8 border-l-transparent': e > 1,
                          }
                        )}
                        style={{
                          color: inlineStyles?.columnTitleColor || null,
                        }}
                      >
                        {heading?.valueOf()}
                      </th>
                    );
                  })}
                </tr>
              </thead>
            )}
            <tbody>{tableContent}</tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

interface TableRowObjectProps {
  index: number;
  colArray: Array<ikeyInfoGridTile>;
  gridCellTextAlignment?: string | 'text-center';
  columnBoxContentAlignment?: string;
  inlineStylesCellBg?: string;
  inlineStylesTextColor?: string;
  inlineStylesCellIconColor?: string;
  inlineStylesBgOpacity?: number;
  customIndividualCellOpacityExists?: boolean;
  hideAnimations?: boolean;
  animationDirection?: string;
  totalColumns?: number | 3;
}

const TableRowObject = ({
  colArray,
  gridCellTextAlignment,
  columnBoxContentAlignment,
  inlineStylesCellBg,
  inlineStylesTextColor,
  inlineStylesCellIconColor,
  inlineStylesBgOpacity,
  customIndividualCellOpacityExists,
  hideAnimations,
  animationDirection,
  totalColumns,
}: TableRowObjectProps) => {
  const tdArray =
    totalColumns === 2
      ? [colArray[0], colArray[1]]
      : [colArray[0], colArray[1], colArray[2]];

  return (
    <tr
      className={cx(
        'transition-[transform,opacity] transform duration-1000 delay-300 opacity-100 translate-y-0 border-t-8 border-t-transparent'
      )}
    >
      {tdArray?.map((i, e) => {
        return (
          <TableTdObject
            key={`td${e.toString()}`}
            index={e}
            cellContent={i}
            gridCellTextAlignment={gridCellTextAlignment}
            columnContentAlignment={columnBoxContentAlignment}
            customIndividualCellOpacityExists={
              customIndividualCellOpacityExists
            }
            inlineStylesCellBg={inlineStylesCellBg}
            inlineStylesTextColor={inlineStylesTextColor}
            inlineStylesBgOpacity={inlineStylesBgOpacity}
            inlineStylesCellIconColor={inlineStylesCellIconColor}
            hideAnimations={hideAnimations}
            animationDirection={animationDirection}
          />
        );
      })}
    </tr>
  );
};

interface TableTdProps {
  index: number;
  cellContent: ikeyInfoGridTile;
  gridCellTextAlignment?: string;
  columnContentAlignment?: string | 'center';
  inlineStylesCellBg?: string;
  inlineStylesTextColor?: string;
  inlineStylesCellIconColor?: string;
  inlineStylesBgOpacity?: number;
  customIndividualCellOpacityExists?: boolean;
  hideAnimations?: boolean;
  animationDirection?: string;
}

const TableTdObject = ({
  cellContent,
  gridCellTextAlignment,
  columnContentAlignment,
  inlineStylesCellBg,
  inlineStylesTextColor,
  inlineStylesCellIconColor,
  customIndividualCellOpacityExists,
  inlineStylesBgOpacity,
  hideAnimations,
  animationDirection,
  index,
}: TableTdProps) => {
  const animations = HandleAnimations({
    hideAnimation: hideAnimations,
    start: `${animationDirection ?? '-translate-y'}-8`,
    delayOne: 'delay-300',
  });

  const opacityVal =
    (cellContent?.backgroundOpacity || 0) > 10 &&
    cellContent?.backgroundOpacity !== null
      ? 10
      : cellContent?.backgroundOpacity;

  const gridCellBgOpacity = !customIndividualCellOpacityExists
    ? inlineStylesBgOpacity
    : !opacityVal
    ? 0
    : opacityVal;

  // Provide converted background
  let cellBackground = !cellContent?.contentBackground
    ? inlineStylesCellBg
    : cellContent?.contentBackground;

  if (
    gridCellBgOpacity !== undefined &&
    gridCellBgOpacity < 10 &&
    gridCellBgOpacity > 0
  ) {
    // Convert color value with opacity to rgba() with opacity decimal limted to 2 places
    const alpha = (gridCellBgOpacity * 0.1).toFixed(1);

    cellBackground = hex2rgbaConst(
      !cellBackground ? '#ffffff' : cellBackground,
      alpha
    );
  } else {
    if (
      gridCellBgOpacity === 0 ||
      (!cellContent?.iconList && !cellContent?.title && !cellContent?.content)
    ) {
      cellBackground = 'none';
    }
  }

  const iconMap = iconmapper();
  const CellIcon =
    iconMap[(cellContent?.iconList as keyof typeof iconMap) || ''];

  // Align cell content left, right or center (center by default)
  let cellContentOrientation = 'justify-center';
  switch (columnContentAlignment) {
    case 'left':
      cellContentOrientation = 'md:flex-row-reverse';
      break;
    case 'right':
      cellContentOrientation = 'md:flex-row';
      break;
    default:
      cellContentOrientation = 'justify-center';
  }

  return (
    <td
      data-testid="grid-tile"
      className={cx(
        'text-base items-stretch',
        !cellContent?.contentBackground && cellContent?.backgroundOpacity === 10
          ? 'bg-white'
          : '',
        animations?.one,
        gridCellTextAlignment,
        index > 0 && 'border-l-8 border-l-transparent'
      )}
      style={{ background: cellBackground || undefined }}
    >
      <div
        ref={animations?.ref}
        className={cx(
          'px-2 md:px-8 py-3 md:py-4 flex flex-col-reverse w-full items-center min-h-[2rem] ',
          cellContent?.content || cellContent?.title
            ? cellContentOrientation
            : 'justify-center'
        )}
      >
        {(cellContent?.title || cellContent?.content) && (
          <div
            className={cx(
              !CellIcon || columnContentAlignment === 'center'
                ? 'w-full'
                : 'w-full md:w-2/3'
            )}
            style={{ color: inlineStylesTextColor }}
          >
            {cellContent?.title && (
              <div>
                <p
                  className="font-headline"
                  dangerouslySetInnerHTML={{
                    __html: sanitize(cellContent?.title),
                  }}
                />
              </div>
            )}

            {cellContent?.content && (
              <div
                dangerouslySetInnerHTML={{
                  __html: HandleWYSIWYGContent(cellContent?.content),
                }}
              />
            )}
          </div>
        )}

        {CellIcon && (
          <div
            className={cx('flex w-full sm:w-1/3 items-center justify-center')}
          >
            <CellIcon
              className={cx(
                !cellContent?.title && !cellContent?.content
                  ? 'h-12 w-12'
                  : 'h-8 w-8',
                'lg:h-16 lg:w-16'
              )}
              fillColor={
                !inlineStylesCellIconColor
                  ? inlineStylesTextColor
                  : inlineStylesCellIconColor || ''
              }
            />
          </div>
        )}
      </div>
    </td>
  );
};
