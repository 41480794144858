import React from 'react';
export function Balcony({
  className,
  fillColor,
}: {
  className?: string;
  fillColor?: string;
}) {
  return (
    <svg
      className={className}
      style={{ fill: fillColor }}
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 24 24"
    >
      <g>
        <g>
          <path style={{ fill: 'none' }} d="M0,0v24h24V0H0z" />
        </g>
        <g>
          <g>
            <path
              d="M5,12.375h14c0.2071,0,0.375-0.1679,0.375-0.375S19.2071,11.625,19,11.625h-2.625V4
            C16.3749,3.7929,16.2071,3.6251,16,3.625H8C7.7929,3.6251,7.6251,3.7929,7.625,4v7.625H5c-0.2071,0-0.375,0.1679-0.375,0.375
            S4.7929,12.375,5,12.375z M12.375,11.625v-3.25h3.25v3.25H12.375z M15.625,7.625h-3.25v-3.25h3.25V7.625z M11.625,4.375v3.25
            h-3.25v-3.25H11.625z M8.375,8.375h3.25v3.25h-3.25V8.375z"
            />
            <path
              d="M19,19.625H5c-0.2071,0-0.375,0.1679-0.375,0.375S4.7929,20.375,5,20.375h14c0.2071,0,0.375-0.1679,0.375-0.375
            S19.2071,19.625,19,19.625z"
            />
            <path
              d="M5.625,14v4c0,0.2071,0.1679,0.375,0.375,0.375S6.375,18.2071,6.375,18v-4c0-0.2071-0.1679-0.375-0.375-0.375
            S5.625,13.7929,5.625,14z"
            />
            <path
              d="M7.625,14v4c0,0.2071,0.1679,0.375,0.375,0.375S8.375,18.2071,8.375,18v-4c0-0.2071-0.1679-0.375-0.375-0.375
            S7.625,13.7929,7.625,14z"
            />
            <path
              d="M9.625,14v4c0,0.2071,0.1679,0.375,0.375,0.375s0.375-0.1679,0.375-0.375v-4c0-0.2071-0.1679-0.375-0.375-0.375
            S9.625,13.7929,9.625,14z"
            />
            <path
              d="M11.625,14v4c0,0.2071,0.1679,0.375,0.375,0.375s0.375-0.1679,0.375-0.375v-4c0-0.2071-0.1679-0.375-0.375-0.375
            S11.625,13.7929,11.625,14z"
            />
            <path
              d="M13.625,14v4c0,0.2071,0.1679,0.375,0.375,0.375s0.375-0.1679,0.375-0.375v-4c0-0.2071-0.1679-0.375-0.375-0.375
            S13.625,13.7929,13.625,14z"
            />
            <path
              d="M15.625,14v4c0,0.2071,0.1679,0.375,0.375,0.375c0.2071,0,0.375-0.1679,0.375-0.375v-4
            c0-0.2071-0.1679-0.375-0.375-0.375C15.7929,13.625,15.625,13.7929,15.625,14z"
            />
            <path
              d="M17.625,14v4c0,0.2071,0.1679,0.375,0.375,0.375s0.375-0.1679,0.375-0.375v-4c0-0.2071-0.1679-0.375-0.375-0.375
            S17.625,13.7929,17.625,14z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
