import { MenuProps } from '@curated-property/shared-components';
import dynamic from 'next/dynamic';

export const AdditionalHead = () => {
  return (
    <>
      <link rel="stylesheet" href="https://use.typekit.net/gdg8xrl.css"></link>
    </>
  );
};

const SharedHeader = dynamic<MenuProps>(() =>
  import('@curated-property/shared-components').then((h) => h.Header)
);

export const Header = (props: MenuProps) => {
  return (
    <SharedHeader
      {...props}
      styleOptions={{
        headerClassName: 'font-serif',
        navStyles: 'font-serif',

        iconMenu: {
          extraClasses: 'uppercase',
        },
      }}
    />
  );
};
