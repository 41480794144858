import { CurioLogo } from '@curated-property/icons';

import dynamic from 'next/dynamic';

const HotelLogo = dynamic<{ className: string }>(() =>
  import('./lasvgqq/hotel-logo').then((i) => i.HotelLogo)
);

export const hotelLogoHeader = (
  <HotelLogo className="antialiased w-40 md:w-48 mr-2.5" />
);

// export const brandLogo = <CurioLogo className="antialiased w-32 ml-2.5" />;

export const hotelLogoFooter = (
  <HotelLogo className="antialiased w-24 md:w-48" />
);
