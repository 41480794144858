import dynamic from 'next/dynamic';

const HotelLogo = dynamic<{ className: string; fillColor?: string }>(() =>
  import('./phxtcpr/hotel-logo').then((i) => i.HotelLogo)
);

export const hotelLogoHeader = (
  <HotelLogo
    className="fill-bg antialiased w-36 sm:w-40 md:mr-5"
    fillColor="#ffffff"
  />
);

export const hotelLogoFooter = (
  <HotelLogo
    className="fill-bg antialiased w-36 sm:w-40 mr-2.5 sm:mr-0"
    fillColor="#ffffff"
  />
);
