import { CroppedImage, customLoader } from '@curated-property/utils';
import { MediaBlocksTwoVerticalInterface } from './interfaces';

export function MediaBlocksTwoVertical(props: MediaBlocksTwoVerticalInterface) {
  return (
    <div
      className="h-full w-full grid grid-cols-2"
      data-testid="media-blocks-two-vert"
    >
      <div className="bg-blue-500 flex justify-center items-center overflow-hidden relative">
        <CroppedImage
          layout="fill"
          src={props?.imageLeft?.sourceUrl}
          alt={props?.imageLeft?.altText}
          loader={() => {
            return customLoader({
              src: props?.imageLeft?.sourceUrl,
              crop: props?.imageLeftCrop?.imageCrop12AddCrop && true,
              cropType: props?.imageLeftCrop?.imageCrop12AddCrop?.[0]?.cropType,
              cropHeight:
                props?.imageLeftCrop?.imageCrop12AddCrop?.[0]?.cropHeight,
              cropWidth:
                props?.imageLeftCrop?.imageCrop12AddCrop?.[0]?.cropWidth,
              xPosition:
                props?.imageLeftCrop?.imageCrop12AddCrop?.[0]?.xPosition,
              xPositionAdvanced:
                props?.imageLeftCrop?.imageCrop12AddCrop?.[0]
                  ?.xPositionAdvanced,
              yPosition:
                props?.imageLeftCrop?.imageCrop12AddCrop?.[0]?.yPosition,
              yPositionAdvanced:
                props?.imageLeftCrop?.imageCrop12AddCrop?.[0]
                  ?.yPositionAdvanced,
              autoPosition:
                props?.imageLeftCrop?.imageCrop12AddCrop?.[0]?.autoPosition,
            });
          }}
          objectFit="cover"
        />
      </div>
      <div className="bg-red-500 flex justify-center items-center overflow-hidden relative">
        <CroppedImage
          layout="fill"
          src={props?.imageRight?.sourceUrl}
          alt={props?.imageRight?.altText}
          loader={() => {
            return customLoader({
              src: props?.imageRight?.sourceUrl,
              crop: props?.imageRightCrop?.imageCrop12AddCrop && true,
              cropType:
                props?.imageRightCrop?.imageCrop12AddCrop?.[0]?.cropType,
              cropHeight:
                props?.imageRightCrop?.imageCrop12AddCrop?.[0]?.cropHeight,
              cropWidth:
                props?.imageRightCrop?.imageCrop12AddCrop?.[0]?.cropWidth,
              xPosition:
                props?.imageRightCrop?.imageCrop12AddCrop?.[0]?.xPosition,
              xPositionAdvanced:
                props?.imageRightCrop?.imageCrop12AddCrop?.[0]
                  ?.xPositionAdvanced,
              yPosition:
                props?.imageRightCrop?.imageCrop12AddCrop?.[0]?.yPosition,
              yPositionAdvanced:
                props?.imageRightCrop?.imageCrop12AddCrop?.[0]
                  ?.yPositionAdvanced,
              autoPosition:
                props?.imageRightCrop?.imageCrop12AddCrop?.[0]?.autoPosition,
            });
          }}
          objectFit="cover"
        />
      </div>
    </div>
  );
}
