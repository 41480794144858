import dynamic from 'next/dynamic';

const HotelLogo = dynamic<{
  className: string;
  fill1?: string;
  fill2?: string;
}>(() => import('./rslvhvh/hotel-logo').then((i) => i.HotelLogo));

export const hotelLogoHeader = (
  <HotelLogo
    className="fill-current text-bg antialiased w-40 sm:w-48 md:w-64"
    fill1="#d81a2b"
    fill2="#010101"
  />
);

export const hotelLogoFooter = (
  <HotelLogo
    className="fill-current text-bg antialiased w-40 sm:w-48 md:w-64"
    fill1="#ffffff"
    fill2="#ffffff"
  />
);
