import { ReactChild, useContext } from 'react';
import { OffersCuratedContext, TagOffersCurated } from '../offers-curated';

export interface TagButtonOffersCuratedStyleProps {
  backgroundColor?: string;
  textColor?: string;
  disabledBackgroundColor?: string;
  disabledTextColor?: string;
}

export const defaultStyles: TagButtonOffersCuratedStyleProps = {
  backgroundColor: '#666666',
  textColor: '#121212',
  disabledBackgroundColor: '#000',
  disabledTextColor: '#FFF',
};

export interface TagButtonOffersCuratedProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  tag: TagOffersCurated;
  label?: string;
}

export function TagButtonOffersCurated({
  tag,
  children,
  label,
  onClick,
  disabled = false,
}: TagButtonOffersCuratedProps) {
  const contextData = useContext(OffersCuratedContext);
  const styles = contextData?.styles?.tagButtons;
  const El = onClick ? `button` : `span`;
  return (
    <El
      onClick={onClick}
      data-element-id="curated-offers-pill"
      className="py-2 px-4 bg-primary rounded-full whitespace-nowrap"
      style={{
        backgroundColor: !disabled
          ? styles?.backgroundColor
          : styles?.disabledBackgroundColor,
        color: !disabled ? styles?.textColor : styles?.disabledTextColor,
      }}
      aria-label={label || tag?.label}
    >
      <span>{children || tag?.label}</span>
    </El>
  );
}
