import { HandleWYSIWYGContent } from '../functions/helper';
import { HeadingStyle } from '../includes/heading-style';
import { GenericModal } from '../global/generic-modal';
import { CroppedImage } from '@curated-property/utils';
import { AnchorLink } from '../global/anchor-link';
import { PinModalSettings } from './interactive-map.types';

export function PinModal({
  title,
  image,
  content,
  link,
  link2,
  linkText,
  active,
  setActive,
  modalSettings,
}: PinModalSettings) {
  const linkURL: string = link2?.url || link?.link || '';

  return (
    <div className="text-center">
      {active ? (
        <GenericModal
          modalTitle={title}
          modalChildStyles="bg-bg rounded-2xl overflow-hidden p-4 max-w-md"
          modalStyles="justify-start pt-12 overflow-auto cp-interactiveMap"
          modalChild={
            <>
              <CroppedImage
                className="w-full"
                src={image?.sourceUrl || ''}
                width={400}
                height={400}
                alt={image?.altText}
              />
              <HeadingStyle
                text={title}
                type="h2"
                className="mb-4 text-primary"
              />
              <div
                data-testid="pin-modal"
                className="mb-4 text-base text-text-alt"
                dangerouslySetInnerHTML={{
                  __html: HandleWYSIWYGContent(content),
                }}
              ></div>
              {linkText ? (
                <AnchorLink
                  url={linkURL}
                  title={linkText || ''}
                  target={link2?.target || '_self'}
                  buttonStyle="primary"
                  className=""
                />
              ) : (
                ''
              )}
            </>
          }
          setModalActive={setActive}
          modalActive={active}
          modalSettings={modalSettings}
        />
      ) : (
        false
      )}
    </div>
  );
}
