import dynamic from 'next/dynamic';

const HotelLogo = dynamic<{ className: string; fillColor?: string }>(() =>
  import('./sjdlchh/hotel-logo').then((i) => i.HotelLogo)
);

export const hotelLogoHeader = (
  <HotelLogo className="antialiased w-32 h-20" fillColor="#FFFFFF" />
);

export const hotelLogoFooter = (
  <HotelLogo className="fill-bg antialiased w-32 h-20" />
);
