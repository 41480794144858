import React from 'react';
export function BellhopTrolley({
  className,
  fillColor,
}: {
  className?: string;
  fillColor?: string;
}) {
  return (
    <svg
      className={className}
      style={{ fill: fillColor }}
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 24 24"
    >
      <g>
        <g>
          <path style={{ fill: 'none' }} d="M0,0v24h24V0H0z" />
        </g>
        <g>
          <g>
            <path
              d="M19,17.625H5c-0.2071,0-0.375,0.1679-0.375,0.375S4.7929,18.375,5,18.375h1.7825C6.6805,18.5677,6.6265,18.782,6.625,19
            c0,0.7594,0.6156,1.375,1.375,1.375S9.375,19.7594,9.375,19c-0.0015-0.218-0.0555-0.4323-0.1575-0.625h5.565
            c-0.102,0.1927-0.156,0.407-0.1575,0.625c0,0.7594,0.6156,1.375,1.375,1.375s1.375-0.6156,1.375-1.375
            c-0.0015-0.218-0.0555-0.4323-0.1575-0.625H19c0.2071,0,0.375-0.1679,0.375-0.375S19.2071,17.625,19,17.625z M8.625,19
            c0,0.3452-0.2798,0.625-0.625,0.625c-0.3452,0-0.625-0.2798-0.625-0.625S7.6548,18.375,8,18.375
            C8.345,18.3753,8.6247,18.655,8.625,19z M16.625,19c0,0.3452-0.2798,0.625-0.625,0.625c-0.3452,0-0.625-0.2798-0.625-0.625
            s0.2798-0.625,0.625-0.625C16.345,18.3753,16.6247,18.655,16.625,19z"
            />
            <path
              d="M12.5,8.625h-1C10.7411,8.6261,10.1261,9.2411,10.125,10v0.625H9C8.2411,10.6261,7.6261,11.2411,7.625,12v3
            C7.6261,15.7589,8.2411,16.3739,9,16.375h6c0.7589-0.0011,1.3739-0.6161,1.375-1.375v-3
            c-0.0011-0.7589-0.6161-1.3739-1.375-1.375h-1.125V10C13.8739,9.2411,13.2589,8.6261,12.5,8.625z M10.875,10
            c0.0003-0.345,0.28-0.6247,0.625-0.625h1c0.345,0.0003,0.6247,0.28,0.625,0.625v0.625h-2.25V10z M15.625,12v3
            c-0.0003,0.345-0.28,0.6247-0.625,0.625H9C8.655,15.6247,8.3753,15.345,8.375,15v-3c0.0003-0.345,0.28-0.6247,0.625-0.625h6
            C15.345,11.3753,15.6247,11.655,15.625,12z"
            />
            <path
              d="M6,16.375C6.2071,16.3749,6.3749,16.2071,6.375,16V9c0.0051-1.8005,1.3332-3.3234,3.1163-3.5735
            C8.3237,6.2421,7.6271,7.5758,7.625,9c0,0.2071,0.1679,0.375,0.375,0.375S8.375,9.2071,8.375,9c0-2.002,1.623-3.625,3.625-3.625
            S15.625,6.998,15.625,9c0,0.2071,0.1679,0.375,0.375,0.375c0.2071,0,0.375-0.1679,0.375-0.375
            c-0.0021-1.4242-0.6987-2.7579-1.8663-3.5735C16.2918,5.6766,17.6199,7.1995,17.625,9v7c0,0.2071,0.1679,0.375,0.375,0.375
            s0.375-0.1679,0.375-0.375V9C18.3722,6.5849,16.4151,4.6278,14,4.625h-1.625V4c0-0.2071-0.1679-0.375-0.375-0.375
            S11.625,3.7929,11.625,4v0.625H10C7.5849,4.6278,5.6278,6.5849,5.625,9v7C5.6251,16.2071,5.7929,16.3749,6,16.375z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
