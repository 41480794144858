import React from 'react';
const FitnessIcon = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg viewBox="0 0 44 44" focusable="false" {...props}>
      <g
        strokeWidth="1.5"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        stroke="currentColor"
      >
        <path d="M16.212 26.733h13.29v-6.37h-13.29zM30 34.169V10.812M30.289 10.808h4.247c1.172 0 2.123.95 2.123 2.124v19.11a2.123 2.123 0 0 1-2.123 2.123h-4.247M36.659 25.672h3.185c.586 0 1.062-.476 1.062-1.061v-4.247c0-.587-.476-1.062-1.062-1.062h-3.185M16 34.169V10.812M15.425 10.808h-4.247c-1.172 0-2.123.95-2.123 2.124v19.11c0 1.173.951 2.123 2.123 2.123h4.247M9.055 25.672H5.87a1.062 1.062 0 0 1-1.062-1.061v-4.247c0-.587.476-1.062 1.062-1.062h3.185" />
      </g>
    </svg>
  );
};

export default FitnessIcon;
