import React from 'react';
export function KeyDispenser({
  className,
  fillColor,
}: {
  className?: string;
  fillColor?: string;
}) {
  return (
    <svg
      className={className}
      style={{ fill: fillColor }}
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 24 24"
    >
      <g>
        <g>
          <path style={{ fill: 'none' }} d="M0,0v24h24V0H0z" />
        </g>
        <g>
          <g>
            <path
              d="M11.3994,6.7129c-1.0042,1.0089-1.2981,2.5252-0.7435,3.8363l-3.6481,3.649c-0.5088,0.5168-0.5023,1.3482,0.0145,1.8569
            c0.5113,0.5033,1.3318,0.5031,1.8429-0.0004l0.1336-0.1336l0.2239,0.2244c0.3915,0.388,1.0225,0.388,1.414,0l0.4424-0.4424
            c0.3896-0.3912,0.3896-1.0238,0-1.415l-0.2236-0.2236l0.8232-0.8233c0.1467-0.1467,0.1467-0.3845,0-0.5312
            s-0.3845-0.1467-0.5312,0L8.335,15.5234c-0.2195,0.2198-0.5756,0.22-0.7954,0.0005c-0.2198-0.2195-0.22-0.5756-0.0005-0.7954
            l3.5037-3.5037c1.1204,1.568,3.2998,1.9308,4.8678,0.8103c0.1563-0.1117,0.3032-0.2362,0.439-0.372l0.001-0.001
            c1.3667-1.3672,1.3662-3.5835-0.001-4.9502C14.9824,5.3452,12.7661,5.3457,11.3994,6.7129z M10.5479,14.8193
            c0.0974,0.097,0.0976,0.2546,0.0006,0.352c-0.0002,0.0002-0.0004,0.0004-0.0006,0.0006l-0.4415,0.4424
            c-0.0978,0.0969-0.2552,0.0973-0.3535,0.0009l-0.2238-0.2243l0.7951-0.7952L10.5479,14.8193z M15.8193,11.1318
            c-1.0742,1.0717-2.8138,1.0697-3.8855-0.0045s-1.0697-2.8138,0.0045-3.8855c1.0725-1.07,2.8086-1.07,3.8811,0
            C16.8915,8.3168,16.8915,10.0568,15.8193,11.1318z"
            />
            <path
              d="M13.5713,7.6338c-0.5129,0.5129-0.5129,1.3445,0,1.8574c0.5129,0.5129,1.3445,0.5129,1.8574,0
            c0.5129-0.5129,0.5129-1.3445,0-1.8574c0,0,0,0,0,0C14.9153,7.1221,14.0847,7.1221,13.5713,7.6338z M14.8975,8.96
            c-0.2195,0.2198-0.5757,0.22-0.7955,0.0005s-0.22-0.5757-0.0005-0.7955s0.5757-0.22,0.7955-0.0005
            c0.0002,0.0002,0.0003,0.0003,0.0005,0.0005c0.2195,0.2179,0.2209,0.5724,0.003,0.792C14.8995,8.958,14.8985,8.959,14.8975,8.96z
            "
            />
            <path
              d="M18,2.625H6C4.6889,2.6265,3.6265,3.6889,3.625,5v12C3.6265,18.3111,4.6889,19.3735,6,19.375h2.8867l2.9053,1.9375
            c0.1262,0.0833,0.2899,0.0833,0.416,0l2.9053-1.9375H18c1.3111-0.0015,2.3735-1.0639,2.375-2.375V5
            C20.3735,3.6889,19.3111,2.6265,18,2.625z M19.625,17c-0.0007,0.8972-0.7278,1.6243-1.625,1.625h-3
            c-0.074-0.0002-0.1464,0.0216-0.208,0.0625L12,20.5488l-2.792-1.8613C9.1464,18.6466,9.074,18.6248,9,18.625H6
            C5.1028,18.6243,4.3757,17.8972,4.375,17V5C4.3757,4.1028,5.1028,3.3757,6,3.375h12C18.8972,3.3757,19.6243,4.1028,19.625,5V17z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
