import React from 'react';
export function Cookie({
  className,
  fillColor,
}: {
  className?: string;
  fillColor?: string;
}) {
  return (
    <svg className={className} viewBox="0 0 24 24" style={{ fill: fillColor }}>
      <g data-name="Bounding Box">
        <path style={{ fill: 'none' }} d="M0,0V24H24V0Z" />
      </g>
      <g data-name="Icons Outlined">
        <g>
          <path d="M9.5,7.625A1.875,1.875,0,1,0,11.375,9.5,1.8767,1.8767,0,0,0,9.5,7.625Zm0,3A1.125,1.125,0,1,1,10.625,9.5,1.1266,1.1266,0,0,1,9.5,10.625Z" />
          <path d="M14,13.625A1.375,1.375,0,1,0,15.375,15,1.377,1.377,0,0,0,14,13.625Zm0,2A.625.625,0,1,1,14.625,15,.6256.6256,0,0,1,14,15.625Z" />
          <path d="M17.5,8.375A1.375,1.375,0,1,0,16.125,7,1.377,1.377,0,0,0,17.5,8.375Zm0-2A.625.625,0,1,1,16.875,7,.6256.6256,0,0,1,17.5,6.375Z" />
          <path d="M9.75,13.625a1.125,1.125,0,1,0,1.125,1.125A1.1266,1.1266,0,0,0,9.75,13.625Zm0,1.5a.375.375,0,1,1,.375-.375A.3751.3751,0,0,1,9.75,15.125Z" />
          <path d="M19.5,10.375a.875.875,0,1,0-.875-.875A.8761.8761,0,0,0,19.5,10.375Z" />
          <path d="M13.5,12.375a.875.875,0,1,0-.875-.875A.8761.8761,0,0,0,13.5,12.375Z" />
          <path d="M19.8027,12.8516a1.4935,1.4935,0,0,1-1.3134-1.4834l.0019-.0137a.3745.3745,0,0,0-.3486-.374,3.3723,3.3723,0,0,1-3.1114-3.3672,3.4179,3.4179,0,0,1,.1446-.9824.3747.3747,0,0,0-.209-.4512,1.5053,1.5053,0,0,1-.8994-1.38,1.5241,1.5241,0,0,1,.0791-.457.375.375,0,0,0-.2686-.4766A8.236,8.236,0,0,0,11.93,3.625a8.3753,8.3753,0,0,0,0,16.75A8.2725,8.2725,0,0,0,20.1289,13.28a.3753.3753,0,0,0-.3262-.4287ZM11.93,19.625a7.6253,7.6253,0,0,1,0-15.25,7.298,7.298,0,0,1,1.4082.1406,2.004,2.004,0,0,0-.0205.2842,2.2583,2.2583,0,0,0,1.0615,1.916,4.2,4.2,0,0,0-.0977.8975,4.1313,4.1313,0,0,0,3.4815,4.0791,2.2455,2.2455,0,0,0,1.5635,1.8222A7.52,7.52,0,0,1,11.93,19.625Z" />
        </g>
      </g>
    </svg>
  );
}
