import React from 'react';
export function Newspaper({
  className,
  fillColor,
}: {
  className?: string;
  fillColor?: string;
}) {
  return (
    <svg
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      fill={fillColor}
    >
      <path d="m0 0v24h24v-24z" fill="none" />
      <path d="m10 12.5h4c.276 0 .5-.224.5-.5v-4c0-.276-.224-.5-.5-.5h-4c-.276 0-.5.224-.5.5v4c0 .276.224.5.5.5zm.5-4h3v3h-3z" />
      <path d="m16 10.5h2c.276 0 .5-.224.5-.5s-.224-.5-.5-.5h-2c-.276 0-.5.224-.5.5s.224.5.5.5z" />
      <path d="m16 12.5h2c.276 0 .5-.224.5-.5s-.224-.5-.5-.5h-2c-.276 0-.5.224-.5.5s.224.5.5.5z" />
      <path d="m10 14.5h8c.276 0 .5-.224.5-.5s-.224-.5-.5-.5h-8c-.276 0-.5.224-.5.5s.224.5.5.5z" />
      <path d="m10 16.5h8c.276 0 .5-.224.5-.5s-.224-.5-.5-.5h-8c-.276 0-.5.224-.5.5s.224.5.5.5z" />
      <path d="m21 4.5h-14c-.276 0-.5.224-.5.5v12c0 .827-.673 1.5-1.5 1.5s-1.5-.673-1.5-1.5v-5c0-.276-.224-.5-.5-.5s-.5.224-.5.5v5c0 1.378 1.122 2.5 2.5 2.5h14c1.379 0 2.5-1.122 2.5-2.5v-12c0-.276-.224-.5-.5-.5zm-.5 12.5c0 .827-.673 1.5-1.5 1.5h-12.001c.314-.418.501-.938.501-1.5v-11.5h13z" />
      <path d="m5 17.5c.276 0 .5-.224.5-.5v-7c0-.276-.224-.5-.5-.5s-.5.224-.5.5v7c0 .276.224.5.5.5z" />
      <path d="m16 8.5h2c.276 0 .5-.224.5-.5s-.224-.5-.5-.5h-2c-.276 0-.5.224-.5.5s.224.5.5.5z" />
    </svg>
  );
}
