import React from 'react';
export function NoSmoking({
  className,
  fillColor,
}: {
  className?: string;
  fillColor?: string;
}) {
  return (
    <svg className={className} viewBox="0 0 24 24" style={{ fill: fillColor }}>
      <g data-name="Bounding Box">
        <path style={{ fill: 'none' }} d="M0,0V24H24V0Z" />
      </g>
      <g data-name="Icons Outlined">
        <g>
          <path d="M18,12.625a.3751.3751,0,0,0-.375.375v2a.375.375,0,0,0,.75,0V13A.3751.3751,0,0,0,18,12.625Z" />
          <path d="M19.9971,12.625a.3751.3751,0,0,0-.375.375v2a.375.375,0,1,0,.75,0V13A.3751.3751,0,0,0,19.9971,12.625Z" />
          <path d="M16,12.625H11.8279L8.3037,7.7793a.3754.3754,0,1,0-.6074.4414l8,11a.3754.3754,0,1,0,.6074-.4414L13.8279,15.375H16A.3751.3751,0,0,0,16.375,15V13A.3751.3751,0,0,0,16,12.625Zm-.375,2H13.2824l-.9091-1.25H15.625Z" />
          <path d="M11.0908,14.625H4.375v-1.25H9.6367a.375.375,0,0,0,0-.75H4A.3751.3751,0,0,0,3.625,13v2A.3751.3751,0,0,0,4,15.375h7.0908a.375.375,0,1,0,0-.75Z" />
          <path d="M17.9385,11.375a.3751.3751,0,0,0,.375-.375,1.3364,1.3364,0,0,0-1.375-1.375h-2A1.4261,1.4261,0,0,1,13.8564,9.23a2.0392,2.0392,0,0,1-.46-1.3223.3754.3754,0,0,0-.1123-.28.4082.4082,0,0,0-.2822-.1084,1.9647,1.9647,0,0,1-1.2911-.4346A1.4315,1.4315,0,0,1,11.3135,6,1.5631,1.5631,0,0,1,12.94,4.375a.375.375,0,0,0-.0019-.75A2.29,2.29,0,0,0,10.5635,6a2.1476,2.1476,0,0,0,.6308,1.6279,2.5428,2.5428,0,0,0,1.4717.625,2.59,2.59,0,0,0,.6465,1.4932,2.161,2.161,0,0,0,1.626.6289h1.997a.5906.5906,0,0,1,.628.625A.3751.3751,0,0,0,17.9385,11.375Z" />
          <path d="M19.9971,11.34a.3751.3751,0,0,0,.375-.375,3.5658,3.5658,0,0,0-.9131-2.6855,2.2388,2.2388,0,0,0-1.52-.6182H16.915a1.9086,1.9086,0,0,0,.4414-1.7783C16.9326,4.5967,15.0791,4.582,15,4.582a.375.375,0,0,0-.001.75c.0137,0,1.3946.0264,1.6446.7852.2548.7734-.8526,1.6074-.8633,1.6152a.3749.3749,0,0,0,.22.6787L17.958,8.41a1.49,1.49,0,0,1,.9844.413,2.869,2.869,0,0,1,.68,2.1416A.375.375,0,0,0,19.9971,11.34Z" />
        </g>
      </g>
    </svg>
  );
}
