import dynamic from 'next/dynamic';

const HotelLogo = dynamic<{ className: string; fillColor: string }>(() =>
  import('./pbiwphh/hotel-logo').then((i) => i.HotelLogo)
);

export const hotelLogoHeader = (
  <HotelLogo fillColor="#104C97" className="antialiased w-28" />
);

export const hotelLogoFooter = (
  <HotelLogo fillColor="#104C97" className="antialiased w-28" />
);
