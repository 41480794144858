import React from 'react';
export function Telephone({
  className,
  fillColor,
}: {
  className?: string;
  fillColor?: string;
}) {
  return (
    <svg className={className} viewBox="0 0 24 24" style={{ fill: fillColor }}>
      <g data-name="Bounding Box">
        <path style={{ fill: 'none' }} d="M0,0V24H24V0Z" />
      </g>
      <g data-name="Icons Outlined">
        <path d="M18.8594,15.6113l-2.5186-1.68a1.3031,1.3031,0,0,0-1.4775-.09l-1.31.7236A24.4973,24.4973,0,0,1,11.37,12.5859a23.78,23.78,0,0,1-1.9355-2.1386l.7246-1.3116a1.2775,1.2775,0,0,0-.0781-1.457L8.3828,5.1309a1.05,1.05,0,0,0-.9863-.5c-.5244.0234-1.5244.8945-2.0664,1.4043a1.6086,1.6086,0,0,0-.6856,1.5419c.3076,2.1758,1.8262,4.6563,4.3916,7.1719l.2744.2725c2.5528,2.57,4.9454,4.0283,7.0625,4.3232a.9415.9415,0,0,0,.2549.03,1.7865,1.7865,0,0,0,1.33-.6973c.5166-.55,1.3877-1.55,1.4024-2.01A1.0683,1.0683,0,0,0,18.8594,15.6113Zm-.2393.9522a6.5818,6.5818,0,0,1-1.2158,1.6084c-.4629.52-.8232.4492-.876.4414-1.376-.1944-3.6533-1.0674-6.6855-4.12-.0821-.083-.1426-.1436-.1621-.1612l-.1192-.1181c-2.4121-2.3653-3.8945-4.76-4.1816-6.78-.0039-.0147-.0713-.376.456-.8447A5.9916,5.9916,0,0,1,7.4775,5.375h.0108a.3573.3573,0,0,1,.2773.1816L9.4922,8.1387a.551.551,0,0,1,.01.6347l-.8458,1.5313a.3749.3749,0,0,0,.0342.4141,26.0538,26.0538,0,0,0,2.1729,2.42A26.12,26.12,0,0,0,13.2822,15.31a.3749.3749,0,0,0,.4141.0342l1.5293-.8448a.5512.5512,0,0,1,.64.0127.2892.2892,0,0,0,.04.03l2.5283,1.6865C18.6348,16.3721,18.626,16.5078,18.62,16.5635Z" />
      </g>
    </svg>
  );
}
