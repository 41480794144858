import dynamic from 'next/dynamic';

const HotelLogo = dynamic<{ className?: string; fillColor: string }>(() =>
  import('./rbacici/hotel-logo').then((i) => i.HotelLogo)
);

export const hotelLogoHeader = (
  <HotelLogo
    fillColor="#bc9b6a"
    className="fill-current text-current antialiased w-32 md:w-40"
  />
);

export const hotelLogoFooter = (
  <HotelLogo
    fillColor="#000000"
    className="fill-current text-current antialiased  w-32 md:w-36"
  />
);
