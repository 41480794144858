import React from 'react';
export function Lift({
  className,
  fillColor,
}: {
  className?: string;
  fillColor?: string;
}) {
  return (
    <svg className={className} viewBox="0 0 24 24" style={{ fill: fillColor }}>
      <g data-name="Bounding Box">
        <path style={{ fill: 'none' }} d="M0,0V24H24V0Z" />
      </g>
      <g data-name="Icons Outlined">
        <g>
          <path d="M8,14.625A.3751.3751,0,0,0,7.625,15v3a.375.375,0,0,0,.75,0V15A.3751.3751,0,0,0,8,14.625Z" />
          <path d="M10,14.625A.3751.3751,0,0,0,9.625,15v3a.375.375,0,0,0,.75,0V15A.3751.3751,0,0,0,10,14.625Z" />
          <path d="M10.1756,11.9489a1.875,1.875,0,1,0-2.3512,0A2.3671,2.3671,0,0,0,6.625,14v2a.375.375,0,0,0,.75,0V14a1.625,1.625,0,0,1,3.25,0v2a.375.375,0,0,0,.75,0V14A2.3671,2.3671,0,0,0,10.1756,11.9489ZM9,11.625A1.125,1.125,0,1,1,10.125,10.5,1.1266,1.1266,0,0,1,9,11.625Z" />
          <path d="M14,14.625a.3751.3751,0,0,0-.375.375v3a.375.375,0,0,0,.75,0V15A.3751.3751,0,0,0,14,14.625Z" />
          <path d="M16,14.625a.3751.3751,0,0,0-.375.375v3a.375.375,0,0,0,.75,0V15A.3751.3751,0,0,0,16,14.625Z" />
          <path d="M16.1756,11.9489a1.875,1.875,0,1,0-2.3512,0A2.3671,2.3671,0,0,0,12.625,14v2a.375.375,0,0,0,.75,0V14a1.625,1.625,0,0,1,3.25,0v2a.375.375,0,0,0,.75,0V14A2.3671,2.3671,0,0,0,16.1756,11.9489ZM15,11.625A1.125,1.125,0,1,1,16.125,10.5,1.1266,1.1266,0,0,1,15,11.625Z" />
          <path d="M8,5.375a.3757.3757,0,0,0,.208-.0625L9.5,4.4512l1.292.8613a.3754.3754,0,0,0,.416-.625l-1.5-1-.0009,0a.3771.3771,0,0,0-.1342-.0539,8.7606,8.7606,0,0,0-.145,0c-.01.0019-.0191.0088-.0292.0116a.3738.3738,0,0,0-.1067.0427l-1.5,1A.3751.3751,0,0,0,8,5.375Z" />
          <path d="M14.292,5.3125c.0053.0035.012.002.0174.0052a.3457.3457,0,0,0,.3812,0c.0054-.0032.0121-.0017.0174-.0052l1.5-1a.3754.3754,0,0,0-.416-.625L14.5,4.5488l-1.292-.8613a.3754.3754,0,1,0-.416.625Z" />
          <path d="M18,6.625H6A1.377,1.377,0,0,0,4.625,8V19A1.377,1.377,0,0,0,6,20.375H18A1.377,1.377,0,0,0,19.375,19V8A1.377,1.377,0,0,0,18,6.625ZM18.625,19a.6256.6256,0,0,1-.625.625H6A.6256.6256,0,0,1,5.375,19V8A.6256.6256,0,0,1,6,7.375H18A.6256.6256,0,0,1,18.625,8Z" />
        </g>
      </g>
    </svg>
  );
}
