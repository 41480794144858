import { GenericModal } from '../global/generic-modal';
import OscComposableSearchForm, {
  GroupBooking,
} from '../osc-composable/osc-composable-search-form';
import { useState } from 'react';
import cx from 'classnames';
import { CMSOptions, ModalSettings } from '../header';

interface IBookButtonStyles {
  buttonStyle?: string | null;
  backgroundColor?: string | null;
  backgroundColorHover?: string | null;
  textColor?: string | null;
  textColorHover?: string | null;
  borderColor?: string | null;
  borderColorHover?: string | null;
  textCase?: string;
}

interface Props {
  text?: string;
  mobileText?: string;
  mainClasses?: string;
  className?: string;
  defaultArrivalDate?: string;
  gmtHours?: number;
  CTYHOCN: string;
  brandCode?: string;
  currency?: string;
  associatedHotels?: {
    hotel?: {
      name?: string;
      ctyhocn?: string;
    };
  }[];
  activeHeader: (arg: boolean) => void;
  styleOptions?: IBookButtonStyles;
  modalSettings?: ModalSettings;
  cmsBookStyle?: CMSOptions;
  oscBookBtnGlobalSetting?: string;
  maxRooms?: number;
  groupBooking?: GroupBooking;
  hideGroupLink?: boolean;
}

export function BookingWidgetButton({
  text,
  mobileText,
  mainClasses,
  className,
  defaultArrivalDate,
  gmtHours,
  CTYHOCN,
  brandCode,
  currency,
  associatedHotels,
  activeHeader,
  styleOptions,
  modalSettings,
  cmsBookStyle,
  oscBookBtnGlobalSetting,
  maxRooms,
  groupBooking,
  hideGroupLink,
}: Props) {
  const [modalActive, setModalActive] = useState(false);
  const [hover, setHover] = useState(false);
  const fontSizeLogic = brandCode === 'HI' ? 'xl:text-[19px]' : 'xl:text-lg';

  // Note UK/US spelling differences

  // Text Color
  let textColor = undefined;
  if (styleOptions?.textColor) textColor = styleOptions?.textColor;
  if (cmsBookStyle?.textColour) textColor = cmsBookStyle?.textColour;

  let textColorHover = undefined;
  if (styleOptions?.textColorHover)
    textColorHover = styleOptions?.textColorHover;
  if (cmsBookStyle?.textColourHover)
    textColorHover = cmsBookStyle?.textColourHover;

  // Background
  let bgColor = undefined;
  if (styleOptions?.backgroundColor) bgColor = styleOptions?.backgroundColor;
  if (cmsBookStyle?.backgroundColour) bgColor = cmsBookStyle?.backgroundColour;

  let bgColorHover = undefined;
  if (styleOptions?.backgroundColorHover)
    bgColorHover = styleOptions?.backgroundColorHover;
  if (cmsBookStyle?.backgroundColourHover)
    bgColorHover = cmsBookStyle?.backgroundColourHover;

  // Border
  let borderColor = undefined;
  if (styleOptions?.borderColor) borderColor = styleOptions?.borderColor;
  if (cmsBookStyle?.borderColour) borderColor = cmsBookStyle?.borderColour;

  let borderColorHover = undefined;
  if (styleOptions?.borderColorHover)
    borderColorHover = styleOptions?.borderColorHover;
  if (cmsBookStyle?.borderColourHover)
    borderColorHover = cmsBookStyle?.borderColourHover;

  const textCase = styleOptions?.textCase || '';

  const borderCMSSet = hover
    ? borderColorHover || undefined
    : borderColor || undefined;
  const borderCMSNotSet = hover ? bgColorHover : bgColor;

  // A CMS button style will take precedence over a prop set in app-props files
  let mainButtonClass = 'btn-secondary';
  if (styleOptions?.buttonStyle)
    mainButtonClass = `btn-${styleOptions?.buttonStyle}`;
  if (cmsBookStyle?.buttonStyle && cmsBookStyle?.buttonStyle)
    mainButtonClass = `btn-${cmsBookStyle?.buttonStyle}`;

  const mainStyleClasses = !mainButtonClass
    ? mainClasses
    : cx(mainButtonClass, 'bg-secondary-alt border-secondary-alt text-bg');

  return (
    <>
      <button
        className={cx(
          'bookingWidgetButton',
          `btn ${mainStyleClasses} text-base ${fontSizeLogic} text-center py-2 px-2 md:px-6 w-full transition duration-300`,
          className
        )}
        style={{
          backgroundColor: hover ? bgColorHover : bgColor,
          borderColor:
            borderCMSSet === undefined ? borderCMSNotSet : borderCMSSet,
        }}
        data-testid="booking-widget-modal-trigger"
        onClick={(e) => setModalActive(!modalActive)}
        onMouseEnter={() => {
          activeHeader(true);
          setHover(true);
        }}
        onMouseLeave={() => {
          activeHeader(false);
          setHover(false);
        }}
        aria-haspopup="true"
      >
        <span
          className={`hidden lg:block ${textCase}`}
          style={{
            color: hover ? textColorHover : textColor,
          }}
          suppressHydrationWarning
        >
          {text}
        </span>
        <span
          className={`block lg:hidden ${textCase}`}
          style={{
            color: hover ? textColorHover : textColor,
          }}
          suppressHydrationWarning
        >
          {mobileText}
        </span>
      </button>
      {modalActive ? (
        <GenericModal
          isBookingWidget={true}
          modalTitle={text || ''}
          setModalActive={setModalActive}
          modalActive={modalActive}
          modalChild={
            <OscComposableSearchForm
              ctyhocn={CTYHOCN}
              defaultArrivalDate={defaultArrivalDate}
              gmtHours={gmtHours}
              brandCode={brandCode}
              currency={currency}
              associatedHotels={associatedHotels}
              parentElement="[data-reach-dialog-content]"
              oscBookButtonStyle={oscBookBtnGlobalSetting}
              isInModal={true}
              maxRooms={maxRooms}
              groupBooking={groupBooking}
              hideGroupLink={hideGroupLink}
            />
          }
          modalChildStyles="bg-bg p-3 md:pt-8 md:pb-10 lg:py-8 generic-modal-booking-widget"
          modalStyles="justify-start lg:justify-center pt-12"
          modalSettings={modalSettings}
        />
      ) : (
        false
      )}
    </>
  );
}
