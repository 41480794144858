import React from 'react';
export function Shopping({
  className,
  fillColor,
}: {
  className?: string;
  fillColor?: string;
}) {
  return (
    <svg className={className} viewBox="0 0 24 24" style={{ fill: fillColor }}>
      <g data-name="Bounding Box">
        <path style={{ fill: 'none' }} d="M0,0V24H24V0Z" />
      </g>
      <g data-name="Icons Outlined">
        <g>
          <path d="M8,18.625H5.375V8.375h10.25V11a.375.375,0,0,0,.75,0V8A.3751.3751,0,0,0,16,7.625H5A.3751.3751,0,0,0,4.625,8V19A.3751.3751,0,0,0,5,19.375H8a.375.375,0,0,0,0-.75Z" />
          <path d="M19,12.625H9.8682a.3751.3751,0,0,0-.375.375v7a.3751.3751,0,0,0,.375.375H19A.3751.3751,0,0,0,19.375,20V13A.3751.3751,0,0,0,19,12.625Zm-.375,7H10.2432v-6.25H18.625Z" />
          <path d="M8,6.875A.3751.3751,0,0,0,8.375,6.5a2.125,2.125,0,0,1,4.25,0,.375.375,0,0,0,.75,0,2.875,2.875,0,0,0-5.75,0A.3751.3751,0,0,0,8,6.875Z" />
          <path d="M14.25,16.875a2.1272,2.1272,0,0,0,2.125-2.125.375.375,0,0,0-.75,0,1.375,1.375,0,0,1-2.75,0,.375.375,0,0,0-.75,0A2.1272,2.1272,0,0,0,14.25,16.875Z" />
        </g>
      </g>
    </svg>
  );
}
