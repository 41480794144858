import dynamic from 'next/dynamic';

const HotelLogo = dynamic<{ className: string; fillColor: string }>(() =>
  import('./rjkochi/hotel-logo').then((i) => i.HotelLogo)
);

export const hotelLogoHeader = (
  <HotelLogo className="text-bg antialiased w-28" fillColor="#426376" />
);

export const hotelLogoFooter = (
  <HotelLogo className="text-bg antialiased w-32" fillColor="#426376" />
);
