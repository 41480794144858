import React from 'react';
export function UserCircle({
  className,
  fillColor,
}: {
  className?: string;
  fillColor?: string;
}) {
  return (
    <svg className={className} viewBox="0 0 24 24" style={{ fill: fillColor }}>
      <g data-name="Bounding Box">
        <path style={{ fill: 'none' }} d="M0,0V24H24V0Z" />
      </g>
      <g data-name="Icons Outlined">
        <g>
          <path d="M12,3.625A8.375,8.375,0,1,0,20.375,12,8.3842,8.3842,0,0,0,12,3.625Zm0,16A7.625,7.625,0,1,1,19.625,12,7.6341,7.6341,0,0,1,12,19.625Z" />
          <path d="M13.54,12.4192a2.875,2.875,0,1,0-3.08,0A4.3716,4.3716,0,0,0,7.625,16.5a.375.375,0,0,0,.75,0,3.625,3.625,0,0,1,7.25,0,.375.375,0,0,0,.75,0A4.3715,4.3715,0,0,0,13.54,12.4192ZM12,12.125A2.125,2.125,0,1,1,14.125,10,2.1272,2.1272,0,0,1,12,12.125Z" />
        </g>
      </g>
    </svg>
  );
}
