import { AccordionSectionProps } from './hotel-policies.types';
import { SectionRow } from './section-row';
import { Accordion } from '@dx-ui/osc-accordion';
import cx from 'classnames';
export function AccordionSection({
  panelKey,
  panelLabel,
  panelRepeater,
  panelStyles,
}: AccordionSectionProps) {
  return (
    <Accordion
      key={`accordion${panelKey}`}
      className={cx(
        'accordion-stacked w-full flex py-4 px-2 justify-between text-2xl font-extrabold align-center border-text border-t-[1px] aria-expanded:border-b-[1px] [&_span]:flex [&_span]:items-center [&>span_svg]:transition-all [&>span_svg]:duration-150 [&>span_svg]:ease-in-out [&>span_svg]:aria-expanded:-rotate-180'
      )}
      collapsedButtonLabel={panelLabel}
      expandedButtonLabel={panelLabel}
    >
      <div
        key={`accordionSection${panelKey}`}
        className="accordion-section p-2 text-lg"
        data-testid="accordion-section"
        style={{ backgroundColor: panelStyles?.hotelOpenPanelBackgroundColour }}
      >
        <ul key={`panelUl${panelKey}`}>
          {panelRepeater?.map((i, sectionIndex) => {
            return i?.lineLabel || i?.lineValue || i?.lineCopy ? (
              <SectionRow
                key={`liSectionRow${sectionIndex.toString()}`}
                keyNumber={`sectionRow${sectionIndex.toString()}`}
                rowLabel={i?.lineLabel}
                rowText={i?.lineValue?.toString()}
                rowCopy={i?.lineCopy}
                rowUrl={i?.lineUrl}
                rowMultiLine={i?.lineList}
              />
            ) : (
              ''
            );
          })}
        </ul>
      </div>
    </Accordion>
  );
}
