import React from 'react';
export function LuggageTrolley({
  className,
  fillColor,
}: {
  className?: string;
  fillColor?: string;
}) {
  return (
    <svg
      className={className}
      style={{ fill: fillColor }}
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 24 24"
    >
      <g>
        <g>
          <path style={{ fill: 'none' }} d="M0,0v24h24V0H0z" />
        </g>
        <g>
          <g>
            <path
              d="M5,15.375h8c0.7589-0.0011,1.3739-0.6161,1.375-1.375V9C14.3739,8.2411,13.7589,7.6261,13,7.625h-2.125V7
            C10.8739,6.2411,10.2589,5.6261,9.5,5.625h-1C7.7411,5.6261,7.1261,6.2411,7.125,7v0.625H5C4.2411,7.6261,3.6261,8.2411,3.625,9
            v5C3.6261,14.7589,4.2411,15.3739,5,15.375z M7.875,7C7.8753,6.655,8.155,6.3753,8.5,6.375h1
            C9.845,6.3753,10.1247,6.655,10.125,7v0.625h-2.25V7z M7.375,10.375h0.25v1.25h-0.25V10.375z M4.375,9
            C4.3753,8.655,4.655,8.3753,5,8.375h2.125v1.25H7C6.7929,9.6251,6.6251,9.7929,6.625,10v2
            C6.6251,12.2071,6.7929,12.3749,7,12.375h1C8.2071,12.3749,8.3749,12.2071,8.375,12v-2C8.3749,9.7929,8.2071,9.6251,8,9.625
            H7.875v-1.25H13c0.345,0.0003,0.6247,0.28,0.625,0.625v5c-0.0003,0.345-0.28,0.6247-0.625,0.625H5
            C4.655,14.6247,4.3753,14.345,4.375,14V9z"
            />
            <path
              d="M20,7.625h-2C16.6889,7.6265,15.6265,8.6889,15.625,10v5c-0.0007,0.8972-0.7278,1.6243-1.625,1.625H4
            c-0.2071,0-0.375,0.1679-0.375,0.375S3.7929,17.375,4,17.375h1.7825C5.6805,17.5677,5.6265,17.782,5.625,18
            c0,0.7594,0.6156,1.375,1.375,1.375S8.375,18.7594,8.375,18c-0.0015-0.218-0.0555-0.4323-0.1575-0.625h3.565
            c-0.102,0.1927-0.156,0.407-0.1575,0.625c0,0.7594,0.6156,1.375,1.375,1.375s1.375-0.6156,1.375-1.375
            c-0.0016-0.226-0.0596-0.448-0.1688-0.6459c1.2237-0.1043,2.165-1.126,2.1688-2.3541v-5C16.3757,9.1028,17.1028,8.3757,18,8.375
            h2c0.2071,0,0.375-0.1679,0.375-0.375S20.2071,7.625,20,7.625z M7.625,18c0,0.3452-0.2798,0.625-0.625,0.625
            S6.375,18.3452,6.375,18S6.6548,17.375,7,17.375C7.345,17.3753,7.6247,17.655,7.625,18z M13,18.625
            c-0.3452,0-0.625-0.2798-0.625-0.625s0.2798-0.625,0.625-0.625s0.625,0.2798,0.625,0.625
            C13.6247,18.345,13.345,18.6247,13,18.625z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
