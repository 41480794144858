import dynamic from 'next/dynamic';

const HotelLogo = dynamic<{ className: string; fillColor: string }>(() =>
  import('./romhiwa/hotel-logo').then((i) => i.HotelLogo)
);

export const hotelLogoHeader = (
  <HotelLogo
    className="fill-current text-current antialiased w-28 lg:w-40 mr-0 lg:mr-4"
    fillColor="#000000"
  />
);

export const hotelLogoFooter = (
  <HotelLogo
    className="fill-current text-current antialiased w-28 lg:w-40"
    fillColor="#ffffff"
  />
);
