import React from 'react';
export function Golf({
  className,
  fillColor,
}: {
  className?: string;
  fillColor?: string;
}) {
  return (
    <svg className={className} viewBox="0 0 24 24" style={{ fill: fillColor }}>
      <g data-name="Bounding Box">
        <path style={{ fill: 'none' }} d="M0,0V24H24V0Z" />
      </g>
      <g data-name="Icons Outlined">
        <g>
          <path d="M20.3491,6.2452a.3653.3653,0,0,0-.0274-.0673c-.0041-.0073-.0045-.0155-.0092-.0226a.3479.3479,0,0,0-.0345-.0342.2885.2885,0,0,0-.1185-.09.3645.3645,0,0,0-.0413-.0236c-.0086-.003-.0172-.0011-.0259-.0035a.38.38,0,0,0-.06-.008.35.35,0,0,0-.09,0,.4093.4093,0,0,0-.0451.0126.3653.3653,0,0,0-.0862.0347c-.0061.0034-.0131.0037-.019.0076-1.2666.8457-2.62-.2813-4.0518-1.4756-1.3594-1.1332-2.8841-2.3931-4.4385-1.7861A.3683.3683,0,0,0,11,2.625.3751.3751,0,0,0,10.625,3V19a.375.375,0,0,0,.75,0V9.47c.9765-1.8523,2.9653-1.7178,4.8906-1.5779C17.97,8.014,19.72,8.13,20.3121,6.5708l.003-.006c.0115-.0306.03-.0515.04-.0834.0024-.0072.0007-.0145.0027-.0217a.3682.3682,0,0,0,.01-.0791.3641.3641,0,0,0,.0005-.066A.36.36,0,0,0,20.3491,6.2452Zm-4.03.8984c-1.6514-.12-3.6377-.2571-4.9443,1.0557V3.5963c1.22-.6471,2.5155.4136,3.8848,1.5551,1.072.8936,2.2465,1.8617,3.4583,1.936A7.6558,7.6558,0,0,1,16.3193,7.1436Z" />
          <path d="M13.1729,17.667a.3752.3752,0,0,0-.3458.666c.5069.2627.7979.6065.7979.9434,0,.6377-1.0781,1.3486-2.625,1.3486s-2.625-.7109-2.625-1.3486c0-.3369.291-.6807.7979-.9434a.3752.3752,0,1,0-.3458-.666A1.9176,1.9176,0,0,0,7.625,19.2764c0,1.1767,1.4824,2.0986,3.375,2.0986s3.375-.9219,3.375-2.0986A1.9176,1.9176,0,0,0,13.1729,17.667Z" />
        </g>
      </g>
    </svg>
  );
}
