import {
  InavMenuItem,
  removeSingleCmsSiteId,
} from '@curated-property/shared-components';

export interface WpMenuItem {
  node?: {
    childItems?: WpMenu | null;
    databaseId?: number;
    label?: string;
    label_noTx?: string;
    NoTranslate?: {
      noTx?: boolean;
    };
    path?: string;
    connectedNode?: {
      node?: {
        databaseId?: number;
      };
    };
    menu?: {
      node?: {
        locations?: string[];
        count?: number;
      };
    };
    iconSelect?: {
      iconSelect?: string;
    };
  };
}

export interface WpMenu {
  edges?: WpMenuItem[];
  menuItems?: { edges?: WpMenuItem[] };
}

export interface WpPage {
  title?: string;
  slug?: string;
  isFrontPage?: boolean;
  parent?: {
    node?: WpPage;
  };
}

export interface LangPremapMenu {
  menu?: {
    menuItems?: WpMenu;
    languageSelector?: {
      languageNavAccentColour?: string;
      languageNavTextColour?: string;
    };
  };
}

export interface WpMenuItemLink {
  label?: string | undefined;
  label_noTx?: string | undefined;
  url?: string | undefined;
  noTx?: boolean | undefined;
  childItems?: WpMenuItemLink[] | undefined;
  icon?: string | undefined;
  location?: string[] | undefined;
  pageId?: number | undefined;
  target?: string | undefined;
}

export function wpMenuItemMap(menuItem: WpMenuItem): InavMenuItem {
  const menuItems = wpMenuMap(menuItem?.node?.childItems);

  const theUrl = menuItem?.node?.path;
  const theUrlNew = removeSingleCmsSiteId(theUrl);

  return {
    label: menuItem?.node?.label,
    label_noTx: menuItem?.node?.label_noTx,
    // url: menuItem?.node?.path,
    url: theUrlNew, //menuItem?.node?.path,
    noTx: menuItem?.node?.NoTranslate?.noTx,
    childItems: menuItems,
    icon: menuItem?.node?.iconSelect?.iconSelect,
    location: menuItem?.node?.menu?.node?.locations,
    pageId: menuItem?.node?.connectedNode?.node?.databaseId,
  };
}

export function wpMenuMap(
  menuItems: WpMenu | undefined | null
): InavMenuItem[] | [] | undefined {
  const menu = menuItems?.edges?.map((item) => wpMenuItemMap(item));
  return menu;
}

export function wpPageHierarchyMap(
  page: WpPage,
  array: Array<{
    title?: string;
    slug?: string;
    isFrontPage?: boolean;
  }> = [],
  first = true
) {
  array.push({
    title: page?.title,
    slug: page?.slug,
    isFrontPage: page?.isFrontPage,
  });

  if (page?.parent?.node) {
    wpPageHierarchyMap(page?.parent?.node, array, false);
  }
  if (first) {
    array.reverse();
  }
  return array;
}
