import React, { ReactElement, useEffect, useState } from 'react';

interface WrappedHeadingProps {
  header: 'h1' | 'h2';
  children: ReactElement;
  className?: string;
  lang?: string;
}

export function WrappedHeading({
  header,
  children,
  className,
  lang,
}: WrappedHeadingProps) {
  const [domLoaded, setDomLoaded] = useState(false);
  const VariableHeading = `${header}` as any;
  useEffect(() => {
    setDomLoaded(true);
  }, []);

  if (domLoaded) {
    return (
      <VariableHeading
        className={className}
        data-testid={`heading-${header?.[1]}`}
        lang={lang || null}
      >
        {children}
      </VariableHeading>
    );
  } else return <h1 data-testid={`heading-1`}>{children}</h1>;
}
