import { MenuProps } from '@curated-property/shared-components';
import dynamic from 'next/dynamic';
import { VistaTelAvivLogo } from '@curated-property/icons';
import { AdditionalHeadProps } from '../pages/_app';

export const AdditionalHead = ({ pageSlug }: AdditionalHeadProps) => {
  if (pageSlug === 'the-vista-at-hilton-tel-aviv') {
    return (
      <>
        <style>{`
            [data-element-id='booking-widget-day-button'] {
              color: #00202f !important;
            }
            .font-headline {
              font-family: Loew Heavy !important;
            }
            body:not(.font-headline), .font-normal, [data-element-id="subtitle-wrapper"] {
              font-family: Loew Medium !important;
            }
          `}</style>
      </>
    );
  }
};

const brandLogo = (
  <VistaTelAvivLogo
    location="header"
    className="hidden sm:block w-32 sm:w-44 pl-4 mr-4"
  />
);

const SharedHeader = dynamic<MenuProps>(() =>
  import('@curated-property/shared-components').then((h) => h.Header)
);

export const Header = (props: MenuProps) => {
  return (
    <SharedHeader
      {...props}
      brandLogo={
        props?.pageSlug === 'the-vista-at-hilton-tel-aviv' ? brandLogo : null
      }
    />
  );
};
