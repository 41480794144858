import React, { useEffect } from 'react';
import { sanitize } from '@curated-property/utils';
import { useTranslation } from 'next-i18next';
import cx from 'classnames';
import { Accordion } from '@dx-ui/osc-accordion';
import {
  GIS_Array,
  GIS_TextAlignment,
  GIS_Padder,
  StyleObject,
} from './functions/global-instance-styles';
import { HandleWYSIWYGContent } from './functions/helper';
import {
  makeRandomInt,
  WrappedSubtitle,
  appliedCloudinaryParams,
} from '@curated-property/utils';
import { HeadingStyle } from './includes/heading-style';

interface SectionRowProps {
  rowLabel?: null | string;
  rowText?: any;
}

function SectionRow({ rowLabel, rowText }: SectionRowProps) {
  return (
    <li className={cx('w-full py-2 block', rowLabel !== null && 'sm:flex')}>
      {rowLabel !== null && (
        <div
          className={cx(
            'block w-full  font-extrabold',
            rowText ? 'sm:w-1/2 lg:w-1/3' : ''
          )}
        >
          {rowLabel}
        </div>
      )}
      {rowText && (
        <div
          className={cx(
            'block w-full ',
            rowLabel !== null && 'sm:w-1/2 lg:w-2/3'
          )}
          dangerouslySetInnerHTML={{
            __html: HandleWYSIWYGContent(rowText),
          }}
        />
      )}
    </li>
  );
}

interface SectionProps {
  panelLabel?: string;
  panelRepeater?: any;
}

function AccordionSection({ panelLabel, panelRepeater }: SectionProps) {
  return (
    <Accordion
      className={cx(
        'accordion-stacked w-full flex py-4 px-2 justify-between text-2xl font-extrabold align-center border-text border-t-[1px] aria-expanded:border-b-[1px] [&_span]:flex [&_span]:items-center [&>span_svg]:transition-all [&>span_svg]:duration-150 [&>span_svg]:ease-in-out [&>span_svg]:aria-expanded:-rotate-180' // styles.panelButton
      )}
      collapsedButtonLabel={panelLabel}
      expandedButtonLabel={panelLabel}
    >
      <div className="px-2 text-lg" data-testid="accordion-section">
        <ul>
          {panelRepeater?.map((i, key) => {
            return (
              <SectionRow
                key={key}
                rowLabel={i?.rowLabel}
                rowText={i?.rowText}
              />
            );
          })}
        </ul>
      </div>
    </Accordion>
  );
}

export interface AccordionProps {
  content?: string;
  heading?: string;
  subtitle?: string;
  panel?: any;
  instanceStyles?: StyleObject;
  globalStyles?: StyleObject;
}

export function AccordionComponent({
  content,
  heading,
  subtitle,
  panel,
  instanceStyles,
  globalStyles,
}: AccordionProps) {
  const { t } = useTranslation();
  const inlineStyles = GIS_Array(globalStyles, instanceStyles);
  const paddingStyles = GIS_Padder(
    inlineStyles?.paddingTop,
    inlineStyles?.paddingBottom
  );
  const textAlignment = GIS_TextAlignment(inlineStyles?.textAlignment);
  const styleRandomInt = makeRandomInt().toString();
  const styleIdPrefix = 'accordionComponent';
  const componentStyleID = `${styleIdPrefix}${styleRandomInt}`;
  const styleElementID = `${styleIdPrefix}Style${styleRandomInt}`;
  const mainSelector = `#${componentStyleID} [data-osc="accordion-component"]`;
  // This will be injected into <head> on an instance-by-instance bases
  let styleString = '';

  const accordionBorderColour = inlineStyles?.accordionBorderColour || '';

  accordionBorderColour &&
    (styleString += `${mainSelector} button.accordion-stacked { border-color:${accordionBorderColour} !important; } `);

  inlineStyles?.textColor &&
    (styleString += `${mainSelector} button.accordion-stacked,${mainSelector} button.accordion-stacked + section div {color: ${inlineStyles?.textColor}; }`);

  const accordionOpenPanelBackgroundColour =
    inlineStyles?.accordionOpenPanelBackgroundColour || '';
  accordionOpenPanelBackgroundColour &&
    (styleString += `${mainSelector} button.accordion-stacked[aria-expanded="true"] + section  {background-color:${accordionOpenPanelBackgroundColour};} `);

  // Default icon color is black.
  const accordionIconIndicatorColour =
    inlineStyles?.accordionIconIndicatorColour || '#000';

  styleString += `${mainSelector} button.accordion-stacked[aria-expanded="false"] > span svg g path { stroke:${accordionIconIndicatorColour} !important; }`;
  styleString += `${mainSelector} button.accordion-stacked[aria-expanded="false"] > span svg > g polyline { color:${accordionIconIndicatorColour} !important; }`;
  styleString += `${mainSelector} button.accordion-stacked > span svg > g { fill: none !important; }`;

  // The expanded state setting uses the unexpanded state setting absent a specific setting of its own.
  const accordionIconIndicatorColourExpanded =
    !inlineStyles?.accordionIconIndicatorColourExpanded
      ? accordionIconIndicatorColour
      : inlineStyles?.accordionIconIndicatorColourExpanded;

  styleString += `${mainSelector} button.accordion-stacked[aria-expanded="true"] > span svg g path { stroke:${accordionIconIndicatorColourExpanded} !important; }`;
  styleString += `${mainSelector} button.accordion-stacked[aria-expanded="true"] > span svg > g polyline { stroke:${accordionIconIndicatorColourExpanded} !important; }`;

  // Adds style to <head>
  useEffect(() => {
    const $style = document.createElement('style');
    $style.setAttribute('id', styleElementID);
    document.head.appendChild($style);
    $style.innerHTML = styleString;
  }, [styleElementID, styleString]);

  return (
    <div
      id={componentStyleID}
      className={cx('cp-accordion', inlineStyles?.showHide && 'hidden')}
    >
      <div
        data-osc="accordion-component"
        className={cx('cp-accordion-wrapper', paddingStyles)}
        style={{
          backgroundImage: inlineStyles?.componentBackgroundImage
            ? `url(${appliedCloudinaryParams(
                inlineStyles?.componentBackgroundImage,
                inlineStyles?.componentBackgroundRepeat
              )})`
            : null,
          backgroundSize: inlineStyles?.componentBackgroundSize || 'cover',
          backgroundRepeat:
            inlineStyles?.componentBackgroundRepeat || 'no-repeat',
          backgroundPosition:
            inlineStyles?.componentBackgroundPosition || 'top left',
          backgroundColor: inlineStyles?.componentBackgroundColor || null,
        }}
      >
        <div
          className={cx('cp-accordion-content container py-8')}
          style={{
            backgroundImage: inlineStyles?.contentBackgroundImage
              ? `url(${appliedCloudinaryParams(
                  inlineStyles?.contentBackgroundImage,
                  inlineStyles?.contentBackgroundRepeat
                )})`
              : null,
            backgroundSize: inlineStyles?.contentBackgroundSize || 'cover',
            backgroundRepeat: inlineStyles?.contentBackgroundRepeat || null,
            backgroundPosition: inlineStyles?.contentBackgroundPosition || null,
            backgroundColor: inlineStyles?.contentBackgroundColor || null,
          }}
        >
          {heading && (
            <h2
              style={{
                color: inlineStyles?.titleColor || null,
                textAlign: textAlignment || null,
              }}
              className="text-2xl md:text-4xl font-black leading-none font-headline mb-4"
              dangerouslySetInnerHTML={{
                __html: sanitize(t(heading)),
              }}
            />
          )}

          {subtitle && (
            <WrappedSubtitle>
              <HeadingStyle
                text={subtitle}
                style={{ textAlign: textAlignment || null }}
                type={!heading ? 'h2' : 'p'}
                className="my-4 text-xl md:text-xl font-sans font-normal"
                textColorInline={inlineStyles?.subtitleColor}
              />
            </WrappedSubtitle>
          )}

          {content && (
            <div
              className="mb-8"
              style={{
                color: inlineStyles?.textColor || null,
                textAlign: textAlignment || null,
              }}
              dangerouslySetInnerHTML={{
                __html: HandleWYSIWYGContent(content, inlineStyles?.textColor),
              }}
            />
          )}

          {panel?.map((i, key) => {
            return (
              <AccordionSection
                key={key}
                panelLabel={i?.panelLabel}
                panelRepeater={i?.panelRepeater}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}
