import React from 'react';
export function AllInclusive({
  className,
  fillColor,
}: {
  className?: string;
  fillColor?: string;
}) {
  return (
    <svg
      className={className}
      style={{ fill: fillColor }}
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 24 24"
    >
      <g>
        <g>
          <path style={{ fill: 'none' }} d="M0,0v24h24V0H0z" />
        </g>
        <g>
          <g>
            <path
              d="M18.3125,6.792l-2-3C16.2426,3.6878,16.1255,3.6252,16,3.625h-4c-0.207-0.0002-0.3749,0.1675-0.3751,0.3745
            c-0.0001,0.1421,0.0801,0.272,0.2071,0.3355l2.58,1.29H8.5C8.3032,5.6251,8.14,5.7773,8.126,5.9736L7.625,13
            c0,1.7623,1.7656,3.1964,4,3.3458v3.2792H9c-0.2071,0-0.375,0.1679-0.375,0.375S8.7929,20.375,9,20.375h6
            c0.2071,0,0.375-0.1679,0.375-0.375S15.2071,19.625,15,19.625h-2.625v-3.2792c2.2344-0.1494,4-1.5835,4-3.3458l-0.001-0.0264
            L15.9023,6.37l1.93,0.9649c0.0521,0.0264,0.1096,0.0401,0.168,0.04c0.2073-0.0002,0.3753-0.1683,0.3751-0.3757
            c-0.0001-0.0738-0.0218-0.1459-0.0626-0.2073L18.3125,6.792z M12,15.625c-1.999,0-3.625-1.1777-3.626-2.5986L8.85,6.375h5.4893
            L12.44,9.5557c-0.105,0.1787-0.0453,0.4087,0.1334,0.5137c0.177,0.104,0.4047,0.0465,0.5111-0.129l2.0731-3.4715l0.4679,6.5438
            C15.6152,14.4541,13.9932,15.625,12,15.625z M13.5889,4.375h2.21l1.1055,1.6582L13.5889,4.375z"
            />
            <path
              d="M14.2051,10.2725c-0.0684,0.083-0.7041,0.7988-1.9942-0.0791c-1.9463-1.32-3.0029,0.07-3.0127,0.0849
            c-0.123,0.1667-0.0875,0.4015,0.0791,0.5245c0.1667,0.123,0.4015,0.0875,0.5245-0.0792
            c0.0253-0.0332,0.6337-0.8271,1.9873,0.0909c0.4635,0.3435,1.0201,0.5386,1.5966,0.5595
            c0.5436,0.0111,1.063-0.2248,1.4122-0.6416c0.1243-0.1629,0.0943-0.3956-0.0674-0.5215
            C14.5678,10.0851,14.3343,10.1124,14.2051,10.2725z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
