import React from 'react';
export function RadioChecked({
  className,
  fillColor,
}: {
  className?: string;
  fillColor?: string;
}) {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <circle cx="12" cy="8" r="7" stroke={fillColor} stroke-width="2" />
        <path
          d="M10.7109 11.2743C10.8672 11.4419 11.1328 11.4419 11.2891 11.2743L15.8828 6.34869C16.0391 6.18115 16.0391 5.89634 15.8828 5.7288L15.3203 5.12565C15.1641 4.95812 14.9141 4.95812 14.7578 5.12565L11.0078 9.1466L9.24219 7.27016C9.08594 7.10262 8.83594 7.10262 8.67969 7.27016L8.11719 7.8733C7.96094 8.04084 7.96094 8.32565 8.11719 8.49319L10.7109 11.2743Z"
          fill={fillColor}
        />
      </g>
    </svg>
  );
}
